import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';

import { dateTimeUtils } from '../../../../common/utils/index';
import { ReadFile, IAttachmentFile, AttachmentFile } from '../../../../organization/models/index';
import {
  IBenefitDetailsConfig,
  BenefitDetailsConfig,
  IBenefitDetailsBasic,
  BenefitDetailsBasic,
  BenefitDetailsCalcMethod,
  IBenefitDetailsProvider,
  BenefitDetailsProvider,
  BenefitDetailsProviderEntity,
  IBenefitDetailsLine,
  BenefitDetailsLine,
  IBenefitDetailsLineStandart,
  BenefitDetailsLineStandart,
  BenefitDetailsProviderLineStandartEntity,
  IBenefitDetailsTier,
  BenefitDetailsTier,
  IBenefitDetailsOption,
  BenefitDetailsOption,
  IBenefitDetailsSavedProvider,
  BenefitDetailsSavedProvider,
  BenefitDetailsGroupBasic,
  IBenefitDetailsGroup,
  BenefitDetailsGroup,
  IBenefitDetailsFormula,
  BenefitDetailsFormula,
  BenefitPayrollDeduction,
  IBenefitPayrollDeduction,
  IBenefitPayrollMapping,
  BenefitPayrollMapping,
  IBenefitDetailsPayrollGroup,
  BenefitDetailsPayrollGroup,
  IBenefitDetailsPayrollDeductions,
  BenefitDetailsPayrollDeductions,
  IBenefitPayrollExistingMapping,
  BenefitPayrollExistingMapping,
  IBenefitsDetailsValidationResult,
  BenefitsDetailsValidationResult
} from '../../models/index';

@Injectable()
export class BenefitDetailsMapService {
  constructor() { }

  public mapToBenefitPlanConfig(dto: IBenefitDetailsConfig): BenefitDetailsConfig {
    const config = new BenefitDetailsConfig();

    config.groups = _.map(dto.groups, v => this.mapToBenefitDetailsGroup(v));
    config.acaHarborCodes = _.map(dto.acaHarborCodes, v => this.mapToBenefitPlanBasic(v));
    config.freqCodes = _.map(dto.freqCodes, v => this.mapToBenefitPlanBasic(v));
    config.planForCodes = _.map(dto.planForCodes, v => this.mapToBenefitPlanBasic(v));
    config.benefitTypes = _.map(dto.benefitTypes, v => this.mapToBenefitPlanBasic<number, string>(v));

    config.canAdd = dto.canAdd;
    config.canEdit = dto.canEdit;
    config.canDelete = dto.canDelete;
    config.canEditFormula = dto.canEditFormulas;
    config.canRenew = dto.canRenew;
    config.canExpire = dto.canExpire;
    config.canExtend = dto.canExtend;
    config.canEnroll = dto.canEnroll;
    config.canMapPayroll = dto.canMapPayroll;
    return config;
  }

  public mapToBenefitDetailsGroup(dto: IBenefitDetailsGroup): BenefitDetailsGroup {
    const group = new BenefitDetailsGroup();
    if (_.isObjectLike(dto) && _.size(dto) > 0) {
      group.group = new BenefitDetailsGroupBasic();
      group.calcMethods = _.map(dto.calcMethods, l => this.mapToBenefitDetailsCalcMethod(l));
      group.allowMultipleEnrollment = dto.allowMultipleEnrollment;
      group.isDeleted = dto.isDeleted;
      group.modifiedAt = dateTimeUtils.convertFromDtoDateTimeString(dto.modifiedAt);
      group.modifiedBy = dto.modifiedBy;
      group.addedAt = dateTimeUtils.convertFromDtoDateTimeString(dto.addedAt);
      group.addedBy = dto.addedBy;

      if (_.isObjectLike(dto.groupDefinition)) {
        group.group.id = dto.groupDefinition.id;
        group.group.name = dto.groupDefinition.name;
        group.group.title = dto.groupDefinition.name;
        group.group.type = dto.groupDefinition.type;
      }
    }
    return group;
  }

  public mapToBenefitPayrollMapping(dto: IBenefitPayrollMapping): BenefitPayrollMapping {
    const payrollMapping = new BenefitPayrollMapping();

    payrollMapping.benefitGroups = _.map(dto.benefitGroups, v => this.mapToBenefitDetailsPayrollGroup(v));
    payrollMapping.payrollDeductions = _.map(dto.payrollDeductions, v => this.mapToBenefitDetailsPayrollDeductions(v));
    payrollMapping.isPayrolSyncEnabled = dto.isPayrolSyncEnabled;

    return payrollMapping;
  }

  public mapToBenefitDetailsPayrollGroup(group: IBenefitDetailsPayrollGroup): BenefitDetailsPayrollGroup {
    const payrollGroup = new BenefitDetailsPayrollGroup();

    payrollGroup.id = group.id;
    payrollGroup.name = group.name;
    payrollGroup.type = group.type;

    return payrollGroup;
  }

  public mapToBenefitDetailsPayrollDeductions(deductions: IBenefitDetailsPayrollDeductions): BenefitDetailsPayrollDeductions {
    const payrollDeductions = new BenefitDetailsPayrollDeductions();

    payrollDeductions.id = deductions.id;
    payrollDeductions.name = deductions.name;
    payrollDeductions.description = deductions.description;
    payrollDeductions.isIgnored = deductions.isIgnored;
    payrollDeductions.initialIsIgnored = deductions.isIgnored;

    return payrollDeductions;
  }

  public mapToBenefitPayrollExistingMapping(dto: IBenefitPayrollExistingMapping): BenefitPayrollExistingMapping {
    const payrollExistingMapping = new BenefitPayrollExistingMapping();

    payrollExistingMapping.payrollDeduction = this.mapToBenefitDetailsPayrollDeductions(dto.payrollDeduction);
    payrollExistingMapping.benefitName = dto.benefitName;
    payrollExistingMapping.benefitLineId = dto.benefitLineId;

    return payrollExistingMapping;
  }

  public mapToBenefitPayrollExistingMappings(dtos: IBenefitPayrollExistingMapping[]): BenefitPayrollExistingMapping[] {
    return _.map(dtos, v => this.mapToBenefitPayrollExistingMapping(v));
  }

  public mapToBenefitPlanProviders(dtos: IBenefitDetailsProvider[]): BenefitDetailsProvider[] {
    return _.map(dtos, v => this.mapToBenefitPlanProvider(v));
  }

  public mapToBenefitPlanProviderEntities(dtos: IBenefitDetailsProvider[]): BenefitDetailsProviderEntity[] {
    return _.map(dtos, v => {
      const entity = new BenefitDetailsProviderEntity();
      entity.current = this.mapToBenefitPlanProvider(v);

      return entity;
    });
  }

  public mapToBenefitPlanLineEntity(dto: IBenefitDetailsLineStandart): BenefitDetailsProviderLineStandartEntity {
    const entity = new BenefitDetailsProviderLineStandartEntity();
    entity.current = this.mapToBenefitPlanLineStandart(dto);

    return entity;
  }

  public mapToBenefitPlanLineStandart(dto: IBenefitDetailsLineStandart): BenefitDetailsLineStandart {
    const planLine = new BenefitDetailsLineStandart();
    planLine.line = this.mapToBenefitPlanLine(dto.lineDefinition);
    planLine.isDeleted = dto.isDeleted;
    planLine.modifiedAt = dateTimeUtils.convertFromDtoDateTimeString(dto.modifiedAt);
    planLine.modifiedBy = dto.modifiedBy;
    planLine.addedAt = dateTimeUtils.convertFromDtoDateTimeString(dto.addedAt);
    planLine.addedBy = dto.addedBy;
    planLine.payrollDeductionDate = dateTimeUtils.convertFromDtoDateTimeString(dto.payrollDeductionDate);
    planLine.childAgeLimit = dto.childAgeLimit === 0 ? null : dto.childAgeLimit;
    planLine.includeInReport = dto.includeInReport;
    planLine.includeInEoiReport = dto.includeInEoiReport;
    planLine.costFreq = dto.costFreq ? this.mapToBenefitPlanBasic<number, string>(dto.costFreq) : null;
    planLine.empContFreq = dto.empContFreq ? this.mapToBenefitPlanBasic<number, string>(dto.empContFreq) : null;
    planLine.calcMethod = dto.calcMethod ? this.mapToBenefitDetailsCalcMethod(dto.calcMethod) : null;
    planLine.tiers = _.map(dto.benefitTiers, t => this.mapToBenefitPlanTier(t));
    planLine.mappedDeduction = this.mapToBenefitPayrollDeduction(dto.mappedDeduction);
    planLine.enrollmentCount = dto.enrollmentCount;
    return planLine;
  }

  public mapBenefitPlanLineStandartToDto(planLine: BenefitDetailsLineStandart): IBenefitDetailsLineStandart {
    const dto = {} as IBenefitDetailsLineStandart;
    dto.lineDefinition = this.mapBenefitPlanLineToDto(planLine.line);
    dto.isDeleted = planLine.isDeleted;
    dto.modifiedAt = dateTimeUtils.convertToDtoDateTimeString(planLine.modifiedAt);
    dto.modifiedBy = planLine.modifiedBy;
    dto.addedAt = dateTimeUtils.convertToDtoDateTimeString(planLine.addedAt);
    dto.addedBy = planLine.addedBy;
    dto.payrollDeductionDate = dateTimeUtils.convertToDtoDateTimeString(planLine.payrollDeductionDate);
    dto.childAgeLimit = planLine.childAgeLimit;
    dto.includeInReport = planLine.includeInReport;
    dto.includeInEoiReport = planLine.includeInEoiReport;
    dto.costFreq = this.mapBenefitPlanBasicToDto<number, string>(planLine.costFreq);
    dto.empContFreq = this.mapBenefitPlanBasicToDto<number, string>(planLine.empContFreq);
    dto.calcMethod = this.mapBenefitPlanBasicToDto<string, string>(planLine.calcMethod);
    dto.benefitTiers = _.map(planLine.tiers, t => this.mapBenefitPlanTierToDto(t));

    return dto;
  }

  public mapToBenefitPlanProvider(dto: IBenefitDetailsProvider): BenefitDetailsProvider {
    const provider = new BenefitDetailsProvider();
    if (_.isObjectLike(dto) && _.size(dto) > 0) {
      provider.id = dto.id;
      provider.name = _.isString(dto.name) && _.size(dto.name) ? dto.name : 'Empty Name';
      provider.storedName = provider.name;
      provider.orgLevelId = dto.orgLevelId;
      provider.orgLevelName = dto.orgLevelName;
      provider.benefitGroupId = dto.benefitGroupId;
      provider.benefitGroupName = dto.benefitGroupName;
      provider.allLinesExpired = dto.allLinesExpired;
      provider.editableAtSelectedOrgLevel = dto.editableAtSelectedOrgLevel;

      const benefitLines = _.map(dto.benefitLines, l => this.mapToBenefitPlanLine(l));
      provider.benefitLines = _.orderBy(benefitLines, (r: BenefitDetailsLine) => r.startDate, 'desc');

      const today = moment().startOf('day');
      let actualBenefitLine = _.find(provider.benefitLines, l => today.isBetween(l.startDate, l.endDate));
      if (_.isNil(actualBenefitLine)) {
        actualBenefitLine = _.head(provider.benefitLines);
      }
      provider.actualOrLatestBenefitLine = actualBenefitLine;
    }
    return provider;
  }

  public mapBenefitPlanProviderToDto(provider: BenefitDetailsProvider): IBenefitDetailsProvider {
    const dto = {} as IBenefitDetailsProvider;
    if (_.isObjectLike(provider) && _.size(provider) > 0) {
      dto.id = provider.id;
      dto.name = provider.name;
      dto.orgLevelId = provider.orgLevelId;
      dto.orgLevelName = provider.orgLevelName;
      dto.benefitGroupId = provider.benefitGroupId;
      dto.benefitGroupName = provider.benefitGroupName;
      dto.allLinesExpired = provider.allLinesExpired;
      dto.editableAtSelectedOrgLevel = provider.editableAtSelectedOrgLevel;
      dto.benefitLines = _.map(provider.benefitLines, l => this.mapBenefitPlanLineToDto(l));
    }
    return dto;
  }

  public mapToSavedProvider(dto: IBenefitDetailsSavedProvider): BenefitDetailsSavedProvider {
    const savedProvider = new BenefitDetailsSavedProvider();
    if (_.isObjectLike(dto) && _.size(dto) > 0) {
      const providerEntity = new BenefitDetailsProviderEntity();
      providerEntity.current = this.mapToBenefitPlanProvider(dto.benefitProvider);
      const lineEntity = new BenefitDetailsProviderLineStandartEntity();
      lineEntity.current = this.mapToBenefitPlanLineStandart(dto.firstLine);
      savedProvider.provider = providerEntity;
      savedProvider.providerLine = lineEntity;
    }

    return savedProvider;
  }

  public mapToSaveProviderRequest(provider: BenefitDetailsProvider, providerLine: BenefitDetailsLineStandart): any {
    providerLine.tiers = _.filter(providerLine.tiers, t => !t.stateIsDeleted);
    providerLine.line.name = provider.name;
    const requestBody = {
      id: provider.id,
      name: provider.name,
      orgLevelId: provider.orgLevelId,
      benefitGroupId: provider.benefitGroupId,
      firstLine: this.mapBenefitPlanLineStandartToDto(providerLine)
    };

    return requestBody;
  }

  public mapToSaveProviderLineStandartRequest(providerName: string, providerLine: BenefitDetailsLineStandart, providerPlanDate: Date): any {
    const tiers = _.reduce(providerLine.tiers, (accum, t) => {
      if (t.isDraft && (t.stateIsAdded || t.stateIsUpdated)) accum.added.push(t);
      if (!t.isDraft && t.stateIsUpdated) accum.updated.push(t);
      if (!t.isDraft && t.stateIsDeleted) accum.deleted.push(t);
      return accum;
    }, { added: [], updated: [], deleted: [] });

    const requestBody = {
      providerName: providerName,
      benefitLine: this.mapBenefitPlanLineStandartToDto(providerLine),
      tiersToAdd: _.map(tiers.added, t => this.mapBenefitPlanAddedTiersToDto(t)),
      tiersToUpdate: _.map(tiers.updated, t => this.mapBenefitPlanUpdatedTiersToDto(t)),
      tiersToDelete: _.map(tiers.deleted, t => t.id),
      effectiveDate: dateTimeUtils.convertToDtoString(providerPlanDate)
    };

    return requestBody;
  }

  public mapFilesToFormData(files: ReadFile[]): FormData {
    const formData: FormData = new FormData();
    _.forEach(files, (file: ReadFile, index: number) => {
      formData.append(`i${index}_name`, file.name);
      formData.append(`i${index}_fileName`, file.fileName);
      formData.append(`i${index}_category`, file.mimeType);
      formData.append(`i${index}_file`, file.data, file.fileName);
    });
    return formData;
  }

  public mapToTiersFilesToSave(savedTiers: BenefitDetailsTier[], draftTiers: BenefitDetailsTier[]): Map<number, ReadFile[]> {
    const filesToSaveByName = _.reduce(draftTiers, (accum, tier) => {
      if (_.size(tier.filesToAttach) > 0 && _.size(tier.name) > 0) {
        accum.set(tier.name, tier.filesToAttach.concat());
      }
      return accum;
    }, new Map<string, ReadFile[]>());

    const filesToSaveById = _.reduce(savedTiers, (accum, tier) => {
      if (filesToSaveByName.has(tier.name)) {
        accum.set(tier.id, filesToSaveByName.get(tier.name));
      }
      return accum;
    }, new Map<number, ReadFile[]>());

    return filesToSaveById;
  }

  public mapToBenefitPlanTier(dto: IBenefitDetailsTier): BenefitDetailsTier {
    const tier = new BenefitDetailsTier();
    if (_.isObjectLike(tier) && _.size(tier) > 0) {
      tier.tier = this.mapToBenefitPlanBasic<number, string>(dto.tierDefinition);
      tier.planId = dto.planId;
      tier.payrollDeductionCode=dto.payrollDeductionCode;
      tier.highDeductableTier = dto.highDeductableTier;
      tier.hasBeneficiaries = dto.hasBeneficiaries;
      tier.notes = dto.notes;
      tier.benefitGroupsCount = dto.benefitGroupsCount;
      tier.nonTobacco = dto.nonTobacco;
      tier.domesticPartnerEligible = dto.domesticPartnerEligible;
      tier.companyProvided = dto.companyProvided;
      tier.employerDeductionCode = dto.employerDeductionCode;
      tier.isEmployeeEarningCode = dto.isEmployeeEarningCode;
      tier.isEmployerEarningCode = dto.isEmployerEarningCode;
      tier.empContribution = dto.empContribution;
      tier.erContribution = dto.erContribution;
      tier.empFormula = this.mapToBenefitDetailsFormula(dto.empFormula);
      tier.erFormula = this.mapToBenefitDetailsFormula(dto.erFormula);
      tier.coverageFormula = this.mapToBenefitDetailsFormula(dto.coverageFormula);
      tier.acaMinCoverage = dto.acaMinCoverage;
      tier.acaMinValue = dto.acaMinValue;
      tier.acaPovLevelTest = dto.acaPovLevelTest;
      tier.acaHarborCode = this.mapToBenefitPlanBasic<number, string>(dto.acaHarborCode);
      tier.attachments = _.map(dto.attachments, a => this.mapToBenefitPlanAttachment(a));
      tier.options = _.map(dto.options, o => this.mapToBenefitPlanOption(o));
      tier.isDeleted = dto.isDeleted;
      tier.modifiedAt = dateTimeUtils.convertFromDtoDateTimeString(dto.modifiedAt);
      tier.modifiedBy = dto.modifiedBy;
      tier.addedAt = dateTimeUtils.convertFromDtoDateTimeString(dto.addedAt);
      tier.addedBy = dto.addedBy;
    }
    return tier;
  }

  public mapToBenefitPlanLine(dto: IBenefitDetailsLine): BenefitDetailsLine {
    const line = new BenefitDetailsLine();
    if (_.isObjectLike(dto) && _.size(dto) > 0) {
      line.id = dto.id;
      line.name = dto.name;
      line.benefitLineType = dto.benefitLineType;
      line.startDate = dateTimeUtils.convertFromDtoDateTimeString(dto.start);
      line.endDate = dateTimeUtils.convertFromDtoDateTimeString(dto.end);
      line.payrollDeductionStartDate = dateTimeUtils.convertFromDtoDateTimeString(dto.payrollDeductionStartDate);
      line.payrollDeductionEndDate = dateTimeUtils.convertFromDtoDateTimeString(dto.payrollDeductionEndDate);
      line.totalEnrolledEmployees = dto.totalEnrolledEmployees;
    }
    return line;
  }

  public mapToBenefitPlanOption(dto: IBenefitDetailsOption): BenefitDetailsOption {
    const option = new BenefitDetailsOption();
    if (_.isObjectLike(dto) && _.size(dto) > 0) {
      option.id = dto.id;
      option.optionId = dto.optionId;

      option.type = dto.type;
      option.code = dto.code;
      option.spouse = dto.spouse;
      option.children = dto.children;
      option.cost = dto.cost;
      option.empContribution = dto.empContribution;
      option.erContribution = dto.erContribution;

      option.ageStart = dto.ageStart;
      option.ageEnd = dto.ageEnd;
      option.annualContributionMin = dto.annualContributionMin;
      option.annualContributionLimit = dto.annualContributionLimit;
      option.erAnnualContribution = dto.erAnnualContribution;

      option.maxContribution = dto.maxContribution;
      option.suggestedContribution = dto.suggestedContribution;
      option.maxMatchGrant = dto.maxMatchGrant;
      option.maxEmpContribution = dto.maxEmpContribution;
      option.limitAmount = dto.limitAmount;
      option.compensationLimit = dto.compensationLimit;
      option.acaCategory = dto.acaCategory;
    }
    return option;
  }

  public mapBenefitPlanOptionToDto(option: BenefitDetailsOption): IBenefitDetailsOption {
    let dto: IBenefitDetailsOption = null;
    if (_.isObjectLike(option) && _.size(option) > 0) {
      dto = {} as any;
      dto.id = option.id;
      dto.optionId = option.optionId;

      dto.type = option.type;
      dto.code = option.code;
      dto.spouse = option.spouse;
      dto.children = option.children;
      dto.cost = option.cost;
      dto.erContribution = _.isFinite(option.erContribution) ? option.erContribution : 0.00;
      dto.empContribution = _.isFinite(option.empContribution) ? option.empContribution : 0.00;

      dto.ageStart = option.ageStart;
      dto.ageEnd = option.ageEnd;
      dto.annualContributionMin = option.annualContributionMin;
      dto.annualContributionLimit = option.annualContributionLimit;
      dto.erAnnualContribution = option.erAnnualContribution;

      dto.maxContribution = option.maxContribution;
      dto.suggestedContribution = option.suggestedContribution;
      dto.maxMatchGrant = option.maxMatchGrant;
      dto.maxEmpContribution = option.maxEmpContribution;
      dto.limitAmount = option.limitAmount;
      dto.compensationLimit = option.compensationLimit;
      dto.acaCategory = option.acaCategory;
    }
    return _.size(dto) > 0 ? dto : null;
  }

  public mapToBenefitDetailsCalcMethod<T, D>(dto: IBenefitDetailsBasic<T, D>): BenefitDetailsCalcMethod {
    let calcMethod = new BenefitDetailsCalcMethod('', '', '');
    if (_.isObjectLike(dto) && _.size(dto) > 0) {
      const { id, name } = dto;
      calcMethod = new BenefitDetailsCalcMethod(id as any, name as any, name as any);
    }
    return calcMethod;
  }

  public mapToBenefitPayrollDeduction(dto: IBenefitPayrollDeduction): BenefitPayrollDeduction {
    const option = new BenefitPayrollDeduction();
    if (_.isObjectLike(dto) && _.size(dto) > 0) {
      option.id = dto.id;
      option.name = dto.name;
      option.description = dto.description;
      option.isIgnored = dto.isIgnored;
      option.startDate = dateTimeUtils.convertFromDtoDateTimeString(dto.startDate);
      option.endDate = dateTimeUtils.convertFromDtoDateTimeString(dto.endDate);
    }
    return option;
  }

  public mapToBenefitValidationResult(dto: IBenefitsDetailsValidationResult): BenefitsDetailsValidationResult {
    const model = new BenefitsDetailsValidationResult();
    model.isValid = dto.isValid;
    model.isReadyForValidation = true;
    model.errorMessage = dto.errorMessage;

    return model;
  }

  private mapBenefitPlanLineToDto(pLine: BenefitDetailsLine): IBenefitDetailsLine {
    const dto = {} as IBenefitDetailsLine;
    if (_.isObjectLike(pLine) && _.size(pLine) > 0) {
      dto.id = pLine.id;
      dto.name = pLine.name;
      dto.benefitLineType = pLine.benefitLineType;
      dto.start = dateTimeUtils.convertToDtoDateTimeString(pLine.startDate);
      dto.end = dateTimeUtils.convertToDtoDateTimeString(pLine.endDate);
      dto.payrollDeductionStartDate = dateTimeUtils.convertToDtoDateTimeString(pLine.payrollDeductionStartDate);
      dto.payrollDeductionEndDate = dateTimeUtils.convertToDtoDateTimeString(pLine.payrollDeductionEndDate);
      dto.totalEnrolledEmployees = pLine.totalEnrolledEmployees;
    }
    return dto;
  }

  private mapBenefitPlanTierToDto(tier: BenefitDetailsTier): IBenefitDetailsTier {
    const dto = {} as IBenefitDetailsTier;
    if (_.isObjectLike(tier) && _.size(tier) > 0) {
      dto.tierDefinition = this.mapBenefitPlanBasicToDto<number, string>(tier.tier);
      dto.planId = tier.planId;
      dto.payrollDeductionCode=tier.payrollDeductionCode;
      dto.highDeductableTier = tier.highDeductableTier;
      dto.hasBeneficiaries = tier.hasBeneficiaries;
      dto.notes = tier.notes;
      dto.benefitGroupsCount = tier.benefitGroupsCount;
      dto.nonTobacco = tier.nonTobacco;
      dto.domesticPartnerEligible = tier.domesticPartnerEligible;
      dto.companyProvided = tier.companyProvided;
      dto.employerDeductionCode = tier.employerDeductionCode;
      dto.isEmployeeEarningCode = tier.isEmployeeEarningCode;
      dto.isEmployerEarningCode = tier.isEmployerEarningCode;
      dto.empContribution = tier.empContribution ? tier.empContribution : 0.0;
      dto.erContribution = tier.erContribution ? tier.erContribution : 0.0;
      dto.empFormula = this.mapBenefitDetailsFormulaToDto(tier.empFormula);
      dto.erFormula = this.mapBenefitDetailsFormulaToDto(tier.erFormula);
      dto.coverageFormula = this.mapBenefitDetailsFormulaToDto(tier.coverageFormula);
      dto.acaMinCoverage = tier.acaMinCoverage;
      dto.acaMinValue = tier.acaMinValue;
      dto.acaPovLevelTest = tier.acaPovLevelTest;
      dto.acaHarborCode = this.mapBenefitPlanBasicToDto<number, string>(tier.acaHarborCode);
      dto.attachments = _.map(tier.attachments, a => this.mapBenefitPlanAttachmentToDto(a));
      dto.options = _.map(tier.options, o => this.mapBenefitPlanOptionToDto(o));
      dto.isDeleted = tier.isDeleted;
      dto.modifiedAt = dateTimeUtils.convertToDtoDateTimeString(tier.modifiedAt);
      dto.modifiedBy = tier.modifiedBy;
      dto.addedAt = dateTimeUtils.convertToDtoDateTimeString(tier.addedAt);
      dto.addedBy = tier.addedBy;
    }
    return dto;
  }

  private mapToBenefitDetailsFormula(dto: IBenefitDetailsFormula): BenefitDetailsFormula {
    const formula = new BenefitDetailsFormula();
    if (_.isObjectLike(dto) && _.size(dto) > 0) {
      formula.formula = this.mapToBenefitPlanBasic<number, string>(dto.formulaDefinition);
      formula.startDate = dateTimeUtils.convertFromDtoDateTimeString(dto.start);
      formula.endDate = dateTimeUtils.convertFromDtoDateTimeString(dto.end);
      formula.payrollFormula = dto.payrollFormula;
      formula.fixedAmount = dto.fixedAmount;
      formula.maxCap = dto.maxCup;
      formula.multipler = dto.multipler;
      formula.calculationType = dto.calculationType;
      formula.expression = dto.expression;
      formula.expressionType = dto.expressionType;
    }
    return formula;
  }

  private mapToBenefitPlanAttachment(dto: IAttachmentFile): AttachmentFile {
    const attachment = new AttachmentFile();
    if (_.isObjectLike(dto) && _.size(dto) > 0) {
      attachment.id = dto.id;
      attachment.name = dto.name;
      attachment.fileName = dto.fileName;
      attachment.employeeId = dto.employeeId;
      attachment.addedBy = dto.addedBy;
      attachment.addedOn = dateTimeUtils.convertFromDtoDateTimeString(dto.addedOn);
    }
    return attachment;
  }

  private mapBenefitDetailsFormulaToDto(formula: BenefitDetailsFormula): IBenefitDetailsFormula {
    let dto: IBenefitDetailsFormula = null;
    if (_.isObjectLike(formula) && _.size(formula.calculationType) > 0) {
      dto = {
        formulaDefinition: this.mapBenefitPlanBasicToDto<number, string>(formula.formula),
        start: dateTimeUtils.convertToDtoDateTimeString(formula.startDate),
        end: dateTimeUtils.convertToDtoDateTimeString(formula.endDate),
        payrollFormula: formula.payrollFormula,
        fixedAmount: _.isFinite(formula.fixedAmount) ? formula.fixedAmount : 0.00,
        maxCup: formula.maxCap,
        multipler: formula.multipler,
        calculationType: formula.calculationType,
        expression: formula.expression,
        expressionType: formula.expressionType
      };
    }
    return dto;
  }

  private mapBenefitPlanAttachmentToDto(attach: AttachmentFile): IAttachmentFile {
    const dto = {} as IAttachmentFile;
    if (_.isObjectLike(attach) && _.size(attach) > 0) {
      dto.id = attach.id;
      dto.name = attach.name;
      dto.fileName = attach.fileName;
      dto.employeeId = attach.employeeId;
      dto.addedBy = attach.addedBy;
      dto.addedOn = dateTimeUtils.convertToDtoDateTimeString(attach.addedOn);
    }
    return dto;
  }

  private mapBenefitPlanAddedTiersToDto(addedTier: BenefitDetailsTier): IBenefitDetailsTier {
    _.remove(addedTier.options, (o) => o.stateIsDeleted);
    return this.mapBenefitPlanTierToDto(addedTier);
  }

  private mapBenefitPlanUpdatedTiersToDto(updatedTier: BenefitDetailsTier): any {
    const options = _.reduce(updatedTier.options, (accum, o) => {
      if (o.isDraft && (o.stateIsAdded || o.stateIsUpdated)) accum.added.push(o);
      if (!o.isDraft && o.stateIsUpdated) accum.updated.push(o);
      if (!o.isDraft && o.stateIsDeleted) accum.deleted.push(o);
      return accum;
    }, { added: [], updated: [], deleted: [] });

    const dto = {
      benefitTier: this.mapBenefitPlanTierToDto(updatedTier),
      optionsToAdd: _.map(options.added, o => this.mapBenefitPlanOptionToDto(o)),
      optionsToUpdate: _.map(options.updated, o => this.mapBenefitPlanOptionToDto(o)),
      optionsToDelete: _.map(options.deleted, o => o.id as number),
    };

    return dto;
  }

  private mapToBenefitPlanBasic<A, B>(dto: IBenefitDetailsBasic<A, B>): BenefitDetailsBasic<A, B> {
    let id = null;
    let name = null;
    if (_.isObjectLike(dto) && _.size(dto) > 0) {
      id = dto.id;
      name = dto.name;
    }
    return new BenefitDetailsBasic<A, B>(id, name, name);
  }

  private mapBenefitPlanBasicToDto<A, B>(b: BenefitDetailsBasic<A, B>): IBenefitDetailsBasic<A, B> {
    let dto = {} as IBenefitDetailsBasic<A, B>;
    if (_.isObjectLike(b) && _.size(b) > 0) {
      dto.id = _.isString(b.id) || _.isFinite(b.id) && (b.id as any) !== -1 ? b.id : null;
      dto.name = _.isString(b.name) ? b.name : null;
    }
    return dto;
  }
}
