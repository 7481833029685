import { Directive, HostListener, Input, Provider } from '@angular/core';
import { Router, ActivatedRoute, UrlTree, NavigationExtras } from '@angular/router';
import { DailyPunchesNavigationService } from '../../services/index';

@Directive({
  selector: '[dailyPunchesLink]',
})
export class DailyPunchesLinkDirective {
  @Input()
  public dailyPunchesLink: number;
  @Input()
  public startDate: Date;
  @Input()
  public endDate: Date;
  @Input()
  public onlyMissing: boolean;
  @Input()
  public punchRequest : boolean;

  private router: Router;
  private route: ActivatedRoute;
  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  @HostListener('click', ['$event'])
  public onClick(e: MouseEvent): void {
    let navService: DailyPunchesNavigationService = new DailyPunchesNavigationService(this.router, this.route);
    if (!this.startDate || !this.endDate) {
      navService.navigateToDailyPunches(this.dailyPunchesLink);
    } else {
      navService.navigateToDailyPunchesDates(this.dailyPunchesLink, this.startDate, this.endDate, this.onlyMissing , this.punchRequest);
    }
  }
}
