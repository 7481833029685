import * as _ from 'lodash';
import * as moment from 'moment';
import { DialogOptions } from '../../../common/models/dialog-options';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { Actions } from '../../../core/models/field/actions-type';
import { ManagementBaseService } from '../../../core/services/index';
import { Observable } from 'rxjs/Observable';
import { ChangeManagementService } from '../../../common/index';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { OrgLevel } from '../../../state-model/models/index';
import { organizationConfig } from '../../../organization/organization.config';
import { LookupEntity } from '../../../organization/models/lookup/lookup-entity';
import { LookupApiService } from '../../../organization/services/lookup/lookup-api.service';

import { AccessManagementService } from '../accessManagement/access-management.service';

import { IConfigutrationContainer } from '../../models/configiration-container.interface';
import { ShiftsContainer } from '../../models/shifts/shifts-container';
import { Shift } from '../../models/shifts/shift';
import { ShiftsApiService } from './shifts-api.service';
import { IConfigurationManagementService } from '../../utils/iconfiguration-management-service';
import { IShiftSetting, ShiftSetting } from '../../models/shifts/shift-setting';

@Injectable()
export class ShiftsManagementService extends ManagementBaseService<ShiftsContainer, any> implements IConfigurationManagementService {

    @mutableSelect(['orgLevel'])
    public orgLevel$: Observable<OrgLevel>;

    public removeItemsCmd$: ReplaySubject<{ dialogOptions: DialogOptions, itemToDelete: Shift }>;
    public addItemCmd$: ReplaySubject<Shift>;
    public editItemCmd$: ReplaySubject<Shift>;
    public savedItemCmd$: ReplaySubject<Shift>;
    public dataChanged$: Subject<boolean>;
    public errorNotify$: Subject<string>;
    public viewRefresh$: Subject<boolean>;
    public editingItem: any;

    public get groups(): LookupEntity[] {
        return this.m_groups;
    }

    public get container(): IConfigutrationContainer {
        return this.m_container;
    }


    public isEditingNewItem: boolean;
    private m_container: IConfigutrationContainer;
    private m_groups: LookupEntity[];
    private currentOrgLevel: OrgLevel;


    @unsubscribeInService()
    private orgLevelSubscription: Subscription;

    constructor(public access: AccessManagementService, public changeService: ChangeManagementService, private api: ShiftsApiService, private lookup: LookupApiService) {

        super();
        this.removeItemsCmd$ = new ReplaySubject<{ dialogOptions: DialogOptions, itemToDelete: Shift }>();
        this.addItemCmd$ = new ReplaySubject<Shift>();
        this.editItemCmd$ = new ReplaySubject<Shift>();
        this.savedItemCmd$ = new ReplaySubject<Shift>();
        this.dataChanged$ = new Subject<boolean>();
        this.viewRefresh$ = new Subject<boolean>();
        this.errorNotify$ = new Subject<string>();
    }

    public init(): void {

        this.access.allowCorporationLevel = false;
        this.access.allowOrganizationLevel = false;
        this.access.allowDepartmentLevel = true;

        this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
            if (_.isNumber(orgLevel.id)) {
                this.currentOrgLevel = orgLevel;
                this.access.orgLevelType = this.currentOrgLevel.type;
                this.onStateChanged$.next({ orgLevelChanged: true, configureMode: true, copyMode: false });
                this.fetchRecords();
                this.getGroups();
            }
        });
    }

    public markAsDirty(): void {
        this.changeService.changeNotify();
    }

    public openCopyItems(): void {
        this.onStateChanged$.next({ configureMode: false, copyMode: true });
    }

    public closeCopyItems(): void {
        this.onStateChanged$.next({ configureMode: true, copyMode: false });
    }

    public setSelectedCount(count: number): void {
        this.access.selectedItemsCount = count;
    }

    public onAddItem(item: any): void {
        this.changeService.changeNotify();
        this.editingItem = item;
        this.isEditingNewItem = true;
        this.addItemCmd$.next(item);
    }
    public onEditItem(item: any): void {
        this.editingItem = item;
        this.editItemCmd$.next(item);
    }

    public onCancelEditItem(): void {
        this.editingItem = null;
        this.isEditingNewItem = false;
        this.editItemCmd$.next(null);
        this.changeService.clearChanges();
    }

    public onRemoveChildPartialShifts(itemToDelete: Shift): Shift {
        if(itemToDelete.hasPartialShift && itemToDelete.partialShiftList.length > 0) {
            _.map(itemToDelete.partialShiftList, (shift) => shift.isDeleted = true);
          }
        return itemToDelete;
    }

    public onRemoveItem(itemToDelete: Shift): void {

        if (itemToDelete.employeesCount > 0) {

            let dialogOptions: DialogOptions = new DialogOptions();
            dialogOptions.width = 370;
            dialogOptions.height = 190;
            this.removeItemsCmd$.next({ dialogOptions: dialogOptions, itemToDelete: itemToDelete });
        } else {
            this.removeItemsCmd$.next({ dialogOptions: null, itemToDelete: itemToDelete });
        }
    }

    public doRemoveItem(item: Shift, reassignTo?: Shift): void {

        this.api.removeShift(item, this.currentOrgLevel.id, reassignTo)
            .then((items: Shift[]) => {
                this.access.lockActions = false;
                this.onStateChanged$.next({ isLoading: false });
                this.fetchRecords();
            }).catch(() => {
                this.access.lockActions = false;
                this.viewRefresh$.next(false);
                this.onStateChanged$.next({ isLoading: false });
            });
    }

    public onSaveFormExternalEditor(): void {
        this.onSaveItem({ dataItem: this.editingItem, isNew: false });
    }

    public onCancelFormExternalEditor(): void {
        this.editingItem = null;
        this.isEditingNewItem = false;
    }

    public onSaveItem(info: { dataItem: Shift, isNew: boolean }): void {
        let preventSaveError: string;
        _.each(this.m_container.records, (shift: Shift) => {
            if (shift !== info.dataItem) {
                let startSame: boolean = moment(shift.start).isSame(info.dataItem.start);
                let endSame: boolean = moment(shift.end).isSame(info.dataItem.end);
                let durationSame: boolean = shift.duration === info.dataItem.duration;
                if (shift.name === info.dataItem.name) {
                    preventSaveError = 'Attempting to save duplicate shift description';
                }
                if (startSame && endSame && durationSame) {
                    preventSaveError = 'Attempting to save duplicate shift';
                }
            }
        });

        if (preventSaveError) {
            this.errorNotify$.next(preventSaveError);
            return;
        }

        if (info.isNew) {
            this.addItem(info.dataItem);
        } else {
            this.updateItem(info.dataItem);
        }
    }

    protected addItem(item: Shift): void {

        _.each(this.m_container.records, (p: Shift) => {
            p.isSelected = false;
        });
        this.onStateChanged$.next({ isLoading: true });
        this.access.lockActions = true;
        this.api.saveShift(item, this.currentOrgLevel.id)
            .then((items: Shift) => {
                this.savedItemCmd$.next(this.editingItem);
                this.access.lockActions = false;
                this.editingItem = null;
                this.isEditingNewItem = false;
                this.onStateChanged$.next({ isLoading: false });
                this.fetchRecords();
            }).catch(() => {
                this.access.lockActions = false;
                this.viewRefresh$.next(false);
                this.onStateChanged$.next({ isLoading: false });
            });

    }

    protected updateItem(item: Shift): void {

        _.each(this.m_container.records, (p: Shift) => {
            p.isSelected = false;
        });
        this.onStateChanged$.next({ isLoading: true });
        this.access.lockActions = true;

        //merging partials shifts 
        if (item.hasPartialShift || item.deletedPartials.length > 0) {
            item.partialShiftList = _.concat(item.partialShiftList, item.deletedPartials)
        }

        this.api.saveShift(item, this.currentOrgLevel.id)
            .then((items: Shift) => {
                this.savedItemCmd$.next(this.editingItem);
                this.access.lockActions = false;
                this.editingItem = null;
                this.isEditingNewItem = false;
                this.viewRefresh$.next(false);
                this.onStateChanged$.next({ isLoading: false });
                this.fetchRecords();
            }).catch(() => {
                this.onStateChanged$.next({ isLoading: false });
                this.access.lockActions = false;
                this.viewRefresh$.next(false);
            });
    }

    protected fetchRecords(): void {
        this.access.lockActions = true;
        this.onStateChanged$.next({ isLoading: true });
        this.api.getShiftsList(this.currentOrgLevel.id).
            then((result: { actions: Actions, records: Shift[] }) => {
                this.changeService.clearChanges();
                this.m_container = new ShiftsContainer();
                this.m_container.records = result.records;
                this.access.actions = result.actions;
                this.access.lockActions = false;
                this.editingItem = null;
                this.isEditingNewItem = false;
                this.onLoaded$.next(this.m_container);
                this.onStateChanged$.next({ isLoading: false });
            }).catch(() => {
                this.access.lockActions = false;
                this.onStateChanged$.next({ isLoading: false });
            });
    }

    protected getGroups(): void {
        let orgLevelId: number = this.currentOrgLevel.id;
        this.access.lockActions = true;
        this.onStateChanged$.next({ isLoading: true });
        this.lookup.getLookup(organizationConfig.lookup.shiftGroup, orgLevelId)
            .then((value: LookupEntity[]) => {
                this.access.lockActions = false;
                this.onStateChanged$.next({ isLoading: false });
                this.m_groups = value;
            })
            .catch((reason: any) => {
                this.access.lockActions = false;
                this.onStateChanged$.next({ isLoading: false });
                this.m_groups = null;
            });
    }

    public async getShiftSetting(shiftId: number): Promise<ShiftSetting> {
        this.access.lockActions = true;
        this.onStateChanged$.next({ isLoading: true });
        return await this.api.getShiftSetting(this.currentOrgLevel.id, shiftId).then(res => {return res;}).finally(()=>{
            this.access.lockActions = false;
            this.onStateChanged$.next({ isLoading: false });
        });
    }
}
