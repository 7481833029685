<slx-spinner [show]="state.isLoading">
  
  <div class="slx-high-box">
    <div style="
    padding-top: 12px;
    padding-left: 13px;">
      <button type="button" class="slx-button slx-blue" *ngIf="isAccessEmployers"  (click)="navigateToACA()">Companies</button>
  </div>
    <div class="editor-container" *ngIf="management.editingItem">
      <slx-pbj-organization-editor [item]="management.editingItem" (actionEmitter)="extenalEditorAction ($event)" [organizationTimezones]="organizationTimezones"></slx-pbj-organization-editor>
    </div>

    <form #templateForm="ngForm" novalidate *ngIf="!management.editingItem" class="slx-high-box__body slx-main-content-indents">
      <kendo-grid #kendoGrid class="slx-full-height" [data]="gridState?.view" [selectable]="{ mode: 'single' }" [filterable]="true"
        [filter]="gridState.state.filter" [sort]="gridState.state.sort" [sortable]="{ mode: 'multiple' }" (selectionChange)="gridState.selectionChange($event)"
        (dataStateChange)="gridState.dataStateChange($event)" (cancel)="gridState.cancelHandler($event)" (edit)="gridState.editHandler($event)"
        (save)="gridState.saveHandler($event)">

        <!-- mobile view -->
        <kendo-grid-column media="(max-width: 450px)" width="100%">
          <ng-template kendoGridHeaderTemplate></ng-template>
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <slx-pbj-organization-renderer [rowIndex]="rowIndex" [item]="dataItem" (actionEmitter)="onMobileRendererEvent ($event, dataItem, rowIndex)" [organizationTimezones]="organizationTimezones"></slx-pbj-organization-renderer>
          </ng-template>
        </kendo-grid-column>

        <!-- desktop view -->
        <kendo-grid-command-column width="60" [locked]="true" media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem let-rowIndex="rowIndex">
            <div class="command-container">
              <button kendoGridEditCommand type="button" class="theme-icon-button theme-inline-edit-button">
                <i class="fas fa-pencil-alt" aria-hidden="true"></i>
              </button>
            </div>
          </ng-template>
        </kendo-grid-command-column>

        <kendo-grid-column title="Name" field="pbjOrganization.name" [filterable]="true" width="200" media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">{{ dataItem.pbjOrganization.name }}</span>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="CMS Pbj Code" field="pbjOrganization.cmsPbjCode" [filterable]="true" width="200" media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">{{ dataItem.pbjOrganization.cmsPbjCode }}</span>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Company" field="pbjOrganization.companyName" [filterable]="true" width="200" media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">{{ dataItem.pbjOrganization.companyName }}</span>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Address" field="pbjOrganization.address" [filterable]="true" width="200" media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">{{ dataItem.pbjOrganization.address }}</span>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="City" field="pbjOrganization.city" [filterable]="true" width="200" media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">{{ dataItem.pbjOrganization.city }}</span>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="State" field="pbjOrganization.state" [filterable]="true" width="90" media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">{{ dataItem.pbjOrganization.state }}</span>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Zip" field="pbjOrganization.zip" [filterable]="true" width="200" media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="right-align">{{ dataItem.pbjOrganization.zip }}</span>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Phone" field="pbjOrganization.phone" [filterable]="true" width="200" media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="right-align">{{ dataItem.pbjOrganization.phone }}</span>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Company Code" field="pbjOrganization.companyCode" [filterable]="true" width="200" media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">{{ dataItem.pbjOrganization.companyCode }}</span>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Group" field="pbjOrganization.group" [filterable]="true" width="200" media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">{{ dataItem.pbjOrganization.group }}</span>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Type" field="pbjOrganization.type" [filterable]="true" width="200" media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">{{ dataItem.pbjOrganization.type }}</span>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Other Name" field="pbjOrganization.otherName" [filterable]="true" width="200" media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">{{ dataItem.pbjOrganization.otherName }}</span>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Telepunch Offset" field="pbjOrganization.telepunchOffset" [filterable]="true" width="200" media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="right-align">{{ dataItem.telepunchOffset.fieldValue }}</span>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-number-filter>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Time Zone" field="pbjOrganization.timezone" [filterable]="true" width="180" media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="right-align">{{ dataItem.pbjOrganization.timezone  | timezoneDisplayName:organizationTimezones}}</span>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Time Zone Offset" field="pbjOrganization.timezoneOffset" [filterable]="true" width="200" media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="right-align">{{ dataItem.timezoneOffset.fieldValue }}</span>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-number-filter>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Payroll Code" field="pbjOrganization.payrollCode" [filterable]="true" width="200" media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">{{ dataItem.pbjOrganization.payrollCode }}</span>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column  *ngIf="nextgenPhasedRollout && isNextgenPayrollEnabled" title="Payroll App Status" field="pbjOrganization.payrollAppStatus.name" [filterable]="true" width="200" media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">{{ dataItem.pbjOrganization.payrollAppStatus.name }}</span>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column  *ngIf="nextgenPhasedRollout && isNextgenPayrollEnabled" title="Payroll App Effective Date" field="pbjOrganization.payrollAppEffectiveDate" [filterable]="true" filter="date" width="200" media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">{{ dataItem.pbjOrganization.payrollAppEffectiveDate | amDateFormat: appConfig.dateFormat}}</span>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-date-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Paygroup" field="pbjOrganization.payGroup" [filterable]="true" width="200" media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">{{ dataItem.pbjOrganization.payGroup }}</span>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
        </kendo-grid-column>
        <!--   possibly will be used later
        <kendo-grid-column title="Updated By" field="lastUpdateUsername" [filterable]="true" width="200" media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">{{ dataItem.lastUpdateUsername }}</span>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Last Updated" field="lastUpdateDate" filter="date" [filterable]="true" width="200" media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align" *ngIf="dataItem.lastUpdateDate">{{ dataItem.lastUpdateDate | amDateFormat: appConfig.dateTimeFormat }}</span>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-date-filter>
          </ng-template>
        </kendo-grid-column>
        -->
      </kendo-grid>
    </form>

  </div>

</slx-spinner>
