import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import 'rxjs/add/observable/forkJoin';
import { Assert } from '../../../framework/index';
import { SoDashboardCounters, TaDashboardCounters, AlertConfig, DashBoardAlertsModel, DashboardAppAlertConfig, AppAlertConfig, DashboardAppTypeEnum, AlertClassEnum } from '../../models/index';
import { ModalService } from './../../../common/services/modal/modal.service';
import { ApplicationDashboardApiService } from './application-dashboard-api.service';
import { NotificationsService } from '../../../core/components/index';
import { ApplicationsConfigDialogComponent } from '../../components/applications-dashboard/applications-config-dialog/applications-config-dialog.component';
import { ApplicationService } from '../application/application.service';
import { SessionService } from '../../../core/services/session/session.service';
var ApplicationList;
(function (ApplicationList) {
    ApplicationList["Scheduler"] = "Schedule Optimizer";
    ApplicationList["Time"] = "Time & Attendance";
    ApplicationList["HR"] = "HRMS";
    ApplicationList["Reports"] = "Reports";
    ApplicationList["PBJ"] = "PBJ";
    ApplicationList["More"] = "More Apps...";
})(ApplicationList || (ApplicationList = {}));
var ApplicationDashboardManagementService = /** @class */ (function () {
    function ApplicationDashboardManagementService(apiService, notificationService, modalService, applicationService, sessionService) {
        this.notificationService = notificationService;
        this.modalService = modalService;
        this.applicationService = applicationService;
        this.sessionService = sessionService;
        this.appsData = [];
        this.loading$ = new Subject();
        this.dashboardApps$ = new ReplaySubject(1);
        this.constOrgId = 1;
        this.apiService = apiService;
        this.countersLoaded$ = new Subject();
        this.appNames = {
            so: 'Scheduler',
            ta: 'Time'
        };
    }
    ApplicationDashboardManagementService.prototype.destroy = function () {
        this.loading$.complete();
    };
    ApplicationDashboardManagementService.prototype.subscribeToLoading = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loading$.subscribe(callback);
    };
    ApplicationDashboardManagementService.prototype.modifyAlerts = function (model) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 2, 3]);
                        this.loading$.next(true);
                        model.forEach(function (x) { return x.orgLevelId = _this.constOrgId; });
                        return [4 /*yield*/, this.apiService.modifyAlerts(model)];
                    case 1:
                        _a.sent();
                        this.notificationService.success('Alert Saved', 'Alert saved successfully');
                        return [3 /*break*/, 3];
                    case 2:
                        this.loading$.next(false);
                        return [7 /*endfinally*/];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ApplicationDashboardManagementService.prototype.loadAppCounters = function (applications, orgLevel) {
        var _this = this;
        if (applications && applications.length > 0) {
            this.orgLevel = orgLevel;
            var counters = _.reduce(applications, function (accumulator, app) {
                if (app.loadCounters) {
                    accumulator.push(_this.loadCounter(app));
                }
                return accumulator;
            }, []);
            this.resolveCounters(counters);
        }
    };
    ApplicationDashboardManagementService.prototype.subscribeToCountersLoaded = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.countersLoaded$.subscribe(callback);
    };
    ApplicationDashboardManagementService.prototype.countersLoaded = function (counters) {
        this.countersLoaded$.next(counters);
    };
    ApplicationDashboardManagementService.prototype.resolveCounters = function (promises) {
        var _this = this;
        var subscription = Observable.forkJoin(promises)
            .subscribe(function (counters) {
            _this.countersLoaded(_.fromPairs(counters));
            subscription.unsubscribe();
        });
    };
    ApplicationDashboardManagementService.prototype.alertsData = function () {
        this.dashboardAlerts = {
            soAlerts: {
                appId: DashboardAppTypeEnum.soAlerts,
                alerts: [{
                        alertId: 1,
                        alertName: 'Open Shift Requests',
                        userAppAlertId: 0,
                        link: 'open_shift_management',
                        icon: 'far fa-address-card',
                        displayOrder: 1,
                        counter: 0,
                        visible: true,
                        loginId: 'system',
                        lastModifiedDate: new Date()
                    },
                    {
                        alertId: 2,
                        userAppAlertId: 0,
                        alertName: 'Emp Projected Overtime',
                        link: 'so_console',
                        icon: 'far fa-clock',
                        displayOrder: 2,
                        counter: 0,
                        visible: true,
                        loginId: 'system',
                        lastModifiedDate: new Date()
                    },
                    {
                        alertId: 3,
                        userAppAlertId: 0,
                        alertName: 'PTO Req',
                        link: 'leave_requests',
                        icon: 'fa fa-user-times',
                        displayOrder: 3,
                        counter: 0,
                        visible: true,
                        loginId: 'system',
                        lastModifiedDate: new Date()
                    }
                ]
            },
            taAlerts: {
                appId: DashboardAppTypeEnum.taAlerts,
                alerts: [
                    {
                        alertId: 1,
                        userAppAlertId: 0,
                        alertName: 'Missing Punch',
                        link: 'daily_punches',
                        icon: 'far fa-calendar-times',
                        displayOrder: 2,
                        counter: 0,
                        visible: true,
                        loginId: 'system',
                        lastModifiedDate: new Date()
                    },
                    {
                        alertId: 2,
                        userAppAlertId: 0,
                        alertName: 'Overtime Approvals',
                        link: 'overtime_approvals',
                        icon: 'far fa-calendar-plus',
                        displayOrder: 3,
                        counter: 0,
                        visible: true,
                        loginId: 'system',
                        lastModifiedDate: new Date()
                    },
                    {
                        alertId: 3,
                        userAppAlertId: 0,
                        alertName: 'Exceptions',
                        link: 'exception_console',
                        icon: 'far fa-clock',
                        displayOrder: 4,
                        counter: 0,
                        visible: true,
                        loginId: 'system',
                        lastModifiedDate: new Date()
                    },
                    {
                        alertId: 4,
                        userAppAlertId: 0,
                        alertName: 'Punch Requests',
                        link: 'daily_punches',
                        icon: 'far fa-calendar-plus',
                        displayOrder: 1,
                        counter: 0,
                        visible: true,
                        loginId: 'system',
                        lastModifiedDate: new Date()
                    }
                ]
            },
            lmAlerts: {
                appId: DashboardAppTypeEnum.lmAlerts,
                alerts: [{
                        alertId: 1,
                        userAppAlertId: 0,
                        alertName: 'On Leave',
                        link: 'OnLeave',
                        icon: 'far fa-calendar-times',
                        displayOrder: 1,
                        counter: 0,
                        visible: true,
                        loginId: 'system',
                        lastModifiedDate: new Date()
                    },
                    {
                        alertId: 2,
                        userAppAlertId: 0,
                        alertName: 'Returning',
                        link: 'Returning',
                        icon: 'far fa-calendar-plus',
                        displayOrder: 2,
                        counter: 0,
                        visible: true,
                        loginId: 'system',
                        lastModifiedDate: new Date()
                    },
                    {
                        alertId: 3,
                        userAppAlertId: 0,
                        alertName: 'Upcoming',
                        link: 'Upcoming',
                        icon: 'far fa-clock',
                        displayOrder: 3,
                        counter: 0,
                        visible: true,
                        loginId: 'system',
                        lastModifiedDate: new Date()
                    },
                    {
                        alertId: 4,
                        userAppAlertId: 0,
                        alertName: 'Incomplete',
                        link: 'Incomplete',
                        icon: 'far fa-calendar-plus',
                        displayOrder: 4,
                        counter: 0,
                        visible: true,
                        loginId: 'system',
                        lastModifiedDate: new Date()
                    },
                    {
                        alertId: 5,
                        userAppAlertId: 0,
                        alertName: 'Past Due',
                        link: 'PastDue',
                        icon: 'far fa-clock',
                        displayOrder: 5,
                        counter: 0,
                        visible: true,
                        loginId: 'system',
                        lastModifiedDate: new Date()
                    }]
            }
        };
    };
    ApplicationDashboardManagementService.prototype.loadCounter = function (app) {
        var promise = null;
        switch (app.name) {
            case this.appNames.so:
                promise = this.apiService.getSoCounters(this.orgLevel.id);
                break;
            case this.appNames.ta:
                promise = this.apiService.getTaCounters(this.orgLevel.id);
                break;
            default:
                promise = Promise.resolve(null);
        }
        return promise.then(function (res) { return [app.name, res]; });
    };
    ApplicationDashboardManagementService.prototype.getDashboardAppAlerts = function (appId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 2, 3]);
                        this.alertsData();
                        this.loading$.next(true);
                        return [4 /*yield*/, this.apiService.getDashboardUserConfig(this.constOrgId)
                                .then(function (res) {
                                var alertdefinition = new AppAlertConfig();
                                switch (appId) {
                                    case DashboardAppTypeEnum.soAlerts:
                                        alertdefinition = { appId: appId, alerts: _this.UpdateUserAlertConfigData(res.soAlerts.alerts, _this.dashboardAlerts.soAlerts.alerts) };
                                        break;
                                    case DashboardAppTypeEnum.lmAlerts:
                                        alertdefinition = { appId: appId, alerts: _this.UpdateUserAlertConfigData(res.lmAlerts.alerts, _this.dashboardAlerts.lmAlerts.alerts) };
                                        break;
                                    case DashboardAppTypeEnum.taAlerts:
                                        alertdefinition = { appId: appId, alerts: _this.UpdateUserAlertConfigData(res.taAlerts.alerts, _this.dashboardAlerts.taAlerts.alerts) };
                                        break;
                                    default:
                                        alertdefinition.alerts = [];
                                        break;
                                }
                                _.forEach(alertdefinition.alerts, function (a) {
                                    var classId = _.toInteger("" + a.alertId + appId);
                                    a.icon = AlertClassEnum[classId];
                                });
                                return _.sortBy(alertdefinition.alerts, function (rec) { return rec.displayOrder; });
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        this.loading$.next(false);
                        return [7 /*endfinally*/];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ApplicationDashboardManagementService.prototype.UpdateUserAlertConfigData = function (alertdefinition, alertData) {
        _.forEach(alertData, function (record) {
            _.forEach(alertdefinition, function (alert) {
                if (record.alertId == alert.alertId) {
                    record.userAppAlertId = alert.userAppAlertId;
                    record.icon = alert.icon;
                    record.loginId = alert.loginId;
                    record.displayOrder = alert.displayOrder;
                    record.visible = alert.visible;
                }
            });
        });
        return alertData;
    };
    ApplicationDashboardManagementService.prototype.showConfigDialog = function () {
        var _this = this;
        this.appConfigWindowOpened = true;
        ApplicationsConfigDialogComponent.showDialog(this.modalService, function (hasChanges) {
            _this.appConfigWindowOpened = false;
            if (hasChanges) {
                _this.loadApps(true);
            }
        });
    };
    ApplicationDashboardManagementService.prototype.loadApps = function (updateCacheForced) {
        if (updateCacheForced === void 0) { updateCacheForced = false; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 2, 3]);
                        this.loading$.next(true);
                        return [4 /*yield*/, this.apiService.loadApps(updateCacheForced).then(function (res) {
                                var apps = res;
                                if (!_this.appConfigWindowOpened) {
                                    _this.dashboardApps$.next(apps);
                                }
                                return apps;
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        this.loading$.next(false);
                        return [7 /*endfinally*/];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ApplicationDashboardManagementService.prototype.modifyApps = function (model) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 2, 3]);
                        this.loading$.next(true);
                        return [4 /*yield*/, this.apiService.modifyApps(model)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this.loadApps(true);
                        this.loading$.next(false);
                        return [7 /*endfinally*/];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return ApplicationDashboardManagementService;
}());
export { ApplicationDashboardManagementService };
