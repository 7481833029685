import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { appConfig } from '../../../../app.config';
import { ModalService, StateManagementService } from '../../../../common/services/index';
import { ShiftReplacementApiService, ShiftReplacementManagementService } from '../../../services/index';
import { destroyService, mutableSelect } from '../../../../core/decorators/index';
import { ShiftReplacementSettings, ShiftReplacementInfo, ShiftEligibleEmployee, ShiftSmsNotificationResponse, ShiftReplacementRequest, ShiftNotificationRequest, ShiftNotificationResponse, ShiftReplacementSms } from '../../../models/index';
import { ShiftReplacementGridComponent } from '../shift-replacement-grid/shift-replacement-grid.component';
import { screenUtils } from '../../../../common/utils/index';
import { PollingPeriodService } from '../../../../core/services/polling-period/polling-period.service';
import { UserActivityService } from '../../../../core/services/user-activity/user-activity.service';
import { unsubscribe } from '../../../../core/decorators/index';
import { StateResetTypes } from '../../../../core/models/index';
import { TokenValidityService } from '../../../../core/services/index';
import { NotificationsService } from '../../../../core/components';
import { ScheduleCycleHelperService } from './../../../../../app/organization';
import { Subject } from 'rxjs';
var ShiftReplacementSelectorComponent = /** @class */ (function () {
    function ShiftReplacementSelectorComponent(shiftReplacementApiService, modalService, userActivityService, stateManagement, tokenValidity, shiftManagementService, notificationsService, scheduleCycleHelperService) {
        var _this = this;
        this.shiftReplacementApiService = shiftReplacementApiService;
        this.modalService = modalService;
        this.userActivityService = userActivityService;
        this.stateManagement = stateManagement;
        this.tokenValidity = tokenValidity;
        this.notificationsService = notificationsService;
        this.scheduleCycleHelperService = scheduleCycleHelperService;
        this.filtersControlKey = 'filter';
        this.scheduleCycleData$ = new Subject();
        this.applicationHeartbeatService = new PollingPeriodService(this.userActivityService, this.tokenValidity);
        this.changedAbsences = new EventEmitter();
        this.shiftManagementService = shiftManagementService;
        this.sendSmsModeOn = false;
        this.state = {
            isLoading: true
        };
        this.shiftManagementService.init();
        this.poolingSubscription = this.applicationHeartbeatService.onHeartbeat.subscribe(function () {
            _this.refreshNotificationResponses();
        });
        this.contextChangeSubscription = this.stateManagement.onComponentActiveStateChanged$.subscribe(function (key) {
            _this.stateManagement.loadData();
        });
        this.initSubscription = this.stateManagement.onInit$.subscribe(function () {
            _this.restoreFilters();
        });
        this.changedSmsModeSubscription = this.shiftManagementService.subscribeToChangeSmsMode(function (smsModeOn) {
            _this.sendSmsModeOn = smsModeOn;
        });
        this.sendSmsSubscription = this.shiftManagementService
            .subscribeToSendSms(function (smsData) {
            _this.sendSms(smsData);
        });
        this.orgLevelSubscripion = this.orgLevel$.subscribe(function (selectedOrgLevel) {
            _this.orgLevel = selectedOrgLevel;
        });
        this.scheduleCycleDataSubscription = this.scheduleCycleData$.subscribe(function (cycleData) {
            var startDate = _this.scheduleCycleHelperService.isDateWithinRange(cycleData, _this.settings.request.date.toString()).startDate;
            if (!_.isUndefined(startDate)) {
                _this.getShiftEligibleEmployees(_this.settings.request, startDate);
            }
        });
    }
    Object.defineProperty(ShiftReplacementSelectorComponent.prototype, "initialScheduleAbsences", {
        set: function (value) {
            if (_.isArray(value) && _.size(value) > 0) {
                this.scheduleAbsences = value;
                this.scheduleAbsence = this.settings.request.scheduleAbsence;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShiftReplacementSelectorComponent.prototype, "hasAbcenses", {
        get: function () {
            return _.isArray(this.scheduleAbsences) && _.size(this.scheduleAbsences) > 0;
        },
        enumerable: true,
        configurable: true
    });
    ShiftReplacementSelectorComponent.prototype.ngOnInit = function () {
        this.screenUtils = screenUtils;
        this.applicationHeartbeatService.listen(appConfig.notifyPoolingInterval);
        this.stateManagement.init('ShiftReplacementSelectorComponent');
    };
    ShiftReplacementSelectorComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
        this.applicationHeartbeatService.stop();
    };
    ShiftReplacementSelectorComponent.prototype.onColumnsChanged = function () {
        this.replacementGrid.onSettingsChanged();
    };
    ShiftReplacementSelectorComponent.prototype.onSettingsChanged = function (settings) {
        this.loadReplacementInfo();
    };
    ShiftReplacementSelectorComponent.prototype.onAbsenceChanged = function (absence) {
        this.shiftManagementService.replaceEmployee.scheduleAbsence = absence;
        this.shiftManagementService.replaceEmployee.absenceCode = absence.code;
        this.changedAbsences.emit(absence);
    };
    ShiftReplacementSelectorComponent.prototype.onRefreshByCheck = function () {
        this.saveFilters();
        this.replacementGrid.refresh();
    };
    ShiftReplacementSelectorComponent.prototype.onTurnOnSmsMode = function () {
        this.shiftManagementService.changeSmsMode(true);
    };
    ShiftReplacementSelectorComponent.prototype.sendSms = function (smsData) {
        var _this = this;
        var req = new ShiftNotificationRequest();
        req.employeeIds = _.map(smsData.recipients, function (record) { return record.employee.id; });
        req.positionId = this.settings.request.positionId;
        req.date = this.settings.request.date;
        req.unitId = this.settings.request.unitId;
        req.text = smsData.message;
        this.state.isLoading = true;
        this.shiftReplacementApiService
            .sendSms(this.settings.request.shiftId, req)
            .then(function (res) {
            _this.state.isLoading = false;
            _.forEach(smsData.recipients, function (record) {
                record.isSmsSent = true;
                record.smsResponse = new ShiftSmsNotificationResponse();
                record.smsResponse.messageText = smsData.message;
                record.smsResponse.dateSent = new Date();
                _this.notificationsService.success('SMS message sent', 'SMS message was sent successfully');
            });
            _this.shiftManagementService.changeSmsMode(false);
        })
            .catch(function () {
            _this.state.isLoading = false;
        });
    };
    /* public onPhoneCall(): void {
      if (this.isAnyEmployeeRowSelected) {
        let employee: ShiftEligibleEmployee = this.selectedRowRecords[0];
        ShiftReplacementPhoneCallComponent.openDialog(this.modalService, employee, (result: boolean, phoneCall: ShiftPhoneCall) => {
          if (result) {
            this.shiftReplacementApiService.addPhoneCallInfo(this.settings.request.shiftId, employee.employee.id, this.settings.request.positionId, this.settings.request.date, phoneCall).then((res: any) => {
              //TBD
              employee.phoneCall = phoneCall;
              employee.isPhoneCalled = true;
            });
          }
        });
      }
    } */
    /*  public onPostText(): void {
       if (this.isAnyEmployeeSelected) {
         ShiftReplacementMsgComponent.openDialog(this.modalService, 'Open Shift', this.generatePostText(), (result: boolean, textMsg: string) => {
           if (result) {
             let req: ShiftNotificationRequest = new ShiftNotificationRequest();
             req.employeeIds = _.map(this.selectedRecords, (record: ShiftEligibleEmployee) => { return record.employee.id; });
             req.positionId = this.settings.request.positionId;
             req.date = this.settings.request.date;
             req.text = textMsg;
             this.shiftReplacementApiService.sendAppNotification(this.settings.request.shiftId, req)
               .then((res: any) => {
                 //TBD
                 _.forEach(this.selectedRecords, (record: ShiftEligibleEmployee) => {
                   record.isAppNotificationSent = true;
                 });
   
               });
           }
         });
       }
     } */
    ShiftReplacementSelectorComponent.prototype.refreshNotificationResponses = function () {
        var _this = this;
        if (!this.settings) {
            return;
        }
        this.shiftReplacementApiService.getShiftNotifications(this.settings.request.shiftId, this.settings.request.positionId, this.settings.request.date)
            .then(function (responses) {
            _this.mapResponsesToRecords(_this.shiftReplacementInfo, responses);
        });
    };
    ShiftReplacementSelectorComponent.prototype.mapResponsesToRecords = function (shiftReplacementInfo, responses) {
        var groupByEmp = _.groupBy(shiftReplacementInfo.records, function (record) {
            return record.employee.id;
        });
        _.forEach(responses, function (response) {
            var group = groupByEmp[response.employeeId];
            if (group) {
                _.forEach(group, function (emp) {
                    emp.appResponse = response.appResponse;
                    emp.smsResponse = response.smsResponse;
                });
            }
        });
    };
    Object.defineProperty(ShiftReplacementSelectorComponent.prototype, "smsText", {
        get: function () {
            var req = _.get(this.settings, 'request') || {};
            return "Open Shift opportunity for position:" +
                (req.positionName + ", " + moment(req.date).format(appConfig.dateFormat) + " (" + req.shiftName + ") in " + req.unitName + " Unit at " + req.organizationName + ".");
        },
        enumerable: true,
        configurable: true
    });
    ShiftReplacementSelectorComponent.prototype.loadReplacementInfo = function () {
        var _this = this;
        if (!this.settings) {
            return;
        }
        this.state.isLoading = true;
        this.scheduleCycleHelperService.getScheduleCycles(this.orgLevel.id)
            .then(function (scheduleCycleData) {
            _this.scheduleCycleData$.next(scheduleCycleData);
        });
    };
    ShiftReplacementSelectorComponent.prototype.getShiftEligibleEmployees = function (request, startDate) {
        var _this = this;
        this.shiftReplacementApiService.getShiftEligibleEmployees(request, startDate)
            .then(function (shiftReplacementInfo) {
            _this.shiftReplacementInfo = shiftReplacementInfo;
            _this.canSendSms = shiftReplacementInfo.canSendSms;
            _this.state.isLoading = false;
            _this.stateManagement.loadedData({});
        });
    };
    ShiftReplacementSelectorComponent.prototype.saveFilters = function () {
        this.stateManagement.setControlState(this.filtersControlKey, {
            value: {
                showDayOffEmployees: this.settings.request.showDayOffEmployees,
                showAgencyEmployees: this.settings.request.showAgencyEmployees,
                showAvailabilityRecords: this.settings.request.showAvailabilityRecords,
                showEmployeesOtherShifts: this.settings.request.showEmployeesOtherShifts,
            }
        }, StateResetTypes.None);
    };
    ShiftReplacementSelectorComponent.prototype.restoreFilters = function () {
        var state = this.stateManagement.getControlState(this.filtersControlKey);
        if (state.value) {
            this.settings.request.showDayOffEmployees = state.value.showDayOffEmployees;
            this.settings.request.showAgencyEmployees = state.value.showAgencyEmployees;
            this.settings.request.showAvailabilityRecords = state.value.showAvailabilityRecords;
            this.settings.request.showEmployeesOtherShifts = state.value.showEmployeesOtherShifts;
        }
        this.loadReplacementInfo();
    };
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", ShiftReplacementManagementService)
    ], ShiftReplacementSelectorComponent.prototype, "shiftManagementService", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftReplacementSelectorComponent.prototype, "poolingSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftReplacementSelectorComponent.prototype, "contextChangeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftReplacementSelectorComponent.prototype, "initSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftReplacementSelectorComponent.prototype, "changedSmsModeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftReplacementSelectorComponent.prototype, "sendSmsSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftReplacementSelectorComponent.prototype, "orgLevelSubscripion", void 0);
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], ShiftReplacementSelectorComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftReplacementSelectorComponent.prototype, "scheduleCycleDataSubscription", void 0);
    return ShiftReplacementSelectorComponent;
}());
export { ShiftReplacementSelectorComponent };
