import { NotificationMessage } from './../../../../app.messages';
import { ErrorHandlingService } from './../../../../core/services/error-handling/error-handling.service';
import { Component, OnInit, OnDestroy, Input, Provider, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, Output, ViewChildren, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/debounceTime';
import { GroupResult, orderBy, groupBy, process, State, aggregateBy, SortDescriptor } from '@progress/kendo-data-query';
import { NgForm } from '@angular/forms';
import {
  GridComponent,
  GridDataResult,
  DataStateChangeEvent, EditEvent
} from '@progress/kendo-angular-grid';


import { appConfig, IApplicationConfig } from '../../../../app.config';
import { KendoGridStateHelper, saveEvent, removeEvent, cancelEvent, DialogOptions } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { ColumnManagementService, ColumnsChangedEvent, ModalService } from '../../../../common/services/index';
import { DailyPunchesManagementService, DailyTimecardManagementService, PunchesView, TimecardsCheckRightsService } from '../../../services/index';
import {
  LinePunch, PunchStatus, PunchLogRequest, PunchDisplay, PunchSource, punchWrapper, DailyLinePunchActions,
  TimeCardDisplayOptions, TimecardAddCommentsReq, TimeCardModel } from '../../../models/index';
import { LookupService } from '../../../../organization/services/index';
import { Lookup, PunchType, TimeclockDefinition, Position, LookupType } from '../../../../organization/models/index';
import { PunchLogsDialogComponent } from '../punch-logs-dialog/punch-logs-dialog.component';
import { GeoPunchDialogComponent } from '../geo-punch-dialog/geo-punch-dialog.component';
import { GeoPunchDialogOptions } from '../../../models/index';

import { TimecardPredefinedComment } from '../../../../organization/models/index';
import { TimecardAddCommentsDialogComponent } from '../../punches/add-comments-dialog/add-comments-dialog.component';
import { AppSettingsManageService } from '../../../../app-settings/services/index';
import { TimecardMobile } from '../..';
import { time } from 'console';
import { ConfirmDialogComponent, ConfirmOptions } from '../../../../common/index';
import { emit } from 'process';
import { dateTimeUtils } from '../../../../common/utils/index';
@Component({
  moduleId: module.id,
  selector: 'slx-daily-line-punch-grid',
  templateUrl: 'daily-line-punch-grid.component.html',
  styleUrls: ['daily-line-punch-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DailyLinePunchGridComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  public hideSettingsButton: boolean;
  @Input()
  public employeeId: number;
  @Input()
  public employeeName: string;
  @Input()
  public defaultPunchTime: Date;
  @Input()
  public minPunchDate: Date;
  @Input()
  public maxPunchDate: Date;
  @Input()
  public groupName: string = 'LinePunches';
  @Input()
  public userActions: DailyLinePunchActions;
  @Input()
  public displayOptions: TimeCardDisplayOptions;
  @Input()
  public isTimecardLocked: boolean;
  @Input()
  public comment: string;


  public appConfig: IApplicationConfig;
  public savedRecords: LinePunch[];
  public records: punchWrapper[];
  public removedPunches: punchWrapper[] = [];
  public gridState: KendoGridStateHelper<punchWrapper>;
  public hasChanges: boolean;
  public punchTypesLookup: Lookup;
  public punchTypesInLookup: Lookup;
  public punchTypesOutLookup: Lookup;
  public invalidLoginPunchTypesLookup: Lookup;
  public positionsLookup: Lookup;
  public positionsOriginLookup: Lookup;
  //public toolbarPosition: string = 'top';
  public canEdit: boolean;
  public hasInMissingPunch: boolean;
  public hasOutMissingPunch: boolean;
  public punchesCount: number;
  public filteredPunchesCount: number;

  public unknown = PunchDisplay.Unknown;
  public edited = PunchDisplay.Edited;
  public validPunch = PunchDisplay.ValidPunch;
  public validEdited = PunchDisplay.ValidEdited;
  public schedule = PunchDisplay.Schedule;
  public scheduleExempt = PunchDisplay.ScheduleExempt;
  public punchRequest = PunchDisplay.PunchRequest;
  public invalidPunch = PunchDisplay.InvalidPunch;
  public invalidLogin  = PunchDisplay.InvalidLogin;
  
  public overlap = PunchDisplay.Overlap;
  public hasOverlapPunches: boolean = false;
  public overlapIndex: number = -1;

  public get rowInEditing(): boolean {
    return this.gridState.editedRowIndex !== undefined;
  }
  public get hasRemoved(): boolean {
    return this.removedPunches.length > 0;
  }

  public get isMobile(): boolean {
    return (screen.width <= appConfig.mobileMaxWidth);
  }

  private timeclocksLookup: Lookup;

  @unsubscribe()
  public loadSubscription: Subscription;
  @unsubscribe()
  private loadStateSubscription: Subscription;
  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private gridSelectionSubscription: Subscription;
  @unsubscribe()
  private onLoadedSubscription: Subscription;
  @unsubscribe()
  private gridSaveSubscription: Subscription;
  @unsubscribe()
  private gridRemoveSubscription: Subscription;
  @unsubscribe()
  private changesSubscription: Subscription;
  @unsubscribe()
  private startEditSubscription: Subscription;
  @unsubscribe()
  private cancelEditSubscription: Subscription;
  @unsubscribe()
  private changedEditSubscription: Subscription;
  @unsubscribe()
  private onFilterAppliedSubscription: Subscription;
  @unsubscribe()
  private onOverlapPuchesActionSubscription: Subscription;
 
  @ViewChild('kendoGrid', {static: true})
  private grid: GridComponent;
  private selectedRecords: punchWrapper[];

  private defaultInType: PunchType;
  private defaultOutType: PunchType;
  private firstLoad: boolean;
  private employeeLookupsFirstLoadStarted: boolean;
  private timecardPredefinedComments: Lookup<TimecardPredefinedComment>;
  public requireTimeclockForPunches: boolean;
  public preventOverlappingPunches: boolean;
  public isPaidRestBreakEnabled:boolean = false;

  constructor(private managementService: DailyPunchesManagementService, private changeDetector: ChangeDetectorRef,
    private lookupService: LookupService, private columnManagementService: ColumnManagementService,
    private modalService: ModalService, private timecardsCheckService: TimecardsCheckRightsService,
    private appSettingsManageService: AppSettingsManageService,
    private errorService: ErrorHandlingService,
    public dailyTimeCardManagementService: DailyTimecardManagementService) {
    this.gridState = new KendoGridStateHelper<punchWrapper>();
    this.gridState.state.sort = [{ field: 'punch.time', dir: 'asc' }];
    this.firstLoad = true;
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.columnManagementService.init('DailyLinePunchGridComponent');
    this.columnManagementService.initGroup(this.groupName, 6);

    this.appSettingsManageService.getAppServerConfig()
    .then((config) => {
      this.requireTimeclockForPunches = config.requireTimeclockForPunches;
      this.preventOverlappingPunches = config.preventOverlappingPunches;
    });

    this.managementService.isPaidRestBreakEnabled$.subscribe((isPaidRestBreakEnabled:any)=>{
      this.isPaidRestBreakEnabled = isPaidRestBreakEnabled;
      this.loadPunchTypes(isPaidRestBreakEnabled);
    })

    this.dailyTimeCardManagementService.isPaidRestBreakEnabled$.subscribe((isPaidRestBreakEnabled:any)=>{
      this.isPaidRestBreakEnabled = isPaidRestBreakEnabled;
      this.loadPunchTypes(isPaidRestBreakEnabled);
    });

    if (!this.employeeLookupsFirstLoadStarted) {
      this.loadEmpLookups();
    }
    this.onLoadedSubscription = this.managementService.onLoadedPairs$.subscribe(
      (records: LinePunch[]) => {
        this.savedRecords = records;
      });

    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });

    this.changesSubscription = this.columnManagementService.columnsChanged$.filter((event: ColumnsChangedEvent) => event.group === this.groupName)
      .debounceTime(500)
      .subscribe((event: ColumnsChangedEvent) => {
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });

    // save punches from Save/Recal
    this.onOverlapPuchesActionSubscription = this.dailyTimeCardManagementService.overlapingPunchesAction$.subscribe((event: any) => {
      if (this.preventOverlappingPunches) {
        this.records = this.sortByTime(this.records);
        let res = this.hasMatchedPair(this.records, null);
      
        if (this.gridState.view && this.gridState.view.data && this.gridState.view.data.length > 0 && res.hasMatchedPair) {
          _.forEach(res.matchedPair, (pair:{inpunch: punchWrapper, outpunch: punchWrapper}) => {
            this.performOverlapCheck(pair, true);
          });
        }
        else {
          this.dailyTimeCardManagementService.overlapPunchesDesc(false);
        }
      } else {
        this.dailyTimeCardManagementService.overlapPunchesDesc(false);
      }
    });

    this.gridSelectionSubscription = this.gridState.onSelectionChanged.subscribe((v: punchWrapper[]): void => {
      this.selectedRecords = v;
    });

    this.gridSaveSubscription = this.gridState.onSave$.subscribe((event: saveEvent<punchWrapper>) => {
      if (event.isNew) {
        this.addPunch(event.dataItem);
      } else {
        // this.puchdetails = event.dataItem;
        this.updatePunch(event.dataItem);
      }
    });

    this.startEditSubscription = this.gridState.onEdit$.subscribe((item: punchWrapper) => {
      this.managementService.startChanges();
    });

    this.cancelEditSubscription = this.gridState.onCancel$.subscribe((event: cancelEvent<punchWrapper>) => {
      //_.remove(this.records, (w: punchWrapper) => w.isNew);
      this.refreshGrid();
      this.validatePairs(this.records);
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
      this.managementService.cancelChanges();
    });

    this.gridRemoveSubscription = this.gridState.onRemove$.subscribe((event: removeEvent<punchWrapper>) => {
      this.removePunch(event.dataItem);
    });

    this.changedEditSubscription = this.managementService.canEditChanged$.subscribe((canEdit: boolean) => {
        this.canEdit = canEdit;
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    });

    this.loadSubscription = this.managementService.onLoaded$.subscribe((model: any) => {
       this.hasOverlapPunches = false;
        this.dailyTimeCardManagementService.setHasOverlap(false);
    });

    this.dailyTimeCardManagementService.setHasOverlapToTC$.subscribe((setHasOverlapToTC: boolean) => {
      this.hasOverlapPunches = false;
    });

    this.onFilterAppliedSubscription = this.managementService.onFilterApplied$.subscribe(
      (records: LinePunch[]) => {
      this.records = this.transformPunchesPairs(records);
      this.hasChanges = !!_.find(this.savedRecords, (p: LinePunch) => p.isDirty);
      this.refreshGrid();
      if (records && this.firstLoad) {
        this.firstLoad = false;
        if (!this.hasChanges) {
          //first punch to edit
          let firstInvalidIndex = _.findIndex(this.gridState.view.data, (val: punchWrapper) => {
            return val.punch.punchStatus !== PunchStatus.Valid;
          });
          if (firstInvalidIndex >= 0 && this.userActions.canEditTimecards && this.userActions.canSave && this.canEdit) {
            let event: EditEvent = {
              sender: this.grid,
              rowIndex: firstInvalidIndex,
              dataItem: this.gridState.view.data[firstInvalidIndex],
              isNew: false
            };
            this.editHandler(event);
          }
        }
      }
      this.validatePairs(this.records);
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });
  }

  public loadPunchTypes(isPaidRestBreakEnabled:boolean = false){
    this.lookupService.getLookup({ lookupType: 'punchTypes' })
    .then((lookup: Lookup) => {
      this.punchTypesLookup = lookup;
      this.invalidLoginPunchTypesLookup = _.cloneDeep(lookup);
      this.invalidLoginPunchTypesLookup.items.splice(0, 0,{"id":999,"name":"NA","description":"","previousPunchTypeId":null,"canBeFirst":true,"canBeLast":false,"isIn":true,"isOut":false,"isBreak":false,"isLunch":false,"isTraining":false});
      this.invalidLoginPunchTypesLookup.items = isPaidRestBreakEnabled ? this.invalidLoginPunchTypesLookup.items : _.filter(this.invalidLoginPunchTypesLookup.items, (type: PunchType) => !(type.name === "in from paid break" || type.name === "out to paid break"));
      this.punchTypesInLookup = new Lookup();
      this.punchTypesInLookup.titleField = lookup.titleField;
      this.punchTypesInLookup.valueField = lookup.valueField;
      this.punchTypesInLookup.items = isPaidRestBreakEnabled ? _.filter(lookup.items, (type: PunchType) => (type.isIn)) :  _.filter(lookup.items, (type: PunchType) => (type.isIn && type.name != "in from paid break"));
      this.defaultInType = _.find(this.punchTypesInLookup.items, (type: PunchType) => type.canBeFirst);
      if (!this.defaultInType) {
        this.defaultInType = _.first(this.punchTypesInLookup.items);
      }
      this.punchTypesOutLookup = new Lookup();
      this.punchTypesOutLookup.titleField = lookup.titleField;
      this.punchTypesOutLookup.valueField = lookup.valueField;
      this.punchTypesOutLookup.items = isPaidRestBreakEnabled ? _.filter(lookup.items, (type: PunchType) => (type.isOut)) : _.filter(lookup.items, (type: PunchType) => (type.isOut && type.name != "out to paid break"));
      this.defaultOutType = _.find(this.punchTypesOutLookup.items, (type: PunchType) => type.canBeLast);
      if (!this.defaultOutType) {
        this.defaultOutType = _.find(this.punchTypesOutLookup.items);
      }
    });
  }

  getRoundedInterval(punch: LinePunch): any {
    const index = _.findIndex(this.savedRecords, (e: LinePunch) => (e.type.name === punch.type.name && e.time.toString() === punch.time.toString()));

    if (index === -1) {
      return null;
    }

    const previousRecord = this.savedRecords[index - 1];
    const currentRecord = this.savedRecords[index];

    if (currentRecord.type.name === "out to paid break" && previousRecord && previousRecord.type.isIn) {
      const timeInterval = currentRecord.time.getTime() - previousRecord.time.getTime();
      const roundedIntervalInHrs = timeInterval / (1000 * 60 * 60);
      return dateTimeUtils.convertFromDtoDurationNumber(+roundedIntervalInHrs, 'h');
    } else if (currentRecord.type.name === "in from paid break" && previousRecord && previousRecord.type.name === "out to paid break") {
      const timeInterval = currentRecord.time.getTime() - previousRecord.time.getTime();
      const roundedIntervalInHrs = timeInterval / (1000 * 60 * 60);
      return dateTimeUtils.convertFromDtoDurationNumber(+roundedIntervalInHrs, 'h');
    } else if (previousRecord && previousRecord.type.name === "in from paid break" && currentRecord.type.isOut) {
      const timeInterval = currentRecord.time.getTime() - previousRecord.time.getTime();
      const roundedIntervalInHrs = timeInterval / (1000 * 60 * 60);
      return dateTimeUtils.convertFromDtoDurationNumber(+roundedIntervalInHrs, 'h');
    }

    return punch.closingPunch ? punch.closingPunch.roundedInterval : '';
  }

  private performOverlapCheck(matchedPair: { inpunch: punchWrapper; outpunch: punchWrapper; }, isFromSaveRecal: boolean, isFromPunches: boolean = false) {
    this.dailyTimeCardManagementService.hasOverlap(matchedPair, this.records, this.defaultPunchTime, this.employeeId).then((res) => {
      this.hasOverlapPunches = false;
      if (res && res.hasOverlapPunches) {
        if (res.records) {
          this.records = res.records;
        }
        this.hasOverlapPunches = res.hasOverlapPunches;
        this.emitChanges();
        this.validatePairs(this.records);
        if (isFromSaveRecal)
          this.dailyTimeCardManagementService.overlapPunchesDesc(true);
        return;
      } else if (isFromPunches) {
        _.forEach(this.records, (x) => {
          if (x.punch.punchStatus == PunchStatus.Overlap)
            x.punch.punchStatus = PunchStatus.Edited;
        });
        this.validatePairs(this.records);
        if (!this.hasInMissingPunch && !this.hasOutMissingPunch) {
          this.doSave();
          return;
        }
        this.modalService.globalAnchor.openConfirmDialog(`Warning`, 'Missing punches appear on this timecard, do you wish to proceed?', (result: boolean) => {
          if (result) {
            this.doSave();
          }
        });
      } else {
        _.forEach(this.records, (x) => {
          if (x.punch.punchStatus == PunchStatus.Overlap)
            x.punch.punchStatus = PunchStatus.Edited;
        });
        this.emitChanges();
        this.validatePairs(this.records);
        if (isFromSaveRecal) {
          this.dailyTimeCardManagementService.overlapPunchesDesc(false);
        }
      }
    });
  }

  public ngOnDestroy(): void {
    // Must be, see #issueWithAOTCompiler
  }

  public ngOnChanges(change: SimpleChanges): void {
    if (change['employeeId']) {
      this.loadEmpLookups();
    }
    if (change['defaultPunchTime']) {
      this.filterPositionLookup();
    }
  }

  public loadEmpLookups(): void {
    if (this.employeeId) {
      this.employeeLookupsFirstLoadStarted = true;
      this.lookupService.getLookup({ lookupType: 'timeclockDefinition', orgLevelId: undefined, employeeId: this.employeeId }).then((lookup: Lookup) => {
        this.timeclocksLookup = lookup;
        if (this.gridState.editedRecord) {
          this.gridState.editedRecord.punch.timeclock = _.first(this.timeclocksLookup.items);
        }
      });
      this.lookupService.getLookup({ lookupType: 'position', orgLevelId: undefined, employeeId: this.employeeId, isActive: false, updateCacheForced: true }).then((lookup: Lookup) => {
        this.positionsOriginLookup = lookup;
        this.filterPositionLookup();
      });
    }
  }


  public filterPositionLookup(): void {
    if (!this.positionsOriginLookup) {
      return;
    }
    this.positionsLookup = _.cloneDeep(this.positionsOriginLookup);
    this.positionsLookup.items = _.filter(this.positionsLookup.items, (p: Position) => {
      const res = (moment(this.defaultPunchTime).isSameOrAfter(p.startDate) && (!p.endDate || moment(this.defaultPunchTime).isSameOrBefore(p.endDate)));
      return res;
    });
    this.positionsLookup.items.unshift({id: 0, name: ''});
  }

  public showComment(punch: LinePunch): void {
    let options: DialogOptions = new DialogOptions();
    options.message = punch.comment;
    options.height = 300;
    options.width = 400;
    this.modalService.globalAnchor.openInfoDialogEx(`Employee ${this.employeeName} comment`, options, (result: boolean) => {
      //nothing to do;
    });
  }

  public showPunchLogs(): void {
    let req: PunchLogRequest = new PunchLogRequest();
    req.employeeId = this.employeeId;
    req.employeeName = this.employeeName;
    req.startDate = this.defaultPunchTime;
    req.endDate = this.defaultPunchTime;
    PunchLogsDialogComponent.openDialog('Punch Log', req, this.modalService, (result: boolean, cmd: any) => {
      //nothing to do
    });

  }
  public emitChanges(): void {
    let punches: LinePunch[] = this.extractPairs(this.records);
    this.managementService.emitChanges(punches);
  }

  public onDiscardChanges(): void {
    if (this.gridState.editedRowIndex !== undefined) {
      this.gridState.cancelHandler({ sender: this.grid, rowIndex: this.gridState.editedRowIndex });
    }
    this.hasOverlapPunches = false;
    this.hasChanges = false;
    this.removedPunches = [];
    this.records = this.transformPunchesPairs(this.savedRecords);
    this.managementService.discardChanges();
    this.refreshGrid();
    if (!this.userActions.canSave) {
      this.emitChanges();
    }
    this.validatePairs(this.records);
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
    this.dailyTimeCardManagementService.setHasOverlap(false);
    this.overlapIndex = -1;
  }

  // save punches from punches screen
  public onSaveChanges(): Promise<any> {
    if (this.preventOverlappingPunches && this.gridState.view.data && this.gridState.view.data.length > 0) {

      this.records = this.sortByTime(this.records);
      let res = this.hasMatchedPair(this.records, null);
      
      if (res.hasMatchedPair) {
        _.forEach(res.matchedPair, (pair:{inpunch: punchWrapper, outpunch: punchWrapper}) => {
          this.performOverlapCheck(pair, false, true);
        });
      } else{
        this.validatePairs(this.records);
        if (!this.hasInMissingPunch && !this.hasOutMissingPunch) {
          this.doSave();
          return;
        }
        this.modalService.globalAnchor.openConfirmDialog(`Warning`, 'Missing punches appear on this timecard, do you wish to proceed?', (result: boolean) => {
          if (result) {
            this.doSave();
          }
        });
      }
    }
    else {
      this.validatePairs(this.records);
      if (!this.hasInMissingPunch && !this.hasOutMissingPunch) {
        this.doSave();
        return;
      }
      this.modalService.globalAnchor.openConfirmDialog(`Warning`, 'Missing punches appear on this timecard, do you wish to proceed?', (result: boolean) => {
        if (result) {
          this.doSave();
        }
      });
    }
  }

  public resizeEvent(event: { isMobile: boolean, grid: GridComponent }): void {
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public onAddPunch(grid: GridComponent): void {
    if (!this.canEditOwnTimecards()) return;

    this.gridState.closeEditor(grid);

    let lastPanch: punchWrapper;
    let item: punchWrapper;
    let p: LinePunch = new LinePunch();
    p.show = true;
    p.punchStatus = PunchStatus.Edited;
    p.punchSource = PunchSource.Manual;
    p.comment = '';
    p.isEditedAndValidated = true;
    if (this.selectedRecords && this.selectedRecords.length > 0) {
      lastPanch = _.last(this.selectedRecords);
    } else {
      let orderedPunches = _.orderBy(this.records, (p: punchWrapper) => p.punch.time);
      lastPanch = _.last(orderedPunches);
    }
    item = { punch: p, id: -1, isNew: true };
    if (!lastPanch) {
      p.type = this.defaultInType;
      p.time = this.defaultPunchTime;
      this.records.push(item);
    } else {
      const next = _.find(this.punchTypesLookup.items, (t: PunchType) => t.previousPunchTypeId === lastPanch.punch.type.id);
      if (next) {
        p.type = next;
      } else {
        if (lastPanch.punch.type.isIn) {
          p.type = this.defaultOutType;
        } else {
          p.type = this.defaultInType;
        }
      }
      p.time = moment(lastPanch.punch.time).add(30, 'm').toDate();
      if (this.timeclocksLookup) p.timeclock = _.first(this.timeclocksLookup.items);
      //const index: number = _.indexOf(this.records, lastPanch);
      //this.newItemIndex = index + 1;
      //this.records.splice(this.newItemIndex, 0, item);
      this.records.push(item);
      this.emitChanges();
    }
    this.refreshGrid();
    if (this.preventOverlappingPunches) {
      this.records = this.sortByTime(this.records);
      let res = this.hasMatchedPair(this.records, item);
      
      if (res.hasMatchedPair) {
        _.forEach(res.matchedPair, (pair:{inpunch: punchWrapper, outpunch: punchWrapper}) => {
          this.performOverlapCheck(pair, false);
        });
      } else {
        _.forEach(this.records, (x) => {
          if (x.punch.punchStatus == PunchStatus.Overlap) {
            x.punch.punchStatus = PunchStatus.Edited;
          }
        });
        this.emitChanges();
      }
    }
    const index: number = _.indexOf(this.gridState.view.data, item);
    let event: EditEvent = {
      sender: this.grid,
      rowIndex: index,
      dataItem: this.gridState.view.data[index],
      isNew: true
    };
    // this.gridState.editHandler(event);
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public onInvalidate(dataItem: punchWrapper): void {
    if (!dataItem || !this.canEditOwnTimecards()) {
      return;
    }
    dataItem.punch.isEditedAndValidated = false;
    this.emitChanges();
  }

  public editHandler(event: { sender: GridComponent, dataItem: punchWrapper, rowIndex: number }): void {
    if (this.overlapIndex != -1 && this.overlapIndex != event.rowIndex) {
      return;
    }
    this.gridState.editHandler(event);
    if (!event.dataItem.punch.timeclock) {
      if (this.timeclocksLookup && this.timeclocksLookup.items) {
        this.gridState.editedRecord.punch.timeclock = _.first(this.timeclocksLookup.items);
      }
    }
  }

  public removeHandler(event: { sender: GridComponent, dataItem: punchWrapper, rowIndex: number, isNew: boolean }): void {
    if (this.canEditOwnTimecards()) {
      if(event.dataItem.punch.punchDisplay === PunchDisplay.InvalidLogin){
        this.modalService.globalAnchor.openConfirmDialog(`Warning`, 'Are you sure you want to delete this entry?', (result: boolean) => {
          if (result) {
            this.managementService.removeInvalidLogin(event.dataItem.punch.time,this.employeeId).
            then((result:boolean) => {
                  if(result){
                    this.gridState.onRemove$.next({ dataItem: event.dataItem, rowIndex: event.rowIndex });
                    this.gridState.refreshGrid();         
                  }
              });
          }
        });
      }else{
        this.gridState.removeHandler(event);
      }
    }
  }

  public saveHandler(event: { sender: GridComponent, dataItem: punchWrapper, rowIndex: number, isNew: boolean }): void {
    if (this.canEditOwnTimecards()) {
      this.gridState.saveHandler(event);
    }
  }

  public cancelHandler(event: { sender: GridComponent, dataItem: punchWrapper, rowIndex: number, isNew: boolean }): void {
    if (this.canEditOwnTimecards()) {
      this.gridState.cancelHandler(event);
    }
  }

  public onShowMap(punch: LinePunch): void {
    // punch.latitude, punch.longitude
    const options = new GeoPunchDialogOptions(
      punch.latitude,
      punch.longitude,
      punch.punchDisplay === PunchDisplay.InvalidPunch,
      600,
      400,
      17
    );
    GeoPunchDialogComponent.openDialog(options, this.modalService);
  }

  private canEditOwnTimecards(): boolean {
    if (!this.timecardsCheckService.userCanEditOwnTimecard(this.employeeId)) {
      this.errorService.errorWithoutLog(new NotificationMessage('Timecard is readonly.',
        `You are not authorized to edit your own timecard`), {});
      return false;
    }
    return true;
  }

  private validatePairs(records: punchWrapper[]): void {
    const punches = _.map(records, (w: punchWrapper) => w.punch);
    this.managementService.markClosingPunches(punches);
    const result = this.managementService.validatePairs(punches);
    this.hasInMissingPunch = result.hasInMissingPunch;
    this.hasOutMissingPunch = result.hasOutMissingPunch;
  }

  private async doSave(): Promise<any> {
    const res = await this.checkComment();
    if(!res) {
      return;
    }
    if (this.gridState.editedRowIndex !== undefined) {
      let event: EditEvent = {
        sender: this.grid,
        rowIndex: this.gridState.editedRowIndex,
        dataItem: this.gridState.editedRecord,
        isNew: false
      };
      this.gridState.saveHandler(event);
    }
    let pairs: LinePunch[] = this.extractPairs(this.records);
    this.managementService.applyChanges({ punches: pairs, comment: this.comment });
    this.hasChanges = false;
    this.removedPunches = [];
  }

  private transformRecords(records: LinePunch[]): punchWrapper[] {
    let id: number = 0;
    let res: punchWrapper[] = _.reduce(records, (result: punchWrapper[], punch: LinePunch) => {
      let rs: punchWrapper[] = [];
      rs.push({ punch: punch, id: id, isNew: false });
      id++;
      return (result || []).concat(rs);
    }, []);
    return res;
  }

  private transformPunchesPairs(records: LinePunch[]): punchWrapper[] {
    let pairs: LinePunch[] = _.map(records, (w: LinePunch) => _.cloneDeep(w));
    return this.transformRecords(pairs);
  }

  private extractPairs(records: punchWrapper[]): LinePunch[] {
    return _.map(this.records, (w: punchWrapper) => w.punch);
  }

  private refreshRecords(): void {
    let id: number = 0;
    //let pairs: LinePunch[] = this.extractPairs(this.records);
    //this.records = this.transformRecords(pairs);
    this.refreshGrid();
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  private addPunch(item: punchWrapper): void {
    this.hasChanges = true;
    this.records.unshift(item);
    this.refreshRecords();
    this.validatePairs(this.records);
    this.emitChanges();
  }

  private updatePunch(item: punchWrapper): Promise<void> {
    let originalRecords: punchWrapper[] = this.transformPunchesPairs(this.savedRecords);
    let originalRecord: punchWrapper = originalRecords.find(x => (x.id === item.id && x.punch.type.id === item.punch.type.id));
    if (!item) {
      return;
    }
    this.hasChanges = true;
    item.punch.isEditedAndValidated = true;
    if(originalRecord && item !== originalRecord && item.punch.punchSource !== PunchSource.Manual){
      item.punch.punchSource = PunchSource.Manual;
    }
    if (this.preventOverlappingPunches) {
      this.records = this.sortByTime(this.records);
      let res = this.hasMatchedPair(this.records, item);
    
      if(res.hasMatchedPair) {
        _.forEach(res.matchedPair, (pair:{inpunch: punchWrapper, outpunch: punchWrapper}) => {
          this.performOverlapCheck(pair, false);
        });
      } else {
        _.forEach(this.records, (x) => {
          if (x.punch.punchStatus == PunchStatus.Overlap)
            x.punch.punchStatus = PunchStatus.Edited;
        });
        this.emitChanges();
        this.hasOverlapPunches = false;
        this.dailyTimeCardManagementService.setHasOverlap(false);
      }
    } else {
      this.emitChanges();
      this.validatePairs(this.records);
    }
  }

  sortByTime(records: punchWrapper[]): punchWrapper[] {
    return _.sortBy(records, x => x.punch.time);
  }

  hasMatchedPair(records: punchWrapper[], item: punchWrapper): {hasMatchedPair: boolean, matchedPair: { inpunch: punchWrapper, outpunch: punchWrapper }[]} {
    let _hasMatchedPair = false;
    let ind = 0;
    let pairs: { inpunch: punchWrapper, outpunch: punchWrapper }[] = [];
    let _records = _.cloneDeep(records);

    if (item) {
      _records = _.filter(_records, (x) => x.punch.punchDisplay != "Schedule");
      ind = _.findIndex(_records, (x) => x.id == (item.id < 0 ? x.id : item.id) && x.punch.time.valueOf() === item.punch.time.valueOf());
      ind = ind >= 0 ? ind : 0;
      if (ind > 0 && _records[ind].punch.type.isOut && _records[ind - 1].punch.type.isIn && (_records[ind - 1].punch.punchDisplay != "Schedule")) { 
        pairs.push({ inpunch: _records[ind - 1], outpunch: _records[ind] });
        _hasMatchedPair = true;
      }
      else if (ind < _records.length - 1 && _records[ind].punch.type.isIn && _records[ind + 1].punch.type.isOut && (_records[ind + 1].punch.punchDisplay != "Schedule")) {
        pairs.push({ inpunch: _records[ind], outpunch: _records[ind + 1] });
        _hasMatchedPair = true;
      }
    } else {
      _.forEach(_records, (x, i) => {
        if (!_hasMatchedPair && x.punch.punchDisplay != "Schedule") {
          {
            ind = i;
            if (ind > 0 && _records[ind].punch.type.isOut && _records[ind - 1].punch.type.isIn && (records[ind - 1].punch.punchDisplay != "Schedule")) { // !this.hasAllCurrentDayPunches(_records, ind, true) && 
              pairs.push({ inpunch: _records[ind - 1], outpunch: _records[ind] });
              _hasMatchedPair = true;
            }
            else if (ind < _records.length - 1 && _records[ind].punch.type.isIn && _records[ind + 1].punch.type.isOut && (records[ind + 1].punch.punchDisplay != "Schedule")) { // !this.hasAllCurrentDayPunches(_records, ind, false) && 
              pairs.push({ inpunch: _records[ind], outpunch: _records[ind + 1] });
              _hasMatchedPair = true;
            }
          }
        }

        if (_hasMatchedPair)
          return { hasMatchedPair: _hasMatchedPair, matchedPair: pairs };
      })
    }
    return { hasMatchedPair: _hasMatchedPair, matchedPair: pairs };
  }

  hasAllCurrentDayPunches(records: punchWrapper[], ind: number, isIn: boolean) {
    let res = moment(records[isIn?(ind -1): (ind+1)].punch.time).format(appConfig.linkDateFormat) == moment(this.defaultPunchTime).format(appConfig.linkDateFormat);
    let res1 = moment(this.defaultPunchTime).format(appConfig.linkDateFormat) == moment(records[ind].punch.time).format(appConfig.linkDateFormat);
    let res2 = res && res1;
    return res2;
  }

  private removePunch(item: punchWrapper): void {
    if (item.punch.punchSource === PunchSource.Request) {
      item.punch.isDeleted = true;
    } else {
      this.removedPunches.push(item);
      _.remove(this.records, (w: punchWrapper) => w.id === item.id);
    }
    // this.refreshGrid();
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
    // this.emitChanges();
    // this.validatePairs(this.records);
    this.hasOverlapPunches = false;
    this.dailyTimeCardManagementService.setHasOverlap(false);
    this.overlapIndex = -1;

    if (this.preventOverlappingPunches) {
      this.records = this.sortByTime(this.records);
      let res = this.hasMatchedPair(this.records, item);
    
      if(res.hasMatchedPair) {
        _.forEach(res.matchedPair, (pair:{inpunch: punchWrapper, outpunch: punchWrapper}) => {
          this.performOverlapCheck(pair, false);
          this.refreshGrid();
        });
      } else {
        _.forEach(this.records, (x) => {
          if (x.punch.punchStatus == PunchStatus.Overlap)
            x.punch.punchStatus = PunchStatus.Edited;
        });
        this.refreshGrid();
        this.emitChanges();
        this.validatePairs(this.records);
        this.hasOverlapPunches = false;
        this.dailyTimeCardManagementService.setHasOverlap(false);
      }
    } else {
      this.refreshGrid();
      this.emitChanges();
      this.validatePairs(this.records);
    }
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }

    let fileredRecords: punchWrapper[] = _.filter(this.records, (w: punchWrapper) => w.punch.show);
    this.punchesCount = this.records.length;
    this.filteredPunchesCount = fileredRecords.length;
    this.gridState.view = process(fileredRecords, this.gridState.state);
  }

  private  async checkComment(): Promise<boolean> {
    if(!this.canEdit) {
      return true;
    }
    if(!this.timecardPredefinedComments) {
      this.timecardPredefinedComments = await this.lookupService.getLookup({lookupType: LookupType.timecardPredefinedComment});
    }

    if (!this.comment && this.timecardPredefinedComments && this.timecardPredefinedComments.items.length > 0) {
      const req = new TimecardAddCommentsReq();
      req.employeeId = this.employeeId
      req.employeeName = this.employeeName
      req.date = this.defaultPunchTime;
      req.timecardPredefinedComments = this.timecardPredefinedComments;
      const res = await TimecardAddCommentsDialogComponent.openDialog('Add Comments', req, this.modalService, 'single');
      if(!res.isApply) {
        return false;
      }
      this.comment = res.comment;
    }
    return true;
  }

  public BindTitle(description : string)
  {
    return description == '' ? 'Please select a valid punch type' : 'Punches';
  }
}

