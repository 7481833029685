import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { OnInit, OnDestroy, DoCheck } from '@angular/core';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { appConfig } from '../../../../app.config';
import { PbjOrganizationModel, PBJMigrationVersion } from '../../../models/index';
import { EditableListEditorComponent } from '../../editableList/listEditor/editable-list-editor.component';
import { LookupApiService } from '../../../../organization/services/index';
import { TextEditorOptions } from '../../../../components-library/models/index';
import { ModalService } from '../../../../common/services/index';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { Subscription } from 'rxjs/Subscription';
import { CCNNumberValidatorAdapter } from '../../../services/index';
import { CommonValidators } from '../../../../common/validators/common-validators';
import { IServerValidatorConf } from '../../../../common/validators/common-validators-models';
import { PbjOrganizationsConfigurationManagementService } from '../../../services/index';
import { OrgPayrollAppStatus, PayrollAppStatus } from '../../../models/index';
import { AppSettingsManageService } from '../../../../app-settings/services';
function customNumberValidator(control) {
    var value = control.value;
    if (value < 1 || value > 99) {
        return { 'invalidNumber': true };
    }
    return null;
}
var PbjOrganizationEditorComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PbjOrganizationEditorComponent, _super);
    function PbjOrganizationEditorComponent(ccnValidationAdapter, lookup, modal, management, fb, appSettingManageService) {
        var _this = _super.call(this) || this;
        _this.ccnValidationAdapter = ccnValidationAdapter;
        _this.lookup = lookup;
        _this.modal = modal;
        _this.management = management;
        _this.fb = fb;
        _this.naicsCode = null;
        _this.isSystemAdministrator = true;
        _this.nextgenPhasedRollout = false;
        _this.isNextgenPayrollEnabled = false;
        _this.tmplAppVersions = ['SmartLinx 5', 'SmartLinx 6', 'Both'];
        _this.tempPayrollAppStatus = [
            PayrollAppStatus.Off,
            PayrollAppStatus.Migration,
            PayrollAppStatus.Live,
            PayrollAppStatus.LiveWithTAExport
        ];
        _this.appSettingManageService = appSettingManageService;
        _this.appConfig = appConfig;
        _this.options = new TextEditorOptions();
        _this.options.toolbarButtons = [
            'fontFamily', 'fontSize', 'color', '|',
            'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'insertLink', '|',
            'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', '|',
            'clearFormatting'
        ];
        _this.options.placeholderText = 'Create your message here...';
        _this.options.charCounterMax = 370;
        _this.options.height = 200;
        var today = moment();
        _this.today = today.toDate();
        _this.tomorrow = moment(today).add(1, 'day').toDate();
        _this.startOfYear = today.startOf('year').toDate();
        _this.endOfYear = today.endOf('year').toDate();
        _this.createServerValidationConfig();
        return _this;
        // this.formGroup.controls['payrollCode'] = this.fb.group({
        //    payrollCode: ['', [Validators.required, customNumberValidator]],
        //   });
    }
    Object.defineProperty(PbjOrganizationEditorComponent.prototype, "item", {
        get: function () {
            return this.privateItem;
        },
        set: function (value) {
            this.privateItem = value;
            this.createServerValidationConfig();
            if (this.formGroup) {
                this.updateFormGroup();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PbjOrganizationEditorComponent.prototype, "ccnValidation", {
        get: function () {
            return CCNNumberValidatorAdapter.ccnNumberValidation;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PbjOrganizationEditorComponent.prototype, "originalCCN", {
        get: function () {
            return this.privateItem.pbjOrganization.ccnNumber;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PbjOrganizationEditorComponent.prototype, "currentOrganizationId", {
        get: function () {
            if (this.privateItem && this.privateItem.pbjOrganization) {
                return this.privateItem.pbjOrganization.organizationId;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PbjOrganizationEditorComponent.prototype, "conversionStateString", {
        get: function () {
            if (this.item && this.item.pbjOrganization && this.item.pbjOrganization.conversionState) {
                return this.item.pbjOrganization.conversionState.state;
            }
            return 'Initial';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PbjOrganizationEditorComponent.prototype, "isApplicableForMigration", {
        get: function () {
            if (this.privateItem) {
                if (this.privateItem.pbjOrganization && this.privateItem.pbjOrganization.migrationVersion) {
                    var savedVersion = this.privateItem.pbjOrganization.migrationVersion.id;
                    var currentVersion = this.migationVersionControl.value.id;
                    return savedVersion !== currentVersion;
                }
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PbjOrganizationEditorComponent.prototype, "isSystemUser", {
        get: function () {
            return this.currentUser ? this.currentUser.username === 'system' : false;
        },
        enumerable: true,
        configurable: true
    });
    // rangeValidator(control) {
    //     const value = parseInt(control.value, 10);
    //     if (isNaN(value) || value < 1 || value > 99) {
    //       return { range: true };
    //     }
    //     return null;
    //   }
    //   onKeyPress(event: KeyboardEvent) {
    //     // Allow only numeric input and prevent other characters
    //     const charCode = event.which ? event.which : event.keyCode;
    //     if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    //       event.preventDefault();
    //     }
    //   }
    PbjOrganizationEditorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userSubscription = this.user$.subscribe(function (user) {
            _this.currentUser = user;
            if (user.roles.includes("System Administrator")) {
                _this.isSystemAdministrator = false;
            }
        });
        this.getSettings();
        this.initDefaults();
    };
    PbjOrganizationEditorComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
        _super.prototype.ngOnDestroy.call(this);
    };
    PbjOrganizationEditorComponent.prototype.ngDoCheck = function () {
        if (this.formGroup) {
            if ((this.item.pbjOrganization.naicsCode == null) && (this.naicsCode == null)) {
                this.formGroup.controls['naicsCode'].setErrors(null);
            }
        }
    };
    PbjOrganizationEditorComponent.prototype.onTextChange = function (html) {
        this.formGroup.get('messageForUsers').setValue(html);
    };
    PbjOrganizationEditorComponent.prototype.onCodeChange = function (naicsCode) {
        this.naicsCode = naicsCode;
        if (_.isEmpty(naicsCode)) {
            this.formGroup.get('naicsCode').setErrors(null);
        }
        else {
            if (naicsCode.toString()[0] == 0) {
                this.formGroup.get('naicsCode').setErrors({ notLeadZeroes: true });
            }
        }
    };
    PbjOrganizationEditorComponent.prototype.initDefaults = function () {
        var _this = this;
        var statePromise = this.lookup.getStates();
        var companyPromise = this.lookup.getCompanies();
        var establishmentType = this.lookup.getEstablishmentTypes();
        var appVersions = this.makeAppVersions();
        var payrollAppStatus = this.getPayrollAppStatus();
        Promise.all([statePromise, companyPromise, establishmentType]).then(function (results) {
            _this.statesLookup = results[0];
            _this.companiesLookup = results[1];
            _this.establishmentTypes = results[2];
            _this.appVersions = appVersions;
            _this.payrollAppStatus = payrollAppStatus;
            _super.prototype.ngOnInit.call(_this);
        });
    };
    PbjOrganizationEditorComponent.prototype.createFormGroup = function () {
        var _this = this;
        var group = new FormGroup({
            name: new FormControl('', Validators.required),
            cmsPbjCode: new FormControl(''),
            company: new FormControl(''),
            address: new FormControl(''),
            city: new FormControl(''),
            state: new FormControl(''),
            zipCode: new FormControl(''),
            phone: new FormControl(''),
            companyCode: new FormControl(''),
            group: new FormControl(''),
            type: new FormControl(''),
            otherName: new FormControl(''),
            telepunchOffset: new FormControl(''),
            timezoneOffset: new FormControl(''),
            timezone: new FormControl(''),
            migrationVersion: new FormControl(''),
            conversionDate: new FormControl('', [Validators.required]),
            payrollCode: new FormControl('', [Validators.required, Validators.pattern('^[0-9]+$'), customNumberValidator]),
            payrollAppStatus: new FormControl(''),
            payrollAppEffectiveDate: new FormControl(''),
            messageForUsers: new FormControl(''),
            ccn: new FormControl(''),
            asOshaCompany: new FormControl(''),
            naicsCode: new FormControl(''),
            naicsDescription: new FormControl(''),
            establishmentType: new FormControl(''),
            establishmentSize: new FormControl(''),
            payGroup: new FormControl('')
        });
        this.migationVersionControl = group.get('migrationVersion');
        this.conversionDateControl = group.get('conversionDate');
        this.ccnNumberControl = group.get('ccn');
        this.payrollAppStatusControl = group.get('payrollAppStatus');
        this.payrollAppEffectiveDateControl = group.get('payrollAppEffectiveDate');
        this.createServerValidationConfig();
        this.ccnNumberControl.setAsyncValidators(CommonValidators.serverValidator(this.serverValidatorConfig));
        this.migrationVersionControlSubscription = this.migationVersionControl.valueChanges.subscribe(function (val) {
            var oldValue = _this.item.pbjOrganization.migrationVersion;
            if (val.id === _this.tmplAppVersions[0] && (oldValue.id === _this.tmplAppVersions[1] || oldValue.id === _this.tmplAppVersions[2])) {
                if (!_this.confirmation) {
                    _this.confirmation = _this.modal.globalAnchor.openConfirmDialog('Confirm action', 'This will re-set all the migrated organizational data for Version 6, are you sure you want to proceed?', function (result) {
                        if (!result) {
                            _this.migationVersionControl.setValue(oldValue);
                        }
                        _this.updateMigrationState();
                        _this.confirmation = null;
                    });
                }
            }
            else {
                _this.updateMigrationState();
            }
        });
        this.conversionDateControlSubscription = this.conversionDateControl.valueChanges.subscribe(function (val) {
            _this.updateConversionDateLimits();
        });
        this.ccnNumberControlSubscription = this.ccnNumberControl.valueChanges.subscribe(function (val) {
            var errors = Object.assign({}, _this.ccnNumberControl.errors, { 'unverified': true });
            _this.ccnNumberControl.setErrors(errors);
            _this.ccnNumberControl.markAsPending();
            _this.formGroup.updateValueAndValidity();
        });
        this.mainFormSubscription = group.statusChanges.subscribe(function () {
            if (_this.formGroup.dirty) {
                _this.management.markAsDirty();
            }
        });
        return group;
    };
    PbjOrganizationEditorComponent.prototype.updateFormGroup = function () {
        var _this = this;
        var company = _.find(this.companiesLookup, function (c) {
            return c.id === _this.item.pbjOrganization.company;
        });
        var state = _.find(this.statesLookup, function (s) {
            return s.name === _this.item.pbjOrganization.state;
        });
        var establishmentType = _.find(this.establishmentTypes, function (s) {
            return s.id === Number(_this.item.pbjOrganization.establishmentType);
        });
        var timezone = _.find(this.organizationTimezones, function (t) {
            return t.timezone === _this.item.pbjOrganization.timezone;
        });
        this.formGroup.get('company').setValue(company);
        this.formGroup.get('state').setValue(state);
        this.formGroup.get('name').setValue(this.item.pbjOrganization.name);
        this.formGroup.get('cmsPbjCode').setValue(this.item.pbjOrganization.cmsPbjCode);
        this.formGroup.get('address').setValue(this.item.pbjOrganization.address);
        this.formGroup.get('city').setValue(this.item.pbjOrganization.city);
        this.formGroup.get('zipCode').setValue(this.item.pbjOrganization.zip);
        this.formGroup.get('phone').setValue(this.item.pbjOrganization.phone);
        this.formGroup.get('companyCode').setValue(this.item.pbjOrganization.companyCode);
        this.formGroup.get('group').setValue(this.item.pbjOrganization.group);
        this.formGroup.get('type').setValue(this.item.pbjOrganization.type);
        this.formGroup.get('otherName').setValue(this.item.pbjOrganization.otherName);
        this.formGroup.get('migrationVersion').setValue(this.item.pbjOrganization.migrationVersion);
        this.formGroup.get('messageForUsers').setValue(this.item.pbjOrganization.messageForUsers);
        this.formGroup.get('telepunchOffset').setValue(this.item.telepunchOffset.fieldValue);
        this.formGroup.get('timezoneOffset').setValue(this.item.timezoneOffset.fieldValue);
        this.formGroup.get('timezone').setValue(timezone);
        this.formGroup.get('payrollCode').setValue(this.item.pbjOrganization.payrollCode);
        this.formGroup.get('payrollAppStatus').setValue(this.item.pbjOrganization.payrollAppStatus);
        this.formGroup.get('payrollAppEffectiveDate').setValue(this.item.pbjOrganization.payrollAppEffectiveDate);
        this.formGroup.get('ccn').setValue(this.item.pbjOrganization.ccnNumber);
        var savedConversionDate = this.item.pbjOrganization.conversionDate || this.today;
        this.formGroup.get('conversionDate').setValue(savedConversionDate);
        this.isConverted = Math.abs(moment(savedConversionDate).diff(this.today, 'day')) > 1;
        this.formGroup.get('asOshaCompany').setValue(this.item.pbjOrganization.asOshaCompany);
        this.formGroup.get('naicsCode').setValue(this.item.pbjOrganization.naicsCode);
        this.formGroup.get('naicsDescription').setValue(this.item.pbjOrganization.naicsDescription);
        this.formGroup.get('establishmentType').setValue(establishmentType);
        this.formGroup.get('establishmentSize').setValue(this.item.pbjOrganization.establishmentSize);
        this.formGroup.get('payGroup').setValue(this.item.pbjOrganization.payGroup);
        this.updateMigrationState();
    };
    PbjOrganizationEditorComponent.prototype.updateItem = function () {
        this.item.pbjOrganization.name = this.formGroup.get('name').value;
        this.item.pbjOrganization.cmsPbjCode = this.formGroup.get('cmsPbjCode').value;
        this.item.pbjOrganization.company = _.get(this.formGroup.get('company').value, 'id', null);
        this.item.pbjOrganization.companyName = _.get(this.formGroup.get('company').value, 'name', null);
        this.item.pbjOrganization.address = this.formGroup.get('address').value;
        this.item.pbjOrganization.city = this.formGroup.get('city').value;
        this.item.pbjOrganization.state = _.get(this.formGroup.get('state').value, 'name', null);
        this.item.pbjOrganization.zip = this.formGroup.get('zipCode').value;
        this.item.pbjOrganization.phone = this.formGroup.get('phone').value;
        this.item.pbjOrganization.companyCode = this.formGroup.get('companyCode').value;
        this.item.pbjOrganization.group = this.formGroup.get('group').value;
        this.item.pbjOrganization.type = this.formGroup.get('type').value;
        this.item.pbjOrganization.otherName = this.formGroup.get('otherName').value;
        this.item.pbjOrganization.ccnNumber = this.formGroup.get('ccn').value;
        this.item.pbjOrganization.payrollCode = this.formGroup.get('payrollCode').value;
        this.item.pbjOrganization.payrollAppStatus = this.formGroup.get('payrollAppStatus').value;
        this.item.pbjOrganization.payrollAppEffectiveDate = this.formGroup.get('payrollAppEffectiveDate').value;
        this.item.pbjOrganization.asOshaCompany = (this.formGroup.get('asOshaCompany').value);
        this.item.pbjOrganization.naicsCode = this.formGroup.get('naicsCode').value;
        this.item.pbjOrganization.naicsDescription = this.formGroup.get('naicsDescription').value;
        this.item.pbjOrganization.establishmentType = _.get(this.formGroup.get('establishmentType').value, 'id', null);
        this.item.pbjOrganization.establishmentSize = this.formGroup.get('establishmentSize').value;
        this.item.pbjOrganization.payGroup = this.formGroup.get('payGroup').value;
        var currentVersion = this.formGroup.get('migrationVersion').value;
        if (currentVersion.id !== this.item.pbjOrganization.migrationVersion.id) {
            this.item.pbjOrganization.conversionDate = this.formGroup.get('conversionDate').value;
        }
        else {
            this.item.pbjOrganization.conversionDate = null;
        }
        this.item.pbjOrganization.migrationVersion = currentVersion;
        this.item.pbjOrganization.messageForUsers = this.formGroup.get('messageForUsers').value;
        this.item.pbjOrganization.telepunchOffset = this.formGroup.get('telepunchOffset').value;
        this.item.pbjOrganization.timezoneOffset = this.formGroup.get('timezoneOffset').value;
        this.item.pbjOrganization.timezone = _.get(this.formGroup.get('timezone').value, 'timezone', null);
        this.item.telepunchOffset.fieldValue = this.formGroup.get('telepunchOffset').value;
        this.item.timezoneOffset.fieldValue = this.formGroup.get('timezoneOffset').value;
    };
    PbjOrganizationEditorComponent.prototype.createServerValidationConfig = function () {
        this.serverValidatorConfig = { validationAdapter: this.ccnValidationAdapter, validationName: this.ccnValidation, parameters: [this.currentOrganizationId] };
    };
    PbjOrganizationEditorComponent.prototype.updateMigrationState = function () {
        if (this.isApplicableForMigration) {
            if (this.conversionDateControl.disabled) {
                this.conversionDateControl.enable();
            }
            this.updateConversionDateLimits();
        }
        else {
            if (this.conversionDateControl.enabled) {
                this.conversionDateControl.disable();
            }
        }
    };
    PbjOrganizationEditorComponent.prototype.updateConversionDateLimits = function () {
        if (this.ignoreNextConversionPropertiesUpdate) {
            this.ignoreNextConversionPropertiesUpdate = false;
            return;
        }
        this.ignoreNextConversionPropertiesUpdate = true;
        if (this.item.pbjOrganization.conversionDate) {
            if (this.isConverted) {
                this.minConversionDate = this.tomorrow;
            }
            else {
                if (this.conversionDateControl) {
                    if (!this.conversionDateControl.dirty) {
                        this.minConversionDate = moment(this.item.pbjOrganization.conversionDate).subtract(1, 'day').toDate();
                    }
                    else {
                        this.minConversionDate = this.tomorrow;
                    }
                }
            }
        }
        else {
            this.minConversionDate = this.tomorrow;
        }
    };
    PbjOrganizationEditorComponent.prototype.makeAppVersions = function () {
        return _.map(this.tmplAppVersions, function (ver) { return new PBJMigrationVersion(_.toLower(ver), ver); });
    };
    PbjOrganizationEditorComponent.prototype.getPayrollAppStatus = function () {
        var _this = this;
        return _.map(this.tempPayrollAppStatus, function (ver) { return new OrgPayrollAppStatus(_this.getEnumKeyByEnumValue(PayrollAppStatus, ver), ver); });
    };
    PbjOrganizationEditorComponent.prototype.getEnumKeyByEnumValue = function (myEnum, enumValue) {
        var keys = Object.keys(myEnum).filter(function (x) { return myEnum[x] == enumValue; });
        return keys.length > 0 ? keys[0] : '';
    };
    PbjOrganizationEditorComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var appServerConfig;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingManageService.getAppServerConfig()];
                    case 1:
                        appServerConfig = _a.sent();
                        this.nextgenPhasedRollout = appServerConfig.NextgenPhasedRollout;
                        this.isNextgenPayrollEnabled = appServerConfig.IsNextgenPayrollEnabled;
                        return [2 /*return*/];
                }
            });
        });
    };
    tslib_1.__decorate([
        mutableSelect(['session', 'user']),
        tslib_1.__metadata("design:type", Observable)
    ], PbjOrganizationEditorComponent.prototype, "user$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjOrganizationEditorComponent.prototype, "userSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjOrganizationEditorComponent.prototype, "migrationVersionControlSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjOrganizationEditorComponent.prototype, "conversionDateControlSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjOrganizationEditorComponent.prototype, "payrollAppStatusControlSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjOrganizationEditorComponent.prototype, "ccnNumberControlSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjOrganizationEditorComponent.prototype, "mainFormSubscription", void 0);
    return PbjOrganizationEditorComponent;
}(EditableListEditorComponent));
export { PbjOrganizationEditorComponent };
