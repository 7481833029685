import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { HttpRequest } from '@angular/common/http';
import { dateTimeUtils } from '../../../../common/utils/index';
import { ResponseBody, Meta } from '../../../../core/models/index';
import { UrlParamsService, ApiUtilService } from '../../../../common/services/index';
import { benefitsConfig } from '../../benefits.config';
import { BenefitDetailsMapService } from './benefit-details-map.service';
var BenefitDetailsApiService = /** @class */ (function () {
    function BenefitDetailsApiService(mapService, apiUtilService, urlParamsService) {
        this.mapService = mapService;
        this.apiUtilService = apiUtilService;
        this.urlParamsService = urlParamsService;
    }
    BenefitDetailsApiService.prototype.getBenefitDetailsConfig = function (orgLevelId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiConfig();
                request = this.urlParamsService.createGetRequest(url, { orgLevelId: orgLevelId });
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) { return _this.mapService.mapToBenefitPlanConfig(response.data); })];
            });
        });
    };
    BenefitDetailsApiService.prototype.getBenefitProviders = function (orgLevelId, groupId, loadExpired) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiProviders();
                request = this.urlParamsService.createGetRequest(url, { orgLevelId: orgLevelId, groupId: groupId, loadExpired: loadExpired });
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) { return _this.mapService.mapToBenefitPlanProviderEntities(response.data); })];
            });
        });
    };
    BenefitDetailsApiService.prototype.saveBenefitProvider = function (provider, providerLine) {
        var _this = this;
        var url = this.getApiProvider();
        var body = this.mapService.mapToSaveProviderRequest(provider, providerLine);
        var request = this.urlParamsService.createPostRequest(url, body);
        return this.apiUtilService.request(request)
            .then(function (response) { return _this.mapService.mapToSavedProvider(response.data); });
    };
    BenefitDetailsApiService.prototype.deleteBenefitProvider = function (providerId) {
        var url = this.getApiProvider();
        var request = this.urlParamsService.createDeleteRequest(url, { providerId: providerId });
        return this.apiUtilService.request(request)
            .then(function (response) { return response.data; });
    };
    BenefitDetailsApiService.prototype.getBenefitDetailsLineStandart = function (lineId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiLine();
                request = this.urlParamsService.createGetRequest(url, { lineId: lineId });
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) { return _this.mapService.mapToBenefitPlanLineEntity(response.data); })];
            });
        });
    };
    BenefitDetailsApiService.prototype.saveBenefitDetailsLineStandart = function (providerName, providerLine, providerPlanDate) {
        var _this = this;
        var url = this.getApiLine();
        var body = this.mapService.mapToSaveProviderLineStandartRequest(providerName, providerLine, providerPlanDate);
        var request = this.urlParamsService.createPutRequest(url, body);
        return this.apiUtilService.request(request)
            .then(function (response) { return _this.mapService.mapToBenefitPlanLineEntity(response.data); });
    };
    BenefitDetailsApiService.prototype.saveTiersAttachments = function (savedTiers, draftTiers) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var tierFilesToSave, promises_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                tierFilesToSave = this.mapService.mapToTiersFilesToSave(savedTiers, draftTiers);
                if (tierFilesToSave.size > 0) {
                    promises_1 = [];
                    tierFilesToSave.forEach(function (files, tierId) {
                        promises_1.push(_this.saveTierAttachments(tierId, files));
                    });
                    return [2 /*return*/, Promise.all(promises_1)];
                }
                return [2 /*return*/, Promise.resolve([])];
            });
        });
    };
    BenefitDetailsApiService.prototype.saveTierAttachments = function (tierId, files) {
        var _this = this;
        var url = this.getApiAttachments();
        var formData = this.mapService.mapFilesToFormData(files);
        var request = this.urlParamsService.requestPost(url, formData, { tierId: tierId });
        request.autoContentType = true;
        return this.apiUtilService.requestNew(request)
            .then(function (response) { return _this.mapService.mapToBenefitPlanTier(response.data); });
    };
    BenefitDetailsApiService.prototype.deleteTierAttachments = function (tierId, documentIds) {
        var _this = this;
        var url = this.getApiAttachments();
        var request = this.urlParamsService.createDeleteRequest(url, { tierId: tierId, documentIds: documentIds }, null, true);
        return this.apiUtilService.request(request)
            .then(function (response) { return _this.mapService.mapToBenefitPlanTier(response.data); });
    };
    BenefitDetailsApiService.prototype.deleteTiersAttachments = function (attachments) {
        var _this = this;
        var promises = Array.from(attachments)
            .map(function (_a) {
            var tierId = _a[0], attachmentIds = _a[1];
            return _this.deleteTierAttachments(tierId, attachmentIds);
        });
        return Promise.all(promises);
    };
    BenefitDetailsApiService.prototype.downloadTierAttachment = function (documentId) {
        var url = this.getApiAttachment();
        var request = this.urlParamsService.createGetRequest(url, { documentId: documentId });
        return this.apiUtilService.requestForFile(request)
            .then(function (file) { return file; });
    };
    BenefitDetailsApiService.prototype.expireBenefitPlan = function (lineId, newEndDate) {
        var _this = this;
        var url = this.getApiLineExpire();
        var params = {
            lineId: lineId,
            newEndDate: dateTimeUtils.convertToDtoString(newEndDate)
        };
        var request = this.urlParamsService.createPutRequest(url, null, params);
        return this.apiUtilService.request(request)
            .then(function (response) { return _this.mapService.mapToBenefitPlanLine(response.data); });
    };
    BenefitDetailsApiService.prototype.extendBenefitPlan = function (lineId, newEndDate) {
        var _this = this;
        var url = this.getApiLineExtend();
        var params = {
            lineId: lineId,
            newEndDate: dateTimeUtils.convertToDtoString(newEndDate)
        };
        var request = this.urlParamsService.createPutRequest(url, null, params);
        return this.apiUtilService.request(request)
            .then(function (response) { return _this.mapService.mapToBenefitPlanLine(response.data); });
    };
    BenefitDetailsApiService.prototype.renewBenefitPlan = function (lineId, newEndDate) {
        var _this = this;
        var url = this.getApiLineRenew();
        var params = {
            lineId: lineId,
            newEndDate: dateTimeUtils.convertToDtoString(newEndDate)
        };
        var request = this.urlParamsService.createPutRequest(url, null, params);
        return this.apiUtilService.request(request)
            .then(function (response) { return _this.mapService.mapToBenefitPlanLineEntity(response.data); });
    };
    BenefitDetailsApiService.prototype.validateProviderName = function (newProviderName, orgLevelId, ignoreProviderId) {
        var _this = this;
        var url = this.getApiValidation();
        var params = {
            orgLevelId: orgLevelId,
            newProviderName: newProviderName
        };
        if (_.isFinite(ignoreProviderId)) {
            params.ignoreProviderId = ignoreProviderId;
        }
        var request = this.urlParamsService.createGetRequest(url, params);
        return this.apiUtilService.request(request)
            .then(function (response) { return _this.mapService.mapToBenefitValidationResult(response.data); });
    };
    BenefitDetailsApiService.prototype.getApiValidation = function () {
        return this.getApiRoot() + "/" + benefitsConfig.api.benefits.validation.root + "/" + benefitsConfig.api.benefits.validation.providerName;
    };
    BenefitDetailsApiService.prototype.getApiLineExpire = function () {
        return this.getApiRoot() + "/" + benefitsConfig.api.benefits.line + "/" + benefitsConfig.api.benefits.expire;
    };
    BenefitDetailsApiService.prototype.getApiLineRenew = function () {
        return this.getApiRoot() + "/" + benefitsConfig.api.benefits.line + "/" + benefitsConfig.api.benefits.renew;
    };
    BenefitDetailsApiService.prototype.getApiLineExtend = function () {
        return this.getApiRoot() + "/" + benefitsConfig.api.benefits.line + "/" + benefitsConfig.api.benefits.extend;
    };
    BenefitDetailsApiService.prototype.getApiLine = function () {
        return this.getApiRoot() + "/" + benefitsConfig.api.benefits.line;
    };
    BenefitDetailsApiService.prototype.getApiAttachments = function () {
        return this.getApiRoot() + "/" + benefitsConfig.api.benefits.attachments;
    };
    BenefitDetailsApiService.prototype.getApiAttachment = function () {
        return this.getApiRoot() + "/" + benefitsConfig.api.benefits.attachment;
    };
    BenefitDetailsApiService.prototype.getApiProvider = function () {
        return this.getApiRoot() + "/" + benefitsConfig.api.benefits.provider;
    };
    BenefitDetailsApiService.prototype.getApiProviders = function () {
        return this.getApiRoot() + "/" + benefitsConfig.api.benefits.providers;
    };
    BenefitDetailsApiService.prototype.getApiConfig = function () {
        return this.getApiRoot() + "/" + benefitsConfig.api.benefits.workplace;
    };
    BenefitDetailsApiService.prototype.getApiRoot = function () {
        return this.apiUtilService.getApiRoot() + "/" + benefitsConfig.api.benefits.root;
    };
    BenefitDetailsApiService.prototype.getdeductionCodeInfo = function (groupId, deductionCode, deductionType) {
        var url = "" + this.getgetdeductionCodeInfoUrl();
        var params = {};
        if (groupId) {
            params['groupId'] = groupId;
        }
        if (deductionCode) {
            params['deductionCode'] = deductionCode;
        }
        if (deductionType)
            params['type'] = deductionType;
        var request = this.urlParamsService.createGetRequest(url, params);
        var promise = this.apiUtilService.request(request)
            .then(function (response) { return response.data; });
        return promise;
    };
    BenefitDetailsApiService.prototype.getgetdeductionCodeInfoUrl = function () {
        return this.apiUtilService.getApiRoot() + "/" + benefitsConfig.api.benefits.root + "/" + benefitsConfig.api.benefits.validateDeductionCode;
    };
    return BenefitDetailsApiService;
}());
export { BenefitDetailsApiService };
