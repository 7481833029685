import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { RuleDto } from '../../models/pay-code-details/dtos/rule.dto';
import { PaycodeExceptionMapService } from './paycode-exception-map.service';
import { RuleMapService } from './rule-map.service';
import { UrlParamsService, ApiUtilService } from "../../../common";
import { PayCodeDetailsContainer } from '../../models/pay-code-details/pay-code-details-container';
import { AdditionalRequirementMapService } from './additional-requirements-map.service';
import { PaycodeExceptionDto } from '../../models/pay-code-details/dtos/paycode-exception.dto';
import { RuleFormulaDto } from '../../models/pay-code-details/dtos/rule-formula.dto';
import { RuleFormulaMapService } from './rule-formula-map.service';

@Injectable()
export class PayCodeDetailsApiService {

    private readonly apiRoot: string = this.getTAServicesApiRoot();

    constructor(
        private apiUtilService: ApiUtilService,
        private exceptionMapService: PaycodeExceptionMapService,
        private ruleMapService: RuleMapService,
        private additionalRequirementMapService: AdditionalRequirementMapService,
        private ruleFormulaMapService: RuleFormulaMapService,
        private urlParamsService: UrlParamsService
    ) { }

    // PaycodeExceptions
    public async getPaycodeExceptions(id: number, orgId: number): Promise<PayCodeDetailsContainer> {
        const url: string = `${this.apiRoot}/paycodeexceptions/organizations/${orgId}/paycodeexceptions/${id}`;
        let request: HttpRequest<any> = new HttpRequest('GET', url);
        try {
            const response = await this.apiUtilService.request<any, any>(request);
            let container = new PayCodeDetailsContainer();

            // Manually check for response and response.data
            container.exceptions = response && response.data
                ? [this.exceptionMapService.mapToModel(response.data)]
                : [];

            container.updateRecords();
            return container;
        } catch (error) {
            if (error && (error.status === 404 || (error.error && error.error.status === 404))) {
                return new PayCodeDetailsContainer(); // Return empty container for 404 errors
            }
            this.handleError(error);
            throw error;
        }

    }

    public async savePaycodeException(data: any): Promise<PaycodeExceptionDto> {
        const url: string = `${this.apiRoot}/paycodeexceptions`;
        const body = this.exceptionMapService.mapToDto(data);
        let request: HttpRequest<any> = new HttpRequest('POST', url, body);
        try {
            const response = await this.apiUtilService.request<PaycodeExceptionDto, any>(request);
            return response.data; // Assuming response.data contains the PaycodeExceptionDto
        } catch (error) {
            this.handleError(error);
            throw error;
        }
    }

    public async deletePaycodeException(id: number): Promise<void> {
        const url: string = `${this.apiRoot}/paycodeexceptions/${id}`;
        let request: HttpRequest<any> = new HttpRequest('DELETE', url);
        try {
            await this.apiUtilService.request<void, any>(request);
        } catch (error) {
            this.handleError(error);
            throw error;
        }
    }

    // Rules
    public async getRules(id: number): Promise<PayCodeDetailsContainer> {
        const url: string = `${this.apiRoot}/rules/${id}`;
        let request: HttpRequest<any> = new HttpRequest('GET', url);
        try {
            const response = await this.apiUtilService.request<any, any>(request);
            let container = new PayCodeDetailsContainer();

            container.rules = response && response.data
                ? response.data.map(this.ruleFormulaMapService.mapToDto)
                : [];

            container.updateRecords();
            return container;
        } catch (error) {
            if (error && (error.status === 404 || (error.error && error.error.status === 404))) {
                return new PayCodeDetailsContainer();
            }
            this.handleError(error);
            throw error;
        }
    }

    public async getRulesByExceptionId(exceptionId: number): Promise<PayCodeDetailsContainer> {
        const url: string = `${this.apiRoot}/rules/exception/${exceptionId}`;
        let request: HttpRequest<any> = new HttpRequest('GET', url);
        try {
            const response = await this.apiUtilService.request<any, any>(request, true);
            let container = new PayCodeDetailsContainer();

            // Manually check for response and response.data
            container.rules = response && response.data
                ? response.data.map(this.ruleMapService.mapToModel)
                : [];

            container.updateRecords();
            return container;
        } catch (error) {
            if (error && (error.status === 404 || (error.error && error.error.status === 404))) {
                console.warn('No rules found for exception ID:', exceptionId);
                return new PayCodeDetailsContainer(); // Return empty container for 404 errors
            }
            this.handleError(error);
            throw error;
        }

    }

    public async saveRule(data: any): Promise<RuleDto> {
        const url: string = `${this.apiRoot}/rules`;
        const body = this.ruleMapService.mapToDto(data);
        let request: HttpRequest<any> = new HttpRequest('POST', url, body);
        try {
            const response = await this.apiUtilService.request<RuleDto, any>(request);
            return response.data;
        } catch (error) {
            this.handleError(error);
            throw error;
        }
    }

    public async deleteRule(id: number): Promise<void> {
        const url: string = `${this.apiRoot}/rules/${id}`;
        let request: HttpRequest<any> = new HttpRequest('DELETE', url);
        try {
            await this.apiUtilService.request<void, any>(request);
        } catch (error) {
            this.handleError(error);
            throw error;
        }
    }

    public async validateRule(whereClause: string): Promise<boolean> {
        const url: string = `${this.apiRoot}/rules/validate?whereClause=${encodeURIComponent(whereClause)}`;
        let request: HttpRequest<any> = new HttpRequest('GET', url);
        try {
            const response = await this.apiUtilService.request<any, any>(request);
            return response.status === 200;
        } catch (error) {
            this.handleError(error);
            return false; // Return false if there's an error
        }
    }

    // RuleFormulas
    public async getRuleFormulasByRuleId(ruleId: number): Promise<RuleFormulaDto[]> {
        const url: string = `${this.apiRoot}/rules/${ruleId}/formulas`;
        let request: HttpRequest<any> = new HttpRequest('GET', url);
        try {
            const response = await this.apiUtilService.request<any, any>(request, true);

            return response && response.data
                ? response.data.map(this.ruleFormulaMapService.mapToDto)
                : [];
        } catch (error) {
            if (error.status === 404 || (error.error && error.error.status === 404)) {
                console.warn('No rule formulas found for rule ID:', ruleId);
                return [];
            }
            this.handleError(error);
            throw error;
        }
    }

    public async saveRuleFormula(data: any): Promise<RuleFormulaDto> {
        const url: string = `${this.apiRoot}/rules/formulas`;
        const body = this.ruleFormulaMapService.mapToDto(data);
        let request: HttpRequest<any> = new HttpRequest('POST', url, body);
        try {
            const response = await this.apiUtilService.request<RuleFormulaDto, any>(request);
            return response.data;
        } catch (error) {
            this.handleError(error);
            throw error;
        }
    }

    public async deleteRuleFormula(ruleId: number, formulaId: number): Promise<void> {
        const url: string = `${this.apiRoot}/rules/${ruleId}/formulas/${formulaId}`;
        let request: HttpRequest<any> = new HttpRequest('DELETE', url);
        try {
            await this.apiUtilService.request<void, any>(request);
        } catch (error) {
            this.handleError(error);
            throw error;
        }
    }

    // AdditionalRequirements
    public async getAdditionalRequirements(exceptionId: number): Promise<PayCodeDetailsContainer> {
        const url: string = `${this.apiRoot}/additionalrequirements/exception/${exceptionId}`;
        let request: HttpRequest<any> = new HttpRequest('GET', url);
        try {
            const response = await this.apiUtilService.request<any, any>(request, true);
            let container = new PayCodeDetailsContainer();

            // Use ternary operator to manually check for response and response.data
            container.additionalRequirements = response && response.data
                ? [this.additionalRequirementMapService.mapToModel(response.data)]
                : [];

            container.updateRecords();
            return container;
        } catch (error) {
            if (error.status === 404 || (error.error && error.error.status === 404)) {
                console.warn('No additional requirements found for exception ID:', exceptionId);
                return new PayCodeDetailsContainer(); // Return empty container for 404 errors
            }
            this.handleError(error);
            throw error;
        }
    }

    public async saveAdditionalRequirements(exceptionId: number, data: any): Promise<void> {
        const url: string = `${this.apiRoot}/additionalrequirements/exception/${exceptionId}`;
        const body = this.additionalRequirementMapService.mapToDto(data);
        const request = new HttpRequest<any>('POST', url, body);
        try {
            const response = await this.apiUtilService.request<any, any>(request);
            // Check the response status
            if (response.status !== 200) {
                throw new Error(`Error saving additional requirement: ${response}`);
            }
        } catch (error) {
            this.handleError(error);
            throw error;
        }
    }

    public async deleteAdditionalRequirement(id: number): Promise<void> {
        const url: string = `${this.apiRoot}/additionalrequirements/${id}`;
        let request: HttpRequest<any> = new HttpRequest('DELETE', url);
        try {
            await this.apiUtilService.request<void, any>(request);
        } catch (error) {
            this.handleError(error);
            throw error;
        }
    }

    private handleError(error: any): void {
        if (error.status === 404 || (error.error && error.error.status === 404))
            console.warn('Resource not found:', error);
        if (error.status === 500 || (error.error && error.error.status === 500)) {
            console.error('Internal server error:', error);
        } else {
            console.error('An error occurred:', error);
        }
    }

    private getTAServicesApiRoot(): string {
        return `${this.apiUtilService.getTAServicesApiRoot()}/taappend`;
    }
}