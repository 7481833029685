import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { GridComponent } from '@progress/kendo-angular-grid';
import { destroyService, unsubscribe } from '../../../core/decorators/index';
import { KendoGridStateHelper, KendoGridCustomSelectionHelper, ModalService } from '../../../common/index';
import { appConfig } from '../../../app.config';
import { PbjOrganizationsConfigurationManagementService } from '../../services/pbj-organizations/pbj-organizations-configuration-management.service';
import { ConfigurationComponentHelper } from '../../utils/configuration-component-helper';
import { EditableListActionKind } from '../../models/index';
import { ActivatedRoute, Router } from '@angular/router';
import { RolesApiService } from '../../services';
import { AppSettingsManageService } from '../../../app-settings/services';
import { LookupApiService } from '../../../organization';
var ConfigurePbjOrganizationsComponent = /** @class */ (function () {
    function ConfigurePbjOrganizationsComponent(management, modalService, route, router, rolesApiService, appSettingManageService, lookup) {
        this.modalService = modalService;
        this.route = route;
        this.router = router;
        this.rolesApiService = rolesApiService;
        this.lookup = lookup;
        this.isAccessEmployers = false;
        this.nextgenPhasedRollout = false;
        this.isNextgenPayrollEnabled = false;
        this.management = management;
        this.gridState = new KendoGridStateHelper();
        this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, true);
        this.crudHelper = new ConfigurationComponentHelper();
        this.crudHelper.gridState = this.gridState;
        this.crudHelper.selectionHelper = this.selectionHelper;
        this.crudHelper.management = management;
        this.appSettingManageService = appSettingManageService;
    }
    Object.defineProperty(ConfigurePbjOrganizationsComponent.prototype, "container", {
        get: function () {
            return this.m_container;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurePbjOrganizationsComponent.prototype, "grid", {
        get: function () {
            return this.m_grid;
        },
        set: function (value) {
            if (this.crudHelper)
                this.crudHelper.grid = value;
            this.m_grid = value;
        },
        enumerable: true,
        configurable: true
    });
    ConfigurePbjOrganizationsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getSettings();
        this.appConfig = appConfig;
        this.state = {
            isLoading: false
        };
        this.savedSubscription = this.management.onItemSaved$.subscribe(function (item) {
            _this.gridState.editedRecord = null;
            _this.gridState.savedEditedRecord = null;
        });
        this.stateSubscription = this.management.onStateChanged$.subscribe(function (state) {
            if (_.has(state, 'isLoading'))
                _this.state.isLoading = state.isLoading;
        });
        this.gridSelectSubscription = this.gridState.onSelectionChanged.subscribe(function (records) {
            _this.crudHelper.selectionChange(_.first(records), 0);
        });
        this.lookup.getOrganizationTimezones().then(function (results) {
            _this.organizationTimezones = results;
        });
        this.crudHelper.grid = this.grid;
        this.crudHelper.init();
        this.management.init();
        var roles, userData, loginRole;
        userData = JSON.parse(localStorage.getItem("slx-worklinx-v6-app"));
        loginRole = userData.session.user.roles[0];
        this.rolesApiService.getRoleAccessTable().then(function (res) {
            roles = res.roles;
            roles.forEach(function (ele) {
                if (loginRole === ele.name) {
                    if (ele.menuModules) {
                        ele.menuModules.forEach(function (element) {
                            if (element.name == 'Configuration') {
                                element.menus.forEach(function (menu) {
                                    if (menu.name == 'General') {
                                        menu.subMenus.forEach(function (subMenu) {
                                            if (subMenu.name == 'Organizations') {
                                                if (subMenu.isEnabled) {
                                                    _this.isAccessEmployers = true;
                                                }
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                }
            });
        });
    };
    // Must be, see #issueWithAOTCompiler
    ConfigurePbjOrganizationsComponent.prototype.ngOnDestroy = function () {
        if (this.crudHelper) {
            this.crudHelper.destroy();
        }
    };
    ConfigurePbjOrganizationsComponent.prototype.navigateToACA = function () {
        console.log(this.route.pathFromRoot[2]);
        this.router.navigate(['configure_employers'], { relativeTo: this.route.pathFromRoot[2] });
        // this.router.navigateByUrl('/configure_employers');     
    };
    ConfigurePbjOrganizationsComponent.prototype.extenalEditorAction = function (action) {
        if (action === EditableListActionKind.COMPLETE_EDIT) {
            this.crudHelper.onSaveItem({ dataItem: this.management.editingItem, isNew: this.management.isEditingNewItem });
        }
        else if (action === EditableListActionKind.CANCEL_EDIT) {
            this.crudHelper.onCancelItem(this.management.editingItem);
        }
    };
    ConfigurePbjOrganizationsComponent.prototype.onMobileRendererEvent = function (action, item, index) {
        if (action === EditableListActionKind.SELECTION_CHANGE) {
            this.crudHelper.selectionChange(item, index);
        }
    };
    ConfigurePbjOrganizationsComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var appServerConfig;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingManageService.getAppServerConfig()];
                    case 1:
                        appServerConfig = _a.sent();
                        this.nextgenPhasedRollout = appServerConfig.NextgenPhasedRollout;
                        this.isNextgenPayrollEnabled = appServerConfig.IsNextgenPayrollEnabled;
                        return [2 /*return*/];
                }
            });
        });
    };
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", PbjOrganizationsConfigurationManagementService)
    ], ConfigurePbjOrganizationsComponent.prototype, "management", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigurePbjOrganizationsComponent.prototype, "stateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigurePbjOrganizationsComponent.prototype, "gridSelectSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigurePbjOrganizationsComponent.prototype, "savedSubscription", void 0);
    return ConfigurePbjOrganizationsComponent;
}());
export { ConfigurePbjOrganizationsComponent };
