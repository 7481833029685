import * as _ from 'lodash';
var DetailGroup = /** @class */ (function () {
    function DetailGroup() {
    }
    DetailGroup.prototype.recalculateFilteredValues = function () {
        var _this = this;
        this.filteredFilledSlots = 0;
        this.filteredTotalSlots = 0;
        this.filteredScheduledHours = 0;
        this.filteredFilledPpd = 0;
        this.filteredTotalPpd = 0;
        this.filteredTotalIdealHours = 0;
        _.forEach(this.rows, function (row) {
            if (row.getFieldValue('ParentShiftId') === '-1') { //only count parent for ideal
                if (_this.noFilters || (_this.isEmptySlotFilterSelected && row.isEmpty && !row.isPendingShift) ||
                    (_this.isMessageFilterSelected && row.messageCount > 0) ||
                    (_this.isOutOfParFilterSelected && row.isOutOfPar) ||
                    (_this.isOvertimeFilterSelected && row.isOvertime) ||
                    (_this.isPendingSlotFilterSelected && row.isPendingShift)) {
                    if (!row.isOutOfPar || (row.isEmpty && !row.isPendingShift) || row.isPendingShift) {
                        _this.filteredTotalIdealHours += +row.getFieldValue('Hours');
                        _this.filteredTotalSlots++;
                    }
                    if (!row.isEmpty) {
                        _this.filteredScheduledHours += +row.getFieldValue('SchedHours');
                        _this.filteredFilledSlots++;
                    }
                }
            }
            if (row.getFieldValue('ParentShiftId') !== '-1') {
                if (_this.noFilters || (_this.isEmptySlotFilterSelected && row.isEmpty && !row.isPendingShift) ||
                    (_this.isMessageFilterSelected && row.messageCount > 0) ||
                    (_this.isOutOfParFilterSelected && row.isOutOfPar) ||
                    (_this.isOvertimeFilterSelected && row.isOvertime) ||
                    (_this.isPendingSlotFilterSelected && row.isPendingShift)) {
                    if (!row.isEmpty) {
                        _this.filteredScheduledHours += +row.getFieldValue('SchedHours');
                    }
                }
            }
            if (row.getFieldValue('ExcludeFromCount') === '1') {
                return;
            }
            else {
                if (_this.noFilters || (_this.isEmptySlotFilterSelected && row.isEmpty && !row.isPendingShift) ||
                    (_this.isMessageFilterSelected && row.messageCount > 0) ||
                    (_this.isOutOfParFilterSelected && row.isOutOfPar) ||
                    (_this.isOvertimeFilterSelected && row.isOvertime) ||
                    (_this.isPendingSlotFilterSelected && row.isPendingShift)) {
                    if (!row.isEmpty) {
                        _this.filteredFilledSlots++;
                    }
                    _this.filteredScheduledHours += +row.getFieldValue('SchedHours');
                }
            }
        });
        if (this.census > 0) {
            this.filteredFilledPpd = this.filteredScheduledHours / this.census;
            this.filteredTotalPpd = this.filteredTotalIdealHours / this.census;
        }
        this.recalculateSwitch();
    };
    Object.defineProperty(DetailGroup.prototype, "noFilters", {
        get: function () {
            return !this.isEmptySlotFilterSelected && !this.isMessageFilterSelected && !this.isOutOfParFilterSelected && !this.isOvertimeFilterSelected && !this.isPendingSlotFilterSelected;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DetailGroup.prototype, "emptySlotCount", {
        get: function () {
            return _.filter(this.rows, function (row) { return row.isEmpty && row.getFieldValue('ExcludeFromCount') !== '1'; }).length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DetailGroup.prototype, "outOfParCount", {
        get: function () {
            return _.filter(this.rows, function (row) { return row.isOutOfPar && !row.isEmpty; }).length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DetailGroup.prototype, "overtimeCount", {
        get: function () {
            return _.filter(this.rows, function (row) { return row.isOvertime; }).length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DetailGroup.prototype, "messageCount", {
        get: function () {
            var empty = _.filter(this.rows, function (row) { return row.isEmpty; });
            var uniq = _.uniqBy(empty, 'key');
            var mapped = _.map(uniq, function (row) { return row.messageCount; });
            return _.sum(mapped);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DetailGroup.prototype, "allSlotCount", {
        get: function () {
            return this.rows.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DetailGroup.prototype, "filledSlotCount", {
        get: function () {
            return this.allSlotCount - this.emptySlotCount;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DetailGroup.prototype, "slots", {
        get: function () {
            return this.filteredFilledSlots + "/" + this.filteredTotalSlots;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DetailGroup.prototype, "shiftGroupId", {
        get: function () {
            var shiftGroupId = -1;
            _.each(this.grouping, function (grouping) {
                if (grouping.name === 'ShiftGroupId') {
                    shiftGroupId = parseInt(grouping.value);
                }
            });
            return shiftGroupId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DetailGroup.prototype, "shiftGroupName", {
        get: function () {
            var shiftGroupName;
            _.each(this.grouping, function (grouping) {
                if (grouping.name === 'ShiftGroup') {
                    shiftGroupName = grouping.value;
                }
            });
            return shiftGroupName;
        },
        enumerable: true,
        configurable: true
    });
    DetailGroup.prototype.recalculateSwitch = function () {
        var _this = this;
        this.filterSwitchShiftCount = 0;
        _.forEach(this.rows, function (row) {
            if ((_this.isOutOfParFilterSelected && row.isOutOfPar)
                && !_this.isEmptySlotFilterSelected
                && !_this.isOvertimeFilterSelected
                && !_this.isPendingSlotFilterSelected) {
                _this.filteredTotalIdealHours = 0;
                _this.filteredTotalSlots = 0;
                _this.filteredTotalPpd = 0;
            }
            if (row.isOutOfPar) {
                if (!row.isEmpty && !row.isPartnerEmployee) {
                    _this.filterSwitchShiftCount++;
                }
            }
        });
    };
    Object.defineProperty(DetailGroup.prototype, "pendingShiftCount", {
        get: function () {
            return _.filter(this.rows, function (row) { return row.isPendingShift; }).length;
        },
        enumerable: true,
        configurable: true
    });
    return DetailGroup;
}());
export { DetailGroup };
