<slx-spinner [show]="state.isLoading">
    <div class="slx-high-box">
        <div class="sticky-save-button">
            <button class="slx-button slx-with-icon slx-min-w90 slx-mobile-adapted save" (click)="saveChanges()"
                [disabled]="isSaveDisabled()" [ngClass]="{'disabled-button': isSaveDisabled()}"
                style="cursor: pointer;">
                <i class="fas fa-save slx-button__icon" aria-hidden="true"></i>
                <span class="slx-button__text">Save Changes</span>
            </button>
            <button class="slx-button slx-min-w90 slx-mobile-adapted cancel" (click)="cancel()">
                <i class="fas fa-times slx-button__icon" aria-hidden="true"></i>
                <span class="slx-button__text">Cancel</span>
            </button>
            <button class="slx-button slx-min-w90 slx-mobile-adapted delete" (click)="deletePaycodeException()">
                <i class="fas fa-trash slx-button__icon" aria-hidden="true"></i>
                <span class="slx-button__text">Delete</span>
            </button>
        </div>
        <form #templateForm="ngForm" novalidate class="slx-high-box__body slx-main-content-indents">
            <!-- Paycode Exceptions Section -->
            <fieldset class="section-container">
                <legend class="section-header">
                    <h4>Pay Code Exception</h4>
                </legend>
                <div class="form-container">
                    <div *ngFor="let dataItem of container?.exceptions || [{}]; let rowIndex = index" class="form-row">
                        <div class="form-column">
                            <label for="exceptionDescription{{rowIndex}}">Description <span class="required">*</span></label>
                            <input type="text" [(ngModel)]="dataItem.exceptionDescription"
                                name="exceptionDescription{{rowIndex}}" class="k-textbox spaced-input"
                                [ngClass]="{'invalid': !dataItem.exceptionDescription}" (input)="markExceptionsDirty()"
                                required />
                        </div>
                        <div class="form-column">
                            <label for="screenColor{{rowIndex}}">Screen Color</label>
                            <input name="screenColor{{rowIndex}}" type="color" [value]="intToHexColor(this.container.exceptions[0].screenColor)"
                                (change)="onColorChange($event)" />
                        </div>
                        <div class="form-column">
                            <label for="workedInd{{rowIndex}}">Worked</label>
                            <input type="checkbox" [(ngModel)]="dataItem.workedInd" name="workedInd{{rowIndex}}"
                                class="styled-checkbox" (change)="markExceptionsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="acaInd{{rowIndex}}">ACA</label>
                            <input type="checkbox" [(ngModel)]="dataItem.acaInd" name="acaInd{{rowIndex}}"
                                id="acaInd{{rowIndex}}" class="styled-checkbox" (change)="markExceptionsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="accrualInd{{rowIndex}}">Accrual</label>
                            <input type="checkbox" [(ngModel)]="dataItem.accrualInd" name="accrualInd{{rowIndex}}"
                                id="accrualInd{{rowIndex}}" class="styled-checkbox" (change)="markExceptionsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="discrepancyInd{{rowIndex}}">Discrepancy</label>
                            <input type="checkbox" [(ngModel)]="dataItem.discrepancyInd"
                                name="discrepancyInd{{rowIndex}}" id="discrepancyInd{{rowIndex}}"
                                class="styled-checkbox" (change)="markExceptionsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="exAllowMultiple{{rowIndex}}">Allow Multiple</label>
                            <input type="checkbox" [(ngModel)]="dataItem.exAllowMultiple"
                                name="exAllowMultiple{{rowIndex}}" id="exAllowMultiple{{rowIndex}}"
                                class="styled-checkbox" (change)="markExceptionsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="exCountTowardsOvertimeInd{{rowIndex}}">Count Towards Overtime</label>
                            <input type="checkbox" [(ngModel)]="dataItem.exCountTowardsOvertimeInd"
                                name="exCountTowardsOvertimeInd{{rowIndex}}" class="styled-checkbox"
                                (change)="markExceptionsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="exApplyToWeekday{{rowIndex}}">Apply to Weekday</label>
                            <input type="checkbox" [(ngModel)]="dataItem.exApplyToWeekday"
                                id="exApplyToWeekday{{rowIndex}}" name="exApplyToWeekday{{rowIndex}}"
                                class="styled-checkbox" (change)="markExceptionsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="exShowInExceptionGrid{{rowIndex}}">Show in Exception Grid</label>
                            <input type="checkbox" [(ngModel)]="dataItem.exShowInExceptionGrid"
                                id="exShowInExceptionGrid{{rowIndex}}" name="exShowInExceptionGrid{{rowIndex}}"
                                class="styled-checkbox" (change)="markExceptionsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="exUseInTimesheets{{rowIndex}}">Use in Timesheets</label>
                            <input type="checkbox" [(ngModel)]="dataItem.exUseInTimesheets"
                                id="exUseInTimesheets{{rowIndex}}" name="exUseInTimesheets{{rowIndex}}"
                                class="styled-checkbox" (change)="markExceptionsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="paidInd{{rowIndex}}">Exception Paid at 2nd Rate</label>
                            <input type="checkbox" [(ngModel)]="dataItem.paidInd" id="paidInd{{rowIndex}}"
                                name="paidInd{{rowIndex}}" class="styled-checkbox" (change)="markExceptionsDirty()" />
                        </div>
                        <div class="form-column" *ngIf="!dataItem.paidInd">
                            <label for="ex2ndPayRateExceptionId{{rowIndex}}">Apply This Exception When Paid at 2nd Rate</label>
                            <kendo-dropdownlist [data]="dataItem.payCodeExceptions" textField="description"
                                valueField="id" [valuePrimitive]="true" [(ngModel)]="dataItem.ex2ndPayRateExceptionId"
                                (valueChange)="markExceptionsDirty()" name="ex2ndPayRateExceptionId{{rowIndex}}">
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-column">
                            <label [attr.for]="'groupId' + rowIndex">Group ID</label>
                            <kendo-dropdownlist [data]="exceptionGroups" textField="group" valueField="id"
                                [valuePrimitive]="true" [(ngModel)]="dataItem.groupId"
                                (valueChange)="onGroupIdChange($event)" name="groupId{{rowIndex}}"
                                [attr.id]="'groupId' + rowIndex">
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-column" *ngIf="dataItem.groupId == 4">
                            <label for="exApplyShiftDiff{{rowIndex}}">Apply Shift Diff</label>
                            <input type="checkbox" [(ngModel)]="dataItem.exApplyShiftDiff"
                                id="exApplyShiftDiff{{rowIndex}}" name="exApplyShiftDiff{{rowIndex}}"
                                class="styled-checkbox" (change)="markExceptionsDirty()" />
                        </div>
                        <div class="form-column" *ngIf="dataItem.groupId == 4">
                            <label for="exWorkStatus{{rowIndex}}">Work Status</label>
                            <kendo-dropdownlist [data]="specialCodes" textField="description" valueField="id"
                                [valuePrimitive]="true" [(ngModel)]="dataItem.exWorkStatus"
                                (valueChange)="markExceptionsDirty()" name="exWorkStatus{{rowIndex}}">
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-column" *ngIf="dataItem.groupId == 5">
                            <label for="exWorkStatus{{rowIndex}}">Work Status</label>
                            <kendo-dropdownlist [data]="workCodes" textField="name" valueField="id"
                                [valuePrimitive]="true" [(ngModel)]="dataItem.exWorkStatus"
                                (valueChange)="markExceptionsDirty()" name="exWorkStatus{{rowIndex}}"
                                [attr.id]="'exWorkStatus' + rowIndex">
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-column">
                            <label for="exAllocationType{{rowIndex}}">Allocation Type <span class="required">*</span></label>
                            <kendo-dropdownlist [data]="allocationTypes" textField="description" valueField="id"
                                [valuePrimitive]="true" [(ngModel)]="dataItem.exAllocationType"
                                (valueChange)="markExceptionsDirty()" name="exAllocationType{{rowIndex}}" required
                                [ngClass]="{'invalid': !dataItem.exAllocationType}">
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-row">
                            <div class="form-column">
                                <label for="exPayDiffType{{rowIndex}}">Pay Diff Type <span class="required">*</span></label>
                                <kendo-dropdownlist [data]="payDiffTypes" textField="description" valueField="id"
                                    [valuePrimitive]="true" [(ngModel)]="dataItem.exPayDiffType"
                                    (valueChange)="markExceptionsDirty()" name="exPayDiffType{{rowIndex}}" required
                                    [ngClass]="{'invalid': !dataItem.exPayDiffType}">
                                </kendo-dropdownlist>
                            </div>
                            <div class="form-column" *ngIf="dataItem.exPayDiffType === '1'">
                                <label for="payDiffPct{{rowIndex}}">Pay Difference Percentage</label>
                                <input type="number" [(ngModel)]="dataItem.payDiffPct" name="payDiffPct{{rowIndex}}"
                                    id="payDiffPct{{rowIndex}}" class="k-textbox spaced-input"
                                    (input)="markExceptionsDirty()" />
                            </div>
                            <div class="form-column" *ngIf="dataItem.exPayDiffType !== '1'">
                                <label for="payDiffPct{{rowIndex}}">Amount</label>
                                <input type="number" [(ngModel)]="dataItem.payDiffPct" name="payDiffPct{{rowIndex}}"
                                    id="payDiffPct{{rowIndex}}" class="k-textbox spaced-input"
                                    (input)="markExceptionsDirty()" />
                            </div>
                        </div>
                        <div class="form-column">
                            <label for="exInterfaceCategoryId{{rowIndex}}">Interface Category ID</label>
                            <kendo-dropdownlist [data]="interfaceCategories" textField="description" valueField="id"
                                [valuePrimitive]="true" [(ngModel)]="dataItem.exInterfaceCategoryId"
                                (valueChange)="markExceptionsDirty()" name="exInterfaceCategoryId{{rowIndex}}">
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-column">
                            <label for="exInterface{{rowIndex}}">Interface</label>
                            <input type="text" [(ngModel)]="dataItem.exInterface" name="exInterface{{rowIndex}}"
                                class="k-textbox spaced-input" (input)="markExceptionsDirty()" />
                        </div>
                        <!-- Radio button group for selecting the display option -->
                        <div class="form-column-full">
                            <div class="radio-buttons">
                                <label>
                                    <input type="radio" name="option" value="Action" [(ngModel)]="selectedOption"
                                        (change)="updateDisplayedFields()" />
                                    Action
                                </label>
                                <label>
                                    <input type="radio" name="option" value="Interval" [(ngModel)]="selectedOption"
                                        (change)="updateDisplayedFields()" />
                                    Interval
                                </label>
                                <label>
                                    <input type="radio" name="option" value="Formula" [(ngModel)]="selectedOption"
                                        (change)="updateDisplayedFields()" />
                                    Formula
                                </label>
                            </div>
                        </div>
                        <!-- Conditionally displayed fields -->
                        <div class="form-row" *ngIf="selectedOption === 'Action'">
                            <div class="form-column">
                                <label for="exActionStart{{rowIndex}}">Action Start</label>
                                <kendo-dropdownlist [data]="exceptionVariables" textField="description"
                                    valueField="description" [valuePrimitive]="true"
                                    [(ngModel)]="this.container.exceptions[0].exActionStart"
                                    (valueChange)="onActionVariableChange($event, 'exActionStart')"
                                    name="exActionStart{{rowIndex}}" id="exActionStart{{rowIndex}}">
                                </kendo-dropdownlist>
                            </div>
                            <div class="form-column">
                                <label for="exActionEnd{{rowIndex}}">Action End</label>
                                <kendo-dropdownlist [data]="exceptionVariables" textField="description"
                                    valueField="description" [valuePrimitive]="true"
                                    [(ngModel)]="this.container.exceptions[0].exActionEnd"
                                    (valueChange)="onActionVariableChange($event, 'exActionEnd')"
                                    name="exActionEnd{{rowIndex}}" id="exActionEnd{{rowIndex}}">
                                </kendo-dropdownlist>
                            </div>
                        </div>
                        <div class="form-row" *ngIf="selectedOption === 'Interval'">
                            <div class="form-column">
                                <label for="exActionStartVar{{rowIndex}}">Action Start Variable</label>
                                <kendo-dropdownlist [data]="exceptionVariables" textField="description" valueField="id"
                                    [valuePrimitive]="true" [(ngModel)]="this.container.exceptions[0].exActionStartVar"
                                    (valueChange)="onExActionStartVarChange($event)" name="exActionStartVar{{rowIndex}}"
                                    id="exActionStartVar{{rowIndex}}">
                                </kendo-dropdownlist>
                            </div>
                            <div class="form-column">
                                <label for="exActionInterval{{rowIndex}}">Action Interval</label>
                                <input type="number" [(ngModel)]="this.container.exceptions[0].exActionInterval"
                                    (input)="onActionIntervalChange($event)" name="exActionInterval{{rowIndex}}"
                                    id="exActionInterval{{rowIndex}}" class="k-textbox spaced-input" />
                            </div>
                        </div>
                        <div class="form-row" *ngIf="selectedOption === 'Formula'">
                            <div class="form-column">
                                <label for="exFormulaStart{{rowIndex}}">Formula Start</label>
                                <input type="text" [(ngModel)]="dataItem.exFormulaStart"
                                    name="exFormulaStart{{rowIndex}}" class="k-textbox spaced-input"
                                    (input)="markExceptionsDirty()" />
                            </div>
                            <div class="form-column">
                                <label for="exFormulaEnd{{rowIndex}}">Formula End</label>
                                <input type="text" [(ngModel)]="dataItem.exFormulaEnd" name="exFormulaEnd{{rowIndex}}"
                                    id="exFormulaEnd{{rowIndex}}" class="k-textbox spaced-input"
                                    (input)="markExceptionsDirty()" />
                            </div>
                        </div>
                        <div class="form-column">
                            <label for="exMinimumInterval{{rowIndex}}">Apply Exception if Interval is Not Less Than (mins)</label>
                            <input type="number" [(ngModel)]="dataItem.exMinimumInterval"
                                name="exMinimumInterval{{rowIndex}}" class="k-textbox spaced-input"
                                (input)="markExceptionsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="exMaximumAppliedInterval{{rowIndex}}">Maximum Applied Interval</label>
                            <input type="number" [(ngModel)]="dataItem.exMaximumAppliedInterval"
                                name="exMaximumAppliedInterval{{rowIndex}}" id="exMaximumAppliedInterval{{rowIndex}}"
                                class="k-textbox spaced-input" (input)="markExceptionsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="exMinimumAppliedInterval{{rowIndex}}">Minimum Applied Interval</label>
                            <input type="number" [(ngModel)]="dataItem.exMinimumAppliedInterval"
                                name="exMinimumInterval{{rowIndex}}" id="exMinimumAppliedInterval{{rowIndex}}"
                                class="k-textbox spaced-input" (input)="markExceptionsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="exAddlVariable{{rowIndex}}">Additional Variable Prompt</label>
                            <input type="text" [(ngModel)]="dataItem.exAddlVariable" name="exAddlVariable{{rowIndex}}"
                                id="exAddlVariable{{rowIndex}}" class="k-textbox spaced-input"
                                (input)="markExceptionsDirty()" />
                        </div>
                    </div>
                </div>
            </fieldset>

            <!-- Additional Requirements Section -->
            <fieldset class="section-container">
                <legend class="section-header">
                    <h4>Additional Details</h4>
                </legend>
                <div class="form-container">
                    <div *ngFor="let dataItem of container?.additionalRequirements || [{}]; let rowIndex = index"
                        class="form-row">
                        <div class="form-column">
                            <label for="ignoreBreak{{rowIndex}}">Ignore Break</label>
                            <input type="checkbox" [(ngModel)]="dataItem.ignoreBreak" name="ignoreBreak{{rowIndex}}"
                                id="ignoreBreak{{rowIndex}}" class="styled-checkbox"
                                (change)="markAdditionalRequirementsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="calculateNetWorkTime{{rowIndex}}">Calculate Net Work Time</label>
                            <input type="checkbox" [(ngModel)]="dataItem.calculateNetWorkTime"
                                name="calculateNetWorkTime{{rowIndex}}" class="styled-checkbox"
                                (change)="markAdditionalRequirementsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="useContiguousTime{{rowIndex}}">Use Contiguous Time</label>
                            <input type="checkbox" [(ngModel)]="dataItem.useContiguousTime"
                                name="useContiguousTime{{rowIndex}}" id="useContiguousTime{{rowIndex}}"
                                class="styled-checkbox" (change)="markAdditionalRequirementsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="stayWithinExceptionTimeframe{{rowIndex}}">Stay Within Exception
                                Timeframe</label>
                            <input type="checkbox" [(ngModel)]="dataItem.stayWithinExceptionTimeframe"
                                id="stayWithinExceptionTimeframe{{rowIndex}}"
                                name="stayWithinExceptionTimeframe{{rowIndex}}" class="styled-checkbox"
                                (change)="markAdditionalRequirementsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="exportAsDollarAmount{{rowIndex}}">Export as Dollar Amount</label>
                            <input type="checkbox" [(ngModel)]="dataItem.exportAsDollarAmount"
                                name="exportAsDollarAmount{{rowIndex}}" class="styled-checkbox"
                                (change)="markAdditionalRequirementsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="paidAtHolidayRate{{rowIndex}}">Paid at Holiday Rate</label>
                            <input type="checkbox" [(ngModel)]="dataItem.paidAtHolidayRate"
                                id="paidAtHolidayRate{{rowIndex}}" name="paidAtHolidayRate{{rowIndex}}"
                                class="styled-checkbox" (change)="markAdditionalRequirementsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="regularOverride{{rowIndex}}">Regular Override</label>
                            <kendo-dropdownlist [data]="payCodeExceptions" textField="description" valueField="id"
                                [valuePrimitive]="true" [(ngModel)]="dataItem.regularOverrideTemp"
                                (valueChange)="onOverrideChange('regularOverride', $event)"
                                id="regularOverride{{rowIndex}}" name="regularOverride{{rowIndex}}">
                            </kendo-dropdownlist>
                        </div>

                        <div class="form-column">
                            <label for="otOverride{{rowIndex}}">OT Override</label>
                            <kendo-dropdownlist [data]="payCodeExceptions" textField="description" valueField="id"
                                [valuePrimitive]="true" [(ngModel)]="dataItem.otOverrideTemp"
                                (valueChange)="onOverrideChange('otOverride', $event)" name="otOverride{{rowIndex}}">
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-column">
                            <label for="holidayOverride{{rowIndex}}">Holiday Override</label>
                            <kendo-dropdownlist [data]="payCodeExceptions" textField="description" valueField="id"
                                [valuePrimitive]="true" [(ngModel)]="dataItem.holidayOverrideTemp"
                                (valueChange)="onOverrideChange('holidayOverride', $event)"
                                name="holidayOverride{{rowIndex}}">
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-column">
                            <label for="overtimeHolidayOverride{{rowIndex}}">Overtime Holiday Override</label>
                            <kendo-dropdownlist [data]="payCodeExceptions" textField="description"
                                valueField="description" [valuePrimitive]="true"
                                [(ngModel)]="dataItem.overtimeHolidayOverride"
                                (valueChange)="markAdditionalRequirementsDirty()"
                                id="overtimeHolidayOverride{{rowIndex}}" name="overtimeHolidayOverride{{rowIndex}}">
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-column">
                            <label for="otherCodeOverride{{rowIndex}}">Other Code Override</label>
                            <kendo-dropdownlist [data]="payCodeExceptions" textField="description"
                                valueField="description" [valuePrimitive]="true"
                                [(ngModel)]="dataItem.otherCodeOverride"
                                (valueChange)="markAdditionalRequirementsDirty()" id="otherCodeOverride{{rowIndex}}"
                                name="otherCodeOverride{{rowIndex}}">
                            </kendo-dropdownlist>
                        </div>
                    </div>
                </div>
            </fieldset>
            <!-- Rules Section -->
            <fieldset class="section-container">
                <legend class="section-header">
                    <h4>Rules</h4>
                    <button (click)="addRule()" class="add-rule-button">+</button>
                </legend>
                <div class="form-container">
                    <div *ngIf="container?.rules?.length === 0">
                        <p>No rules. Press + to add.</p>
                    </div>
                    <div *ngFor="let rule of container?.rules || []; let ruleIndex = index" class="form-row rule-item">
                        <div class="form-column-full">
                            <label for="ruleDescription{{ruleIndex}}">Description <span
                                    class="required">*</span></label>
                            <input type="text" [(ngModel)]="rule.ruleDescription" name="ruleDescription{{ruleIndex}}"
                                id="ruleDescription{{ruleIndex}}" class="k-textbox spaced-input"
                                [ngClass]="{'invalid': !rule.ruleDescription}" (input)="markRulesDirty()" required />
                        </div>
                        <div class="form-column-full rule-formula-header">
                            <label for="addRuleFormulaButton">Rule Formulas</label>
                            <button id="addRuleFormulaButton" (click)="addRuleFormula(rule)"
                                class="add-rule-formula-button">
                                +
                            </button>
                        </div>
                        <div class="form-column-full"
                            *ngFor="let formula of rule.ruleFormulas || []; let formulaIndex = index">
                            <div class="form-row rule-formula">
                                <div class="form-column">
                                    <label for="variableId1{{ruleIndex}}{{formulaIndex}}">Variable 1</label>
                                    <kendo-dropdownlist [data]="exceptionVariables" textField="description"
                                        valueField="description" [valuePrimitive]="true"
                                        [(ngModel)]="formula.variableId1"
                                        name="variableId1{{ruleIndex}}{{formulaIndex}}"
                                        id="variableId1{{ruleIndex}}{{formulaIndex}}" (valueChange)="markRulesDirty()">
                                    </kendo-dropdownlist>
                                </div>

                                <div class="form-column">
                                    <label for="operator{{ruleIndex}}{{formulaIndex}}">Operator</label>
                                    <select [(ngModel)]="formula.operator" name="operator{{ruleIndex}}{{formulaIndex}}"
                                        (change)="markRulesDirty()">
                                        <option value="=">=</option>
                                        <option value="In">In</option>
                                        <option value="Not In">Not In</option>
                                        <option value=">">&gt;</option>
                                        <option value="<">&lt;</option>
                                        <option value="<=">&lt;=</option>
                                        <option value=">=">&gt;=</option>
                                        <option value="<>">&lt;&gt;</option>
                                    </select>
                                </div>
                                <div class="form-column">
                                    <label for="valueType{{ruleIndex}}{{formulaIndex}}">Value Type</label>
                                    <div class="radio-buttons">
                                        <label>
                                            <input type="radio" name="valueType{{ruleIndex}}{{formulaIndex}}"
                                                [(ngModel)]="formula.valueType" value="value"
                                                (change)="markRulesDirty()"> Value
                                        </label>
                                        <label>
                                            <input type="radio" name="valueType{{ruleIndex}}{{formulaIndex}}"
                                                [(ngModel)]="formula.valueType" value="variable"
                                                (change)="markRulesDirty()"> Variable
                                        </label>
                                        <label>
                                            <input type="radio" name="valueType{{ruleIndex}}{{formulaIndex}}"
                                                [(ngModel)]="formula.valueType" value="formula"
                                                (change)="markRulesDirty()"> Formula
                                        </label>
                                    </div>
                                </div>
                                <div class="form-column"
                                    *ngIf="formula.valueType === 'value' || formula.valueType === 'formula'">
                                    <label for="variableId2{{ruleIndex}}{{formulaIndex}}">Variable 2</label>
                                    <input type="text" [(ngModel)]="formula.variableId2"
                                        name="variableId2{{ruleIndex}}{{formulaIndex}}"
                                        id="variableId2{{ruleIndex}}{{formulaIndex}}" class="k-textbox spaced-input"
                                        (input)="markRulesDirty()">
                                </div>

                                <div class="form-column" *ngIf="formula.valueType === 'variable'">
                                    <label for="variableId2{{ruleIndex}}{{formulaIndex}}">Variable 2</label>
                                    <kendo-dropdownlist [data]="exceptionVariables" textField="description"
                                        valueField="description" [valuePrimitive]="true"
                                        [(ngModel)]="formula.variableId2"
                                        name="variableId2{{ruleIndex}}{{formulaIndex}}"
                                        id="variableId2{{ruleIndex}}{{formulaIndex}}" (valueChange)="markRulesDirty()">
                                    </kendo-dropdownlist>
                                </div>
                                <div class="form-column" style="text-align: right;">
                                    <button (click)="removeRuleFormula(rule, formulaIndex)"
                                        class="remove-rule-formula-button">-</button>
                                </div>
                            </div>
                            <hr class="rule-formula-separator">
                        </div>
                        <div class="form-column-full" style="text-align: right;">
                            <button (click)="removeRule(ruleIndex)" class="remove-rule-button">-</button>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </div>
</slx-spinner>