import * as _ from 'lodash';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { GridComponent } from '@progress/kendo-angular-grid';
import { destroyService, unsubscribe } from '../../../core/decorators/index';
import { KendoGridStateHelper, KendoGridCustomSelectionHelper, ModalService, PbjNavigationService } from '../../../common/index';
import { IApplicationConfig, appConfig } from '../../../app.config';
import { PbjOrganizationsConfigurationManagementService } from '../../services/pbj-organizations/pbj-organizations-configuration-management.service';
import { PbjOrganizationContainer } from '../../models/pbj-organizations/pbj-organizations-container';
import { PbjOrganizationModel } from '../../models/pbj-organizations/pbj-organization.model';
import { ConfigurationComponentHelper } from '../../utils/configuration-component-helper';
import { EditableListActionKind } from '../../models/index';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { RolesApiService } from '../../services';import { AppSettingsManageService } from '../../../app-settings/services';
import { AppServerConfig } from '../../../app-settings/model/app-server-config';
import { LookupApiService } from '../../../organization'
import { OrganizationTimezone } from '../../../organization/models/lookup/organization-timezone';

@Component({
    moduleId: module.id,
    selector: 'slx-configure-pbj-organizations',
    templateUrl: 'configure-pbj-organizations.component.html',
    styleUrls: ['configure-pbj-organizations.component.scss'],
    providers: [PbjOrganizationsConfigurationManagementService]
})
export class ConfigurePbjOrganizationsComponent implements OnInit, OnDestroy {
   
    public get container(): PbjOrganizationContainer {
        return this.m_container;
    }

    public crudHelper: ConfigurationComponentHelper<PbjOrganizationModel>;
    public gridState: KendoGridStateHelper<PbjOrganizationModel>;
    public selectionHelper: KendoGridCustomSelectionHelper<PbjOrganizationModel>;

    public state: {
        isLoading: boolean;
    };
    public isAccessEmployers: boolean = false;
    public appConfig: IApplicationConfig;

    @destroyService()
    public management: PbjOrganizationsConfigurationManagementService;

    private m_container: PbjOrganizationContainer;

    @ViewChild('kendoGrid', {static: false})
    private set grid(value: GridComponent) {
        if (this.crudHelper) this.crudHelper.grid = value;
        this.m_grid = value;
    }
    private get grid(): GridComponent {
        return this.m_grid;
    }
    private m_grid: GridComponent;
    
    @unsubscribe()
    private stateSubscription: Subscription;
    @unsubscribe()
    private gridSelectSubscription: Subscription;
    @unsubscribe()
    private savedSubscription: Subscription;
    private appSettingManageService: AppSettingsManageService;
    public nextgenPhasedRollout: boolean = false;
    public isNextgenPayrollEnabled: boolean = false;
    public organizationTimezones: OrganizationTimezone[];

    constructor(management: PbjOrganizationsConfigurationManagementService, private modalService: ModalService , private route: ActivatedRoute,private router: Router, private rolesApiService :RolesApiService
      , appSettingManageService: AppSettingsManageService,private lookup: LookupApiService) {

        this.management = management;

        this.gridState = new KendoGridStateHelper<PbjOrganizationModel>();
        this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, true);

        this.crudHelper = new ConfigurationComponentHelper<PbjOrganizationModel>();
        this.crudHelper.gridState = this.gridState;
        this.crudHelper.selectionHelper = this.selectionHelper;
        this.crudHelper.management = management;
        
        this.appSettingManageService = appSettingManageService;
    }

    public ngOnInit(): void {
      this.getSettings();

        this.appConfig = appConfig;

        this.state = {
            isLoading: false
        };

       
        this.savedSubscription = this.management.onItemSaved$.subscribe((item: PbjOrganizationModel) => {
            this.gridState.editedRecord = null;
            this.gridState.savedEditedRecord = null;
        });

        this.stateSubscription = this.management.onStateChanged$.subscribe((state: { isLoading: boolean, configureMode: boolean, copyMode: boolean }) => {
            if (_.has(state, 'isLoading')) this.state.isLoading = state.isLoading;
        });

        this.gridSelectSubscription = this.gridState.onSelectionChanged.subscribe((records: PbjOrganizationModel[]): void => {
            this.crudHelper.selectionChange(_.first(records), 0);
        });

        this.lookup.getOrganizationTimezones().then((results:OrganizationTimezone[])=>{
          this.organizationTimezones = results;
        });

        this.crudHelper.grid = this.grid;
        this.crudHelper.init();
        this.management.init();
        let roles,userData,loginRole;	
        userData = JSON.parse(localStorage.getItem("slx-worklinx-v6-app"));	
        loginRole = userData.session.user.roles[0];	       	
        this.rolesApiService.getRoleAccessTable().then( res => {	
          roles = res.roles;	
            roles.forEach(ele => {	
              if(loginRole === ele.name){	
                if(ele.menuModules){	
                  ele.menuModules.forEach( element => {	
                    if(element.name == 'Configuration'){	
                      element.menus.forEach( menu => {	
                        if(menu.name == 'General'){	
                          menu.subMenus.forEach( subMenu => {	                           
                            if(subMenu.name == 'Organizations'){	
                              if(subMenu.isEnabled){	
                                this.isAccessEmployers = true;	                               
                              }	
                            }	
                          })	
                        }	
                      })	
                    }	
                  })	
                }	
              }	
            })	         
        });	
    }

    // Must be, see #issueWithAOTCompiler
    public ngOnDestroy(): void {
        if (this.crudHelper) {
            this.crudHelper.destroy();
        }
    }
    public navigateToACA():void{
        console.log(this.route.pathFromRoot[2] )
        this.router.navigate(['configure_employers'], { relativeTo: this.route.pathFromRoot[2] });
      
        // this.router.navigateByUrl('/configure_employers');     
    }
    public extenalEditorAction(action: EditableListActionKind): void {
        if (action === EditableListActionKind.COMPLETE_EDIT) {
            this.crudHelper.onSaveItem({ dataItem: this.management.editingItem, isNew: this.management.isEditingNewItem });
        } else if (action === EditableListActionKind.CANCEL_EDIT) {
            this.crudHelper.onCancelItem(this.management.editingItem);
        }
    }

    public onMobileRendererEvent(action: EditableListActionKind, item: PbjOrganizationModel, index: number): void {
        if (action === EditableListActionKind.SELECTION_CHANGE) {
            this.crudHelper.selectionChange(item, index);
        }
    }
        
    public async getSettings(): Promise<void> {
        let appServerConfig: AppServerConfig = await this.appSettingManageService.getAppServerConfig();
        this.nextgenPhasedRollout = appServerConfig.NextgenPhasedRollout;
        this.isNextgenPayrollEnabled = appServerConfig.IsNextgenPayrollEnabled;
    }

}
