import * as moment from 'moment';
import { ITimeclockDefinition, IPunchType, PunchType, TimeclockDefinition, Position } from '../../../organization/models/index';
import { IClosingPunchAttributes, ClosingPunchAttributes } from './closing-punch-attributes';

/*
export enum PunchStatus {
  valid = 'valid',
  validEdited = 'validEdited',
  invalid = 'invalid',
  scheduled = 'scheduled',
  ess = 'ess',
  deleted = 'deleted',
  edited = 'edited',
  request = 'request'
}*/

export enum PunchSource {
  Unknown = 'Unknown',
  Timeclock = 'Timeclock',
  Schedule = 'Schedule',
  Request = 'Request',
  Manual = 'Manual',
  Geo = 'Geo'
}

export enum PunchStatus {
  Unknown = 'Unknown',
  Valid = 'Valid',
  Invalid = 'Invalid',
  Pending = 'Pending',
  Edited = 'Edited',
  Deleted = 'Deleted',
  Overlap = 'Overlap',
  InvalidLogin = 'InvalidLogin'
}

export enum PunchDisplay {
  Unknown = 'Unknown',
  Edited = 'Edited',
  ValidPunch = 'Valid Punch',
  ValidEdited = 'Valid Edited',
  Schedule = 'Schedule',
  ScheduleExempt = 'Schedule Exempt',
  PunchRequest = 'Punch Request',
  InvalidPunch = 'Invalid Punch',
  DeletedPunch = 'Deleted Punch',
  Overlap = 'Overlap',
  InvalidLogin = 'Invalid Login'
}

export interface ILinePunch {
  type: IPunchType;
  time: string;
  roundedTime: string;
  closingPunch?: IClosingPunchAttributes;
  inService: boolean;
  timeclock: ITimeclockDefinition;
  isDeleted: boolean;
  comment: string;
  punchStatus: string;
  punchSource: string;
  isEdited: boolean;
  isExempt: boolean;
  latitude?: number;
  longitude?: number;
  punchDate: string;
  geoPunchDescription?:string;
}



export class LinePunch {
  //public displayTag: string;
  public punchStatus: PunchStatus;
  public punchSource: PunchSource;
  public isEditedAndValidated: boolean;
  public isDeleted: boolean;
  public closingPunch?: ClosingPunchAttributes;
  public comment: string;
  public positionsString: string;
  public position: Position;
  public canBeClosing: boolean;
  //ESS properties
  //Schedule properties
  public show: boolean;
  public isExempt: boolean;
  public latitude: number;
  public longitude: number;
  public geoPunchDescription:string;

  public get punchDisplay(): string {
    if (this.isEditedAndValidated) {
      if (this.punchStatus === PunchStatus.Overlap) {
        return PunchDisplay.Overlap;
      }
      return PunchDisplay.Edited;
    }
    if (this.punchStatus === PunchStatus.Valid) {
      if (this.punchSource === PunchSource.Manual) {
        return PunchDisplay.ValidEdited;
      }
      return PunchDisplay.ValidPunch;
    }
    if (this.punchStatus === PunchStatus.Edited) {
      return PunchDisplay.ValidEdited;
    }
    if (this.punchStatus === PunchStatus.Unknown || this.punchStatus === PunchStatus.Pending) {
      if (this.punchSource === PunchSource.Schedule) {
        if (this.isExempt) {
          return PunchDisplay.ScheduleExempt;
        } else {
          return PunchDisplay.Schedule;
        }
      }
      if (this.punchSource === PunchSource.Request) {
        return PunchDisplay.PunchRequest;
      }
    }
    if (this.punchStatus === PunchStatus.Invalid) {
      return PunchDisplay.InvalidPunch;
    }
    if (this.punchStatus === PunchStatus.Deleted) {
      return PunchDisplay.DeletedPunch;
    }
    if (this.punchStatus === PunchStatus.InvalidLogin) {
      return PunchDisplay.InvalidLogin;
    }
    return PunchDisplay.Unknown;
  }

  public get isDirty(): boolean {
    if (this.m_otypeName !== (this.type ? this.type.name : null)) {
      return true;
    }
    if (this.m_otime && this.time && !moment(this.m_otime).isSame(this.time)) {
      return true;
    }
    if (!this.m_otime && this.time || this.m_otime && !this.time) {
      return true;
    }
    if ((this.m_oroundedTime && this.roundedTime && !moment(this.m_oroundedTime).isSame(this.roundedTime))) {
      return true;
    }
    if (!this.m_oroundedTime && this.roundedTime || this.m_oroundedTime && !this.roundedTime) {
      return true;
    }
    if (this.m_oinservice !== this.inService) {
      return true;
    }
    if (this.m_otimeclockId !== (this.timeclock ? this.timeclock.id : null)) {
      return true;
    }
    if (this.isEditedAndValidated) {
      return true;
    }
    return false;
  }

  public get isGeoPunch(): boolean {
    return this.punchSource === PunchSource.Geo;
  }

  public type: PunchType;
  public time: Date;
  public roundedTime: Date;
  public inService: boolean;
  public timeclock: TimeclockDefinition;
  public punchDate: Date;
  public autoOverlap: boolean = false;

  private m_otypeName: string;
  private m_otime: Date;
  private m_oroundedTime: Date;
  private m_oinservice: boolean;
  private m_otimeclockId: number;


  public resetDirty(): void {
    this.m_otypeName = this.type ? this.type.name : null;
    this.m_otime = this.time;
    this.m_oroundedTime = this.roundedTime;
    this.m_oinservice = this.inService;
    this.m_otimeclockId = this.timeclock ? this.timeclock.id : null;
  }
}
