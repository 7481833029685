import * as tslib_1 from "tslib";
import { NotificationMessage } from './../../../../app.messages';
import { ErrorHandlingService } from './../../../../core/services/error-handling/error-handling.service';
import { OnInit, OnDestroy, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/debounceTime';
import { process, State } from '@progress/kendo-data-query';
import { GridComponent, EditEvent } from '@progress/kendo-angular-grid';
import { appConfig } from '../../../../app.config';
import { KendoGridStateHelper, DialogOptions } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { ColumnManagementService, ModalService } from '../../../../common/services/index';
import { DailyPunchesManagementService, DailyTimecardManagementService, TimecardsCheckRightsService } from '../../../services/index';
import { LinePunch, PunchStatus, PunchLogRequest, PunchDisplay, PunchSource, punchWrapper, DailyLinePunchActions, TimeCardDisplayOptions, TimecardAddCommentsReq } from '../../../models/index';
import { LookupService } from '../../../../organization/services/index';
import { Lookup, PunchType, Position, LookupType } from '../../../../organization/models/index';
import { PunchLogsDialogComponent } from '../punch-logs-dialog/punch-logs-dialog.component';
import { GeoPunchDialogComponent } from '../geo-punch-dialog/geo-punch-dialog.component';
import { GeoPunchDialogOptions } from '../../../models/index';
import { TimecardAddCommentsDialogComponent } from '../../punches/add-comments-dialog/add-comments-dialog.component';
import { AppSettingsManageService } from '../../../../app-settings/services/index';
import { dateTimeUtils } from '../../../../common/utils/index';
var DailyLinePunchGridComponent = /** @class */ (function () {
    function DailyLinePunchGridComponent(managementService, changeDetector, lookupService, columnManagementService, modalService, timecardsCheckService, appSettingsManageService, errorService, dailyTimeCardManagementService) {
        this.managementService = managementService;
        this.changeDetector = changeDetector;
        this.lookupService = lookupService;
        this.columnManagementService = columnManagementService;
        this.modalService = modalService;
        this.timecardsCheckService = timecardsCheckService;
        this.appSettingsManageService = appSettingsManageService;
        this.errorService = errorService;
        this.dailyTimeCardManagementService = dailyTimeCardManagementService;
        this.groupName = 'LinePunches';
        this.removedPunches = [];
        this.unknown = PunchDisplay.Unknown;
        this.edited = PunchDisplay.Edited;
        this.validPunch = PunchDisplay.ValidPunch;
        this.validEdited = PunchDisplay.ValidEdited;
        this.schedule = PunchDisplay.Schedule;
        this.scheduleExempt = PunchDisplay.ScheduleExempt;
        this.punchRequest = PunchDisplay.PunchRequest;
        this.invalidPunch = PunchDisplay.InvalidPunch;
        this.invalidLogin = PunchDisplay.InvalidLogin;
        this.overlap = PunchDisplay.Overlap;
        this.hasOverlapPunches = false;
        this.overlapIndex = -1;
        this.isPaidRestBreakEnabled = false;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.sort = [{ field: 'punch.time', dir: 'asc' }];
        this.firstLoad = true;
    }
    Object.defineProperty(DailyLinePunchGridComponent.prototype, "rowInEditing", {
        get: function () {
            return this.gridState.editedRowIndex !== undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DailyLinePunchGridComponent.prototype, "hasRemoved", {
        get: function () {
            return this.removedPunches.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DailyLinePunchGridComponent.prototype, "isMobile", {
        get: function () {
            return (screen.width <= appConfig.mobileMaxWidth);
        },
        enumerable: true,
        configurable: true
    });
    DailyLinePunchGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.columnManagementService.init('DailyLinePunchGridComponent');
        this.columnManagementService.initGroup(this.groupName, 6);
        this.appSettingsManageService.getAppServerConfig()
            .then(function (config) {
            _this.requireTimeclockForPunches = config.requireTimeclockForPunches;
            _this.preventOverlappingPunches = config.preventOverlappingPunches;
        });
        this.managementService.isPaidRestBreakEnabled$.subscribe(function (isPaidRestBreakEnabled) {
            _this.isPaidRestBreakEnabled = isPaidRestBreakEnabled;
            _this.loadPunchTypes(isPaidRestBreakEnabled);
        });
        this.dailyTimeCardManagementService.isPaidRestBreakEnabled$.subscribe(function (isPaidRestBreakEnabled) {
            _this.isPaidRestBreakEnabled = isPaidRestBreakEnabled;
            _this.loadPunchTypes(isPaidRestBreakEnabled);
        });
        if (!this.employeeLookupsFirstLoadStarted) {
            this.loadEmpLookups();
        }
        this.onLoadedSubscription = this.managementService.onLoadedPairs$.subscribe(function (records) {
            _this.savedRecords = records;
        });
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.changesSubscription = this.columnManagementService.columnsChanged$.filter(function (event) { return event.group === _this.groupName; })
            .debounceTime(500)
            .subscribe(function (event) {
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        // save punches from Save/Recal
        this.onOverlapPuchesActionSubscription = this.dailyTimeCardManagementService.overlapingPunchesAction$.subscribe(function (event) {
            if (_this.preventOverlappingPunches) {
                _this.records = _this.sortByTime(_this.records);
                var res = _this.hasMatchedPair(_this.records, null);
                if (_this.gridState.view && _this.gridState.view.data && _this.gridState.view.data.length > 0 && res.hasMatchedPair) {
                    _.forEach(res.matchedPair, function (pair) {
                        _this.performOverlapCheck(pair, true);
                    });
                }
                else {
                    _this.dailyTimeCardManagementService.overlapPunchesDesc(false);
                }
            }
            else {
                _this.dailyTimeCardManagementService.overlapPunchesDesc(false);
            }
        });
        this.gridSelectionSubscription = this.gridState.onSelectionChanged.subscribe(function (v) {
            _this.selectedRecords = v;
        });
        this.gridSaveSubscription = this.gridState.onSave$.subscribe(function (event) {
            if (event.isNew) {
                _this.addPunch(event.dataItem);
            }
            else {
                // this.puchdetails = event.dataItem;
                _this.updatePunch(event.dataItem);
            }
        });
        this.startEditSubscription = this.gridState.onEdit$.subscribe(function (item) {
            _this.managementService.startChanges();
        });
        this.cancelEditSubscription = this.gridState.onCancel$.subscribe(function (event) {
            //_.remove(this.records, (w: punchWrapper) => w.isNew);
            _this.refreshGrid();
            _this.validatePairs(_this.records);
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
            _this.managementService.cancelChanges();
        });
        this.gridRemoveSubscription = this.gridState.onRemove$.subscribe(function (event) {
            _this.removePunch(event.dataItem);
        });
        this.changedEditSubscription = this.managementService.canEditChanged$.subscribe(function (canEdit) {
            _this.canEdit = canEdit;
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.loadSubscription = this.managementService.onLoaded$.subscribe(function (model) {
            _this.hasOverlapPunches = false;
            _this.dailyTimeCardManagementService.setHasOverlap(false);
        });
        this.dailyTimeCardManagementService.setHasOverlapToTC$.subscribe(function (setHasOverlapToTC) {
            _this.hasOverlapPunches = false;
        });
        this.onFilterAppliedSubscription = this.managementService.onFilterApplied$.subscribe(function (records) {
            _this.records = _this.transformPunchesPairs(records);
            _this.hasChanges = !!_.find(_this.savedRecords, function (p) { return p.isDirty; });
            _this.refreshGrid();
            if (records && _this.firstLoad) {
                _this.firstLoad = false;
                if (!_this.hasChanges) {
                    //first punch to edit
                    var firstInvalidIndex = _.findIndex(_this.gridState.view.data, function (val) {
                        return val.punch.punchStatus !== PunchStatus.Valid;
                    });
                    if (firstInvalidIndex >= 0 && _this.userActions.canEditTimecards && _this.userActions.canSave && _this.canEdit) {
                        var event_1 = {
                            sender: _this.grid,
                            rowIndex: firstInvalidIndex,
                            dataItem: _this.gridState.view.data[firstInvalidIndex],
                            isNew: false
                        };
                        _this.editHandler(event_1);
                    }
                }
            }
            _this.validatePairs(_this.records);
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
    };
    DailyLinePunchGridComponent.prototype.loadPunchTypes = function (isPaidRestBreakEnabled) {
        var _this = this;
        if (isPaidRestBreakEnabled === void 0) { isPaidRestBreakEnabled = false; }
        this.lookupService.getLookup({ lookupType: 'punchTypes' })
            .then(function (lookup) {
            _this.punchTypesLookup = lookup;
            _this.invalidLoginPunchTypesLookup = _.cloneDeep(lookup);
            _this.invalidLoginPunchTypesLookup.items.splice(0, 0, { "id": 999, "name": "NA", "description": "", "previousPunchTypeId": null, "canBeFirst": true, "canBeLast": false, "isIn": true, "isOut": false, "isBreak": false, "isLunch": false, "isTraining": false });
            _this.invalidLoginPunchTypesLookup.items = isPaidRestBreakEnabled ? _this.invalidLoginPunchTypesLookup.items : _.filter(_this.invalidLoginPunchTypesLookup.items, function (type) { return !(type.name === "in from paid break" || type.name === "out to paid break"); });
            _this.punchTypesInLookup = new Lookup();
            _this.punchTypesInLookup.titleField = lookup.titleField;
            _this.punchTypesInLookup.valueField = lookup.valueField;
            _this.punchTypesInLookup.items = isPaidRestBreakEnabled ? _.filter(lookup.items, function (type) { return (type.isIn); }) : _.filter(lookup.items, function (type) { return (type.isIn && type.name != "in from paid break"); });
            _this.defaultInType = _.find(_this.punchTypesInLookup.items, function (type) { return type.canBeFirst; });
            if (!_this.defaultInType) {
                _this.defaultInType = _.first(_this.punchTypesInLookup.items);
            }
            _this.punchTypesOutLookup = new Lookup();
            _this.punchTypesOutLookup.titleField = lookup.titleField;
            _this.punchTypesOutLookup.valueField = lookup.valueField;
            _this.punchTypesOutLookup.items = isPaidRestBreakEnabled ? _.filter(lookup.items, function (type) { return (type.isOut); }) : _.filter(lookup.items, function (type) { return (type.isOut && type.name != "out to paid break"); });
            _this.defaultOutType = _.find(_this.punchTypesOutLookup.items, function (type) { return type.canBeLast; });
            if (!_this.defaultOutType) {
                _this.defaultOutType = _.find(_this.punchTypesOutLookup.items);
            }
        });
    };
    DailyLinePunchGridComponent.prototype.getRoundedInterval = function (punch) {
        var index = _.findIndex(this.savedRecords, function (e) { return (e.type.name === punch.type.name && e.time.toString() === punch.time.toString()); });
        if (index === -1) {
            return null;
        }
        var previousRecord = this.savedRecords[index - 1];
        var currentRecord = this.savedRecords[index];
        if (currentRecord.type.name === "out to paid break" && previousRecord && previousRecord.type.isIn) {
            var timeInterval = currentRecord.time.getTime() - previousRecord.time.getTime();
            var roundedIntervalInHrs = timeInterval / (1000 * 60 * 60);
            return dateTimeUtils.convertFromDtoDurationNumber(+roundedIntervalInHrs, 'h');
        }
        else if (currentRecord.type.name === "in from paid break" && previousRecord && previousRecord.type.name === "out to paid break") {
            var timeInterval = currentRecord.time.getTime() - previousRecord.time.getTime();
            var roundedIntervalInHrs = timeInterval / (1000 * 60 * 60);
            return dateTimeUtils.convertFromDtoDurationNumber(+roundedIntervalInHrs, 'h');
        }
        else if (previousRecord && previousRecord.type.name === "in from paid break" && currentRecord.type.isOut) {
            var timeInterval = currentRecord.time.getTime() - previousRecord.time.getTime();
            var roundedIntervalInHrs = timeInterval / (1000 * 60 * 60);
            return dateTimeUtils.convertFromDtoDurationNumber(+roundedIntervalInHrs, 'h');
        }
        return punch.closingPunch ? punch.closingPunch.roundedInterval : '';
    };
    DailyLinePunchGridComponent.prototype.performOverlapCheck = function (matchedPair, isFromSaveRecal, isFromPunches) {
        var _this = this;
        if (isFromPunches === void 0) { isFromPunches = false; }
        this.dailyTimeCardManagementService.hasOverlap(matchedPair, this.records, this.defaultPunchTime, this.employeeId).then(function (res) {
            _this.hasOverlapPunches = false;
            if (res && res.hasOverlapPunches) {
                if (res.records) {
                    _this.records = res.records;
                }
                _this.hasOverlapPunches = res.hasOverlapPunches;
                _this.emitChanges();
                _this.validatePairs(_this.records);
                if (isFromSaveRecal)
                    _this.dailyTimeCardManagementService.overlapPunchesDesc(true);
                return;
            }
            else if (isFromPunches) {
                _.forEach(_this.records, function (x) {
                    if (x.punch.punchStatus == PunchStatus.Overlap)
                        x.punch.punchStatus = PunchStatus.Edited;
                });
                _this.validatePairs(_this.records);
                if (!_this.hasInMissingPunch && !_this.hasOutMissingPunch) {
                    _this.doSave();
                    return;
                }
                _this.modalService.globalAnchor.openConfirmDialog("Warning", 'Missing punches appear on this timecard, do you wish to proceed?', function (result) {
                    if (result) {
                        _this.doSave();
                    }
                });
            }
            else {
                _.forEach(_this.records, function (x) {
                    if (x.punch.punchStatus == PunchStatus.Overlap)
                        x.punch.punchStatus = PunchStatus.Edited;
                });
                _this.emitChanges();
                _this.validatePairs(_this.records);
                if (isFromSaveRecal) {
                    _this.dailyTimeCardManagementService.overlapPunchesDesc(false);
                }
            }
        });
    };
    DailyLinePunchGridComponent.prototype.ngOnDestroy = function () {
        // Must be, see #issueWithAOTCompiler
    };
    DailyLinePunchGridComponent.prototype.ngOnChanges = function (change) {
        if (change['employeeId']) {
            this.loadEmpLookups();
        }
        if (change['defaultPunchTime']) {
            this.filterPositionLookup();
        }
    };
    DailyLinePunchGridComponent.prototype.loadEmpLookups = function () {
        var _this = this;
        if (this.employeeId) {
            this.employeeLookupsFirstLoadStarted = true;
            this.lookupService.getLookup({ lookupType: 'timeclockDefinition', orgLevelId: undefined, employeeId: this.employeeId }).then(function (lookup) {
                _this.timeclocksLookup = lookup;
                if (_this.gridState.editedRecord) {
                    _this.gridState.editedRecord.punch.timeclock = _.first(_this.timeclocksLookup.items);
                }
            });
            this.lookupService.getLookup({ lookupType: 'position', orgLevelId: undefined, employeeId: this.employeeId, isActive: false, updateCacheForced: true }).then(function (lookup) {
                _this.positionsOriginLookup = lookup;
                _this.filterPositionLookup();
            });
        }
    };
    DailyLinePunchGridComponent.prototype.filterPositionLookup = function () {
        var _this = this;
        if (!this.positionsOriginLookup) {
            return;
        }
        this.positionsLookup = _.cloneDeep(this.positionsOriginLookup);
        this.positionsLookup.items = _.filter(this.positionsLookup.items, function (p) {
            var res = (moment(_this.defaultPunchTime).isSameOrAfter(p.startDate) && (!p.endDate || moment(_this.defaultPunchTime).isSameOrBefore(p.endDate)));
            return res;
        });
        this.positionsLookup.items.unshift({ id: 0, name: '' });
    };
    DailyLinePunchGridComponent.prototype.showComment = function (punch) {
        var options = new DialogOptions();
        options.message = punch.comment;
        options.height = 300;
        options.width = 400;
        this.modalService.globalAnchor.openInfoDialogEx("Employee " + this.employeeName + " comment", options, function (result) {
            //nothing to do;
        });
    };
    DailyLinePunchGridComponent.prototype.showPunchLogs = function () {
        var req = new PunchLogRequest();
        req.employeeId = this.employeeId;
        req.employeeName = this.employeeName;
        req.startDate = this.defaultPunchTime;
        req.endDate = this.defaultPunchTime;
        PunchLogsDialogComponent.openDialog('Punch Log', req, this.modalService, function (result, cmd) {
            //nothing to do
        });
    };
    DailyLinePunchGridComponent.prototype.emitChanges = function () {
        var punches = this.extractPairs(this.records);
        this.managementService.emitChanges(punches);
    };
    DailyLinePunchGridComponent.prototype.onDiscardChanges = function () {
        if (this.gridState.editedRowIndex !== undefined) {
            this.gridState.cancelHandler({ sender: this.grid, rowIndex: this.gridState.editedRowIndex });
        }
        this.hasOverlapPunches = false;
        this.hasChanges = false;
        this.removedPunches = [];
        this.records = this.transformPunchesPairs(this.savedRecords);
        this.managementService.discardChanges();
        this.refreshGrid();
        if (!this.userActions.canSave) {
            this.emitChanges();
        }
        this.validatePairs(this.records);
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
        this.dailyTimeCardManagementService.setHasOverlap(false);
        this.overlapIndex = -1;
    };
    // save punches from punches screen
    DailyLinePunchGridComponent.prototype.onSaveChanges = function () {
        var _this = this;
        if (this.preventOverlappingPunches && this.gridState.view.data && this.gridState.view.data.length > 0) {
            this.records = this.sortByTime(this.records);
            var res = this.hasMatchedPair(this.records, null);
            if (res.hasMatchedPair) {
                _.forEach(res.matchedPair, function (pair) {
                    _this.performOverlapCheck(pair, false, true);
                });
            }
            else {
                this.validatePairs(this.records);
                if (!this.hasInMissingPunch && !this.hasOutMissingPunch) {
                    this.doSave();
                    return;
                }
                this.modalService.globalAnchor.openConfirmDialog("Warning", 'Missing punches appear on this timecard, do you wish to proceed?', function (result) {
                    if (result) {
                        _this.doSave();
                    }
                });
            }
        }
        else {
            this.validatePairs(this.records);
            if (!this.hasInMissingPunch && !this.hasOutMissingPunch) {
                this.doSave();
                return;
            }
            this.modalService.globalAnchor.openConfirmDialog("Warning", 'Missing punches appear on this timecard, do you wish to proceed?', function (result) {
                if (result) {
                    _this.doSave();
                }
            });
        }
    };
    DailyLinePunchGridComponent.prototype.resizeEvent = function (event) {
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    DailyLinePunchGridComponent.prototype.onAddPunch = function (grid) {
        var _this = this;
        if (!this.canEditOwnTimecards())
            return;
        this.gridState.closeEditor(grid);
        var lastPanch;
        var item;
        var p = new LinePunch();
        p.show = true;
        p.punchStatus = PunchStatus.Edited;
        p.punchSource = PunchSource.Manual;
        p.comment = '';
        p.isEditedAndValidated = true;
        if (this.selectedRecords && this.selectedRecords.length > 0) {
            lastPanch = _.last(this.selectedRecords);
        }
        else {
            var orderedPunches = _.orderBy(this.records, function (p) { return p.punch.time; });
            lastPanch = _.last(orderedPunches);
        }
        item = { punch: p, id: -1, isNew: true };
        if (!lastPanch) {
            p.type = this.defaultInType;
            p.time = this.defaultPunchTime;
            this.records.push(item);
        }
        else {
            var next = _.find(this.punchTypesLookup.items, function (t) { return t.previousPunchTypeId === lastPanch.punch.type.id; });
            if (next) {
                p.type = next;
            }
            else {
                if (lastPanch.punch.type.isIn) {
                    p.type = this.defaultOutType;
                }
                else {
                    p.type = this.defaultInType;
                }
            }
            p.time = moment(lastPanch.punch.time).add(30, 'm').toDate();
            if (this.timeclocksLookup)
                p.timeclock = _.first(this.timeclocksLookup.items);
            //const index: number = _.indexOf(this.records, lastPanch);
            //this.newItemIndex = index + 1;
            //this.records.splice(this.newItemIndex, 0, item);
            this.records.push(item);
            this.emitChanges();
        }
        this.refreshGrid();
        if (this.preventOverlappingPunches) {
            this.records = this.sortByTime(this.records);
            var res = this.hasMatchedPair(this.records, item);
            if (res.hasMatchedPair) {
                _.forEach(res.matchedPair, function (pair) {
                    _this.performOverlapCheck(pair, false);
                });
            }
            else {
                _.forEach(this.records, function (x) {
                    if (x.punch.punchStatus == PunchStatus.Overlap) {
                        x.punch.punchStatus = PunchStatus.Edited;
                    }
                });
                this.emitChanges();
            }
        }
        var index = _.indexOf(this.gridState.view.data, item);
        var event = {
            sender: this.grid,
            rowIndex: index,
            dataItem: this.gridState.view.data[index],
            isNew: true
        };
        // this.gridState.editHandler(event);
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    DailyLinePunchGridComponent.prototype.onInvalidate = function (dataItem) {
        if (!dataItem || !this.canEditOwnTimecards()) {
            return;
        }
        dataItem.punch.isEditedAndValidated = false;
        this.emitChanges();
    };
    DailyLinePunchGridComponent.prototype.editHandler = function (event) {
        if (this.overlapIndex != -1 && this.overlapIndex != event.rowIndex) {
            return;
        }
        this.gridState.editHandler(event);
        if (!event.dataItem.punch.timeclock) {
            if (this.timeclocksLookup && this.timeclocksLookup.items) {
                this.gridState.editedRecord.punch.timeclock = _.first(this.timeclocksLookup.items);
            }
        }
    };
    DailyLinePunchGridComponent.prototype.removeHandler = function (event) {
        var _this = this;
        if (this.canEditOwnTimecards()) {
            if (event.dataItem.punch.punchDisplay === PunchDisplay.InvalidLogin) {
                this.modalService.globalAnchor.openConfirmDialog("Warning", 'Are you sure you want to delete this entry?', function (result) {
                    if (result) {
                        _this.managementService.removeInvalidLogin(event.dataItem.punch.time, _this.employeeId).
                            then(function (result) {
                            if (result) {
                                _this.gridState.onRemove$.next({ dataItem: event.dataItem, rowIndex: event.rowIndex });
                                _this.gridState.refreshGrid();
                            }
                        });
                    }
                });
            }
            else {
                this.gridState.removeHandler(event);
            }
        }
    };
    DailyLinePunchGridComponent.prototype.saveHandler = function (event) {
        if (this.canEditOwnTimecards()) {
            this.gridState.saveHandler(event);
        }
    };
    DailyLinePunchGridComponent.prototype.cancelHandler = function (event) {
        if (this.canEditOwnTimecards()) {
            this.gridState.cancelHandler(event);
        }
    };
    DailyLinePunchGridComponent.prototype.onShowMap = function (punch) {
        // punch.latitude, punch.longitude
        var options = new GeoPunchDialogOptions(punch.latitude, punch.longitude, punch.punchDisplay === PunchDisplay.InvalidPunch, 600, 400, 17);
        GeoPunchDialogComponent.openDialog(options, this.modalService);
    };
    DailyLinePunchGridComponent.prototype.canEditOwnTimecards = function () {
        if (!this.timecardsCheckService.userCanEditOwnTimecard(this.employeeId)) {
            this.errorService.errorWithoutLog(new NotificationMessage('Timecard is readonly.', "You are not authorized to edit your own timecard"), {});
            return false;
        }
        return true;
    };
    DailyLinePunchGridComponent.prototype.validatePairs = function (records) {
        var punches = _.map(records, function (w) { return w.punch; });
        this.managementService.markClosingPunches(punches);
        var result = this.managementService.validatePairs(punches);
        this.hasInMissingPunch = result.hasInMissingPunch;
        this.hasOutMissingPunch = result.hasOutMissingPunch;
    };
    DailyLinePunchGridComponent.prototype.doSave = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, event_2, pairs;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.checkComment()];
                    case 1:
                        res = _a.sent();
                        if (!res) {
                            return [2 /*return*/];
                        }
                        if (this.gridState.editedRowIndex !== undefined) {
                            event_2 = {
                                sender: this.grid,
                                rowIndex: this.gridState.editedRowIndex,
                                dataItem: this.gridState.editedRecord,
                                isNew: false
                            };
                            this.gridState.saveHandler(event_2);
                        }
                        pairs = this.extractPairs(this.records);
                        this.managementService.applyChanges({ punches: pairs, comment: this.comment });
                        this.hasChanges = false;
                        this.removedPunches = [];
                        return [2 /*return*/];
                }
            });
        });
    };
    DailyLinePunchGridComponent.prototype.transformRecords = function (records) {
        var id = 0;
        var res = _.reduce(records, function (result, punch) {
            var rs = [];
            rs.push({ punch: punch, id: id, isNew: false });
            id++;
            return (result || []).concat(rs);
        }, []);
        return res;
    };
    DailyLinePunchGridComponent.prototype.transformPunchesPairs = function (records) {
        var pairs = _.map(records, function (w) { return _.cloneDeep(w); });
        return this.transformRecords(pairs);
    };
    DailyLinePunchGridComponent.prototype.extractPairs = function (records) {
        return _.map(this.records, function (w) { return w.punch; });
    };
    DailyLinePunchGridComponent.prototype.refreshRecords = function () {
        var id = 0;
        //let pairs: LinePunch[] = this.extractPairs(this.records);
        //this.records = this.transformRecords(pairs);
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    DailyLinePunchGridComponent.prototype.addPunch = function (item) {
        this.hasChanges = true;
        this.records.unshift(item);
        this.refreshRecords();
        this.validatePairs(this.records);
        this.emitChanges();
    };
    DailyLinePunchGridComponent.prototype.updatePunch = function (item) {
        var _this = this;
        var originalRecords = this.transformPunchesPairs(this.savedRecords);
        var originalRecord = originalRecords.find(function (x) { return (x.id === item.id && x.punch.type.id === item.punch.type.id); });
        if (!item) {
            return;
        }
        this.hasChanges = true;
        item.punch.isEditedAndValidated = true;
        if (originalRecord && item !== originalRecord && item.punch.punchSource !== PunchSource.Manual) {
            item.punch.punchSource = PunchSource.Manual;
        }
        if (this.preventOverlappingPunches) {
            this.records = this.sortByTime(this.records);
            var res = this.hasMatchedPair(this.records, item);
            if (res.hasMatchedPair) {
                _.forEach(res.matchedPair, function (pair) {
                    _this.performOverlapCheck(pair, false);
                });
            }
            else {
                _.forEach(this.records, function (x) {
                    if (x.punch.punchStatus == PunchStatus.Overlap)
                        x.punch.punchStatus = PunchStatus.Edited;
                });
                this.emitChanges();
                this.hasOverlapPunches = false;
                this.dailyTimeCardManagementService.setHasOverlap(false);
            }
        }
        else {
            this.emitChanges();
            this.validatePairs(this.records);
        }
    };
    DailyLinePunchGridComponent.prototype.sortByTime = function (records) {
        return _.sortBy(records, function (x) { return x.punch.time; });
    };
    DailyLinePunchGridComponent.prototype.hasMatchedPair = function (records, item) {
        var _hasMatchedPair = false;
        var ind = 0;
        var pairs = [];
        var _records = _.cloneDeep(records);
        if (item) {
            _records = _.filter(_records, function (x) { return x.punch.punchDisplay != "Schedule"; });
            ind = _.findIndex(_records, function (x) { return x.id == (item.id < 0 ? x.id : item.id) && x.punch.time.valueOf() === item.punch.time.valueOf(); });
            ind = ind >= 0 ? ind : 0;
            if (ind > 0 && _records[ind].punch.type.isOut && _records[ind - 1].punch.type.isIn && (_records[ind - 1].punch.punchDisplay != "Schedule")) {
                pairs.push({ inpunch: _records[ind - 1], outpunch: _records[ind] });
                _hasMatchedPair = true;
            }
            else if (ind < _records.length - 1 && _records[ind].punch.type.isIn && _records[ind + 1].punch.type.isOut && (_records[ind + 1].punch.punchDisplay != "Schedule")) {
                pairs.push({ inpunch: _records[ind], outpunch: _records[ind + 1] });
                _hasMatchedPair = true;
            }
        }
        else {
            _.forEach(_records, function (x, i) {
                if (!_hasMatchedPair && x.punch.punchDisplay != "Schedule") {
                    {
                        ind = i;
                        if (ind > 0 && _records[ind].punch.type.isOut && _records[ind - 1].punch.type.isIn && (records[ind - 1].punch.punchDisplay != "Schedule")) { // !this.hasAllCurrentDayPunches(_records, ind, true) && 
                            pairs.push({ inpunch: _records[ind - 1], outpunch: _records[ind] });
                            _hasMatchedPair = true;
                        }
                        else if (ind < _records.length - 1 && _records[ind].punch.type.isIn && _records[ind + 1].punch.type.isOut && (records[ind + 1].punch.punchDisplay != "Schedule")) { // !this.hasAllCurrentDayPunches(_records, ind, false) && 
                            pairs.push({ inpunch: _records[ind], outpunch: _records[ind + 1] });
                            _hasMatchedPair = true;
                        }
                    }
                }
                if (_hasMatchedPair)
                    return { hasMatchedPair: _hasMatchedPair, matchedPair: pairs };
            });
        }
        return { hasMatchedPair: _hasMatchedPair, matchedPair: pairs };
    };
    DailyLinePunchGridComponent.prototype.hasAllCurrentDayPunches = function (records, ind, isIn) {
        var res = moment(records[isIn ? (ind - 1) : (ind + 1)].punch.time).format(appConfig.linkDateFormat) == moment(this.defaultPunchTime).format(appConfig.linkDateFormat);
        var res1 = moment(this.defaultPunchTime).format(appConfig.linkDateFormat) == moment(records[ind].punch.time).format(appConfig.linkDateFormat);
        var res2 = res && res1;
        return res2;
    };
    DailyLinePunchGridComponent.prototype.removePunch = function (item) {
        var _this = this;
        if (item.punch.punchSource === PunchSource.Request) {
            item.punch.isDeleted = true;
        }
        else {
            this.removedPunches.push(item);
            _.remove(this.records, function (w) { return w.id === item.id; });
        }
        // this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
        // this.emitChanges();
        // this.validatePairs(this.records);
        this.hasOverlapPunches = false;
        this.dailyTimeCardManagementService.setHasOverlap(false);
        this.overlapIndex = -1;
        if (this.preventOverlappingPunches) {
            this.records = this.sortByTime(this.records);
            var res = this.hasMatchedPair(this.records, item);
            if (res.hasMatchedPair) {
                _.forEach(res.matchedPair, function (pair) {
                    _this.performOverlapCheck(pair, false);
                    _this.refreshGrid();
                });
            }
            else {
                _.forEach(this.records, function (x) {
                    if (x.punch.punchStatus == PunchStatus.Overlap)
                        x.punch.punchStatus = PunchStatus.Edited;
                });
                this.refreshGrid();
                this.emitChanges();
                this.validatePairs(this.records);
                this.hasOverlapPunches = false;
                this.dailyTimeCardManagementService.setHasOverlap(false);
            }
        }
        else {
            this.refreshGrid();
            this.emitChanges();
            this.validatePairs(this.records);
        }
    };
    DailyLinePunchGridComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        var fileredRecords = _.filter(this.records, function (w) { return w.punch.show; });
        this.punchesCount = this.records.length;
        this.filteredPunchesCount = fileredRecords.length;
        this.gridState.view = process(fileredRecords, this.gridState.state);
    };
    DailyLinePunchGridComponent.prototype.checkComment = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, req, res;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.canEdit) {
                            return [2 /*return*/, true];
                        }
                        if (!!this.timecardPredefinedComments) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.lookupService.getLookup({ lookupType: LookupType.timecardPredefinedComment })];
                    case 1:
                        _a.timecardPredefinedComments = _b.sent();
                        _b.label = 2;
                    case 2:
                        if (!(!this.comment && this.timecardPredefinedComments && this.timecardPredefinedComments.items.length > 0)) return [3 /*break*/, 4];
                        req = new TimecardAddCommentsReq();
                        req.employeeId = this.employeeId;
                        req.employeeName = this.employeeName;
                        req.date = this.defaultPunchTime;
                        req.timecardPredefinedComments = this.timecardPredefinedComments;
                        return [4 /*yield*/, TimecardAddCommentsDialogComponent.openDialog('Add Comments', req, this.modalService, 'single')];
                    case 3:
                        res = _b.sent();
                        if (!res.isApply) {
                            return [2 /*return*/, false];
                        }
                        this.comment = res.comment;
                        _b.label = 4;
                    case 4: return [2 /*return*/, true];
                }
            });
        });
    };
    DailyLinePunchGridComponent.prototype.BindTitle = function (description) {
        return description == '' ? 'Please select a valid punch type' : 'Punches';
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyLinePunchGridComponent.prototype, "loadSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyLinePunchGridComponent.prototype, "loadStateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyLinePunchGridComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyLinePunchGridComponent.prototype, "gridSelectionSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyLinePunchGridComponent.prototype, "onLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyLinePunchGridComponent.prototype, "gridSaveSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyLinePunchGridComponent.prototype, "gridRemoveSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyLinePunchGridComponent.prototype, "changesSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyLinePunchGridComponent.prototype, "startEditSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyLinePunchGridComponent.prototype, "cancelEditSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyLinePunchGridComponent.prototype, "changedEditSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyLinePunchGridComponent.prototype, "onFilterAppliedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyLinePunchGridComponent.prototype, "onOverlapPuchesActionSubscription", void 0);
    return DailyLinePunchGridComponent;
}());
export { DailyLinePunchGridComponent };
