import { UserApplication } from '../state-model/models/index';
import { IBreadcrumbItemConfig } from '../core/models/index';

export interface IPortalConfig {
  api: {
    so: {
      root: string;
    },
    ta: {
      root: string;
    },
    hr: {
      root: string;
      loa: string;
    },
    dashboard: {
      root: string;
    },
    dashboardUserConfig: {
      getAlert: {
        root: string;
        alert: string;
      },
      postAlert: {
        alert: string;
      },
      apps: {
        getApps: string;
        modifyApps: string;
      }
    }
  };
}

export const portalConfig: IPortalConfig = {
  api: {
    so: {
      root: 'schedule'
    },
    ta: {
      root: 'ta'
    },
    hr: {
      root: 'hr',
      loa: 'loa'
    },
    dashboard: {
      root: 'dashboard'
    },
    dashboardUserConfig: {
      getAlert: {
        root: 'dashboardUserConfig',
        alert: 'alert'
      },
      postAlert: {
        alert: 'modifyAlert'
      },
      apps: {
        getApps: 'apps',
        modifyApps: 'ModifyApp'
      }
    }
  }
};

const employeeConfig: IBreadcrumbItemConfig = {
  id: 'employee',
  isHidden: true,
  childs: [
    {
      id: 'employeeId',
      isDynamic: true,
      mustRefreshEveryTime: true,
      childs: [
        {
          id: 'employee_sections',
          isHidden: true,
        },
        {
          id: 'shifts',
          isHidden: true,
          childs: [
            {
              id: 'shift_date',
              isDynamic: true,
              mustRefreshEveryTime: true
            }
          ]
        },
      ]
    }
  ]
};

const userConfig: IBreadcrumbItemConfig = {
  id: 'user',
  isHidden: true,
  childs: [
    {
      id: 'userId',
      isDynamic: true,
      mustRefreshEveryTime: true,
      childs: [
        {
          id: 'profile',
          isHidden: true
        }
      ]
    },
    {
      id: 'create',
      title: 'Create New User'
    }
  ]
};

const auditTrial: IBreadcrumbItemConfig = {
  id: 'audit_trail',
  title: 'Audit Trail',
  icon: 'far fa-list-alt',
};

export const breadcrumpbConfig: IBreadcrumbItemConfig = {
  id: 'apps',
  title: 'Dashboards',
  icon: 'fas fa-chart-pie',
  childs: [
    {
      id: 'application',
      isDynamic: true,
      mustRefreshEveryTime: true,
      childs: [
        {
          id: 'employee_list',
          title: 'Employee List',
          icon: 'fa fa-id-card',
          canVirtual: true,
          childs: [
            {
              id: 'add_employee',
              title: 'Add Employee'
            },
            employeeConfig
          ]
        },
        {
          id: 'call_log',
          title: 'Call Log',
          icon: 'far fa-futbol',
          childs: [
            {
              id: 'send_clock_message',
              title: 'Clock Message'
            },
            {
              id: 'log_phone_call',
              title: 'Phone Call'
            }
          ]
        },
        {
          id: 'add_employee',
          title: 'Add Employee',
        },
        {
          id: 'master_schedule',
          title: 'Master Schedule',
          canVirtual: true,
          canUseForRedirect: true,
          childs: [
            employeeConfig,
            {
              id: 'details_screen',
              title: 'Details Screen',
              displayType: 'string',
              childs: [
                {
                  id: 'date',
                  isDynamic: true,
                  mustRefreshEveryTime: true
                }
              ]
            },
            {
              id: 'post',
              displayType: 'string',
              title: 'Posted Schedules',
              childs: [
                {
                  id: 'date',
                  isDynamic: true,
                  mustRefreshEveryTime: true
                }
              ]
            }
          ]
        },
        {
          id: 'pto_planner',
          title: 'PTO Planner',
          canVirtual: true,
          childs: [employeeConfig]
        },
        {
          id: 'individual_schedule',
          title: 'Individual Schedule',
          canVirtual: true,
          childs: [employeeConfig]
        },
        {
          id: 'posted_schedules',
          title: 'Posted Schedules',
        },
        {
          id: 'schedules_not_posted',
          title: 'Schedules Not Posted',
        },
        {
          id: 'scheduled_rotations',
          title: 'Schedule Rotation Template',
        },
        {
          id: 'Open_Position_Control',
          title: 'Open Position Control'
        },
        {
          id: 'authorize_sso',
          title: 'Authorize SSO',
        },
        {
          id: 'budget_management',
          title: 'Budget Management',
          icon: 'fal fa-money-bill',
          canVirtual: true,
          childs: [employeeConfig]
        },
        {
          id: 'leave_requests',
          title: 'Review Leave Request',
          canVirtual: true,
          childs: [employeeConfig]
        },
        {
          id: 'latenesses',
          title: 'Employee Lateness',
          canVirtual: true,
          childs: [employeeConfig]
        },
        {
          id: 'attendance_points',
          title: 'Attendance Points Transactions',
          icon: 'fal fa-trophy-alt',
          canVirtual: true,
          childs: [employeeConfig]
        },
        {
          id: 'attendance_points_totals',
          title: 'Attendance Points Totals',
          icon: 'fal fa-trophy-alt',
          canVirtual: true,
          childs: [employeeConfig]
        },
        {
          id: 'attendance_points_config',
          title: 'Attendance Points Config',
          icon: 'fal fa-trophy-alt',
          canVirtual: true,
          childs: [employeeConfig]
        },
        {
          id: 'direct_care_hours',
          title: 'Direct Care Hours',
          canVirtual: true,
          childs: [employeeConfig]
        },
        {
          id: 'schedule_comments',
          title: 'Schedule Comments',
          canVirtual: true,
          childs: [employeeConfig]
        },
        {
          id: 'projected_hours',
          title: 'Projected Hours',
          canVirtual: true,
          childs: [employeeConfig]
        },
        {
          id: 'operational_dashboard',
          title: 'Operational Console',
          canVirtual: true,
          childs: [employeeConfig]
        },
        {
          id: 'daily_unit_assignment',
          title: 'Daily Unit Assignment',
          canVirtual: true,
          childs: [
            {
              id: 'date',
              isDynamic: true,
              canVirtual: true,
              canUseForRedirect: true,
              mustRefreshEveryTime: true,
              childs: [
                {
                  id: 'request_agency_staff',
                  title: 'Request Agency Staff',
                  canVirtual: true,
                  isNotLink: true,
                  childs: [{
                    id: 'dua_date_rdate',
                    isDynamic: true,
                    isHidden: true,
                    isNotLink: true,
                    canVirtual: true,
                    canUseForRedirect: true,
                    mustRefreshEveryTime: true,
                    childs: [employeeConfig]
                  }
                ]
                },
                {
                  id: 'add_open_shifts',
                  title: 'Add Open Shifts',
                  canVirtual: true,
                  isNotLink: true,
                  childs: [{
                    id: 'dua_date_rdate',
                    isDynamic: true,
                    isHidden: true,
                    isNotLink: true,
                    canVirtual: true,
                    canUseForRedirect: true,
                    mustRefreshEveryTime: true,
                    childs: [employeeConfig]
                  }
                ]
                },

              ]
            },
            {
              id: 'request_agency_staff',
              title: 'Request Agency Staff',
              canVirtual: true,
              isNotLink: true,
              childs: [{
                id: 'rdate',
                isDynamic: true,
                isHidden: true,
                isNotLink: true,
                canVirtual: true,
                canUseForRedirect: true,
                mustRefreshEveryTime: true,
                childs: [employeeConfig]
              }
            ]
            },
            {
              id: 'add_open_shifts',
              title: 'Add Open Shifts',
              canVirtual: true,
              isNotLink: true,
              childs: [{
                id: 'rdate',
                isDynamic: true,
                isHidden: true,
                isNotLink: true,
                canVirtual: true,
                canUseForRedirect: true,
                mustRefreshEveryTime: true,
                childs: [employeeConfig]
              }
            ]
            },
            employeeConfig
          ]
        },
        {
          id: 'so_console',
          title: 'Scheduling Console',
          childs: [
            {
              id: 'date',
              isDynamic: true,
              canUseForRedirect: true,
              mustRefreshEveryTime: true
            }
          ]
        },
        {
          id: 'shift_swaps',
          title: 'Shift Swaps',
        },
        {
          id: 'ta_console',
          title: 'Time and Attendance Console',
          childs: [
            {
              id: 'date',
              isDynamic: true,
              canUseForRedirect: true,
              mustRefreshEveryTime: true
            }
          ]
        },
        {
          id: 'review_timeclocks',
          title: 'Timeclock Status',
          childs: [
            {
              id: 'date',
              isDynamic: true,
              canUseForRedirect: true,
              mustRefreshEveryTime: true
            }
          ]
        },
        {
          id: 'open_shift_management',
          title: 'Open Shift Management',
        },
        {
          id: 'employee_search',
          title: 'Employee Search',
          icon: 'far fa-search',
          canVirtual: true,
          childs: [employeeConfig]
        },
        {
          id: 'configure_units',
          title: 'Units',
          icon: 'fal fa-building'
        },
        {
          id: 'configure_absences',
          title: 'Configure Absences',
          icon: 'fal fa-calendar-minus',
          canVirtual: true
        },
        {
          id: 'positions',
          title: 'Configure Positions',
          icon: 'fal fa-clipboard-list',
          canVirtual: true
        },
        {
          id: 'license_types',
          title: 'License Types',
          icon: 'fal fa-file-certificate',
          canVirtual: true
        },
        {
          id: 'intg_position_mapping',
          title: 'Position Mappings',
          icon: 'fal fa-clipboard-list'
        },
        {
          id: 'configure_organizations',
          title: 'Configure Organizations',
          canVirtual: true
        },
        {
          id: 'configure_paycodes',
          title: 'Pay Codes Configuration',
          canVirtual: true
        },
        {
          id: 'paycode_details',
          title: 'Pay Code Details',
          canVirtual: true
        },
        {
          id: 'configure_constraints',
          title: 'Configure Constraints',
          icon: 'fas fa-ban',
          canVirtual: true
        },
        {
          id: 'configure_shifts',
          title: 'Shifts',
          icon: 'fal fa-calendar-edit'
        },
        {
          id: 'configure_Shift_Group',
          title: 'Shift Group',
          icon: 'fal fa-calendar-edit'
        },
        {
          id: 'elite_dashboard',
          title: 'Dashboard',
          icon: 'fal fa-calendar-edit'
        },
        {
          id: 'elite_dashboard_view_report',
          title: 'View Reports',
          icon: 'spotlight-icon'
        },
        {
          id: 'elite_dashboard_create_report',
          title: 'Create Report',
          icon: 'spotlight-icon'
        },
        {
          id: 'code_mapping',
          title: 'Org Structure Codes',
          icon: 'fal fa-calendar-edit'
        },
        {
          id: 'payroll_paycodes',
          title: 'Payroll Pay Codes',
          icon: 'fal fa-calendar-edit'
        },
        {
          id: 'set_up_timeclocks',
          title: 'Timeclocks'
        },
        {
          id: 'punch_attestation',
          title: 'Punch Attestation',
        },
        {
          id: 'punch_profile',
          title: 'Punch Profile',
        },
        {
          id: 'configure_roles',
          title: 'Role Configuration',
          icon: 'fal fa-users'
        },
        {
          id: 'intg_notifications',
          title: 'Notifications',
          icon: 'fas fa-bell'
        },
        {
          id: 'configure_cost_centers',
          title: 'Cost Centers',
          icon: 'fas fa-usd-square',
          canVirtual: true
        },

        {
          id: 'timeclock_assignment',
          title: 'Timeclock Assignment',
          canVirtual: true,
          childs: [employeeConfig]
        },
        {
          id: 'configure_ideal_schedule',
          title: 'Ideal Schedule',
          icon: 'fal fa-calendar-alt',
          canVirtual: true,
        },
        {
          id: 'manage_agency',
          title: 'Agency Configuration',
          canVirtual: true,
        },
        {
          id: 'intg_staffing_agency_report_console',
          title: 'Agency Staffing Console',
          icon: 'fa fa-calendar-alt',
          canVirtual: true,
        },
        {
          id: 'File_Uploads',
          title: 'File Uploads',
          icon: 'fa fa-file-upload',
          canVirtual: true
        },
        {
          id: 'configure_users',
          title: 'Users',
          icon: 'fal fa-user-circle',
          canVirtual: true,
          childs: [userConfig]
        },
        {
          id: 'employee_sync',
          title: 'Employee Sync',
          icon: 'fa fa-calendar',
          canVirtual: true,
          childs: [userConfig,
            {
              id: 'syncId',
              isDynamic: true,
              mustRefreshEveryTime: true,
              isHidden: true,
              childs: [
                {
                  id: 'sync_response',
                  title: 'Employee Sync Report',

                },
              ]
            },

          ]
        },
        {
          id: 'daily_transaction_report',
          title: 'Daily Transaction Report',
          icon: 'fa fa-calendar',
          canVirtual: true
        },
        {
          id: 'paydata_event_report',
          title: 'Post Pay Data Event Report',
          icon: 'fa fa-calendar',
          canVirtual: true
        },
        {

          id: 'work_location_migration',
          title: 'Migration Sync',
          icon: 'fa fa-calendar',
          canVirtual: true
        },
        {
          id: 'work_location',
          title: 'Ongoing Sync',
          icon: 'fa fa-calendar',
          canVirtual: true
        },
        {
          id: 'event_report',
          title: 'Event Report',
          icon: 'fa fa-calendar',
          canVirtual: true
        },
        {
          id: 'ta_event_report',
          title: 'TA Event Report',
          icon: 'fa fa-calendar',
          canVirtual: true
        },
        {
          id: 'sync_dashboard',
          title: 'Sync Dashboard',
          icon: 'fa fa-university',
          canVirtual: true
        },
        {
          id: 'sync_dashboard_migration',
          title: 'Sync Dashboard Migration',
          icon: 'fa fa-university',
          canVirtual: true
        },
        {
          id: 'event_error_report',
          title: 'Event Error Report',
          icon: 'fa fa-calendar',
          canVirtual: true
        },

        {
          id: 'configure_telepunch_locations',
          title: 'Telepunch Locations',
          icon: 'fas fa-phone-square',
          canVirtual: true,
        },
        {
          id: 'workers_compensation_table',
          title: 'Workers\' Compensation Table',
          // icon: 'fas fa-phone-square',
          canVirtual: true,
        },
        {
          id: 'timecards',
          title: 'Timecards',
          canVirtual: true,
          childs: [
            {
              id: 'employee',
              isHidden: true,
              childs: [
                {
                  id: 'employeeId',
                  isDynamic: true,
                  mustRefreshEveryTime: true,
                  childs: [
                    {
                      id: 'date',
                      isDynamic: true,
                      mustRefreshEveryTime: true,
                    }
                  ]
                }
              ]
            },
            {
              id: 'summary',
              title: 'Summary',
              canVirtual: true
            }
          ]
        },
        {
          id: 'exception_console',
          title: 'Exception Console',
          canVirtual: true,
          childs: [employeeConfig]
        },
        {
          id: 'arrivals_departures_details',
          title: 'Arrivals & Departures Details',
        },
        {
          id: 'holidays',
          title: 'Holidays',
          icon: 'fal fa-suitcase'
        },

        {
          id: 'audit_trail_employee',
          title: 'Employee Audit',
          icon: 'fas fa-clipboard-user',
          prefix: auditTrial
        },
        {
          id: 'audit_trail_config',
          title: 'Config Audit',
          icon: 'fas fa-ballot-check',
          prefix: auditTrial
        },
        {
          id: 'applicant_tracker',
          title: 'Applicant Tracker',
          icon: 'applicant-tracker-icon'
        },
        {
          id: 'assign_supervisor',
          title: 'Supervisor Assignment'
        },
        {
          id: 'agency_schedule',
          title: 'Add Agency Employee'
        },
        {
          id: 'daily_census',
          title: 'Daily Census'
        },
        {
          id: 'daily_punches',
          title: 'Daily Punches'
        },
        {
          id: 'employee_punch_request',
          title: 'Employee Punch Request'
        },
        {
          id: 'overtime_approvals',
          title: 'Overtime Approvals'
        },
        {
          id: 'approval_required_codes',
          title: 'Approval Required Codes'
        },
        {
          id: 'pbj_manual_entry',
          title: 'PBJ Manual Entry'
        },
        {
          id: 'pbj-duplicate-workers',
          title: 'Identify Duplicate Workers'
        },
        {
          id: 'pbj_reconciliation',
          title: 'Time Card to PBJ Reconciliation',
          childs: [
            {
              id: 'employee',
              isHidden: true,
              childs: [
                {
                  id: 'employeeId',
                  isDynamic: true,
                  mustRefreshEveryTime: true
                }
              ]
            }
          ]
        },
        {
          id: 'pbj_exports',
          title: 'PBJ Log Export',
          childs: [
            {
              id: 'exportId',
              isDynamic: true,
              isHidden: true,
            }
          ]
        },
        {
          id: 'cms_insights_dashboard',
          title: 'CMS Insights Dashboard'
        },
        {
          id: 'bi_dashboards',
          title: 'Dashboards'
        },
        {
          id: 'bi_ss_create_report',
          title: 'Self Service Create Report'
        },
        {
          id: 'bi_ss_view_reports',
          title: 'Self Service View Reports'
        },
        {
          id: 'standard_reports',
          title: 'Standard Reports'
        },
        {
          id: 'w2_management',
          title: 'Form W-2 Statements'
        },
        {
          id: 'bi_adhoc_create_report',
          title: 'Ad-Hoc Create New'
        },
        {
          id: 'bi_adhoc_view_reports',
          title: 'Ad-Hoc View Reports'
        },
        {
          id: 'export_data',
          title: 'Data Exports',
          icon: 'fas fa-file-export'
        },
        {
          id: 'staffing_predictor',
          title: 'Five-Star Predictor'
        },
        {
          id: 'user_password_settings',
          title: 'User Password Settings',
          icon: 'fa fa-key',
        },
        {
          id: 'payroll_export_management',
          title: 'Payroll Export Management'
        },
        {
          id: 'payroll_export',
          title: 'Payroll Export'
        },
        {
          id: 'ess_templates',
          title: 'Go User Templates'
        },
        {
          id: 'geolocation_punches',
          title: 'Geolocation',
          icon: 'fas fa-map-marked-alt'
        },
        {
          id: 'loa_console',
          title: 'LOA Console'
        },
        {
          id: 'loa_roster',
          title: 'LOA Roster'
        },
        {
          id: 'incident_roster',
          title: 'Workers\' Compensation Roster'
        },
        {
          id: 'wc_comparisons',
          title: 'Workers\' Compensation Comparison'
        },
        {
          id: 'create_osha_recordkeeping_forms',
          title: 'Create OSHA Recordkeeping Forms'
        },
        {
          id: 'incident_tracking',
          title: 'Incident Tracking'
        },
        {
          id: 'osha_budget_tracker',
          title: 'OSHA Budget Tracker'
        },
        {
          id: 'repeat_injury_list',
          title: 'Repeat Injury List'
        },
        {
          id: 'wc_summary',
          title: 'WC Summary'
        },
        {
          id: 'configure_leave_types',
          title: 'Configure Leave Types',
          icon: 'far fa-calendar'
        },
        {
          id: 'configure_benefit_classes',
          title: 'Configure Benefit Class',
          icon: 'fas fa-umbrella'
        },
        {
          id: 'performance_management_roster',
          title: 'Performance Management Roster',
          childs: [
            {
              id: 'employee',
              isHidden: true,
              childs: [
                {
                  id: 'employeeId',
                  isDynamic: true,
                  mustRefreshEveryTime: true
                }
              ]
            }
          ]
        },
        {
          id: 'configure_performance_management',
          title: 'Performance Management'
        },
        {
          id: 'configure_employers',
          title: 'Configure Companies',
          icon: 'fas fa-balance-scale',
          canVirtual: true
        },
        {
          id: 'ten_ninty_five_c',
          title: '1095-C/1094-C Reporting',
          icon: 'fas fa-balance-scale'
        },
        {
          id: 'measurement_details',
          title: 'Measurement Details',
          icon: 'fas fa-stream',
          canVirtual: true
        },
        {
          id: 'aca_exports',
          title: 'ACA Exports',
          icon: 'fas fa-file-export',
          canVirtual: true
        },
        {
          id: 'aca_c1095_audit',
          title: '1095-C Audit',
          icon: 'fas fa-balance-scale'
        },
        {
          id: 'manage_aca_periods',
          title: 'Manage ACA Periods',
          icon: 'fas fa-balance-scale',
          canVirtual: true
        },
        {
          id: 'benefits_management_console',
          title: 'Benefits Console',
          icon: 'fas fa-user-shield'
        },
        {
          id: 'benefits_management_details',
          title: 'Benefit Plan Details',
          icon: 'fas fa-user-shield'
        },
        {
          id: 'accrual_balances',
          title: 'Accrual Balances'
        },
        {
          id: 'accrual_transactions',
          title: 'Accrual Transactions'
        },
        {
          id: 'accrual_policy_definitions',
          title: 'Accrual Policy Definitions'
        },
        {
          id: 'ip_whitelisting',
          title: 'IP Management',
          icon: 'fas fa-tv'
        },
        {
          id: 'user_login_management',
          title: 'User Login Management',
          icon: 'fas fa-users'
        },
        {
          id: 'temp_sensor_reading',
          title: 'Temp Readings'
        },
        {
          id: 'message_center',
          title: 'Message Center',
          icon: 'fas fa-comments',
        },
        {
          id: 'message_center_sms',
          title: 'Message Center',
          icon: 'fas fa-comments',
          childs:[
             {
                id: "sms",
                title: "SMS",
                icon: "fas fa-comments",
                isDynamic: true
             }
          ]
        },
        employeeConfig,
        userConfig
      ]
    }
  ]
};
