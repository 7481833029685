import { Router, ActivatedRoute, UrlTree, NavigationExtras } from '@angular/router';
import * as moment from 'moment';
import { appConfig } from '../../../app.config';

export class DailyPunchesNavigationService {
  private router: Router;
  private route: ActivatedRoute;

  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  public navigateToDailyPunches(orgLevelId: number): void {
    this.router.navigateByUrl(this.getUrlTree({ orgLevelId: orgLevelId }));
  }

  public navigateToDailyPunchesDates(orgLevelId: number, startDate: Date, endDate: Date, onlyMissing?: boolean, punchRequest? : boolean): void {
    let params: any = {
      orgLevelId: orgLevelId,
      startDate: moment(startDate).format(appConfig.linkDateFormat),
      endDate: moment(endDate).format(appConfig.linkDateFormat)
    };
    if (onlyMissing) {
      params['onlyMissing'] = true;
    }
    if(punchRequest){
      params['punchRequest'] = true;
    }
    this.router.navigateByUrl(this.getUrlTree(params));
  }

  private getUrlTree(params: any): UrlTree {
    if (this.route.pathFromRoot.length > 3) {
      return this.router.createUrlTree(['daily_punches'], { relativeTo: this.route.pathFromRoot[2], queryParams: params });
    } else {
      return this.router.createUrlTree([appConfig.globalMenuItemsApplication.link, 'daily_punches'], { relativeTo: this.route.pathFromRoot[1], queryParams: params });
    }

  }
}
