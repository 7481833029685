<div *ngIf="formGroup">
  <form [formGroup]="formGroup">
    <div class="slx-high-box editor-container">
      <div class="field-container">
        <div class="field-holder org-name">
          <slx-input-decorator>
            <input slx-input class="form-control" type="text" formControlName="name" [required]="true"
              placeholder="Organization Name" tabindex="1" slxMaxLength="50"
              [readonly]="!isSystemUser">
            <span errorMessage for="required"></span>
            <span errorMessage for="slxMaxLength">value must not exceed 50 characters</span>
          </slx-input-decorator>
        </div>
        <div class="field-holder company-id">
          <slx-input-decorator>
            <input slx-input class="form-control" type="text" formControlName="cmsPbjCode"
              placeholder="CMS PBJ Company ID" tabindex="2" slxMaxLength="16">
            <span errorMessage for="required"></span>
            <span errorMessage for="slxMaxLength">value must not exceed 16 characters</span>
          </slx-input-decorator>
        </div>
        <div class="field-holder ccn">
          <slx-input-decorator>
            <input slx-input class="form-control" type="text" formControlName="ccn" placeholder="CCN Number"
              tabindex="15" slxMaxLength="50">
            <span errorMessage [for]="ccnValidation"></span>
            <span errorMessage for="unverified">validating...</span>
            <span errorMessage for="slxMaxLength">value must not exceed 50 characters</span>
          </slx-input-decorator>
        </div>
        <div class="field-holder company-selector">
          <slx-input-decorator>
            <slx-dropdown-input slx-input class="form-control" placeholder="Company" name="company"
              formControlName="company" titleField="name" valueField="id" tabindex="3" [options]="companiesLookup"
              [readonly]="!isSystemUser" emptyOption></slx-dropdown-input>
          </slx-input-decorator>
        </div>
      </div>
      <div class="field-container">
        <div class="field-holder address">
          <slx-input-decorator>
            <input slx-input class="form-control" type="text" formControlName="address" placeholder="Address"
              tabindex="4" slxMaxLength="255" [readonly]="!isSystemUser">
            <span errorMessage for="required"></span>
            <span errorMessage for="slxMaxLength">value must not exceed 255 characters</span>
          </slx-input-decorator>
        </div>
        <div class="field-holder city">
          <slx-input-decorator>
            <input slx-input class="form-control" type="text" formControlName="city" placeholder="City" tabindex="5"
              slxMaxLength="50" [readonly]="!isSystemUser">
            <span errorMessage for="required"></span>
            <span errorMessage for="slxMaxLength">value must not exceed 50 characters</span>
          </slx-input-decorator>
        </div>
        <div class="field-holder state">
          <slx-input-decorator>
            <slx-dropdown-input slx-input class="form-control" placeholder="State" name="state" formControlName="state"
              titleField="name" valueField="name" [options]="statesLookup" tabindex="6" emptyOption [readonly]="!isSystemUser">
            </slx-dropdown-input>
            <span errorMessage for="required"></span>
          </slx-input-decorator>
        </div>
        <div class="field-holder zip">
          <slx-input-decorator>
            <input slx-input class="form-control" type="text" formControlName="zipCode" placeholder="Zip" tabindex="7"
              zip [readonly]="!isSystemUser">
            <span errorMessage for="required"></span>
            <span errorMessage for="zip"></span>
          </slx-input-decorator>
        </div>
        <div class="field-holder phone">
          <slx-input-decorator>
            <input slx-input class="form-control" type="text" formControlName="phone" placeholder="Phone" tabindex="8"
              phone [readonly]="!isSystemUser">
            <span errorMessage for="required"></span>
            <span errorMessage for="phone"></span>
          </slx-input-decorator>
        </div>
      </div>
      <div class="field-container">
        <div class="field-holder company-code">
          <slx-input-decorator>
            <input slx-input class="form-control" type="text" formControlName="companyCode" placeholder="Company Code"
              tabindex="9">
            <span errorMessage for="required"></span>
          </slx-input-decorator>
        </div>
        <div class="field-holder group">
          <slx-input-decorator>
            <input slx-input class="form-control" type="text" formControlName="group" placeholder="Group" tabindex="10" >
            <span errorMessage for="required"></span>
          </slx-input-decorator>
        </div>
        <div class="field-holder type">
          <slx-input-decorator>
            <input slx-input class="form-control" type="text" formControlName="type" placeholder="Type" tabindex="11">
            <span errorMessage for="required"></span>
          </slx-input-decorator>
        </div>
        <div class="field-holder other-name">
          <slx-input-decorator>
            <input slx-input class="form-control" type="text" formControlName="otherName" placeholder="Other Name"
              tabindex="12" slxMaxLength="50">
            <span errorMessage for="slxMaxLength">value must not exceed 50 characters</span>
          </slx-input-decorator>
        </div>
        <div class="field-holder telepunch-offset">
          <slx-input-decorator>
            <slx-kendo-number slx-input class="form-control" formControlName="telepunchOffset" [decimals]="0" [step]="1"
              placeholder="Telepunch offset" tabindex="13"
              [readonly]="!item?.telepunchOffset.isEditable"></slx-kendo-number>
            <span errorMessage for="required"></span>
          </slx-input-decorator>
        </div>
        <div class="field-holder timezone-offset">
          <slx-input-decorator>
            <slx-kendo-number slx-input class="form-control" formControlName="timezoneOffset" [decimals]="0" [step]="1"
              [readonly]="!item?.timezoneOffset.isEditable" placeholder="Time Zone Offset"
              tabindex="14"></slx-kendo-number>
            <span errorMessage for="required"></span>
          </slx-input-decorator>
        </div>


      </div>
      <div class="field-container">
        <div class="field-holder timezone">
          <slx-input-decorator>
            <slx-dropdown-input slx-input class="form-control" placeholder="Time Zone" name="timezone" [required]="!(item.pbjOrganization.name === 'Agency')"
              formControlName="timezone" titleField="timezoneDisplayName" valueField="timezone" [options]="organizationTimezones"
              [readonly]="!isSystemUser"></slx-dropdown-input>
              <span errorMessage for="required"></span>
          </slx-input-decorator>
        </div>
        <div class="field-holder app-version" *ngIf="isSystemUser">
          <slx-input-decorator>
            <slx-dropdown-input slx-input class="form-control" placeholder="Application Version" name="migrationVersion"
              tabindex="15" formControlName="migrationVersion" titleField="name" valueField="id"
              [options]="appVersions"></slx-dropdown-input>
            <span errorMessage for="required"></span>
          </slx-input-decorator>
        </div>
        <div class="field-holder payrollCode">
          <slx-input-decorator>
            <input slx-input class="form-control" type="text" formControlName="payrollCode" placeholder="PayrollCode"
              tabindex="5" [required]="true" [readonly]="!isSystemUser">
            <span errorMessage for="required"></span>
          </slx-input-decorator>
          <span errorMessage style="color:#E64FC3" *ngIf="formGroup.get('payrollCode').hasError('invalidNumber')">
            Please enter a number between 1 and 99.</span>
          <span errorMessage style="color:#E64FC3" *ngIf="formGroup.get('payrollCode').hasError('pattern')">
            Please enter numbers only.
          </span>
        </div>
        <div class="field-holder conversion" *ngIf="isSystemUser&&isApplicableForMigration">
          <slx-input-decorator>
            <input slx-date-time-picker slx-input formControlName="conversionDate" placeholder="Conversion Date"
              tabindex="16" [slxMinDate]="minConversionDate" [slxMaxDate]="endOfYear" [minDate]="minConversionDate"
              [maxDate]="endOfYear" />
            <span errorMessage for="minDate">Minimum date should be greater than {{ minConversionDate | amDateFormat:
              appConfig.dateFormat }}</span>
            <span errorMessage for="maxDate">Maximum date should be smaller than {{ endOfYear | amDateFormat:
              appConfig.dateFormat }}</span>
            <span errorMessage for="required"></span>
          </slx-input-decorator>
        </div>
        <div class="field-holder conversion-state" *ngIf="isSystemUser">
          <span>Conversion state: {{conversionStateString}}</span>
        </div>
        <div class="field-holder payroll-app-status" *ngIf="nextgenPhasedRollout && isNextgenPayrollEnabled">
          <slx-input-decorator>
            <slx-dropdown-input slx-input class="form-control" placeholder="Payroll App Status" name="payrollAppStatus"
              tabindex="17" formControlName="payrollAppStatus" titleField="name" valueField="id"
              [options]="payrollAppStatus"
              [readonly]="!isSystemUser"></slx-dropdown-input>
            <span errorMessage for="required"></span>
          </slx-input-decorator>
        </div>
        <div class="field-holder payroll-app-date" *ngIf="nextgenPhasedRollout && isNextgenPayrollEnabled">
          <slx-input-decorator>
            <input slx-date-picker slx-input formControlName="payrollAppEffectiveDate" placeholder="Payroll App Effective Date"
              tabindex="16" [readonly]="!isSystemUser"/>
            <span errorMessage for="required"></span>
          </slx-input-decorator>
        </div>
      </div>
      <div class="field-container">
      <div class="field-holder pay-group">
        <slx-input-decorator>
          <input slx-input class="form-control" type="text" formControlName="payGroup"  placeholder="Paygroup"
            [readonly]="true">
        </slx-input-decorator>
      </div>
      </div>
      <div class="field-container" *ngIf="isSystemUser">
        <div class="field-holder message">
          <slx-text-editor formControlName="messageForUsers" [options]="options"
            (onTextChange)="onTextChange($event)"></slx-text-editor>
        </div>
      </div>


      <div class="osha-header">OSHA Settings</div>

      <div class="field-container">
        <div class="field-holder osha-subject">
          <slx-input-decorator className="slx-form-group slx-no-border small-font">
            <slx-checkbox-input slx-input fieldName="asOshaCompany" formControlName="asOshaCompany"
              placeholder="Use Company Name for OSHA Export">
            </slx-checkbox-input>
          </slx-input-decorator>
        </div>
      </div>

      <div class="field-container">
        <div class="field-holder osha-subject">
          <slx-input-decorator>
            <slx-kendo-number slx-input number notLeadZeroes class="form-control" formControlName="naicsCode"
              fieldName="naicsCode" [format]="'#'" [decimals]="0" [max]="999999" [min]="100000" [slxMin]="0"
              name="naicsCode" placeholder="NAICS Code" (input)="onCodeChange($event.target.value)"></slx-kendo-number>
            <span errorMessage for="max">NAICS Code for the facility should be 6 digits long. Please enter the correct
              NAICS Code.</span>
            <span errorMessage for="min">NAICS Code for the facility should be 6 digits long. Please enter the correct
              NAICS Code.</span>
            <span errorMessage for="notLeadZeroes">NAICS Code for the facility should not begin with 0. Please enter the
              correct NAICS Code.</span>
          </slx-input-decorator>
        </div>
      </div>

      <div class="field-container">
        <div class="field-holder osha-subject">
          <slx-input-decorator>
            <input slx-input class="form-control" type="text" formControlName="naicsDescription" slxMaxLength="255"
              placeholder="NAICS Description"/>
            <span errorMessage for="slxMaxLength">Value must not exceed 255 characters</span>
          </slx-input-decorator>
        </div>
      </div>

      <div class="field-container">
        <div class="field-holder osha-subject">
          <slx-input-decorator>
            <slx-dropdown-input slx-input class="form-control" name="establishmentType" placeholder="Establishment Type"
              formControlName="establishmentType" titleField="name" valueField="id"
              [options]="establishmentTypes"></slx-dropdown-input>
            <span errorMessage for="required"></span>
          </slx-input-decorator>
        </div>
      </div>

      <div class="field-container">
        <div class="field-holder osha-subject"
          title="This is based on the maximum number of employees that worked there at any point during the year">
          <slx-input-decorator>
            <slx-kendo-number slx-input class="form-control" formControlName="establishmentSize" [decimals]="0"
              [step]="1" placeholder="Number of Employees" [max]="1999999999" [min]="0"></slx-kendo-number>
            <span errorMessage for="max">Maximum limit for Number of Employees is 1999999999</span>
            <span errorMessage for="min">Please enter a valid number</span>
          </slx-input-decorator>
        </div>
      </div>

    </div>

    <div class="actions-bar">
      <div class="col"></div>
      <button class="action-button save-btn" (click)="onSaveClick ($event)"
        [disabled]="formGroup.invalid || formGroup.pending">Save</button>
      <button class="action-button cancel-btn" (click)="onCancelClick ($event)">Cancel</button>
    </div>

  </form>
</div>