import * as tslib_1 from "tslib";
import { ApiUtilService, UrlParamsService, CacheUtilService } from '../../../common/services/index';
import { MetaMapService } from '../../../core/services/index';
import { FieldsMeta, Meta, ResponseBody } from '../../../core/models/index';
import { HttpRequest } from '@angular/common/http';
import { appConfig } from '../../../app.config';
import { configurationConfig } from '../../configuration.config';
import { PayCodesMapService } from './pay-codes-map.service';
import { PaycodeExceptionSortOverrideMapService } from './paycode-exception-sortoverride-map.service';
var PayCodesApiService = /** @class */ (function () {
    function PayCodesApiService(apiUtilService, urlParamsService, mapService, metaMapService, cacheUtilService, exceptionsortoverrideMapService) {
        this.apiUtilService = apiUtilService;
        this.urlParamsService = urlParamsService;
        this.mapService = mapService;
        this.metaMapService = metaMapService;
        this.cacheUtilService = cacheUtilService;
        this.exceptionsortoverrideMapService = exceptionsortoverrideMapService;
        this.taserviceApiRoot = this.getTAServicesApiRoot();
    }
    PayCodesApiService.prototype.getPayCodes = function (orgLevelId) {
        var _this = this;
        var url = this.getExeptionsRoot(orgLevelId);
        var request = new HttpRequest('GET', url);
        return this.apiUtilService.request(request).then(function (response) {
            var fieldsMeta = response.meta;
            var container = _this.mapService.mapToPayCodesContainer(response.data, fieldsMeta);
            return container;
        });
    };
    PayCodesApiService.prototype.savePayCodes = function (payCodes, orgLevelId) {
        var url = "" + this.getExeptionsRoot();
        var body = {
            list: this.mapService.mapToPayCodeDtos(payCodes)
        };
        var request = new HttpRequest('PUT', url, body);
        return this.apiUtilService.request(request);
    };
    PayCodesApiService.prototype.savePayCode = function (payCode) {
        var url = this.getExeptionsRoot();
        var body = {
            list: this.mapService.mapToPayCodeDtos([payCode])
        };
        var request = new HttpRequest('PUT', url, body);
        return this.apiUtilService.request(request);
    };
    PayCodesApiService.prototype.getAccrualPolicy = function (orgLevelId) {
        var _this = this;
        var url = appConfig.api.url + "/" + appConfig.api.version + "/accruals/" + orgLevelId + "/policy/short/" + "all";
        var request = this.urlParamsService.createGetRequest(url);
        var promise = this.apiUtilService.request(request).
            then(function (response) {
            return _this.mapService.mapAccrualPolicy(response.data);
        });
        return promise;
    };
    PayCodesApiService.prototype.getAccrualType = function (orgLevelId) {
        var _this = this;
        var url = appConfig.api.url + "/" + appConfig.api.version + "/accruals/" + orgLevelId + "/" + "accrualtype";
        var request = this.urlParamsService.createGetRequest(url);
        var promise = this.apiUtilService.request(request).
            then(function (response) {
            return _this.mapService.mapAccrualTypes(response.data);
        });
        return promise;
    };
    PayCodesApiService.prototype.getExeptionsRoot = function (orgLevelId) {
        if (orgLevelId !== undefined) {
            return this.getConfigurationApiRoot() + "/" + configurationConfig.api.configuration.payCodes.root + "/" + orgLevelId;
        }
        return this.getConfigurationApiRoot() + "/" + configurationConfig.api.configuration.payCodes.root;
    };
    PayCodesApiService.prototype.getConfigurationApiRoot = function () {
        return this.getApiRoot() + "/" + configurationConfig.api.configuration.root;
    };
    PayCodesApiService.prototype.getApiRoot = function () {
        return appConfig.api.url + "/" + appConfig.api.version;
    };
    PayCodesApiService.prototype.getPaycodeExceptionSortOverrides = function () {
        var _this = this;
        var url = this.taserviceApiRoot + "/paycodeexceptions/sortoverride";
        var request = new HttpRequest('GET', url);
        var promise = this.apiUtilService.request(request).
            then(function (response) {
            return _this.exceptionsortoverrideMapService.mapToModelsFromDtos(response.data);
        });
        return promise;
    };
    PayCodesApiService.prototype.savePaycodeExceptionSortOverrides = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request, response, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.taserviceApiRoot + "/paycodeexceptions/paycodeexceptionssortoverride";
                        body = this.exceptionsortoverrideMapService.mapToDtosFromModels(data);
                        request = new HttpRequest('POST', url, body);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiUtilService.request(request)];
                    case 2:
                        response = _a.sent();
                        return [2 /*return*/, response.data]; // Assuming response.data contains the PaycodeExceptionSortOverrideDto
                    case 3:
                        error_1 = _a.sent();
                        this.handleError(error_1);
                        throw error_1;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PayCodesApiService.prototype.deletePaycodeExceptionSortOverride = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.taserviceApiRoot + "/paycodeexceptions/sortoverride/" + id;
                        request = new HttpRequest('DELETE', url);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiUtilService.request(request)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _a.sent();
                        this.handleError(error_2);
                        throw error_2;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PayCodesApiService.prototype.handleError = function (error) {
        if (error.status === 404 || (error.error && error.error.status === 404))
            console.warn('Resource not found:', error);
        if (error.status === 500 || (error.error && error.error.status === 500)) {
            console.error('Internal server error:', error);
        }
        else {
            console.error('An error occurred:', error);
        }
    };
    PayCodesApiService.prototype.getTAServicesApiRoot = function () {
        return this.apiUtilService.getTAServicesApiRoot() + "/taappend";
    };
    return PayCodesApiService;
}());
export { PayCodesApiService };
