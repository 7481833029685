<!-- <slx-spinner  [show]="state.isLoading" class="response"> -->
  <slx-spinner class="dua-spinner" [show]="isLoading">
    <div class="slx-high-box">
      <div class="row slx-high-box__body slx-main-content-indents res">
        <div class="row align">
          <div class="col-xs-2 date">
            <slx-input-decorator>
              <slx-date-picker-ngx
                slx-input
                [ngModel]="startDateValue"
                [maxDate]="today"
                (ngModelChange)="onChangeStartDate($event)"
                placeholder="Start Date"
                format="MM/dd/yyyy"
                name="startDate"
                [acceptNullDate]="true"
              >
              </slx-date-picker-ngx>
            </slx-input-decorator>
            <div *ngIf="dateError" class="slx-error-block">
              <span *ngIf="dateError" errorMessage for="endDate">{{ startDateMessage }}</span>
            </div>
          </div>
          <div class="col-xs-2 date">
            <slx-input-decorator>
              <slx-date-picker-ngx
                slx-input
                [ngModel]="endDateValue"
                [maxDate]="today"
                (ngModelChange)="onEndDateChange($event)"
                placeholder="End Date"
                format="MM/dd/yyyy"
                name="endDate"
                [acceptNullDate]="true"
              >
              </slx-date-picker-ngx>
            </slx-input-decorator>
            <div *ngIf="dateError" class="slx-error-block">
              <span *ngIf="dateError" errorMessage for="endDate">{{ endDateMessage }}</span>
            </div>
          </div>
          <div class="col-xs-2">
            <button class="theme-button-apply apply" (click)="apply()" [disabled]="disable">Get Report</button>
          </div>
        </div>
  
        <!-- <div class="row wrapper">
                        <button class="theme-button-apply view" (click)="exportToExcel(grid)">Download to Excel</button>
                    </div> -->
        <div class="row">
          <kendo-grid
            #grid="kendoGrid"
            [data]="gridState.view"
            [pageable]="true"
            filterable="menu"
            [filter]="gridState.state.filter"
            [sortable]="true"
            [sort]="gridState.state.sort"
            [pageSize]="pageSize"
            [skip]="gridState.state.skip"
            (dataStateChange)="gridState.dataStateChange($event)"
            (stateRestored)="gridState.dataStateChange($event)"
            class="grid slx-blue-grid"
          >
            <kendo-grid-column field="transactionId" title="Transaction ID" width="160"> </kendo-grid-column>
            <kendo-grid-column field="transactionDate" title="Transaction Date" width="200" filterable=false >
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.transactionDate | date : 'MM/dd/yyyy hh:mm:ss a' }}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="eventId" title="Event ID" width="160"> </kendo-grid-column>
            <kendo-grid-column field="transactionName" title="Action Type" width="160"> </kendo-grid-column>
            <kendo-grid-column field="transactionsubName" title="Sub Action" width="160"> </kendo-grid-column>
            <kendo-grid-column field="employeeId" title="Employee ID" width="160"> </kendo-grid-column>
            <!-- <kendo-grid-column field="orgType" title="Org Type" width="160"></kendo-grid-column> -->
            <kendo-grid-column field="orgTypeId" title="Org Type Id" width="160">
            </kendo-grid-column>
            <kendo-grid-column field="transactionStatus" title="Status" width="160">
            </kendo-grid-column>
            <kendo-grid-column
              field="errorMessage"
              title="Message"
              [filterable]="false"
              width="160"
            ></kendo-grid-column>
            <ng-template kendoGridNoRecordsTemplate>
              <p>There is no data to display</p>
            </ng-template>
            <kendo-grid-excel fileName="DailyTransactionReport.xlsx" [fetchData]="allData"></kendo-grid-excel>
          </kendo-grid>
        </div>
      </div>
    </div>
  </slx-spinner>
  