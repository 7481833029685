import * as tslib_1 from "tslib";
import { HttpRequest } from '@angular/common/http';
import { PaycodeExceptionMapService } from './paycode-exception-map.service';
import { RuleMapService } from './rule-map.service';
import { UrlParamsService, ApiUtilService } from "../../../common";
import { PayCodeDetailsContainer } from '../../models/pay-code-details/pay-code-details-container';
import { AdditionalRequirementMapService } from './additional-requirements-map.service';
import { RuleFormulaMapService } from './rule-formula-map.service';
var PayCodeDetailsApiService = /** @class */ (function () {
    function PayCodeDetailsApiService(apiUtilService, exceptionMapService, ruleMapService, additionalRequirementMapService, ruleFormulaMapService, urlParamsService) {
        this.apiUtilService = apiUtilService;
        this.exceptionMapService = exceptionMapService;
        this.ruleMapService = ruleMapService;
        this.additionalRequirementMapService = additionalRequirementMapService;
        this.ruleFormulaMapService = ruleFormulaMapService;
        this.urlParamsService = urlParamsService;
        this.apiRoot = this.getTAServicesApiRoot();
    }
    // PaycodeExceptions
    PayCodeDetailsApiService.prototype.getPaycodeExceptions = function (id, orgId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response, container, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.apiRoot + "/paycodeexceptions/organizations/" + orgId + "/paycodeexceptions/" + id;
                        request = new HttpRequest('GET', url);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiUtilService.request(request)];
                    case 2:
                        response = _a.sent();
                        container = new PayCodeDetailsContainer();
                        // Manually check for response and response.data
                        container.exceptions = response && response.data
                            ? [this.exceptionMapService.mapToModel(response.data)]
                            : [];
                        container.updateRecords();
                        return [2 /*return*/, container];
                    case 3:
                        error_1 = _a.sent();
                        if (error_1 && (error_1.status === 404 || (error_1.error && error_1.error.status === 404))) {
                            return [2 /*return*/, new PayCodeDetailsContainer()]; // Return empty container for 404 errors
                        }
                        this.handleError(error_1);
                        throw error_1;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PayCodeDetailsApiService.prototype.savePaycodeException = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request, response, error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.apiRoot + "/paycodeexceptions";
                        body = this.exceptionMapService.mapToDto(data);
                        request = new HttpRequest('POST', url, body);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiUtilService.request(request)];
                    case 2:
                        response = _a.sent();
                        return [2 /*return*/, response.data]; // Assuming response.data contains the PaycodeExceptionDto
                    case 3:
                        error_2 = _a.sent();
                        this.handleError(error_2);
                        throw error_2;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PayCodeDetailsApiService.prototype.deletePaycodeException = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, error_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.apiRoot + "/paycodeexceptions/" + id;
                        request = new HttpRequest('DELETE', url);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiUtilService.request(request)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_3 = _a.sent();
                        this.handleError(error_3);
                        throw error_3;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    // Rules
    PayCodeDetailsApiService.prototype.getRules = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response, container, error_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.apiRoot + "/rules/" + id;
                        request = new HttpRequest('GET', url);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiUtilService.request(request)];
                    case 2:
                        response = _a.sent();
                        container = new PayCodeDetailsContainer();
                        container.rules = response && response.data
                            ? response.data.map(this.ruleFormulaMapService.mapToDto)
                            : [];
                        container.updateRecords();
                        return [2 /*return*/, container];
                    case 3:
                        error_4 = _a.sent();
                        if (error_4 && (error_4.status === 404 || (error_4.error && error_4.error.status === 404))) {
                            return [2 /*return*/, new PayCodeDetailsContainer()];
                        }
                        this.handleError(error_4);
                        throw error_4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PayCodeDetailsApiService.prototype.getRulesByExceptionId = function (exceptionId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response, container, error_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.apiRoot + "/rules/exception/" + exceptionId;
                        request = new HttpRequest('GET', url);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiUtilService.request(request, true)];
                    case 2:
                        response = _a.sent();
                        container = new PayCodeDetailsContainer();
                        // Manually check for response and response.data
                        container.rules = response && response.data
                            ? response.data.map(this.ruleMapService.mapToModel)
                            : [];
                        container.updateRecords();
                        return [2 /*return*/, container];
                    case 3:
                        error_5 = _a.sent();
                        if (error_5 && (error_5.status === 404 || (error_5.error && error_5.error.status === 404))) {
                            console.warn('No rules found for exception ID:', exceptionId);
                            return [2 /*return*/, new PayCodeDetailsContainer()]; // Return empty container for 404 errors
                        }
                        this.handleError(error_5);
                        throw error_5;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PayCodeDetailsApiService.prototype.saveRule = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request, response, error_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.apiRoot + "/rules";
                        body = this.ruleMapService.mapToDto(data);
                        request = new HttpRequest('POST', url, body);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiUtilService.request(request)];
                    case 2:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                    case 3:
                        error_6 = _a.sent();
                        this.handleError(error_6);
                        throw error_6;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PayCodeDetailsApiService.prototype.deleteRule = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, error_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.apiRoot + "/rules/" + id;
                        request = new HttpRequest('DELETE', url);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiUtilService.request(request)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_7 = _a.sent();
                        this.handleError(error_7);
                        throw error_7;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PayCodeDetailsApiService.prototype.validateRule = function (whereClause) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response, error_8;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.apiRoot + "/rules/validate?whereClause=" + encodeURIComponent(whereClause);
                        request = new HttpRequest('GET', url);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiUtilService.request(request)];
                    case 2:
                        response = _a.sent();
                        return [2 /*return*/, response.status === 200];
                    case 3:
                        error_8 = _a.sent();
                        this.handleError(error_8);
                        return [2 /*return*/, false]; // Return false if there's an error
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    // RuleFormulas
    PayCodeDetailsApiService.prototype.getRuleFormulasByRuleId = function (ruleId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response, error_9;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.apiRoot + "/rules/" + ruleId + "/formulas";
                        request = new HttpRequest('GET', url);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiUtilService.request(request, true)];
                    case 2:
                        response = _a.sent();
                        return [2 /*return*/, response && response.data
                                ? response.data.map(this.ruleFormulaMapService.mapToDto)
                                : []];
                    case 3:
                        error_9 = _a.sent();
                        if (error_9.status === 404 || (error_9.error && error_9.error.status === 404)) {
                            console.warn('No rule formulas found for rule ID:', ruleId);
                            return [2 /*return*/, []];
                        }
                        this.handleError(error_9);
                        throw error_9;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PayCodeDetailsApiService.prototype.saveRuleFormula = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request, response, error_10;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.apiRoot + "/rules/formulas";
                        body = this.ruleFormulaMapService.mapToDto(data);
                        request = new HttpRequest('POST', url, body);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiUtilService.request(request)];
                    case 2:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                    case 3:
                        error_10 = _a.sent();
                        this.handleError(error_10);
                        throw error_10;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PayCodeDetailsApiService.prototype.deleteRuleFormula = function (ruleId, formulaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, error_11;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.apiRoot + "/rules/" + ruleId + "/formulas/" + formulaId;
                        request = new HttpRequest('DELETE', url);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiUtilService.request(request)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_11 = _a.sent();
                        this.handleError(error_11);
                        throw error_11;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    // AdditionalRequirements
    PayCodeDetailsApiService.prototype.getAdditionalRequirements = function (exceptionId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response, container, error_12;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.apiRoot + "/additionalrequirements/exception/" + exceptionId;
                        request = new HttpRequest('GET', url);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiUtilService.request(request, true)];
                    case 2:
                        response = _a.sent();
                        container = new PayCodeDetailsContainer();
                        // Use ternary operator to manually check for response and response.data
                        container.additionalRequirements = response && response.data
                            ? [this.additionalRequirementMapService.mapToModel(response.data)]
                            : [];
                        container.updateRecords();
                        return [2 /*return*/, container];
                    case 3:
                        error_12 = _a.sent();
                        if (error_12.status === 404 || (error_12.error && error_12.error.status === 404)) {
                            console.warn('No additional requirements found for exception ID:', exceptionId);
                            return [2 /*return*/, new PayCodeDetailsContainer()]; // Return empty container for 404 errors
                        }
                        this.handleError(error_12);
                        throw error_12;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PayCodeDetailsApiService.prototype.saveAdditionalRequirements = function (exceptionId, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request, response, error_13;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.apiRoot + "/additionalrequirements/exception/" + exceptionId;
                        body = this.additionalRequirementMapService.mapToDto(data);
                        request = new HttpRequest('POST', url, body);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiUtilService.request(request)];
                    case 2:
                        response = _a.sent();
                        // Check the response status
                        if (response.status !== 200) {
                            throw new Error("Error saving additional requirement: " + response);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_13 = _a.sent();
                        this.handleError(error_13);
                        throw error_13;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PayCodeDetailsApiService.prototype.deleteAdditionalRequirement = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, error_14;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.apiRoot + "/additionalrequirements/" + id;
                        request = new HttpRequest('DELETE', url);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiUtilService.request(request)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_14 = _a.sent();
                        this.handleError(error_14);
                        throw error_14;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PayCodeDetailsApiService.prototype.handleError = function (error) {
        if (error.status === 404 || (error.error && error.error.status === 404))
            console.warn('Resource not found:', error);
        if (error.status === 500 || (error.error && error.error.status === 500)) {
            console.error('Internal server error:', error);
        }
        else {
            console.error('An error occurred:', error);
        }
    };
    PayCodeDetailsApiService.prototype.getTAServicesApiRoot = function () {
        return this.apiUtilService.getTAServicesApiRoot() + "/taappend";
    };
    return PayCodeDetailsApiService;
}());
export { PayCodeDetailsApiService };
