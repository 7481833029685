export class AdditionalRequirementModel {
    public ignoreBreak?: boolean;
    public calculateNetWorkTime?: boolean;
    public useContiguousTime?: boolean;
    public stayWithinExceptionTimeframe?: boolean;
    public exportAsDollarAmount?: boolean;
    public paidAtHolidayRate?: boolean;
    public regularOverride?: string;
    public otOverride?: string;
    public holidayOverride?: string;
    public overtimeHolidayOverride?: string;
    public otherCodeOverride?: string;
    public recalculate?: string;
    public requirementIds?: { [key: string]: number | null };

    public regularOverrideTemp?: number;
    public otOverrideTemp?: number;
    public holidayOverrideTemp?: number;
}