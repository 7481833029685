import { IConfigutrationContainer } from '../configiration-container.interface';
import { AdditionalRequirementModel } from './models/additional-requirement.model';
import { PaycodeExceptionModel } from './models/paycode-exception.model';
import { RuleModel } from './models/rule.model';

export class PayCodeDetailsContainer implements IConfigutrationContainer {
    public id: number;
    public exceptions: PaycodeExceptionModel[];
    public rules: RuleModel[];
    public additionalRequirements: AdditionalRequirementModel[];

    // Combined records to satisfy IConfigutrationContainer interface
    public records: (PaycodeExceptionModel | RuleModel | AdditionalRequirementModel)[];

    constructor() {
        this.exceptions = [];
        this.rules = [];
        this.additionalRequirements = [];
        this.records = [];
    }

    public updateRecords() {
        this.records = []
            .concat(this.exceptions)
            .concat(this.rules)
            .concat(this.additionalRequirements)
    }
}