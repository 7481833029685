import * as tslib_1 from "tslib";
import { ActivatedRoute, Router } from '@angular/router';
import { unsubscribe } from './../../../core/decorators/unsubscribe-decorator';
import { ModalService } from './../../../common/services/modal/modal.service';
import { ScheduleApiService } from './../../services/schedule/schedule-api.service';
import { OnInit, OnDestroy } from '@angular/core';
import { Assert } from '../../../framework/index';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash';
import * as moment from 'moment';
import { appConfig } from '../../../app.config';
import { OrgLevel } from '../../../state-model/models/index';
import { OpenShiftShortDetailsWrapper, OpenShiftSummary, OpenShiftShortDetailsSetWrapper, PostScheduleSettings, IOpenShiftCountChangedEventArgs } from '../../models/index';
import { OpenShiftManagementApiService, OpenShiftManagementManagementService } from '../../services/index';
import { LookupApiService } from '../../../organization/services/index';
import { mutableSelect } from '../../../core/decorators/index';
import { NotificationsService } from '../../../core/components/angular2-notifications/simple-notifications/services/notifications.service';
var OpenShiftManagementComponent = /** @class */ (function () {
    function OpenShiftManagementComponent(openShiftManagementApiService, openShiftManagementManagementService, lookupApiService, scheduleApiService, modalService, activatedRoute, router, notificationsService) {
        this.openShiftManagementApiService = openShiftManagementApiService;
        this.openShiftManagementManagementService = openShiftManagementManagementService;
        this.lookupApiService = lookupApiService;
        this.scheduleApiService = scheduleApiService;
        this.modalService = modalService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.notificationsService = notificationsService;
        this.state = {
            isLoading: false,
        };
        this.appConfig = appConfig;
    }
    OpenShiftManagementComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgLevelSubscription = this.orgLevel$
            .filter(function (o) { return !_this.selectedOrgLevel || o && _this.selectedOrgLevel.id !== o.id; })
            .subscribe(function (o) {
            _this.selectedOrgLevel = o;
            _this.loadScheduleCycles(_this.selectedOrgLevel);
        });
        this.routeSubscripion = this.activatedRoute.queryParams
            .subscribe(function (queryParams) {
            var dateOn = queryParams['date'];
            if (dateOn) {
                _this.paramDate = moment(dateOn, appConfig.linkDateFormat);
            }
        });
        this.routeSubscripion = this.openShiftManagementManagementService.loading$
            .subscribe(function (isShown) {
            _this.state.isLoading = isShown;
        });
        this.onShiftCountChangedByPartialShift = this.openShiftManagementManagementService.onOpenShiftCountChangedByPartialShift$.subscribe(function (args) { return _this.refreshPage(); });
    };
    OpenShiftManagementComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    OpenShiftManagementComponent.prototype.onSummaryItemSelected = function (shortDetails) {
        this.selectDailyItem(shortDetails);
    };
    OpenShiftManagementComponent.prototype.onScheduleCycleSelected = function (cycle) {
        this.setScheduleCycleSelected(cycle);
    };
    OpenShiftManagementComponent.prototype.onFilterChanged = function (filters) {
        this.filters = filters;
        this.loadScheduleCycleSummary();
    };
    OpenShiftManagementComponent.prototype.postSchedule = function () {
        var _this = this;
        this.state.isLoading = true;
        this.scheduleApiService.postSchedule(this.selectedOrgLevel, this.selectedScheduleCycle.startDate.toDate(), this.selectedScheduleCycle.endDate.toDate(), this.openShiftManagementManagementService.postSettings)
            .then(function (value) {
            _this.state.isLoading = false;
            _this.notificationsService.success('Post Schedule', 'Schedule has been successfully posted.');
            _this.loadScheduleCycleSummary();
        }).catch(function (reason) {
            _this.state.isLoading = false;
            _this.notificationsService.error('Post Schedule', 'Schedule posting failed.');
        });
    };
    OpenShiftManagementComponent.prototype.savePostSettings = function () {
        var _this = this;
        this.state.isLoading = true;
        this.openShiftManagementManagementService.updatePostSettingsByCycle(this.selectedScheduleCycle);
        this.scheduleApiService.savePostSettings(this.selectedOrgLevel, this.openShiftManagementManagementService.postSettings)
            .then(function (value) {
            _this.state.isLoading = false;
        }).catch(function (reason) {
            _this.state.isLoading = false;
        });
    };
    OpenShiftManagementComponent.prototype.loadPostSettings = function (orgLevel) {
        return this.scheduleApiService.loadPostSettings(orgLevel, this.selectedScheduleCycle.startDate.toDate(), this.selectedScheduleCycle.endDate.toDate());
    };
    OpenShiftManagementComponent.prototype.selectDailyItem = function (shortDetails) {
        this.selectedShortDetails = shortDetails;
        this.loadDailySummary(this.selectedOrgLevel, this.selectedShortDetails);
    };
    OpenShiftManagementComponent.prototype.loadScheduleCycles = function (orgLevel) {
        var _this = this;
        this.selectedOpenShiftSummary = null;
        this.selectedOrgLevel = orgLevel;
        this.selectedStartDate = null;
        this.lookupApiService.getScheduleCycles(orgLevel.id)
            .then(function (cycles) {
            _this.scheduleCycles = cycles;
            if (!_this.scheduleCycles)
                return;
            var dateNow = _this.paramDate ? _this.paramDate : moment().startOf('day');
            var curentCycle = _.find(_this.scheduleCycles, function (cycle) {
                if (dateNow.isBetween(cycle.startDate, cycle.endDate, null, '[]'))
                    return true;
                else
                    return false;
            });
            if (curentCycle) {
                _this.selectedScheduleCycle = curentCycle;
                _this.setScheduleCycleSelected(curentCycle);
            }
        });
    };
    OpenShiftManagementComponent.prototype.getDailyDetailsByDate = function (dateOn) {
        var details = undefined;
        _.forEach(this.summaryDetailsSet.weeklyData, function (weeklyData) {
            _.forEach(weeklyData.days, function (dayData) {
                if (dayData.startOfDay.isSame(dateOn.startOf('day'))) {
                    details = dayData.data;
                }
            });
        });
        return details;
    };
    OpenShiftManagementComponent.prototype.setScheduleCycleSelected = function (cycle) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var settings, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!cycle) return [3 /*break*/, 5];
                        this.selectedScheduleCycle = cycle;
                        this.openShiftManagementManagementService.getSelectedScheduleCycle(this.selectedScheduleCycle);
                        this.selectedStartDate = cycle.startDate.toDate();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        this.state.isLoading = true;
                        return [4 /*yield*/, this.loadPostSettings(this.selectedOrgLevel)];
                    case 2:
                        settings = _a.sent();
                        this.openShiftManagementManagementService.postSettingsLoaded(settings);
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        throw error_1;
                    case 4:
                        this.state.isLoading = false;
                        this.loadScheduleCycleSummary();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    OpenShiftManagementComponent.prototype.loadScheduleCycleSummary = function () {
        var _this = this;
        this.selectedOpenShiftSummary = null;
        if (!this.selectedOrgLevel || !this.selectedStartDate) {
            return;
        }
        this.state.isLoading = true;
        this.openShiftManagementApiService.getOpenShiftScheduleCycleSummary(this.selectedOrgLevel.id, this.selectedStartDate, this.filters)
            .then(function (summaryDetailsSetWrap) {
            _this.openShiftManagementManagementService.setCanPostSchedule(summaryDetailsSetWrap.canPostSchedule);
            _this.openShiftManagementManagementService.setCanEditOpenShiftCount(summaryDetailsSetWrap.canEditOpenShiftCount);
            _this.openShiftManagementManagementService.setCanSendSMS(summaryDetailsSetWrap.canSendSMS);
            _this.summaryDetailsSet = summaryDetailsSetWrap;
            _this.state.isLoading = false;
            _this.tryToSelectTodaysScheduleDetails();
            console.log(summaryDetailsSetWrap.canSendSMS);
        });
    };
    OpenShiftManagementComponent.prototype.tryToSelectTodaysScheduleDetails = function () {
        var date;
        if (this.selectedShortDetails) {
            date = moment(this.selectedShortDetails.date);
        }
        var dateNow = date ? date : moment().startOf('day');
        var todaysDailyDetails = this.getDailyDetailsByDate(dateNow);
        if (todaysDailyDetails) {
            this.openShiftManagementManagementService.selectDailyDetails({ dailySummaryDetails: todaysDailyDetails });
        }
    };
    OpenShiftManagementComponent.prototype.loadDailySummary = function (orgLevel, shortDetails) {
        var _this = this;
        this.selectedOpenShiftSummary = null;
        Assert.isNotNull(orgLevel, 'orgLevel');
        Assert.isNotNull(shortDetails, 'shortDetails');
        this.state.isLoading = true;
        this.openShiftManagementApiService.getOpenShiftSummary(this.selectedOrgLevel.id, shortDetails.date, this.filters).then(function (openShiftSummary) {
            _this.selectedOpenShiftSummary = openShiftSummary;
            _this.state.isLoading = false;
        });
    };
    OpenShiftManagementComponent.prototype.updatePartnerShiftCount = function (isPartnerShift) {
        var _this = this;
        this.state.isLoading = true;
        if (isPartnerShift) {
            this.openShiftManagementApiService.getOpenShiftScheduleCycleSummary(this.selectedOrgLevel.id, this.selectedStartDate, this.filters)
                .then(function (summaryDetailsSetWrap) {
                _this.summaryDetailsSet = summaryDetailsSetWrap;
                _this.state.isLoading = false;
            });
        }
    };
    OpenShiftManagementComponent.prototype.refreshPage = function () {
        this.loadScheduleCycles(this.selectedOrgLevel);
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], OpenShiftManagementComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OpenShiftManagementComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OpenShiftManagementComponent.prototype, "routeSubscripion", void 0);
    return OpenShiftManagementComponent;
}());
export { OpenShiftManagementComponent };
