import { OpenShiftDetails } from './../../../models/open-shift-management/open-shift-details';
import { Component, Input, Inject } from '@angular/core';
import { DialogOptions } from '../../../../common/models/index';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { OPEN_SHIFT_COUNT_TOKEN, OPEN_SHIFT_DETAILS_TOKEN } from '../../../../core/models/index';
import * as _ from 'lodash';


@Component({
  moduleId: module.id,
  selector: 'slx-edit-open-shift-count',
  templateUrl: 'edit-open-shift-count.component.html',
  styleUrls: ['edit-open-shift-count.component.scss']
})
export class EditOpenShiftCountComponent {

  public options: DialogOptions;
  public dialogResult: boolean;
  public adjustedShiftCount: number;
  public openShiftDetails: OpenShiftDetails;

  private modalService: ModalService;

  constructor(options: DialogOptions, modalService: ModalService,
    @Inject(OPEN_SHIFT_DETAILS_TOKEN) openShiftDetails: OpenShiftDetails) {
    this.options = options;
    this.modalService = modalService;
    this.dialogResult = false;
    this.adjustedShiftCount = openShiftDetails.adjustedShiftCount;
    this.openShiftDetails = openShiftDetails;
  }

  public get currenOpenShiftCount(): number {
    if (!this.openShiftDetails) return 0;
    return this.adjustedShiftCount - this.openShiftDetails.scheduledShiftCount;
  }

  public get scheduledShiftCount(): number {
    if (!this.openShiftDetails) return 0;
    let scheduledShiftCount: number = 0;
    if (this.openShiftDetails.hasPartialShift && this.openShiftDetails.partialShifts.length > 0) {
      _.forEach(this.openShiftDetails.partialShifts, (shiftInDetails: OpenShiftDetails) => {
        scheduledShiftCount += shiftInDetails.scheduledShiftCount;
      })
    } else {
      scheduledShiftCount = this.openShiftDetails.scheduledShiftCount;
    }

    return scheduledShiftCount;
  }

  public get scheduledHours(): number {
    if (!this.openShiftDetails) return 0;
    let scheduledShiftHours: number = 0;
    if (this.openShiftDetails.hasPartialShift && this.openShiftDetails.partialShifts.length > 0) {
      _.forEach(this.openShiftDetails.partialShifts, (shiftInDetails: OpenShiftDetails) => {
        scheduledShiftHours += shiftInDetails.scheduledHours;
      })
    } else {
      scheduledShiftHours = this.openShiftDetails.scheduledHours;
    }

    return scheduledShiftHours;
  }

  public onOk(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
