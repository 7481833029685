import { screenUtils } from './../../../common/utils/screenUtils';

import * as _ from 'lodash';
import { Component, Input, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef, ViewEncapsulation, Renderer2, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { unsubscribe, destroyService } from '../../../core/decorators/index';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/of';

import { Assert } from '../../../framework/index';
import { ISession } from '../../../authentication/store/index';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';
import { ModalService, StateManagementService, ChangeManagementService, RestoreQueryParamsService } from '../../../common/services/index';
import { ApiWatchService } from '../../../core/services/index';
import { ApiEvent, ApiStatus } from '../../../core/models/index';
import { ApplicationStateBusService, OrgLevelManagementService } from '../../services/index';
import { UserApplication } from '../../../state-model/models/index';
import { TreeItem } from '@progress/kendo-angular-treeview';
import { StateNavigationService } from '../../../common/services/index';
import { scheduleMicrotask } from '../../../core/utils/index';
import { AuthenticationService } from '../../../authentication/services/index';
import { InfoDialogComponent } from '../../../common';

@Component({
  moduleId: module.id,
  selector: 'slx-organization-structure',
  templateUrl: 'organization-structure.component.html',
  styleUrls: ['organization-structure.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrganizationStructureComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  public set show(value: boolean) {
    this.visibilityChanged(value);
  }
  @Input()
  public set top(value: number) {
    this.positionChanged(value, null, null);
  }
  @Input()
  public set left(value: number) {
    this.positionChanged(null, value, null);
  }
  @Input()
  public set pointerIconShift(value: number) {
    this.positionChanged(null, null, value);
  }

  public isLocked: boolean;
  public storedOrgLevels: OrgLevel[];
  public orgLevels: OrgLevel[];
  public orgLevel: OrgLevel;
  public orgLevelFieldName: string;
  public keyForExpanding: string;
  public expandedOrgLevels: number[];
  public keyForSelecting: string;
  public selectedOrgLevels: number[];
  public m_searchValue: string;

  public get noData(): boolean {
    return this.managementService.noData;
  }
  public set noData(s: boolean) {
    this.managementService.noData = s;
  }

  @unsubscribe()
  private apiWatchStartSubscription: Subscription;
  @unsubscribe()
  private apiWatchEndSubscription: Subscription;
  @unsubscribe()
  private orgLevelsLoadedSubscription: Subscription;
  @unsubscribe()
  private statusSubscription: Subscription;
  @unsubscribe()
  private selectedSubscription: Subscription;
  @unsubscribe()
  private searchValueSubscription: Subscription;
  @unsubscribe()
  private noOrgLevelsSubscription: Subscription;

  private searchValue$: Subject<string>;
  private isViewInit: boolean;
  private isShowOrgTree: boolean;
  private posTop: number;
  private posLeft: number;
  private iconShift: number;

  private navService: StateNavigationService;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private managementService: OrgLevelManagementService,
    private apiWatchService: ApiWatchService,
    private cdr: ChangeDetectorRef,
    private appStateBusService: ApplicationStateBusService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private changeManagement: ChangeManagementService,
    private restoreQueryParamsService: RestoreQueryParamsService
  ) {
    this.orgLevelFieldName = 'name';
    this.keyForExpanding = 'id';
    this.keyForSelecting = 'id';
    this.expandedOrgLevels = [];
    this.selectedOrgLevels = [];
    this.orgLevels = [];
    this.searchValue$ = new Subject<string>();
    this.isViewInit = false;
    this.isShowOrgTree = false;
    this.navService = new StateNavigationService(this.router, this.route, this.changeManagement, this.restoreQueryParamsService);
    this.m_searchValue = '';
    this.posTop = 0;
    this.posLeft = 0;
    this.iconShift = 0;
    this.isLocked = false;
  }

  public ngOnInit(): void {
    this.statusSubscription = this.managementService.onLoadStatus$.subscribe((isLocked: boolean) => {
      this.isLocked = isLocked;
    });

    this.orgLevelsLoadedSubscription = this.appStateBusService.subscribeToOrgLevelsLoaded((orgLevels: OrgLevel[]) => {
      scheduleMicrotask(() => {
        if (orgLevels.length > 0) {
          this.storedOrgLevels = orgLevels;
          this.orgLevels = _.cloneDeep(orgLevels);
        }
      });
    });

    this.selectedSubscription = this.appStateBusService.subscribeToSelectOrgLevel((orgLevel: OrgLevel) => {
      scheduleMicrotask(() => {
        const newOrgLevelId = _.get(orgLevel, 'id', null);
        const currentOrgLevelId = _.get(this.orgLevel, 'id', null);
        if (_.isNumber(newOrgLevelId) && currentOrgLevelId !== newOrgLevelId) {
          this.orgLevel = orgLevel;
          if (this.isMobile) {
            this.visibilityChanged(false);
          }
        }
      });
    });

    this.searchValueSubscription = this.searchValue$.debounceTime(400)
      .subscribe((value: string) => this.searchOrglevel(value));

    this.apiWatchStartSubscription = this.apiWatchService.apiRequestStart$.subscribe((event: ApiEvent) => {
      if (!event.isBackgroundRequest) {
        this.isLocked = true;
      }
      this.cdr.detectChanges();
    });
    this.apiWatchEndSubscription = this.apiWatchService.apiRequestEnd$
      .debounceTime(1000)
      .subscribe((event: ApiEvent) => {
        if (!event.isBackgroundRequest) {
          this.isLocked = false;
        }
        this.cdr.detectChanges();
      });
  }

  public ngAfterViewInit(): void {
    this.isViewInit = true;
    this.setVisibility();
    this.setPosition();
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  public set searchValue(value: string) {
    this.m_searchValue = value;
    this.searchValue$.next(value);
  }

  public get searchValue(): string {
    return this.m_searchValue;
  }

  public get orgTreeElem(): HTMLElement {
    return this.elementRef.nativeElement as HTMLElement;
  }

  public get hasOrgLevelTree(): boolean {
    return _.isArray(this.orgLevels) && _.size(this.orgLevels) > 0;
  }

  public hasChildsOrgLevels(orgLevelInTree: OrgLevel): boolean {
    return orgLevelInTree.hasChilds;
  }

  public childsOrgLevels(orgLevelInTree: OrgLevel): Observable<OrgLevel[]> {
    return Observable.of(orgLevelInTree.childs);
  }

  public searchOrglevel(value: string): void {
    this.resetSearch(false);
    if (_.isString(value) && _.size(value) > 0) {
      const notContain: boolean[] = [];
      _.forEach(this.orgLevels, (orgLevel: OrgLevel, index: number) => {
        notContain.push(this.searchInTree(orgLevel, value));
      });
      _.remove(this.orgLevels, (o: OrgLevel, i: number) => notContain[i]);
      this.expandAllTree();
    }
  }

  public onOrgLevelChanged(event: TreeItem): void {
    const orgLevel: OrgLevel = event.dataItem;
    const expanded: number[] = [...this.expandedOrgLevels, ...orgLevel.treeViewPath, orgLevel.id];
    this.expandedOrgLevels = _.uniq(expanded);
    if (orgLevel) {
      this.navService.navigateToOrgLevelAndKeepQueryParams(orgLevel.id);
    }
  }

  public onClearSearch(): void {
    this.resetSearch(true);
    this.resetTree();
  }

  public getIconClass(orgLevel: OrgLevel): string {
    let cssClass: string = '';
    switch (orgLevel.type) {
      case OrgLevelType.corporation:
        cssClass = 'fa-building corp';
        break;
      case OrgLevelType.organization:
        cssClass = 'fa-building';
        break;
      case OrgLevelType.region:
        cssClass = 'fa-globe';
        break;
      case OrgLevelType.division:
        cssClass = 'fa-sitemap';
        break;
      case OrgLevelType.department:
        cssClass = 'fa-users';
        break;
    }

    return cssClass;
  }

  public getNodeText(orgLevel: OrgLevel): string {
    let name: string = orgLevel.name;
    const valueLength: number = this.searchValue.length;
    if (valueLength > 0) {
      const regexp: RegExp = new RegExp(this.searchValue, 'i');
      const startIndex: number = name.search(regexp);
      if (startIndex !== -1) {
        const endIndex: number = startIndex + valueLength;
        name = name.slice(0, startIndex) + `<strong>${name.slice(startIndex, endIndex)}</strong>` + name.slice(endIndex);
      }

      return name;
    }

    return name;
  }

  private searchInTree(orgLevel: OrgLevel, value: string): boolean {
    const notContain: boolean = !_.includes(orgLevel.name.toLocaleLowerCase(), value.toLocaleLowerCase());
    const notContainChilds: boolean[] = [];
    if (!notContain && orgLevel.childs.length > 0){
      orgLevel.childs.forEach(x=>{
        notContainChilds.push(false);
      })
    }
    else if (notContain && orgLevel.childs.length > 0) {
      _.forEach(orgLevel.childs, (orgLevel: OrgLevel, index: number) => {
        notContainChilds.push(this.searchInTree(orgLevel, value));
      });
    }
    const notContainTotal: boolean = notContainChilds.length === 0 || _.every(notContainChilds, (v: boolean) => v === true);
    if (notContainTotal) {
      orgLevel.childs.length = 0;
    } else {
      _.remove(orgLevel.childs, (o: OrgLevel, i: number) => notContainChilds[i]);
    }

    return notContain && notContainTotal;
  }

  private expandAllTree(): void {
    let orgLevelsToExpand: number[] = [];
    const collectOrgLevelIds: (orgLevel: OrgLevel) => number[] = (orgLevel: OrgLevel) => {
      const childsOrgLevelIds: number[] = [orgLevel.id];
      if (orgLevel.childs.length > 0) {
        _.forEach(orgLevel.childs, (orgLevel: OrgLevel, index: number) => {
          childsOrgLevelIds.push(...collectOrgLevelIds(orgLevel));
        });
      }

      return childsOrgLevelIds;
    };

    _.forEach(this.orgLevels, (orgLevel: OrgLevel) => {
      orgLevelsToExpand.push(orgLevel.id, ...collectOrgLevelIds(orgLevel));
    });
    this.expandedOrgLevels = _.uniq(orgLevelsToExpand);
  }

  private visibilityChanged(isShow: boolean): void {
    this.isShowOrgTree = isShow;
    if (this.isViewInit) {
      this.setVisibility();
    }
  }

  private positionChanged(top: number, left: number, iconShift: number): void {
    if (_.isNumber(top)) this.posTop = top;
    if (_.isNumber(left)) this.posLeft = left;
    if (_.isNumber(iconShift)) this.iconShift = iconShift;
    if (this.isViewInit) {
      this.setPosition();
    }
  }

  private setVisibility(): void {
    this.resetTree();
    if (!this.isShowOrgTree) {
      this.resetSearch(true);
    }
    if (this.isShowOrgTree) {
      this.renderer.addClass(this.orgTreeElem, 'show');
    } else {
      this.renderer.removeClass(this.orgTreeElem, 'show');
    }
  }

  private resetSearch(isClear: boolean): void {
    this.orgLevels = _.cloneDeep(this.storedOrgLevels);
    if (isClear) {
      this.m_searchValue = '';
    }
  }

  private setPosition(): void {
    this.renderer.setStyle(this.orgTreeElem, 'top', `${this.posTop}px`);
    this.renderer.setStyle(this.orgTreeElem, 'left', `${this.posLeft}px`);
    const pointerIcon: HTMLElement = this.orgTreeElem.querySelector('.js-pointer');
    if (_.isElement(pointerIcon)) {
      const styles: CSSStyleDeclaration = window.getComputedStyle(pointerIcon);
      this.renderer.setStyle(pointerIcon, 'left', `${this.iconShift - (parseInt(styles.width) / 2)}px`);
    }
  }

  private resetTree(): void {
    const orgLevelId: number = _.get(this.orgLevel, 'id');
    const treeViewPath: number[] = _.get(this.orgLevel, 'treeViewPath');
    if (_.isNumber(orgLevelId)) {
      this.selectedOrgLevels.length = this.expandedOrgLevels.length = 0;
      this.selectedOrgLevels.push(orgLevelId);
      this.expandedOrgLevels.push(...treeViewPath);
    }
  }

  private get isMobile(): boolean {
    return screenUtils.isMobile;
  }
}
