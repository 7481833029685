import * as tslib_1 from "tslib";
import { ActivatedRoute, Router } from '@angular/router';
import { OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/combineLatest';
import * as _ from 'lodash';
import * as moment from 'moment';
import { appConfig } from '../../../../app.config';
import { StateResetTypes } from '../../../../core/models/settings/reset-types';
import { EmployeesPunchesContainer, EmployeeDailyPunches } from '../../../models/index';
import { PunchesManagementService } from '../../../services/punches/punches-management.service';
import { unsubscribe, destroyService } from '../../../../core/decorators/index';
import { ColumnManagementService, StateManagementService, ComponentStateStorageService, DailyPunchesNavigationService } from '../../../../common/services/index';
import { RangeDates } from '../../../../common/models/range-dates';
import { PunchUtils } from '../../../utils/punch-utils';
var PunchesManagementComponent = /** @class */ (function () {
    function PunchesManagementComponent(managementService, columnManagementService, stateManagement, storageService, activatedRoute, router) {
        this.columnManagementService = columnManagementService;
        this.stateManagement = stateManagement;
        this.storageService = storageService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.dataRangeCollapsed = true;
        this.groupName = 'EmployeePunches';
        this.m_dateFiltersControlId = 'DateFiltersNgx';
        this.m_dateFiltersResetType = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;
        this.managementService = managementService;
        this.appConfig = appConfig;
        this.state = {
            isLoading: false,
            isFiltering: false
        };
        this.selectedRange = new RangeDates();
        //this.ranges = [];
        this.punchesNavService = new DailyPunchesNavigationService(this.router, this.activatedRoute);
    }
    PunchesManagementComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.columnManagementService.init('PunchesManagementComponent');
        this.columnManagementService.initGroup(this.groupName, 15);
        this.stateManagement.init('PunchesManagementComponent');
        this.loadStatusSubscription = this.managementService.onLoadStatus$
            .subscribe(function (value) {
            _this.state.isLoading = value;
        });
        this.loadedSubscription = this.managementService.onLoaded$
            .subscribe(function (container) {
            _this.container = container;
        });
        this.savedEmpPunchesSubscription = this.managementService.onSavedEmpPunches$.subscribe(function (empPunches) {
            _.forEach(empPunches, function (empPunch) {
                var index = _.findIndex(_this.container.entities, function (r) {
                    return moment(r.header.date).isSame(empPunch.header.date) && r.header.employee.id === empPunch.header.employee.id;
                });
                _this.container.entities.splice(index, 1, empPunch);
            });
        });
        this.saveFiltersSubscription = this.managementService.saveFilters$.subscribe(function () {
            _this.saveFilters();
        });
        this.payCycleCalculatedSubscription = this.managementService.onOrgLevelLoaded$.
            combineLatest(this.activatedRoute.data, this.activatedRoute.queryParams)
            .subscribe(function (_a) {
            var lvl = _a[0], data = _a[1], queryParams = _a[2];
            if (PunchUtils.isRollup(lvl)) {
                return;
            }
            if (_this.orgLevelId !== lvl.id ||
                _this.qStartDate !== queryParams.startDate && _.isString(queryParams.startDate) ||
                _this.qEndDate !== queryParams.endDate && _.isString(queryParams.endDate)) {
                _this.orgLevelId = lvl.id;
                _this.qStartDate = queryParams.startDate;
                _this.qEndDate = queryParams.endDate;
                var range = new RangeDates();
                var routeData = data.punchesRouteData;
                _this.managementService.lastPayCycle = routeData.payCycle;
                if (routeData.isMissingOnly) {
                    _this.managementService.setOnlyMissingNextFilter();
                }
                if (routeData.punchRequest) {
                    _this.managementService.setOnlyPunchRequestNextFilter();
                }
                if (_this.qStartDate && _this.qEndDate) {
                    range.startDate = moment(_this.qStartDate, _this.appConfig.linkDateFormat).toDate();
                    range.endDate = moment(_this.qEndDate, _this.appConfig.linkDateFormat).toDate();
                }
                else {
                    var dateRange = _this.restoreFilters();
                    if (dateRange.startDate && dateRange.endDate) {
                        range = dateRange;
                    }
                    else {
                        range = routeData.range;
                    }
                }
                _this.selectedRange = range;
                _this.managementService.loadOrgLevelPunches(_this.orgLevelId, range.startDate, range.endDate);
            }
        });
    };
    PunchesManagementComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    PunchesManagementComponent.prototype.toggleDataRangeFilter = function () {
        this.dataRangeCollapsed = !this.dataRangeCollapsed;
    };
    PunchesManagementComponent.prototype.onFilterDateChanged = function (_a) {
        var _this = this;
        var startDate = _a.startDate, endDate = _a.endDate;
        this.selectedRange = { startDate: startDate, endDate: endDate };
        this.activatedRoute.queryParamMap.subscribe(function (params) {
            if (params.get('startDate') && params.get('endDate') && (params.get('startDate') !== moment(startDate).format(appConfig.linkDateFormat) || params.get('endDate') !== moment(endDate).format(appConfig.linkDateFormat))) {
                _this.punchesNavService.navigateToDailyPunchesDates(_this.orgLevelId, _this.selectedRange.startDate, _this.selectedRange.endDate);
            }
            else {
                _this.managementService.loadOrgLevelPunches(_this.orgLevelId, _this.selectedRange.startDate, _this.selectedRange.endDate);
                _this.saveFilters();
            }
        });
    };
    PunchesManagementComponent.prototype.onSaveAllChanges = function () {
        this.managementService.saveAllChanges();
    };
    PunchesManagementComponent.prototype.onClose = function (popper) {
        if (popper) {
            popper.hide();
        }
    };
    PunchesManagementComponent.prototype.filteringState = function (state) {
        var _this = this;
        setTimeout(function () {
            _this.state.isFiltering = state;
        }, state ? 1 : 100);
    };
    PunchesManagementComponent.prototype.saveFilters = function () {
        this.storageService.setControlState(this.stateManagement.componentKey, this.m_dateFiltersControlId, {
            value: { startDate: this.selectedRange.startDate, endDate: this.selectedRange.endDate }
        }, this.m_dateFiltersResetType, this.stateKey);
        this.stateManagement.controlValueChanged(this.m_dateFiltersControlId);
    };
    PunchesManagementComponent.prototype.restoreFilters = function () {
        var state = this.storageService.getControlState(this.stateManagement.componentKey, this.m_dateFiltersControlId);
        var startDate = _.get(state, 'value.startDate');
        var endDate = _.get(state, 'value.endDate');
        var dateRange = new RangeDates();
        dateRange.startDate = startDate ? moment(startDate).toDate() : null;
        dateRange.endDate = endDate ? moment(endDate).toDate() : null;
        return dateRange;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchesManagementComponent.prototype, "loadStatusSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchesManagementComponent.prototype, "loadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchesManagementComponent.prototype, "payCycleCalculatedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchesManagementComponent.prototype, "savedEmpPunchesSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchesManagementComponent.prototype, "saveFiltersSubscription", void 0);
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", PunchesManagementService)
    ], PunchesManagementComponent.prototype, "managementService", void 0);
    return PunchesManagementComponent;
}());
export { PunchesManagementComponent };
