import { Injectable } from '@angular/core';
import { HttpRequest, HttpParams } from '@angular/common/http';

import { dateTimeUtils } from '../../../common/utils/index';
import { UrlParamsService, ApiUtilService, DateTimeService } from '../../../common/services/index';
import { appConfig } from '../../../app.config';
import { ResponseBody } from '../../../core/models/index';
import { Meta } from '../../../core/models/api/meta';
import { organizationConfig } from '../../organization.config';
import { Assert } from '../../../framework/index';
import { LookupMapService } from './lookup-map.service';
import {
  EmpType, IEmpType,
  BudgetedPosition, IBudgetedPosition,
  BudgetedGroup, IBudgetedGroup,
  State, IState,
  Position, IPosition,
  AccrualPolicy, IAccrualPolicy,
  Acuity, IAcuity,
  CostCenter, ICostCenter,
  PayPolicy, IPayPolicy,
  ShiftDiffPolicy, IShiftDiffPolicy,
  ExemptStatus, IExemptStatus,
  Organization, IOrganization,
  Department, IDepartment,
  AttendancePointsDefinition, IAttendancePointsDefinition,
  PayType, PayCyclePeriod,
  ILookupEntity, LookupEntity,
  IMaritalStatus, MaritalStatus,
  IGender, Gender,
  IRace, Race,
  ShiftDefinition, IShiftDefinition,
  LocationUnit, ILocationUnit,
  ConstraintDefinition, IConstraintDefinition,
  Shift, IShift,
  ScheduleAbsence, IScheduleAbsence,
  ScheduleCycle, IScheduleCycle, SearchCategory, ISearchCategory,
  IEmployeeTerminationReason, EmployeeTerminationReason,
  IBudgetDefinition, BudgetDefinition,
  IAvailabilityDateRange, AvailabilityDateRange,
  PbjPosition, IPbjPosition,
  TimeclockDefinition, ITimeclockDefinition,
  TimeclockRestrictionDefinition, ITimeclockRestrictionDefinition,
  TimeclockModelDefinition, ITimeclockModelDefinition,
  TimezoneDefinition, ITimezoneDefinition,
  IPayCycle, PayCycle,
  IShiftGroupDefinition, ShiftGroupDefinition,
  PayRule, IPayRule,
  PunchType, IPunchType,
  TaAbsenceCode, ITaAbsenceCode,
  AgencyDefinition, IAgencyDefinition,
  TimecardPredefinedComment, ITimecardPredefinedComment, ICompany, Company,
  EssEntity, IEssEntity, EmployeeDocumentCategory, IEmployeeDocumentCategory, BenefitClassDefinition, IBenefitClassDefinition, VeteranStatus, IVeteranStatus,
  IPositionGroup, PositionGroup,
  EstablishmentType, IEstablishmentType,
  IPerformanceReviewCode, PerformanceReviewCode, IPmTemplate, PmTemplate, IPerformanceReviewCodeType, PerformanceReviewCodeType,
  IBenefitGroup, BenefitGroup, ObjAcc, IObjAcc, IAcaCategory, AcaCategory,IAcaSafeHarbor,IAcaReason,IAcaOfferOfCoverage,AcaSafeHarbor,AcaReason,AcaOfferOfCoverage,IAcaEmployers, AcaEmployers,
  IPbjActualPosition, PbjActualPosition,
  IPayUnitDefinition, PayUnitDefinition,
  TimeclockSlateProfileDefinition, ITimeclockSlateProfileDefinition, IncreaseReason, IIncreaseReason, LicenseType, ILicenseType,
  AttestationType,IAttestationType,RestrictedAnswer,IRestrictedAnswer,NotifyData,INotifyData
} from '../../models/index';
import { CacheType } from '../../../common/models/cache/cache-definition';
import * as _ from 'lodash';
import { IPunchProfile } from '../../models/lookup/punch-profile';
import { IPunchAttestationGroup } from '../../models/lookup/punch-attestation-groups';
import { IOrganizationTimezone, OrganizationTimezone } from '../../models/lookup/organization-timezone';

@Injectable()
export class LookupApiService {
  cachedPositionsData = [];
  cachedEmployeeData = [];

  constructor(
    private lookupMapService: LookupMapService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService,
    private dateTimeService: DateTimeService) {
  }

  public getLookup(lookupName: string, orgLevelId?: number, employeeId?: number): Promise<LookupEntity[]> {
    let lookup: string = organizationConfig.lookup[lookupName];
    Assert.isNotNull(lookup);

    const url: string = `${this.getApiRoot()}/${lookup}`;

    let promise: Promise<LookupEntity[]> = this.apiUtilService
      .cachedRequest<ILookupEntity[], Meta>(
        this.urlParamsService.createGetRequest(url, { orgLevelId, employeeId })
        , CacheType.longTerm)
      .then((response: ResponseBody<ILookupEntity[], Meta>) => this.lookupMapService.mapLookupEntity(response.data));
    return promise;
  }

  public getShiftGroupDefinition(orgLevelId?: number): Promise<ShiftGroupDefinition[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.shiftGroup}`;

    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: this.urlParamsService.convertToParams({ orgLevelId })
    });
    let promise: Promise<ShiftGroupDefinition[]> = this.apiUtilService
      .cachedRequest<IShiftGroupDefinition[], Meta>(request, CacheType.longTerm)
      .then((response: ResponseBody<IShiftGroupDefinition[], Meta>) => this.lookupMapService.mapShiftGroupsDefinition(response.data));
    return promise;
  }

  public getShifts(employeeId?: number, orgLevelId?: number): Promise<Shift[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.shift}`;

    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: this.urlParamsService.convertToParams({ orgLevelId: orgLevelId, empId: employeeId })
    });
    let promise: Promise<Shift[]> = this.apiUtilService
      .cachedRequest<IShift[], Meta>(request, CacheType.longTerm)
      .then((response: ResponseBody<IShift[], Meta>) => this.lookupMapService.mapShifts(response.data));
    return promise;
  }

  public getTaAbsenceCodes(employeeId?: number, orgLevelId?: number): Promise<TaAbsenceCode[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.taAbsenceCodes}`;

    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: this.urlParamsService.convertToParams({ orgLevelId, employeeId })
    });
    let promise: Promise<TaAbsenceCode[]> = this.apiUtilService
      .cachedRequest<ITaAbsenceCode[], Meta>(request, CacheType.longTerm)
      .then((response: ResponseBody<ITaAbsenceCode[], Meta>) => this.lookupMapService.mapTaAbsenceCodes(response.data));
    return promise;
  }

  public getScheduleCycles(orgLevelId: number): Promise<ScheduleCycle[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.scheduleCycles}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: this.urlParamsService.convertToParams({ orgLevelId })
    });

    let promise: Promise<ScheduleCycle[]> = this.apiUtilService
      .cachedRequest<IScheduleCycle[], Meta>(request, CacheType.longTerm)
      .then((response: ResponseBody<ScheduleCycle[], Meta>) => this.lookupMapService.mapScheduleCycles(response.data));
    return promise;
  }

  public getEmpTypes(): Promise<EmpType[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.employeeType}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EmpType[]> = this.apiUtilService
      .cachedRequest<IEmpType[], Meta>(request, CacheType.longTerm)
      .then((response: ResponseBody<IEmpType[], Meta>) => this.lookupMapService.mapEmpTypes(response.data));
    return promise;
  }

  public getBudgetedPositions(employeeId: number, orgLevelId: number): Promise<BudgetedPosition[]> {
    Assert.isNotNull(employeeId, 'employeeId');
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.budgetedPosition}`;

    let promise: Promise<BudgetedPosition[]> = this.apiUtilService
      .cachedRequest<IBudgetedPosition[], Meta>(this.urlParamsService.createGetRequest(url, { orgLevelId, empId: employeeId }), CacheType.longTerm)
      .then((response: ResponseBody<IBudgetedPosition[], Meta>) => this.lookupMapService.mapBudgetedPositions(response.data));
    return promise;
  }

  public getLicenseType(employeeId: number, orgLevelId: number): Promise<LicenseType[]> {
    Assert.isNotNull(employeeId, 'employeeId');
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.licenseType}`;

    let promise: Promise<LicenseType[]> = this.apiUtilService
      .cachedRequest<ILicenseType[], Meta>(this.urlParamsService.createGetRequest(url, { empId: employeeId, orgLevelId }), CacheType.longTerm)
      .then((response: ResponseBody<ILicenseType[], Meta>) => this.lookupMapService.mapLicenseTypes(response.data));
    return promise;
  }

  public getBudgetedGroups(orgLevelId: number, updateCacheForced: boolean = false): Promise<BudgetedGroup[]> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.budgetedGroups}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: this.urlParamsService.convertToParams({ orgLevelId: orgLevelId })
    });
    let promise: Promise<BudgetedGroup[]> = this.apiUtilService
      .cachedRequest<IBudgetedGroup[], Meta>(request, CacheType.longTerm, updateCacheForced)
      .then((response: ResponseBody<IBudgetedGroup[], Meta>) => this.lookupMapService.mapBudgetedGroups(response.data));
    return promise;
  }

  public getStates(): Promise<State[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.state}`;

    let request: HttpRequest<any> = new HttpRequest('GET', url);
    /*
     let promise: Promise<State[]> = Promise.resolve(
     this.lookupMapService.mapStates([{ name: 'NJ' }, { name: 'NY' }, { name: 'NC' }, { name: 'TX' }])
     );*/

    let promise: Promise<State[]> = this.apiUtilService
      .cachedRequest<IState[], Meta>(request, CacheType.longTerm)
      .then((response: ResponseBody<IState[], Meta>) => this.lookupMapService.mapStates(response.data));
    return promise;
  }

  public clearPositionsCacheData() {
    this.cachedPositionsData = [];
  }

  public clearOldPositionsCacheData() {
    const currentTime = new Date();
    this.cachedPositionsData = this.cachedPositionsData.filter(item => {
      const seconds = this.dateTimeService.GetDiffSeconds(new Date(item.timestamp), currentTime);
      if (seconds < appConfig.cacheInvalidatePositionsTime) {
        return item;
      }
    });
  }

  public getEffectivePositions(employeeId: number, effectiveDate: Date, updateCacheForced: boolean = false): Promise<Position[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.position}/${organizationConfig.lookup.effectivePositions}`;
    
    let params: StringMap<any> = {};

    if (employeeId) {
      params['empId'] = employeeId.toString();
    }
    
    if (effectiveDate) {
      params['effectiveDate'] = dateTimeUtils.convertToDtoString(effectiveDate);
    }

    let promise: Promise<Position[]> = this.apiUtilService
    .cachedRequest<IPosition[], Meta>(this.urlParamsService.createGetRequest(url, params), CacheType.longTerm, updateCacheForced)
    .then((response: ResponseBody<IPosition[], Meta>) => this.lookupMapService.mapPositions(response.data));
  return promise;

  }

  public getPositions(employeeId?: number, orgLevelId?: number, isActive?: boolean, updateCacheForced: boolean = false): Promise<Position[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.position}`;
    let params: StringMap<any> = {};

    if (employeeId) {
      params['empId'] = employeeId.toString();
    }

    if (orgLevelId) {
      params['orgLevelId'] = orgLevelId.toString();
    }

    if (_.isBoolean(isActive)) {
      params['isActive'] = String(isActive);
    }
    /*
     let promise: Promise<Position[]> = Promise.resolve(
     this.lookupMapService.mapPositions([{ id: 1, description: 'CNA' }, { id: 2, description: 'CNA2' }])
     );*/

    let promise: Promise<Position[]> = null;

    // Check if data already fetched for the selected cached Id within 60 seconds.
    // If yes than return the cached data
    // Else fetch data from API, store it in cache and return it.
    let cacheId: string = "" + employeeId;
    if (orgLevelId) {
      cacheId = cacheId + '_' + orgLevelId;
    }

    if (isActive) {
      cacheId = cacheId + '_' + isActive;
    }
    this.clearOldPositionsCacheData();
    const cacheItemIndex = this.cachedPositionsData.findIndex(item => item.cacheId === cacheId);
    if (cacheItemIndex > -1) {
      const seconds = this.dateTimeService.GetDiffSeconds(new Date(this.cachedPositionsData[cacheItemIndex].timestamp), new Date());
      if (seconds < appConfig.cacheInvalidatePositionsTime) {
        // In here means data present in cache.
        promise = new Promise((resolve) => {
          resolve(this.cachedPositionsData[cacheItemIndex].data);
        });
        return promise;
      }
    }

    promise = this.apiUtilService
      .cachedRequest<IPosition[], Meta>(this.urlParamsService.createGetRequest(url, params), CacheType.longTerm, updateCacheForced)
      .then((response: ResponseBody<IPosition[], Meta>) => {
        this.cachedPositionsData.push({
          cacheId,
          data: this.lookupMapService.mapPositions(response.data),
          timestamp: new Date().getTime()
        });
        return this.lookupMapService.mapPositions(response.data)
      });

    return promise;
  }

  public getPbjPosition(employeeId?: number, orgLevelId?: number, isActive?: boolean, updateCacheForced: boolean = false): Promise<PbjActualPosition[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.pbjPosition}`;
    let params: StringMap<any> = {};

    if (employeeId) {
      params['empId'] = employeeId.toString();
    }

    if (orgLevelId) {
      params['orgLevelId'] = orgLevelId.toString();
    }

    if (_.isBoolean(isActive)) {
      params['isActive'] = String(isActive);
    }

    let promise: Promise<PbjActualPosition[]> = this.apiUtilService
      .cachedRequest<IPbjActualPosition[], Meta>(this.urlParamsService.createGetRequest(url, params), CacheType.longTerm, updateCacheForced)
      .then((response: ResponseBody<IPbjActualPosition[], Meta>) => this.lookupMapService.mapActualPbjPositions(response.data));
    return promise;
  }

  public getEstablishmentTypes(): Promise<any[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.establishmentTypes}`;

    let promise: Promise<EstablishmentType[]> = this.apiUtilService
      .cachedRequest<IEstablishmentType[], Meta>(this.urlParamsService.createGetRequest(url), CacheType.longTerm)
      .then((response: ResponseBody<IEstablishmentType[], Meta>) => this.lookupMapService.mapEstablishmentTypes(response.data));
    return promise;
  }

  public getShiftDiffPolicies(employeeId?: number, orgLevelId?: number): Promise<ShiftDiffPolicy[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.shiftdiffpolicy}`;

    let promise: Promise<ShiftDiffPolicy[]> = this.apiUtilService
      .cachedRequest<IShiftDiffPolicy[], Meta>(this.urlParamsService.createGetRequest(url, { empId: employeeId, orgLevelId }), CacheType.longTerm)
      .then((response: ResponseBody<IShiftDiffPolicy[], Meta>) => this.lookupMapService.mapShiftDiffPolicies(response.data));
    return promise;
  }

  public getPayPolicies(employeeId?: number, orgLevelId?: number): Promise<PayPolicy[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.paypolicy}`;

    let promise: Promise<PayPolicy[]> = this.apiUtilService
      .cachedRequest<IPayPolicy[], Meta>(this.urlParamsService.createGetRequest(url, { empId: employeeId, orgLevelId }), CacheType.longTerm)
      .then((response: ResponseBody<IPayPolicy[], Meta>) => this.lookupMapService.mapPayPolicies(response.data));
    return promise;
  }

  public getAcuities(orgLevelId?: number): Promise<Acuity[]> {
    const url: string = `${appConfig.api.url}/${appConfig.api.version}/${organizationConfig.lookup.acuities}`;

    let promise: Promise<Acuity[]> = this.apiUtilService
      .cachedRequest<IAcuity[], Meta>(this.urlParamsService.createGetRequest(url, { orgLevelId }), CacheType.longTerm)
      .then((response: ResponseBody<IAcuity[], Meta>) => this.lookupMapService.mapAcuities(response.data));
    return promise;
  }

  public getEmployeeDocumentCategories(): Promise<EmployeeDocumentCategory[]> {
    const url: string = `${appConfig.api.url}/${appConfig.api.version}/${organizationConfig.api.employee.root}/${organizationConfig.lookup.documentsCategories}`;

    let promise: Promise<EmployeeDocumentCategory[]> = this.apiUtilService
      .cachedRequest<IEmployeeDocumentCategory[], Meta>(this.urlParamsService.createGetRequest(url), CacheType.longTerm)
      .then((response: ResponseBody<IEmployeeDocumentCategory[], Meta>) => this.lookupMapService.mapEmployeeDocumentCategories(response.data));
    return promise;
  }

  public getCostCenters(employeeId: number): Promise<CostCenter[]> {
    const url: string = `${appConfig.api.url}/${appConfig.api.version}/${organizationConfig.lookup.costCenters}`;
    const params: any = { employeeId: employeeId };
    let promise: Promise<CostCenter[]> = this.apiUtilService
      .cachedRequest<ICostCenter[], Meta>(this.urlParamsService.createGetRequest(url, params), CacheType.longTerm)
      .then((response: ResponseBody<ICostCenter[], Meta>) => this.lookupMapService.mapCostCenters(response.data));
    return promise;
  }

  public getAccrualPolicies(employeeId?: number, orgLevelId?: number): Promise<AccrualPolicy[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.accrualpolicy}`;

    let promise: Promise<AccrualPolicy[]> = this.apiUtilService
      .cachedRequest<IAccrualPolicy[], Meta>(this.urlParamsService.createGetRequest(url, { empId: employeeId, orgLevelId }), CacheType.longTerm)
      .then((response: ResponseBody<IAccrualPolicy[], Meta>) => this.lookupMapService.mapAccrualPolicies(response.data));
    return promise;
  }

  public getExemptStatuses(): Promise<ExemptStatus[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.exempt}`;
    /*
    let promise: Promise<ExemptStatus[]> = Promise.resolve(
      this.lookupMapService.mapExemptStatuses([{ name: 'ExemptStatus1' }, { name: 'ExemptStatus2' }])
    );*/

    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<ExemptStatus[]> = this.apiUtilService
      .cachedRequest<IExemptStatus[], Meta>(request, CacheType.longTerm)
      .then((response: ResponseBody<IExemptStatus[], Meta>) => this.lookupMapService.mapExemptStatuses(response.data));
    return promise;
  }

  public getPayTypes(): Promise<PayType[]> {
    let promise: Promise<PayType[]> = Promise.resolve(
      this.lookupMapService.mapPayTypes([{ name: 'Hourly' }, { name: 'Salaried' }])
    );
    return promise;
  }

  public getPayCyclePeriods(): Promise<PayCyclePeriod[]> {
    let promise: Promise<PayCyclePeriod[]> = Promise.resolve(
      this.lookupMapService.mapPayCyclePeriods([
        { name: 'weekly', description: 'Weekly' },
        { name: 'bweekly', description: 'Bi-Weekly' },
        { name: 'monthly', description: 'Monthly' },
        { name: 'smonthly', description: 'Semi-Monthly' },
      ])
    );
    return promise;
  }

  public async getOrganizationsAsync(employeeId?: number, orgLevelId?: number, isActive?: boolean): Promise<Organization[]> {
    return await this.getOrganizations(employeeId, orgLevelId, isActive);
  }

  public getOrganizations(employeeId?: number, orgLevelId?: number, isActive?: boolean): Promise<Organization[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.organization}`;

    let params: StringMap<any> = {};
    if (employeeId) {
      params['empId'] = employeeId.toString();
    }

    if (orgLevelId) {
      params['orgLevelId'] = orgLevelId.toString();
    }

    if (_.isBoolean(isActive)) {
      params['isActive'] = String(isActive);
    }

    let promise: Promise<Organization[]> = this.apiUtilService
      .cachedRequest<IOrganization[], Meta>(this.urlParamsService.createGetRequest(url, params), CacheType.longTerm)
      .then((response: ResponseBody<IOrganization[], Meta>) => this.lookupMapService.mapOrganizations(response.data));
    return promise;
  }

  public getUserAccesibleOrganizations(orgLevelId?: number, crossCorpActionCheck?: boolean): Promise<Organization[]> {
    let params: StringMap<any> = {};
    let url: string;
    if(crossCorpActionCheck) {
      url = `${this.getApiRoot()}/${organizationConfig.lookup.userAccesibleOrganizationsCrossCorpActionCheck}`;
    } else {
      url = `${this.getApiRoot()}/${organizationConfig.lookup.userAccesibleOrganizations}`;
    }

    if (orgLevelId) {
      params['orgLevelId'] = orgLevelId.toString();
    }
    let promise: Promise<Organization[]> = this.apiUtilService
      .request<IOrganization[], Meta>(this.urlParamsService.createGetRequest(url, params))
      .then((response: ResponseBody<IOrganization[], Meta>) => this.lookupMapService.mapOrganizations(response.data));
    return promise;
  }

  public getUserAccessibleDepartments(orgLevelId?: number): Promise<Department[]> {
    let params: StringMap<any> = {};
    if (orgLevelId) {
      params['orgLevelId'] = orgLevelId.toString();
    }
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.userAccessibleDepartments}`;

    let promise: Promise<Organization[]> = this.apiUtilService
      .request<IDepartment[], Meta>(this.urlParamsService.createGetRequest(url, params))
      .then((response: ResponseBody<IDepartment[], Meta>) => this.lookupMapService.mapDepartments(response.data));
    return promise;
  }

  public getCompanies(): Promise<any[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.companies}`;

    let promise: Promise<Company[]> = this.apiUtilService
      .cachedRequest<IDepartment[], Meta>(this.urlParamsService.createGetRequest(url), CacheType.longTerm)
      .then((response: ResponseBody<ICompany[], Meta>) => this.lookupMapService.mapCompanies(response.data));
    return promise;
  }

  public getDepartments(employeeId?: number, orgLevelId?: number, isActive?: boolean, updateCacheForced: boolean = false): Promise<Department[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.department}`;

    let params: StringMap<any> = {};
    if (employeeId) {
      params['empId'] = employeeId.toString();
    }

    if (orgLevelId) {
      params['orgLevelId'] = orgLevelId.toString();
    }

    if (_.isBoolean(isActive)) {
      params['isActive'] = String(isActive);
    }
    console.log('get departments: ');

    let promise: Promise<Department[]> = this.apiUtilService
      .cachedRequest<IDepartment[], Meta>(this.urlParamsService.createGetRequest(url, params), CacheType.longTerm, updateCacheForced)
      .then((response: ResponseBody<IDepartment[], Meta>) => this.lookupMapService.mapDepartments(response.data));
    return promise;
  }

  public getAttendancePointsDefinitions(orgLevelId?: number, employeeId?: number): Promise<AttendancePointsDefinition[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.attendancePointsDefinitions}`;
    let params: StringMap<any> = {};
    if (employeeId) {
      params['empId'] = employeeId.toString();
    }
    if (orgLevelId) {
      params['orgLevelId'] = orgLevelId.toString();
    }
    let promise: Promise<AttendancePointsDefinition[]> = this.apiUtilService
      .cachedRequest<IAttendancePointsDefinition[], Meta>(this.urlParamsService.createGetRequest(url, params), CacheType.longTerm)
      .then((response: ResponseBody<IAttendancePointsDefinition[], Meta>) => this.lookupMapService.mapAttendancePointsDefinitions(response.data));
    return promise;
  }

  public getGenders(): Promise<Gender[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.gender}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<Gender[]> = this.apiUtilService
      .cachedRequest<IGender[], Meta>(request, CacheType.longTerm)
      .then((response: ResponseBody<IGender[], Meta>) => this.lookupMapService.mapGenders(response.data));
    return promise;
  }

  public getMaritalStatuses(): Promise<MaritalStatus[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.maritalStatus}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<MaritalStatus[]> = this.apiUtilService
      .cachedRequest<IMaritalStatus[], Meta>(request, CacheType.longTerm)
      .then((response: ResponseBody<IMaritalStatus[], Meta>) => this.lookupMapService.mapMaritalStatuses(response.data));
    return promise;
  }

  public getRaces(): Promise<Race[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.race}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<Race[]> = this.apiUtilService
      .cachedRequest<IRace[], Meta>(request, CacheType.longTerm)
      .then((response: ResponseBody<IRace[], Meta>) => this.lookupMapService.mapRaces(response.data));
    return promise;
  }

  public getLocationUnits(employeeId?: number, orgLevelId?: number): Promise<LocationUnit[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.unit}`;

    let promise: Promise<LocationUnit[]> = this.apiUtilService
      .cachedRequest<ILocationUnit[], Meta>(this.urlParamsService.createGetRequest(url, { empId: employeeId, orgLevelId }), CacheType.longTerm)
      .then((response: ResponseBody<ILocationUnit[], Meta>) => this.lookupMapService.mapLocationUnits(response.data));
    return promise;
  }
  public getShiftDefinitions(employeeId?: number, orgLevelId?: number): Promise<ShiftDefinition[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.shift}`;

    let promise: Promise<ShiftDefinition[]> = this.apiUtilService
      .cachedRequest<IShiftDefinition[], Meta>(this.urlParamsService.createGetRequest(url, { empId: employeeId, orgLevelId }), CacheType.longTerm)
      .then((response: ResponseBody<IShiftDefinition[], Meta>) => this.lookupMapService.mapShiftDefinitions(response.data));
    return promise;
  }
  public getConstraintDefinitions(employeeId?: number, orgLevelId?: number): Promise<ConstraintDefinition[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.constraint}`;

    let promise: Promise<ConstraintDefinition[]> = this.apiUtilService
      .cachedRequest<IConstraintDefinition[], Meta>(this.urlParamsService.createGetRequest(url, { empId: employeeId, orgLevelId }), CacheType.longTerm)
      .then((response: ResponseBody<IConstraintDefinition[], Meta>) => this.lookupMapService.mapConstraintDefinitions(response.data));
    return promise;
  }

  public getScheduleAbsences(orgLevelId: number): Promise<ScheduleAbsence[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.scheduleAbsences}`;

    let promise: Promise<ScheduleAbsence[]> = this.apiUtilService
      .cachedRequest<IScheduleAbsence[], Meta>(this.urlParamsService.createGetRequest(url, { orgLevelId }), CacheType.longTerm)
      .then((response: ResponseBody<IScheduleAbsence[], Meta>) => this.lookupMapService.mapScheduleAbsences(response.data));
    return promise;
  }

  public getSearchCategories(): Promise<SearchCategory[]> {

    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.searchCategories}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<SearchCategory[]> = this.apiUtilService
      .cachedRequest<ISearchCategory[], Meta>(request, CacheType.longTerm)
      .then((response: ResponseBody<any[], Meta>) => (response.data = this.lookupMapService.mapSearchCategories(response.data)));

    return promise;
  }

  public getEmployeeTerminationReasons(): Promise<EmployeeTerminationReason[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.employeeTerminationReasons}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<EmployeeTerminationReason[]> = this.apiUtilService
      .cachedRequest<IEmployeeTerminationReason[], Meta>(request, CacheType.longTerm)
      .then((response: ResponseBody<IEmployeeTerminationReason[], Meta>) => this.lookupMapService.mapEmployeeTerminationReasons(response.data));
    return promise;
  }

  public getBudgetDefinitions(orgLevelId: number, updateCacheForced: boolean = false): Promise<BudgetDefinition[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.budgets}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: this.urlParamsService.convertToParams({ orgLevelId: orgLevelId })
    });
    let promise: Promise<BudgetDefinition[]> = this.apiUtilService
      .cachedRequest<IBudgetDefinition[], Meta>(request, CacheType.longTerm, updateCacheForced)
      .then((response: ResponseBody<IBudgetDefinition[], Meta>) => this.lookupMapService.mapBudgetDefinitions(response.data));
    return promise;
  }

  public getPbjPositions(): Promise<PbjPosition[]> {

    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.pbjPositions}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<PbjPosition[]> = this.apiUtilService
      .cachedRequest<IPbjPosition[], Meta>(request, CacheType.longTerm)
      .then((response: ResponseBody<IPbjPosition[], Meta>) => this.lookupMapService.mapPbjPositions(response.data));
    return promise;
  }

  public getAvailabilityRanges(employeeId?: number, updateCacheForced: boolean = false): Promise<AvailabilityDateRange[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.availabilityDateRanges}`;

    let promise: Promise<AvailabilityDateRange[]> = this.apiUtilService
      .cachedRequest<IAvailabilityDateRange[], Meta>(this.urlParamsService.createGetRequest(url, { employeeId: employeeId }), CacheType.longTerm, updateCacheForced)
      .then((response: ResponseBody<IAvailabilityDateRange[], Meta>) => this.lookupMapService.mapAvailabilityDateRanges(response.data));
    return promise;
  }

  public getTimeclockDefinitions(orgLevelId: number, employeeId?: number, updateCacheForced: boolean = false): Promise<TimeclockDefinition[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.timeClocks}`;

    let params: any = {};

    if (orgLevelId) {
      params.orgLevelId = orgLevelId;
    }

    if (employeeId) {
      params.empId = employeeId;
    }

    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: this.urlParamsService.convertToParams(params)
    });
    let promise: Promise<TimeclockDefinition[]> = this.apiUtilService
      .cachedRequest<ITimeclockDefinition[], Meta>(request, CacheType.longTerm, updateCacheForced)
      .then((response: ResponseBody<ITimeclockDefinition[], Meta>) => this.lookupMapService.mapTimeclockDefinitions(response.data));
    return promise;
  }

  public getTimeclockRestrictionDefinitions(orgLevelId: number): Promise<TimeclockRestrictionDefinition[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.timeclockRestrictions}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: this.urlParamsService.convertToParams({ orgLevelId: orgLevelId })
    });
    let promise: Promise<TimeclockRestrictionDefinition[]> = this.apiUtilService
      .cachedRequest<ITimeclockRestrictionDefinition[], Meta>(request, CacheType.longTerm)
      .then((response: ResponseBody<ITimeclockRestrictionDefinition[], Meta>) => this.lookupMapService.mapTimeclockRestrictionDefinitions(response.data));
    return promise;
  }

  public getTimeclockModelDefinitions(orgLevelId: number): Promise<TimeclockModelDefinition[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.timeclockModels}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: this.urlParamsService.convertToParams({ orgLevelId: orgLevelId })
    });

    let promise: Promise<TimeclockModelDefinition[]> = this.apiUtilService
      .cachedRequest<ITimeclockModelDefinition[], Meta>(request, CacheType.longTerm)
      .then((response: ResponseBody<ITimeclockModelDefinition[], Meta>) => this.lookupMapService.mapTimeclockModelDefinitions(response.data));
    return promise;
  }

  public getTimezoneDefinitions(): Promise<TimezoneDefinition[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.timezones}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<TimezoneDefinition[]> = this.apiUtilService
      .cachedRequest<ITimezoneDefinition[], Meta>(request, CacheType.longTerm)
      .then((response: ResponseBody<ITimezoneDefinition[], Meta>) => this.lookupMapService.mapTimezoneDefinitions(response.data));
    return promise;
  }

  public getPayCyles(orgLevelId: number, employeeId?: number, date?: Date, updateCacheForced: boolean = false): Promise<PayCycle[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.paycycles}`;
    if (employeeId > 0)
      orgLevelId = null;
    const request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, { orgLevelId, employeeId, date });

    let promise: Promise<PayCycle[]> = this.apiUtilService
      .cachedRequest<IPayCycle[], Meta>(request, CacheType.longTerm, updateCacheForced)
      .then((response: ResponseBody<IPayCycle[], Meta>) => this.lookupMapService.mapPayCycles(response.data));
    return promise;
  }

  public getPayRules(empId: number): Promise<PayRule[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.payrules}`;
    let promise: Promise<PayRule[]> = this.apiUtilService
      .cachedRequest<IPayRule[], Meta>(this.urlParamsService.createGetRequest(url, { employeeId: empId }), CacheType.longTerm)
      .then((response: ResponseBody<IPayRule[], Meta>) => this.lookupMapService.mapPayRules(response.data));
    return promise;
  }

  public getPunchTypes(): Promise<PunchType[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.punchtypes}`;
    let promise: Promise<PunchType[]> = this.apiUtilService
      .cachedRequest<IPunchType[], Meta>(this.urlParamsService.createGetRequest(url, {}), CacheType.longTerm)
      .then((response: ResponseBody<IPunchType[], Meta>) => this.lookupMapService.mapPunchTypes(response.data));
    return promise;
  }

  public clearEmployeeCacheData() {
    this.cachedEmployeeData = [];
  }

  public clearOldEmployeeCacheData() {
    const currentTime = new Date();
    this.cachedEmployeeData = this.cachedEmployeeData.filter(item => {
      const seconds = this.dateTimeService.GetDiffSeconds(new Date(item.timestamp), currentTime);
      if (seconds < appConfig.cacheInvalidateEmployeeDropDownTime) {
        return item;
      }
    });
  }

  public getEmployees(orgLevelId: number, isActive?: boolean): Promise<any[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.employees}`;
    let params: StringMap<any> = {};

    if (orgLevelId) {
      params['orgLevelId'] = orgLevelId.toString();
    }

    if (_.isBoolean(isActive)) {
      params['isActive'] = String(isActive);
    }

    let promise: Promise<any[]> = null;

    // Check if data already fetched for the selected cached Id within 60 seconds.
    // If yes than return the cached data
    // Else fetch data from API, store it in cache and return it.
    let cacheId: string = "" + orgLevelId;
    if (isActive) {
      cacheId = cacheId + '_' + isActive;
    }
    this.clearOldEmployeeCacheData();
    const cacheItemIndex = this.cachedEmployeeData.findIndex(item => item.cacheId === cacheId);
    if (cacheItemIndex > -1) {
      const seconds = this.dateTimeService.GetDiffSeconds(new Date(this.cachedEmployeeData[cacheItemIndex].timestamp), new Date());
      if (seconds < appConfig.cacheInvalidateEmployeeDropDownTime) {
        // In here means data present in cache.
        promise = new Promise((resolve) => {
          resolve(this.cachedEmployeeData[cacheItemIndex].data);
        });
    return promise;
   }
    }

    promise = this.apiUtilService.request<any[], Meta>(this.urlParamsService.createGetRequest(url, params))
      .then((response: ResponseBody<any[], Meta>) => {
        this.cachedEmployeeData.push({
          cacheId,
          data: this.lookupMapService.mapEmployees(response.data),
          timestamp: new Date().getTime()
        });
        return this.lookupMapService.mapEmployees(response.data)
      });
    return promise;
  }

  public getSupervisors(orgLevelId: number,employeeId:number, isActive?: boolean): Promise<any[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.supervisors}`;
    let params: StringMap<any> = {};

    if (orgLevelId) {
      params['orgLevelId'] = orgLevelId.toString();
    }
    if (orgLevelId) {
      params['employeeId'] = employeeId.toString();
    }
    if (_.isBoolean(isActive)) {
      params['isActive'] = true;
    }
    let promise: Promise<any[]> = null;
    promise = this.apiUtilService.request<any[], Meta>(this.urlParamsService.createGetRequest(url, params))
      .then((response: ResponseBody<any[], Meta>) => {
        return this.lookupMapService.mapSupervisors(response.data)
      });
    return promise;
  }


  public getEmployeesBySecondaryPositions(orgLevelId: number, isActive?: boolean): Promise<any[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.employeesBySecondaryPositions}`;
    let params: StringMap<any> = {};

    if (orgLevelId) {
      params['orgLevelId'] = orgLevelId.toString();
    }

    if (_.isBoolean(isActive)) {
      params['isActive'] = String(isActive);
    }


    let promise: Promise<any[]> = this.apiUtilService.request<any[], Meta>(this.urlParamsService.createGetRequest(url, params))
      .then((response: ResponseBody<any[], Meta>) => this.lookupMapService.mapEmployees(response.data));
    return promise;
  }

  public getEmployeesByActiveDateRange(orgLevelId: number, startDate: Date, endDate: Date): Promise<any[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.employeesByActiveDateRange}`;

    let promise: Promise<any[]> = this.apiUtilService.request<any[], Meta>(
      this.urlParamsService.createGetRequest(url, {
        orgLevelId,
        startDate: dateTimeUtils.convertToDtoString(startDate),
        endDate: dateTimeUtils.convertToDtoString(endDate)
      })
    )
      .then((response: ResponseBody<any[], Meta>) => this.lookupMapService.mapEmployeesDefinitions(response.data));
    return promise;
  }

  public manualEntryActiveEmployees(orgLevelId: number, startDate: Date, endDate: Date): Promise<any[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.manualEntryActiveEmployees}`;

    let promise: Promise<any[]> = this.apiUtilService.request<any[], Meta>(
      this.urlParamsService.createGetRequest(url, {
        orgLevelId,
        startDate: dateTimeUtils.convertToDtoString(startDate),
        endDate: dateTimeUtils.convertToDtoString(endDate)
      })
    )
      .then((response: ResponseBody<any[], Meta>) => this.lookupMapService.mapEmployeesDefinitions(response.data));
    return promise;
  }

  public getAgencies(updateCacheForced: boolean = false): Promise<AgencyDefinition[]> {

    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.agencyDefinitions}`;

    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);

    let promise: Promise<AgencyDefinition[]> = this.apiUtilService
      .cachedRequest<IAgencyDefinition[], Meta>(request, CacheType.longTerm, updateCacheForced)
      .then((response: ResponseBody<IAgencyDefinition[], Meta>) => this.lookupMapService.mapAgencyDefinitions(response.data));
    return promise;
  }

  public getAgenciesReports(updateCacheForced: boolean = false): Promise<AgencyDefinition[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.agencyDefinitionsReports}`;

    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);

    let promise: Promise<AgencyDefinition[]> = this.apiUtilService
      .cachedRequest<IAgencyDefinition[], Meta>(request, CacheType.longTerm, updateCacheForced)
      .then((response: ResponseBody<IAgencyDefinition[], Meta>) => this.lookupMapService.mapAgencyDefinitions(response.data));
    return promise;
  }

  public getTimecardPredefinedComment(): Promise<TimecardPredefinedComment[]> {

    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.timecardPredefinedComments}`;

    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);
    /*
        let promise: Promise<TimecardPredefinedComment[]> = Promise.resolve(
           this.lookupMapService.mapTimecardPredefinedComments([
            { id: 0, description: 'First Comment1', order: 1, type: '' },
            { id: 0, description: 'Second Comment2', order: 2, type: '' },
            { id: 0, description: 'AA Comment3', order: 3, type: '' },
            { id: 0, description: 'For Comment4', order: 4, type: '' }
          ]));*/
    let promise: Promise<TimecardPredefinedComment[]> = this.apiUtilService
      .cachedRequest<ITimecardPredefinedComment[], Meta>(request, CacheType.longTerm)
      .then((response: ResponseBody<ITimecardPredefinedComment[], Meta>) => this.lookupMapService.mapTimecardPredefinedComments(response.data));
    return promise;
  }

  public getPtoPlannerAbsenceCodes(orgLevelId: number, updateCacheForced: boolean = false): Promise<ScheduleAbsence[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.scheduleAbsences}`;

    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, { orgLevelId });

    let promise: Promise<ScheduleAbsence[]> = this.apiUtilService
      .cachedRequest<IScheduleAbsence[], Meta>(request, CacheType.longTerm, updateCacheForced)
      .then((response: ResponseBody<IScheduleAbsence[], Meta>): ScheduleAbsence[] => {
        Assert.isNotNull(response);
        Assert.isNotNull(response.data);

        let filterCodes: IScheduleAbsence[] = _.filter(response.data, (item: IScheduleAbsence): boolean => item.ptoPlannerIndicator);
        return this.lookupMapService.mapScheduleAbsences(filterCodes);
      });
    return promise;
  }

  public getBenefitClassesDefinitions(employeeId?: number, orgLevelId?: number, updateCacheForced: boolean = false): Promise<BenefitClassDefinition[]> {

    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.benefitClasses}`;
    let promise: Promise<BenefitClassDefinition[]> = this.apiUtilService
      .cachedRequest<IBenefitClassDefinition[], Meta>(this.urlParamsService.createGetRequest(url, { employeeId: employeeId, orgLevelId: orgLevelId }), CacheType.longTerm, updateCacheForced)
      .then((response: ResponseBody<IBenefitClassDefinition[], Meta>) => this.lookupMapService.mapBenefitClassDefinitions(response.data));
    return promise;
  }

  public getVeteranStatuses(employeeId?: number, orgLevelId?: number, updateCacheForced: boolean = false): Promise<VeteranStatus[]> {

    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.veteranStatuses}`;
    let promise: Promise<VeteranStatus[]> = this.apiUtilService
      .cachedRequest<IVeteranStatus[], Meta>(this.urlParamsService.createGetRequest(url, { employeeId: employeeId, orgLevelId: orgLevelId }), CacheType.longTerm, updateCacheForced)
      .then((response: ResponseBody<IVeteranStatus[], Meta>) => this.lookupMapService.mapVeteranStatuses(response.data));
    return promise;
  }

  public getPerformanceReviewTemplates(orgLevelId: number, updateCacheForced: boolean = false): Promise<PmTemplate[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.performanceReviewTemplates}`;
    const request = this.urlParamsService.createGetRequest(url, { orgLevelId: orgLevelId });
    return this.apiUtilService
      .cachedRequest<IPmTemplate[], Meta>(request, CacheType.longTerm, updateCacheForced)
      .then((response: ResponseBody<IPmTemplate[], Meta>) => {
        return this.lookupMapService.mapPerformanceReviewTemplates(response.data);
      });
  }

  public getPerformanceReviewCodeTypes(updateCacheForced: boolean = false): Promise<PerformanceReviewCodeType[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.performanceReviewCodeTypes}`;
    const request = this.urlParamsService.createGetRequest(url);
    return this.apiUtilService
      .cachedRequest<IPerformanceReviewCodeType[], Meta>(request, CacheType.longTerm, updateCacheForced)
      .then((response: ResponseBody<IPerformanceReviewCodeType[], Meta>) => {
        return this.lookupMapService.mapPerformanceReviewCodeTypes(response.data);
      });
  }

  public getPerformanceReviewCodes(orgLevelId?: number, updateCacheForced: boolean = false): Promise<PerformanceReviewCode[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.performanceReviewCodes}`;
    const request = this.urlParamsService.createGetRequest(url, { orgLevelId: orgLevelId });
    return this.apiUtilService
      .cachedRequest<IPerformanceReviewCode[], Meta>(request, CacheType.longTerm, updateCacheForced)
      .then((response: ResponseBody<IPerformanceReviewCode[], Meta>) => this.lookupMapService.mapPerformanceReviewCodes(response.data));
  }

  public getEssOptions(): Promise<EssEntity[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.essOptions}`;
    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);
    let promise: Promise<EssEntity[]> = this.apiUtilService
      .cachedRequest<IEssEntity[], Meta>(request, CacheType.longTerm)
      .then((response: ResponseBody<IEssEntity[], Meta>) => this.lookupMapService.mapEssEntities(response.data));
    return promise;
  }


  public getPositionGroups(orgLevelId: number, updateCacheForced: boolean = false): Promise<PositionGroup[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.positionGroups}`;
    const request = this.urlParamsService.createGetRequest(url, { orgLevelId });

    return this.apiUtilService
      .cachedRequest<IPositionGroup[], Meta>(request, CacheType.longTerm, updateCacheForced)
      .then((response: ResponseBody<IPositionGroup[], Meta>) => this.lookupMapService.mapPositionGroups(response.data));
  }

  public getBenefitGroups(empId: number, isActive: boolean, updateCacheForced: boolean = false) {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.benefitGroups}`;
    const request = this.urlParamsService.createGetRequest(url, { employeeId: empId, isActive: isActive });

    return this.apiUtilService
      .cachedRequest<IBenefitGroup[], Meta>(request, CacheType.longTerm, updateCacheForced)
      .then((response: ResponseBody<BenefitGroup[], Meta>) => this.lookupMapService.mapBenefitGroups(response.data));
  }

  public getObjAcc(orgLevelId?: number, updateCacheForced: boolean = false): Promise<ObjAcc[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.getObjAcc}`;
    const request = this.urlParamsService.createGetRequest(url, { orgLevelId: orgLevelId });
    return this.apiUtilService
      .cachedRequest<IObjAcc[], Meta>(request, CacheType.longTerm, updateCacheForced)
      .then((response: ResponseBody<IObjAcc[], Meta>) => this.lookupMapService.mapObjAccs(response.data));
  }

  public getAcaCategory():Promise<AcaCategory[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.acaCategory}`;
    const request = this.urlParamsService.createGetRequest(url);

    return this.apiUtilService
      .cachedRequest<IAcaCategory[], Meta>(request, CacheType.longTerm)
      .then((response: ResponseBody<AcaCategory[], Meta>) => this.lookupMapService.mapAcaCategoryDefinitions(response.data));
  }
  public getAcaEmployers(activeOnly: boolean = true): Promise<AcaEmployers[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.acaEmployers}`;
    const request = this.urlParamsService.createGetRequest(url, { activeOnly });

    return this.apiUtilService
      .cachedRequest<IAcaEmployers[], Meta>(request, CacheType.longTerm)
      .then((response: ResponseBody<AcaEmployers[], Meta>) => this.lookupMapService.mapAcaEmployers(response.data));
  }
  public getAcaSafeHarbors():Promise<AcaSafeHarbor[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.acaSafeHarbor}?orgLevelId=1`;
    const request = this.urlParamsService.createGetRequest(url);

    return this.apiUtilService
      .cachedRequest<IAcaSafeHarbor[], Meta>(request, CacheType.longTerm)
      .then((response: ResponseBody<AcaSafeHarbor[], Meta>) => this.lookupMapService.mapAcaSafeHarbors(response.data));
  }
  public getAcaReasons():Promise<AcaReason[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.acaReason}?orgLevelId=1`;
    const request = this.urlParamsService.createGetRequest(url);

    return this.apiUtilService
      .cachedRequest<IAcaReason[], Meta>(request, CacheType.longTerm)
      .then((response: ResponseBody<AcaReason[], Meta>) => this.lookupMapService.mapAcaReasons(response.data));
  }
  public getAcaOfferOfCoverage(year:number):Promise<AcaOfferOfCoverage[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.acaOfferOfCoverage}?orgLevelId=1&year=${year}`;
    const request = this.urlParamsService.createGetRequest(url);

    return this.apiUtilService
      .cachedRequest<IAcaOfferOfCoverage[], Meta>(request, CacheType.longTerm)
      .then((response: ResponseBody<AcaOfferOfCoverage[], Meta>) => this.lookupMapService.mapAcaOfferOfCoverages(response.data));
  }

  public getPayUnits(orgLevelId: number, isActive: boolean, updateCacheForced: boolean = false):Promise<PayUnitDefinition[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.payunits}`;
    const request = this.urlParamsService.createGetRequest(url, { orgLevelId, isActive });

    return this.apiUtilService
      .cachedRequest<IPayUnitDefinition[], Meta>(request, CacheType.longTerm)
      .then((response: ResponseBody<IPayUnitDefinition[], Meta>) => this.lookupMapService.mapToPayUnitDefinitions(response.data));
  }

  public getIncreaseReasons(updateCacheForced: boolean = false):Promise<IncreaseReason[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.increaseReason}`;
    const request = this.urlParamsService.createGetRequest(url);
    return this.apiUtilService
      .cachedRequest<IIncreaseReason[], Meta>(request, CacheType.longTerm, updateCacheForced)
      .then((response: ResponseBody<IIncreaseReason[], Meta>) => this.lookupMapService.mapToIncreaseReasons(response.data));
  }

  public getTimeclockSlateProfileDefinitions(): Promise<TimeclockSlateProfileDefinition[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.timeclockSlateProfile}`;
    const request = this.urlParamsService.createGetRequest(url);
    let promise: Promise<TimeclockSlateProfileDefinition[]> = this.apiUtilService
      .cachedRequest<ITimeclockSlateProfileDefinition[], Meta>(request, CacheType.longTerm)
      .then((response: ResponseBody<ITimeclockSlateProfileDefinition[], Meta>) => this.lookupMapService.mapTimeclockSlateProfileDefinitions(response.data));
    return promise;
  }

  public getPunchProfile(): Promise<IPunchProfile[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.punchProfiles}`;
    const request = this.urlParamsService.createGetRequest(url);
    let promise: Promise<IPunchProfile[]> = this.apiUtilService
      .cachedRequest<IPunchProfile[], Meta>(request, CacheType.longTerm)
      .then((response: ResponseBody<IPunchProfile[], Meta>) => response.data);
    return promise;
  }

  public getPunchAttestatiionGroup(): Promise<IPunchAttestationGroup[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.punchAttestationGroups}`;
    const request = this.urlParamsService.createGetRequest(url);
    let promise: Promise<IPunchAttestationGroup[]> = this.apiUtilService
      .cachedRequest<IPunchAttestationGroup[], Meta>(request, CacheType.longTerm)
      .then((response: ResponseBody<IPunchAttestationGroup[], Meta>) => response.data);
    return promise;
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}/${organizationConfig.lookup.root}`;
  }

  public getAttestationTypes(updateCacheForced: boolean = false): Promise<AttestationType[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.attestationType}`;
    const request = this.urlParamsService.createGetRequest(url);
    return this.apiUtilService
      .cachedRequest<IAttestationType[], Meta>(request, CacheType.longTerm, updateCacheForced)
      .then((response: ResponseBody<IAttestationType[], Meta>) => this.lookupMapService.mapToAttestationtypes(response.data));
  }

  public getRestrictedAnswers(updateCacheForced: boolean = false): Promise<RestrictedAnswer[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.restrictedAnswer}`;
    const request = this.urlParamsService.createGetRequest(url);
    return this.apiUtilService
      .cachedRequest<IRestrictedAnswer[], Meta>(request, CacheType.longTerm, updateCacheForced)
      .then((response: ResponseBody<IRestrictedAnswer[], Meta>) => this.lookupMapService.mapToRestrictedAnswers(response.data));
  }

  public getRestrictedNotify(updateCacheForced:boolean = false): Promise<NotifyData[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.restrictedNotify}`;
    const request = this.urlParamsService.createGetRequest(url);
    return this.apiUtilService
      .cachedRequest<INotifyData[], Meta>(request, CacheType.longTerm, updateCacheForced)
      .then((response: ResponseBody<INotifyData[], Meta>) => this.lookupMapService.mapToRestrictedNotifys(response.data));
 
  }

  public getOrganizationTimezones(updateCacheForced:boolean = false): Promise<OrganizationTimezone[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.lookup.organizationTimezones}`;
    const request = this.urlParamsService.createGetRequest(url);
    return this.apiUtilService
      .cachedRequest<IOrganizationTimezone[], Meta>(request, CacheType.longTerm, updateCacheForced)
      .then((response: ResponseBody<IOrganizationTimezone[], Meta>) => this.lookupMapService.mapToOrganizationTimezones(response.data));
 
  }
}
