import * as _ from 'lodash';
var Details = /** @class */ (function () {
    function Details() {
    }
    Object.defineProperty(Details.prototype, "totalIdealHours", {
        get: function () {
            var totalIdealHours = 0;
            _.each(this.groups, function (group) {
                totalIdealHours += group.groupedIdealScheduleHours;
            });
            return totalIdealHours;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Details.prototype, "scheduledHours", {
        get: function () {
            var scheduledHours = 0;
            _.each(this.groups, function (group) {
                scheduledHours += group.groupedScheduledHours;
            });
            return scheduledHours;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Details.prototype, "idealSlots", {
        get: function () {
            var idealSlot = 0;
            _.each(this.groups, function (group) {
                idealSlot += group.parLevel;
            });
            return idealSlot;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Details.prototype, "scheduledSlots", {
        get: function () {
            var scheduledSlot = 0;
            _.each(this.groups, function (group) {
                scheduledSlot += group.groupedScheduledCount;
            });
            return scheduledSlot;
        },
        enumerable: true,
        configurable: true
    });
    return Details;
}());
export { Details };
