import { Injectable } from '@angular/core';
import { RuleFormulaDto } from '../../models/pay-code-details/dtos/rule-formula.dto';
import { RuleFormulaModel } from '../../models/pay-code-details/models/rule-formula.model';

@Injectable()
export class RuleFormulaMapService {
    public mapToModel(dto: RuleFormulaDto): RuleFormulaModel {
        let model: RuleFormulaModel = new RuleFormulaModel();
        Object.assign(model, dto);
        model.ruleId = dto.ruleId;
        model.variableId1 = dto.variableId1;
        model.operator = dto.operator;
        model.variableId2 = dto.variableId2;

        return model;
    }

    public mapToDto(model: RuleFormulaModel): RuleFormulaDto {
        return {
            id: model.id,
            ruleId: model.ruleId,
            variableId1: model.variableId1,
            operator: model.operator,
            variableId2: model.variableId2
        };
    }

    public mapToModels(dtos: RuleFormulaDto[]): RuleFormulaModel[] {
        return dtos.map(dto => this.mapToModel(dto));
    }

    public mapToDtos(models: RuleFormulaModel[]): RuleFormulaDto[] {
        return models.map(model => this.mapToDto(model));
    }
}