import * as tslib_1 from "tslib";
import { NotificationsService } from '../../../../../app/core/components';
import { BusyService } from '../../../../common';
import { WfmSyncService } from '../../../../configuration/services/wfm/wfm-sync.service';
import { BaseSyncComponent } from '../wfm-sync-base.component';
import { dataCheck } from './../../../models/wfm-sync';
var WfmNgpBenefitdeductionSyncComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WfmNgpBenefitdeductionSyncComponent, _super);
    function WfmNgpBenefitdeductionSyncComponent(wfmSyncService, notificationService, busyService) {
        var _this = _super.call(this, notificationService, busyService) || this;
        _this.wfmSyncService = wfmSyncService;
        return _this;
    }
    WfmNgpBenefitdeductionSyncComponent.prototype.syncDataCore = function (selectedItems) {
        return this.busyService.busy(this.wfmSyncService.benefitdeductionSyncOrg(selectedItems, this.syncType));
    };
    WfmNgpBenefitdeductionSyncComponent.prototype.getSyncItemKey = function (item) {
        return '' + item.enrollmentId;
    };
    WfmNgpBenefitdeductionSyncComponent.prototype.fetchData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.busyService.busy(this.wfmSyncService.GetBenefitDeductionChangeLogs(this.syncType, this.isActiveOnly))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.processData(response)];
                }
            });
        });
    };
    WfmNgpBenefitdeductionSyncComponent.prototype.processData = function (data) {
        return dataCheck(data);
    };
    return WfmNgpBenefitdeductionSyncComponent;
}(BaseSyncComponent));
export { WfmNgpBenefitdeductionSyncComponent };
