import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[slxChangeText]'
})
export class WhiteSpaceTrimDirective {

    constructor(private el: ElementRef) {}

    @HostListener('blur', ['$event'])
    onBlur(event: KeyboardEvent) {
      this.trimText();
    }
  
    private trimText() {
      this.el.nativeElement.value = this.el.nativeElement.value.trim();
    }

}
