<div class="field-container">

  <div *ngIf="editable" class="action-bar">
    <button class="edit-button" kendoGridEditCommand (click)="onEditClick ()"></button>
  </div>
  <div>
    <span class="field-label">Name:</span>
    <span class="value-label">{{item?.pbjOrganization?.name}}</span>
  </div>
  <div>
    <span class="field-label">Address:</span>
    <span class="value-label">{{item?.pbjOrganization?.address}}</span>
  </div>
  <div>
    <span class="field-label">City:</span>
    <span class="value-label">{{item?.pbjOrganization?.city}}</span>
  </div>
  <div>
    <span class="field-label">State:</span>
    <span class="value-label">{{item?.pbjOrganization?.state}}</span>
  </div>
  <div>
    <span class="field-label">Zip code:</span>
    <span class="value-label">{{item?.pbjOrganization?.zip}}</span>
  </div>
  <div>
    <span class="field-label">Phone:</span>
    <span class="value-label">{{item?.pbjOrganization?.phone}}</span>
  </div>
  <div>
    <span class="field-label">Company Code:</span>
    <span class="value-label">{{item?.pbjOrganization?.companyCode}}</span>
  </div>
  <div>
    <span class="field-label">Group:</span>
    <span class="value-label">{{item?.pbjOrganization?.group}}</span>
  </div>
  <div>
    <span class="field-label">Type:</span>
    <span class="value-label">{{item?.pbjOrganization?.type}}</span>
  </div>
  <div>
    <span class="field-label">Other Name:</span>
    <span class="value-label">{{item?.pbjOrganization?.otherName}}</span>
  </div>
  <div>
    <span class="field-label">Telepunch Offset:</span>
    <span class="value-label">{{item?.pbjOrganization?.telepunchOffset}}</span>
  </div>
  <div>
    <span class="field-label">Time Zone:</span>
    <span class="value-label">{{ item?.pbjOrganization?.timezone | timezoneDisplayName:organizationTimezones}}</span>
  </div>
  <div>
    <span class="field-label">Time Zone Offset:</span>
    <span class="value-label">{{item?.pbjOrganization?.timezoneOffset}}</span>
  </div>
</div>
