import * as i0 from "@angular/core";
var PaycodeExceptionSortOverrideMapService = /** @class */ (function () {
    function PaycodeExceptionSortOverrideMapService() {
    }
    PaycodeExceptionSortOverrideMapService.prototype.mapToModel = function (dto) {
        return {
            exceptionId: Number(dto.exceptionId),
            overrideSortOrder: Number(dto.overrideSortOrder)
        };
    };
    PaycodeExceptionSortOverrideMapService.prototype.mapToDto = function (model) {
        return {
            exceptionId: Number(model.exceptionId),
            overrideSortOrder: Number(model.overrideSortOrder)
        };
    };
    PaycodeExceptionSortOverrideMapService.prototype.mapToModelsFromDtos = function (dtos) {
        var _this = this;
        var models = [];
        if (Array.isArray(dtos)) {
            dtos.forEach(function (dto) {
                models.push(_this.mapToModel(dto));
            });
        }
        else if (dtos && typeof dtos === 'object') {
            models.push(this.mapToModel(dtos));
        }
        else {
            console.error('Invalid input: dtos is neither an array nor a valid object', dtos);
        }
        return models;
    };
    PaycodeExceptionSortOverrideMapService.prototype.mapToDtosFromModels = function (models) {
        var _this = this;
        var dtos = [];
        if (Array.isArray(models)) {
            models.forEach(function (model) {
                dtos.push(_this.mapToDto(model));
            });
        }
        else if (models && typeof models === 'object') {
            dtos.push(this.mapToDto(models));
        }
        else {
            console.error('Invalid input: models is neither an array nor a valid object', models);
        }
        return dtos;
    };
    PaycodeExceptionSortOverrideMapService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaycodeExceptionSortOverrideMapService_Factory() { return new PaycodeExceptionSortOverrideMapService(); }, token: PaycodeExceptionSortOverrideMapService, providedIn: "root" });
    return PaycodeExceptionSortOverrideMapService;
}());
export { PaycodeExceptionSortOverrideMapService };
