import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
;
import { BehaviorSubject, Observable } from 'rxjs';
import { PayCodeDetailsManagementService } from '../../services/pay-code-details/pay-code-details-management.service';
import { PayCodeDetailsContainer } from '../../models/pay-code-details/pay-code-details-container';
import { ColorUtil } from '../../../common/utils/index';
import { DatePipe, Location } from '@angular/common';
import { appConfig } from '../../../app.config';
import { StateManagementService, ColumnManagementService } from '../../../common/index';
import { mutableSelect } from '../../../core/decorators/index';
var PayCodeDetailsComponent = /** @class */ (function () {
    function PayCodeDetailsComponent(stateManagement, management, columnManagementService, cdr, datePipe, location) {
        this.stateManagement = stateManagement;
        this.management = management;
        this.columnManagementService = columnManagementService;
        this.cdr = cdr;
        this.datePipe = datePipe;
        this.location = location;
        this.container = new PayCodeDetailsContainer();
        this.allocationTypes = [];
        this.exceptionGroups = [];
        this.exceptionVariables = [];
        this.payDiffTypes = [];
        this.specialCodes = [];
        this.payCodeExceptions = [];
        this.workCodes = [];
        this.interfaceCategories = [];
        this.subscriptions = [];
        this.dataLoaded$ = new BehaviorSubject(false);
        this.columnGroup = 'PayCodeDetails';
    }
    PayCodeDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.state = {
            isLoading: false,
            exceptionsDirty: false,
            additionalRequirementsDirty: false,
            rulesDirty: false
        };
        this.subscriptions.push(this.dataLoaded$.subscribe(function (loaded) {
            if (loaded) {
                _this.sanitizeData();
                _this.populateDropdownData();
                _this.state.isLoading = false;
                _this.cdr.detectChanges();
            }
        }));
        this.subscriptions.push(this.orgLevel$.subscribe(function (orgLevel) {
            _this.orgId = orgLevel.organizationId;
        }));
        this.bindPageLoadData();
        this.management.init();
        this.container = this.management.container;
        this.state.isLoading = true;
        this.loadData();
    };
    PayCodeDetailsComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (sub) { return sub.unsubscribe(); });
    };
    PayCodeDetailsComponent.prototype.loadData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        return [4 /*yield*/, this.fetchAndSetData()];
                    case 1:
                        _a.sent();
                        if (this.container.exceptions.some(function (exception) { return exception.deleteInd; })) {
                            this.location.back();
                            return [2 /*return*/];
                        }
                        this.dataLoaded$.next(true);
                        return [3 /*break*/, 4];
                    case 2:
                        error_1 = _a.sent();
                        console.error('Error loading data:', error_1);
                        return [3 /*break*/, 4];
                    case 3:
                        this.cdr.detectChanges();
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PayCodeDetailsComponent.prototype.fetchAndSetData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.cdr.detectChanges();
                        if (!(this.container && this.container.id)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fetchDataAndPopulate(this.container.id)];
                    case 1:
                        _a = _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        _a = console.error('Container ID is not set.');
                        _b.label = 3;
                    case 3:
                        _a;
                        this.cdr.detectChanges();
                        return [2 /*return*/];
                }
            });
        });
    };
    PayCodeDetailsComponent.prototype.fetchDataAndPopulate = function (containerId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, Promise.all([
                                this.management.fetchPaycodeExceptions(containerId),
                                this.management.fetchAdditionalRequirements(containerId),
                                this.management.fetchRulesByExceptionId(containerId),
                            ])];
                    case 1:
                        _a.sent();
                        this.populateDropdownData();
                        this.setRuleFormulasValueType();
                        this.sanitizeData();
                        this.dataLoaded$.next(true);
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        console.error('Error fetching data:', error_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PayCodeDetailsComponent.prototype.populateDropdownData = function () {
        if (this.container.exceptions && this.container.exceptions.length > 0) {
            var exception = this.container.exceptions[0];
            this.allocationTypes = exception.allocationTypes || [];
            this.exceptionGroups = exception.exceptionGroups || [];
            this.exceptionVariables = exception.exceptionVariables || [];
            this.payDiffTypes = exception.payDiffTypes || [];
            this.specialCodes = exception.specialCodes || [];
            this.payCodeExceptions = exception.payCodeExceptions || [];
            this.workCodes = exception.workCodes || [];
            this.interfaceCategories = exception.interfaceCategories || [];
            exception.exInterfaceCategoryId = this.adjustInterfaceCategoryIdForDisplay(exception.exInterfaceCategoryId);
            this.parseAndFormatAdditionalRequirements();
            this.determineDefaultOption();
        }
    };
    PayCodeDetailsComponent.prototype.sanitizeData = function () {
        // Sanitize and merge defaults for exceptions
        this.container.exceptions = this.container.exceptions.map(this.mergeWithDefaults.bind(this));
        if (this.container.exceptions.length === 0) {
            this.container.exceptions = [this.newPaycodeException()];
        }
        // Sanitize other arrays
        this.container.rules = this.sanitizeArray(this.container.rules);
        this.container.additionalRequirements = this.sanitizeArray(this.container.additionalRequirements);
        if (this.container.additionalRequirements.length === 0) {
            this.container.additionalRequirements = [this.newAdditionalRequirement()];
        }
    };
    PayCodeDetailsComponent.prototype.mergeWithDefaults = function (exception) {
        var defaults = this.newPaycodeException();
        // Merge defaults with exception ensuring undefined values in exception are replaced by defaults
        Object.keys(defaults).forEach(function (key) {
            if (exception[key] === undefined) {
                exception[key] = defaults[key];
            }
        });
        return exception;
    };
    PayCodeDetailsComponent.prototype.sanitizeArray = function (array) {
        return array.map(function (item) {
            Object.keys(item).forEach(function (key) {
                if (item[key] === null || item[key] === undefined) {
                    item[key] = '';
                }
            });
            return item;
        });
    };
    PayCodeDetailsComponent.prototype.formatDate = function (date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    };
    PayCodeDetailsComponent.prototype.determineDefaultOption = function () {
        if (this.container.exceptions[0].exActionStart || this.container.exceptions[0].exActionEnd) {
            this.selectedOption = 'Action';
        }
        else if (this.container.exceptions[0].exActionStartVar || this.container.exceptions[0].exActionInterval) {
            this.selectedOption = 'Interval';
        }
        else if (this.container.exceptions[0].exFormulaStart || this.container.exceptions[0].exFormulaEnd) {
            this.selectedOption = 'Formula';
        }
        else {
            this.selectedOption = 'Action';
        }
        this.updateDisplayedFields();
    };
    PayCodeDetailsComponent.prototype.parseOverride = function (value) {
        if (typeof value === 'string') {
            // Try to match the specific pattern first
            var match = value.match(/OT (\d+)/);
            if (match) {
                return +match[1];
            }
            // Try to convert directly if no match
            var intValue = parseInt(value, 10);
            return isNaN(intValue) ? null : intValue;
        }
        return typeof value === 'number' ? value : null;
    };
    PayCodeDetailsComponent.prototype.parseAndFormatAdditionalRequirements = function () {
        var _this = this;
        this.container.additionalRequirements.forEach(function (req) {
            req.regularOverrideTemp = _this.parseOverride(req.regularOverride);
            req.otOverrideTemp = _this.parseOverride(req.otOverride);
            req.holidayOverrideTemp = _this.parseOverride(req.holidayOverride);
        });
        this.cdr.detectChanges();
    };
    PayCodeDetailsComponent.prototype.updateDisplayedFields = function () {
        this.cdr.detectChanges();
    };
    PayCodeDetailsComponent.prototype.saveChanges = function () {
        var _this = this;
        if (this.isSaveDisabled()) {
            return;
        }
        this.state.isLoading = true;
        var saveTasks = [];
        this.sanitizeData();
        if (this.state.exceptionsDirty) {
            saveTasks.push(this.savePaycodeExceptions());
        }
        if (this.state.additionalRequirementsDirty) {
            saveTasks.push(this.saveAdditionalRequirements());
        }
        if (this.state.rulesDirty) {
            saveTasks.push(this.saveRules());
        }
        Promise.all(saveTasks).then(function () {
            _this.state.isLoading = false;
            _this.state.exceptionsDirty = false;
            _this.state.additionalRequirementsDirty = false;
            _this.state.rulesDirty = false;
            _this.cdr.detectChanges();
        }).catch(function (error) {
            console.error('Error saving changes:', error);
            _this.state.isLoading = false;
            _this.cdr.detectChanges();
        });
    };
    PayCodeDetailsComponent.prototype.savePaycodeExceptions = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var updatedData, savePromises, error_3;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        updatedData = this.container.exceptions.map(function (exception) {
                            return tslib_1.__assign({}, exception);
                        });
                        this.state.isLoading = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        savePromises = updatedData.map(function (dataItem) { return _this.management.savePaycodeException(dataItem); });
                        return [4 /*yield*/, Promise.all(savePromises)];
                    case 2:
                        _a.sent();
                        this.state.exceptionsDirty = false;
                        return [3 /*break*/, 5];
                    case 3:
                        error_3 = _a.sent();
                        console.error('Error saving paycode exceptions:', error_3);
                        return [3 /*break*/, 5];
                    case 4:
                        this.state.isLoading = false;
                        this.cdr.detectChanges();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    PayCodeDetailsComponent.prototype.saveAdditionalRequirements = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var updatedData, savePromises, error_4;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        updatedData = this.container.additionalRequirements;
                        this.state.isLoading = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        savePromises = updatedData.map(function (dataItem) { return _this.management.saveAdditionalRequirement(_this.container.id, dataItem); });
                        return [4 /*yield*/, Promise.all(savePromises)];
                    case 2:
                        _a.sent();
                        this.state.additionalRequirementsDirty = false;
                        return [3 /*break*/, 5];
                    case 3:
                        error_4 = _a.sent();
                        console.error('Error saving additional requirements:', error_4);
                        return [3 /*break*/, 5];
                    case 4:
                        this.parseAndFormatAdditionalRequirements();
                        this.state.isLoading = false;
                        this.cdr.detectChanges();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    PayCodeDetailsComponent.prototype.saveRules = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var updatedData, savePromises, _loop_1, this_1, _i, updatedData_1, rule, state_1, error_5;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        updatedData = this.container.rules.map(function (rule) { return (tslib_1.__assign({}, rule, { whereClause: _this.generateWhereClause(rule), startDate: rule.startDate ? _this.formatDate(rule.startDate) : undefined, endDate: rule.endDate ? _this.formatDate(rule.endDate) : undefined })); });
                        this.state.isLoading = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 8, 9, 10]);
                        savePromises = [];
                        _loop_1 = function (rule) {
                            var isValid;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!rule.whereClause) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this_1.management.validateRule(rule.whereClause)];
                                    case 1:
                                        isValid = _a.sent();
                                        if (!isValid) {
                                            this_1.state.isLoading = false;
                                            this_1.cdr.detectChanges();
                                            return [2 /*return*/, { value: void 0 }];
                                        }
                                        _a.label = 2;
                                    case 2:
                                        if (rule.id && rule.id > 0) {
                                            savePromises.push(this_1.management.saveRule(rule).then(function (savedRule) {
                                                rule.isNew = false; // Reset the isNew flag after saving
                                                return savedRule;
                                            }));
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        _i = 0, updatedData_1 = updatedData;
                        _a.label = 2;
                    case 2:
                        if (!(_i < updatedData_1.length)) return [3 /*break*/, 5];
                        rule = updatedData_1[_i];
                        return [5 /*yield**/, _loop_1(rule)];
                    case 3:
                        state_1 = _a.sent();
                        if (typeof state_1 === "object")
                            return [2 /*return*/, state_1.value];
                        _a.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5: return [4 /*yield*/, Promise.all(savePromises)];
                    case 6:
                        _a.sent();
                        // Re-fetch the rules to ensure the state is up-to-date
                        return [4 /*yield*/, this.management.fetchRulesByExceptionId(this.container.id)];
                    case 7:
                        // Re-fetch the rules to ensure the state is up-to-date
                        _a.sent();
                        this.setRuleFormulasValueType();
                        this.state.rulesDirty = false;
                        return [3 /*break*/, 10];
                    case 8:
                        error_5 = _a.sent();
                        console.error('Error saving rules:', error_5);
                        return [3 /*break*/, 10];
                    case 9:
                        this.state.isLoading = false;
                        this.cdr.detectChanges();
                        return [7 /*endfinally*/];
                    case 10: return [2 /*return*/];
                }
            });
        });
    };
    PayCodeDetailsComponent.prototype.bindPageLoadData = function () {
        this.initServices();
        this.appConfig = appConfig;
    };
    PayCodeDetailsComponent.prototype.initServices = function () {
        this.stateManagement.init('PayCodeDetailsComponent');
        this.columnManagementService.init('PayCodeDetailsComponent');
        this.columnManagementService.initGroup(this.columnGroup, 12);
    };
    PayCodeDetailsComponent.prototype.markExceptionsDirty = function () {
        this.state.exceptionsDirty = true;
    };
    PayCodeDetailsComponent.prototype.markAdditionalRequirementsDirty = function () {
        this.state.additionalRequirementsDirty = true;
    };
    PayCodeDetailsComponent.prototype.markRulesDirty = function () {
        this.state.rulesDirty = true;
    };
    PayCodeDetailsComponent.prototype.isSaveDisabled = function () {
        var formValid = this.templateForm ? this.templateForm.valid : false;
        var dropdownsValid = this.container.exceptions.every(function (exception) {
            return exception.exAllocationType && exception.exPayDiffType;
        });
        return !(formValid && dropdownsValid && (this.state.exceptionsDirty || this.state.additionalRequirementsDirty || this.state.rulesDirty));
    };
    PayCodeDetailsComponent.prototype.onExActionStartVarChange = function (value) {
        this.container.exceptions[0].exActionStartVar = value;
        this.markExceptionsDirty();
    };
    PayCodeDetailsComponent.prototype.onGroupIdChange = function (value) {
        this.container.exceptions[0].groupId = value;
        this.markExceptionsDirty();
    };
    PayCodeDetailsComponent.prototype.onExPayDiffTypeChange = function (value) {
        this.container.exceptions[0].exPayDiffType = value;
        this.markExceptionsDirty();
    };
    PayCodeDetailsComponent.prototype.onExAllocationTypeChange = function (value) {
        this.container.exceptions[0].exAllocationType = value;
        this.markExceptionsDirty();
    };
    PayCodeDetailsComponent.prototype.onExWorkStatusChange = function (value) {
        this.container.exceptions[0].exWorkStatus = value;
        this.markExceptionsDirty();
    };
    PayCodeDetailsComponent.prototype.intToHexColor = function (value) {
        return ColorUtil.DecToHexString(value, true);
    };
    PayCodeDetailsComponent.prototype.onColorChange = function (event) {
        var colorValue = parseInt(event.target.value.replace('#', ''), 16);
        this.container.exceptions[0].screenColor = colorValue;
        this.markExceptionsDirty();
    };
    PayCodeDetailsComponent.prototype.onActionVariableChange = function (value, field) {
        this.container.exceptions[0][field] = value;
        this.markExceptionsDirty();
    };
    PayCodeDetailsComponent.prototype.onActionIntervalChange = function (event) {
        var intervalValue = parseInt(event.target.value, 10);
        if (!isNaN(intervalValue)) {
            this.container.exceptions[0].exActionInterval = intervalValue.toString();
        }
        else {
            this.container.exceptions[0].exActionInterval = '';
        }
        this.markExceptionsDirty();
    };
    PayCodeDetailsComponent.prototype.onOverrideChange = function (field, value) {
        this.container.additionalRequirements[0][field + 'Temp'] = value;
        this.container.additionalRequirements[0][field] = "OT " + value;
        this.markAdditionalRequirementsDirty();
    };
    PayCodeDetailsComponent.prototype.cancel = function () {
        this.location.back();
    };
    PayCodeDetailsComponent.prototype.deletePaycodeException = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.state.isLoading = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.management.deletePaycodeException(this.container.id)];
                    case 2:
                        _a.sent();
                        this.location.back();
                        return [3 /*break*/, 4];
                    case 3:
                        error_6 = _a.sent();
                        console.error('Error deleting paycode exception:', error_6);
                        this.state.isLoading = false;
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PayCodeDetailsComponent.prototype.addRule = function () {
        this.container.rules.push(this.newRule());
        this.markRulesDirty();
    };
    PayCodeDetailsComponent.prototype.removeRule = function (index) {
        var _this = this;
        var rule = this.container.rules[index];
        if (rule.isNew) {
            this.container.rules.splice(index, 1);
            this.cdr.detectChanges();
        }
        else if (rule.id && rule.id > 0) {
            this.management.deleteRule(rule.id).then(function () {
                _this.setRuleFormulasValueType();
                _this.cdr.detectChanges();
            }).catch(function (error) {
                console.error('Error deleting rule:', error);
            });
        }
    };
    PayCodeDetailsComponent.prototype.addRuleFormula = function (rule) {
        var newFormula = {
            id: Math.floor(Math.random() * 9000000) + 1000000,
            ruleId: rule.id,
            variableId1: '',
            operator: '=',
            variableId2: '',
            isNew: true,
            valueType: 'value'
        };
        rule.ruleFormulas.push(newFormula);
        this.markRulesDirty();
        this.cdr.detectChanges();
    };
    PayCodeDetailsComponent.prototype.removeRuleFormula = function (rule, formulaIndex) {
        var _this = this;
        var formula = rule.ruleFormulas[formulaIndex];
        if (formula.isNew) {
            rule.ruleFormulas.splice(formulaIndex, 1);
            this.cdr.detectChanges();
        }
        else if (formula.id && formula.id > 0) {
            this.management.deleteRuleFormula(rule.id, formula.id).then(function () {
                rule.ruleFormulas.splice(formulaIndex, 1);
                _this.cdr.detectChanges();
            }).catch(function (error) {
                console.error('Error deleting rule formula:', error);
            });
        }
    };
    PayCodeDetailsComponent.prototype.setRuleFormulasValueType = function () {
        var _this = this;
        this.container.rules.forEach(function (rule) {
            rule.ruleFormulas.forEach(function (formula) {
                if (formula.variableId2.includes('@')) {
                    formula.valueType = 'formula';
                }
                else if (_this.exceptionVariables.some(function (variable) { return variable.description === formula.variableId2; })) {
                    formula.valueType = 'variable';
                }
                else {
                    formula.valueType = 'value';
                }
            });
        });
    };
    PayCodeDetailsComponent.prototype.generateWhereClause = function (rule) {
        var _this = this;
        var clauses = rule.ruleFormulas.map(function (formula) {
            var variable1 = _this.getFieldProperty(formula.variableId1);
            var operator = formula.operator;
            var variable2;
            if (formula.valueType === 'variable') {
                variable2 = _this.getFieldProperty(formula.variableId2);
            }
            else if (formula.valueType === 'formula') {
                variable2 = formula.variableId2.startsWith('@') ? formula.variableId2.slice(1) : formula.variableId2;
            }
            else {
                variable2 = formula.variableId2;
            }
            // Check if dataType is 'char' and operator is '=' to wrap variable2 in single quotes
            var variable = _this.exceptionVariables.find(function (v) { return v.description === formula.variableId2; });
            if (variable && variable.dataType === 'char' && operator === '=' && !variable2.startsWith("'") && !variable2.endsWith("'")) {
                variable2 = "'" + variable2 + "'";
            }
            return variable1 + " " + operator + " " + variable2;
        });
        return "WHERE " + clauses.join(' AND ');
    };
    PayCodeDetailsComponent.prototype.getFieldProperty = function (variableDescription) {
        var variable = this.exceptionVariables.find(function (v) { return v.description === variableDescription; });
        return variable ? variable.field : '';
    };
    PayCodeDetailsComponent.prototype.adjustInterfaceCategoryIdForDisplay = function (id) {
        return (Number)(id) + 1;
    };
    PayCodeDetailsComponent.prototype.newPaycodeException = function () {
        return {
            id: this.container.id,
            exceptionDescription: '',
            screenColor: 0,
            accrualInd: false,
            paidInd: false,
            payDiffPct: 0,
            deleteInd: false,
            discrepancyInd: false,
            exAllocationType: 0,
            exInterface: '',
            exWorkStatus: '',
            exInterfaceCategoryId: 0,
            exFormulaStart: '',
            exFormulaEnd: '',
            exAllowMultiple: false,
            exCountTowardsOvertimeInd: false,
            exSortOrder: 0,
            exMinimumInterval: 0,
            exMaximumAppliedInterval: 0,
            exMinimumAppliedInterval: 0,
            exShiftDiffCategory: '',
            exAddlVariable: '',
            ex2ndPayRateExceptionId: 0,
            exSecondaryJobCode: 0,
            exApplyToWeekday: false,
            exApplyShiftDiff: false,
            groupId: 0,
            exAction: '',
            exActionStart: '',
            exActionEnd: '',
            exActionStartVar: 0,
            exActionInterval: '',
            exActionMessage: '',
            exDepartmentId: 0,
            exPayDiffType: 0,
            exShowInExceptionGrid: false,
            exAmount: 0,
            exDisplayGroup: 0,
            exOrganizationId: this.orgId,
            exUseInTimesheets: false,
            workedInd: false,
            acaInd: false,
            exceptionColumnGroup: '',
            isException: false
        };
    };
    PayCodeDetailsComponent.prototype.newAdditionalRequirement = function () {
        return {
            ignoreBreak: false,
            calculateNetWorkTime: false,
            useContiguousTime: false,
            stayWithinExceptionTimeframe: false,
            exportAsDollarAmount: false,
            paidAtHolidayRate: false,
            regularOverride: '',
            otOverride: '',
            holidayOverride: '',
            overtimeHolidayOverride: '',
            otherCodeOverride: '',
            recalculate: '',
            requirementIds: {}
        };
    };
    PayCodeDetailsComponent.prototype.newRule = function () {
        return {
            id: Math.floor(Math.random() * 9000000) + 1000000,
            ruleDescription: '',
            startDate: new Date(),
            endDate: undefined,
            period: 0,
            organizationId: this.orgId,
            departmentId: 0,
            jobCode: 0,
            exceptionId: this.container.id,
            whereClause: '',
            ruleFormulas: [],
            isNew: true
        };
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], PayCodeDetailsComponent.prototype, "orgLevel$", void 0);
    return PayCodeDetailsComponent;
}());
export { PayCodeDetailsComponent };
