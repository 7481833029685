import * as tslib_1 from "tslib";
import { NotificationsService } from '../../../../../app/core/components';
import { BusyService } from '../../../../common/services/index';
import { WfmSyncService } from '../../../../configuration/services/wfm/wfm-sync.service';
import { BaseFilterSyncComponent } from '../base-filter-sync.component';
import { dataCheck } from './../../../models/wfm-sync';
var BenefitdeductionMigrationComponent = /** @class */ (function (_super) {
    tslib_1.__extends(BenefitdeductionMigrationComponent, _super);
    function BenefitdeductionMigrationComponent(wfmSyncService, notificationService, busyService) {
        return _super.call(this, wfmSyncService, notificationService, busyService) || this;
    }
    BenefitdeductionMigrationComponent.prototype.syncDataCore = function (selectedItems) {
        return this.busyService.busy(this.wfmSyncService.benefitdeductionSyncOrg(selectedItems, 'ongoing'));
    };
    BenefitdeductionMigrationComponent.prototype.getSyncItemKey = function (item) {
        return '' + item.enrollmentId;
    };
    BenefitdeductionMigrationComponent.prototype.fetchData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.busyService.busy(this.wfmSyncService.GetBenefitDeductionChangeLogs('ongoing', this.isActiveOnly))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.processData(response)];
                }
            });
        });
    };
    BenefitdeductionMigrationComponent.prototype.processData = function (data) {
        return dataCheck(data);
    };
    BenefitdeductionMigrationComponent.prototype.setGlobalFilter = function () {
        var _this = this;
        this.globalFilterFunc$.next(function (item) {
            var orgId = _this.selectedOrganization && _this.selectedOrganization.id;
            var deptId = _this.selectedDepartment && _this.selectedDepartment.id;
            var posId = _this.selectedPosition && _this.selectedPosition.id;
            return (orgId == null || item.organizationId === orgId) &&
                (deptId == null || item.departmentId === deptId) &&
                (posId == null || item.positionId === posId);
        });
    };
    return BenefitdeductionMigrationComponent;
}(BaseFilterSyncComponent));
export { BenefitdeductionMigrationComponent };
