import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    distinct,
    filterBy,
    FilterDescriptor,
  } from "@progress/kendo-data-query";
  import { FilterService } from "@progress/kendo-angular-grid";

interface CompositeFilterDescriptor {
    logic: "or" | "and";
    filters: Array<any>;
}
@Component({
  selector: 'slx-multicheck-filter',
  templateUrl: './multicheck-filter.component.html',
  styleUrls: ['./multicheck-filter.component.scss']
})
export class MultiCheckFilterComponent implements AfterViewInit  {
    @Input() public isPrimitive: boolean;
    @Input() public currentFilter: CompositeFilterDescriptor;
    @Input() public data;
    @Input() public textField;
    @Input() public valueField;
    @Input() public filterService: FilterService;
    @Input() public field: string;
    @Output() public valueChange = new EventEmitter<number[]>();
    private blanks = '(Blanks)';
  
    public currentData: unknown[];
    public showFilter = true;
    private value: unknown[] = [];
  
    public textAccessor = (dataItem: unknown): string =>
      this.isPrimitive ? dataItem : dataItem[this.textField];

    public valueAccessor = (dataItem: unknown): unknown =>
      this.isPrimitive ? dataItem : dataItem[this.valueField];

    public ngAfterViewInit(): void {
      this.currentData = this.data;
      this.value = this.currentFilter.filters.map(
        (f: FilterDescriptor) => f.operator === "isempty" ? '' : f.value
      );
  
      this.showFilter =
        typeof this.textAccessor(this.currentData[0]) === "string";
    }
  
    public isItemSelected(item: unknown): boolean {
      if(item === this.blanks) {
        if (this.value.some((x) => x === "" || x === null)) {
          return true;
        }
      } else {
        return this.value.some((x) => x === this.valueAccessor(item));
      }
    }
  
    public onSelectionChange(item: unknown, li: HTMLLIElement): void {
      if(item === this.blanks){
        if (this.value.some((x) => x === "" || x === null)) {
          this.value = this.value.filter((x) => x !== "" && x !== null);
          
        } else {
          this.value.push(null);
          this.value.push('');
        }
      } else if (this.value.some((x) => x === item)) {
        this.value = this.value.filter((x) => x !== item);
      } else {
        this.value.push(item);
      }
  
      this.filterService.filter({
        filters: this.value.map((value) => ({
          field: this.field,
          operator: value === null ? "isnull" : value === '' ? "isempty" : "eq",
          value: value === '' ? null : value,
        })),
        logic: "or",
      });
  
      this.onFocus(li);
    }
  
    public onInput(e: any): void {
      this.currentData = distinct([
        ...this.currentData.filter(dataItem => this.value.some(val => val === this.valueAccessor(dataItem))),
        ...filterBy(this.data, {
          operator: 'contains',
          field: this.textField,
          value: e.target.value
        })],
        this.textField
      );
    }
  
    public onFocus(li: HTMLLIElement): void {
      const ul = li.parentNode as HTMLUListElement;
      const below =
        ul.scrollTop + ul.offsetHeight < li.offsetTop + li.offsetHeight;
      const above = li.offsetTop < ul.scrollTop;
  
      // Scroll to focused checkbox
      if (above) {
        ul.scrollTop = li.offsetTop;
      }
  
      if (below) {
        ul.scrollTop += li.offsetHeight;
      }
    }
  }