import { AllocationTypeModel } from "./allocation-type.model";
import { ExceptionGroupModel } from "./exception-group.model";
import { ExceptionVariableModel } from "./exception-variable.model";
import { InterfaceCategory } from "./interface-category.model";
import { PayDiffTypeModel } from "./pay-diff-type.model";
import { PayCodeMinException } from "./paycode-min-exception.model";
import { SpecialCodeModel } from "./special-code.model";
import { WorkCode } from "./work-code.model";

export class PaycodeExceptionModel {
    public id: number;
    public exceptionDescription?: string;
    public screenColor?: number;
    public accrualInd?: boolean;
    public paidInd?: boolean;
    public payDiffPct?: number;
    public deleteInd?: boolean;
    public discrepancyInd?: boolean;
    public exAllocationType?: number;
    public exInterface?: string;
    public exWorkStatus?: string;
    public exInterfaceCategoryId?: number;
    public exFormulaStart?: string;
    public exFormulaEnd?: string;
    public exAllowMultiple?: boolean;
    public exCountTowardsOvertimeInd?: boolean;
    public exSortOrder?: number;
    public exMinimumInterval?: number;
    public exMaximumAppliedInterval?: number;
    public exMinimumAppliedInterval?: number;
    public exShiftDiffCategory?: string;
    public exAddlVariable?: string;
    public ex2ndPayRateExceptionId?: number;
    public exSecondaryJobCode?: number;
    public exApplyToWeekday?: boolean;
    public exApplyShiftDiff?: boolean;
    public groupId?: number;
    public exAction?: string;
    public exActionStart?: string;
    public exActionEnd?: string;
    public exActionStartVar?: number;
    public exActionInterval?: string;
    public exActionMessage?: string;
    public exDepartmentId?: number;
    public exPayDiffType?: number;
    public exShowInExceptionGrid?: boolean;
    public exAmount?: number;
    public exDisplayGroup?: number;
    public exOrganizationId?: number;
    public exUseInTimesheets?: boolean;
    public workedInd?: boolean;
    public acaInd?: boolean;
    public exceptionColumnGroup?: string;
    public isException: boolean;
    public allocationTypes?: AllocationTypeModel[];
    public exceptionGroups?: ExceptionGroupModel[];
    public exceptionVariables?: ExceptionVariableModel[];
    public payDiffTypes?: PayDiffTypeModel[];
    public specialCodes?: SpecialCodeModel[];
    public payCodeExceptions?: PayCodeMinException[];
    public workCodes?: WorkCode[];
    public interfaceCategories?: InterfaceCategory[];
}