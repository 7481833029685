import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

import { dateTimeUtils } from './../../../../common/utils/dateTimeUtils';
import { Assert } from '../../../../framework/index';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { StateResetTypes } from '../../../../core/models/index';
import {
  BenefitDetailsBasic,
  BenefitFormulaCalculationType,
  BenefitDetailsProviderEntity,
  BenefitDetailsLine,
  BenefitDetailsProviderLineStandartEntity,
  BenefitDetailsTier,
  BenefitDetailsCalcMethod,
  BenefitDetailsFormula,
  ExtendExpireRenewModel,
  BenefitDetailsOption,
  BenefitDetailsLineStandart,
  BenefitEligibleEmployeesInfo,
} from '../../models/index';
import { StateManagementService, ComponentStateStorageService, FileService } from '../../../../common/services/index';
import { CalculationCommonService } from '../../services/benefit-details/calculation-common.service';
import { BenefitDetailsManagementService } from './benefit-details-management.service';
import { BenefitDetailsPermissionService } from './benefit-details-permission.service';
import { BenefitDetailsApiService } from './benefit-details-api.service';
import { BenefitEmployeesApiService } from '../benefit-employees/benefit-employees-api.service';
import { BenefitEligibilityRulesManagementService } from '../benefit-eligibility-rules';
import { OrgLevel } from '../../../../state-model/models';

@Injectable()
export class BenefitDetailsStandartManagementService {
  private calcMethod$ = new ReplaySubject<BenefitDetailsCalcMethod>(1);
  private calcMethod: BenefitDetailsCalcMethod;
  private state$ = new ReplaySubject<boolean>(1);
  private providerTiers$ = new ReplaySubject<BenefitDetailsTier[]>(1);
  private selectProviderTier$ = new ReplaySubject<BenefitDetailsTier>(1);
  private addCoverageOption$ = new ReplaySubject<BenefitDetailsOption>(1);
  private changeProviderPlanDate$ = new ReplaySubject<Date>(1);
  private benefitEligibleEmployeesInfo$ = new ReplaySubject<BenefitEligibleEmployeesInfo>(1);
  private benefitEnrolledEmployeesInfo$ = new ReplaySubject<number>(1);
  private benefitEnrolledEmployeesChanged$ = new ReplaySubject<boolean>(1);
  private costOrEmpFrequency$ = new Subject<boolean>();

  private selectedProvider: BenefitDetailsProviderEntity;
  private selectedProviderLine: BenefitDetailsProviderLineStandartEntity;
  private providerTiers: BenefitDetailsTier[] = [];
  private selectedProviderTier: BenefitDetailsTier;
  private providerPlanDate: Date;

  private updateEmployees$ = new ReplaySubject<boolean>(1);

  private providerNameIsUnique = true;
  private providerTierNameIsUnique = true;
  public providerTierEECodeIsUnique = true;
  public providerTierERCodeIsUnique = true;
  public isValidEEdeductionCode = true;
  public isValidERdeductionCode = true;
  private minAge: number = 1;
  private maxAge: number = 99;

  private coverageOptionsValid: boolean = true;

  private readonly formulaTypesNames = {
    [BenefitFormulaCalculationType.Formula]: 'Formula',
    [BenefitFormulaCalculationType.Fixed]: 'Fixed Amount',
    [BenefitFormulaCalculationType.MultiplerMaxCap]: 'Multiplier with Max Cap',
    [BenefitFormulaCalculationType.AnyValueMaxCap]: 'Any value with Max Cap',
  };

  @unsubscribeAll('destroy')
  private subscriptions: StringMap<Subscription> = {};
  private componentId: string;
  private controlId: string = 'shownFormula';
  private readonly resetBy: StateResetTypes = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;
  private readonly defaultTierName = 'Default';
  private covOptionsChanged: boolean = false;
  private hasEnrolledEmpTierLevel: boolean = false;
  private exSelectedProviderTier: BenefitDetailsTier;
  public deductionValue :boolean =false;
  public duplicateValue:boolean=false
  public isDuplicateERDeductionCode: boolean = false;
  public eeValidationMessage: string;
  public erValidationMessage: string;
  public eEErrorMessage: string;
  public eRErrorMessage: string;
  public employeeDeductionCode: string;
  public employerDeductionCode: string;
  public deductions:any;
  readonly eEdeductionType = 'employee';
  readonly eRdeductionType = 'employer';
  constructor(
    private commonManService: BenefitDetailsManagementService,
    private permissionService: BenefitDetailsPermissionService,
    private stateService: StateManagementService,
    private storageService: ComponentStateStorageService,
    private apiService: BenefitDetailsApiService,
    private empApiService: BenefitEmployeesApiService,
    private fileService: FileService,
    private rulesManagementService: BenefitEligibilityRulesManagementService,
    private costCalculationService: CalculationCommonService,
    public benefitDetailsApiService: BenefitDetailsApiService
  ) {}

  public init(componentId: string): void {
    this.componentId = componentId;
    this.stateService.init(componentId, true);

    this.subscribeToData();
  }

  public destroy(): void {
    this.calcMethod$.complete();
    this.state$.complete();
    this.providerTiers$.complete();
    this.selectProviderTier$.complete();
    this.changeProviderPlanDate$.complete();
    this.addCoverageOption$.complete();
    this.benefitEligibleEmployeesInfo$.complete();
    this.benefitEnrolledEmployeesInfo$.complete();
    this.benefitEnrolledEmployeesChanged$.complete();
    this.costOrEmpFrequency$.complete();
  }

  public hasChangesInCovOptions(): boolean {
    return this.covOptionsChanged;
  }

  public subscribeToChangeCalcMethod(callback: (v: BenefitDetailsCalcMethod) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.calcMethod$.subscribe(callback);
  }

  public subscribeToChangeState(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.state$.subscribe(callback);
  }

  public subscribeToChangeupdateEmployees(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.updateEmployees$.subscribe(callback);
  }

  public updateEmployees(value: boolean): void {
    this.covOptionsChanged = false;
    this.updateEmployees$.next(value);
  }

  public subscribeToProviderTiers(callback: (v: BenefitDetailsTier[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.providerTiers$.subscribe(callback);
  }

  public subscribeToSelectProviderTier(callback: (v: BenefitDetailsTier) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.selectProviderTier$.subscribe(callback);
  }

  public subscribeToChangeProviderPlanDate(callback: (v: Date) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.changeProviderPlanDate$.subscribe(callback);
  }

  public subscribeToAddNewCoverageOption(callback: (v: BenefitDetailsOption) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.addCoverageOption$.subscribe(callback);
  }

  public subscribeToBenefitEligibleEmployeesInfo(callback: (info: BenefitEligibleEmployeesInfo) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.benefitEligibleEmployeesInfo$.subscribe(callback);
  }

  public subscribeToBenefitEnrolledEmployeesInfo(callback: (r: number) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.benefitEnrolledEmployeesInfo$.subscribe(callback);
  }

  public subscribeToBenefitEnrolledEmployeesChanged(callback: (r: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.benefitEnrolledEmployeesChanged$.subscribe(callback);
  }

  public subscribeToCostOrEmpFrequencyChanged(callback: (isCostFrequencyChanged: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.costOrEmpFrequency$.subscribe(callback);
  }

  public changeCalcMethod(cm: BenefitDetailsCalcMethod): void {
    this.calcMethod = cm;
    this.calcMethod$.next(cm);
  }

  public changeProviderPlanDate(date: Date): void {
    if (_.isDate(date)) {
      this.providerPlanDate = date;
      this.changeProviderPlanDate$.next(date);
    }
  }

  public updateProviderName(pName: string): void {
    this.selectedProvider.current.name = _.trim(pName);
    this.updateCanSaveState();
  }
  public updatedeductionCode(pName: string): void {
    this.selectedProviderTier.payrollDeductionCode = _.trim(pName);
    this.updateCanSaveState();
  }

  public updateERDeductionCode(eRDCode: string): void {
    this.selectedProviderTier.employerDeductionCode = _.trim(eRDCode);
    this.updateCanSaveState();
  }

  public async selectProviderLine(pLine: BenefitDetailsLine, byPass: boolean = false): Promise<void> {
    await this.commonManService.loadProviderLine(pLine.id, byPass);
  }

  public updateProviderLine(isCostFrequencyChanged: boolean = null): void {
    if (_.isBoolean(isCostFrequencyChanged) && !this.selectedProviderLine.isDraft) {
      this.updateAllOptionsInAllTiers(this.selectedProviderLine.current);
    }
    this.updateTierFormulasDates(this.selectedProviderLine);
    this.updateCanSaveState();
  }

  public addProviderTier(): void {
    const pLine = this.selectedProviderLine.current;
    const newTier = this.createBenefitProviderTier(pLine.line.startDate, pLine.line.endDate, _.size(pLine.tiers));
    pLine.tiers.push(newTier);
    this.selectProviderTiers(pLine.tiers);
    this.selectProviderTier(newTier);
  }

  public updateProviderTier(): void {
    this.selectedProviderTier.setStateUpdated();
    this.updateCanSaveState();
  }

  public deleteProviderTier(tierIdToDelete: number): void {
    this.rulesManagementService.clearExistingMappedRules();
    const pLine = this.selectedProviderLine.current;
    const tier = _.find(pLine.tiers, { id: tierIdToDelete });
    if (_.isObjectLike(tier)) {
      tier.setStateDeleted();
      const newTier = _.findLast(pLine.tiers, (t) => !t.stateIsDeleted);
      this.selectProviderTier(newTier);
      this.selectProviderTiers(pLine.tiers);
      this.updateCanSaveState();
    }
  }

  public selectProviderTier(newTier: BenefitDetailsTier, byPass = false): void {
    const tier = this.selectedProviderTier;
    if (_.isObjectLike(newTier) && (!_.isObjectLike(tier) || tier.id !== newTier.id || byPass)) {
      this.exSelectedProviderTier = _.cloneDeep(newTier);
      this.selectedProviderLine.selectedTierId = newTier.id;
      this.selectedProviderTier = newTier;
      this.selectProviderTier$.next(newTier);
      this.commonManService.saveSelectedTierId(newTier.id);
      const currentCalcMethod = this.selectedProviderLine.current.calcMethod;
      const editmode = this.permissionService.getEditMode();
      const isEditmode = editmode.providerInfo || editmode.benefitInfo || editmode.coverageOptions;
      const orgId = this.commonManService.getProperOrglevelBasedOnSelectedOrglevel();
      if (currentCalcMethod && isEditmode && !newTier.isDraft) {
        this.getBenefitEnrolledEmployeesInfo({ tierId: newTier.id, orgLevelId: orgId, date: this.providerPlanDate });
      } else if (isEditmode && newTier.isDraft) {
        this.hasEnrolledEmpTierLevel = false;
        this.benefitEnrolledEmployeesChanged$.next(false);
      }
    }
  }

  public updateProviderTierName(pTierId: number, pTierName: string): boolean {
    this.providerTierNameIsUnique = this.commonManService.isUniqueName(
      _.filter(this.providerTiers, (t) => !t.stateIsDeleted),
      pTierId,
      pTierName
    );
    if (this.providerTierNameIsUnique) {
      this.selectedProviderTier.name = _.trim(pTierName);
      this.selectedProviderTier.setStateUpdated();
    }
    this.updateCanSaveState();

    return this.providerTierNameIsUnique;
  }

  public updateProviderTierCode(pTierId: number, pCode: string): boolean {
    this.providerTierNameIsUnique = this.commonManService.isUniqueName(
      _.filter(this.providerTiers, (t) => !t.stateIsDeleted),
      pTierId,
      pCode
    );
    if (this.providerTierNameIsUnique) {
      this.selectedProviderTier.name = _.trim(pCode);
      this.selectedProviderTier.setStateUpdated();
    }
    this.updateCanSaveState();

    return this.providerTierNameIsUnique;
  }
  public updateProviderTierEECode(pTierId: number, pCode: string) {
    this.providerTierEECodeIsUnique = this.commonManService.isUniqueEECode(
      _.filter(this.providerTiers, (t) => !t.stateIsDeleted),
      pTierId,
      pCode
    );
    if(this.containsSpecialCharacters(pCode)){
      this.isValidEEdeductionCode = false;
    }
    else{
      this.isValidEEdeductionCode = true;
    }
    if(!this.providerTierEECodeIsUnique){
      this.eEErrorMessage = `This code ${pCode.toLowerCase()} is already in use as an “ER Code” please select a different value`;
    }
    else{
      this.eEErrorMessage = '';
    }
    if (this.providerTierEECodeIsUnique && this.isValidEEdeductionCode) {
      this.selectedProviderTier.payrollDeductionCode = _.trim(pCode);
      this.selectedProviderTier.setStateUpdated();
    }
    this.updateCanSaveState();
  }

  public updateProviderTierERCode(pTierId: number, eRCode: string) {
    this.providerTierERCodeIsUnique = this.commonManService.isUniqueERCode(
      _.filter(this.providerTiers, (t) => !t.stateIsDeleted),
      pTierId,
      eRCode
    );
    if(this.containsSpecialCharacters(eRCode)){
      this.isValidERdeductionCode = false;
    }
    else{
      this.isValidERdeductionCode = true;
    }
    if(!this.providerTierERCodeIsUnique){
      this.eRErrorMessage = `This code ${eRCode.toLowerCase()} is already in use as an “EE Code” please select a different value`;
    }
    else{
      this.eRErrorMessage = '';
    }
    if (this.providerTierERCodeIsUnique && this.isValidERdeductionCode) {
      this.selectedProviderTier.employerDeductionCode = _.trim(eRCode);
      this.selectedProviderTier.setStateUpdated();
    }
    this.updateCanSaveState();
  }

  public updateTierEmployerFormula(type: BenefitFormulaCalculationType, fixed: number, formula: string): void {
    const tier = this.selectedProviderTier;
    if (_.isObjectLike(tier)) {
      if (!this.covOptionsChanged) {
        this.covOptionsChanged =
          this.hasEnrolledEmpTierLevel &&
          (type !== tier.erFormula.calculationType ||
            (tier.erFormula.calculationType === BenefitFormulaCalculationType.Fixed &&
              fixed !== tier.erFormula.fixedAmount));
      }
      this.updateTierFormula(tier, tier.erFormula, { type, fixed, formula });
    }
  }

  public updateTierEmployeeFormula(type: BenefitFormulaCalculationType, fixed: number, formula: string): void {
    const tier = this.selectedProviderTier;
    if (_.isObjectLike(tier)) {
      if (!this.covOptionsChanged) {
        this.covOptionsChanged =
          this.hasEnrolledEmpTierLevel &&
          (type !== tier.empFormula.calculationType ||
            (tier.empFormula.calculationType === BenefitFormulaCalculationType.Fixed &&
              fixed !== tier.empFormula.fixedAmount));
      }
      this.updateTierFormula(tier, tier.empFormula, { type, fixed, formula });
    }
  }

  public updateTierCoverageFormula(
    type: BenefitFormulaCalculationType,
    fixed: number,
    formula: string,
    multipler: number,
    maxCup: number
  ): void {
    const tier = this.selectedProviderTier;
    if (_.isObjectLike(tier)) {
      if (!this.covOptionsChanged) {
        this.covOptionsChanged =
          this.hasEnrolledEmpTierLevel &&
          (type !== tier.coverageFormula.calculationType ||
            (type === BenefitFormulaCalculationType.Fixed && fixed !== tier.coverageFormula.fixedAmount) ||
            (type === BenefitFormulaCalculationType.MultiplerMaxCap &&
              (maxCup !== tier.coverageFormula.maxCap || multipler !== tier.coverageFormula.multipler)) ||
            (type === BenefitFormulaCalculationType.AnyValueMaxCap && maxCup !== tier.coverageFormula.maxCap));
      }
      this.updateTierFormula(tier, tier.coverageFormula, { type, fixed, formula, multipler, maxCup });
    }
  }

  public startEditingProvider(): void {
    this.commonManService.storeProviderAndLine(false);
    this.toggleActionsAndModes(false, true, true);
  }

  public cancelEditingProvider(): void {
    this.covOptionsChanged = false;
    this.rulesManagementService.clearExistingMappedRules();
    this.commonManService.restoreProviderAndLine(false);
    this.toggleActionsAndModes(true, false, false, false);
    this.duplicateValue = false;
    this.isDuplicateERDeductionCode = false;
    this.eeValidationMessage = '';
    this.erValidationMessage = '';
    this.eEErrorMessage = '';
    this.eRErrorMessage = '';
  }

  public createDraftProvider(): void {
    this.benefitEnrolledEmployeesInfo$.next(0);
    this.rulesManagementService.clearExistingMappedRules();
    this.commonManService.storeProviderAndLine(true);
    this.commonManService.selectDraftProviderAndLine();
    this.toggleActionsAndModes(false, true, null);
  }

  public removeDraftProvider(): void {
    this.covOptionsChanged = false;
    this.commonManService.removeDraftProvider();
    this.commonManService.restoreProviderAndLine(true);
    this.selectedProviderTier = null;
    this.toggleActionsAndModes(true, false, false, false);
  }

  public async saveProviderLine(): Promise<void> {
    this.commonManService.updateSpinner(true);
    try {
      await this.commonManService.saveBenefitProviderLine(async (providerLine) => {
        await this.selectProviderLine(providerLine, true);
      }, this.providerPlanDate);
      this.toggleActionsAndModes(true, false, false, false);
    } catch (e) {
      console.error(e);
    }
  }

  public async saveProvider(): Promise<void> {
    try {
      await this.commonManService.saveBenefitProvider(async (providerLine) => {
        await this.selectProviderLine(providerLine, true);
      });
      this.toggleActionsAndModes(true, false, false, false);
    } catch (e) {
      console.error(e);
    }
  }

  public async deleteProvider(): Promise<void> {
    try {
      await this.commonManService.deleteBenefitProvider(async (providerLine) => {
        await this.selectProviderLine(providerLine, true);
      });
    } catch (e) {
      console.error(e);
    }
  }

  public async deleteTierAttachments(delAttachmentIds: number[]): Promise<void> {
    this.commonManService.deleteTierAttachments(this.selectedProviderTier.id, delAttachmentIds);
    _.remove(this.selectedProviderTier.attachments, (a) => _.includes(delAttachmentIds, a.id));
    this.updateCanSaveState();
  }

  public async downloadTierAttachment(attachmentId: number): Promise<void> {
    this.commonManService.updateSpinner(true);
    try {
      const file = await this.apiService.downloadTierAttachment(attachmentId);
      this.fileService.saveToFileSystem(file.blob, file.file);
    } catch (e) {
      console.error(e);
    } finally {
      this.commonManService.updateSpinner(false);
    }
  }

  public async getBenefitEligibleEmployeesInfo({ orgLevelId, tierId, date }): Promise<void> {
    this.commonManService.updateEligibleEmployeesSpinner(true);
    try {
      const selectedProviderorgLevelId = orgLevelId ? orgLevelId : this.selectedProvider.current.orgLevelId;
      const info = await this.empApiService.getBenefitEligibleEmployeesInfo(selectedProviderorgLevelId, tierId, date);
      this.benefitEligibleEmployeesInfo$.next(info);
    } catch (e) {
      console.error(e);
    } finally {
      this.commonManService.updateEligibleEmployeesSpinner(false);
    }
  }

  public async getBenefitEnrolledEmployeesInfo({ orgLevelId, tierId, date }): Promise<void> {
    this.commonManService.updateEnrolledEmployeesSpinner(true);
    try {
      const empCount = await this.empApiService.getBenefitEnrolledEmployeesInfo(orgLevelId, tierId, date);
      this.benefitEnrolledEmployeesInfo$.next(empCount);
      this.benefitEnrolledEmployeesChanged$.next(empCount > 0);
      this.hasEnrolledEmpTierLevel = empCount > 0;
    } catch (e) {
      console.error(e);
    } finally {
      this.commonManService.updateEnrolledEmployeesSpinner(false);
    }
  }

  public getFormulaTypes(): BenefitDetailsBasic<number, BenefitFormulaCalculationType>[] {
    let counter = 0;
    return _.map(
      BenefitFormulaCalculationType,
      (v, i) => new BenefitDetailsBasic(++counter, i, this.formulaTypesNames[i])
    );
  }

  public changeShownFormulaState(value: boolean): void {
    this.storageService.setControlState(this.componentId, this.controlId, { value }, this.resetBy);
    this.state$.next(value);
  }

  public getAllCoverageOptions(): BenefitDetailsOption[] {
    let options: BenefitDetailsOption[] = [];
    _.forEach(this.providerTiers, (t) => {
      if (!t.stateIsDeleted) {
        options = options.concat(_.filter(t.options, (o) => !o.stateIsDeleted));
      }
    });
    return options;
  }

  private updateTierFormula(
    tier: BenefitDetailsTier,
    formula: BenefitDetailsFormula,
    data: { type: BenefitFormulaCalculationType; fixed: number; formula: string; multipler?: number; maxCup?: number }
  ): void {
    formula.calculationType = data.type;
    formula.fixedAmount = null;
    formula.expression = null;
    formula.multipler = null;
    formula.maxCap = null;
    if (formula.isFixedCalcType) {
      formula.fixedAmount = data.fixed;
    }
    if (formula.isFormulaCalcType) {
      formula.expression = data.formula;
    }
    if (formula.isMultiplierCalcType) {
      formula.multipler = data.multipler;
      formula.maxCap = data.maxCup;
    }
    if (formula.isAnyValueMaxCapCalcType) {
      formula.maxCap = data.maxCup;
    }
    tier.setStateUpdated();
    this.updateCanSaveState();
  }

  private validateCanMaxCapFields(): boolean {
    const invalidMaxCap = _.some(this.providerTiers, (tier: BenefitDetailsTier) => {
      const covFormula = tier.coverageFormula;
      return (
        (covFormula.isAnyValueMaxCapCalcType && !_.isFinite(covFormula.maxCap)) ||
        (covFormula.isMultiplierCalcType &&
          (!_.isFinite(covFormula.maxCap) ||
            !_.isFinite(covFormula.multipler) ||
            _.lt(covFormula.maxCap, covFormula.multipler)))
      );
    });
    return !invalidMaxCap;
  }

  private updateTierFormulasDates(providerLine: BenefitDetailsProviderLineStandartEntity): void {
    if (_.isObjectLike(this.selectedProviderTier)) {
      const { empFormula, erFormula, coverageFormula } = this.selectedProviderTier;
      const { startDate, endDate } = providerLine.current.line;
      this.updateTierFormulaDates(empFormula, startDate, endDate);
      this.updateTierFormulaDates(erFormula, startDate, endDate);
      this.updateTierFormulaDates(coverageFormula, startDate, endDate);
    }
  }

  private createBenefitProviderTier(sDate: Date, eDate: Date, length: number): BenefitDetailsTier {
    const providerTier = new BenefitDetailsTier();
    let tierId = -Math.abs(length) - 1;
    const tierName = `${this.defaultTierName} ${Math.abs(tierId)}`;
    providerTier.tier.id = tierId;
    providerTier.tier.name = tierName;
    providerTier.tier.title = tierName;
    providerTier.notes = '';
    providerTier.setStateAdded();
    this.updateTierFormulaDates(providerTier.empFormula, sDate, null /* eDate */);
    this.updateTierFormulaDates(providerTier.erFormula, sDate, null /* eDate */);
    this.updateTierFormulaDates(providerTier.coverageFormula, sDate, null /* eDate */);

    return providerTier;
  }

  private selectProviderTiers(tiers: BenefitDetailsTier[]): void {
    if (_.isArray(tiers)) {
      this.providerTiers = tiers.concat();
      this.providerTiers$.next(this.providerTiers);
    }
  }

  private updateTierFormulaDates(formula: BenefitDetailsFormula, sDate: Date, eDate: Date): void {
    formula.startDate = sDate;
    formula.endDate = eDate;
  }

  public replaceTierInProviderTiers(providerLine: BenefitDetailsLineStandart, tierToReplace: BenefitDetailsTier): void {
    const index = _.findIndex(providerLine.tiers, { id: tierToReplace.id });
    if (index !== -1) {
      providerLine.tiers.splice(index, 1, tierToReplace);
    }
  }

  public updateCanSaveState(): void {
    const canSave = this.validateCanSaveProvider();
    this.permissionService.setCanSave(canSave);
  }
  // public updateSpecial(valid:boolean): void {
  //   // valid = this.validateCanSaveProvider();
  //   this.permissionService.setCanSave(valid);
  // }
  public updateCanSaveDeductionCode(): void {
    const canSave = this.validateCanSaveProvider();
    this.permissionService.setCanSave(canSave);
  }
  public updateCanSaveStateByValidity(isValid: boolean): void {
    this.coverageOptionsValid = isValid;
    const canSave = this.validateCanSaveProvider();
    this.permissionService.setCanSave(isValid && canSave);
  }

  public updateFlatCoverageOptions(empContribution: number, employeercontribution: number): void {
    const tiers = this.selectedProviderTier;

    if (!this.covOptionsChanged) {
      this.covOptionsChanged =
        this.hasEnrolledEmpTierLevel &&
        (tiers.empContribution !== empContribution || tiers.erContribution !== employeercontribution);
    }

    tiers.setStateUpdated();
    tiers.empContribution = empContribution;
    tiers.erContribution = employeercontribution;
    this.updateCanSaveState();
  }

  public createBenefitProviderOption(): BenefitDetailsOption {
    const option = new BenefitDetailsOption();
    option.id = -(_.size(this.selectedProviderTier.options) + 1);

    return option;
  }

  public addNewCoverageOption(): void {
    this.addCoverageOption$.next(this.createBenefitProviderOption());
  }

  public saveCoverageOption(o: BenefitDetailsOption, is401K: boolean): void {
    const option = _.assign(new BenefitDetailsOption(), o);
    option.setStateAdded();
    if (is401K) {
      _.forEach(this.selectedProviderTier.options, (o) => {
        if (o.isDraft) o.setStateDeleted();
      });
    }
    this.selectedProviderTier.options.unshift(option);
    this.selectedProviderTier.setStateUpdated();
    this.updateCanSaveState();
  }

  public updateCoverageOption(option: BenefitDetailsOption): void {
    if (!this.covOptionsChanged && this.hasEnrolledEmpTierLevel) {
      let empCont = this.exSelectedProviderTier.options.find((x) => x.id === option.id);

      const hasChangedOptionRates =
        this.calcMethod.isOptionsRates &&
        (option.empContribution !== empCont.empContribution ||
          option.erContribution !== empCont.erContribution ||
          option.code !== empCont.code);

      const hasChanged401KOptions =
        this.calcMethod.is401K &&
        (empCont.maxContribution !== option.maxContribution ||
          empCont.suggestedContribution !== option.suggestedContribution ||
          empCont.maxMatchGrant !== option.maxMatchGrant ||
          empCont.maxEmpContribution !== option.maxEmpContribution ||
          empCont.empContribution !== option.empContribution ||
          empCont.limitAmount !== option.limitAmount ||
          empCont.compensationLimit !== option.compensationLimit);
      this.covOptionsChanged = hasChangedOptionRates || hasChanged401KOptions;
    }

    option.setStateUpdated();
    this.selectedProviderTier.setStateUpdated();
    this.updateCanSaveState();
  }

  public removeCoverageOption(option: BenefitDetailsOption): void {
    option.setStateDeleted();
    this.selectedProviderTier.setStateUpdated();
    this.updateCanSaveState();
  }

  public updateTierOptionCost(selectedProviderLine: BenefitDetailsLineStandart, option: BenefitDetailsOption): void {
    const { costFreq, empContFreq } = selectedProviderLine;
    const { empContribution, erContribution } = option;
    let employeeContribution = 0;
    if (empContribution > 0) {
      employeeContribution = this.costCalculationService.getConvertedEmployeeContribution(
        costFreq,
        empContFreq,
        empContribution
      );
    }
    option.cost = parseFloat((erContribution + employeeContribution).toFixed(2));
  }

  private updateAllOptionsInAllTiers(line: BenefitDetailsLineStandart): void {
    _.forEach(line.tiers, (t) => {
      t.setStateUpdated();
      _.forEach(t.options, (o) => {
        o.setStateUpdated();
        this.updateTierOptionCost(line, o);
      });
    });
  }
  private validateDeductionCode(): boolean {
    return this.hasName(this.selectedProviderTier.payrollDeductionCode);
  }
  
  private validateCanSaveProvider(): boolean {
    const { costFreq, empContFreq, calcMethod, line, tiers, childAgeLimit } = this.selectedProviderLine.current;

    return (
      this.coverageOptionsValid &&
      this.hasName(this.selectedProvider.current) &&
      !this.duplicateValue &&
      !this.isDuplicateERDeductionCode &&
      this.hasId(costFreq) &&
      this.hasId(empContFreq) &&
      this.hasId(calcMethod) &&
      _.isObjectLike(line) &&
      this.isValidDate(line.startDate) &&
      this.isValidDate(line.endDate) &&
      this.hasName(this.selectedProviderTier) &&
      !this.hasOptionsWithoutMandatoryFields &&
      this.providerNameIsUnique &&
      this.providerTierNameIsUnique &&
      this.providerTierEECodeIsUnique &&
      this.providerTierERCodeIsUnique &&
      this.isValidEEdeductionCode &&
      this.isValidERdeductionCode &&
      (!_.isFinite(childAgeLimit) || (childAgeLimit >= this.minAge && childAgeLimit <= this.maxAge)) &&
      this.validateCanMaxCapFields()
    );
  }

  public containsSpecialCharacters(value: string): boolean {
    const regex = /^[^\^\\]*$/;
    return !regex.test(value);
  }

  public get hasOptionsWithoutMandatoryFields(): boolean {
    if (!this.selectedProviderLine || !this.selectedProviderLine.current) {
      return false;
    }
    const tiers: BenefitDetailsTier[] = this.selectedProviderLine.current.tiers;
    const calcMethod: BenefitDetailsCalcMethod = this.selectedProviderLine.current.calcMethod;
    const hasTiers = _.size(tiers) > 0;
    const hasBadOptions: boolean = _.some(tiers, (tier: BenefitDetailsTier) => {
      if (tier.stateIsDeleted || !tier.options) {
        return false;
      }
      return _.some(tier.options, (opt) => !opt.stateIsDeleted && !opt.hasTypeAndCode);
    });
    return calcMethod && calcMethod.isOptionsRates && hasTiers && hasBadOptions;
  }

  private toggleActionsAndModes(
    create: boolean,
    isEdit: boolean,
    expireExtendRenew: boolean,
    save: boolean = null
  ): void {
    this.permissionService.setCanSave(save);
    this.permissionService.toggleCanCreateEditDeleteSave(create, null, null);
    this.permissionService.toggleEditModeForProviderBenefitCoverageInfo(isEdit, null, null);
    if (!create) {
      this.permissionService.toggleCanAddCovOptions(true);
    }
    if (_.isBoolean(expireExtendRenew)) {
      this.permissionService.toggleCanExpireExtendRenew(expireExtendRenew, expireExtendRenew, expireExtendRenew);
    }
  }

  private async expireOrExtendProviderLine(line: BenefitDetailsLine) {
    if (_.isObjectLike(line)) {
      let benefitLines = [...this.selectedProvider.current.benefitLines];
      const index = _.findIndex(benefitLines, { id: line.id });
      if (index !== -1) {
        benefitLines.splice(index, 1, line);
        this.selectedProvider.current.benefitLines = benefitLines;
        this.commonManService.selectProvider(this.selectedProvider, false, true);
        await this.selectProviderLine(line, true);
        this.toggleActionsAndModes(true, false, false, false);
      }
    }
  }

  private async renewProviderLine(prLine: BenefitDetailsProviderLineStandartEntity) {
    if (_.isObjectLike(prLine)) {
      const benefitLines = [...this.selectedProvider.current.benefitLines];
      benefitLines.unshift(prLine.current.line);
      this.selectedProvider.current.benefitLines = benefitLines;
      this.commonManService.selectProvider(this.selectedProvider, false, true);
      await this.selectProviderLine(prLine.current.line, true);
      this.toggleActionsAndModes(true, false, false, false);
    }
  }

  private subscribeToData(): void {
    this.subscriptions.state = this.stateService.onInit$.subscribe(() => {
      const state = this.storageService.getControlState(this.componentId, this.controlId);
      const value = _.isBoolean(state.value) ? state.value : true;
      this.state$.next(value);
    });

    this.subscriptions.selectProvider = this.commonManService.subscribeToSelectProvider(
      (provider: BenefitDetailsProviderEntity) => {
        this.selectedProvider = provider;
        if (provider.requireUpdate) {
          let selectedLine = provider.current.actualOrLatestBenefitLine;
          if (_.isFinite(provider.selectedLineId)) {
            selectedLine = _.find(provider.current.benefitLines, { id: provider.selectedLineId }) || selectedLine;
          }
          this.selectProviderLine(selectedLine, true);
        }
      }
    );

    this.subscriptions.selectProviderLine = this.commonManService.subscribeToSelectProviderLine(
      (pr: BenefitDetailsProviderLineStandartEntity) => {
        this.selectedProviderLine = pr;
        const hasSelectedTier = _.isFinite(pr.selectedTierId);
        let tier: BenefitDetailsTier = null;
        let calcMethod: BenefitDetailsCalcMethod = null;
        if (pr.isDraft) {
          tier = this.createBenefitProviderTier(
            pr.current.line.startDate,
            pr.current.line.endDate,
            _.size(pr.current.tiers)
          );
          pr.current.tiers.push(tier);
        } else {
          tier = _.head(pr.current.tiers);
          if (hasSelectedTier) {
            const t = _.find(pr.current.tiers, { id: pr.selectedTierId });
            if (_.isObjectLike(t) && !t.isDraft) tier = t;
          }
          calcMethod = pr.current.calcMethod;
        }
        this.detectProviderPlanDate(pr.current.line);

        this.selectProviderTiers(pr.current.tiers);
        this.selectProviderTier(tier, !pr.isDraft && hasSelectedTier);
        this.changeCalcMethod(calcMethod);
      }
    );

    this.subscriptions.createNewProvider = this.commonManService.subscribeToCreateNewProvider(() => {
      Observable.of(true)
        .delay(250)
        .subscribe(() => this.createDraftProvider());
    });

    this.subscriptions.expireExtendRenew = this.commonManService.subscribeToExpireExtendRenew(
      (b: ExtendExpireRenewModel) => {
        if (b.isRenew) {
          this.renewProviderLine(b.benefitLineStandart);
        } else {
          this.expireOrExtendProviderLine(b.benefitLine);
        }
      }
    );

    this.subscriptions.orgLevelChanged = this.commonManService.subscribeToOrgLevel((v: OrgLevel) => {
      const editMode = this.permissionService.getEditMode();
      const isEditmode = editMode.providerInfo || editMode.benefitInfo || editMode.coverageOptions;
      if (isEditmode) {
        this.cancelEditingProvider();
      }
    });
  }

  public detectProviderPlanDate(line: BenefitDetailsLine) {
    let providerPlanDate: Date = null;
    const today = moment().startOf('day');
    const { startOfStartDate, startOfEndDate } = line;
    const hasDates = _.isDate(startOfStartDate) && _.isDate(startOfEndDate);
    if (!hasDates || (hasDates && today.isBetween(startOfStartDate, startOfEndDate))) {
      providerPlanDate = today.toDate();
    } else {
      providerPlanDate = startOfStartDate;
    }
    this.changeProviderPlanDate(providerPlanDate);
  }

  private hasId(value: any): boolean {
    return _.isObjectLike(value) && !_.isNil(value.id);
  }

  private hasName(value: any): boolean {
    return _.isObjectLike(value) && _.size(value.name) > 0;
  }

  private isValidDate(date: Date): boolean {
    return _.isDate(date) && dateTimeUtils.isInValidPeriod(moment(date).startOf('day').toDate());
  }

}
