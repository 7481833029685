import * as _ from 'lodash';
var SoDashboardCounters = /** @class */ (function () {
    function SoDashboardCounters(counters) {
        this.openShiftRequestCount = _.get(counters, 'openShiftRequestCount', 0);
        this.employeeProjectedOvertimeCount = _.get(counters, 'employeeProjectedOvertimeCount', 0);
        this.ptoRequestCount = _.get(counters, 'ptoRequestCount', 0);
    }
    Object.defineProperty(SoDashboardCounters.prototype, "openShiftRequest", {
        get: function () {
            return _.isNumber(this.openShiftRequestCount) ? this.openShiftRequestCount : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SoDashboardCounters.prototype, "employeeProjectedOvertime", {
        get: function () {
            return _.isNumber(this.employeeProjectedOvertimeCount) ? this.employeeProjectedOvertimeCount : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SoDashboardCounters.prototype, "ptoRequest", {
        get: function () {
            return _.isNumber(this.ptoRequestCount) ? this.ptoRequestCount : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SoDashboardCounters.prototype, "total", {
        get: function () {
            return this.openShiftRequest + this.employeeProjectedOvertime + this.ptoRequest;
        },
        enumerable: true,
        configurable: true
    });
    return SoDashboardCounters;
}());
export { SoDashboardCounters };
var TaDashboardCounters = /** @class */ (function () {
    function TaDashboardCounters(counters) {
        this.missingPunchCount = _.get(counters, 'missingPunchCount', 0);
        this.overtimeApprovalCount = _.get(counters, 'overtimeApprovalCount', 0);
        this.timecardExceptionCount = _.get(counters, 'timecardExceptionCount', 0);
        this.punchRequestCount = _.get(counters, 'punchRequestCount', 0);
    }
    Object.defineProperty(TaDashboardCounters.prototype, "missingPunch", {
        get: function () {
            return _.isNumber(this.missingPunchCount) ? this.missingPunchCount : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaDashboardCounters.prototype, "overtimeApproval", {
        get: function () {
            return _.isNumber(this.overtimeApprovalCount) ? this.overtimeApprovalCount : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaDashboardCounters.prototype, "timecardException", {
        get: function () {
            return _.isNumber(this.timecardExceptionCount) ? this.timecardExceptionCount : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaDashboardCounters.prototype, "punchRequest", {
        get: function () {
            return _.isNumber(this.punchRequestCount) ? this.punchRequestCount : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaDashboardCounters.prototype, "total", {
        get: function () {
            return this.missingPunch + this.overtimeApproval + this.timecardException + this.punchRequestCount;
        },
        enumerable: true,
        configurable: true
    });
    return TaDashboardCounters;
}());
export { TaDashboardCounters };
