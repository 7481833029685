import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/combineLatest';
import { ManagementBaseService } from '../../../core/services/index';
import { ChangeManagementService } from '../../../common/services/index';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { OrgLevel, User } from '../../../state-model/models/index';
import { PayCodesContainer } from '../../models/index';
import { PayCodesApiService } from './pay-codes-api.service';
import { AccessManagementService } from '../accessManagement/access-management.service';
import { PayCodesMapService } from './pay-codes-map.service';
import { Assert } from '../../../../app/framework';
import { NotificationsService } from '../../../core/components/angular2-notifications/simple-notifications/services/notifications.service';
import { PaycodeExceptionSortOverrideMapService } from './paycode-exception-sortoverride-map.service';
//import { ModalService, KendoGridStateHelper, KendoGridCustomSelectionHelper, ConfirmDialogComponent, StateManagementService, ColumnManagementService, ConfirmOptions, ColumnsChangedEvent } from '../../../common/index';
var PayCodesConfigurationManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(PayCodesConfigurationManagementService, _super);
    function PayCodesConfigurationManagementService(access, changeService, api, map, notificationsService, exceptionsortoverrideMap) {
        var _this = _super.call(this) || this;
        _this.access = access;
        _this.changeService = changeService;
        _this.api = api;
        _this.map = map;
        _this.notificationsService = notificationsService;
        _this.exceptionsortoverrideMap = exceptionsortoverrideMap;
        _this.isDirtySortOrder = false;
        _this.exportTo$ = new Subject();
        _this.removeItemsCmd$ = new ReplaySubject();
        _this.addItemCmd$ = new ReplaySubject();
        _this.editItemCmd$ = new ReplaySubject();
        _this.onItemSaved$ = new ReplaySubject();
        _this.viewRefresh$ = new Subject();
        _this.bulkSaveResponse$ = new Subject();
        return _this;
        //this.gridState = new KendoGridStateHelper<PayCodeModel>();
        //this.gridState.state.skip = 0;
    }
    Object.defineProperty(PayCodesConfigurationManagementService.prototype, "container", {
        get: function () {
            return this.m_container;
        },
        enumerable: true,
        configurable: true
    });
    PayCodesConfigurationManagementService.prototype.init = function () {
        var _this = this;
        this.access.allowCorporationLevel = false;
        this.access.allowOrganizationLevel = true;
        this.access.allowDepartmentLevel = true;
        this.appDataSubscription = this.orgLevel$.combineLatest(this.user$).subscribe(function (value) {
            var orgLevel = value[0], user = value[1];
            if (!orgLevel || !_.isNumber(orgLevel.id) || !user) {
                return;
            }
            _this.currentOrgLevel = orgLevel;
            _this.currentUser = user;
            _this.access.orgLevelType = _this.currentOrgLevel.type;
            if (_this.m_container) {
                _this.m_container.records = [];
                _this.m_container.exceptionsSortOverrides = [];
                _this.onLoaded$.next(_this.m_container);
            }
            _this.onStateChanged$.next({ orgLevelChanged: true, bulkEditMode: false, canBulkEdit: false });
            _this.fetchRecords(_this.currentOrgLevel.id, _this.currentUser);
        });
    };
    PayCodesConfigurationManagementService.prototype.markAsDirty = function () {
        this.changeService.changeNotify();
    };
    //#region IConfigurationManagementService implementation
    // no add or remove actions for this component at current state
    PayCodesConfigurationManagementService.prototype.onRemoveItem = function (itemToDelete) {
        _.noop();
    };
    PayCodesConfigurationManagementService.prototype.onAddItem = function (item) {
        _.noop();
    };
    PayCodesConfigurationManagementService.prototype.setSelectedCount = function (count) {
        this.access.selectedItemsCount = count;
    };
    PayCodesConfigurationManagementService.prototype.onEditItem = function (item) {
        this.editingItem = item;
        this.editItemCmd$.next(item);
    };
    PayCodesConfigurationManagementService.prototype.onCancelEditItem = function () {
        this.changeService.clearChanges();
        this.editingItem = null;
        this.editItemCmd$.next(null);
    };
    PayCodesConfigurationManagementService.prototype.onSaveItem = function (info) {
        this.updateItem(info.dataItem);
    };
    //#endregion IConfigurationManagementService implementation
    //#region bulk actions
    PayCodesConfigurationManagementService.prototype.setItemDirty = function (item) {
        item.isDirty = true;
        this.changeService.changeNotify();
    };
    PayCodesConfigurationManagementService.prototype.setSortOrderDirty = function () {
        this.isDirtySortOrder = true;
    };
    PayCodesConfigurationManagementService.prototype.doBulkEdit = function () {
        this.originalCollection = this.map.clonePayCodesCollection(this.m_container.records);
        this.onStateChanged$.next({ bulkEditMode: true });
    };
    PayCodesConfigurationManagementService.prototype.doBulkSave = function (paycodeSortOrderExceptions) {
        var _this = this;
        this.onStateChanged$.next({ isLoading: true });
        var dirtyRecords = [];
        var dirtyexceptionSortOrdersRecords = [];
        _.each(this.m_container.records, function (model) {
            if (model.isDirty) {
                dirtyRecords.push(model);
            }
        });
        if (this.isDirtySortOrder) {
            dirtyexceptionSortOrdersRecords = paycodeSortOrderExceptions;
        }
        if (dirtyRecords.length > 0) {
            this.api.savePayCodes(dirtyRecords, this.currentOrgLevel.id)
                .then(function () {
                _this.originalCollection = null;
                _this.updateDirtyRecords();
                _this.bulkSaveResponse$.next(true);
                _this.notificationsService.success('Success', 'The Changes are updated to Pay Codes Configuration successful');
                _this.changeService.clearChanges();
                _this.viewRefresh$.next(false);
                _this.onStateChanged$.next({ isLoading: false, bulkEditMode: false });
            }).catch(function () {
                _this.viewRefresh$.next(false);
                _this.onStateChanged$.next({ isLoading: false });
            });
        }
        else {
            this.onStateChanged$.next({ isLoading: false, bulkEditMode: false });
        }
        if (dirtyexceptionSortOrdersRecords.length > 0) {
            this.api.savePaycodeExceptionSortOverrides(dirtyexceptionSortOrdersRecords)
                .then(function () {
                _this.updateSortOrderDirtyRecords();
                _this.notificationsService.success('Success', 'The SortOrder is updated to Pay Codes Configuration successful');
                _this.changeService.clearChanges();
                _this.viewRefresh$.next(false);
                _this.onStateChanged$.next({ isLoading: false, bulkEditMode: false });
                //this.gridState.view.data = [...this.gridState.view.data];
                //window.location.reload();
            }).catch(function () {
                _this.viewRefresh$.next(false);
                _this.onStateChanged$.next({ isLoading: false });
            });
        }
        else {
            this.onStateChanged$.next({ isLoading: false, bulkEditMode: false });
        }
        //this.gridState.view.data = [...this.gridState.view.data];
    };
    PayCodesConfigurationManagementService.prototype.doBulkDiscard = function () {
        this.m_container.records = this.originalCollection;
        this.changeService.clearChanges();
        this.onStateChanged$.next({ bulkEditMode: false });
        this.onLoaded$.next(this.m_container);
    };
    PayCodesConfigurationManagementService.prototype.updateDirtyRecords = function () {
        _.each(this.m_container.records, function (model) {
            if (!model.payCode.isUsedInAccrualCalc) {
                model.payCode.accrualPolicyList = [];
                model.payCode.accrualPolicyNames = '';
            }
            else if (model.payCode.isUsedInAccrualCalc && (_.isNull(model.payCode.accrualPolicyList) || (model.payCode.accrualPolicyList && model.payCode.accrualPolicyList.length == 0))) {
                model.payCode.accrualPolicyNames = 'All';
            }
            if (model.isDirty)
                model.isDirty = false;
        });
    };
    PayCodesConfigurationManagementService.prototype.updateSortOrderDirtyRecords = function () {
        var _this = this;
        _.each(this.m_container.exceptionsSortOverrides, function (model) {
            if (_this.isDirtySortOrder)
                _this.isDirtySortOrder = false;
        });
    };
    //#endregion bulk actions
    PayCodesConfigurationManagementService.prototype.fetchRecords = function (orgLevelId, user) {
        var _this = this;
        this.onStateChanged$.next({ isLoading: true });
        this.access.lockActions = true;
        this.api.getPayCodes(orgLevelId).
            then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var dirtySortOrdersRecords, dirtyExceptions, state;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.access.lockActions = false;
                        this.m_container = result;
                        dirtySortOrdersRecords = [];
                        dirtyExceptions = [];
                        if (!user.isSmartAdmin) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fetchSortPaycodeExceptionSortOverrides()];
                    case 1:
                        dirtySortOrdersRecords = _a.sent();
                        _a.label = 2;
                    case 2:
                        dirtyExceptions = this.m_container.records;
                        if (dirtySortOrdersRecords !== undefined && dirtySortOrdersRecords !== null) {
                            this.m_container.sortRecordsWithOverrides(dirtyExceptions, dirtySortOrdersRecords);
                        }
                        this.editingItem = null;
                        this.isEditingNewItem = false;
                        if (this.m_container.actions)
                            this.access.actions = this.m_container.actions;
                        this.onLoaded$.next(this.m_container);
                        state = {
                            isLoading: false,
                            bulkEditMode: false,
                            canBulkEdit: this.m_container.actions.canEdit || this.m_container.canEditExceptionFlag || this.m_container.canEditPaycodestoAccrualMappingFlag,
                            canEditPBJFlag: this.m_container.actions.canEdit,
                            canEditUseInTimesheets: this.m_container.actions.canEdit,
                            canEditExceptionFlag: this.m_container.canEditExceptionFlag,
                            canEditAca: this.m_container.actions.canEdit,
                            canEditPaycodestoAccrualMappingFlag: this.m_container.canEditPaycodestoAccrualMappingFlag,
                            canEdit: false,
                            canAdd: false,
                            canDelete: false,
                            isSmartAdmin: user.isSmartAdmin
                        };
                        this.onStateChanged$.next(state);
                        return [2 /*return*/];
                }
            });
        }); }).catch(function (e) {
            _this.m_container = new PayCodesContainer();
            _this.m_container.records = [];
            _this.onLoaded$.next(_this.m_container);
            _this.access.lockActions = false;
            _this.onStateChanged$.next({ isLoading: false });
        });
    };
    PayCodesConfigurationManagementService.prototype.fetchSortPaycodeExceptionSortOverrides = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var overrides, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.fetchPaycodeExceptionSortOverrides()];
                    case 1:
                        overrides = _a.sent();
                        this.m_container.exceptionsSortOverrides = overrides;
                        return [2 /*return*/, overrides];
                    case 2:
                        error_1 = _a.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PayCodesConfigurationManagementService.prototype.updateItem = function (item) {
        var _this = this;
        this.onStateChanged$.next({ isLoading: true });
        this.api.savePayCode(item)
            .then(function () {
            _this.onItemSaved$.next(_this.editingItem);
            _this.editingItem = null;
            _this.viewRefresh$.next(false);
            _this.onStateChanged$.next({ isLoading: false });
            _this.changeService.clearChanges();
        }).catch(function () {
            _this.viewRefresh$.next(false);
            _this.onStateChanged$.next({ isLoading: false });
        });
    };
    PayCodesConfigurationManagementService.prototype.updatePayCodeModel = function (from, to) {
        to.payCode.id = from.payCode.id;
        to.payCode.description = from.payCode.description;
        to.payCode.allocationType = from.payCode.allocationType;
        to.payCode.amount = from.payCode.amount;
        to.payCode.color = from.payCode.color;
        to.payCode.department = from.payCode.department;
        to.payCode.description = from.payCode.description;
        to.payCode.group = from.payCode.group;
        to.payCode.interfaceIndicator = from.payCode.interfaceIndicator;
        to.payCode.isAca = from.payCode.isAca;
        to.payCode.isPaid = from.payCode.isPaid;
        to.payCode.isPbj = from.payCode.isPbj;
        to.payCode.payDifferential = from.payCode.payDifferential;
        to.payCode.isException = from.payCode.isException;
        to.lastUpdateDate = from.lastUpdateDate;
        to.lastUpdateUsername = from.lastUpdateUsername;
    };
    PayCodesConfigurationManagementService.prototype.exportTo = function (isPDF) {
        this.exportTo$.next(isPDF);
    };
    PayCodesConfigurationManagementService.prototype.subscribeToExport = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.exportTo$.subscribe(callback);
    };
    PayCodesConfigurationManagementService.prototype.subscribeToBulkSaveResponse = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.bulkSaveResponse$.subscribe(callback);
    };
    PayCodesConfigurationManagementService.prototype.getAccrualPolicies = function (orglevelId) {
        return this.api.getAccrualPolicy(orglevelId);
    };
    PayCodesConfigurationManagementService.prototype.getAccrualTypes = function (orglevelId) {
        return this.api.getAccrualType(orglevelId);
    };
    PayCodesConfigurationManagementService.prototype.fetchPaycodeExceptionSortOverrides = function () {
        return this.api.getPaycodeExceptionSortOverrides();
    };
    PayCodesConfigurationManagementService.prototype.savePaycodeExceptionSortOverride = function (data) {
        var _this = this;
        return this.api.savePaycodeExceptionSortOverrides(data).then(function () {
            _this.notificationsService.success('Success', 'Paycode exception sort override saved successfully');
        }).catch(function (error) {
            console.error('Error saving paycode exception sort override:', error);
            throw error;
        });
    };
    PayCodesConfigurationManagementService.prototype.deletePaycodeExceptionSortOverride = function (id) {
        var _this = this;
        this.api.deletePaycodeExceptionSortOverride(id).then(function () {
            _this.notificationsService.success('Success', 'Paycode exception sort override deleted successfully');
        }).catch(function (error) {
            console.error('Error deleting paycode exception sort override:', error);
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], PayCodesConfigurationManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        mutableSelect(['session', 'user']),
        tslib_1.__metadata("design:type", Observable)
    ], PayCodesConfigurationManagementService.prototype, "user$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], PayCodesConfigurationManagementService.prototype, "appDataSubscription", void 0);
    return PayCodesConfigurationManagementService;
}(ManagementBaseService));
export { PayCodesConfigurationManagementService };
