<div class="benefit-info flex justify-content__space-between">
  <section class="slx-benefit-box benefit flex__grow">
    <h5 class="slx-benefit-box__hd flex align-items__center">
      <i aria-hidden="true" class="slx-benefit-box__icon fas fa-info"></i>
      <span class="slx-benefit-box__text">Benefits Info</span>
    </h5>
    <div class="slx-benefit-box__body">
      <div class="slx-benefit-box__control flex align-items__center">
        <p class="slx-benefit-box__label benefit__label">Benefit Name</p>
        <p class="slx-benefit-box__value flex__grow">
          <input
            #benefitNameM="ngModel"
            type="text"
            class="slx-benefit-box__text-field"
            [(ngModel)]="benefitName"
            (ngModelChange)="onChangeTierName(benefitNameM)"
            [disabled]="!isEditMode"
            name="benefitName"
            maxlength="25"
            required
          />
          <span *ngIf="benefitNameM?.errors || !isValidProviderTierName" class="slx-error-block error-box">
            <span *ngIf="benefitNameM?.errors?.required" errorMessage for="required"></span>
            <span *ngIf="!isValidProviderTierName" errorMessage
              >Same tier name exists. Please choose a different name</span
            >
          </span>
        </p>
      </div>
      <div class="slx-benefit-box__control flex align-items__center">
        <p class="slx-benefit-box__label benefit__label">Plan ID</p>
        <p class="slx-benefit-box__value flex__grow">
          <input
            type="text"
            class="slx-benefit-box__text-field"
            [(ngModel)]="benefitPlanId"
            (ngModelChange)="onChangeTierPlanId()"
            [disabled]="!isEditMode"
            maxlength="25"
            name="benefitPlanId"
          />
        </p>
      </div>
      <div class="slx-benefit-box__control flex align-items__center">
        <p class="slx-benefit-box__label benefit__label">EE Code Type</p>
        <p class="slx-benefit-box__value flex__grow">
        <slx-radio-button class="deduction__radio"
          caption="Deduction"
          name="eECodeType" 
          fieldName="eECodeType"
          [option]="false"  
          [(ngModel)]="isEmployeeEarningCode"
          (ngModelChange)="onChangeEECodeType()"
          [readonly]="!isEditMode"
        ></slx-radio-button>
        <slx-radio-button class="er__radio"
          caption="Earnings"
          name="eECodeType" 
          fieldName="eECodeType"
          [option]="true"  
          [(ngModel)]="isEmployeeEarningCode"
          (ngModelChange)="onChangeEECodeType()"
          [readonly]="!isEditMode"
        ></slx-radio-button>
        </p>
      </div>

      <div class="slx-benefit-box__control flex align-items__center">
        <p class="slx-benefit-box__label benefit__label">EE Code</p>
        <p class="slx-benefit-box__value flex__grow">
            <input 
            #benefitPayrollDeductionCodeMD="ngModel"
              type="text"
              class="slx-benefit-box__text-field"
              [(ngModel)]="benefitPayrollDeductionCode"
              (input)="onSearch(benefitPayrollDeductionCodeMD)"
              [disabled]="!isEditMode"
              [pattern]="commonConfig.deductionCodePattern"
              maxlength="10"
              [slxMaxLength]="10"
              slxChangeText
              name="benefitPayrollDeductionCode"
              autocomplete="off"
            />
            <span *ngIf="isEditMode && (benefitPayrollDeductionCodeMD?.errors || manService.duplicateValue || !manService.providerTierEECodeIsUnique)"  class="slx-error-block error-box">
              <span *ngIf="benefitPayrollDeductionCodeMD?.errors?.pattern" errorMessage for="pattern">Not allowed "\" or "^"</span>
              <span *ngIf="benefitPayrollDeductionCodeMD?.errors?.slxMaxLength" errorMessage>Max length is 10 characters</span>
            
            <span *ngIf="manService.duplicateValue && manService.eeValidationMessage" class="slx-error-block error-box">
              <span errorMessage for="uniqueName">{{manService.eeValidationMessage}}</span>
            </span>
            <span *ngIf="!manService.duplicateValue && !manService.providerTierEECodeIsUnique" class="slx-error-block error-box">
              <span errorMessage for="uniqueName">{{manService.eEErrorMessage}}</span>
            </span>
          </span>
      </div>
      <div class="slx-benefit-box__control flex align-items__center">
        <p class="slx-benefit-box__label benefit__label">ER Code Type</p>
        <p class="slx-benefit-box__value flex__grow">
          <slx-radio-button class="deduction__radio"
          caption="Deduction"
          name="eRCodeType" 
          fieldName="eRCodeType"
          [option]="false"  
          [(ngModel)]="isEmployerEarningCode"
          (ngModelChange)="onChangeERCodeType()"
          [readonly]="!isEditMode"
        ></slx-radio-button>
        <slx-radio-button class="er__radio"
          caption="Earnings"
          name="eRCodeType" 
          fieldName="eRCodeType"
          [option]="true"  
          [(ngModel)]="isEmployerEarningCode"
          (ngModelChange)="onChangeERCodeType()"
          [readonly]="!isEditMode"
        ></slx-radio-button>
      </p>
      </div>
      <div class="slx-benefit-box__control flex align-items__center">
        <p class="slx-benefit-box__label benefit__label">ER Code</p>
        <p class="slx-benefit-box__value flex__grow">
            <input 
            #eRDeductionCode="ngModel"
              type="text"
              class="slx-benefit-box__text-field"
              [(ngModel)]="employerDeductionCode"
              (input)="onERDeductionCodeChange(eRDeductionCode)"
              [disabled]="!isEditMode"
              [pattern]="commonConfig.deductionCodePattern"
              maxlength="10"
              [slxMaxLength]="10"
              slxChangeText
              name="employerDeductionCode"
              autocomplete="off"
            />
            <span *ngIf="isEditMode && (eRDeductionCode?.errors || manService.isDuplicateERDeductionCode || !manService.providerTierERCodeIsUnique)"  class="slx-error-block error-box">
            <span *ngIf="eRDeductionCode?.errors?.pattern" errorMessage for="pattern">Not allowed "\" or "^"</span>
            <span *ngIf="eRDeductionCode?.errors?.slxMaxLength" errorMessage>Max length is 10 characters</span>
            <span *ngIf="manService.isDuplicateERDeductionCode && this.manService.erValidationMessage" class="slx-error-block error-box">
              <span errorMessage for="uniqueName">{{manService.erValidationMessage}}</span>
            </span>
            <span *ngIf="!manService.isDuplicateERDeductionCode && !manService.providerTierERCodeIsUnique" class="slx-error-block error-box">
              <span errorMessage for="uniqueName">{{manService.eRErrorMessage}}</span>
            </span>
          </span>
        </p>
       
      </div>
      <div class="slx-benefit-box__control flex align-items__center">
        <p class="slx-benefit-box__label benefit__label">High Deductible Tier</p>
        <p class="slx-benefit-box__value flex__grow">
          <slx-checkbox-button
            class="slx-no-indents"
            [(ngModel)]="highDeductible"
            (ngModelChange)="onChangeTierDeductible()"
            [readonly]="!isEditMode"
          ></slx-checkbox-button>
        </p>
      </div>
      <div class="slx-benefit-box__control flex align-items__center">
        <p class="slx-benefit-box__label benefit__label">Beneficiaries</p>
        <p class="slx-benefit-box__value flex__grow">
          <slx-checkbox-button
            class="slx-no-indents"
            [(ngModel)]="hasBeneficiaries"
            (ngModelChange)="onChangeTierBeneficiaries()"
            [readonly]="!isEditMode"
          ></slx-checkbox-button>
        </p>
      </div>
      <div class="slx-benefit-box__control flex align-items__center">
        <p class="slx-benefit-box__label benefit__label label-text">Notes</p>
        <p class="slx-benefit-box__value flex__grow">
          <textarea
            class="slx-benefit-box__text-field textarea"
            [(ngModel)]="benefitNote"
            (ngModelChange)="onChangeTierNote()"
            [disabled]="!isEditMode"
            placeholder="Type your notes here..."
            maxlength="255"
          ></textarea>
        </p>
      </div>
      <div>
        <div class="flex">
          <button
            type="button"
            (click)="showEligibilityRuleDialog()"
            [disabled]="!isEditMode"
            class="slx-button slx-blue slx-with-icon slx-margin-r slx-mobile-adapted"
          >
            <i aria-hidden="true" class="fas fa-plus slx-button__icon"></i>
            <span class="slx-button__text">Add Eligibility Rule</span>
          </button>
          <slx-dropdown-button
            class="slx-wide small"
            title="Add Existing Rule"
            [data]="availableExistingBenefitRules"
            textField="name"
            [disabled]="!isEditMode"
            [valuePrimitive]="false"
            icon="fa fa-caret-down"
            (onItemClick)="addExistigBenefitEligibilityRule($event)"
          ></slx-dropdown-button>
        </div>
        <slx-benefit-details-eligibility-rules-list
          [benefitEligibilityRules]="benefitEligibilityRules"
          [benefitName]="benefitName"
          [isEditMode]="isEditMode"
          [isLoading]="isLoading"
          [orgLevelId]="orgLevelId"
          [canDelete]="canDelete"
          [benefitRulesAlreadyUsedNames]="benefitRulesAlreadyUsedNames"
          (benefitEligibilityRulesAdded)="benefitEligibilityRulesAdded($event)"
          (benefitEligibilityRulesUpdated)="benefitEligibilityRulesUpdated($event)"
          (benefitEligibilityRulesDeleted)="benefitEligibilityRulesDeleted($event)"
        ></slx-benefit-details-eligibility-rules-list>
      </div>
    </div>
  </section>
  <section class="slx-benefit-box employees flex__grow">
    <h5 class="slx-benefit-box__hd flex align-items__center">
      <i aria-hidden="true" class="slx-benefit-box__icon fas fa-users"></i>
      <span class="slx-benefit-box__text">Employees</span>
    </h5>
    <div class="slx-benefit-box__body">
      <div class="slx-benefit-box__control flex align-items__center">
        <p class="slx-benefit-box__label employees__label">Total Enrolled Employees</p>
        <p class="slx-benefit-box__value flex__grow">
          <a
            *ngIf="totalEnrolledEmployees != 0 && shownEmployeesCounters"
            [slxBenefitEnrolledEmployeesModal]="enrolledEmpData"
            (refreshEmployees)="onRefreshEmployees($event)"
            >{{ totalEnrolledEmployees }}</a
          >
          <span *ngIf="totalEnrolledEmployees == 0 && !isLoadingEnrolledEmployees && shownEmployeesCounters">{{
            totalEnrolledEmployees
          }}</span>
          <em class="fas fa-spinner fa-spin" *ngIf="isLoadingEnrolledEmployees"></em>
          <ng-container *ngIf="!shownEmployeesCounters">
            <span class="flex-start"
              ><span [slxTooltip]="'Value available in non-edit mode'" tipPosition="left">&mdash;</span></span
            >
          </ng-container>
        </p>
      </div>
      <div class="slx-benefit-box__control flex align-items__center control-padd">
        <p class="slx-benefit-box__label employees__label">Total Eligible Not Enrolled Employees</p>
        <p class="slx-benefit-box__value flex__grow">
          <a
            *ngIf="totalEligibleEmployees && totalEligibleEmployees.count > 0 && shownEmployeesCounters"
            [slxBenefitEligibleEmployeesModal]="eligibleEmpData"
            (refreshEmployees)="onRefreshEmployees($event)"
            >{{ totalEligibleEmployees.count }}</a
          >
          <span *ngIf="totalEligibleEmployees && totalEligibleEmployees.count === 0 && !isLoadingEligibleEmployees && shownEmployeesCounters"
            class="total-eligible-employees-error"
          >{{totalEligibleEmployees.count}}
          <i *ngIf="totalEligibleEmployees.hasError" class="fas fa-exclamation-triangle text-danger ml-5" [slxTooltip]="'Error in eligibility rules'" aria-hidden="true"></i>
          </span>
          <em class="fas fa-spinner fa-spin" *ngIf="isLoadingEligibleEmployees"></em>
          <ng-container *ngIf="!shownEmployeesCounters">
            <span class="flex-start"
              ><span [slxTooltip]="'Value available in non-edit mode'" tipPosition="left">&mdash;</span></span
            >
          </ng-container>
        </p>
      </div>
      <div class="slx-benefit-box__control flex align-items__center">
        <p class="slx-benefit-box__label employees__label">Non Tobacco User only</p>
        <p class="slx-benefit-box__value flex__grow">
          <slx-checkbox-button
            class="slx-no-indents"
            [(ngModel)]="nonTobacco"
            (ngModelChange)="onChangeTierTobacco()"
            [readonly]="!isEditMode"
          ></slx-checkbox-button>
        </p>
      </div>
      <div class="slx-benefit-box__control flex align-items__center">
        <p class="slx-benefit-box__label employees__label">Domestic Partner Eligible</p>
        <p class="slx-benefit-box__value flex__grow">
          <slx-checkbox-button
            class="slx-no-indents"
            [(ngModel)]="domesticPartnerEligible"
            (ngModelChange)="onChangeTierPartner()"
            [readonly]="!isEditMode"
          ></slx-checkbox-button>
        </p>
      </div>
      <div class="slx-benefit-box__control flex align-items__center">
        <p class="slx-benefit-box__label employees__label">Company Provided</p>
        <p class="slx-benefit-box__value flex__grow">
          <slx-checkbox-button
            class="slx-no-indents"
            [(ngModel)]="companyProvided"
            (ngModelChange)="onChangeTierCompanyProvided()"
            [readonly]="!isEditMode"
          ></slx-checkbox-button>
        </p>
      </div>
    </div>
  </section>
  <section class="slx-benefit-box aca flex__grow" *ngIf="shownAcaCompliance">
    <h5 class="slx-benefit-box__hd flex align-items__center">
      <i aria-hidden="true" class="slx-benefit-box__icon fas fa-info"></i>
      <span class="slx-benefit-box__text">ACA Compliance</span>
    </h5>
    <div class="slx-benefit-box__body">
      <div class="slx-benefit-box__control flex align-items__center">
        <p class="slx-benefit-box__label aca__label">Minimum Essential Coverage</p>
        <p class="slx-benefit-box__value flex__grow">
          <slx-checkbox-button
            class="slx-no-indents"
            [(ngModel)]="acaMinCoverage"
            (ngModelChange)="onChangeTierAcaCoverage()"
            [readonly]="!isEditMode"
          ></slx-checkbox-button>
        </p>
      </div>
      <div class="slx-benefit-box__control flex align-items__center">
        <p class="slx-benefit-box__label aca__label">Minimum Value</p>
        <p class="slx-benefit-box__value flex__grow">
          <slx-checkbox-button
            class="slx-no-indents"
            [(ngModel)]="acaMinValue"
            (ngModelChange)="onChangeTierAcaValue()"
            [readonly]="!isEditMode"
          ></slx-checkbox-button>
        </p>
      </div>
      <div class="slx-benefit-box__control flex align-items__center">
        <p class="slx-benefit-box__label aca__label">Poverty Level Test</p>
        <p class="slx-benefit-box__value flex__grow">
          <slx-checkbox-button
            class="slx-no-indents"
            [(ngModel)]="acaPovLevelTest"
            (ngModelChange)="onChangeTierPoverty()"
            [readonly]="!isEditMode"
          ></slx-checkbox-button>
        </p>
      </div>
      <div class="slx-benefit-box__control flex align-items__center">
        <p class="slx-benefit-box__label aca__label">Default Safe Harbor Code</p>
        <p class="slx-benefit-box__value flex__grow">
          <slx-dropdown-list
            #cFreq="ngModel"
            class="slx-wide slx-short"
            valueField="id"
            titleField="name"
            [options]="config?.acaHarborCodes"
            [readonly]="!isEditMode"
            [(ngModel)]="acaHarborCode"
            (ngModelChange)="onChangeHarbourCode()"
          ></slx-dropdown-list>
          <span *ngIf="cFreq.errors && (cFreq.dirty || cFreq.touched)" class="slx-error-block error-box">
            <span *ngIf="cFreq.errors.required" errorMessage for="required"></span>
          </span>
        </p>
      </div>
    </div>
  </section>
  <section class="slx-benefit-box documents flex__grow">
    <h5 class="slx-benefit-box__hd flex align-items__center">
      <i aria-hidden="true" class="slx-benefit-box__icon fas fa-file-alt"></i>
      <span class="slx-benefit-box__text">Benefits Documents</span>
      <i aria-hidden="true" class="slx-benefit-box__icon count-style">{{ fileLength }}</i>
    </h5>
    <div class="slx-benefit-box__body">
      <div class="slx-benefit-box__control flex align-items__center">
        <slx-attachments
          class="documents__attachments"
          [disabled]="!isEditMode"
          [attachments]="attachments"
          [filesToAttach]="filesToAttach"
          [maxFiles]="maxFiles"
          [showFileTypeAsTooltip]="true"
          [maxFileSizeBytes]="maxFileSizeBytes"
          [acceptedFileTypes]="acceptedFileTypes"
          (addedFiles)="onAddedFiles($event)"
          (deletedFile)="onDeletedFile($event)"
          (deletedAttachment)="onDeletedAttachment($event)"
          (downloadAttachment)="onDownloadAttachment($event)"
        ></slx-attachments>
      </div>
    </div>
  </section>
</div>
