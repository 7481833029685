import { Injectable } from '@angular/core';
import {
  EmpType, IEmpType,
  BudgetedPosition, IBudgetedPosition,
  BudgetedGroup, IBudgetedGroup,
  State, IState,
  Position, IPosition,
  AccrualPolicy, IAccrualPolicy,
  Acuity, IAcuity,
  CostCenter, ICostCenter,
  PayPolicy, IPayPolicy,
  ShiftDiffPolicy, IShiftDiffPolicy,
  PayType, IPayType,
  PayCyclePeriod, IPayCyclePeriod,
  ExemptStatus, IExemptStatus,
  Organization, IOrganization,
  Department, IDepartment,
  AttendancePointsDefinition, IAttendancePointsDefinition, AttendancePointsCategory,
  ShiftDefinition, IShiftDefinition,
  LocationUnit, ILocationUnit,
  ILookupEntity, LookupEntity,
  ConstraintDefinition, IConstraintDefinition,
  IGender, Gender,
  IMaritalStatus, MaritalStatus,
  IRace, Race,
  IShift, Shift,
  IScheduleAbsence, ScheduleAbsence,
  ISchedulePartialAbsence, SchedulePartialAbsence,
  IScheduleCycle, ScheduleCycle, ISearchCategory, SearchCategory,
  IEmployeeTerminationReason, EmployeeTerminationReason,
  IPayCycle, PayCycle,
  IBudgetDefinition, BudgetDefinition,
  IAvailabilityDateRange, AvailabilityDateRange,
  IPbjPosition, PbjPosition,
  ITimeclockDefinition, TimeclockDefinition,
  ITimeclockRestrictionDefinition, TimeclockRestrictionDefinition,
  ITimeclockModelDefinition, TimeclockModelDefinition,
  ITimezoneDefinition, TimezoneDefinition,
  ShiftGroupDefinition, IShiftGroupDefinition,
  PayRule, IPayRule,
  PunchType, IPunchType,
  ITaAbsenceCode, TaAbsenceCode,
  RoleDefinition, IRoleDefinition,
  UserProfileDefinition, IUserProfileDefinition,
  SupervisorGroupDefinition, ISupervisorGroupDefinition,
  Employee, IEmployee, IEmployeeDefinition, EmployeeDefinition, AgencyDefinition, IAgencyDefinition, Corporation, ICorporation,
  TimecardPredefinedComment, ITimecardPredefinedComment, ITAAbsence, TAAbsence, Company, ICompany,
  IEssEntity, EssEntity, IEmployeeDocumentCategory, EmployeeDocumentCategory,
  IBenefitClassDefinition, BenefitClassDefinition,
  IVeteranStatus, VeteranStatus,
  DisabilityStatus, IPositionGroup, PositionGroup,
  EstablishmentType, IEstablishmentType,
  IPerformanceReviewCode, PerformanceReviewCode, IPerformanceReviewCodeType, PerformanceReviewCodeType,
  IPmTemplate, PmTemplate, IPmTemplateDefinition, PmTemplateDefinition, IPmAttachment, PmAttachment,
  BenefitGroup, IBenefitGroup, IObjAcc, ObjAcc, AcaCategory, IAcaCategory,AcaOfferOfCoverage,IAcaOfferOfCoverage,AcaReason,IAcaReason,AcaSafeHarbor,IAcaSafeHarbor, IAcaEmployers, AcaEmployers,
  IPbjActualPosition, PbjActualPosition,
  IPayUnitDefinition, PayUnitDefinition,
  ITimeclockSlateProfileDefinition, TimeclockSlateProfileDefinition, IncreaseReason, IIncreaseReason, LicenseType, ILicenseType, Supervisor, ISupervisor, IAttestationType, AttestationType, IRestrictedAnswer, RestrictedAnswer,NotifyData, INotifyData
} from '../../models/index';
import { Assert } from '../../../framework/index';
import { appConfig } from '../../../app.config';
import * as _ from 'lodash';
import * as moment from 'moment';
import { dateTimeUtils } from '../../../common/utils/index';
import { PayCode } from '../../../configuration/models/index';
import { WcCodeDefinition, IWcCodeDefinitionDto } from '../../../app-modules/workers-compensation/models/index';
import { IOrganizationTimezone, OrganizationTimezone } from '../../models/lookup/organization-timezone';

@Injectable()
export class LookupMapService {

  public mapEmpTypes(data: IEmpType[]): EmpType[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');
    let roots: EmpType[] = [];
    _.forEach(data, (item: IEmpType) => {
      let type: EmpType = this.mapEmpType(item);
      roots.push(type);
    });
    return roots;
  }

  public mapEmpType(data: IEmpType): EmpType {
    let type: EmpType = new EmpType();
    type.name = _.trim(data.name);
    type.description = data.description;
    type.fullTimeIndex = data.fullTimeIndex;
    return type;
  }

  public mapScheduleCycles(data: IScheduleCycle[]): ScheduleCycle[] {

    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');

    let roots: ScheduleCycle[] = [];
    let ind = 0;
    _.forEach(data, (item: IScheduleCycle) => {
      let type: ScheduleCycle = this.mapScheduleCycle(item, ind);
      roots.push(type);
      ind++;
    });
    return roots;
  }

  public mapScheduleCycle(data: IScheduleCycle, ind: number): ScheduleCycle {
    let cycle: ScheduleCycle = new ScheduleCycle();
    cycle.departmentId = data.departmentId;
    cycle.endDate = moment(data.endDate);
    cycle.startDate = moment(data.startDate);
    cycle.id = ind;
    cycle.name = '';
    return cycle;
  }

  public mapShiftGroupsDefinition(data: IShiftGroupDefinition[]): ShiftGroupDefinition[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');

    let roots: ShiftGroupDefinition[] = [];
    _.forEach(data, (item: IShiftGroupDefinition) => {
      let schiftGroup: ShiftGroupDefinition = this.mapShiftGroupDefinition(item);
      roots.push(schiftGroup);
    });
    return roots;
  }

  public mapShiftGroupDefinition(data: IShiftGroupDefinition): ShiftGroupDefinition {
    return new ShiftGroupDefinition(data.id, data.name, data.startTime, data.endTime);
  }

  public reverseShiftGroupDefinition(data: IShiftGroupDefinition): IShiftGroupDefinition {
    return {
      id: data.id,
      name: data.name,
      startTime: data.startTime ? data.startTime.format(appConfig.dateFormat) : null,
      endTime: data.endTime ? data.endTime.format(appConfig.dateFormat) : null,
    };
  }

  public mapShifts(data: IShift[]): Shift[] {

    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');


    let roots: Shift[] = [];
    _.forEach(data, (item: IShift) => {
      let type: Shift = this.mapShift(item);
      roots.push(type);
    });
    return roots;

  }

  public mapEmployees(data: IEmployee[]): Employee[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');

    let roots: Employee[] = [];
    _.forEach(data, (item: IEmployee) => {
      let emp: Employee = this.mapEmployee(item);
      roots.push(emp);
    });
    return roots;
  }

  public mapSupervisors(data: ISupervisor[]): Supervisor[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');

    let roots: Supervisor[] = [];
    _.forEach(data, (item: ISupervisor) => {
      let emp: Supervisor = this.mapSupervisor(item);
      roots.push(emp);
    });
    return roots;
  }

  public mapEmployeesDefinitions(data: IEmployeeDefinition[]): EmployeeDefinition[] {
    Assert.isNotNull(data, 'Nothing to map: data');

    let roots: EmployeeDefinition[] = [];
    _.forEach(data, (item: IEmployeeDefinition) => {
      let emp: EmployeeDefinition = this.mapEmployeeDefinition(item);
      roots.push(emp);
    });

    return roots;
  }

  public mapEmployee(data: IEmployee): Employee {
    let emp: Employee = new Employee();
    emp.id = data.id;
    emp.masterId = data.masterId;
    emp.name = data.fullName;
    emp.badgeId = data.badgeId;
    emp.type = data.type;
    emp.dateHired = dateTimeUtils.convertFromDtoString(data.dateHired);
    emp.terminationDate = dateTimeUtils.convertFromDtoString(data.terminationDate);
    emp.payrollNumber = data.payrollNumber;
    if (data.position) emp.position = this.mapPosition(data.position);
    if (data.department) emp.department = this.mapDepartment(data.department);
    if (data.organization) emp.organization = this.mapOrganization(data.organization);
    return emp;
  }

  public mapSupervisor(data: ISupervisor): Supervisor {
    if (!data) {
      return null;
    }
    let supervisor: ISupervisor = {
      id: data.id,
      name: data.name,
      isActive: data.isActive,
      isDefault: data.isDefault
    };
    return supervisor;
  }
  
  public mapEmployeeDto(emp: Employee): IEmployee {
    const dto: IEmployee = {
      id: emp.id,
      masterId: emp.masterId,
      fullName: emp.name,
      badgeId: emp.badgeId,
      dateHired: dateTimeUtils.convertToDtoString(emp.dateHired),
      terminationDate: dateTimeUtils.convertToDtoString(emp.terminationDate)
    } as any;
    if (emp.position) {
      dto.position = this.mapPositionDto(emp.position);
    }
    if (emp.department) {
      dto.department = this.mapDepartmentDto(emp.department);
    }
    if (emp.organization) {
      dto.organization = this.mapOrganizationDto(emp.organization);
    }

    return dto;
  }
  
  public mapSupervisorDto(data: Supervisor): ISupervisor {
    const dto: ISupervisor = {
      id: data.id,
      name: data.name,
      isDefault: data.isDefault,
      isActive: data.isActive
    } as any;
    return dto;
  }
  
  public mapEmployeeDefinition(data: IEmployeeDefinition): EmployeeDefinition {
    let emp: EmployeeDefinition = new EmployeeDefinition();
    emp.id = data.id;
    emp.name = data.name;
    emp.dateHired = dateTimeUtils.convertFromDtoString(data.dateHired);
    emp.dateTerminated = dateTimeUtils.convertFromDtoString(data.dateTerminated);
    return emp;
  }

  public mapEmployeeDefinitionDto(data: EmployeeDefinition): IEmployeeDefinition {
    return {
      id: data.id,
      name: data.name,
      dateHired: dateTimeUtils.convertToDtoString(data.dateHired),
      averageHoursPerWeek: data.averageHoursPerWeek,
      dateActivation: dateTimeUtils.convertToDtoString(data.dateActivation),
      dateTerminated: dateTimeUtils.convertToDtoString(data.dateTerminated),
      employeeType: this.mapEmpTypeDto(data.employeeType),
      hasRotations: data.hasRotations,
      hoursPerRotation: data.hoursPerRotation,
      isAgencyEmployee: data.isAgencyEmployee,
      mobilePhoneNumber: data.mobilePhoneNumber,
      payrollNumber: data.payrollNumber,
      phoneNumber: data.phoneNumber
    }
  }


  public mapShift(data: IShift): Shift {
    let shift: Shift = new Shift();
    shift.duration = data.duration;
    shift.end = data.end;
    shift.start = data.start;
    shift.id = data.id;
    shift.name = data.name;
    return shift;
  }

  public mapShiftDto(data: Shift): IShift {
    let dto: IShift = {
      id: data.id,
      name: data.name,
      duration: data.duration,
      start: data.start,
      end: data.end
    };
    return dto;
  }

  public mapEmpTypeDto(data: EmpType): IEmpType {
    if (!data) {
      return null;
    }
    let dto: IEmpType = {
      name: data.name,
      description: data.description,
      fullTimeIndex: data.fullTimeIndex
    };
    return dto;
  }

  public mapBudgetedPositions(data: IBudgetedPosition[]): BudgetedPosition[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');
    let roots: BudgetedPosition[] = [];
    _.forEach(data, (item: IBudgetedPosition) => {
      let value: BudgetedPosition = this.mapBudgetedPosition(item);
      roots.push(value);
    });
    return roots;
  }

  public mapBudgetedPosition(data: IBudgetedPosition): BudgetedPosition {
    let value: BudgetedPosition = new BudgetedPosition();
    value.id = data.id;
    value.description = data.description;
    return value;
  }

  public mapBudgetedPositionDto(data: BudgetedPosition): IBudgetedPosition {
    if (!data) {
      return null;
    }
    let dto: IBudgetedPosition = {
      id: data.id,
      description: data.description,
    };
    return dto;
  }

  public mapLicenseTypes(data: ILicenseType[]): LicenseType[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');
    let roots: LicenseType[] = [];
    _.forEach(data, (item: ILicenseType) => {
      let value: LicenseType = this.mapLicenseType(item);
      roots.push(value);
    });
    return roots;
  }

  public mapLicenseType(data: ILicenseType): LicenseType {
    let value: LicenseType = new LicenseType();
    value.licenseTypeID = data.licenseTypeID;
    value.licenseTypeName = data.licenseTypeName;
    return value;
  }

  public mapLicenseTypeDto(data: LicenseType): ILicenseType {
    if(!data) {
      return null;
    }
    let dto: ILicenseType = {
      licenseTypeID: data.licenseTypeID,
      licenseTypeName: data.licenseTypeName
    };
    return dto;
  }

  public mapBudgetedGroups(data: IBudgetedGroup[]): BudgetedGroup[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');
    let roots: BudgetedGroup[] = [];
    _.forEach(data, (item: IBudgetedGroup) => {
      let value: BudgetedGroup = this.mapBudgetedGroup(item);
      roots.push(value);
    });
    return roots;
  }

  public mapBudgetedGroup(data: IBudgetedGroup): BudgetedGroup {
    let value: BudgetedGroup = new BudgetedGroup();
    value.id = data.id;
    value.description = data.description;
    value.assignedPositionCount = data.assignedPositionCount;
    return value;
  }


  public mapBudgetedGroupDto(data: BudgetedGroup): IBudgetedGroup {
    if (!data) {
      return null;
    }
    let dto: IBudgetedGroup = {
      id: data.id,
      description: data.description,
      assignedPositionCount: data.assignedPositionCount
    };
    return dto;
  }

  public mapPositions(data: IPosition[]): Position[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');
    let roots: Position[] = [];
    _.forEach(data, (item: IPosition) => {
      let value: Position = this.mapPosition(item);
      roots.push(value);
    });
    return roots;
  }

  public mapPosition(data: IPosition): Position {
    let value: Position = new Position();
    value.id = data.id;
    value.name = data.name;
    value.fullName = data.name + ' - ' + data.organizationName;
    value.orgLevelId = data.orgLevelId;
    value.group = data.group;
    value.groupedWithPositionId = data.groupedWithPositionId;
    value.startDate = dateTimeUtils.convertFromDtoString(data.startDate);
    value.endDate = dateTimeUtils.convertFromDtoString(data.endDate);
    value.positionGroupId = data.positionGroupId;
    value.positionGroupName = data.positionGroupName;
    return value;
  }

  public mapPositionDto(data: Position): IPosition {
    if (!data) {
      return null;
    }
    let dto: IPosition = {
      id: data.id,
      name: data.name,
      orgLevelId: data.orgLevelId,
      positionGroupId: data.positionGroupId,
      positionGroupName: data.positionGroupName,
      organizationName: data.organizationName,
      group: data.group,
      groupedWithPositionId: data.groupedWithPositionId,
      startDate: dateTimeUtils.convertToDtoString(data.startDate),
      endDate: dateTimeUtils.convertToDtoString(data.endDate)
    };
    return dto;
  }

  public mapActualPbjPositions(data: IPbjActualPosition[]): PbjActualPosition[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');
    let roots: PbjActualPosition[] = [];
    _.forEach(data, (item: IPbjActualPosition) => {
      let value: PbjActualPosition = this.mapActualPbjPosition(item);
      roots.push(value);
    });
    return roots;
  }

  public mapActualPbjPosition(data: IPbjActualPosition): PbjActualPosition {
    let value = new PbjActualPosition();
    value.id = data.id;
    value.name = data.name;
    value.fullName = data.name + ' - ' + data.organizationName;
    value.orgLevelId = data.orgLevelId;
    value.group = data.group;
    value.groupedWithPositionId = data.groupedWithPositionId;
    value.startDate = dateTimeUtils.convertFromDtoString(data.startDate);
    value.endDate = dateTimeUtils.convertFromDtoString(data.endDate);
    value.positionGroupId = data.positionGroupId;
    value.positionGroupName = data.positionGroupName;
    value.organizationId = data.organizationId;
    value.organizationName = data.organizationName;
    value.departmentId = data.departmentId;
    value.departmentName = data.departmentName;
    value.isPrimary = data.isPrimary;
    return value;
  }

  public mapActualPbjPositionDto(data: PbjActualPosition): IPbjActualPosition {
    if (!data) {
      return null;
    }
    let dto: IPbjActualPosition = {
      id: data.id,
      name: data.name,
      orgLevelId: data.orgLevelId,
      positionGroupId: data.positionGroupId,
      positionGroupName: data.positionGroupName,
      organizationName: data.organizationName,
      group: data.group,
      groupedWithPositionId: data.groupedWithPositionId,
      startDate: dateTimeUtils.convertToDtoString(data.startDate),
      endDate: dateTimeUtils.convertToDtoString(data.endDate),
      organizationId: data.organizationId,
      departmentId: data.departmentId,
      departmentName: data.departmentName,
      isPrimary: data.isPrimary
    };
    return dto;
  }

  public mapStates(data: IState[]): State[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');
    let roots: State[] = [];
    _.forEach(data, (item: IState) => {
      let value: State = this.mapState(item);
      roots.push(value);
    });
    return roots;
  }

  public mapState(data: IState): State {
    if (!data || !data.value) {
      return null;
    }
    let value: State = new State();
    value.name = data.value;
    return value;
  }
  public mapTerminateReason(data: string): EmployeeTerminationReason {
    if (!data ) {
      return null;
    }
    let value: EmployeeTerminationReason = new EmployeeTerminationReason();
    value.reason = data;
    return value;
  }
  public mapStateDto(data: State): IState {
    if (!data) {
      return null;
    }
    let dto: IState = {
      value: data.name
    };
    return dto;
  }

  public mapEmployeeDocumentCategories(data: IEmployeeDocumentCategory[]): EmployeeDocumentCategory[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');
    let roots: EmployeeDocumentCategory[] = [];
    _.forEach(data, (item: IEmployeeDocumentCategory) => {
      let value: EmployeeDocumentCategory = this.mapEmployeeDocumentCategory(item);
      roots.push(value);
    });
    return roots;
  }

  public mapEmployeeDocumentCategory(data: IEmployeeDocumentCategory): EmployeeDocumentCategory {
    let value: EmployeeDocumentCategory = new EmployeeDocumentCategory();
    value.description = data.description;
    return value;
  }

  public mapEmployeeDocumentCategoryDto(data: EmployeeDocumentCategory): IEmployeeDocumentCategory {
    if (!data) {
      return null;
    }
    let dto: IEmployeeDocumentCategory = {
      description: data.description
    };
    return dto;
  }

  public mapAcuities(data: IAcuity[]): Acuity[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');
    let roots: Acuity[] = [];
    _.forEach(data, (item: IAcuity) => {
      let value: Acuity = this.mapAcuity(item);
      roots.push(value);
    });
    return roots;
  }

  public mapAcuity(data: IAcuity): Acuity {
    let value: Acuity = new Acuity();
    value.id = data.id;
    value.name = data.name;
    value.orgLevelId = data.orgLevelId;
    return value;
  }

  public mapAcuityDto(data: Acuity): IAcuity {
    if (!data) {
      return null;
    }
    let dto: IAcuity = {
      id: data.id,
      name: data.name,
      orgLevelId: data.orgLevelId
    };
    return dto;
  }

  public mapCostCenters(data: ICostCenter[]): CostCenter[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');
    let roots: CostCenter[] = [];
    _.forEach(data, (item: ICostCenter) => {
      let value: CostCenter = this.mapCostCenter(item);
      roots.push(value);
    });
    return roots;
  }

  public mapCostCenter(data: ICostCenter): CostCenter {
    let value: CostCenter = new CostCenter();
    value.code = data.code ? data.code.toString() : null;
    value.description = data.description;
    return value;
  }

  public mapCostCenterDto(data: CostCenter): ICostCenter {
    if (!data) {
      return null;
    }
    let dto: ICostCenter = {
      code: parseInt(data.code, 10),
      description: data.description
    };
    return dto;
  }

  public mapAccrualPolicies(data: IAccrualPolicy[]): AccrualPolicy[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');
    let roots: AccrualPolicy[] = [];
    _.forEach(data, (item: IAccrualPolicy) => {
      let value: AccrualPolicy = this.mapAccrualPolicy(item);
      roots.push(value);
    });
    return roots;
  }

  public mapAccrualPolicy(data: IAccrualPolicy): AccrualPolicy {
    let value: AccrualPolicy = new AccrualPolicy();
    value.id = data.id;
    value.name = data.name;
    return value;
  }

  public mapAccrualPolicyDto(data: AccrualPolicy): IAccrualPolicy {
    if (!data) {
      return null;
    }
    let dto: IAccrualPolicy = {
      id: data.id,
      name: data.name
    };
    return dto;
  }

  public mapPayPolicies(data: IPayPolicy[]): PayPolicy[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');
    let roots: PayPolicy[] = [];
    _.forEach(data, (item: IPayPolicy) => {
      let value: PayPolicy = this.mapPayPolicy(item);
      roots.push(value);
    });
    return roots;
  }

  public mapPayPolicy(data: IPayPolicy): PayPolicy {
    let value: PayPolicy = new PayPolicy();
    value.name = data.name;
    value.description = data.description ? data.description : data.name;
    return value;
  }

  public mapPayPolicyDto(data: PayPolicy): IPayPolicy {
    if (!data) {
      return null;
    }
    let dto: IPayPolicy = {
      name: data.name,
      description: data.description
    };
    return dto;
  }

  public mapShiftDiffPolicies(data: IShiftDiffPolicy[]): ShiftDiffPolicy[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');
    let roots: ShiftDiffPolicy[] = [];
    _.forEach(data, (item: IShiftDiffPolicy) => {
      let value: ShiftDiffPolicy = this.mapShiftDiffPolicy(item);
      roots.push(value);
    });
    return roots;
  }

  public mapShiftDiffPolicy(data: IShiftDiffPolicy): ShiftDiffPolicy {
    let value: ShiftDiffPolicy = new ShiftDiffPolicy();
    value.name = data.name;
    value.description = data.description ? data.description : data.name;
    return value;
  }

  public mapShiftDiffPolicyDto(data: ShiftDiffPolicy): IShiftDiffPolicy {
    if (!data) {
      return null;
    }
    let dto: IShiftDiffPolicy = {
      name: data.name,
      description: data.description
    };
    return dto;
  }


  public mapPayTypes(data: IPayType[]): PayType[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');
    let roots: PayType[] = [];
    _.forEach(data, (item: IPayType) => {
      let type: PayType = this.mapPayType(item);
      roots.push(type);
    });
    return roots;
  }

  public mapPayType(data: IPayType): PayType {
    let type: PayType = new PayType();
    type.name = data.name;
    type.description = data.name;
    return type;
  }

  public mapPayTypeDto(data: PayType): IPayType {
    if (!data) {
      return null;
    }
    let dto: IPayType = {
      name: data.name,
    };
    return dto;
  }

  public mapPayCyclePeriods(data: IPayCyclePeriod[]): PayCyclePeriod[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');
    let roots: PayCyclePeriod[] = [];
    _.forEach(data, (item: IPayCyclePeriod) => {
      let type: PayCyclePeriod = this.mapPayCyclePeriod(item);
      roots.push(type);
    });
    return roots;
  }

  public mapPayCyclePeriod(data: IPayCyclePeriod): PayCyclePeriod {
    let type: PayCyclePeriod = new PayCyclePeriod();
    type.name = data.name;
    type.description = data.description;
    return type;
  }

  public mapPayCycleDto(data: PayCyclePeriod): IPayCyclePeriod {
    if (!data) {
      return null;
    }
    let dto: IPayCyclePeriod = {
      name: data.name,
      description: data.description,
    };
    return dto;
  }


  public mapExemptStatuses(data: IExemptStatus[]): ExemptStatus[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');
    let roots: ExemptStatus[] = [];
    _.forEach(data, (item: IExemptStatus) => {
      let type: ExemptStatus = this.mapExemptStatus(item);
      roots.push(type);
    });
    return roots;
  }

  public mapExemptStatus(data: IExemptStatus): ExemptStatus {
    let type: ExemptStatus = new ExemptStatus();
    type.name = data.name;
    return type;
  }

  public mapExemptStatusDto(data: ExemptStatus): IExemptStatus {
    if (!data) {
      return null;
    }
    let dto: IExemptStatus = {
      name: data.name,
    };
    return dto;
  }


  public mapOrganizations(data: IOrganization[]): Organization[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');
    let roots: Organization[] = [];
    _.forEach(data, (item: IOrganization) => {
      let value: Organization = this.mapOrganization(item);
      roots.push(value);
    });
    return roots;
  }

  public mapCorporation(data: ICorporation): Corporation {
    let value: Corporation = new Corporation();
    value.id = data.id;
    value.orgLevelId = data.orgLevelId;
    value.name = data.name;
    return value;
  }

  public mapCorporationDto(data: Corporation): ICorporation {
    return {
      id: data.id,
      name: data.name,
      orgLevelId: data.orgLevelId
    };
  }

  public mapOrganizationDtos(data: Organization[]): IOrganization[] {
    let dtos: IOrganization[] = [];
    _.each(data, (org: IOrganization) => {
      let dto: IOrganization = this.mapOrganizationDto(org);
      dtos.push(dto);
    });
    return dtos;
  }


  public mapOrganization(data: IOrganization): Organization {
    let value: Organization = new Organization();
    value.id = data.id;
    value.orgLevelId = data.orgLevelId;
    value.name = data.name;
    return value;
  }


  public mapOrganizationDto(data: Organization): IOrganization {
    let dto: IOrganization = {
      id: data.id,
      orgLevelId: data.orgLevelId,
      name: data.name
    };
    return dto;
  }

  public mapCompanies(data: ICompany[]): Company[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');
    let roots: Company[] = [];
    _.forEach(data, (item: ICompany) => {
      let value: Company = this.mapCompany(item);
      roots.push(value);
    });
    return roots;
  }

  public mapCompany(data: ICompany): Company {
    let value: Company = new Company();
    value.id = data.id;
    value.name = data.name;
    return value;
  }

  public mapEstablishmentTypes(data: IEstablishmentType[]): EstablishmentType[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');
    let roots: EstablishmentType[] = [];
    _.forEach(data, (item: IEstablishmentType) => {
      let value: EstablishmentType = this.mapEstablishmentType(item);
      roots.push(value);
    });
    return roots;
  }

  public mapEstablishmentType(data: IEstablishmentType): EstablishmentType {
    let value: EstablishmentType = new EstablishmentType();
    value.name = data.name;
    value.id = data.id;
    return value;
  }

  public mapDepartments(data: IDepartment[]): Department[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');
    let roots: Department[] = [];
    _.forEach(data, (item: IDepartment) => {
      let value: Department = this.mapDepartment(item);
      roots.push(value);
    });
    return roots;
  }

  public mapLookupEntity(data: ILookupEntity[]): LookupEntity[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');
    let roots: LookupEntity[] = [];
    _.forEach(data, (item: ILookupEntity) => {
      let value: LookupEntity = new LookupEntity();
      value.id = item.id;
      value.name = item.name;
      roots.push(value);
    });
    return roots;
  }

  public mapDepartment(data: IDepartment): Department {
    let value: Department = new Department();
    value.id = data.id;
    value.orgLevelId = data.orgLevelId;
    value.name = data.name;
    value.parentOrganizationId = data.organizationId;
    return value;
  }

  public mapDepartmentDto(data: Department): IDepartment {
    let dto: IDepartment = {
      id: data.id,
      orgLevelId: data.orgLevelId,
      name: data.name
    };
    return dto;
  }

  public mapAttendancePointsDefinitions(data: IAttendancePointsDefinition[]): AttendancePointsDefinition[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');
    let roots: AttendancePointsDefinition[] = [];
    _.forEach(data, (item: IAttendancePointsDefinition) => {
      let value: AttendancePointsDefinition = this.mapAttendancePointsDefinition(item);
      roots.push(value);
    });
    return roots;
  }

  public mapAttendancePointsDefinition(dto: IAttendancePointsDefinition): AttendancePointsDefinition {
    let category: AttendancePointsCategory = { name: dto.category.name };
    let data: AttendancePointsDefinition = {
      id: dto.id,
      category: category,
      definition: dto.definition,
      points: dto.points,
      exceptions: _.map(dto.exceptionNames, (name: string) => {
        let pc: PayCode = new PayCode();
        pc.description = name;
        return pc;
      })
    };
    return data;
  }

  public mapAttendancePointsDefinitionDto(data: AttendancePointsDefinition): IAttendancePointsDefinition {
    let dto: IAttendancePointsDefinition = {
      id: data.id,
      category: { name: data.category.name },
      definition: data.definition,
      points: data.points,
      exceptionNames: _.map(data.exceptions, pc => pc.description)
    };
    return dto;
  }

  public mapGender(data: IGender): Gender {
    let value: Gender = new Gender();
    value.name = data.value;
    value.description = this.genderMapping(data.value);
    return value;
  }

  public genderMapping(value): string {
    switch (value) {
      case "NonBinary":
        return "Non-Binary";
      case "NotDefined":
        return "Not Identified";
      default:
        return value;
    }
  }

  public mapGenderDto(data: Gender): IGender {
    if (!data) {
      return null;
    }
    let dto: IGender = {
      value: data.name
    };
    return dto;
  }

  public mapGenders(data: IGender[]): Gender[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');
    let roots: Gender[] = [];
    _.forEach(data, (item: IGender) => {
      let value: Gender = this.mapGender(item);
      roots.push(value);
    });
    return roots;
  }

  public mapMaritalStatus(data: IMaritalStatus): MaritalStatus {
    let value: MaritalStatus = new MaritalStatus();
    value.name = data.value;
    value.description = data.value;
    return value;
  }

  public mapMaritalStatusDto(data: MaritalStatus): IMaritalStatus {
    if (!data) {
      return null;
    }

    let dto: IMaritalStatus = {
      value: data.name
    };
    return dto;
  }

  public mapMaritalStatuses(data: IMaritalStatus[]): MaritalStatus[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');
    let roots: MaritalStatus[] = [];
    _.forEach(data, (item: IMaritalStatus) => {
      let value: MaritalStatus = this.mapMaritalStatus(item);
      roots.push(value);
    });
    return roots;
  }

  public mapRace(data: IRace): Race {
    let value: Race = new Race();
    value.name = data.value;
    value.description = data.value;
    return value;
  }

  public mapRaceDto(data: Race): IRace {
    if (!data) {
      return null;
    }

    let dto: IRace = {
      value: data.name
    };
    return dto;
  }

  public mapRaces(data: IRace[]): Race[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');
    let roots: Race[] = [];
    _.forEach(data, (item: IRace) => {
      let value: Race = this.mapRace(item);
      roots.push(value);
    });
    return roots;
  }

  public mapLocationUnits(data: ILocationUnit[]): LocationUnit[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');
    let roots: LocationUnit[] = [];
    _.forEach(data, (item: ILocationUnit) => {
      let value: LocationUnit = this.mapLocationUnit(item);
      roots.push(value);
    });
    return roots;
  }

  public mapLocationUnit(data: ILocationUnit): LocationUnit {
    let value: LocationUnit = new LocationUnit();
    value.id = data.id;
    value.name = data.name ? data.name.trim() : '';
    return value;
  }

  public mapLocationUnitDto(data: LocationUnit): ILocationUnit {
    let dto: ILocationUnit = {
      id: data.id,
      name: data.name ? data.name.trim() : ''
    };
    return dto;
  }

  public mapShiftDefinitions(data: IShiftDefinition[]): ShiftDefinition[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');
    let roots: ShiftDefinition[] = [];
    _.forEach(data, (item: IShiftDefinition) => {
      let value: ShiftDefinition = this.mapShiftDefinition(item);
      roots.push(value);
    });
    return roots;
  }

  public mapShiftDefinition(data: IShiftDefinition): ShiftDefinition {
    let value: ShiftDefinition = new ShiftDefinition(data.id, data.name, data.start, data.end, data.duration,
      data.lunchDuration, data.group, data.employeesCount, data.usedInIdealSchedule);
    return value;
  }

  public mapShiftDefinitionDto(data: ShiftDefinition): IShiftDefinition {
    let dto: IShiftDefinition = {
      id: data.id,
      name: data.name,
      start: data.start,
      end: data.end,
      duration: data.duration,
      lunchDuration: data.lunchDuration,
      group: data.group,
      employeesCount: data.employeesCount,
      usedInIdealSchedule: data.usedInIdealSchedule
    };
    return dto;
  }

  public mapConstraintDefinitions(data: IConstraintDefinition[]): ConstraintDefinition[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');
    let roots: ConstraintDefinition[] = [];
    _.forEach(data, (item: IConstraintDefinition) => {
      let value: ConstraintDefinition = this.mapConstraintDefinition(item);
      roots.push(value);
    });
    return roots;
  }

  public mapConstraintDefinition(data: IConstraintDefinition): ConstraintDefinition {
    let value: ConstraintDefinition = new ConstraintDefinition();
    value.id = data.id;
    value.name = data.name;
    value.code = data.code;
    value.organizationId = data.organizationId;
    value.countAs = data.countAs;
    return value;
  }

  public mapConstraintDefinitionDto(data: ConstraintDefinition): IConstraintDefinition {
    let dto: IConstraintDefinition = {
      id: data.id,
      name: data.name,
      code: data.code,
      organizationId: data.organizationId,
      countAs: data.countAs
    };
    return dto;
  }

  public mapScheduleAbsences(data: IScheduleAbsence[]): ScheduleAbsence[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');
    let roots: ScheduleAbsence[] = [];
    _.forEach(data, (item: IScheduleAbsence) => {
      let value: ScheduleAbsence = this.mapScheduleAbsence(item);
      roots.push(value);
    });
    return roots;
  }

  public mapScheduleAbsence(dto: IScheduleAbsence): ScheduleAbsence {
    let data: ScheduleAbsence = new ScheduleAbsence();
    data.code = dto.code;
    data.description = dto.description;
    data.color = dto.color;
    data.isPaid = dto.isPaid;
    data.ptoPlannerIndicator = dto.ptoPlannerIndicator;
    data.loaIndicator = dto.loaIndicator;
    return data;
  }

  public mapScheduleAbsenceDto(data: ScheduleAbsence): IScheduleAbsence {
    if (!data) {
      return null;
    }
    let dto: IScheduleAbsence = {
      code: data.code,
      description: data.description,
      color: data.color,
      isPaid: data.isPaid,
      ptoPlannerIndicator: data.ptoPlannerIndicator,
      loaIndicator: data.loaIndicator
    };
    return dto;
  }

  public mapSchedulePartialAbsence(dto: ISchedulePartialAbsence): SchedulePartialAbsence {
    let data: SchedulePartialAbsence = new SchedulePartialAbsence();
    data.code = dto.code;
    data.description = dto.description;
    data.start = dto.start;
    data.end = dto.end;
    data.duration = dto.duration;
    return data;
  }

  public mapSchedulePartialAbsenceDto(data: SchedulePartialAbsence): ISchedulePartialAbsence {
    if (!data) {
      return null;
    }
    let dto: ISchedulePartialAbsence = {
      code: data.code,
      description: data.description,
      start: data.start,
      end: data.end,
      duration: data.duration
    };
    return dto;
  }

  public mapTAAbsence(dto: ITAAbsence): TAAbsence {
    let data: TAAbsence = new TAAbsence();
    data.code = dto.code;
    data.description = dto.description;
    data.color = dto.color;
    data.id = dto.id;
    return data;
  }

  public mapTAAbsenceDto(data: TAAbsence): ITAAbsence {
    if (!data) {
      return null;
    }
    let dto: ITAAbsence = {
      code: data.code,
      description: data.description,
      color: data.color,
      id: data.id
    };
    return dto;
  }

  public mapSearchCategories(data: ISearchCategory[]): SearchCategory[] {

    let categories: SearchCategory[] = [];
    _.each(data, (dto: ISearchCategory) => {

      let category: SearchCategory = new SearchCategory();
      category.categoryFieldId = dto.categoryFieldId;
      category.categoryFieldName = dto.categoryFieldName;
      category.dataType = dto.dataType;
      category.description = dto.description;
      category.displayName = dto.displayName;
      category.fieldName = dto.fieldName;
      category.operators = dto.operators;
      category.tableName = dto.tableName;

      categories.push(category);
    });
    return categories;
  }

  public mapEmployeeTerminationReasons(data: IEmployeeTerminationReason[]): EmployeeTerminationReason[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');
    let roots: EmployeeTerminationReason[] = [];
    _.forEach(data, (item: IEmployeeTerminationReason) => {
      let value: EmployeeTerminationReason = this.mapEmployeeTerminationReason(item);
      roots.push(value);
    });
    return roots;
  }

  public mapAvailabilityDateRanges(data: IAvailabilityDateRange[]): AvailabilityDateRange[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');
    let roots: AvailabilityDateRange[] = [];
    _.forEach(data, (item: IAvailabilityDateRange) => {
      let value: AvailabilityDateRange = this.mapEmployeeAvailabilityRange(item);
      roots.push(value);
    });
    return roots;
  }

  public mapEmployeeAvailabilityRange(dto: IAvailabilityDateRange): AvailabilityDateRange {
    let data: AvailabilityDateRange = new AvailabilityDateRange();
    data.employeeId = dto.employeeId;
    data.startDate = dto.startDate;
    data.endDate = dto.endDate;
    return data;
  }

  public mapEmployeeAvailabilityRangeDto(data: AvailabilityDateRange): IAvailabilityDateRange {
    let dto: AvailabilityDateRange = new AvailabilityDateRange();
    dto.employeeId = data.employeeId;
    dto.startDate = data.startDate;
    dto.endDate = data.endDate;
    return data;
  }

  public mapEmployeeTerminationReason(data: IEmployeeTerminationReason): EmployeeTerminationReason {
    let value: EmployeeTerminationReason = new EmployeeTerminationReason();
    value.id = data.id;
    value.reason = data.reason;
    value.isVoluntary = data.isVoluntary;
    return value;
  }

  public mapEmployeeTerminationReasonDto(data: EmployeeTerminationReason): IEmployeeTerminationReason {
    let dto: IEmployeeTerminationReason = {
      id: data.id,
      reason: data.reason,
      isVoluntary: data.isVoluntary
    };
    return dto;
  }

  public mapPayCycle(dto: IPayCycle): PayCycle {
    let value: PayCycle = new PayCycle();
    value.startDate = dateTimeUtils.getUtcDateTime(dto.startDate);
    value.endDate = dateTimeUtils.getUtcDateTime(dto.endDate);
    value.isApproved = dto.isApproved;
    return value;
  }
  public mapPayCycles(dto: IPayCycle[]): PayCycle[] {
    return _.map(dto, (dtoDef: IPayCycle) => { return this.mapPayCycle(dtoDef); });
  }

  public mapBudgetDefinitions(dto: IBudgetDefinition[]): BudgetDefinition[] {
    return _.map(dto, (dtoDef: IBudgetDefinition) => { return this.mapBudgetDefinition(dtoDef); });
  }

  public mapBudgetDefinition(dto: IBudgetDefinition): BudgetDefinition {
    let value: BudgetDefinition = new BudgetDefinition();
    value.id = dto.id;
    value.startDate = dateTimeUtils.getUtcDateTime(dto.startDate);
    value.endDate = dateTimeUtils.getUtcDateTime(dto.endDate);
    value.orgLevelId = dto.orgLevelId;
    return value;
  }

  public mapBudgetDefinitionDto(data: BudgetDefinition): IBudgetDefinition {
    if (!data) {
      return null;
    }
    let dto: IBudgetDefinition = {
      id: data.id,
      startDate: dateTimeUtils.convertToDtoUTC(data.startDate),
      endDate: dateTimeUtils.convertToDtoUTC(data.endDate),
      orgLevelId: data.orgLevelId,
    };
    return dto;
  }


  public mapPbjPositions(data: IPbjPosition[]): PbjPosition[] {
    let positions: PbjPosition[] = [];
    _.each(data, (p: IPbjPosition) => {
      let pbjPosition: PbjPosition = new PbjPosition();
      pbjPosition.id = p.id;
      pbjPosition.name = p.name;
      pbjPosition.govermentCode = p.govermentCode;
      positions.push(pbjPosition);
    });
    return positions;
  }

  public mapTimeclockDefinitions(dto: ITimeclockDefinition[]): TimeclockDefinition[] {
    return _.map(dto, (dtoDef: ITimeclockDefinition) => { return this.mapTimeclockDefinition(dtoDef); });
  }

  public mapTimeclockDefinition(dto: ITimeclockDefinition): TimeclockDefinition {
    let value: TimeclockDefinition = new TimeclockDefinition();
    value.id = dto.id;
    value.name = dto.name;
    value.isVirtual = dto.isVirtual;
    value.lastMessageText = dto.lastMessageText;
    value.messageDisplayStartDate = dateTimeUtils.convertFromDtoString(dto.messageDisplayStartDate);
    value.messageDisplayEndDate = dateTimeUtils.convertFromDtoString(dto.messageDisplayEndDate);
    value.clockOrganization = dto.clockOrganization;
    value.physicalId = dto.physicalId;
    return value;
  }

  public mapTimeclockDefinitionDto(data: TimeclockDefinition): ITimeclockDefinition {
    if (!data) {
      return null;
    }
    let dto: ITimeclockDefinition = {
      id: data.id,
      name: data.name,
      isVirtual: data.isVirtual,
      lastMessageText: data.lastMessageText,
      messageDisplayStartDate: dateTimeUtils.convertToDtoDateTimeString(data.messageDisplayStartDate),
      messageDisplayEndDate: dateTimeUtils.convertToDtoDateTimeString(data.messageDisplayEndDate),
      clockOrganization: data.clockOrganization,
      physicalId: data.physicalId
    };
    return dto;
  }

  public mapTimeclockRestrictionDefinitions(dto: ITimeclockRestrictionDefinition[]): TimeclockRestrictionDefinition[] {
    return _.map(dto, (dtoDef: ITimeclockRestrictionDefinition) => { return this.mapTimeclockRestrictionDefinition(dtoDef); });
  }

  public mapTimeclockRestrictionDefinition(dto: ITimeclockRestrictionDefinition): TimeclockRestrictionDefinition {
    let value: TimeclockRestrictionDefinition = new TimeclockRestrictionDefinition();
    value.id = dto.id;
    value.name = dto.name;
    return value;
  }

  public mapTimeclockRestrictionDto(data: TimeclockRestrictionDefinition): ITimeclockRestrictionDefinition {
    if (!data) {
      return null;
    }
    let dto: ITimeclockRestrictionDefinition = {
      id: data.id,
      name: data.name
    };
    return dto;
  }

  public mapTimeclockModelDefinitions(dto: ITimeclockModelDefinition[]): TimeclockModelDefinition[] {
    return _.map(dto, (dtoDef: ITimeclockModelDefinition) => { return this.mapTimeclockModelDefinition(dtoDef); });
  }

  public mapTimeclockModelDefinition(dto: ITimeclockModelDefinition): TimeclockModelDefinition {
    let value: TimeclockModelDefinition = new TimeclockModelDefinition();
    value.id = dto.id;
    value.name = dto.name;
    value.qrPunchSupported = dto.qrPunchSupported;
    return value;
  }

  public mapTimeclockModelDefinitionDto(data: TimeclockModelDefinition): ITimeclockModelDefinition {
    if (!data) {
      return null;
    }
    let dto: ITimeclockModelDefinition = {
      id: data.id,
      name: data.name,
      qrPunchSupported: data.qrPunchSupported
    };
    return dto;
  }

  public mapTimezoneDefinitions(dto: ITimezoneDefinition[]): TimezoneDefinition[] {
    return _.map(dto, (dtoDef: ITimezoneDefinition) => { return this.mapTimezoneDefinition(dtoDef); });
  }

  public mapTimezoneDefinition(dto: ITimezoneDefinition): TimezoneDefinition {
    let value: TimezoneDefinition = new TimezoneDefinition();
    value.id = dto.id;
    value.name = dto.name;
    return value;
  }

  public mapTimezoneDefinitionDto(data: TimezoneDefinition): ITimezoneDefinition {
    if (!data) {
      return null;
    }
    let dto: ITimezoneDefinition = {
      id: data.id,
      name: data.name
    };
    return dto;
  }

  public mapPayRules(dto: IPayRule[]): PayRule[] {
    return _.map(dto, (dtoDef: IPayRule) => { return this.mapPayRule(dtoDef); });
  }

  public mapPayRule(dto: IPayRule): PayRule {
    let rule: PayRule = new PayRule();
    rule.id = dto.id;
    rule.description = dto.description;
    rule.name = dto.name;
    rule.color = dto.color;
    rule.ruleType = dto.ruleType;
    rule.group = dto.group;
    rule.startTimeOffset = dto.startTimeOffset;
    rule.endTimeOffset = dto.endTimeOffset;
    rule.additionalVariable = dto.additionalVariable;
    return rule;
  }

  public mapPayRuleDto(payRule: PayRule): IPayRule {
    return {
      id: payRule.id,
      description: payRule.description,
      name: payRule.name,
      color: payRule.color,
      ruleType: payRule.ruleType,
      group: payRule.group,
      startTimeOffset: payRule.startTimeOffset,
      endTimeOffset: payRule.endTimeOffset,
      additionalVariable: payRule.additionalVariable
    };
  }

  public mapPunchTypes(dto: IPunchType[]): PunchType[] {
    return _.map(dto, (dtoDef: IPunchType) => { return this.mapPunchType(dtoDef); });
  }

  public mapPunchType(dto: IPunchType): PunchType {
    let punchType: PunchType = new PunchType();
    punchType.id = dto.id;
    punchType.name = dto.name;
    punchType.description = _.startCase(dto.name);
    punchType.previousPunchTypeId = dto.previousPunchTypeId;
    punchType.canBeFirst = dto.canBeFirst;
    punchType.canBeLast = dto.canBeLast;
    punchType.isIn = dto.isIn;
    punchType.isOut = dto.isOut;
    punchType.isBreak = dto.isBreak;
    punchType.isLunch = dto.isLunch;
    punchType.isTraining = dto.isTraining;
    return punchType;
  }

  public mapPunchTypeDto(data: PunchType): IPunchType {
    if (!data) {
      return null;
    }
    let dto: IPunchType = {
      id: data.id,
      name: data.name,
      previousPunchTypeId: data.previousPunchTypeId,
      canBeFirst: data.canBeFirst,
      canBeLast: data.canBeLast,
      isIn: data.isIn,
      isOut: data.isOut,
      isBreak: data.isBreak,
      isLunch: data.isLunch,
      isTraining: data.isTraining
    };
    return dto;
  }

  public mapTaAbsenceCode(dto: ITaAbsenceCode): TaAbsenceCode {
    let taCode: ITaAbsenceCode = new TaAbsenceCode();
    taCode.id = dto.id;
    taCode.code = _.trim(dto.code);
    taCode.description = dto.description;
    return taCode;
  }

  public mapTaAbsenceCodes(dto: ITaAbsenceCode[]): TaAbsenceCode[] {
    return _.map(dto, (dtoDef: ITaAbsenceCode) => { return this.mapTaAbsenceCode(dtoDef); });
  }

  public mapRoleDefinitions(dto: IRoleDefinition[]): RoleDefinition[] {
    return _.map(dto, (dtoDef: IRoleDefinition) => { return this.mapRoleDefinition(dtoDef); });
  }

  public mapRoleDefinition(dto: IRoleDefinition): RoleDefinition {
    let value: RoleDefinition = new RoleDefinition();
    value.id = dto.id;
    value.name = dto.name;
    value.assignedCount = dto.assignedCount;
    value.lastUpdateDate = dateTimeUtils.convertFromDtoString(dto.lastUpdateDate);
    value.lastUpdateUsername = dto.lastUpdateUsername;
    return value;
  }

  public mapRoleDefinitionDto(data: RoleDefinition): IRoleDefinition {

    if (!data) {
      return null;
    }

    let dto: IRoleDefinition = {
      id: data.id,
      name: data.name,
      assignedCount: data.assignedCount,
      lastUpdateDate: dateTimeUtils.convertToDtoString(data.lastUpdateDate),
      lastUpdateUsername: data.lastUpdateUsername
    };
    return dto;
  }

  public mapUserProfileDefinitions(dto: IUserProfileDefinition[]): UserProfileDefinition[] {
    return _.map(dto, (dtoDef: IUserProfileDefinition) => { return this.mapUserProfileDefinition(dtoDef); });
  }

  public mapUserProfileDefinition(dto: IUserProfileDefinition): UserProfileDefinition {
    let value: UserProfileDefinition = new UserProfileDefinition();
    value.id = dto.id;
    value.name = dto.name;
    value.login = dto.login;
    value.email = dto.email;
    return value;
  }

  public mapUserProfileDefinitionDto(data: UserProfileDefinition): IUserProfileDefinition {

    if (!data) {
      return null;
    }

    let dto: IUserProfileDefinition = {
      id: data.id,
      name: data.name,
      login: data.login,
      email: data.email
    };
    return dto;
  }

  public mapSupervisorGroupDefinitions(dto: ISupervisorGroupDefinition[]): SupervisorGroupDefinition[] {
    return _.map(dto, (dtoDef: ISupervisorGroupDefinition) => { return this.mapSupervisorGroupDefinition(dtoDef); });
  }
  public mapSupervisorGroupDefinitionDtos(data: SupervisorGroupDefinition[]): ISupervisorGroupDefinition[] {
    return _.map(data, (d: SupervisorGroupDefinition) => { return this.mapSupervisorGroupDefinitionDto(d); });
  }
  public mapSupervisorGroupDefinition(dto: ISupervisorGroupDefinition): SupervisorGroupDefinition {
    let value: SupervisorGroupDefinition = new SupervisorGroupDefinition();
    value.id = dto.id;
    value.name = dto.name;
    value.orgLevelId = dto.orgLevelId;
    return value;
  }

  public mapSupervisorGroupDefinitionDto(data: SupervisorGroupDefinition): ISupervisorGroupDefinition {

    if (!data) {
      return null;
    }

    let dto: ISupervisorGroupDefinition = {
      id: data.id,
      name: data.name,
      orgLevelId: data.orgLevelId
    };
    return dto;
  }

  public mapAgencyDefinition(dto: IAgencyDefinition): AgencyDefinition {
    let data: IAgencyDefinition = new AgencyDefinition();
    data.id = dto.id;
    data.name = dto.name;
    data.orgLevelId = dto.orgLevelId;
    return data;
  }
  public mapAgencyDefinitionDto(data: AgencyDefinition): IAgencyDefinition {
    if (!data) {
      return null;
    }
    let dto: IAgencyDefinition = {
      id: data.id,
      name: data.name,
      orgLevelId: data.orgLevelId
    };
    return dto;
  }

  public mapAgencyDefinitions(dto: IAgencyDefinition[]): AgencyDefinition[] {
    return _.map(dto, (dtoDef: IAgencyDefinition) => { return this.mapAgencyDefinition(dtoDef); });
  }

  public mapTimecardPredefinedComment(dto: ITimecardPredefinedComment): TimecardPredefinedComment {
    let data: TimecardPredefinedComment = new TimecardPredefinedComment();
    data.id = dto.id;
    data.order = dto.order;
    data.description = dto.description;
    data.type = dto.type;
    return data;
  }
  public mapTimecardPredefinedCommentDto(data: TimecardPredefinedComment): ITimecardPredefinedComment {
    if (!data) {
      return null;
    }
    let dto: ITimecardPredefinedComment = {
      id: data.id,
      order: data.order,
      description: data.description,
      type: data.type
    };
    return dto;
  }

  public mapTimecardPredefinedComments(dto: ITimecardPredefinedComment[]): TimecardPredefinedComment[] {
    return _.map(dto, (dtoDef: ITimecardPredefinedComment) => { return this.mapTimecardPredefinedComment(dtoDef); });
  }

  public mapEssEntities(data: IEssEntity[]): EssEntity[] {
    return _.map(data, (dto: IEssEntity) => {
      let value: EssEntity = new EssEntity();
      value.id = dto.id;
      value.name = dto.name;
      return value;
    });
  }

  public mapVeteranStatuses(dtos: IVeteranStatus[]): VeteranStatus[] {
    return _.sortBy(_.map(dtos, x => this.mapVeteranStatus(x)), 'name');
  }

  public mapVeteranStatus(dto: IVeteranStatus): VeteranStatus {
    let model: VeteranStatus = new VeteranStatus();
    model.id = dto.name;
    model.name = dto.name;
    return model;
  }

  public mapBenefitClassDefinitions(dtos: IBenefitClassDefinition[]): BenefitClassDefinition[] {
    return _.map(dtos, x => this.mapBenefitClassDefinition(x));
  }

  public mapBenefitClassDefinition(dto: IBenefitClassDefinition): BenefitClassDefinition {
    let model: BenefitClassDefinition = new BenefitClassDefinition();
    model.id = dto.id;
    model.name = dto.name;
    model.description = dto.description;
    model.orgLevelId = dto.orgLevelId;
    model.code = dto.code;
    return model;
  }

  public mapToBenefitClassDefinitionDTO(model: BenefitClassDefinition): IBenefitClassDefinition {
    return {
      id: model.id,
      description: model.description,
      name: model.name,
      orgLevelId: model.orgLevelId,
      code: model.code
    };
  }

  public mapCodeDefinition(dto: IWcCodeDefinitionDto): WcCodeDefinition {
    let model: WcCodeDefinition = new WcCodeDefinition();
    model.id = dto.id;
    model.codeType = dto.codeType;
    model.code = dto.code;
    model.description = dto.description;
    return model;
  }


  public mapTemplateDefinititionDto(template: PmTemplateDefinition): IPmTemplateDefinition {
    return {
      templateId: template.templateId,
      orgLevel: template.orgLevel,
      orgLevelObject: {
        id: template.orgLevel,
        name: template.orgLevelName
      },
      attachmentId: template.attachmentId,
      templateDescription: template.templateDescription,
      addedBy: template.addedBy,
      addedOn: dateTimeUtils.convertToDtoString(template.addedOn),
      modifiedBy: template.modifiedBy,
      modifiedOn: dateTimeUtils.convertToDtoString(template.modifiedOn),
      attachment: template.attachment ? this.mapPmAttachmentToDto(template.attachment) : null
    }
  }

  public mapPerformanceReviewTemplates(dtos: IPmTemplate[]): PmTemplate[] {
    return _.map(dtos, x => this.mapPerformanceReviewTemplate(x));
  }

  public mapPerformanceReviewTemplate(dto: IPmTemplate): PmTemplate {
    let model: PmTemplate = new PmTemplate();
    model.template = this.mapTemplateDefinition(dto.templateDefinition);
    model.attachment = this.mapPmAttachment(dto.attachment);
    model.attachment.name = model.template.templateDescription;
    return model;
  }

  public mapTemplateDefinition(dto: IPmTemplateDefinition): PmTemplateDefinition {

    var d: PmTemplateDefinition = new PmTemplateDefinition();

    d.templateId = dto.templateId;
    d.orgLevel = dto.orgLevel;
    d.orgLevelName = dto.orgLevelObject ? dto.orgLevelObject.name : null;
    d.attachmentId = dto.attachmentId;
    d.templateDescription = dto.templateDescription;
    d.addedBy = dto.addedBy;
    d.addedOn = dateTimeUtils.convertFromDtoString(dto.addedOn);
    d.modifiedBy = dto.modifiedBy;
    d.modifiedOn = dateTimeUtils.convertFromDtoString(dto.modifiedOn);
    d.attachment = this.mapPmAttachment(dto.attachment)
    return d;
  }

  public mapPmAttachments(attachments: IPmAttachment[]): PmAttachment[] {
    return _.map(attachments, a => this.mapPmAttachment(a));
  }

  public mapPmAttachment(a: IPmAttachment): PmAttachment {
    const attachment = new PmAttachment();
    if (_.isObjectLike(a)) {
      attachment.id = a.id;
      attachment.name = a.name;
      attachment.fileName = a.fileName;
      attachment.employeeId = a.employeeId;
      attachment.addedBy = a.addedBy;
      attachment.addedOn = dateTimeUtils.convertFromDtoString(a.addedOn);
    }

    return attachment;
  }

  public mapPmAttachmentsToDto(attachments: PmAttachment[]): IPmAttachment[] {
    return _.map(attachments, a => this.mapPmAttachmentToDto(a));
  }

  public mapPmAttachmentToDto(attachment: PmAttachment): IPmAttachment {
    const dto: IPmAttachment = {
      id: attachment.id,
      name: attachment.name,
      fileName: attachment.fileName,
      employeeId: attachment.employeeId,
      addedBy: attachment.addedBy,
      addedOn: dateTimeUtils.convertToDtoString(attachment.addedOn)
    };

    return dto;
  }

  public mapPerformanceReviewCodes(dtos: IPerformanceReviewCode[]): PerformanceReviewCode[] {
    return _.map(dtos, x => this.mapPerformanceReviewCode(x));
  }

  public mapPerformanceReviewCode(dto: IPerformanceReviewCode): PerformanceReviewCode {
    const model = new PerformanceReviewCode();
    model.id = dto.id;
    model.name = dto.code;
    model.type = this.mapPerformanceReviewCodeType(dto.codeType);
    return model;
  }

  public mapPerformanceReviewCodeTypes(data: IPerformanceReviewCodeType[]): PerformanceReviewCodeType[] {
    return _.map(data, x => this.mapPerformanceReviewCodeType(x));
  }

  public mapPerformanceReviewCodeType(dto: IPerformanceReviewCodeType): PerformanceReviewCodeType {
    const type = new PerformanceReviewCodeType();
    type.id = dto.id;
    type.name = dto.name;
    type.description = dto.description;
    return type;
  }

  public mapPerformanceReviewCodeTypeDto(codeType: PerformanceReviewCodeType): IPerformanceReviewCodeType {
    return {
      id: codeType.id,
      name: codeType.name,
      description: codeType.description
    };
  }

  public mapDisabilityStatuses(): DisabilityStatus[] {
    return _.map(['No', 'Yes', 'Not Identified'], (name, id) => new DisabilityStatus(String(id), name));
  }

  public mapDisabilityStatus(status: string): DisabilityStatus {
    if (_.isString(status) && _.size(status) > 0) {
      const list = this.mapDisabilityStatuses();
      let disStatus = _.find(list, (s) => s.name === status) || new DisabilityStatus(status, status);
      return disStatus;
    }

    return null;
  }

  public mapDisabilityStatusDTO(disStatus: DisabilityStatus): string {
    if (_.isObjectLike(disStatus)) {
      return disStatus.name;
    }

    return null;
  }

  public mapPositionGroups(dtos: IPositionGroup[]): PositionGroup[] {
    return _.map(dtos, (dto: IPositionGroup) => this.mapPositionGroup(dto));
  }

  public mapPositionGroup(dto: IPositionGroup): PositionGroup {
    const group = new PositionGroup();
    group.id = dto.id;
    group.name = dto.name;
    group.departmentId = dto.departmentId;

    return group;
  }

  public mapPositionGroupDto(group: PositionGroup): IPositionGroup {
    const dto = {} as IPositionGroup;

    if (_.isFinite(group.id)) {
      dto.id = group.id;
    }

    if (_.size(group.name) > 0) {
      dto.name = group.name;
    }

    if (_.isFinite(group.departmentId)) {
      dto.departmentId = group.departmentId;
    }

    return dto;
  }

  public mapBenefitGroups(dto: IBenefitGroup[]): BenefitGroup[] {
    return _.map(dto, d => this.mapBenefitGroup(d));
  }

  public mapBenefitGroup(dto: IBenefitGroup): BenefitGroup {
    let model = new BenefitGroup();
    model.id = dto.id;
    model.name = dto.name;
    model.allowMultipleEnrollment = dto.allowMultipleEnrollment;
    return model;
  }

  public mapObjAccs(dtos: IObjAcc[]): ObjAcc[] {
    return _.map(dtos, x => this.mapObjAcc(x));
  }

  public mapObjAcc(dto: IObjAcc): ObjAcc {
    const model = new ObjAcc();
    model.name = dto.value;
    return model;
  }

  public mapAcaCategoryDefinitions(dto: IAcaCategory[]): AcaCategory[] {
    return _.map(dto, d => this.mapAcaCategoryDefinition(d));
  }

  public mapAcaCategoryDefinition(dto: IAcaCategory): AcaCategory {
    let model = new AcaCategory();
    model.value = dto.value;
    return model;
  }
  public mapAcaSafeHarbors(dto: IAcaSafeHarbor[]): AcaSafeHarbor[] {
    return _.map(dto, d => this.mapAcaSafeHarbor(d));
  }
  public mapAcaEmployers(dto: IAcaEmployers[]): AcaEmployers[] {
    return _.map(dto, d => this.mapAcaEmployer(d));
  }

  public mapAcaEmployer(dto: IAcaEmployers): AcaEmployers {
    let model = new AcaEmployers();
    model.aleGroupId = dto.aleGroupId;
    model.aleMembers = dto.aleGroupId;
    model.tccCode = dto.tccCode;
    model.companyId = dto.companyId;
    model.deleted = dto.deleted;
    model.legalName = dto.legalName;
    return model;
  }
  public mapAcaSafeHarbor(dto: IAcaSafeHarbor): AcaSafeHarbor {
    let model = new AcaSafeHarbor();
    model.id = dto.id;
    model.name = dto.name;
    return model;
  }
  public mapAcaReasons(dto: IAcaReason[]): AcaReason[] {
    return _.map(dto, d => this.mapAcaReason(d));
  }

  public mapAcaReason(dto: IAcaReason): AcaReason {
    let model = new AcaReason();
    model.id = dto.id;
    model.name = dto.name;
    return model;
  }
  public mapAcaOfferOfCoverages(dto: IAcaOfferOfCoverage[]): AcaOfferOfCoverage[] {
    return _.map(dto, d => this.mapAcaOfferOfCoverage(d));
  }

  public mapAcaOfferOfCoverage(dto: IAcaOfferOfCoverage): AcaOfferOfCoverage {
    let model = new AcaOfferOfCoverage();
    model.id = dto.id;
    model.name = dto.name;
    return model;
  }

  public mapToPayUnitDefinitions(dtos: IPayUnitDefinition[]): PayUnitDefinition[] {
    return _.map(dtos, v => this.mapToPayUnitDefinition(v));
  }

  public mapToIncreaseReasons(dto: IIncreaseReason[]): IncreaseReason[] {
    return dto.map(x => this.mapToIncreaseReason(x));
  }

  public mapToPayUnitDefinition(dto: IPayUnitDefinition): PayUnitDefinition {
    const data = new PayUnitDefinition();
    data.id = dto.id;
    data.name = dto.name;
    data.departmentId = dto.departmentId ? dto.departmentId: 0;
    data.organizationId = dto.organizationId;
    return data;
  }

  public mapToPayUnitDefinitionDto(data: PayUnitDefinition): IPayUnitDefinition {
    const dto: IPayUnitDefinition = {
      id: data.id,
      name: data.name,
      departmentId: data.departmentId,
      organizationId: data.organizationId
    };
    return dto;
  }
  
  public mapToIncreaseReason(dto: IIncreaseReason): IncreaseReason {
    const data = new IncreaseReason();
    data.id = dto.id;
    data.name = dto.name;
    return data;
  }

  public mapToIncreaseReasonDto(data: IncreaseReason): IIncreaseReason {
    const dto: IncreaseReason = {
      id: data.id,
      name: data.name
    };
    return dto;
  }

  public mapTimeclockSlateProfileDefinitions(dto: ITimeclockSlateProfileDefinition[]): TimeclockSlateProfileDefinition[] {
    return _.map(dto, (dtoDef: ITimeclockSlateProfileDefinition) => this.mapTimeclockSlateProfileDefinition(dtoDef));
  }
  public mapTimeclockSlateProfileDefinition(dto: ITimeclockSlateProfileDefinition): TimeclockSlateProfileDefinition {
    return new TimeclockSlateProfileDefinition(dto.id, dto.name);

  }

  public mapToAttestationtypes(dto: IAttestationType[]): AttestationType[] {
    return dto.map(x => this.mapToAttestationtype(x));
  }

  public mapToAttestationtype(dto: IAttestationType): AttestationType {
    const data = new AttestationType();
    data.attestationTypeId = dto.attestationTypeId;
    data.attestationTypeName = dto.attestationTypeName;
    return data;
  }

  public mapToRestrictedAnswers(dto: IRestrictedAnswer[]): RestrictedAnswer[] {
    return dto.map(x => this.mapToRestrictedAnswer(x));
  }

  public mapToRestrictedAnswer(dto: IRestrictedAnswer): RestrictedAnswer {
    const data = new RestrictedAnswer();
    data.restrictedAnswerId = dto.restrictedAnswerId;
    data.restrictedAnswerName = dto.restrictedAnswerName;
    return data;
  }

  public mapToRestrictedNotifys(dto: INotifyData[]): NotifyData[] {
    return dto.map(x => this.mapToRestrictedNotify(x));
  }

  public mapToRestrictedNotify(dto:INotifyData):NotifyData{
    const data = new NotifyData();
    data.notifyId = dto.notifyId;
    data.notifyType = dto.notifyType;
    return data;

  }

  public mapToOrganizationTimezones(dto: IOrganizationTimezone[]): OrganizationTimezone[] {
    return dto.map(x => this.mapToOrganizationTimezone(x));
  }

  public mapToOrganizationTimezone(dto:IOrganizationTimezone):OrganizationTimezone{
    const data = new OrganizationTimezone();
    data.timezone = dto.timezone;
    data.timezoneDisplayName = dto.timezoneDisplayName;
    return data;

  }
}
