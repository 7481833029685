import { Injectable } from '@angular/core';
import { AdditionalRequirementDto } from '../../models/pay-code-details/dtos/additional-requirement.dto';
import { AdditionalRequirementModel } from '../../models/pay-code-details/models/additional-requirement.model';

@Injectable()
export class AdditionalRequirementMapService {
    public mapToModel(dto: AdditionalRequirementDto): AdditionalRequirementModel {
        let model: AdditionalRequirementModel = new AdditionalRequirementModel();
        model.ignoreBreak = dto.ignoreBreak;
        model.calculateNetWorkTime = dto.calculateNetWorkTime;
        model.useContiguousTime = dto.useContiguousTime;
        model.stayWithinExceptionTimeframe = dto.stayWithinExceptionTimeframe;
        model.exportAsDollarAmount = dto.exportAsDollarAmount;
        model.paidAtHolidayRate = dto.paidAtHolidayRate;
        model.regularOverride = dto.regularOverride;
        model.otOverride = dto.otOverride;
        model.holidayOverride = dto.holidayOverride;
        model.overtimeHolidayOverride = dto.overtimeHolidayOverride;
        model.otherCodeOverride = dto.otherCodeOverride;
        model.recalculate = dto.recalculate;
        model.requirementIds = dto.requirementIds;
        return model;
    }

    public mapToDto(model: AdditionalRequirementModel): AdditionalRequirementDto {
        let dto: AdditionalRequirementDto = {
            ignoreBreak: model.ignoreBreak,
            calculateNetWorkTime: model.calculateNetWorkTime,
            useContiguousTime: model.useContiguousTime,
            stayWithinExceptionTimeframe: model.stayWithinExceptionTimeframe,
            exportAsDollarAmount: model.exportAsDollarAmount,
            paidAtHolidayRate: model.paidAtHolidayRate,
            regularOverride: model.regularOverride,
            otOverride: model.otOverride,
            holidayOverride: model.holidayOverride,
            overtimeHolidayOverride: model.overtimeHolidayOverride,
            otherCodeOverride: model.otherCodeOverride,
            recalculate: model.recalculate,
            requirementIds: model.requirementIds
        };
        return dto;
    }

    public mapToModels(dtos: AdditionalRequirementDto[]): AdditionalRequirementModel[] {
        return dtos.map(dto => this.mapToModel(dto));
    }

    public mapToDtos(models: AdditionalRequirementModel[]): AdditionalRequirementDto[] {
        return models.map(model => this.mapToDto(model));
    }
}