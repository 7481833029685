<div class="timeclock-cell-container">
  <div class="display-container">
    <i *ngIf="punch?.isDirty" class="dirty-indicator fa fa-exclamation" aria-hidden="true"></i>
    <span *ngIf="punch?.punchDisplay === unknown" class="display-message invalid-display-status">{{punch?.punchDisplay}}</span>
    <span *ngIf="punch?.punchDisplay === schedule" class="display-message scheduled-display-status">{{punch?.punchDisplay}}</span>
    <span *ngIf="punch?.punchDisplay === scheduleExempt" class="display-message scheduled-display-status">{{punch?.punchDisplay}}</span>
    <span *ngIf="punch?.punchDisplay === invalidPunch" class="display-message invalid-display-status">{{punch?.punchDisplay}}</span>
    <span *ngIf="punch?.punchDisplay === deletedPunch" class="display-message invalid-display-status">{{punch?.punchDisplay}}</span>
    <span *ngIf="punch?.punchDisplay === validPunch" class="display-message valid-display-status">{{punch?.punchDisplay}}</span>
    <span *ngIf="punch?.punchDisplay === validEdited" class="display-message valid-display-status">{{punch?.punchDisplay}}</span>
    <span *ngIf="punch?.punchDisplay === punchRequest" class="display-message scheduled-display-status">{{punch?.punchDisplay}}</span>
    <span *ngIf="punch?.punchDisplay === edited" class="display-message edited-display-status">{{punch?.punchDisplay}}</span>
    <span *ngIf="punch?.punchDisplay === overlap" class="display-message invalid-display-status">{{punch?.punchDisplay}}</span>
    <span *ngIf="punch?.punchDisplay === invalidLogin" class="display-message invalid-display-status">{{punch?.punchDisplay}}</span>
    <span *ngIf="punch?.isDeleted" class="deleted-status"><i class="fas fa-times" aria-hidden="true"></i></span>
  </div>
  <button type="button" *ngIf="punch?.punchDisplay === punchRequest ||  (punch?.comment !== '' || null)" class="slx-inore-by-kendo-row-click-directive theme-icon-button theme-inline-apply-button comment-button"
    (click)="onShowComment(punch)">
    <i class="far fa-comment-alt" aria-hidden="true"></i>
  </button>
</div>
