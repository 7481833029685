import * as _ from 'lodash';
import * as moment from 'moment';
import { EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { NgModel } from '@angular/forms';
import { dateTimeUtils } from '../../../common/utils/index';
import { RangeDates } from '../../../common/models/range-dates';
import { DateRangeValue } from '../../models/index';
import { appConfig } from '../../../app.config';
var DaterangeInputComponent = /** @class */ (function () {
    function DaterangeInputComponent() {
        this.changeButtonStyling = false;
        this.format = 'MM/dd/yyyy';
        this.startDatePlaceholder = 'From';
        this.endDatePlaceholder = 'To';
        this.dates = new RangeDates();
        this.dates.startDate = null;
        this.dates.endDate = null;
        this.rangeDateChanged = new EventEmitter();
        this.valueChanged = new EventEmitter();
        this.appConfig = appConfig;
        this.isValidRange = true;
        this.today = moment().endOf('day').toDate();
        this.disableFutureDates = false;
    }
    Object.defineProperty(DaterangeInputComponent.prototype, "datesValid", {
        get: function () {
            return this.isValidRange && this.isValidDate(this.dates.startDate) && this.isValidDate(this.dates.startDate) && this.isValidFields;
        },
        enumerable: true,
        configurable: true
    });
    DaterangeInputComponent.prototype.ngOnChanges = function (changes) {
        var startDate = _.get(changes, 'startDate.currentValue', null);
        var endDate = _.get(changes, 'endDate.currentValue', null);
        this.maxDateForEndDate = this.disableFutureDates ? this.today : null;
        if (this.isValidDate(startDate)) {
            this.dates.startDate = moment(startDate).toDate();
        }
        if (this.isValidDate(endDate)) {
            this.dates.endDate = moment(endDate).toDate();
        }
        this.checkValidRange();
    };
    DaterangeInputComponent.prototype.onRangeDateChanged = function (startDate, endDate) {
        var _this = this;
        Promise.resolve(null)
            .then(function () {
            if (_this.isValidDate(startDate) && _this.isValidDate(endDate)) {
                _this.dates.startDate = startDate;
                _this.dates.endDate = endDate;
                _this.checkValidRange();
                if (_this.isValidRange) {
                    _this.emitRangeDateChanged(true);
                }
            }
        });
    };
    DaterangeInputComponent.prototype.onStartDateChanged = function (date) {
        var _this = this;
        Promise.resolve(null)
            .then(function () {
            if (_this.isValidDate(date)) {
                _this.dates.startDate = date;
                _this.checkValidRange();
                if (_this.isValidFields) {
                    _this.emitRangeDateChanged(false);
                }
            }
        });
    };
    DaterangeInputComponent.prototype.onEndDateChanged = function (date) {
        var _this = this;
        Promise.resolve(null)
            .then(function () {
            if (_this.isValidDate(date)) {
                _this.dates.endDate = date;
                _this.checkValidRange();
                if (_this.isValidFields) {
                    _this.emitRangeDateChanged(false);
                }
            }
        });
    };
    DaterangeInputComponent.prototype.onValueChanged = function (controlName, isValid, date) {
        var sDate = null;
        var eDate = null;
        switch (controlName) {
            case 'startDate':
                sDate = date;
                break;
            case 'endDate':
                eDate = date;
                break;
            default:
                throw new Error("The parameter \"controlName\" has incorret value, check what you passed into arguments. controlName: " + JSON.stringify(controlName));
        }
        this.valueChanged.emit(new DateRangeValue(sDate, eDate, isValid));
    };
    DaterangeInputComponent.prototype.onApply = function () {
        if (this.datesValid) {
            this.emitRangeDateChanged(true);
        }
    };
    Object.defineProperty(DaterangeInputComponent.prototype, "isValidFields", {
        get: function () {
            return this.startDatePicker.valid && this.endDatePicker.valid;
        },
        enumerable: true,
        configurable: true
    });
    DaterangeInputComponent.prototype.emitRangeDateChanged = function (force) {
        if (force || !this.hasApplyButton) {
            this.rangeDateChanged.emit(this.dates);
        }
    };
    DaterangeInputComponent.prototype.isValidDate = function (date) {
        return (!_.isNull(date) && !_.isUndefined(date)) && dateTimeUtils.validateDate(date);
    };
    DaterangeInputComponent.prototype.checkValidRange = function () {
        if (!this.maxSecRange || this.maxSecRange <= 0 || !this.isValidFields) {
            this.isValidRange = true;
            return;
        }
        this.isValidRange = moment(this.dates.endDate).diff(this.dates.startDate, 's') < this.maxSecRange;
    };
    return DaterangeInputComponent;
}());
export { DaterangeInputComponent };
