import { IConfigutrationContainer } from '../configiration-container.interface';
import { PayCodeModel } from './pay-code.model';
import { Actions } from '../../../core/models/index';
import { PaycodeExceptionSortOverrideModel } from './paycode-exceptionsortoverride';


export class PayCodesContainer implements IConfigutrationContainer {
    public records: PayCodeModel[];
    public actions: Actions;
    public canEditExceptionFlag: boolean;
    public canEditPaycodestoAccrualMappingFlag: boolean;
    public exceptionsSortOverrides: PaycodeExceptionSortOverrideModel[];

    // Combined records to satisfy IConfigutrationContainer interface
    public recordDetails: (PayCodeModel | PaycodeExceptionSortOverrideModel)[];

    constructor() {
        this.records = [];
        this.exceptionsSortOverrides = [];
    }

    public sortRecordsWithOverrides(dirtyExceptions: PayCodeModel[], dirtySortOrdersRecords: PaycodeExceptionSortOverrideModel[]) {
        if (dirtySortOrdersRecords.length > 0) {

            if (dirtySortOrdersRecords.length > 0) {

                if (!dirtySortOrdersRecords || !dirtyExceptions) {
                    console.error("Missing data for join operation.");
                    return;
                }

                const joinedResult = dirtyExceptions.map(record => {
                    const override = dirtySortOrdersRecords.find(o => o.exceptionId === record.payCode.id);
                    return {
                        ...record,
                        overrideSortOrder: override ? override.overrideSortOrder : Number.MAX_VALUE
                    };
                });

                const sortedRecords = joinedResult.slice().sort((a, b) => a.overrideSortOrder - b.overrideSortOrder);

                const finalRecords: PayCodeModel[] = sortedRecords.map(({ overrideSortOrder, ...rest }) => rest as PayCodeModel);

                this.records = finalRecords;
            }

            if (dirtySortOrdersRecords.length === 0) {

                const sortedRecords = dirtyExceptions
                    .map(record => ({
                        ...record,
                        allocationTypeId: record.payCode.allocationTypeId
                    }))
                    .sort((a, b) => a.allocationTypeId - b.allocationTypeId);

                this.records = sortedRecords.map(({ allocationTypeId, ...rest }) => rest as PayCodeModel);
            }
        }
    }
}
