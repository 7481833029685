import * as tslib_1 from "tslib";
import { AppSettingsManageService } from './../../../../app-settings/services/app-settings-manage.service';
import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Assert } from '../../../../framework/index';
import { mutableSelect, unsubscribeAll } from '../../../../core/decorators/index';
import { OrgLevelType } from '../../../../state-model/models/index';
import { NotificationsService } from '../../../../core/components/index';
import { StateResetTypes } from '../../../../core/models/index';
import { StateManagementService, ComponentStateStorageService, BenefitsNavigationService, ChangeManagementService } from '../../../../common/services/index';
import { BenefitDetailsProvider, BenefitDetailsProviderEntity, BenefitDetailsLineStandart, BenefitDetailsProviderLineStandartEntity, BenefitLineTypeEnum, ExtendExpireRenewModel, BenefitDetailsPageState, } from '../../models/index';
import { BenefitDetailsPermissionService } from './benefit-details-permission.service';
import { BenefitDetailsApiService } from './benefit-details-api.service';
import { BenefitEligibilityRulesManagementService } from './../../services/benefit-eligibility-rules/benefit-eligibility-rules-management.service';
import { OrgLevelWatchService } from '../../../../organization/services/index';
import { LookupApiService } from '../../../../organization/services/index';
import { BenefitConsoleDetailsCommonService } from '../benefit-console/benefit-console-details-common.service';
import * as moment from 'moment';
var BenefitDetailsManagementService = /** @class */ (function () {
    function BenefitDetailsManagementService(permissionService, apiService, stateService, storageService, notificationService, orgLevelWatchService, rulesManagementService, lookupApiService, commonService, changeManagement, appSettingsManageService) {
        this.permissionService = permissionService;
        this.apiService = apiService;
        this.stateService = stateService;
        this.storageService = storageService;
        this.notificationService = notificationService;
        this.orgLevelWatchService = orgLevelWatchService;
        this.rulesManagementService = rulesManagementService;
        this.lookupApiService = lookupApiService;
        this.commonService = commonService;
        this.changeManagement = changeManagement;
        this.appSettingsManageService = appSettingsManageService;
        this.providers = [];
        this.loadExpriredPlans = false;
        this.lastSelected = {
            provider: null,
            providerLine: null,
        };
        this.toSelect = { groupId: null, providerId: null, providerLineId: null };
        this.createNewProvider = false;
        this.tierAttachmentsToDelete = new Map();
        this.loading$ = new Subject();
        this.loadingEligibleEmployees$ = new Subject();
        this.loadingEnrolledEmployees$ = new Subject();
        this.orgLevelChanged$ = new ReplaySubject(1);
        this.configChanged$ = new ReplaySubject(1);
        this.loadProviders$ = new ReplaySubject(1);
        this.selectGroup$ = new ReplaySubject(1);
        this.selectProvider$ = new ReplaySubject(1);
        this.selectProviderLine$ = new ReplaySubject(1);
        this.expireExtendRenew$ = new Subject();
        this.acaCategoryLookup$ = new Subject();
        this.createNewProvider$ = new Subject();
        this.defaultProviderName = 'New';
        this.defaultProviderLineName = 'New';
        this.subscriptions = {};
        this.pageState = null;
        this.controlId = 'pageState';
        this.resetBy = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;
        this.isBenefitDeductionEnabled2 = false;
    }
    BenefitDetailsManagementService.prototype.init = function (componentId, router, activatedRoute) {
        this.componentId = componentId;
        this.changeManagement.setCurrentComponentId(this.componentId);
        this.storeQueryParams(activatedRoute);
        this.subscribeToConsoleService();
        this.navService = new BenefitsNavigationService(router, activatedRoute);
        this.stateService.init(componentId, true);
        this.subscribeToOrgLevelChanges();
    };
    BenefitDetailsManagementService.prototype.destroy = function () {
        this.orgLevel = null;
        this.loading$.complete();
        this.loadingEligibleEmployees$.complete();
        this.loadingEnrolledEmployees$.complete();
        this.orgLevelChanged$.complete();
        this.configChanged$.complete();
        this.loadProviders$.complete();
        this.selectGroup$.complete();
        this.selectProvider$.complete();
        this.selectProviderLine$.complete();
        this.expireExtendRenew$.complete();
        this.acaCategoryLookup$.complete();
    };
    BenefitDetailsManagementService.prototype.subscribeToLoading = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loading$.subscribe(callback);
    };
    BenefitDetailsManagementService.prototype.subscribeToEligibleEmployeesLoading = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loadingEligibleEmployees$.subscribe(callback);
    };
    BenefitDetailsManagementService.prototype.subscribeToEnrolledEmployeesLoading = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loadingEnrolledEmployees$.subscribe(callback);
    };
    BenefitDetailsManagementService.prototype.subscribeToOrgLevel = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.orgLevelChanged$.subscribe(callback);
    };
    BenefitDetailsManagementService.prototype.subscribeToChangeConfig = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.configChanged$.subscribe(callback);
    };
    BenefitDetailsManagementService.prototype.subscribeToLoadProviders = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loadProviders$.subscribe(callback);
    };
    BenefitDetailsManagementService.prototype.subscribeToSelectGroup = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.selectGroup$.subscribe(callback);
    };
    BenefitDetailsManagementService.prototype.subscribeToSelectProvider = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.selectProvider$.subscribe(callback);
    };
    BenefitDetailsManagementService.prototype.subscribeToSelectProviderLine = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.selectProviderLine$.subscribe(callback);
    };
    BenefitDetailsManagementService.prototype.subscribeToAcaCategoryLookup = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.acaCategoryLookup$.subscribe(callback);
    };
    BenefitDetailsManagementService.prototype.subscribeToExpireExtendRenew = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.expireExtendRenew$.subscribe(callback);
    };
    BenefitDetailsManagementService.prototype.subscribeToCreateNewProvider = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.createNewProvider$.subscribe(callback);
    };
    Object.defineProperty(BenefitDetailsManagementService.prototype, "isLoadingExpiredPlans", {
        get: function () {
            return this.loadExpriredPlans;
        },
        set: function (isLoading) {
            if (this.loadExpriredPlans !== isLoading) {
                this.loadExpriredPlans = isLoading;
                if (_.isObjectLike(this.selectedGroup)) {
                    this.loadBenefitProviders(this.selectedGroup.id, isLoading);
                }
                this.commonService.saveIsShowingExpiredPlansState(isLoading);
            }
        },
        enumerable: true,
        configurable: true
    });
    BenefitDetailsManagementService.prototype.isUniqueName = function (list, id, name) {
        var trimmedName = _.toLower(_.trim(name));
        if (_.isArray(list) && _.size(list) > 0) {
            var foundedItems = _.find(list, function (p) { return p.id !== id && _.toLower(p.name) === trimmedName; });
            return _.isNil(foundedItems);
        }
        return true;
    };
    BenefitDetailsManagementService.prototype.isUniqueERCode = function (list, id, employerDeductionCode) {
        var trimmedName = _.toLower(_.trim(employerDeductionCode));
        if (_.isArray(list) && _.size(list) > 0) {
            var foundedItems = _.find(list, function (p) { return trimmedName && _.toLower(_.trim(p.payrollDeductionCode)) === trimmedName; });
            return _.isNil(foundedItems);
        }
        return true;
    };
    BenefitDetailsManagementService.prototype.isUniqueEECode = function (list, id, payrollDeductionCode) {
        var trimmedName = _.toLower(_.trim(payrollDeductionCode));
        if (_.isArray(list) && _.size(list) > 0) {
            var foundedItems = _.find(list, function (p) { return trimmedName && _.toLower(_.trim(p.employerDeductionCode)) === trimmedName; });
            return _.isNil(foundedItems);
        }
        return true;
    };
    BenefitDetailsManagementService.prototype.isEqualProviders = function (e1, e2) {
        if (_.isObjectLike(e1) && _.isObjectLike(e2)) {
            var p1 = e1.current;
            var p2 = e2.current;
            if (_.isNumber(p1.id) && _.isNumber(p2.id)) {
                return p1.id === p2.id;
            }
            return p1.name === p2.name && p1.orgLevelId === p2.orgLevelId && p1.benefitGroupId === p2.benefitGroupId;
        }
        return e1 === e2;
    };
    BenefitDetailsManagementService.prototype.updateSpinner = function (isLoading) {
        this.loading$.next(isLoading);
    };
    BenefitDetailsManagementService.prototype.updateEligibleEmployeesSpinner = function (isLoading) {
        this.loadingEligibleEmployees$.next(isLoading);
    };
    BenefitDetailsManagementService.prototype.updateEnrolledEmployeesSpinner = function (isLoading) {
        this.loadingEnrolledEmployees$.next(isLoading);
    };
    BenefitDetailsManagementService.prototype.storeProviderAndLine = function (isDraft) {
        this.markAsDirty();
        if (isDraft) {
            this.lastSelected.provider = this.selectedProvider;
            this.lastSelected.providerLine = this.selectedProviderLine;
        }
        else {
            this.selectedProvider.storeEntity();
            this.selectedProviderLine.storeEntity();
        }
    };
    BenefitDetailsManagementService.prototype.restoreProviderAndLine = function (isDraft) {
        var provider = null;
        var providerLine = null;
        if (isDraft) {
            provider = this.lastSelected.provider;
            providerLine = this.lastSelected.providerLine;
            this.lastSelected.provider = this.lastSelected.providerLine = null;
            this.selectedProvider = null;
            this.selectedProviderLine = null;
        }
        else {
            provider = this.selectedProvider;
            providerLine = this.selectedProviderLine;
            if (this.selectedProvider.isStored) {
                provider = this.selectedProvider.restoreEntity();
            }
            if (this.selectedProviderLine.isStored) {
                providerLine = this.selectedProviderLine.restoreEntity();
            }
        }
        this.clearChanges();
        this.tierAttachmentsToDelete.clear();
        this.selectProvider(provider, false, true);
        this.selectProviderLine(providerLine, true);
    };
    BenefitDetailsManagementService.prototype.selectDraftProviderAndLine = function () {
        var draftProvider = this.createBenefitProviderEntity();
        var draftProviderLine = this.createBenefitProviderLineEntity(BenefitLineTypeEnum.Standart);
        this.providers = this.providers.concat([draftProvider]);
        this.loadProviders$.next(this.providers);
        this.selectProvider(draftProvider, false);
        this.selectProviderLine(draftProviderLine);
    };
    BenefitDetailsManagementService.prototype.removeDraftProvider = function () {
        if (this.selectedProvider.isDraft) {
            this.rulesManagementService.clearExistingMappedRules();
            this.updateProvidersList(this.selectedProvider, null);
        }
    };
    BenefitDetailsManagementService.prototype.getProperOrglevelBasedOnSelectedOrglevel = function () {
        if (!this.selectedProvider) {
            return;
        }
        var providerOrgLevelId = this.selectedProvider.current.orgLevelId;
        var _a = this.validateSelectedOrglevelAgainstProvider(this.orgLevel.id, providerOrgLevelId), orglevelsNotFound = _a.orglevelsNotFound, isSameAsProvider = _a.isSameAsProvider, isUnderProvider = _a.isUnderProvider;
        if (orglevelsNotFound || isSameAsProvider || isUnderProvider) {
            return this.orgLevel.id;
        }
        return providerOrgLevelId;
    };
    BenefitDetailsManagementService.prototype.validateSelectedOrglevelAgainstProvider = function (selectedOrglevelId, providerOrglevelId) {
        var orglevelsList = this.orgLevelWatchService.getFlatList();
        var providerOrglevel = _.find(orglevelsList, function (o) { return o.orgLevel.id === providerOrglevelId; });
        var selectedOrglevel = _.find(orglevelsList, function (o) { return o.orgLevel.id === selectedOrglevelId; });
        var notFound = !_.isObjectLike(providerOrglevel) || !_.isObjectLike(selectedOrglevel);
        return {
            orglevelsNotFound: notFound,
            isSameAsProvider: !notFound && providerOrglevel.orgLevel.id === selectedOrglevel.orgLevel.id,
            isUnderProvider: !notFound && _.includes(selectedOrglevel.orgLevel.treeViewPath, providerOrglevel.orgLevel.id)
        };
    };
    BenefitDetailsManagementService.prototype.selectProvider = function (p, isRequireUpdate, byPass) {
        if (byPass === void 0) { byPass = false; }
        if (_.isObjectLike(p)) {
            var _a = this.validateSelectedOrglevelAgainstProvider(this.orgLevel.id, p.current.orgLevelId), orglevelsNotFound = _a.orglevelsNotFound, isSameAsProvider = _a.isSameAsProvider, isUnderProvider = _a.isUnderProvider;
            p.requireUpdate = isRequireUpdate;
            this.emitProvider(p, byPass);
            this.permissionService.setOrgLevelPermissions(!orglevelsNotFound && (isSameAsProvider || !isUnderProvider), null);
            if (_.size(p.current.benefitLines) === 0) {
                this.updateSpinner(false);
            }
        }
    };
    BenefitDetailsManagementService.prototype.emitProvider = function (p, byPass) {
        if (byPass === void 0) { byPass = false; }
        if (_.isObjectLike(p) && (!this.isEqualProviders(this.selectedProvider, p) || byPass)) {
            this.restoreSelectedProviderLine(p);
            this.selectedProvider = p;
            this.selectProvider$.next(this.selectedProvider);
            this.updateQueryParams(null, p);
        }
    };
    BenefitDetailsManagementService.prototype.selectProviderLine = function (pl, byPass) {
        if (byPass === void 0) { byPass = false; }
        var sPL = this.selectedProviderLine;
        if (_.isObjectLike(pl) && (!_.isObjectLike(sPL) || sPL.current.id !== pl.current.id || byPass)) {
            this.restoreSelectedTier(sPL, pl);
            this.selectedProviderLine = pl;
            this.selectedProviderLine.nextLineDetails = this.getNextLine(pl.current.id);
            this.selectedProvider.current.name = pl.current.name;
            this.selectedProvider.current.storedName = pl.current.name;
            this.selectProviderLine$.next(this.selectedProviderLine);
            this.updateQueryParams(null, null, pl);
        }
    };
    BenefitDetailsManagementService.prototype.selectGroup = function (g, byPass) {
        if (byPass === void 0) { byPass = false; }
        if (_.isObjectLike(g) && (!_.isObjectLike(this.selectedGroup) || this.selectedGroup.id !== g.id || byPass)) {
            this.selectedGroup = g;
            this.selectGroup$.next(g);
            if (_.isObjectLike(this.selectedProvider)) {
                this.selectedProvider.requireUpdate = false;
            }
            this.updateQueryParams(g);
            this.loadBenefitProviders(g.id, this.loadExpriredPlans);
        }
    };
    BenefitDetailsManagementService.prototype.saveSelectedTierId = function (ptId) {
        if (_.isFinite(ptId) && this.pageState.selectedProviderTierId !== ptId) {
            this.pageState.selectedProviderTierId = ptId;
            this.storageService.setControlState(this.componentId, this.controlId, { value: this.pageState }, this.resetBy);
        }
    };
    BenefitDetailsManagementService.prototype.saveBenefitProvider = function (callbackLoadProviderLine) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, provider, providerLine, needsToSelectProvider, firstProvider, e_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.updateSpinner(true);
                        this.clearChanges();
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 7, 8, 9]);
                        return [4 /*yield*/, this.apiService.saveBenefitProvider(this.selectedProvider.current, this.selectedProviderLine.current)];
                    case 2:
                        _a = _b.sent(), provider = _a.provider, providerLine = _a.providerLine;
                        return [4 /*yield*/, this.rulesManagementService.saveRules(this.orgLevel.id, providerLine.current.tiers)];
                    case 3:
                        _b.sent();
                        if (!this.selectedProviderLine.hasTiersAttachments) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.apiService.saveTiersAttachments(providerLine.current.tiers, this.selectedProviderLine.current.tiers)];
                    case 4:
                        _b.sent();
                        _b.label = 5;
                    case 5:
                        this.tierAttachmentsToDelete.clear();
                        needsToSelectProvider = this.loadExpriredPlans || !provider.current.allLinesExpired;
                        this.updateProvidersList(this.selectedProvider, needsToSelectProvider ? provider : null);
                        firstProvider = _.head(this.providers);
                        this.selectProvider(needsToSelectProvider ? provider : firstProvider, false);
                        return [4 /*yield*/, callbackLoadProviderLine(needsToSelectProvider ? providerLine.current.line : _.head(firstProvider.current.benefitLines))];
                    case 6:
                        _b.sent();
                        this.notificationService.success('Provider Created', 'Benefit Provider created successfully');
                        return [3 /*break*/, 9];
                    case 7:
                        e_1 = _b.sent();
                        console.error(e_1);
                        return [3 /*break*/, 9];
                    case 8:
                        this.updateSpinner(false);
                        return [7 /*endfinally*/];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    BenefitDetailsManagementService.prototype.saveBenefitProviderLine = function (callbackLoadProviderLine, providerPlandate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var providerLine, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.updateSpinner(true);
                        this.clearChanges();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 9, 10, 11]);
                        return [4 /*yield*/, this.apiService.saveBenefitDetailsLineStandart(this.selectedProvider.current.name, this.selectedProviderLine.current, providerPlandate)];
                    case 2:
                        providerLine = _a.sent();
                        return [4 /*yield*/, this.rulesManagementService.saveRules(this.orgLevel.id, providerLine.current.tiers)];
                    case 3:
                        _a.sent();
                        if (!(this.tierAttachmentsToDelete.size > 0)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.apiService.deleteTiersAttachments(this.tierAttachmentsToDelete)];
                    case 4:
                        _a.sent();
                        this.tierAttachmentsToDelete.clear();
                        _a.label = 5;
                    case 5:
                        if (!this.selectedProviderLine.hasTiersAttachments) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.apiService.saveTiersAttachments(providerLine.current.tiers, this.selectedProviderLine.current.tiers)];
                    case 6:
                        _a.sent();
                        this.selectedProviderLine.hasTiersAttachments = false;
                        _a.label = 7;
                    case 7:
                        this.selectedProvider.dropStored();
                        return [4 /*yield*/, callbackLoadProviderLine(providerLine.current.line)];
                    case 8:
                        _a.sent();
                        this.notificationService.success('Provider Saved', 'Benefits Provider saved successfully');
                        return [3 /*break*/, 11];
                    case 9:
                        e_2 = _a.sent();
                        console.error(e_2);
                        return [3 /*break*/, 11];
                    case 10:
                        this.updateSpinner(false);
                        return [7 /*endfinally*/];
                    case 11: return [2 /*return*/];
                }
            });
        });
    };
    BenefitDetailsManagementService.prototype.deleteBenefitProvider = function (callbackLoadProviderLine) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var firstProvider, e_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.updateSpinner(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        return [4 /*yield*/, this.apiService.deleteBenefitProvider(this.selectedProvider.current.id)];
                    case 2:
                        _a.sent();
                        this.updateProvidersList(this.selectedProvider, null);
                        firstProvider = _.head(this.providers);
                        this.selectProvider(firstProvider, false);
                        return [4 /*yield*/, callbackLoadProviderLine(_.head(firstProvider.current.benefitLines))];
                    case 3:
                        _a.sent();
                        this.notificationService.success('Provider Deleted', 'Benefit Provider deleted successfully');
                        return [3 /*break*/, 6];
                    case 4:
                        e_3 = _a.sent();
                        console.error(e_3);
                        return [3 /*break*/, 6];
                    case 5:
                        this.updateSpinner(false);
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    BenefitDetailsManagementService.prototype.deleteTierAttachments = function (tierId, delAttachmentIds) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var attachmentsIds;
            return tslib_1.__generator(this, function (_a) {
                attachmentsIds = this.tierAttachmentsToDelete.get(tierId) || [];
                this.tierAttachmentsToDelete.set(tierId, attachmentsIds.concat(delAttachmentIds));
                return [2 /*return*/];
            });
        });
    };
    BenefitDetailsManagementService.prototype.loadProviderLine = function (id, byPass) {
        if (byPass === void 0) { byPass = false; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var pl;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        pl = this.selectedProviderLine;
                        if (!(_.isFinite(id) && (!_.isObjectLike(pl) || pl.current.id !== id || byPass))) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.loadBenefitProviderLine(id)];
                    case 1:
                        _a.sent();
                        this.emitCreateNewProvider();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    BenefitDetailsManagementService.prototype.loadBenefitProviderLine = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var pl, e_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.updateSpinner(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.apiService.getBenefitDetailsLineStandart(id)];
                    case 2:
                        pl = _a.sent();
                        this.selectProviderLine(pl, true);
                        return [3 /*break*/, 5];
                    case 3:
                        e_4 = _a.sent();
                        console.error(e_4);
                        return [3 /*break*/, 5];
                    case 4:
                        this.updateSpinner(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    BenefitDetailsManagementService.prototype.loadBenefitProviders = function (groupId, isLoadingExprired) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var providers, e_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.updateSpinner(true);
                        providers = [];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.apiService.getBenefitProviders(this.orgLevel.id, groupId, isLoadingExprired)];
                    case 2:
                        providers = _a.sent();
                        this.applyProviders(providers);
                        return [3 /*break*/, 5];
                    case 3:
                        e_5 = _a.sent();
                        console.error(e_5);
                        this.updateSpinner(false);
                        return [3 /*break*/, 5];
                    case 4:
                        if (_.size(providers) === 0) {
                            this.updateSpinner(false);
                            this.emitCreateNewProvider();
                        }
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    BenefitDetailsManagementService.prototype.applyProviders = function (providers) {
        this.providers = providers;
        this.loadProviders$.next(this.providers);
        this.detectSelectedProvider(providers);
    };
    BenefitDetailsManagementService.prototype.detectSelectedProvider = function (providers) {
        var _this = this;
        var selectedProvider = _.head(providers);
        if (_.isFinite(this.toSelect.providerId)) {
            selectedProvider = _.find(providers, function (p) { return p.current.id === _this.toSelect.providerId; }) || selectedProvider;
        }
        this.selectedProvider = this.selectedProviderLine = this.toSelect.providerId = null;
        this.selectProvider(selectedProvider, true);
    };
    BenefitDetailsManagementService.prototype.loadConfig = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, config, e_6;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.updateSpinner(true);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 4, , 5]);
                        _a = this;
                        return [4 /*yield*/, this.getBenefitDeductionEnabled2()];
                    case 2:
                        _a.isBenefitDeductionEnabled2 = _b.sent();
                        return [4 /*yield*/, this.apiService.getBenefitDetailsConfig(this.orgLevel.id)];
                    case 3:
                        config = _b.sent();
                        this.applyConfig(config);
                        return [3 /*break*/, 5];
                    case 4:
                        e_6 = _b.sent();
                        console.error(e_6);
                        this.updateSpinner(false);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    BenefitDetailsManagementService.prototype.applyConfig = function (config) {
        this.detectSelectedGroup(config);
        this.configChanged$.next(config);
        var canAdd = config.canAdd, canEdit = config.canEdit, canDelete = config.canDelete, canEditFormula = config.canEditFormula, canRenew = config.canRenew, canExpire = config.canExpire, canExtend = config.canExtend, canEnroll = config.canEnroll, canMapPayroll = config.canMapPayroll;
        var benefitDeductionEnabled = canMapPayroll && this.isBenefitDeductionEnabled2;
        this.permissionService.setExternalPermissions(canAdd, canEdit, canDelete, canEditFormula, canRenew, canExpire, canExtend, canEnroll, canMapPayroll, benefitDeductionEnabled);
    };
    BenefitDetailsManagementService.prototype.detectSelectedGroup = function (config) {
        var selectedGroup = _.head(config.groups);
        if (_.isFinite(this.toSelect.groupId)) {
            selectedGroup = _.find(config.groups, { id: this.toSelect.groupId }) || selectedGroup;
            this.toSelect.groupId = null;
        }
        this.selectGroup(selectedGroup, true);
    };
    BenefitDetailsManagementService.prototype.expirePlan = function (newEndDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var providerLine, e_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.updateSpinner(true);
                        this.clearChanges();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiService.expireBenefitPlan(this.selectedProviderLine.current.id, newEndDate)];
                    case 2:
                        providerLine = _a.sent();
                        this.expireExtendRenew$.next(new ExtendExpireRenewModel(false, null, providerLine));
                        this.notificationService.success('Provider Saved', 'Benefits Provider saved successfully');
                        return [3 /*break*/, 4];
                    case 3:
                        e_7 = _a.sent();
                        console.error(e_7);
                        this.updateSpinner(false);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    BenefitDetailsManagementService.prototype.extendPlan = function (newEndDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var providerLine, e_8;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.updateSpinner(true);
                        this.clearChanges();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiService.extendBenefitPlan(this.selectedProviderLine.current.id, newEndDate)];
                    case 2:
                        providerLine = _a.sent();
                        this.expireExtendRenew$.next(new ExtendExpireRenewModel(false, null, providerLine));
                        this.notificationService.success('Provider Saved', 'Benefits Provider saved successfully');
                        return [3 /*break*/, 4];
                    case 3:
                        e_8 = _a.sent();
                        console.error(e_8);
                        this.updateSpinner(false);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    BenefitDetailsManagementService.prototype.renewPlan = function (newEndDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var providerLine, e_9;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.updateSpinner(true);
                        this.clearChanges();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiService.renewBenefitPlan(this.selectedProviderLine.current.id, newEndDate)];
                    case 2:
                        providerLine = _a.sent();
                        this.expireExtendRenew$.next(new ExtendExpireRenewModel(true, providerLine, null));
                        this.notificationService.success('Provider Saved', 'Benefits Provider saved successfully');
                        return [3 /*break*/, 4];
                    case 3:
                        e_9 = _a.sent();
                        console.error(e_9);
                        this.updateSpinner(false);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    BenefitDetailsManagementService.prototype.createBenefitProviderEntity = function () {
        var provider = new BenefitDetailsProvider();
        provider.id = -1;
        provider.name = this.defaultProviderName;
        provider.storedName = this.defaultProviderName;
        provider.allLinesExpired = false;
        provider.orgLevelId = this.orgLevel.id;
        provider.orgLevelName = this.orgLevel.name;
        provider.benefitGroupId = this.selectedGroup.id;
        var entity = new BenefitDetailsProviderEntity();
        entity.current = provider;
        entity.requireUpdate = false;
        entity.markAsDraft();
        return entity;
    };
    BenefitDetailsManagementService.prototype.createBenefitProviderLineEntity = function (benefitLineType) {
        var line = new BenefitDetailsLineStandart();
        line.line.id = -1;
        line.line.name = this.defaultProviderLineName;
        line.line.startDate = moment().startOf('day').toDate();
        line.line.endDate = moment().add('year', 1).endOf('day').toDate();
        line.line.benefitLineType = benefitLineType;
        line.includeInReport = false;
        var entity = new BenefitDetailsProviderLineStandartEntity();
        entity.current = line;
        entity.markAsDraft();
        return entity;
    };
    BenefitDetailsManagementService.prototype.restoreSelectedProviderLine = function (p) {
        if (_.isFinite(this.toSelect.providerLineId)) {
            p.selectedLineId = this.toSelect.providerLineId;
            this.toSelect.providerLineId = null;
        }
    };
    BenefitDetailsManagementService.prototype.restoreSelectedTier = function (prevPL, newPL) {
        if (_.isObjectLike(newPL)) {
            if (_.isFinite(this.pageState.selectedProviderTierId)) {
                newPL.selectedTierId = this.pageState.selectedProviderTierId;
                this.pageState.selectedProviderTierId = null;
            }
            else if (_.isObjectLike(prevPL) && prevPL.current.id === newPL.current.id) {
                newPL.selectedTierId = prevPL.selectedTierId;
            }
        }
    };
    BenefitDetailsManagementService.prototype.subscribeToOrgLevelChanges = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.stateService.onInit$
            .combineLatest(this.orgLevel$.filter(function (o) { return _.isFinite(_.get(o, 'id')); }))
            .subscribe(function (_a) {
            var s = _a[0], orgLevel = _a[1];
            if (!_.isObjectLike(_this.pageState)) {
                var state = _this.storageService.getControlState(_this.componentId, _this.controlId);
                _this.pageState = (_.isObjectLike(state.value) && state.value) || new BenefitDetailsPageState();
            }
            if (!_.isObjectLike(_this.orgLevel) || _this.orgLevel.id !== orgLevel.id) {
                _this.orgLevel = orgLevel;
                _this.orgLevelChanged$.next(_this.orgLevel);
                if (orgLevel.type === OrgLevelType.department)
                    _this.createNewProvider = false;
                _this.permissionService.setOrgLevelPermissions(null, orgLevel.type !== OrgLevelType.department);
                _this.loadConfig();
            }
        });
    };
    BenefitDetailsManagementService.prototype.subscribeToConsoleService = function () {
        var _this = this;
        this.commonService.restoreState();
        this.subscriptions.consoleService = this.commonService.subscribeToShowExpiredPlansState(function (isShow) {
            _this.loadExpriredPlans = isShow;
        });
    };
    BenefitDetailsManagementService.prototype.updateProvidersList = function (prevP, newP) {
        var _this = this;
        var providers = this.providers.concat();
        var index = _.findIndex(providers, function (pr) { return _this.isEqualProviders(pr, prevP); });
        if (index !== -1) {
            providers.splice.apply(providers, _.isNil(newP) ? [index, 1] : [index, 1, newP]);
            this.providers = providers;
            this.loadProviders$.next(this.providers);
        }
    };
    BenefitDetailsManagementService.prototype.storeQueryParams = function (activatedRoute) {
        var queryParams = activatedRoute.snapshot.queryParams || {};
        var gId = (this.toSelect.groupId = parseInt(queryParams.groupId) || null);
        var pId = (this.toSelect.providerId = parseInt(queryParams.providerId) || null);
        var plId = (this.toSelect.providerLineId = parseInt(queryParams.providerLineId) || null);
        this.createNewProvider = _.isFinite(gId) && !_.isFinite(pId) && !_.isFinite(plId);
    };
    BenefitDetailsManagementService.prototype.updateQueryParams = function (g, p, pl) {
        if (_.isObjectLike(g) && _.isFinite(g.id)) {
            this.toSelect.groupId = g.id;
            this.navService.updateQueryParams(g.id);
        }
        if (_.isObjectLike(p) && !p.isDraft && _.isFinite(p.current.id)) {
            this.toSelect.providerId = p.current.id;
            this.navService.updateQueryParams(null, p.current.id);
        }
        if (_.isObjectLike(pl) && !pl.isDraft && _.isFinite(pl.current.id)) {
            this.toSelect.providerLineId = pl.current.id;
            this.navService.updateQueryParams(null, null, pl.current.id);
        }
    };
    BenefitDetailsManagementService.prototype.emitCreateNewProvider = function () {
        var needCreateNewProvider = this.createNewProvider;
        this.createNewProvider = false;
        if (needCreateNewProvider && (!_.isObjectLike(this.selectedProvider) || !this.selectedProvider.isDraft)) {
            this.createNewProvider$.next(needCreateNewProvider);
        }
    };
    BenefitDetailsManagementService.prototype.loadAcaCategory = function () {
        var _this = this;
        this.lookupApiService.getAcaCategory()
            .then(function (container) {
            _this.acaCategoryLookup$.next(container);
        });
    };
    BenefitDetailsManagementService.prototype.getNextLine = function (lineId) {
        if (this.selectedProvider && this.selectedProvider.current && this.selectedProvider.current.benefitLines) {
            var lines = this.selectedProvider.current.benefitLines;
            var currentLine_1 = _.find(lines, function (x) { return x.id === lineId; });
            if (_.isObjectLike(currentLine_1)) {
                var nextLines = _.filter(lines, function (x) { return x.startDate > currentLine_1.endDate; });
                if (_.size(nextLines) > 0) {
                    return _.minBy(nextLines, 'startDate');
                }
            }
        }
        return null;
    };
    BenefitDetailsManagementService.prototype.markAsDirty = function () {
        this.changeManagement.changeNotify();
    };
    BenefitDetailsManagementService.prototype.clearChanges = function () {
        this.changeManagement.clearChanges();
    };
    BenefitDetailsManagementService.prototype.getBenefitDeductionEnabled2 = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1: return [2 /*return*/, (_a.sent()).isBenefitDeductionEnabled2];
                }
            });
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], BenefitDetailsManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeAll('destroy'),
        tslib_1.__metadata("design:type", Object)
    ], BenefitDetailsManagementService.prototype, "subscriptions", void 0);
    return BenefitDetailsManagementService;
}());
export { BenefitDetailsManagementService };
