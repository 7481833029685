import * as tslib_1 from "tslib";
import { HttpRequest } from '@angular/common/http';
import { dateTimeUtils } from '../../../common/utils/index';
import { UrlParamsService, ApiUtilService, DateTimeService } from '../../../common/services/index';
import { appConfig } from '../../../app.config';
import { organizationConfig } from '../../organization.config';
import { Assert } from '../../../framework/index';
import { LookupMapService } from './lookup-map.service';
import { CacheType } from '../../../common/models/cache/cache-definition';
import * as _ from 'lodash';
var LookupApiService = /** @class */ (function () {
    function LookupApiService(lookupMapService, apiUtilService, urlParamsService, dateTimeService) {
        this.lookupMapService = lookupMapService;
        this.apiUtilService = apiUtilService;
        this.urlParamsService = urlParamsService;
        this.dateTimeService = dateTimeService;
        this.cachedPositionsData = [];
        this.cachedEmployeeData = [];
    }
    LookupApiService.prototype.getLookup = function (lookupName, orgLevelId, employeeId) {
        var _this = this;
        var lookup = organizationConfig.lookup[lookupName];
        Assert.isNotNull(lookup);
        var url = this.getApiRoot() + "/" + lookup;
        var promise = this.apiUtilService
            .cachedRequest(this.urlParamsService.createGetRequest(url, { orgLevelId: orgLevelId, employeeId: employeeId }), CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapLookupEntity(response.data); });
        return promise;
    };
    LookupApiService.prototype.getShiftGroupDefinition = function (orgLevelId) {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.shiftGroup;
        var request = new HttpRequest('GET', url, {
            params: this.urlParamsService.convertToParams({ orgLevelId: orgLevelId })
        });
        var promise = this.apiUtilService
            .cachedRequest(request, CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapShiftGroupsDefinition(response.data); });
        return promise;
    };
    LookupApiService.prototype.getShifts = function (employeeId, orgLevelId) {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.shift;
        var request = new HttpRequest('GET', url, {
            params: this.urlParamsService.convertToParams({ orgLevelId: orgLevelId, empId: employeeId })
        });
        var promise = this.apiUtilService
            .cachedRequest(request, CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapShifts(response.data); });
        return promise;
    };
    LookupApiService.prototype.getTaAbsenceCodes = function (employeeId, orgLevelId) {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.taAbsenceCodes;
        var request = new HttpRequest('GET', url, {
            params: this.urlParamsService.convertToParams({ orgLevelId: orgLevelId, employeeId: employeeId })
        });
        var promise = this.apiUtilService
            .cachedRequest(request, CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapTaAbsenceCodes(response.data); });
        return promise;
    };
    LookupApiService.prototype.getScheduleCycles = function (orgLevelId) {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.scheduleCycles;
        var request = new HttpRequest('GET', url, {
            params: this.urlParamsService.convertToParams({ orgLevelId: orgLevelId })
        });
        var promise = this.apiUtilService
            .cachedRequest(request, CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapScheduleCycles(response.data); });
        return promise;
    };
    LookupApiService.prototype.getEmpTypes = function () {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.employeeType;
        var request = new HttpRequest('GET', url);
        var promise = this.apiUtilService
            .cachedRequest(request, CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapEmpTypes(response.data); });
        return promise;
    };
    LookupApiService.prototype.getBudgetedPositions = function (employeeId, orgLevelId) {
        var _this = this;
        Assert.isNotNull(employeeId, 'employeeId');
        Assert.isNotNull(orgLevelId, 'orgLevelId');
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.budgetedPosition;
        var promise = this.apiUtilService
            .cachedRequest(this.urlParamsService.createGetRequest(url, { orgLevelId: orgLevelId, empId: employeeId }), CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapBudgetedPositions(response.data); });
        return promise;
    };
    LookupApiService.prototype.getLicenseType = function (employeeId, orgLevelId) {
        var _this = this;
        Assert.isNotNull(employeeId, 'employeeId');
        Assert.isNotNull(orgLevelId, 'orgLevelId');
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.licenseType;
        var promise = this.apiUtilService
            .cachedRequest(this.urlParamsService.createGetRequest(url, { empId: employeeId, orgLevelId: orgLevelId }), CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapLicenseTypes(response.data); });
        return promise;
    };
    LookupApiService.prototype.getBudgetedGroups = function (orgLevelId, updateCacheForced) {
        var _this = this;
        if (updateCacheForced === void 0) { updateCacheForced = false; }
        Assert.isNotNull(orgLevelId, 'orgLevelId');
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.budgetedGroups;
        var request = new HttpRequest('GET', url, {
            params: this.urlParamsService.convertToParams({ orgLevelId: orgLevelId })
        });
        var promise = this.apiUtilService
            .cachedRequest(request, CacheType.longTerm, updateCacheForced)
            .then(function (response) { return _this.lookupMapService.mapBudgetedGroups(response.data); });
        return promise;
    };
    LookupApiService.prototype.getStates = function () {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.state;
        var request = new HttpRequest('GET', url);
        /*
         let promise: Promise<State[]> = Promise.resolve(
         this.lookupMapService.mapStates([{ name: 'NJ' }, { name: 'NY' }, { name: 'NC' }, { name: 'TX' }])
         );*/
        var promise = this.apiUtilService
            .cachedRequest(request, CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapStates(response.data); });
        return promise;
    };
    LookupApiService.prototype.clearPositionsCacheData = function () {
        this.cachedPositionsData = [];
    };
    LookupApiService.prototype.clearOldPositionsCacheData = function () {
        var _this = this;
        var currentTime = new Date();
        this.cachedPositionsData = this.cachedPositionsData.filter(function (item) {
            var seconds = _this.dateTimeService.GetDiffSeconds(new Date(item.timestamp), currentTime);
            if (seconds < appConfig.cacheInvalidatePositionsTime) {
                return item;
            }
        });
    };
    LookupApiService.prototype.getEffectivePositions = function (employeeId, effectiveDate, updateCacheForced) {
        var _this = this;
        if (updateCacheForced === void 0) { updateCacheForced = false; }
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.position + "/" + organizationConfig.lookup.effectivePositions;
        var params = {};
        if (employeeId) {
            params['empId'] = employeeId.toString();
        }
        if (effectiveDate) {
            params['effectiveDate'] = dateTimeUtils.convertToDtoString(effectiveDate);
        }
        var promise = this.apiUtilService
            .cachedRequest(this.urlParamsService.createGetRequest(url, params), CacheType.longTerm, updateCacheForced)
            .then(function (response) { return _this.lookupMapService.mapPositions(response.data); });
        return promise;
    };
    LookupApiService.prototype.getPositions = function (employeeId, orgLevelId, isActive, updateCacheForced) {
        var _this = this;
        if (updateCacheForced === void 0) { updateCacheForced = false; }
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.position;
        var params = {};
        if (employeeId) {
            params['empId'] = employeeId.toString();
        }
        if (orgLevelId) {
            params['orgLevelId'] = orgLevelId.toString();
        }
        if (_.isBoolean(isActive)) {
            params['isActive'] = String(isActive);
        }
        /*
         let promise: Promise<Position[]> = Promise.resolve(
         this.lookupMapService.mapPositions([{ id: 1, description: 'CNA' }, { id: 2, description: 'CNA2' }])
         );*/
        var promise = null;
        // Check if data already fetched for the selected cached Id within 60 seconds.
        // If yes than return the cached data
        // Else fetch data from API, store it in cache and return it.
        var cacheId = "" + employeeId;
        if (orgLevelId) {
            cacheId = cacheId + '_' + orgLevelId;
        }
        if (isActive) {
            cacheId = cacheId + '_' + isActive;
        }
        this.clearOldPositionsCacheData();
        var cacheItemIndex = this.cachedPositionsData.findIndex(function (item) { return item.cacheId === cacheId; });
        if (cacheItemIndex > -1) {
            var seconds = this.dateTimeService.GetDiffSeconds(new Date(this.cachedPositionsData[cacheItemIndex].timestamp), new Date());
            if (seconds < appConfig.cacheInvalidatePositionsTime) {
                // In here means data present in cache.
                promise = new Promise(function (resolve) {
                    resolve(_this.cachedPositionsData[cacheItemIndex].data);
                });
                return promise;
            }
        }
        promise = this.apiUtilService
            .cachedRequest(this.urlParamsService.createGetRequest(url, params), CacheType.longTerm, updateCacheForced)
            .then(function (response) {
            _this.cachedPositionsData.push({
                cacheId: cacheId,
                data: _this.lookupMapService.mapPositions(response.data),
                timestamp: new Date().getTime()
            });
            return _this.lookupMapService.mapPositions(response.data);
        });
        return promise;
    };
    LookupApiService.prototype.getPbjPosition = function (employeeId, orgLevelId, isActive, updateCacheForced) {
        var _this = this;
        if (updateCacheForced === void 0) { updateCacheForced = false; }
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.pbjPosition;
        var params = {};
        if (employeeId) {
            params['empId'] = employeeId.toString();
        }
        if (orgLevelId) {
            params['orgLevelId'] = orgLevelId.toString();
        }
        if (_.isBoolean(isActive)) {
            params['isActive'] = String(isActive);
        }
        var promise = this.apiUtilService
            .cachedRequest(this.urlParamsService.createGetRequest(url, params), CacheType.longTerm, updateCacheForced)
            .then(function (response) { return _this.lookupMapService.mapActualPbjPositions(response.data); });
        return promise;
    };
    LookupApiService.prototype.getEstablishmentTypes = function () {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.establishmentTypes;
        var promise = this.apiUtilService
            .cachedRequest(this.urlParamsService.createGetRequest(url), CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapEstablishmentTypes(response.data); });
        return promise;
    };
    LookupApiService.prototype.getShiftDiffPolicies = function (employeeId, orgLevelId) {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.shiftdiffpolicy;
        var promise = this.apiUtilService
            .cachedRequest(this.urlParamsService.createGetRequest(url, { empId: employeeId, orgLevelId: orgLevelId }), CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapShiftDiffPolicies(response.data); });
        return promise;
    };
    LookupApiService.prototype.getPayPolicies = function (employeeId, orgLevelId) {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.paypolicy;
        var promise = this.apiUtilService
            .cachedRequest(this.urlParamsService.createGetRequest(url, { empId: employeeId, orgLevelId: orgLevelId }), CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapPayPolicies(response.data); });
        return promise;
    };
    LookupApiService.prototype.getAcuities = function (orgLevelId) {
        var _this = this;
        var url = appConfig.api.url + "/" + appConfig.api.version + "/" + organizationConfig.lookup.acuities;
        var promise = this.apiUtilService
            .cachedRequest(this.urlParamsService.createGetRequest(url, { orgLevelId: orgLevelId }), CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapAcuities(response.data); });
        return promise;
    };
    LookupApiService.prototype.getEmployeeDocumentCategories = function () {
        var _this = this;
        var url = appConfig.api.url + "/" + appConfig.api.version + "/" + organizationConfig.api.employee.root + "/" + organizationConfig.lookup.documentsCategories;
        var promise = this.apiUtilService
            .cachedRequest(this.urlParamsService.createGetRequest(url), CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapEmployeeDocumentCategories(response.data); });
        return promise;
    };
    LookupApiService.prototype.getCostCenters = function (employeeId) {
        var _this = this;
        var url = appConfig.api.url + "/" + appConfig.api.version + "/" + organizationConfig.lookup.costCenters;
        var params = { employeeId: employeeId };
        var promise = this.apiUtilService
            .cachedRequest(this.urlParamsService.createGetRequest(url, params), CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapCostCenters(response.data); });
        return promise;
    };
    LookupApiService.prototype.getAccrualPolicies = function (employeeId, orgLevelId) {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.accrualpolicy;
        var promise = this.apiUtilService
            .cachedRequest(this.urlParamsService.createGetRequest(url, { empId: employeeId, orgLevelId: orgLevelId }), CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapAccrualPolicies(response.data); });
        return promise;
    };
    LookupApiService.prototype.getExemptStatuses = function () {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.exempt;
        /*
        let promise: Promise<ExemptStatus[]> = Promise.resolve(
          this.lookupMapService.mapExemptStatuses([{ name: 'ExemptStatus1' }, { name: 'ExemptStatus2' }])
        );*/
        var request = new HttpRequest('GET', url);
        var promise = this.apiUtilService
            .cachedRequest(request, CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapExemptStatuses(response.data); });
        return promise;
    };
    LookupApiService.prototype.getPayTypes = function () {
        var promise = Promise.resolve(this.lookupMapService.mapPayTypes([{ name: 'Hourly' }, { name: 'Salaried' }]));
        return promise;
    };
    LookupApiService.prototype.getPayCyclePeriods = function () {
        var promise = Promise.resolve(this.lookupMapService.mapPayCyclePeriods([
            { name: 'weekly', description: 'Weekly' },
            { name: 'bweekly', description: 'Bi-Weekly' },
            { name: 'monthly', description: 'Monthly' },
            { name: 'smonthly', description: 'Semi-Monthly' },
        ]));
        return promise;
    };
    LookupApiService.prototype.getOrganizationsAsync = function (employeeId, orgLevelId, isActive) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getOrganizations(employeeId, orgLevelId, isActive)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    LookupApiService.prototype.getOrganizations = function (employeeId, orgLevelId, isActive) {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.organization;
        var params = {};
        if (employeeId) {
            params['empId'] = employeeId.toString();
        }
        if (orgLevelId) {
            params['orgLevelId'] = orgLevelId.toString();
        }
        if (_.isBoolean(isActive)) {
            params['isActive'] = String(isActive);
        }
        var promise = this.apiUtilService
            .cachedRequest(this.urlParamsService.createGetRequest(url, params), CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapOrganizations(response.data); });
        return promise;
    };
    LookupApiService.prototype.getUserAccesibleOrganizations = function (orgLevelId, crossCorpActionCheck) {
        var _this = this;
        var params = {};
        var url;
        if (crossCorpActionCheck) {
            url = this.getApiRoot() + "/" + organizationConfig.lookup.userAccesibleOrganizationsCrossCorpActionCheck;
        }
        else {
            url = this.getApiRoot() + "/" + organizationConfig.lookup.userAccesibleOrganizations;
        }
        if (orgLevelId) {
            params['orgLevelId'] = orgLevelId.toString();
        }
        var promise = this.apiUtilService
            .request(this.urlParamsService.createGetRequest(url, params))
            .then(function (response) { return _this.lookupMapService.mapOrganizations(response.data); });
        return promise;
    };
    LookupApiService.prototype.getUserAccessibleDepartments = function (orgLevelId) {
        var _this = this;
        var params = {};
        if (orgLevelId) {
            params['orgLevelId'] = orgLevelId.toString();
        }
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.userAccessibleDepartments;
        var promise = this.apiUtilService
            .request(this.urlParamsService.createGetRequest(url, params))
            .then(function (response) { return _this.lookupMapService.mapDepartments(response.data); });
        return promise;
    };
    LookupApiService.prototype.getCompanies = function () {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.companies;
        var promise = this.apiUtilService
            .cachedRequest(this.urlParamsService.createGetRequest(url), CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapCompanies(response.data); });
        return promise;
    };
    LookupApiService.prototype.getDepartments = function (employeeId, orgLevelId, isActive, updateCacheForced) {
        var _this = this;
        if (updateCacheForced === void 0) { updateCacheForced = false; }
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.department;
        var params = {};
        if (employeeId) {
            params['empId'] = employeeId.toString();
        }
        if (orgLevelId) {
            params['orgLevelId'] = orgLevelId.toString();
        }
        if (_.isBoolean(isActive)) {
            params['isActive'] = String(isActive);
        }
        console.log('get departments: ');
        var promise = this.apiUtilService
            .cachedRequest(this.urlParamsService.createGetRequest(url, params), CacheType.longTerm, updateCacheForced)
            .then(function (response) { return _this.lookupMapService.mapDepartments(response.data); });
        return promise;
    };
    LookupApiService.prototype.getAttendancePointsDefinitions = function (orgLevelId, employeeId) {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.attendancePointsDefinitions;
        var params = {};
        if (employeeId) {
            params['empId'] = employeeId.toString();
        }
        if (orgLevelId) {
            params['orgLevelId'] = orgLevelId.toString();
        }
        var promise = this.apiUtilService
            .cachedRequest(this.urlParamsService.createGetRequest(url, params), CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapAttendancePointsDefinitions(response.data); });
        return promise;
    };
    LookupApiService.prototype.getGenders = function () {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.gender;
        var request = new HttpRequest('GET', url);
        var promise = this.apiUtilService
            .cachedRequest(request, CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapGenders(response.data); });
        return promise;
    };
    LookupApiService.prototype.getMaritalStatuses = function () {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.maritalStatus;
        var request = new HttpRequest('GET', url);
        var promise = this.apiUtilService
            .cachedRequest(request, CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapMaritalStatuses(response.data); });
        return promise;
    };
    LookupApiService.prototype.getRaces = function () {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.race;
        var request = new HttpRequest('GET', url);
        var promise = this.apiUtilService
            .cachedRequest(request, CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapRaces(response.data); });
        return promise;
    };
    LookupApiService.prototype.getLocationUnits = function (employeeId, orgLevelId) {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.unit;
        var promise = this.apiUtilService
            .cachedRequest(this.urlParamsService.createGetRequest(url, { empId: employeeId, orgLevelId: orgLevelId }), CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapLocationUnits(response.data); });
        return promise;
    };
    LookupApiService.prototype.getShiftDefinitions = function (employeeId, orgLevelId) {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.shift;
        var promise = this.apiUtilService
            .cachedRequest(this.urlParamsService.createGetRequest(url, { empId: employeeId, orgLevelId: orgLevelId }), CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapShiftDefinitions(response.data); });
        return promise;
    };
    LookupApiService.prototype.getConstraintDefinitions = function (employeeId, orgLevelId) {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.constraint;
        var promise = this.apiUtilService
            .cachedRequest(this.urlParamsService.createGetRequest(url, { empId: employeeId, orgLevelId: orgLevelId }), CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapConstraintDefinitions(response.data); });
        return promise;
    };
    LookupApiService.prototype.getScheduleAbsences = function (orgLevelId) {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.scheduleAbsences;
        var promise = this.apiUtilService
            .cachedRequest(this.urlParamsService.createGetRequest(url, { orgLevelId: orgLevelId }), CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapScheduleAbsences(response.data); });
        return promise;
    };
    LookupApiService.prototype.getSearchCategories = function () {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.searchCategories;
        var request = new HttpRequest('GET', url);
        var promise = this.apiUtilService
            .cachedRequest(request, CacheType.longTerm)
            .then(function (response) { return (response.data = _this.lookupMapService.mapSearchCategories(response.data)); });
        return promise;
    };
    LookupApiService.prototype.getEmployeeTerminationReasons = function () {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.employeeTerminationReasons;
        var request = new HttpRequest('GET', url);
        var promise = this.apiUtilService
            .cachedRequest(request, CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapEmployeeTerminationReasons(response.data); });
        return promise;
    };
    LookupApiService.prototype.getBudgetDefinitions = function (orgLevelId, updateCacheForced) {
        var _this = this;
        if (updateCacheForced === void 0) { updateCacheForced = false; }
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.budgets;
        var request = new HttpRequest('GET', url, {
            params: this.urlParamsService.convertToParams({ orgLevelId: orgLevelId })
        });
        var promise = this.apiUtilService
            .cachedRequest(request, CacheType.longTerm, updateCacheForced)
            .then(function (response) { return _this.lookupMapService.mapBudgetDefinitions(response.data); });
        return promise;
    };
    LookupApiService.prototype.getPbjPositions = function () {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.pbjPositions;
        var request = new HttpRequest('GET', url);
        var promise = this.apiUtilService
            .cachedRequest(request, CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapPbjPositions(response.data); });
        return promise;
    };
    LookupApiService.prototype.getAvailabilityRanges = function (employeeId, updateCacheForced) {
        var _this = this;
        if (updateCacheForced === void 0) { updateCacheForced = false; }
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.availabilityDateRanges;
        var promise = this.apiUtilService
            .cachedRequest(this.urlParamsService.createGetRequest(url, { employeeId: employeeId }), CacheType.longTerm, updateCacheForced)
            .then(function (response) { return _this.lookupMapService.mapAvailabilityDateRanges(response.data); });
        return promise;
    };
    LookupApiService.prototype.getTimeclockDefinitions = function (orgLevelId, employeeId, updateCacheForced) {
        var _this = this;
        if (updateCacheForced === void 0) { updateCacheForced = false; }
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.timeClocks;
        var params = {};
        if (orgLevelId) {
            params.orgLevelId = orgLevelId;
        }
        if (employeeId) {
            params.empId = employeeId;
        }
        var request = new HttpRequest('GET', url, {
            params: this.urlParamsService.convertToParams(params)
        });
        var promise = this.apiUtilService
            .cachedRequest(request, CacheType.longTerm, updateCacheForced)
            .then(function (response) { return _this.lookupMapService.mapTimeclockDefinitions(response.data); });
        return promise;
    };
    LookupApiService.prototype.getTimeclockRestrictionDefinitions = function (orgLevelId) {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.timeclockRestrictions;
        var request = new HttpRequest('GET', url, {
            params: this.urlParamsService.convertToParams({ orgLevelId: orgLevelId })
        });
        var promise = this.apiUtilService
            .cachedRequest(request, CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapTimeclockRestrictionDefinitions(response.data); });
        return promise;
    };
    LookupApiService.prototype.getTimeclockModelDefinitions = function (orgLevelId) {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.timeclockModels;
        var request = new HttpRequest('GET', url, {
            params: this.urlParamsService.convertToParams({ orgLevelId: orgLevelId })
        });
        var promise = this.apiUtilService
            .cachedRequest(request, CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapTimeclockModelDefinitions(response.data); });
        return promise;
    };
    LookupApiService.prototype.getTimezoneDefinitions = function () {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.timezones;
        var request = new HttpRequest('GET', url);
        var promise = this.apiUtilService
            .cachedRequest(request, CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapTimezoneDefinitions(response.data); });
        return promise;
    };
    LookupApiService.prototype.getPayCyles = function (orgLevelId, employeeId, date, updateCacheForced) {
        var _this = this;
        if (updateCacheForced === void 0) { updateCacheForced = false; }
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.paycycles;
        if (employeeId > 0)
            orgLevelId = null;
        var request = this.urlParamsService.createGetRequest(url, { orgLevelId: orgLevelId, employeeId: employeeId, date: date });
        var promise = this.apiUtilService
            .cachedRequest(request, CacheType.longTerm, updateCacheForced)
            .then(function (response) { return _this.lookupMapService.mapPayCycles(response.data); });
        return promise;
    };
    LookupApiService.prototype.getPayRules = function (empId) {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.payrules;
        var promise = this.apiUtilService
            .cachedRequest(this.urlParamsService.createGetRequest(url, { employeeId: empId }), CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapPayRules(response.data); });
        return promise;
    };
    LookupApiService.prototype.getPunchTypes = function () {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.punchtypes;
        var promise = this.apiUtilService
            .cachedRequest(this.urlParamsService.createGetRequest(url, {}), CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapPunchTypes(response.data); });
        return promise;
    };
    LookupApiService.prototype.clearEmployeeCacheData = function () {
        this.cachedEmployeeData = [];
    };
    LookupApiService.prototype.clearOldEmployeeCacheData = function () {
        var _this = this;
        var currentTime = new Date();
        this.cachedEmployeeData = this.cachedEmployeeData.filter(function (item) {
            var seconds = _this.dateTimeService.GetDiffSeconds(new Date(item.timestamp), currentTime);
            if (seconds < appConfig.cacheInvalidateEmployeeDropDownTime) {
                return item;
            }
        });
    };
    LookupApiService.prototype.getEmployees = function (orgLevelId, isActive) {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.employees;
        var params = {};
        if (orgLevelId) {
            params['orgLevelId'] = orgLevelId.toString();
        }
        if (_.isBoolean(isActive)) {
            params['isActive'] = String(isActive);
        }
        var promise = null;
        // Check if data already fetched for the selected cached Id within 60 seconds.
        // If yes than return the cached data
        // Else fetch data from API, store it in cache and return it.
        var cacheId = "" + orgLevelId;
        if (isActive) {
            cacheId = cacheId + '_' + isActive;
        }
        this.clearOldEmployeeCacheData();
        var cacheItemIndex = this.cachedEmployeeData.findIndex(function (item) { return item.cacheId === cacheId; });
        if (cacheItemIndex > -1) {
            var seconds = this.dateTimeService.GetDiffSeconds(new Date(this.cachedEmployeeData[cacheItemIndex].timestamp), new Date());
            if (seconds < appConfig.cacheInvalidateEmployeeDropDownTime) {
                // In here means data present in cache.
                promise = new Promise(function (resolve) {
                    resolve(_this.cachedEmployeeData[cacheItemIndex].data);
                });
                return promise;
            }
        }
        promise = this.apiUtilService.request(this.urlParamsService.createGetRequest(url, params))
            .then(function (response) {
            _this.cachedEmployeeData.push({
                cacheId: cacheId,
                data: _this.lookupMapService.mapEmployees(response.data),
                timestamp: new Date().getTime()
            });
            return _this.lookupMapService.mapEmployees(response.data);
        });
        return promise;
    };
    LookupApiService.prototype.getSupervisors = function (orgLevelId, employeeId, isActive) {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.supervisors;
        var params = {};
        if (orgLevelId) {
            params['orgLevelId'] = orgLevelId.toString();
        }
        if (orgLevelId) {
            params['employeeId'] = employeeId.toString();
        }
        if (_.isBoolean(isActive)) {
            params['isActive'] = true;
        }
        var promise = null;
        promise = this.apiUtilService.request(this.urlParamsService.createGetRequest(url, params))
            .then(function (response) {
            return _this.lookupMapService.mapSupervisors(response.data);
        });
        return promise;
    };
    LookupApiService.prototype.getEmployeesBySecondaryPositions = function (orgLevelId, isActive) {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.employeesBySecondaryPositions;
        var params = {};
        if (orgLevelId) {
            params['orgLevelId'] = orgLevelId.toString();
        }
        if (_.isBoolean(isActive)) {
            params['isActive'] = String(isActive);
        }
        var promise = this.apiUtilService.request(this.urlParamsService.createGetRequest(url, params))
            .then(function (response) { return _this.lookupMapService.mapEmployees(response.data); });
        return promise;
    };
    LookupApiService.prototype.getEmployeesByActiveDateRange = function (orgLevelId, startDate, endDate) {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.employeesByActiveDateRange;
        var promise = this.apiUtilService.request(this.urlParamsService.createGetRequest(url, {
            orgLevelId: orgLevelId,
            startDate: dateTimeUtils.convertToDtoString(startDate),
            endDate: dateTimeUtils.convertToDtoString(endDate)
        }))
            .then(function (response) { return _this.lookupMapService.mapEmployeesDefinitions(response.data); });
        return promise;
    };
    LookupApiService.prototype.manualEntryActiveEmployees = function (orgLevelId, startDate, endDate) {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.manualEntryActiveEmployees;
        var promise = this.apiUtilService.request(this.urlParamsService.createGetRequest(url, {
            orgLevelId: orgLevelId,
            startDate: dateTimeUtils.convertToDtoString(startDate),
            endDate: dateTimeUtils.convertToDtoString(endDate)
        }))
            .then(function (response) { return _this.lookupMapService.mapEmployeesDefinitions(response.data); });
        return promise;
    };
    LookupApiService.prototype.getAgencies = function (updateCacheForced) {
        var _this = this;
        if (updateCacheForced === void 0) { updateCacheForced = false; }
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.agencyDefinitions;
        var request = this.urlParamsService.createGetRequest(url);
        var promise = this.apiUtilService
            .cachedRequest(request, CacheType.longTerm, updateCacheForced)
            .then(function (response) { return _this.lookupMapService.mapAgencyDefinitions(response.data); });
        return promise;
    };
    LookupApiService.prototype.getAgenciesReports = function (updateCacheForced) {
        var _this = this;
        if (updateCacheForced === void 0) { updateCacheForced = false; }
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.agencyDefinitionsReports;
        var request = this.urlParamsService.createGetRequest(url);
        var promise = this.apiUtilService
            .cachedRequest(request, CacheType.longTerm, updateCacheForced)
            .then(function (response) { return _this.lookupMapService.mapAgencyDefinitions(response.data); });
        return promise;
    };
    LookupApiService.prototype.getTimecardPredefinedComment = function () {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.timecardPredefinedComments;
        var request = this.urlParamsService.createGetRequest(url);
        /*
            let promise: Promise<TimecardPredefinedComment[]> = Promise.resolve(
               this.lookupMapService.mapTimecardPredefinedComments([
                { id: 0, description: 'First Comment1', order: 1, type: '' },
                { id: 0, description: 'Second Comment2', order: 2, type: '' },
                { id: 0, description: 'AA Comment3', order: 3, type: '' },
                { id: 0, description: 'For Comment4', order: 4, type: '' }
              ]));*/
        var promise = this.apiUtilService
            .cachedRequest(request, CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapTimecardPredefinedComments(response.data); });
        return promise;
    };
    LookupApiService.prototype.getPtoPlannerAbsenceCodes = function (orgLevelId, updateCacheForced) {
        var _this = this;
        if (updateCacheForced === void 0) { updateCacheForced = false; }
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.scheduleAbsences;
        var request = this.urlParamsService.createGetRequest(url, { orgLevelId: orgLevelId });
        var promise = this.apiUtilService
            .cachedRequest(request, CacheType.longTerm, updateCacheForced)
            .then(function (response) {
            Assert.isNotNull(response);
            Assert.isNotNull(response.data);
            var filterCodes = _.filter(response.data, function (item) { return item.ptoPlannerIndicator; });
            return _this.lookupMapService.mapScheduleAbsences(filterCodes);
        });
        return promise;
    };
    LookupApiService.prototype.getBenefitClassesDefinitions = function (employeeId, orgLevelId, updateCacheForced) {
        var _this = this;
        if (updateCacheForced === void 0) { updateCacheForced = false; }
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.benefitClasses;
        var promise = this.apiUtilService
            .cachedRequest(this.urlParamsService.createGetRequest(url, { employeeId: employeeId, orgLevelId: orgLevelId }), CacheType.longTerm, updateCacheForced)
            .then(function (response) { return _this.lookupMapService.mapBenefitClassDefinitions(response.data); });
        return promise;
    };
    LookupApiService.prototype.getVeteranStatuses = function (employeeId, orgLevelId, updateCacheForced) {
        var _this = this;
        if (updateCacheForced === void 0) { updateCacheForced = false; }
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.veteranStatuses;
        var promise = this.apiUtilService
            .cachedRequest(this.urlParamsService.createGetRequest(url, { employeeId: employeeId, orgLevelId: orgLevelId }), CacheType.longTerm, updateCacheForced)
            .then(function (response) { return _this.lookupMapService.mapVeteranStatuses(response.data); });
        return promise;
    };
    LookupApiService.prototype.getPerformanceReviewTemplates = function (orgLevelId, updateCacheForced) {
        var _this = this;
        if (updateCacheForced === void 0) { updateCacheForced = false; }
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.performanceReviewTemplates;
        var request = this.urlParamsService.createGetRequest(url, { orgLevelId: orgLevelId });
        return this.apiUtilService
            .cachedRequest(request, CacheType.longTerm, updateCacheForced)
            .then(function (response) {
            return _this.lookupMapService.mapPerformanceReviewTemplates(response.data);
        });
    };
    LookupApiService.prototype.getPerformanceReviewCodeTypes = function (updateCacheForced) {
        var _this = this;
        if (updateCacheForced === void 0) { updateCacheForced = false; }
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.performanceReviewCodeTypes;
        var request = this.urlParamsService.createGetRequest(url);
        return this.apiUtilService
            .cachedRequest(request, CacheType.longTerm, updateCacheForced)
            .then(function (response) {
            return _this.lookupMapService.mapPerformanceReviewCodeTypes(response.data);
        });
    };
    LookupApiService.prototype.getPerformanceReviewCodes = function (orgLevelId, updateCacheForced) {
        var _this = this;
        if (updateCacheForced === void 0) { updateCacheForced = false; }
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.performanceReviewCodes;
        var request = this.urlParamsService.createGetRequest(url, { orgLevelId: orgLevelId });
        return this.apiUtilService
            .cachedRequest(request, CacheType.longTerm, updateCacheForced)
            .then(function (response) { return _this.lookupMapService.mapPerformanceReviewCodes(response.data); });
    };
    LookupApiService.prototype.getEssOptions = function () {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.essOptions;
        var request = this.urlParamsService.createGetRequest(url);
        var promise = this.apiUtilService
            .cachedRequest(request, CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapEssEntities(response.data); });
        return promise;
    };
    LookupApiService.prototype.getPositionGroups = function (orgLevelId, updateCacheForced) {
        var _this = this;
        if (updateCacheForced === void 0) { updateCacheForced = false; }
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.positionGroups;
        var request = this.urlParamsService.createGetRequest(url, { orgLevelId: orgLevelId });
        return this.apiUtilService
            .cachedRequest(request, CacheType.longTerm, updateCacheForced)
            .then(function (response) { return _this.lookupMapService.mapPositionGroups(response.data); });
    };
    LookupApiService.prototype.getBenefitGroups = function (empId, isActive, updateCacheForced) {
        var _this = this;
        if (updateCacheForced === void 0) { updateCacheForced = false; }
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.benefitGroups;
        var request = this.urlParamsService.createGetRequest(url, { employeeId: empId, isActive: isActive });
        return this.apiUtilService
            .cachedRequest(request, CacheType.longTerm, updateCacheForced)
            .then(function (response) { return _this.lookupMapService.mapBenefitGroups(response.data); });
    };
    LookupApiService.prototype.getObjAcc = function (orgLevelId, updateCacheForced) {
        var _this = this;
        if (updateCacheForced === void 0) { updateCacheForced = false; }
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.getObjAcc;
        var request = this.urlParamsService.createGetRequest(url, { orgLevelId: orgLevelId });
        return this.apiUtilService
            .cachedRequest(request, CacheType.longTerm, updateCacheForced)
            .then(function (response) { return _this.lookupMapService.mapObjAccs(response.data); });
    };
    LookupApiService.prototype.getAcaCategory = function () {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.acaCategory;
        var request = this.urlParamsService.createGetRequest(url);
        return this.apiUtilService
            .cachedRequest(request, CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapAcaCategoryDefinitions(response.data); });
    };
    LookupApiService.prototype.getAcaEmployers = function (activeOnly) {
        var _this = this;
        if (activeOnly === void 0) { activeOnly = true; }
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.acaEmployers;
        var request = this.urlParamsService.createGetRequest(url, { activeOnly: activeOnly });
        return this.apiUtilService
            .cachedRequest(request, CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapAcaEmployers(response.data); });
    };
    LookupApiService.prototype.getAcaSafeHarbors = function () {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.acaSafeHarbor + "?orgLevelId=1";
        var request = this.urlParamsService.createGetRequest(url);
        return this.apiUtilService
            .cachedRequest(request, CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapAcaSafeHarbors(response.data); });
    };
    LookupApiService.prototype.getAcaReasons = function () {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.acaReason + "?orgLevelId=1";
        var request = this.urlParamsService.createGetRequest(url);
        return this.apiUtilService
            .cachedRequest(request, CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapAcaReasons(response.data); });
    };
    LookupApiService.prototype.getAcaOfferOfCoverage = function (year) {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.acaOfferOfCoverage + "?orgLevelId=1&year=" + year;
        var request = this.urlParamsService.createGetRequest(url);
        return this.apiUtilService
            .cachedRequest(request, CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapAcaOfferOfCoverages(response.data); });
    };
    LookupApiService.prototype.getPayUnits = function (orgLevelId, isActive, updateCacheForced) {
        var _this = this;
        if (updateCacheForced === void 0) { updateCacheForced = false; }
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.payunits;
        var request = this.urlParamsService.createGetRequest(url, { orgLevelId: orgLevelId, isActive: isActive });
        return this.apiUtilService
            .cachedRequest(request, CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapToPayUnitDefinitions(response.data); });
    };
    LookupApiService.prototype.getIncreaseReasons = function (updateCacheForced) {
        var _this = this;
        if (updateCacheForced === void 0) { updateCacheForced = false; }
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.increaseReason;
        var request = this.urlParamsService.createGetRequest(url);
        return this.apiUtilService
            .cachedRequest(request, CacheType.longTerm, updateCacheForced)
            .then(function (response) { return _this.lookupMapService.mapToIncreaseReasons(response.data); });
    };
    LookupApiService.prototype.getTimeclockSlateProfileDefinitions = function () {
        var _this = this;
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.timeclockSlateProfile;
        var request = this.urlParamsService.createGetRequest(url);
        var promise = this.apiUtilService
            .cachedRequest(request, CacheType.longTerm)
            .then(function (response) { return _this.lookupMapService.mapTimeclockSlateProfileDefinitions(response.data); });
        return promise;
    };
    LookupApiService.prototype.getPunchProfile = function () {
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.punchProfiles;
        var request = this.urlParamsService.createGetRequest(url);
        var promise = this.apiUtilService
            .cachedRequest(request, CacheType.longTerm)
            .then(function (response) { return response.data; });
        return promise;
    };
    LookupApiService.prototype.getPunchAttestatiionGroup = function () {
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.punchAttestationGroups;
        var request = this.urlParamsService.createGetRequest(url);
        var promise = this.apiUtilService
            .cachedRequest(request, CacheType.longTerm)
            .then(function (response) { return response.data; });
        return promise;
    };
    LookupApiService.prototype.getApiRoot = function () {
        return appConfig.api.url + "/" + appConfig.api.version + "/" + organizationConfig.lookup.root;
    };
    LookupApiService.prototype.getAttestationTypes = function (updateCacheForced) {
        var _this = this;
        if (updateCacheForced === void 0) { updateCacheForced = false; }
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.attestationType;
        var request = this.urlParamsService.createGetRequest(url);
        return this.apiUtilService
            .cachedRequest(request, CacheType.longTerm, updateCacheForced)
            .then(function (response) { return _this.lookupMapService.mapToAttestationtypes(response.data); });
    };
    LookupApiService.prototype.getRestrictedAnswers = function (updateCacheForced) {
        var _this = this;
        if (updateCacheForced === void 0) { updateCacheForced = false; }
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.restrictedAnswer;
        var request = this.urlParamsService.createGetRequest(url);
        return this.apiUtilService
            .cachedRequest(request, CacheType.longTerm, updateCacheForced)
            .then(function (response) { return _this.lookupMapService.mapToRestrictedAnswers(response.data); });
    };
    LookupApiService.prototype.getRestrictedNotify = function (updateCacheForced) {
        var _this = this;
        if (updateCacheForced === void 0) { updateCacheForced = false; }
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.restrictedNotify;
        var request = this.urlParamsService.createGetRequest(url);
        return this.apiUtilService
            .cachedRequest(request, CacheType.longTerm, updateCacheForced)
            .then(function (response) { return _this.lookupMapService.mapToRestrictedNotifys(response.data); });
    };
    LookupApiService.prototype.getOrganizationTimezones = function (updateCacheForced) {
        var _this = this;
        if (updateCacheForced === void 0) { updateCacheForced = false; }
        var url = this.getApiRoot() + "/" + organizationConfig.lookup.organizationTimezones;
        var request = this.urlParamsService.createGetRequest(url);
        return this.apiUtilService
            .cachedRequest(request, CacheType.longTerm, updateCacheForced)
            .then(function (response) { return _this.lookupMapService.mapToOrganizationTimezones(response.data); });
    };
    return LookupApiService;
}());
export { LookupApiService };
