import { ObjectUtils } from './../../../framework/object-utils/object-utils';

export interface ISaveScheduleForPostingRequest {
    postingScheduleOptions: IPostScheduleSettings;
    startDate: string;
    endDate: string;
    orgLevelId: number;
}

export interface IPostScheduleSettings {
    offerToApproachingOT: boolean;
    offerToSameDay: boolean;
    offerToSecondaryPositions: boolean;
    allowAutoShiftPickUpByEmployee:boolean;
    notifyOnFirstPost: boolean;
    notifyOnAvailability: boolean;
    notifyWeekly: boolean;
    reminderCount : number;
    notifySenioritiesFirst: boolean;
    startDate: string;
    endDate: string;
    notifyDaysAndWeekly : number;
    offerToPositionGrouping: boolean;
}

export class PostScheduleSettings {
    public offerToApproachingOT: boolean = false;
    public offerToSameDay: boolean = false;
    public offerToSecondaryPositions: boolean = false;
    public allowAutoShiftPickUpByEmployee:boolean= false;
    public notifyOnFirstPost: boolean = false;
    public notifyOnAvailability: boolean = false;
    public notifyWeekly:  boolean;
    public reminderCount : number;
    public notifySenioritiesFirst: boolean = false;
    public startDate: Date;
    public endDate: Date;
    public notifyDaysAndWeekly : number;
    public offerToPositionGrouping: boolean = false;

    public clone(): PostScheduleSettings {
        let s: PostScheduleSettings = new PostScheduleSettings();
        s.offerToApproachingOT = this.offerToApproachingOT;
        s.offerToSameDay = this.offerToSameDay;
        s.offerToSecondaryPositions = this.offerToSecondaryPositions;
        s.notifyOnFirstPost = this.notifyOnFirstPost;
        s.notifyOnAvailability = this.notifyOnAvailability;
        s.notifyWeekly = this.notifyWeekly;
        s.reminderCount = this.reminderCount;
        s.notifySenioritiesFirst = this.notifySenioritiesFirst;
        s.notifyDaysAndWeekly = this.notifyDaysAndWeekly;
        s.allowAutoShiftPickUpByEmployee = this.allowAutoShiftPickUpByEmployee;
        s.offerToPositionGrouping = this.offerToPositionGrouping
        return s;
    }
}
