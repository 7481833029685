import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/switchMap';
import { mutableSelect } from '../../../core/decorators/index';
import { unsubscribeInService } from '../../../../app/core/decorators/index';

import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';
import * as moment from 'moment';
import * as _ from 'lodash';
import {
  IdealSchedulePosition, IdealScheduleList, IdealSchedulePositionDefinition
} from '../../models/index';
import { IdealScheduleApiService } from './ideal-schedule-api.service';
import { OrgLevelWatchService } from '../../../organization/services/org-level/org-level-watch.service';
import { IdealScheduleConfigType, IdealScheduleConfigTypeDefinition, IdealScheduleConfigTypes, IdealScheduleConfigCensus, IdealScheduleConfigCensusDefinition, IdealSchedulePositionPeriod, IdealScheduleConfigCensusOptions, IdealScheduleOverviewItem } from '../../models/ideal-schedule/index';
import { IdealScheduleHelperService } from './ideal-schedule.helper.service';
import { LookupMultiselectModel } from '../../../common/models/index';
import { ParLevels } from '../../models/ideal-schedule/index';
import { Actions, IDestroyService } from '../../../core/models/index';
import { MasterScheduleManagementService } from '../../../scheduler/services/index';
import { ChangeManagementService, FileService, ModalService } from '../../../common/services/index';
import { IdealSchedulePositionRange } from '../../models/ideal-schedule/ideal-schedule-position-range';
import { IdealScheduleGridToolbarComponent } from '../../components/ideal-schedule/index';
import { Subject } from 'rxjs';
import { IdealScheduleShiftGroupDTO } from '../../models/ideal-schedule/dto';
import { Action } from 'rxjs/internal/scheduler/Action';
import { DownloadTemplate, IdealScheduleImportTemplate, ImportException, OrgLevelDetails } from '../../models/ideal-schedule/ideal-schedule-import-template';
import { FileBlobResponse } from './../../../../app/core/models/api/file-blob-response';
import { v4 as UUID } from 'uuid';
import { NotificationsService } from './../../../../app/core/components';
import { DownloadDataService } from '../file-uploads/download-data.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


@Injectable()
export class IdealScheduleStateService implements IDestroyService {

  @mutableSelect()
  public orgLevel$: Observable<OrgLevel>;

  public onIdealScheduleList$: ReplaySubject<IdealScheduleList>;
  public onFiltersChanged$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public changed$: ReplaySubject<IdealSchedulePosition> = new ReplaySubject<IdealSchedulePosition>(1);
  public poolChanged$: ReplaySubject<IdealSchedulePosition> = new ReplaySubject<IdealSchedulePosition>(1);
  public totalHoursChanged$: ReplaySubject<IdealSchedulePosition> = new ReplaySubject<IdealSchedulePosition>(1);
  public cycle: moment.Range;
  public censusChange$ = new Subject<boolean>();
  public IdealScheduleConfigTypes: IdealScheduleConfigType[] = IdealScheduleConfigTypes;
  public shiftGroupDetailss: IdealScheduleShiftGroupDTO;
  public organizationDetails: OrgLevelDetails[];
  public idealScheduleCurrentDateRangePositions: any;


  @unsubscribeInService()
  public onTotalHoursSubscription: Subscription;
  public isShiftGroupEnabled: boolean = false;
  public isShiftNavPopupEnabled: boolean = false;
  public isDefaultShiftConfigured: boolean = false;
  public actionData: Actions = null;
  public state: {
    isLoading: boolean,
    isLoadingPositionPool: boolean,
    isScheduleAvailable: boolean,
    isNoDepartmentLevel: boolean,
    isNoPositionsAvailable: boolean,
    isError: boolean
  };
  public isDefaultShiftGroupFlagEnabled: boolean = false;

  private data: {
    orgLevel: OrgLevel,
    idealSchedule: IdealScheduleList,
    selectedPositionDefinition: IdealSchedulePositionDefinition,
    selectedPositionDefinitionPeriod: IdealSchedulePositionPeriod,
    selectedPositionType: IdealScheduleConfigType,
    originalPosition: IdealSchedulePosition,
    selectedPosition: IdealSchedulePosition,
    selectedConfigCensus: IdealScheduleConfigCensus,
    positions: Map<IdealScheduleConfigType, IdealSchedulePosition>,
    selectedShiftsGroups: Map<IdealSchedulePositionRange, LookupMultiselectModel[]>,
    selectedShifts: Map<IdealSchedulePositionRange, LookupMultiselectModel[]>,
    selectedUnits: Map<IdealSchedulePositionRange, LookupMultiselectModel[]>,
    hideUnused: boolean,
    currentRangeConfigPositions: IdealSchedulePositionDefinition[]
  };

  private scheduleSubscription: Subscription;
  private restorePosition: IdealSchedulePosition;
  private restorePeriod: IdealSchedulePositionPeriod;
  public idealToolBarComponent: IdealScheduleGridToolbarComponent;
  public shiftGroupDetails: IdealSchedulePositionRange;
  private orgLevelSubscription: Subscription;
  constructor(
    private scheduleApiService: IdealScheduleApiService,
    private orgLevelService: OrgLevelWatchService,
    private scheduleHelper: IdealScheduleHelperService,
    private changeManagementService: ChangeManagementService,
    private fileService: FileService,
    public notificationService: NotificationsService,
    public downloadService: DownloadDataService) {
    this.onIdealScheduleList$ = new ReplaySubject(1);
    this.state = {
      isLoading: true,
      isLoadingPositionPool: false,
      isScheduleAvailable: false,
      isNoDepartmentLevel: false,
      isNoPositionsAvailable: false,
      isError: false
    };

    this.data = {
      orgLevel: null,
      idealSchedule: null,
      selectedPositionDefinition: null,
      selectedPositionDefinitionPeriod: null,
      originalPosition: null,
      selectedPosition: null,
      selectedPositionType: null,
      selectedConfigCensus: null,
      positions: new Map<IdealScheduleConfigType, IdealSchedulePosition>(),
      selectedShiftsGroups: new Map<IdealSchedulePositionRange, LookupMultiselectModel[]>(),
      selectedShifts: new Map<IdealSchedulePositionRange, LookupMultiselectModel[]>(),
      selectedUnits: new Map<IdealSchedulePositionRange, LookupMultiselectModel[]>(),
      hideUnused: false,
      currentRangeConfigPositions: null
    };

    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: any) => {
      if (orgLevel) {
        this.subscribe();
      }
    });

  }

  public subscribe(): void {
    this.scheduleSubscription = this.orgLevel$
      .do(() => this.state.isLoading = true)
      .switchMap((orgLevel: OrgLevel) => {
        this.clearState(false);
        this.data.orgLevel = orgLevel;
        this.getOrganizationsList(orgLevel.id);
        if (orgLevel.type === OrgLevelType.department) {
          this.scheduleApiService.getIdealSchedule(orgLevel.id)
            .then((result: { actions: Actions, records: IdealScheduleList }) => {
              this.actionData = result.actions;
              this.onIdealScheduleSubscribeComplete(result.records);
              this.GetIdealShiftValidationData();
              return result.records;
            });
        } else {
          this.state.isNoDepartmentLevel = true;
          this.state.isLoading = false;
          return Promise.resolve(null);
        }
      })
      .subscribe(
        (list: IdealScheduleList) => this.onIdealScheduleSubscribeComplete(list),
        (error: any) => this.clearState(true)
      );
  }

  public destroy(): void {
    // See #issueWithAOTCompiler
  }

  public refreshIdealScheduleList(orgLevelId: number): void {
    this.scheduleApiService.getIdealSchedule(orgLevelId)
      .then((result: { actions: Actions, records: IdealScheduleList }) => {
        this.onIdealScheduleSubscribeComplete(result.records)
      });
  }

  public unsubscribe(): void {
    this.scheduleSubscription.unsubscribe();
  }

  public get orgLevelId(): number {
    return this.data.orgLevel ? this.data.orgLevel.id : 0;
  }

  public get idealSchedule(): IdealScheduleList {
    return this.data.idealSchedule;
  }

  public setIdealSchedule(selected: IdealScheduleList): void {
    this.data.idealSchedule = selected;
    let position: any = null;
    if (!!selected && !!selected.positions && selected.positions.length > 0) {
      if (this.restorePosition) {
        position = _.find(selected.positions, pos => pos.position.id === this.restorePosition.position.id);
      }
      if (position) {
        this.data.selectedPositionDefinition = null;
      } else {
        position = selected.positions[0];
      }
    }
    this.setSelectedPositionDefinition(position);
  }

  public get selectedPositionDefinition(): IdealSchedulePositionDefinition {
    return this.data.selectedPositionDefinition;
  }

  public get selectedPositionDefinitionPeriod(): IdealSchedulePositionPeriod {
    return this.data.selectedPositionDefinitionPeriod;
  }

  public setSelectedPositionDefinition(selected: IdealSchedulePositionDefinition): void {
    // if (selected && this.data.selectedPositionDefinition &&
    //   selected.position.id === this.data.selectedPositionDefinition.position.id) {
    //   return;
    // }

    this.data.selectedPositionDefinition = selected;

    this.clearSelectedPosition();
    if (selected) {
      this.data.selectedPositionType = selected.idealScheduleType;

      let range: IdealSchedulePositionPeriod;
      if (this.restorePeriod) {
        range = _.find(selected.periods, (r: IdealSchedulePositionPeriod) => {
          return this.restorePeriod.name === r.name;
        });
      } else {
        let now: moment.Moment = moment();
        range = _.find(selected.periods, (r: IdealSchedulePositionPeriod) => {
          return now.isSameOrAfter(r.startDate) && now.isSameOrBefore(r.endDate);
        });
      }

      if (_.isNil(range)) {
        range = _.get(selected.periods, 0);
      }

      this.data.selectedPositionDefinitionPeriod = range;
      this.clearRestoreData();
      this.loadPosition(range.startDate, range.endDate);
    }
  }

  public get selectedPositionType(): IdealScheduleConfigType {
    return this.data.selectedPositionType;
  }

  public set selectedPositionType(positionType: IdealScheduleConfigType) {
    if (!positionType || (this.data.selectedPositionType && positionType.id === this.data.selectedPositionType.id)) {
      return;
    }

    this.data.selectedPositionType = positionType;
    this.data.selectedPosition = this.getSelectedPosition(this.data.selectedPositionType);
    this.positionChanged();
  }

  public changePositionType(positionTypeDefinition: IdealScheduleConfigTypeDefinition): void {
    this.selectedPositionType = _.find(
      IdealScheduleConfigTypes,
      idealScheduleConfigType => idealScheduleConfigType.id === positionTypeDefinition
    );
  }

  public get selectedConfigCensus(): IdealScheduleConfigCensus {
    return this.data.selectedConfigCensus;
  }

  public set selectedConfigCensus(configCensus: IdealScheduleConfigCensus) {
    if (!configCensus || (this.data.selectedConfigCensus && configCensus.id === this.data.selectedConfigCensus.id)) {
      return;
    }

    if (this.data.selectedPosition) {
      this.data.selectedPosition.hasChanges = true;
    }

    this.data.selectedConfigCensus = configCensus;
  }

  public get hideUnused(): boolean {
    return this.data.hideUnused;
  }

  public set hideUnused(hideUnused: boolean) {
    this.data.hideUnused = hideUnused;
    this.onFiltersChanged$.next(null);
  }

  public get selectedPosition(): IdealSchedulePosition {
    return this.data.selectedPosition;
  }

  public get originalPosition(): IdealSchedulePosition {
    return this.data.originalPosition;
  }

  public getSelectedShiftsGroups(range?: IdealSchedulePositionRange): LookupMultiselectModel[] {
    if (!range && this.data.selectedPosition && this.data.selectedPosition.ranges) {
      range = _.first(this.data.selectedPosition.ranges);
    }

    return this.data.selectedShiftsGroups.get(range) || [];
  }

  public setSelectedShiftsGroups(models: LookupMultiselectModel[], range?: IdealSchedulePositionRange): void {
    if (!range && this.data.selectedPosition && this.data.selectedPosition.ranges) {
      range = _.first(this.data.selectedPosition.ranges);
    }

    this.data.selectedShiftsGroups.set(range, models);
  }

  public getSelectedShifts(range?: IdealSchedulePositionRange): LookupMultiselectModel[] {
    if (!range && this.data.selectedPosition && this.data.selectedPosition.ranges) {
      range = _.first(this.data.selectedPosition.ranges);
    }

    return this.data.selectedShifts.get(range) || [];
  }

  public setSelectedShifts(models: LookupMultiselectModel[], range?: IdealSchedulePositionRange): void {
    if (!range && this.data.selectedPosition && this.data.selectedPosition.ranges) {
      range = _.first(this.data.selectedPosition.ranges);
    }

    this.data.selectedShifts.set(range, models);

    if (this.data.selectedPosition) {
      this.data.selectedPosition.hasChanges = true;
    }
  }

  public getSelectedUnits(range?: IdealSchedulePositionRange): LookupMultiselectModel[] {
    if (!range && this.data.selectedPosition && this.data.selectedPosition.ranges) {
      range = _.first(this.data.selectedPosition.ranges);
    }

    return this.data.selectedUnits.get(range) || [];
  }

  public setSelectedUnits(models: LookupMultiselectModel[], range?: IdealSchedulePositionRange): void {
    if (!range && this.data.selectedPosition && this.data.selectedPosition.ranges) {
      range = _.first(this.data.selectedPosition.ranges);
    }

    this.data.selectedUnits.set(range, models);

    if (this.data.selectedPosition) {
      this.data.selectedPosition.hasChanges = true;
    }
  }

  public getSelectedPosition(positionType: IdealScheduleConfigType): IdealSchedulePosition {
    let position: IdealSchedulePosition = this.data.positions.get(positionType);
    if (position) {
      return position;
    }

    let clone: IdealSchedulePosition = this.clonePositionFromExisting(positionType);
    this.data.positions.set(positionType, clone);
    return clone;
  }

  public configureShiftGroupData(models: LookupMultiselectModel[]) {
    if (this.data.selectedPosition != null && this.data.selectedPosition.ranges != null || undefined) {
      _.forEach(this.data.selectedPosition.ranges, (item) => {
        this.setSelectedShifts(models, item);
      });
    }
  }

  public positionChanged(): void {
    //Due to daterange null issue .so we added terminate condition
    if (this.data.selectedPosition != null && this.data.selectedPosition.ranges != null || undefined) {
      _.forEach(this.data.selectedPosition.ranges, (range: IdealSchedulePositionRange) => {
        this.recalculateHours(range);
        this.resetAcuityTypeOnTotalCensus(range);
      });
    }

    this.changed$.next(this.data.selectedPosition);
  }

  public resetAcuityTypeOnTotalCensus(range: IdealSchedulePositionRange) {
    this.censusChange$.subscribe((data: boolean) => {
      if (data) {
        if (this.data.selectedConfigCensus.name === 'Total') {
          if(range.acuity.acuity.id != null || undefined && this.data.selectedPosition.ranges[0].acuity.acuity != null || undefined) {
            range.acuity.acuity.id = this.data.selectedPosition.ranges[0].acuity.acuity.id;
            range.acuity.acuity.name = this.data.selectedPosition.ranges[0].acuity.acuity.name;
          }
        }
      }
    });
  }

  public get isFixedCensus(): boolean {
    return this.selectedConfigCensus && this.selectedConfigCensus.id === IdealScheduleConfigCensusDefinition.Fixed;
  }

  public get isRangedCensus(): boolean {
    return this.selectedConfigCensus && this.selectedConfigCensus.id === IdealScheduleConfigCensusDefinition.Range;
  }

  public isDailyVarianceChanged(): void {
    this.data.selectedPosition.isDailyVariance = !this.data.selectedPosition.isDailyVariance;
    this.changed$.next(this.data.selectedPosition);
  }

  public clonePositionFromExisting(positionType: IdealScheduleConfigType): IdealSchedulePosition {
    let originalPosition: IdealSchedulePosition =
      this.data.positions.get(_.find(IdealScheduleConfigTypes, idealScheduleConfigType => idealScheduleConfigType.id === IdealScheduleConfigTypeDefinition.ShiftUnit))
      || this.data.positions.get(_.find(IdealScheduleConfigTypes, idealScheduleConfigType => idealScheduleConfigType.id === IdealScheduleConfigTypeDefinition.Shift))
      || this.data.positions.get(_.find(IdealScheduleConfigTypes, idealScheduleConfigType => idealScheduleConfigType.id === IdealScheduleConfigTypeDefinition.ShiftGroup));

    if (!originalPosition) {
      return null;
    }

    this.data.originalPosition = null;
    let position: IdealSchedulePosition = _.cloneDeep(originalPosition);
    position.idealScheduleType = positionType;
    this.configureIdealSchedule(position, positionType);
    return position;
  }

  public cloneRange(range: IdealSchedulePositionRange): void {
    if (!this.data.selectedPosition) {
      return;
    }

    let clone: IdealSchedulePositionRange = _.cloneDeep(range);
    clone.id = -1;

    this.data.selectedPosition.ranges.push(clone);
  }

  public deleteRange(rangeIndex: number): void {
    this.data.selectedPosition.ranges.splice(rangeIndex, 1);
    this.rangeChanged();
  }

  public setSelectedPosition(positionType: IdealScheduleConfigType, selected: IdealSchedulePosition): void {
    this.data.positions.clear();
    this.data.positions.set(positionType, selected);
    this.data.selectedPositionType = positionType;
    this.data.selectedConfigCensus = selected.idealScheduleCensus;
    this.data.selectedPosition = this.getSelectedPosition(positionType);
    this.positionChanged();
  }

  public selectedPositionPeriodChanged(selected: IdealSchedulePositionPeriod): void {

    if (this.data.selectedPosition && this.data.selectedPosition.name === this.selectedPositionDefinition.position.name) {
      return;
    }
    this.data.selectedPositionDefinitionPeriod = selected;
    this.loadPosition(selected.startDate, selected.endDate);
  }

  public clearSelectedPosition(): void {
    this.data.positions.set(_.find(IdealScheduleConfigTypes, idealScheduleConfigType => idealScheduleConfigType.id === IdealScheduleConfigTypeDefinition.ShiftGroup), null);
    this.data.positions.set(_.find(IdealScheduleConfigTypes, idealScheduleConfigType => idealScheduleConfigType.id === IdealScheduleConfigTypeDefinition.ShiftUnit), null);
    this.data.positions.set(_.find(IdealScheduleConfigTypes, idealScheduleConfigType => idealScheduleConfigType.id === IdealScheduleConfigTypeDefinition.Shift), null);
    this.data.selectedPosition = null;
  }

  public resetShiftRowHours(range: IdealSchedulePositionRange, rowUniqueId: string): void {
    let current: ParLevels = _.find(range.parLevels, (level: ParLevels) => {
      return level.uniqueId === rowUniqueId;
    });

    let hasChanges: boolean = false;
    for (let dayNumber: number = 0; dayNumber <= 7; dayNumber++) {
      hasChanges = hasChanges || current.getDayCounter(dayNumber) > 0 || current.getDayHours(dayNumber) > 0;
      current.setDayCounter(dayNumber, 0);
      current.setDayHours(dayNumber, 0);
    }
    this.recalculateHours(range);

    if (hasChanges) {
      this.selectedPosition.hasChanges = true;
    }
  }

  public applyToAllDays(range: IdealSchedulePositionRange, rowUniqueId: string): void {
    let current: ParLevels = _.find(range.parLevels, (level: ParLevels) => {
      return level.uniqueId === rowUniqueId;
    });

    current.isDailyVariance = !current.isDailyVariance;
    this.recalculateHours(range);
  }

  public resetDailyHours(range: IdealSchedulePositionRange, dayNumber: number): void {
    _.forOwn(range.parLevels, (level: ParLevels) => {
      level.setDayCounter(dayNumber, 0);
      level.setDayHours(dayNumber, 0);
    });
    this.recalculateHours(range);
  }

  public setDayCounter(schedule: IdealSchedulePosition, range: IdealSchedulePositionRange, parLevelUniqueId: string, dayNumber: number, value: number): void {
    let parLevel: ParLevels = range.parLevels[parLevelUniqueId];
    if (!parLevel) {
      return;
    }
    if (!value) {
      value = 0;
    }
    if (!this.data.originalPosition) {
      this.data.originalPosition = _.cloneDeep(schedule);
    }
    schedule.hasChanges = true;
    range.hasChanges = true;
    this.changeManagementService.changeNotify();

    let duration: number = parLevel.shift ? parLevel.shift.durationHours : parLevel.shiftGroup.durationHours;
    parLevel.setDayCounter(dayNumber, value);
    parLevel.setDayHours(dayNumber, value * duration);
    range.counters[dayNumber] = this.scheduleHelper.calculateDayCounterHours(range, dayNumber);

    if (dayNumber === 0) {
      for (let dayN: number = 1; dayN <= 7; dayN++) {
        parLevel.setDayCounter(dayN, parLevel.days[0].counter);
        parLevel.setDayHours(dayN, parLevel.days[0].hours);
        range.counters[dayN] = this.scheduleHelper.calculateDayCounterHours(range, dayN);
      }
      range.totalHours = range.counters[dayNumber];
      //this.recalculateTotalHours(schedule);
      this.totalHoursChanged$.next(this.data.selectedPosition);
    }
  }

  public recalculateHours(range: IdealSchedulePositionRange): void {
    for (let dayNumber: number = 0; dayNumber <= 7; dayNumber++) {
      range.counters[dayNumber] = this.scheduleHelper.calculateDayCounterHours(range, dayNumber);
    }
    range.totalHours = range.counters[0];
    //this.recalculateTotalHours(range);
    this.totalHoursChanged$.next(this.data.selectedPosition);
  }

  public reconfigureRangeParLevels(range: IdealSchedulePositionRange): void {
    if (!range) {
      return;
    }

    let parLevels: StringMap<ParLevels>;

    const selectedUnits: LookupMultiselectModel[] = this.getSelectedUnits(range);
    const selectedShifts: LookupMultiselectModel[] = this.getSelectedShifts(range);
    const selectedShiftsGroups: LookupMultiselectModel[] = this.getSelectedShiftsGroups(range);

    switch (this.data.selectedPositionType.id) {
      case IdealScheduleConfigTypeDefinition.Shift:
        parLevels = this.scheduleHelper.getParLevelsForShift(range.parLevels, selectedShifts);
        break;
      case IdealScheduleConfigTypeDefinition.ShiftUnit:
        parLevels = this.scheduleHelper.getParLevelsForShiftAndUnit(range.parLevels, selectedShifts, selectedUnits);
        break;
      case IdealScheduleConfigTypeDefinition.ShiftGroup:
        parLevels = this.scheduleHelper.getParLevelsForShiftGroup(range.parLevels, selectedShiftsGroups);
        break;
      default:
        throw new Error('Unknown type of IdealScheduleConfigTypeDefinition');
    }

    range.parLevels = parLevels;

    this.positionChanged();
  }

  public reconfigureIdealSchedule(): void {
    if (!this.data.selectedPosition || !this.data.selectedPositionType) {
      return;
    }

    this.configureIdealSchedule(this.data.selectedPosition, this.data.selectedPositionType);
  }

  public configureIdealSchedule(position: IdealSchedulePosition, positionType: IdealScheduleConfigType): void {
    if (!position || !positionType) {
      return;
    }

    _.forEach(position.ranges, (range: IdealSchedulePositionRange) => {
      let parLevels: StringMap<ParLevels>;

      const selectedUnits: LookupMultiselectModel[] = this.getSelectedUnits(range);
      const selectedShifts: LookupMultiselectModel[] = this.getSelectedShifts(range);
      //const selectedShiftsGroups: LookupMultiselectModel[] = this.getSelectedShiftsGroups(range);

      switch (positionType.id) {
        case IdealScheduleConfigTypeDefinition.Shift:
          parLevels = this.scheduleHelper.getParLevelsForShift(range.parLevels, selectedShifts);
          break;
        case IdealScheduleConfigTypeDefinition.ShiftUnit:
          parLevels = this.scheduleHelper.getParLevelsForShiftAndUnit(range.parLevels, selectedShifts, selectedUnits);
          break;
        case IdealScheduleConfigTypeDefinition.ShiftGroup:
          parLevels = this.scheduleHelper.getParLevelsForShift(range.parLevels, selectedShifts);
          //parLevels = this.scheduleHelper.getParLevelsForShiftGroup(range.parLevels, selectedShiftsGroups);
          break;
        default:
          throw new Error('Unknown type of IdealScheduleConfigTypeDefinition');
      }

      range.parLevels = parLevels;
    });

    this.positionChanged();
  }

  /*
  public saveTargetHours(definition: IdealSchedulePositionDefinition, value: number): void {

    if (definition) {
      definition.targetHours = value;

      this.scheduleApiService.saveIdealScheduleConfiguration(
        this.data.idealSchedule.id,
        this.orgLevelService.getCurrentOrgLevel().id,
        definition.startDate,
        definition.endDate,
        definition.position.id,
        value)
        .then((success: any) => {
          if (this.selectedPosition && this.selectedPosition.position.name === definition.position.name && this.selectedPosition.isActive) {
            this.selectedPosition.targetHours = definition.targetHours;
            this.totalHoursChanged$.next(this.selectedPosition);
          }
        })
        .catch((error: any) => {
          this.clearState(true);
        });
    }
  }
  */

  public savePosition(position: IdealSchedulePosition, startDate: Date, endDate: Date): void {
    position.hasChanges = true;
    this.changeManagementService.changeNotify();
    this.state.isLoadingPositionPool = true;
    let isPeriodChanged: boolean = !(moment(position.startDate).isSame(startDate) && moment(position.endDate).isSame(endDate));
    this.scheduleApiService.saveIdealSchedulePosition(
      this.data.idealSchedule.id, this.orgLevelService.getCurrentOrgLevel().id,
      this.data.selectedConfigCensus,
      startDate,
      endDate,
      position)
      .then((success: any) => {
        this.clearSelectedPosition();
        MasterScheduleManagementService.firstLoad = true;
        position.hasChanges = false;
        this.changeManagementService.clearChanges();
        if (isPeriodChanged) {
          this.restorePosition = position;
          this.restorePeriod = new IdealSchedulePositionPeriod();
          this.restorePeriod.startDate = startDate;
          this.restorePeriod.endDate = endDate;
          this.refreshIdealScheduleList(this.orgLevelService.getCurrentOrgLevel().id);
        } else {
          this.loadPosition(
            this.data.selectedPositionDefinitionPeriod.startDate,
            this.data.selectedPositionDefinitionPeriod.endDate);
        }
      })
      .catch((error: any) => this.clearState(true));
  }

  public discardChanges(): void {
    let position: IdealSchedulePosition = this.selectedPosition;
    if (_.isNull(this.selectedPosition)) return;
    this.loadPosition(position.startDate, position.endDate);
  }

  public isCurrentOrFutureSchedule(position: IdealSchedulePosition): boolean {
    if (!position) {
      return null;
    }
    return moment(position.endDate).isSameOrAfter(moment(new Date()));
  }

  public rangeChanged(): void {
    if (!this.selectedPosition) {
      return;
    }

    this.selectedPosition.hasChanges = true;
  }

  public isRangeMinValueValid(checkedRange: IdealSchedulePositionRange, checkedRangeIndex: number): boolean {
    if (!this.selectedPosition || !this.selectedPosition.ranges.length) {
      return true;
    }

    const otherRanges: IdealSchedulePositionRange[] = this.getOtherRangesByAcuity(checkedRange, checkedRangeIndex);

    return !_.some(
      otherRanges,
      (otherRange: IdealSchedulePositionRange) =>
        checkedRange.acuity.minValue >= otherRange.acuity.minValue
        && checkedRange.acuity.minValue <= otherRange.acuity.maxValue
    );
  }

  public isRangeMaxValueValid(checkedRange: IdealSchedulePositionRange, checkedRangeIndex: number): boolean {
    if (!this.selectedPosition || !this.selectedPosition.ranges.length) {
      return true;
    }

    const otherRanges: IdealSchedulePositionRange[] = this.getOtherRangesByAcuity(checkedRange, checkedRangeIndex);

    return !_.some(
      otherRanges,
      (otherRange: IdealSchedulePositionRange) =>
        checkedRange.acuity.maxValue >= otherRange.acuity.minValue
        && checkedRange.acuity.maxValue <= otherRange.acuity.maxValue
    );
  }

  public isRangeValid(checkedRange: IdealSchedulePositionRange, checkedRangeIndex: number): boolean {
    if (!this.selectedPosition || !this.selectedPosition.ranges.length) {
      return true;
    }

    return this.rangeHasConflictingValues(checkedRange, checkedRangeIndex);
  }

  public areRangesValid(): boolean {
    if (!this.selectedPosition || !this.selectedConfigCensus || this.isFixedCensus || !this.selectedPosition.ranges.length) {
      return true;
    }

    let valid: boolean = true;

    _.forEach(this.selectedPosition.ranges, (range: IdealSchedulePositionRange, index: number) => {
      if (_.isNull(range.acuity.minValue) || _.isNull(range.acuity.maxValue)
        || _.isUndefined(range.acuity.minValue) || _.isUndefined(range.acuity.maxValue)
        || range.acuity.maxValue === 0) {
        valid = false;
        return;
      }

      if (!range.parLevels) {
        valid = false;
        return;
      }

      const isConflicting: boolean = this.rangeHasConflictingValues(range, index);

      if (isConflicting) {
        valid = false;
        return;
      }
    });

    return valid;
  }

  public allNecessaryFieldsFilled(): boolean {
    if (!this.data.selectedPositionType) return false;
    return true;
  }

  public canBeSaved(): boolean {
    return this.selectedPosition.hasChanges && this.areRangesValid() && this.allNecessaryFieldsFilled();
  }

  public get isLoading(): boolean {
    return this.state.isLoading || this.state.isLoadingPositionPool;
  }

  private clearRestoreData(): void {
    this.restorePeriod = null;
    this.restorePosition = null;
  }

  private rangeHasConflictingValues(range: IdealSchedulePositionRange, index: number): boolean {
    return _.some(
      this.getOtherRangesByAcuity(range, index),
      (otherRange: IdealSchedulePositionRange) => {
        let minRange: IdealSchedulePositionRange;
        let maxRange: IdealSchedulePositionRange;

        if (range.acuity.minValue < otherRange.acuity.minValue) {
          minRange = range;
          maxRange = otherRange;
        } else {
          minRange = otherRange;
          maxRange = range;
        }

        return minRange.acuity.maxValue >= maxRange.acuity.minValue;
      }
    );
  }

  private getOtherRangesByAcuity(checkedRange: IdealSchedulePositionRange, checkedRangeIndex: number): IdealSchedulePositionRange[] {
    return _.filter(
      this.selectedPosition.ranges,
      (range: IdealSchedulePositionRange, index: number) =>
        index !== checkedRangeIndex
        && range.acuity.acuity
        && checkedRange.acuity.acuity
        && range.acuity.acuity.id === checkedRange.acuity.acuity.id
    );
  }

  private clearState(changeLoading: boolean): void {
    this.state.isScheduleAvailable = false;
    this.state.isNoDepartmentLevel = false;
    this.state.isNoPositionsAvailable = false;
    this.state.isError = false;
    if (changeLoading) {
      this.state.isLoading = false;
    }
  }

  private onIdealScheduleSubscribeComplete(list: IdealScheduleList): void {
    if (list !== null) {
      this.state.isScheduleAvailable = list !== null && list.positions && list.positions.length > 0;
      this.state.isNoPositionsAvailable = list !== null && list.positions && list.positions.length === 0;

      this.setIdealSchedule(list);
    }
    this.state.isLoading = false;
    this.data.currentRangeConfigPositions = list.positions;
    this.onIdealScheduleList$.next(list);
  }

  private loadPosition(startDate: Date, endDate: Date): Promise<IdealSchedulePosition> {
    this.state.isLoadingPositionPool = true;
    return <any>this.scheduleApiService.getIdealSchedulePosition(
      this.orgLevelId,
      this.selectedPositionDefinition.position.id,
      startDate,
      endDate)
      .then((value: IdealSchedulePosition) =>
        this.loadPositionSuccess(value)
      )
      .catch((ex) => {
        this.state.isLoadingPositionPool = false;
      });
  }

  private loadPositionSuccess(position: IdealSchedulePosition): IdealSchedulePosition {
    this.setSelectedPosition(position.idealScheduleType, position);
    _.forEach(position.ranges, (range: IdealSchedulePositionRange) => {
      const parLevels: ParLevels[] = _.values(range.parLevels);

      this.setSelectedUnits(this.scheduleHelper.getSelectedUnitsFromParLevels(parLevels), range);
      this.setSelectedShifts(this.scheduleHelper.getSelectedShiftsFromParLevels(parLevels), range);
      this.setSelectedShiftsGroups(this.scheduleHelper.getSelectedShiftGroupsFromParLevels(parLevels), range);

      this.recalculateHours(range);
    });
    position.targetHours = this.data.selectedPositionDefinition.getTargetHours();

    this.state.isLoadingPositionPool = false;
    this.data.selectedPosition.hasChanges = false;
    this.changeManagementService.clearChanges();
    this.poolChanged$.next(position);
    return position;
  }

  // private getPositionByDates(pools: IdealSchedulePosition[], startDate: moment.Moment, endDate: moment.Moment): IdealSchedulePosition {
  //   if (!pools || pools.length === 0) {
  //     return new IdealSchedulePosition();
  //   }
  //   let position: any = _.find(pools, (elem: IdealSchedulePosition) => {
  //     return elem.startDate === startDate && elem.endDate === endDate;
  //   });
  //   return !position ? pools[0] : position;
  // }

  private recalculateTotalHours(value: IdealSchedulePosition): void {
    if (this.data.selectedPositionDefinition) {
      this.data.selectedPositionDefinition.totalHours = value.totalHours;
    }
  }
  public GetIdealShiftValidationData() {
    this.scheduleApiService.GetIdealShiftValidation(this.data.orgLevel.id).then((idealScheduleGroup: IdealScheduleShiftGroupDTO) => {
      this.shiftGroupDetailss = idealScheduleGroup;
      this.isDefaultShiftConfigured = idealScheduleGroup.shiftGroupCount < 3 ? true : false;
      this.modifyOption(this.IdealScheduleConfigTypes);
    })
  }
  public modifyOption(options: any) {
    this.IdealScheduleConfigTypes = this.shiftGroupDetailss.flagStatus ? options : _.filter(options, (item) => item.id !== 1);
    return this.IdealScheduleConfigTypes;
  }

  public getOrganizationsList(orgLevelId) {
    this.scheduleApiService.getOrganizationsList(orgLevelId).then((data) => {
      this.organizationDetails = data;
    });
  }

  public checkPositionsValidation(modalService: ModalService, uniqueId: any, importData: IdealScheduleImportTemplate) {
    this.state.isLoading = true;
    let selectedShiftGroupData = importData.positionList.filter(x => x.isChecked && x.idealScheduleType.id == 1);
    if (selectedShiftGroupData && selectedShiftGroupData.length > 0) {
      if (this.isDefaultShiftConfigured) {
        const exceptionData = _.map(selectedShiftGroupData, (item) => {
          const data: ImportException = new ImportException();
          data.positionId = item.position.id;
          data.positionName = item.position.name;
          data.configurationType = item.idealScheduleType.name;
          data.censusType = item.idealScheduleCensus.name;
          data.errorMessage = 'Default Shifts Not Configured';
          data.isInvalidConfigType = true;
          data.isInvalidCensusType = false;
          data.isNoException = false;
          return data;
        });
        setTimeout(() => {
          this.state.isLoading = false;
          importData.exceptionData = exceptionData;
        }, 1000)
        return importData;
      }
      else {
        return this.downloadImportTemplate(modalService, uniqueId, importData);
      }
    }
    else {
      return this.downloadImportTemplate(modalService, uniqueId, importData);
    }
  }

  public downloadImportTemplate(modalService: ModalService, uniqueId: any, data: IdealScheduleImportTemplate) {
    this.state.isLoading = true;
    this.scheduleApiService.downloadImportTemplate(this.orgLevelId, data).then((resultSet: DownloadTemplate) => {
      if (resultSet.fileData != null && resultSet.fileName != null) {
        this.downloadService.excelDownloadDataProcess(resultSet.fileData, resultSet.fileName);
        this.state.isLoading = false;
        data.positionList.map(x => x.isChecked = false);
        return modalService.closeWindow(uniqueId);
      }
      else {
        this.notificationService.error('Error', 'Shifts Not Configured');
        this.state.isLoading = false;
        data.positionList.map(x => x.isChecked = false);
        return modalService.closeWindow(uniqueId);
      }
    }).catch((reason: any) => {
      console.log(reason);
      this.state.isLoading = false;
      data.positionList.map(x => x.isChecked = false);
      return modalService.closeWindow(uniqueId);
    });
  }

  public get currentRangeConfigPositions() {
    return this.data.currentRangeConfigPositions;
  }


  private isAllNoException(data: ImportException[]) {
    return _.every(data, (item: ImportException) => item.isConfigured && !item.isInvalidCensusType && !item.isInvalidConfigType);
  }

  private updateIdealScheduleId(importTemplate: IdealScheduleImportTemplate, exceptionData: ImportException[]) {
    _.map(importTemplate.positionList, (item: IdealSchedulePositionDefinition) => {
      if (exceptionData) {
        _.forEach(exceptionData, (exception) => {
          if (item.position.id == exception.positionId) {
            item.idealScheduleId = exception.idealScheduleId;
          }
        });
      }
    });
    return importTemplate;
  }

  public getUniqueId(): string {
    return UUID();
  }
}
