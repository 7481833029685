
<div class="slx-daterange">
  <label class="slx-daterange__date">
    <slx-datepicker #startDate="ngModel"
      class="slx-wide"
      name="startDate"
      placeholder="{{ startDatePlaceholder }}"
      [minDate]="minimumStartDate"
      [maxDate]="dates.endDate"
      [format]="format"
      [readonly]="startDateReadonly"
      [acceptNullDate]="false"
      [disabledDates]="disableStartDate"
      [ngModel]="dates.startDate"
      (ngModelChange)="onStartDateChanged($event)"
      (valueChange)="onValueChanged('startDate', startDate.valid, $event)"
      [readOnlyInput]="startDateReadOnlyInput"
    ></slx-datepicker>
    <div class="slx-daterange__error slx-error-block" *ngIf="startDate.errors">
      <span errorMessage for="date" *ngIf="startDate.errors.date"></span>
      <span errorMessage for="maxDate" *ngIf="startDate.errors.maxDate">The value should be lower than {{ dates.endDate | amDateFormat: appConfig.dateFormat }}</span>
    </div>
  </label>

  <label class="slx-daterange__date">
    <slx-datepicker #endDate="ngModel"
      class="slx-wide"
      name="endDate"
      placeholder="{{ endDatePlaceholder }}"
      [minDate]="dates.startDate"
      [maxDate]="maxDateForEndDate || maximumDate"
      [format]="format"
      [readonly]="endDateReadonly"
      [acceptNullDate]="false"
      [disabledDates]="disableEndDate"
      [ngModel]="dates.endDate"
      (ngModelChange)="onEndDateChanged($event)"
      (valueChange)="onValueChanged('endDate', endDate.valid, $event)"
      [readOnlyInput]="endDateReadOnlyInput"
    ></slx-datepicker>
    <div class="slx-daterange__error slx-error-block" *ngIf="endDate.errors">
      <span errorMessage for="date" *ngIf="endDate.errors.date"></span>
      <span errorMessage for="minDate" *ngIf="endDate.errors.minDate">The value should be greater than {{ dates.startDate | amDateFormat: appConfig.dateFormat }}</span>
      <span errorMessage for="maxDate" *ngIf="endDate.errors.maxDate">The value should be lower than {{ maxDateForEndDate || maximumDate | amDateFormat: appConfig.dateFormat }}</span>
    </div>
  </label>

  <button type="button" *ngIf="hasApplyButton" [disabled]="!datesValid" (click)="onApply()" class="slx-button slx-daterange__button" [ngClass]="{'slx-applyButton' : changeButtonStyling}">Apply</button>
</div>
<div class="slx-daterange__error slx-error-block" *ngIf="!isValidRange&&startDate.valid&&endDate.valid">
  <span>{{ maxRangeErrorText }}</span>
</div>
