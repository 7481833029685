import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { OrganizationTimezone } from '../../organization/models/lookup/organization-timezone';

@Pipe({ name: 'timezoneDisplayName' })
export class TimezoneDisplayNamePipe implements PipeTransform {
  transform(value: string, organizationTimezones:OrganizationTimezone[]): string {
    if (!value) return '';
    let timezone:OrganizationTimezone;
    timezone = _.find(organizationTimezones,(t:OrganizationTimezone)=> t.timezone === value);
    return (timezone.timezoneDisplayName != undefined) ? timezone.timezoneDisplayName : value;
  }
}
