import { Injectable } from '@angular/core';
import { PaycodeExceptionSortOverrideDto } from '../../models/pay-codes/paycode-exceptionsortoverride.dto';
import { PaycodeExceptionSortOverrideModel } from '../../models/pay-codes/paycode-exceptionsortoverride';



@Injectable({
    providedIn: 'root'
})
export class PaycodeExceptionSortOverrideMapService {

    public mapToModel(dto: PaycodeExceptionSortOverrideDto): PaycodeExceptionSortOverrideModel {
        return {
          exceptionId: Number(dto.exceptionId),
          overrideSortOrder: Number(dto.overrideSortOrder)
        };
    }
    
    public mapToDto(model: PaycodeExceptionSortOverrideModel): PaycodeExceptionSortOverrideDto {
        return {
            exceptionId: Number(model.exceptionId),
            overrideSortOrder: Number(model.overrideSortOrder)
        };
    }
    
    public mapToModelsFromDtos(dtos: PaycodeExceptionSortOverrideDto | PaycodeExceptionSortOverrideDto[]): PaycodeExceptionSortOverrideModel[] {
        const models: PaycodeExceptionSortOverrideModel[] = [];
        
		if (Array.isArray(dtos)) {
            dtos.forEach(dto => {
                models.push(this.mapToModel(dto));
            });
        } else if (dtos && typeof dtos === 'object') {
            models.push(this.mapToModel(dtos));
        } else {
            console.error('Invalid input: dtos is neither an array nor a valid object', dtos);
        }
        return models;
    }

    public mapToDtosFromModels(models: PaycodeExceptionSortOverrideModel | PaycodeExceptionSortOverrideModel[]): PaycodeExceptionSortOverrideDto[] {
        const dtos: PaycodeExceptionSortOverrideDto[] = [];
    
        if (Array.isArray(models)) {
            models.forEach(model => {
                dtos.push(this.mapToDto(model));
            });
        } else if (models && typeof models === 'object') {
            dtos.push(this.mapToDto(models));
        } else {
            console.error('Invalid input: models is neither an array nor a valid object', models);
        }
        return dtos;
    }
}