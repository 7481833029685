import * as tslib_1 from "tslib";
import { ReplaySubject, Subject, Observable, Subscription } from 'rxjs';
import { combineLatest } from 'rxjs/operators';
import { ManagementBaseService } from '../../../core/services/index';
import { ChangeManagementService } from '../../../common/services/index';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { AccessManagementService } from '../accessManagement/access-management.service';
import { ActivatedRoute } from '@angular/router';
import { NotificationsService } from '../../../core/components/angular2-notifications/simple-notifications/services/notifications.service';
import { PayCodeDetailsApiService } from './pay-code-details-api.service';
import { PayCodeDetailsContainer } from '../../models/pay-code-details/pay-code-details-container';
import * as _ from 'lodash';
import { RuleFormulaMapService } from './rule-formula-map.service';
var PayCodeDetailsManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(PayCodeDetailsManagementService, _super);
    function PayCodeDetailsManagementService(access, changeService, api, ruleFormulaMap, notificationsService, route) {
        var _this = _super.call(this) || this;
        _this.access = access;
        _this.changeService = changeService;
        _this.api = api;
        _this.ruleFormulaMap = ruleFormulaMap;
        _this.notificationsService = notificationsService;
        _this.route = route;
        _this.m_container = new PayCodeDetailsContainer();
        _this.addItemCmd$ = new ReplaySubject();
        _this.editItemCmd$ = new ReplaySubject();
        _this.viewRefresh$ = new Subject();
        _this.removeItemsCmd$ = new ReplaySubject();
        return _this;
    }
    Object.defineProperty(PayCodeDetailsManagementService.prototype, "container", {
        get: function () {
            return this.m_container;
        },
        enumerable: true,
        configurable: true
    });
    PayCodeDetailsManagementService.prototype.markAsDirty = function () {
        throw new Error('Method not implemented.');
    };
    PayCodeDetailsManagementService.prototype.init = function () {
        var _this = this;
        this.onStateChanged$.next({ isLoading: true });
        this.access.allowCorporationLevel = false;
        this.access.allowOrganizationLevel = true;
        this.access.allowDepartmentLevel = true;
        this.route.queryParams.subscribe(function (params) {
            var id = params['id'];
            if (id) {
                _this.m_container.id = +id;
            }
        });
        this.appDataSubscription = this.orgLevel$.pipe(combineLatest(this.user$)).subscribe(function (value) {
            var orgLevel = value[0], user = value[1];
            if (!orgLevel || !_.isNumber(orgLevel.id) || !user) {
                return;
            }
            _this.currentOrgLevel = orgLevel;
            _this.currentUser = user;
            _this.access.orgLevelType = _this.currentOrgLevel.type;
            if (_this.m_container.id) {
                _this.fetchPaycodeExceptions(_this.m_container.id);
            }
            else {
                _this.onLoaded$.next(_this.m_container);
            }
            _this.onStateChanged$.next({ isLoading: false });
        });
    };
    // AdditionalRequirements
    PayCodeDetailsManagementService.prototype.fetchAdditionalRequirements = function (id) {
        var _this = this;
        return this.api.getAdditionalRequirements(id).then(function (response) {
            _this.m_container.additionalRequirements = response.additionalRequirements;
            _this.m_container.updateRecords();
            _this.onLoaded$.next(_this.m_container);
        }).catch(function (error) {
            if (error.status === 404 || (error.error && error.error.status === 404)) {
                console.warn('No additional requirements found for exception ID:', id);
                _this.m_container.additionalRequirements = [];
                _this.m_container.updateRecords();
                _this.onLoaded$.next(_this.m_container);
            }
            else {
                console.error('Error fetching additional requirements by exception ID:', error);
            }
        });
    };
    PayCodeDetailsManagementService.prototype.saveAdditionalRequirement = function (exceptionId, data) {
        var _this = this;
        return this.api.saveAdditionalRequirements(exceptionId, data).then(function () {
            _this.notificationsService.success('Success', 'Additional requirement saved successfully');
            if (_this.m_container.id) {
                return _this.fetchAdditionalRequirements(exceptionId);
            }
        }).catch(function (error) {
            console.error('Error saving additional requirement:', error);
            throw error;
        });
    };
    PayCodeDetailsManagementService.prototype.deleteAdditionalRequirement = function (id) {
        var _this = this;
        this.api.deleteAdditionalRequirement(id).then(function () {
            _this.notificationsService.success('Success', 'Additional requirement deleted successfully');
            if (_this.m_container.id) {
                _this.fetchAdditionalRequirements(_this.m_container.id);
            }
        }).catch(function (error) {
            console.error('Error deleting additional requirement:', error);
        });
    };
    // PaycodeExceptions
    PayCodeDetailsManagementService.prototype.fetchPaycodeExceptions = function (id) {
        var _this = this;
        return this.api.getPaycodeExceptions(id, this.currentOrgLevel.organizationId).then(function (response) {
            // Trim whitespace from exWorkStatus
            response.exceptions = response.exceptions.map(function (exception) {
                if (exception.exWorkStatus) {
                    exception.exWorkStatus = exception.exWorkStatus.trim();
                }
                return exception;
            });
            _this.m_container.exceptions = response.exceptions;
            _this.m_container.updateRecords();
        }).catch(function (error) {
            console.error('Error fetching paycode exceptions:', error);
        });
    };
    PayCodeDetailsManagementService.prototype.savePaycodeException = function (data) {
        var _this = this;
        return this.api.savePaycodeException(data).then(function () {
            _this.notificationsService.success('Success', 'Paycode exception saved successfully');
            if (_this.m_container.id) {
                return _this.fetchPaycodeExceptions(_this.m_container.id);
            }
        }).catch(function (error) {
            console.error('Error saving paycode exception:', error);
            throw error;
        });
    };
    PayCodeDetailsManagementService.prototype.deletePaycodeException = function (id) {
        var _this = this;
        this.api.deletePaycodeException(id).then(function () {
            _this.notificationsService.success('Success', 'Paycode exception deleted successfully');
            if (_this.m_container.id) {
                _this.fetchPaycodeExceptions(_this.m_container.id);
            }
        }).catch(function (error) {
            console.error('Error deleting paycode exception:', error);
        });
    };
    // Rules
    PayCodeDetailsManagementService.prototype.fetchRules = function (id) {
        var _this = this;
        return this.api.getRules(id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _i, _a, rule, formulas;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.m_container.rules = response.rules;
                        _i = 0, _a = this.m_container.rules;
                        _b.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 4];
                        rule = _a[_i];
                        return [4 /*yield*/, this.api.getRuleFormulasByRuleId(rule.id)];
                    case 2:
                        formulas = _b.sent();
                        rule.ruleFormulas = this.ruleFormulaMap.mapToModels(formulas);
                        _b.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        this.m_container.updateRecords();
                        this.onLoaded$.next(this.m_container);
                        return [2 /*return*/];
                }
            });
        }); }).catch(function (error) {
            console.error('Error fetching rules:', error);
        });
    };
    PayCodeDetailsManagementService.prototype.fetchRulesByExceptionId = function (exceptionId) {
        var _this = this;
        return this.api.getRulesByExceptionId(exceptionId).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _i, _a, rule, formulas;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.m_container.rules = response.rules;
                        _i = 0, _a = this.m_container.rules;
                        _b.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 4];
                        rule = _a[_i];
                        return [4 /*yield*/, this.api.getRuleFormulasByRuleId(rule.id)];
                    case 2:
                        formulas = _b.sent();
                        rule.ruleFormulas = this.ruleFormulaMap.mapToModels(formulas);
                        _b.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        this.m_container.updateRecords();
                        this.onLoaded$.next(this.m_container);
                        return [2 /*return*/];
                }
            });
        }); }).catch(function (error) {
            if (error.status === 404 || (error.error && error.error.status === 404)) {
                console.warn('No rules found for exception ID:', exceptionId);
                _this.m_container.rules = [];
                _this.m_container.updateRecords();
                _this.onLoaded$.next(_this.m_container);
            }
            else {
                console.error('Error fetching rules by exception ID:', error);
            }
        });
    };
    PayCodeDetailsManagementService.prototype.saveRule = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var existingFormulas, formulasToDelete, _i, formulasToDelete_1, formula, _a, _b, formula, error_1;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 13, , 14]);
                        // Save the rule itself
                        return [4 /*yield*/, this.api.saveRule(data)];
                    case 1:
                        // Save the rule itself
                        _c.sent();
                        return [4 /*yield*/, this.api.getRuleFormulasByRuleId(data.id)];
                    case 2:
                        existingFormulas = _c.sent();
                        formulasToDelete = existingFormulas.filter(function (existingFormula) {
                            return !data.ruleFormulas.some(function (formula) { return formula.id === existingFormula.id; });
                        });
                        _i = 0, formulasToDelete_1 = formulasToDelete;
                        _c.label = 3;
                    case 3:
                        if (!(_i < formulasToDelete_1.length)) return [3 /*break*/, 6];
                        formula = formulasToDelete_1[_i];
                        return [4 /*yield*/, this.api.deleteRuleFormula(data.id, formula.id)];
                    case 4:
                        _c.sent();
                        _c.label = 5;
                    case 5:
                        _i++;
                        return [3 /*break*/, 3];
                    case 6:
                        _a = 0, _b = data.ruleFormulas;
                        _c.label = 7;
                    case 7:
                        if (!(_a < _b.length)) return [3 /*break*/, 10];
                        formula = _b[_a];
                        return [4 /*yield*/, this.api.saveRuleFormula(formula)];
                    case 8:
                        _c.sent();
                        _c.label = 9;
                    case 9:
                        _a++;
                        return [3 /*break*/, 7];
                    case 10:
                        this.notificationsService.success('Success', 'Rule and formulas saved successfully');
                        if (!this.m_container.id) return [3 /*break*/, 12];
                        return [4 /*yield*/, this.fetchRulesByExceptionId(this.m_container.id)];
                    case 11:
                        _c.sent();
                        _c.label = 12;
                    case 12: return [3 /*break*/, 14];
                    case 13:
                        error_1 = _c.sent();
                        console.error('Error saving rule and formulas:', error_1);
                        throw error_1;
                    case 14: return [2 /*return*/];
                }
            });
        });
    };
    PayCodeDetailsManagementService.prototype.deleteRule = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ruleFormulas, _i, ruleFormulas_1, formula, error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 9, , 10]);
                        return [4 /*yield*/, this.api.getRuleFormulasByRuleId(id)];
                    case 1:
                        ruleFormulas = _a.sent();
                        _i = 0, ruleFormulas_1 = ruleFormulas;
                        _a.label = 2;
                    case 2:
                        if (!(_i < ruleFormulas_1.length)) return [3 /*break*/, 5];
                        formula = ruleFormulas_1[_i];
                        return [4 /*yield*/, this.api.deleteRuleFormula(id, formula.id)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5: return [4 /*yield*/, this.api.deleteRule(id)];
                    case 6:
                        _a.sent();
                        this.notificationsService.success('Success', 'Rule and formulas deleted successfully');
                        if (!this.m_container.id) return [3 /*break*/, 8];
                        return [4 /*yield*/, this.fetchRulesByExceptionId(this.m_container.id)];
                    case 7:
                        _a.sent();
                        _a.label = 8;
                    case 8: return [3 /*break*/, 10];
                    case 9:
                        error_2 = _a.sent();
                        console.error('Error deleting rule and formulas:', error_2);
                        throw error_2;
                    case 10: return [2 /*return*/];
                }
            });
        });
    };
    PayCodeDetailsManagementService.prototype.validateRule = function (whereClause) {
        var _this = this;
        return this.api.validateRule(whereClause).then(function (isValid) {
            if (!isValid) {
                _this.notificationsService.error('Validation Failed', 'The SQL WHERE clause is invalid');
            }
            return isValid;
        }).catch(function (error) {
            console.error('Error validating rule:', error);
            return false; // Return false if there's an error
        });
    };
    PayCodeDetailsManagementService.prototype.deleteRuleFormula = function (ruleId, formulaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.api.deleteRuleFormula(ruleId, formulaId)];
                    case 1:
                        _a.sent();
                        this.notificationsService.success('Success', 'Rule formula deleted successfully');
                        return [3 /*break*/, 3];
                    case 2:
                        error_3 = _a.sent();
                        console.error('Error deleting rule formula:', error_3);
                        throw error_3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], PayCodeDetailsManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        mutableSelect(['session', 'user']),
        tslib_1.__metadata("design:type", Observable)
    ], PayCodeDetailsManagementService.prototype, "user$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], PayCodeDetailsManagementService.prototype, "appDataSubscription", void 0);
    return PayCodeDetailsManagementService;
}(ManagementBaseService));
export { PayCodeDetailsManagementService };
