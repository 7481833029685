import * as tslib_1 from "tslib";
import { Subscription } from 'rxjs/Subscription';
import { TimeclockDataService } from './../../../../../organization/services/time-clock/time-clock-data.service';
import { ConfirmDialogComponent } from './../../../../../common/components/confirm-dialog/confirm-dialog.component';
import { OnInit, OnDestroy, NgZone, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { EmployeeSectionsEmploymentApiService } from '../../../services/index';
import * as _ from 'lodash';
import { EmployeeSectionTimeclock, EmployeeSectionTimeclockContainer } from '../../../models/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { TimeclockDefinition } from '../../../../../organization/models/lookup/index';
import { TimeclocksApiService } from '../../../../../configuration/services/index';
import { GridComponent } from '@progress/kendo-angular-grid';
import { orderBy } from '@progress/kendo-data-query';
import { EmployeeValidatorAdapter } from '../../../../../organization/services/index';
import { ModalService } from '../../../../../common/services/modal/modal.service';
import { EmployeeDefinitionsApiService, OrgLevelWatchService } from '../../../../../organization/services/index';
import { appConfig } from '../../../../../../app/app.config';
import * as moment from 'moment';
import { unsubscribe } from '../../../../../core/decorators';
var EmployeeSectionsTimeclocksComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsTimeclocksComponent, _super);
    function EmployeeSectionsTimeclocksComponent(employeeSectionsEmploymentApiService, decorator, ngZone, timeclocksApiService, employeeValidatorAdapter, modalService, employeeDefinitionApi, changeDetector, orgLevelWatchService, timeclockDataService) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.employeeSectionsEmploymentApiService = employeeSectionsEmploymentApiService;
        _this.timeclocksApiService = timeclocksApiService;
        _this.employeeValidatorAdapter = employeeValidatorAdapter;
        _this.modalService = modalService;
        _this.employeeDefinitionApi = employeeDefinitionApi;
        _this.changeDetector = changeDetector;
        _this.orgLevelWatchService = orgLevelWatchService;
        _this.timeclockDataService = timeclockDataService;
        _this.sort = [];
        _this.skip = 0;
        _this.pageSize = 10;
        _this.assignedAll = false;
        _this.lastLoadedOrgLevelId = 0;
        _this.orgelvelTreeHasLoaded = false;
        return _this;
    }
    Object.defineProperty(EmployeeSectionsTimeclocksComponent.prototype, "employeeSectionTimeclockContainer", {
        get: function () {
            return this.timeclockContainer;
        },
        set: function (value) {
            this.timeclockContainer = value;
            if (this.timeclockContainer && this.timeclockContainer.homeOrgLevelId !== 0) {
                this.loadTimeclocksByOrgLevelId(this.timeclockContainer.homeOrgLevelId);
            }
            this.updateData();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsTimeclocksComponent.prototype, "badgeIdValidation", {
        get: function () {
            return EmployeeValidatorAdapter.badgeIdValidation;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsTimeclocksComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.timeclockLoadedSubscription = this.timeclockDataService.onLoaded$
            .subscribe(function (timeclocksContainer) {
            _this.timeclockDailySummaryMap = _.keyBy(timeclocksContainer.records, 'id');
            _this.refreshGrid();
        });
        this.timeclockDataService.loadTimeclocks();
    };
    EmployeeSectionsTimeclocksComponent.prototype.getSubsectionModel = function () {
        return this.timeclockContainer;
    };
    EmployeeSectionsTimeclocksComponent.prototype.getFormattedDate = function (date) {
        if (date != null) {
            return moment(date).format(appConfig.militaryDateTimeFormat);
        }
        else {
            return '';
        }
    };
    Object.defineProperty(EmployeeSectionsTimeclocksComponent.prototype, "form", {
        get: function () {
            return this.ngForm ? this.ngForm.form : null;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsTimeclocksComponent.prototype.setEditState = function (editState) {
        var len = this.gridView ? this.gridView.total : 0;
        if (editState) {
            for (var i = 0; i < len; i++)
                this.grid.editRow(i);
        }
        else {
            for (var i = 0; i < len; i++)
                this.grid.closeRow(i);
        }
        this.state.isEditMode = editState;
    };
    EmployeeSectionsTimeclocksComponent.prototype.onPageChange = function (event) {
        this.skip = event.skip;
        this.refreshGrid();
    };
    EmployeeSectionsTimeclocksComponent.prototype.onSortChange = function (sort) {
        this.sort = sort;
        this.refreshGrid();
    };
    EmployeeSectionsTimeclocksComponent.prototype.onRestrictionChanged = function (restriction) {
        _.map(this.readyTimeclocks, function (item) {
            if (item.assigned) {
                item.restriction = restriction;
            }
        });
        this.updateData();
    };
    EmployeeSectionsTimeclocksComponent.prototype.onItemRestrictionChanged = function (item, assigned) {
        if (assigned) {
            item.restriction = this.restriction;
        }
        else {
            item.restriction = null;
        }
    };
    Object.defineProperty(EmployeeSectionsTimeclocksComponent.prototype, "organizationId", {
        get: function () {
            if (!this.employeeShortInfo) {
                return 0;
            }
            return this.employeeShortInfo.organization ? this.employeeShortInfo.organization.id : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsTimeclocksComponent.prototype, "hasAgencyPrefix", {
        get: function () {
            return this.employeeShortInfo ? this.employeeShortInfo.isAgency : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsTimeclocksComponent.prototype, "agencyPrefix", {
        get: function () {
            return this.timeclockContainer ? this.timeclockContainer.agencyPrefix : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsTimeclocksComponent.prototype, "pin", {
        get: function () {
            return this.timeclockContainer.pinNumber.fieldValue;
        },
        set: function (value) {
            this.timeclockContainer.pinNumber.fieldValue = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsTimeclocksComponent.prototype, "badge", {
        get: function () {
            return this.timeclockContainer.badgeId.fieldValue.badge;
        },
        set: function (value) {
            this.timeclockContainer.badgeId.fieldValue.badge = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsTimeclocksComponent.prototype, "restriction", {
        get: function () {
            return this.timeclockContainer.restriction.fieldValue;
        },
        set: function (value) {
            this.timeclockContainer.restriction.fieldValue = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsTimeclocksComponent.prototype, "multiPositionPunch", {
        get: function () {
            return this.timeclockContainer ? this.timeclockContainer.multiPositionPunch : false;
        },
        set: function (value) {
            this.timeclockContainer.multiPositionPunch = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsTimeclocksComponent.prototype, "slateProfile", {
        get: function () {
            return this.timeclockContainer.slateProfile.fieldValue;
        },
        set: function (value) {
            this.timeclockContainer.slateProfile.fieldValue = value;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsTimeclocksComponent.prototype.generateBageId = function () {
        var _this = this;
        if (this.organizationId === 0) {
            throw new Error('organizationId not defined');
        }
        ConfirmDialogComponent.openDialog('Confirmation', 'Are you sure you want to generate a badge ID?', this.modalService, function (result) {
            if (result) {
                _this.badge = '';
                _this.employeeDefinitionApi.generateBadgeId(_this.organizationId)
                    .then(function (badgeId) {
                    _this.badge = badgeId;
                });
            }
        });
    };
    EmployeeSectionsTimeclocksComponent.prototype.resubmit = function () {
        var _this = this;
        this.startProgress();
        this.employeeSectionsEmploymentApiService.resubmitTimeclocks(this.employeeId)
            .then(function (value) {
            _this.modalService.globalAnchor.openInfoDialog('Re-Submit', 'Re-submission has started.');
            _this.loadSubsection();
            //this.stopProgress();
        })
            .catch(function (error) {
            _this.stopProgress();
        });
    };
    EmployeeSectionsTimeclocksComponent.prototype.selectAll = function () {
        var _this = this;
        if (this.readyTimeclocks) {
            _.forEach(this.readyTimeclocks, function (tc) {
                tc.assigned = _this.assignedAll;
                tc.restriction = tc.assigned ? _this.restriction : null;
            });
        }
        this.updateData();
    };
    EmployeeSectionsTimeclocksComponent.prototype.loadSubsection = function () {
        var _this = this;
        this.startProgress();
        this.readyTimeclocks = null;
        this.employeeSectionsEmploymentApiService.getEmployeeTimeclocks(this.employeeId)
            .then(function (value) {
            _this.employeeSectionTimeclockContainer = value;
            _this.stopProgress();
        })
            .catch(function () {
            _this.stopProgress();
        });
    };
    EmployeeSectionsTimeclocksComponent.prototype.doSave = function (effectiveDate) {
        var _this = this;
        this.timeclockContainer.records = this.readyTimeclocks;
        this.employeeSectionsEmploymentApiService.setEmployeeTimeclocks(this.employeeId, this.timeclockContainer, effectiveDate)
            .then(function (status) {
            _this.onActionComplete(true);
        })
            .catch(function (reason) {
            _this.onActionError(reason);
        });
    };
    EmployeeSectionsTimeclocksComponent.prototype.getTimeclocks = function (orgLevelId) {
        var _this = this;
        this.timeclocksApiService.getTimeclocksForEmlployee(orgLevelId, this.employeeId)
            .then(function (value) {
            _this.levelTimeclocks = value.records;
            _this.refreshGrid();
        })
            .catch(function (reason) {
            _this.lastLoadedOrgLevelId = 0;
            _this.levelTimeclocks = [];
        });
    };
    EmployeeSectionsTimeclocksComponent.prototype.getUniqTimeclock = function (item) {
        var tc = _.find(this.employeeSectionTimeclockContainer.records, function (searched) {
            return searched.assignmentRestriction.timeclock.id === item.id;
        });
        if (tc) {
            tc.assigned = true;
            this.mapSummaryDetails(tc);
            return tc;
        }
        tc = new EmployeeSectionTimeclock();
        var timeclock = new TimeclockDefinition();
        timeclock.id = item.id;
        timeclock.name = item.name;
        timeclock.clockOrganization = item.clockOrganization;
        timeclock.physicalId = item.physicalId;
        tc.timeclock = timeclock;
        tc.organization = item.organization;
        tc.department = item.department;
        this.mapSummaryDetails(tc);
        return tc;
    };
    EmployeeSectionsTimeclocksComponent.prototype.mapSummaryDetails = function (tc) {
        var summary = this.timeclockDailySummaryMap[tc.timeclock.id];
        if (summary) {
            tc.lastCommunicationDate = this.getFormattedDate(summary.lastCommunicationDate);
            tc.communicationStatus = summary.communicationStatus;
        }
    };
    EmployeeSectionsTimeclocksComponent.prototype.refreshGrid = function () {
        var _this = this;
        if (!this.employeeSectionTimeclockContainer || !this.levelTimeclocks || !this.timeclockDailySummaryMap) {
            this.gridView = null;
            return;
        }
        if (!this.readyTimeclocks) {
            this.readyTimeclocks = _.map(this.levelTimeclocks, function (item) { return _this.getUniqTimeclock(item); });
        }
        var sortedRecords = orderBy(this.readyTimeclocks, this.sort);
        var pagedRecords = sortedRecords.slice(this.skip, this.skip + this.pageSize);
        this.gridView = {
            data: pagedRecords,
            total: this.readyTimeclocks.length
        };
        this.updateData();
    };
    EmployeeSectionsTimeclocksComponent.prototype.editHandler = function (sender) {
        this.grid.editRow(sender.rowIndex);
    };
    EmployeeSectionsTimeclocksComponent.prototype.loadTimeclocksByOrgLevelId = function (orgLevelId) {
        if (this.lastLoadedOrgLevelId !== orgLevelId) {
            this.lastLoadedOrgLevelId = orgLevelId;
            this.levelTimeclocks = null;
            this.readyTimeclocks = null;
            this.getTimeclocks(orgLevelId);
        }
        else {
            this.refreshGrid();
        }
    };
    EmployeeSectionsTimeclocksComponent.prototype.updateData = function () {
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsTimeclocksComponent.prototype, "timeclockLoadedSubscription", void 0);
    return EmployeeSectionsTimeclocksComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsTimeclocksComponent };
