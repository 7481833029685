import { WeatherSettings, WeatherLayout, ForecastMode, TemperatureScale } from '../app-modules/angular-weather-widget/index';
export interface ICommonConfig {
  api: {
    root: string;
    entityMetadata: string;
    settings: string;
  };
  emailPattern: RegExp;
  phonePattern: RegExp;
  ipv4Pattern: RegExp;
  ssnPattern: RegExp;
  feinPattern: RegExp;
  timePattern: RegExp;
  datePattern: RegExp;
  numberPattern: RegExp;
  durationPattern: RegExp;
  notEmptyPattern: RegExp;
  notLeadZeroes: RegExp;
  alphanumericPattern: RegExp;
  passwordPattern: RegExp;
  pbjIdPattern: RegExp;
  dialogs: {
    confirmDialog: {
      width: number;
      height: number;
    };
  };
  weatherWidgetSettings: WeatherSettings;
  acaReceiptIdPattern: RegExp;
  deductionCodePattern: RegExp;
}
export const commonConfig: ICommonConfig = {
  api: {
    root: 'commonCore',
    entityMetadata: 'entityMetadata',
    settings: 'settings'
  },
  emailPattern: /^[_a-zA-Z`'0-9-]+(\.[_a-zA-Z`'0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-z]{2,})$/,
  phonePattern: /\([0-9]{3}\)[ ][0-9]{3}[-][0-9]{4}/,
  ipv4Pattern: /^\d+\.\d+\.\d+\.\d+$/,
  ssnPattern: /\d{3}-\d{2}-\d{4}/,
  feinPattern: /^(?:\d{2}-\d{7}|\d{9})$/,
  timePattern: /^(0[0-9]|1[0-2]):[0-5][0-9]\s*?([aApP][mM]){1}$/,
  // tslint:disable-next-line:max-line-length
  datePattern: /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
  numberPattern: /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/,
  durationPattern: /^(0[0-9]|1[0-2]):[0-5][0-9]$/,
  notEmptyPattern: /^(?!\s*$).+/,
  notLeadZeroes: /^[1-9]\d*$/,
  alphanumericPattern: /^[a-z0-9]+$/i,
  passwordPattern: /^(?=.*[^a-zA-Z])(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])\S+$/,
  pbjIdPattern: /^[A-Za-z0-9\-\\\,\.\_]+$/,
  dialogs: {
    confirmDialog: {
      width: 300,
      height: 200,
    },
  },
  weatherWidgetSettings: {
    location: {
        latLng: {
          lat: 0,
          lng: 0
        }
      },
    scale: TemperatureScale.FAHRENHEIT,
    showWind: false,
    showDetails: true,
    showForecast: false,
    forecastMode: ForecastMode.DETAILED,
    language: 'en',
    layout: WeatherLayout.WIDE
  },
  acaReceiptIdPattern: /[1][0][9][4|5][C|c][-][0-9]{2}[-][0-9]{8}/,
  deductionCodePattern: /^[^\^\\]*$/
};

