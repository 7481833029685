import { IMenuItemMapping, IApplicationStyleMap } from './models/index';

export interface IOrganizationConfig {
  apiroot: string;
  api: {
    orglevels: string;
    getlastSelected: string;
    putlastSelected: string;
    menu: string;
    employee: {
      root: string;
      photo: string;
      thumbnails: string;
      generateBadges: string;
    }
    employees: {
      root: string;
      shortInfo: string;
      validation: {
        root: string;
        ssn: string;
        payrollNumber: string;
        names: string;
        badgeId: string;
        pbjId: string;
        email: string;
      },
      actions: {
        root: string,
        generatePassword: string,
        generateBadgeId: string,
        getNextPayrollNumber: string,
      },
      benefitsManagement: {
        root: string;
        benefitClass: {
          root: string;
          canEdit: string;
        }
      },
      optin: {
        root: string;
        email: string;
        sms: string;
        template: string;
      };
    }
    user: {
      root: string,
      actions: string,
    }
    applications: {
      root: string;
      components: {
        root: string;
        accessible: string;
        orglevel: string;
        employee: string;
      }
    }
    pbj: {
      root: string;
      id: string;
    }
    payroll: {
      root: string;
      ta: string;
      submitted: string;
      exports: string;
    }
    gethired: {
      root: string;
      link: string;
    }
    benadmin: {
      root: string;
      link: string;
    }
    accruals: {
      root: string,
      policy: {
        root: string,
        all: string,
        massPolicyAssignment: string
      }
    }
    app: {
      root: string;
      settings: {
        root: string;
        accruals: {
          root: string;
          allowDynamicAccrualPolicies: string
        }
      }
    }
  };
  lookup: {
    root: string;
    employeeType: string;
    state: string;
    budgetedPosition: string;
    licenseType: string;
    budgetedGroups: string;
    position: string;
    effectivePositions: string;
    positionGroups: string;
    shiftdiffpolicy: string;
    paypolicy: string;
    accrualpolicy: string;
    acuities: string;
    documentsCategories: string;
    costCenters: string;
    exempt: string;
    department: string;
    companies: string;
    organization: string;
    userAccesibleOrganizations: string;
    userAccesibleOrganizationsCrossCorpActionCheck: string;
    userAccessibleDepartments: string;
    shift: string;
    shiftGroup: string;
    constraint: string;
    unit: string;
    attendancePointsDefinitions: string;
    race: string;
    maritalStatus: string;
    gender: string;
    scheduleAbsences: string;
    scheduleCycles: string;
    [key: string]: string;
    searchCategories: string;
    employeeTerminationReasons: string;
    budgets: string;
    pbjPosition: string;
    pbjPositions: string;
    availabilityDateRanges: string;
    timeClocks: string;
    timeclockRestrictions: string;
    timeclockModels: string;
    timezones: string;
    paycycles: string;
    payrules: string;
    punchtypes: string;
    taAbsenceCodes: string;
    employees: string;
    employeesBySecondaryPositions: string,
    employeesByActiveDateRange: string,
    manualEntryActiveEmployees: string,
    agencyDefinitions: string;
    timecardPredefinedComments: string;
    essOptions: string;
    benefitClasses: string;
    veteranStatuses: string;
    establishmentTypes: string;
    performanceReviewCodes: string;
    performanceReviewTemplates: string;
    performanceReviewCodeTypes: string;
    benefitGroups: string;
    getObjAcc: string;
    acaCategory: string;
    acaEmployers: string;
    acaSafeHarbor:string;
    acaReason:string;
    acaOfferOfCoverage:string;
    agencyDefinitionsReports: string;
    payunits: string;
    timeclockSlateProfile: string;
    increaseReason: string;
    supervisors: string;
    attestationType:string;
    restrictedAnswer:string;
    punchProfiles:string;
    punchAttestationGroups:string;
    restrictedNotify:string;
    organizationTimezones:string;
  };
  domain: {
    emptyId: number;
  };
  applications: IApplicationStyleMap[];
  unknownApplication: IApplicationStyleMap;
  moreApplication: IApplicationStyleMap;
  navigationMenu: {
    menuItemsMapping: StringMap<IMenuItemMapping>;
  };
}

export const organizationConfig: IOrganizationConfig = {
  apiroot: 'applications',
  api: {
    orglevels: 'orglevels',
    getlastSelected: 'orglevels/selected',
    putlastSelected: 'orglevels/selected',
    menu: 'menu',
    employee: {
      root: 'employee',
      photo: 'photo',
      thumbnails: 'thumbnails',
      generateBadges: 'generateBadges'
    },
    employees: {
      root: 'employees',
      shortInfo: 'shortInfo',
      validation: {
        root: 'validation',
        ssn: 'ssn',
        payrollNumber: 'payrollNumber',
        names: 'names',
        badgeId: 'badgeId',
        pbjId: 'pbjId',
        email: 'email'
      },
      actions: {
        root: 'actions',
        generatePassword: 'generatePassword',
        generateBadgeId: 'generateBadgeId',
        getNextPayrollNumber: 'getNextPayrollNumber'
      },
      benefitsManagement: {
        root: 'benefitsManagement',
        benefitClass: {
          root: 'benefitClass',
          canEdit: 'canEdit'
        }
      },
      optin: {
        root: 'optin',
        email: 'email',
        sms: 'sms',
        template: 'template'
      }
    },
    user: {
      root: 'user',
      actions: 'actions'
    },
    applications: {
      root: 'applications',
      components: {
        root: 'components',
        accessible: 'accessible',
        orglevel: 'orglevel',
        employee: 'employee'
      }
    },
    pbj: {
      root: 'pbj',
      id: 'id'
    },
    payroll: {
      root: 'payroll',
      ta: 'ta',
      submitted: 'submitted',
      exports: 'exports'
    },
    gethired: {
      root: 'gethired',
      link: 'link'
    },
    benadmin: {
      root: 'benadmin',
      link: 'link'
    },
    accruals:{
      root: 'accruals',
      policy: {
        root: 'policy',
        all: 'short/all',
        massPolicyAssignment: 'assign'
      }
    },
    app: {
      root: 'app',
      settings: {
        root: 'settings',
        accruals: {
          root: 'accruals',
          allowDynamicAccrualPolicies: 'allowDynamicAccrualPolicies'
        }
      }
    }
  },
  lookup: {
    root: 'lookup',
    employeeType: 'empType',
    state: 'state',
    budgetedPosition: 'budgetedPosition',
    licenseType: 'licenseType',
    budgetedGroups: 'budgetedGroups',
    position: 'position',
    effectivePositions: 'effective',
    positionGroups: 'positionGroups',
    shiftdiffpolicy: 'shiftdiffpolicy',
    paypolicy: 'paypolicy',
    accrualpolicy: 'accrualpolicy',
    acuities: 'acuities',
    documentsCategories: 'documents/categories',
    costCenters: 'costCenters',
    exempt: 'exempt',
    department: 'department',
    companies: 'companies',
    organization: 'organization',
    userAccesibleOrganizations: 'userAccesibleOrganizations',
    userAccesibleOrganizationsCrossCorpActionCheck: 'userAccesibleOrganizationsCrossCorpActionCheck',
    userAccessibleDepartments: 'userAccessibleDepartments',
    shift: 'shift',
    shiftGroup: 'shiftGroup',
    constraint: 'constraint',
    unit: 'unit',
    attendancePointsDefinitions: 'attendancePointsDefinitions',
    race: 'race',
    maritalStatus: 'maritalStatus',
    gender: 'gender',
    scheduleAbsences: 'scheduleAbsences',
    scheduleCycles: 'scheduleCycles',
    searchCategories: 'employeeSearchCategories',
    employeeTerminationReasons: 'employeeTerminationReasons',
    budgets: 'budgets',
    availabilityDateRanges: 'availabilityRanges',
    pbjPosition: 'pbjPosition',
    pbjPositions: 'pbjPositions',
    timeClocks: 'timeClocks',
    timeclockRestrictions: 'timeclockRestrictions',
    timeclockModels: 'timeclockModels',
    timezones: 'timeclockTimezones',
    paycycles: 'paycycles',
    payrules: 'payrules',
    punchtypes: 'punchtypes',
    taAbsenceCodes: 'taAbsenceCodes',
    employees: 'employees',
    employeesBySecondaryPositions: 'employeesBySecondaryPositions',
    employeesByActiveDateRange: 'employeesByActiveDateRange',
    manualEntryActiveEmployees: 'manualEntryActiveEmployees',
    agencyDefinitions: 'agencyDefinitions',
    timecardPredefinedComments: 'timecardPredefinedComments',
    essOptions: 'essOptions',
    benefitClasses: 'benefitClasses',
    codeTypes : 'codeTypes',
    veteranStatuses: 'veteranStatuses',
    establishmentTypes: 'establishmentTypes',
    performanceReviewCodes: 'performanceReviewCodes',
    performanceReviewTemplates: 'performanceReviewTemplates',
    performanceReviewCodeTypes: 'performanceReviewCodeTypes',
    benefitGroups: 'benefitsManagement/benefitGroups',
    getObjAcc: 'getObjAcc',
    acaCategory: 'acacategory',
    acaEmployers: 'aca/employers',
    acaSafeHarbor:'safeHarbor',
    acaReason:'reason',
    acaOfferOfCoverage:'offerOfCoverage',
    agencyDefinitionsReports: 'agencyDefinitionsReports',
    payunits: 'payunits',
    timeclockSlateProfile:'timeclockSlateProfile',
    increaseReason: 'increaseReason',
    supervisors:'supervisors',
    attestationType : 'attestationType',
    restrictedAnswer:'restrictedAnswer'    ,
    punchProfiles : 'punchProfiles',
    punchAttestationGroups : 'punchAttestationGroups',
    restrictedNotify : 'restrictedNotify',
    organizationTimezones:'organizationTimezones'
  },
  domain: {
    emptyId: 0
  },
  applications: [
    { id: 3, icon: 'fa-calendar-alt', loadCounters: true, index: 0 },
    { id: 4, icon: 'fa-calendar-check', loadCounters: true, index: 0 },
    { id: 2, icon: 'fa-list-alt', loadCounters: false, index: 0 },
    { id: 6, icon: 'fa-users', loadCounters: false, index: 0 },
    { id: 7, icon: 'fa-check-square', loadCounters: false, index: 0 },
    { id: 5, icon: 'fa-chart-bar', loadCounters: false, index: 0 },
    { id: 1, icon: 'fa-cogs', loadCounters: false, index: 0 },
    { id: 8, icon: 'fa-chart-line', loadCounters: false, index: 1 },
    { id: 10, icon: 'fa-suitcase-rolling', loadCounters: false, index: 0 },
    { id: 11, icon: 'fa-university', loadCounters: false, index: 0 },
    { id: 12, icon: 'fa-university', loadCounters: false, index: 0 },
    { id: 14, icon: 'spotlight-icon', loadCounters: false, index: 0 },
    { id: 100, icon: 'fa-ellipsis-h', loadCounters: false, index: 2 },
    { id: 96, icon: 'fa-umbrella', loadCounters: false, index: 0 },
    { id: 9, icon: 'fas fa-balance-scale', loadCounters: false, index: 0 },
    { id: 16, icon: 'fas fa-badge-dollar', loadCounters: false, index: 0 }
  ],
  unknownApplication: { id: 0, icon: 'fa-question-circle', loadCounters: false, index: 0 },
  moreApplication: { id: 0, icon: 'fas fa-th', loadCounters: false, index: 0 },

  navigationMenu: {
    menuItemsMapping: {
      'Default': {
        name: 'Default',
        icon: 'fa fa-folder',
        url: 'scheduler',
        isV5App: false,
      },
      // groups
      'Dashboards': {
        name: 'Dashboards',
        icon: 'far fa-chart-bar',
        url: '',
        isV5App: false,
      },
      'Punches': {
        name: 'Punches',
        icon: 'fas fa-hand-paper',
        url: '',
        isV5App: false,
      },
      'Review Timecards': {
        name: 'Review Timecards',
        icon: 'fas fa-id-card',
        url: '',
        isV5App: false,
      },
      'Approvals': {
        name: 'Approvals',
        icon: 'fas fa-calendar-check',
        url: '',
        isV5App: false,
      },
      'NPayroll': {
        name: 'Payroll',
        icon: 'fas fa-calendar-check',
        url: '',
        isV5App: true,
      },
      'Payroll Based Journal': {
        name: 'Payroll Based Journal',
        icon: 'far fa-money-bill',
        url: '',
        isV5App: false,
      },
      'Leave Requests': {
        name: 'Leave Requests',
        icon: 'far fa-calendar-times',
        url: '',
        isV5App: false,
      },
      'Agency': {
        name: 'Agency',
        icon: 'far fa-building',
        url: '',
        isV5App: false,
      },
      'Schedule': {
        name: 'Schedule',
        icon: 'fas fa-calendar-alt',
        url: '',
        isV5App: false,
      },
      'Agency Staffing': {
        name: '',
        icon: 'fas fa-user-nurse',
        url: '',
        isV5App: false,
      },
      'Employees': {
        name: 'Employees',
        icon: 'fa fa-id-card',
        url: '',
        isV5App: false,
      },
      'Configuration': {
        name: 'Configuration',
        icon: 'fa fa-cogs',
        url: '',
        isV5App: false,
      },
      'Units': {
        name: 'Units',
        icon: 'fal fa-building',
        url: '',
        isV5App: false,
      },
      'Ideal Schedule': {
        name: 'Ideal Schedule',
        icon: 'fal fa-calendar-alt',
        url: '',
        isV5App: false,
      },
      'Absences': {
        name: 'Absences',
        icon: 'fal fa-calendar-minus',
        url: '',
        isV5App: false,
      },
      'Shifts': {
        name: 'Shifts',
        icon: 'fal fa-calendar-edit',
        url: '',
        isV5App: false,
      },
      'Positions': {
        name: 'Positions',
        icon: 'fal fa-clipboard-list',
        url: '',
        isV5App: false,
      },
      'Employers': {
        name: 'Employers',
        icon: 'fas fa-balance-scale',
        url: '',
        isV5App: false,
      },
      'Constraints': {
        name: 'Constraints',
        icon: 'fas fa-ban',
        url: '',
        isV5App: false,
      },
      'Cost Centers': {
        name: 'Cost Centers',
        icon: 'fas fas fa-usd-square',
        url: '',
        isV5App: false,
      },
      'Budget Management': {
        name: 'Budget Management',
        icon: 'fal fa-money-bill',
        url: '',
        isV5App: false,
      },
      'Attendance Points': {
        name: 'Attendance Points',
        icon: 'fal fa-trophy-alt',
        url: '',
        isV5App: false,
      },
      'Holidays': {
        name: 'Holidays',
        icon: 'fal fa-suitcase',
        url: '',
        isV5App: false,
      },
      'Audit Trail': {
        name: 'Audit Trail',
        icon: 'fa-list-alt',
        url: '',
        isV5App: false,
      },
      //items
      'Console': {
        name: 'Console',
        icon: 'fa fa-home',
        url: '',
        isV5App: false,
      },
      'Manage Schedule': {
        name: 'Manage Schedule',
        icon: 'fa fa-folder',
        url: '',
        isV5App: false,
      },
      'Employee Communication': {
        name: 'Employee Communication',
        icon: 'far fa-futbol',
        url: 'scheduler',
        isV5App: false,
      },
      'Ad-hoc Queries': {
        name: 'Ad-hoc Queries',
        icon: 'fa fa-folder',
        url: 'scheduler',
        isV5App: false,
      },
      'Employee List': {
        name: 'Employee List',
        icon: 'fa fa-id-card',
        url: 'scheduler',
        isV5App: false,
      },
      'API Employee Sync': {
        name: 'Employee Sync',
        icon: 'fa fa-calendar-o',
        url: 'scheduler',
        isV5App: false,
      },
      'Daily Transaction Report': {
        name: 'Daily Transaction Report',
        icon: 'fa fa-calendar-o',
        url: 'scheduler',
        isV5App: false,
      },

      'Scheduling Console': {
        name: 'Scheduling Console',
        icon: 'fa fa-folder',
        url: 'scheduler',
        isV5App: false,
      },
      'Projected Hrs': {
        name: 'Projected Hrs',
        icon: 'fa fa-folder',
        url: 'hr',
        isV5App: false,
      },
      'Direct Care Hours': {
        name: 'Direct Care Hours',
        icon: 'far fa-building',
        url: 'scheduler',
        isV5App: false,
      },
      'Search': {
        name: 'Search',
        icon: 'far fa-search',
        url: 'search',
        isV5App: false,
      },
      'Clocks': {
        name: 'Clocks',
        icon: 'far fa-clock',
        url: 'clocks',
        isV5App: false,
      },
      'Add Employee': {
        name: 'Add Employee',
        icon: 'far fa-user-plus',
        url: 'add_employee',
        isV5App: false,
      },
      'Users': {
        name: 'Users',
        icon: 'fal fa-user-circle',
        url: '',
        isV5App: false,
      },
      'User Roles': {
        name: 'User Roles',
        icon: 'fal fa-users',
        url: '',
        isV5App: false,
      },
      'Admin': {
        name: 'Admin',
        icon: 'fas fa-building',
        url: '',
        isV5App: false,
      },
      'Manager': {
        name: 'Manager',
        icon: 'fas fa-clipboard-list',
        url: '',
        isV5App: false,
      },
      'HR': {
        name: 'HR',
        icon: 'fas fa-clipboard-check',
        url: '',
        isV5App: false,
      },
      'Payroll': {
        name: 'Payroll',
        icon: 'fas fa-money-check-alt',
        url:  'payroll',
        isV5App: true,
      },
      'Payroll Export Management': {
        name: 'Payroll Export Management',
        icon: 'fal fa-money-check',
        url: '',
        isV5App: false,
      },
      'Scheduler': {
        name: 'Scheduler',
        icon: 'fas fa-calendar-check',
        url: '',
        isV5App: false,
      },
      'More apps...': {
        name: 'More apps...',
        icon: 'fas fa-th',
        url: '',
        isV5App: true,
      },
      'HRMS': {
        name: 'HRMS',
        icon: 'fas fa-users',
        url: 'hrms',
        isV5App: true,
      },
      'Leave Management': {
        name: 'Leave Management',
        icon: 'far fa-calendar-minus',
        url: '',
        isV5App: false,
      },
      'Workers\' Compensation': {
        name: 'Workers\' Compensation',
        icon: 'fas fa-notes-medical',
        url: '',
        isV5App: false,
      },
      'Performance Management': {
        name: 'Performance Management',
        icon: 'far fa-bullseye-arrow',
        url: '',
        isV5App: false,
      },
      'Benefits Management': {
        name: 'Benefits Management',
        icon: 'fas fa-user-shield',
        url: '',
        isV5App: false,
      },
      'Attest': {
        name: 'Attest',
        icon: 'far fa-check-square',
        url: 'attest',
        isV5App: true,
      },
      'Business Analytics': {
        name: 'Business Analytics',
        icon: 'fas fa-chart-bar',
        url: 'business_analytics',
        isV5App: true,
      },
      'Directory': {
        name: 'Directory',
        icon: 'fas fa-book',
        url: 'directory',
        isV5App: true,
      },
      'ACA Director': {
        name: 'ACA Director',
        icon: 'fas fa-university',
        url: 'aca_director',
        isV5App: true,
      },
      'Measurement Details': {
        name: 'Measurement Details',
        icon: 'fas fa-stream',
        url: 'measurement_details',
        isV5App: false,
      },
      '1095-C/1094-C Reporting': {
        name: 'Ten Ninty Five C',
        icon: 'fas fa-file-spreadsheet',
        url: '',
        isV5App: false,
      },
      'ACA Exports': {
        name: 'ACA Exports',
        icon: 'fas fa-file-export',
        url: 'aca_exports',
        isV5App: false,
      },
      '1095-C Audit': {
        name: 'Aca C1095 Audit',
        icon: 'fas fa-list-alt',
        url: '',
        isV5App: false,
      },


      'Accruals': {
        name: 'Accruals',
        icon: 'fa-suitcase-rolling',
        url: 'accruals',
        isV5App: true,
      },
      'Accrual Balances': {
        name: 'Accrual Balances',
        icon: 'fal fa-file-spreadsheet',
        url: '',
        isV5App: false,
      },
      'Accrual Transactions': {
        name: 'Accrual Transactions',
        icon: 'fas fa-file-invoice',
        url: '',
        isV5App: false,
      },
      'Accrual Policy Definitions': {
        name: 'Accrual Policy Definitions',
        icon: 'fas fa-book',
        url: '',
        isV5App: false,
      },
      'Self Service': {
        name: 'Self Service',
        icon: 'fas fa-hand-pointer',
        url: '',
        isV5App: false,
      },
      'Five-Star Predictor': {
        name: 'Five-Star Predictor',
        icon: 'fas fa-star-half-alt',
        url: '',
        isV5App: false,
      },
      'Create New': {
        name: 'Create New',
        icon: 'fa fa-folder',
        url: '',
        isV5App: false,
      },
      'Standard Reports': {
        name: 'Standard Reports',
        icon: 'fas fa-file',
        url: '',
        isV5App: false,
      },
      'Ad-Hoc Reports': {
        name: 'Ad-Hoc Reports',
        icon: 'fas fa-file-alt',
        url: '',
        isV5App: false,
      },
      'Applicant Tracker': {
        name: 'Applicant Tracker',
        icon: 'applicant-tracker-icon',
        url: '',
        isV5App: false,
      },
      'Telepunch Locations': {
        name: 'Telepunch Locations',
        icon: 'fas fa-phone-square',
        url: '',
        isV5App: false,
      },
      'Benefits': {
        name: 'Benefits',
        icon: 'fas fa-umbrella',
        url: '',
        isV5App: false,
      },
      'Go User Templates': {
        name: 'Go User Templates',
        icon: 'fas fa-users-class',
        url: '',
        isV5App: false,
      },
      'Geolocation': {
        name: 'Geolocation',
        icon: 'fas fa-map-marked-alt',
        url: '',
        isV5App: false,
      },
      'Leave Types': {
        name: 'Leave Types',
        icon: 'far fa-calendar',
        url: '',
        isV5App: false,
      },
      'Benefit Class': {
        name: 'Benefit Class',
        icon: 'fas fa-umbrella',
        url: '',
        isV5App: false,
      },
      'Data Exports': {
        name: 'Data Exports',
        icon: 'fas fa-file-export',
        url: '',
        isV5App: false,
      },
      'Employee Audit': {
        name: 'Employee Audit',
        icon: 'fas fa-clipboard-user',
        url: '',
        isV5App: false,
      },
      'Config Audit': {
        name: 'Config Audit',
        icon: 'fas fa-ballot-check',
        url: '',
        isV5App: false,
      },
      'IP Management': {
        name: 'IP Management',
        icon: 'fas fa-tv',
        url: '',
        isV5App: false,
      },
      'User Permissions': {
        name: 'User Roles',
        icon: 'fal fa-users',
        url: '',
        isV5App: false,
      },
      'General': {
        name: 'General',
        icon: 'fas fa-sliders-v-square',
        url: '',
        isV5App: false,
      },
      'Time & Attendance': {
        name: 'Time & Attendance',
        icon: 'fa fa-calendar-check',
        url: '',
        isV5App: false,
      },
      'ACA': {
        name: 'ACA',
        icon: 'fas fa-balance-scale',
        url: '',
        isV5App: false,
      },
      'Message Center': {
        name: 'Message Center',
        icon: 'fas fa-envelope',
        url: '',
        isV5App: false,
      },
      'SMS': {
        name: 'SMS',
        icon: 'fas fa-envelope',
        url: '',
        isV5App: false,
      },
      'Staffing': {
        name: 'Staffing',
        icon: 'fas fa-user-nurse',
        url: '',
        isV5App: false,
      },
      'WFM': {
        name: 'WFM',
        icon: 'fas fa-users',
        url: '',
        isV5App: false,
      },
      'Dashboard': {
        name: 'Dashboard',
        icon: 'far fa-user-chart',
        url: '',
        isV5App: false,
      },
      'Sync': {
        name: 'Sync',
        icon: 'far fa-sync',
        url: '',
        isV5App: false,
      },
      'Code Mapping': {
        name: 'Code Mapping',
        icon: 'fas fa-money-check-alt',
        url: '',
        isV5App: false,
      }
    }
  }
};
