import { Subscription } from 'rxjs/Subscription';
import { TimeclockDailySummaryContainer } from './../../../../../organization/models/time-clock/time-clock-daily-summary-container';
import { TimeclockDataService } from './../../../../../organization/services/time-clock/time-clock-data.service';
import { ConfirmDialogComponent } from './../../../../../common/components/confirm-dialog/confirm-dialog.component';
import { Component, OnInit, OnDestroy, NgZone, Host, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NgForm, AbstractControl } from '@angular/forms';
import { EmployeeSectionsEmploymentApiService } from '../../../services/index';
import * as _ from 'lodash';
import { EmployeeSectionTimeclock, EmployeeSectionTimeclockContainer, EmployeeSectionsBase } from '../../../models/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';

import { GridDataResult } from '@progress/kendo-angular-grid';
import { Assert } from '../../../../../framework/index';
import { TimeclockRestrictionDefinition, TimeclockDefinition, TimeclockSlateProfileDefinition } from '../../../../../organization/models/lookup/index';
import { TimeclocksApiService } from '../../../../../configuration/services/index';
import { TimeclockContainer, Timeclock } from '../../../../../configuration/models/index';
import {
  GridComponent,
  PageChangeEvent
} from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { EmployeeValidatorAdapter } from '../../../../../organization/services/index';
import { ModalService } from '../../../../../common/services/modal/modal.service';
import { EmployeeDefinitionsApiService, OrgLevelWatchService } from '../../../../../organization/services/index';
import { ChangeDetectionStrategy } from '@angular/core';
import { appConfig, IApplicationConfig } from '../../../../../../app/app.config';
import * as moment from 'moment';
import { unsubscribe } from '../../../../../core/decorators';
import { TimeclockDailySummary } from '../../../../../organization';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-timeclocks',
  templateUrl: 'employee-sections-timeclocks.component.html',
  styleUrls: ['employee-sections-timeclocks.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class EmployeeSectionsTimeclocksComponent extends EmployeeSectionsBasicComponent implements OnInit, OnDestroy {
  public lastCommunicationDate: string;
  @Input()
  public employeeId: number;
  @Input()
  public set employeeSectionTimeclockContainer(value: EmployeeSectionTimeclockContainer) {
    this.timeclockContainer = value;
    if (this.timeclockContainer && this.timeclockContainer.homeOrgLevelId !== 0) {
      this.loadTimeclocksByOrgLevelId(this.timeclockContainer.homeOrgLevelId);
    }
    this.updateData();
  }

  public get badgeIdValidation(): string {
    return EmployeeValidatorAdapter.badgeIdValidation;
  }

  @ViewChild('form', { static: false })
  public ngForm: NgForm;
  public gridView: GridDataResult;
  public sort: SortDescriptor[] = [];
  public skip: number = 0;
  public pageSize: number = 10;
  public assignedAll: boolean = false;

  @ViewChild('kendoGrid', { static: false })
  private grid: GridComponent;
  private timeclockContainer: EmployeeSectionTimeclockContainer;
  private readyTimeclocks: EmployeeSectionTimeclock[];
  private isEditMode: boolean;
  private levelTimeclocks: Timeclock[];
  private lastLoadedOrgLevelId: number = 0;
  private orgelvelTreeHasLoaded: boolean = false;

  public appConfig: IApplicationConfig;
  @unsubscribe()
  private timeclockLoadedSubscription: Subscription;
  private timeclockDailySummaryMap: _.Dictionary<TimeclockDailySummary>;

  constructor(
    private employeeSectionsEmploymentApiService: EmployeeSectionsEmploymentApiService,
    @Host() decorator: EmployeeSubSectionsDecoratorComponent,
    ngZone: NgZone,
    private timeclocksApiService: TimeclocksApiService,
    public employeeValidatorAdapter: EmployeeValidatorAdapter,
    private modalService: ModalService,
    private employeeDefinitionApi: EmployeeDefinitionsApiService,
    private changeDetector: ChangeDetectorRef,
    private orgLevelWatchService: OrgLevelWatchService,
    private timeclockDataService: TimeclockDataService) {
    super(decorator, ngZone);

  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.timeclockLoadedSubscription = this.timeclockDataService.onLoaded$
        .subscribe((timeclocksContainer: TimeclockDailySummaryContainer) => {
            this.timeclockDailySummaryMap = _.keyBy(timeclocksContainer.records, 'id');
            this.refreshGrid();
        });
    this.timeclockDataService.loadTimeclocks();
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.timeclockContainer;
  }

  public getFormattedDate(date: Date) {
    if (date != null) {
      return moment(date).format(appConfig.militaryDateTimeFormat);
    } else {
      return '';
    }
  }

  public get employeeSectionTimeclockContainer(): EmployeeSectionTimeclockContainer {
    return this.timeclockContainer;
  }

  public get form(): AbstractControl {
    return this.ngForm ? this.ngForm.form : null;
  }

  public setEditState(editState: boolean): void {
    let len: number = this.gridView ? this.gridView.total : 0;
    if (editState) {
      for (let i: number = 0; i < len; i++)
        this.grid.editRow(i);
    } else {
      for (let i: number = 0; i < len; i++)
        this.grid.closeRow(i);
    }
    this.state.isEditMode = editState;
  }

  public onPageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.refreshGrid();
  }

  public onSortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.refreshGrid();
  }

  public onRestrictionChanged(restriction: TimeclockRestrictionDefinition): void {
    _.map(this.readyTimeclocks, (item: EmployeeSectionTimeclock) => {
      if (item.assigned) {
        item.restriction = restriction;
      }
    });
    this.updateData();
  }

  public onItemRestrictionChanged(item: EmployeeSectionTimeclock, assigned: boolean): void {
    if (assigned) {
      item.restriction = this.restriction;
    } else {
      item.restriction = null;
    }
  }

  public get organizationId(): number {
    if (!this.employeeShortInfo) {
      return 0;
    }
    return this.employeeShortInfo.organization ? this.employeeShortInfo.organization.id : 0;
  }

  public get hasAgencyPrefix(): boolean {
    return this.employeeShortInfo ? this.employeeShortInfo.isAgency : false;
  }

  public get agencyPrefix(): string {
    return this.timeclockContainer ? this.timeclockContainer.agencyPrefix : '';
  }

  public get pin(): string {
    return this.timeclockContainer.pinNumber.fieldValue;
  }

  public set pin(value: string) {
    this.timeclockContainer.pinNumber.fieldValue = value;
  }

  public get badge(): string {
    return this.timeclockContainer.badgeId.fieldValue.badge;
  }

  public set badge(value: string) {
    this.timeclockContainer.badgeId.fieldValue.badge = value;
  }

  public get restriction(): TimeclockRestrictionDefinition {
    return this.timeclockContainer.restriction.fieldValue;
  }

  public set restriction(value: TimeclockRestrictionDefinition) {
    this.timeclockContainer.restriction.fieldValue = value;
  }

  public get multiPositionPunch(): boolean {
    return this.timeclockContainer ? this.timeclockContainer.multiPositionPunch : false;
  }

  public set multiPositionPunch(value: boolean) {
    this.timeclockContainer.multiPositionPunch = value;
  }

  public get slateProfile(): TimeclockSlateProfileDefinition {
    return this.timeclockContainer.slateProfile.fieldValue;
  }

  public set slateProfile(value: TimeclockSlateProfileDefinition) {
    this.timeclockContainer.slateProfile.fieldValue = value;
  }

  public generateBageId(): void {
    if (this.organizationId === 0) {
      throw new Error('organizationId not defined');
    }
    ConfirmDialogComponent.openDialog('Confirmation', 'Are you sure you want to generate a badge ID?', this.modalService, (result: boolean) => {
      if (result) {
        this.badge = '';
        this.employeeDefinitionApi.generateBadgeId(this.organizationId)
          .then((badgeId: string) => {
            this.badge = badgeId;
          });
      }
    }
    );
  }

  public resubmit(): void {
    this.startProgress();
    this.employeeSectionsEmploymentApiService.resubmitTimeclocks(this.employeeId)
      .then((value: any) => {
        this.modalService.globalAnchor.openInfoDialog('Re-Submit', 'Re-submission has started.');
        this.loadSubsection();
        //this.stopProgress();
      })
      .catch((error: any) => {
        this.stopProgress();
      });
  }

  public selectAll(): void {
    if (this.readyTimeclocks) {
      _.forEach(this.readyTimeclocks, (tc: EmployeeSectionTimeclock) => {
        tc.assigned = this.assignedAll;
        tc.restriction = tc.assigned ? this.restriction : null;
      });
    }
    this.updateData();
  }

  protected loadSubsection(): void {
    this.startProgress();
    this.readyTimeclocks = null;
    this.employeeSectionsEmploymentApiService.getEmployeeTimeclocks(this.employeeId)
      .then((value: EmployeeSectionTimeclockContainer) => {
        this.employeeSectionTimeclockContainer = value;
        this.stopProgress();
      })
      .catch(() => {
        this.stopProgress();
      });
  }

  protected doSave(effectiveDate: Date): void {
    this.timeclockContainer.records = this.readyTimeclocks;
    this.employeeSectionsEmploymentApiService.setEmployeeTimeclocks(this.employeeId, this.timeclockContainer, effectiveDate)
      .then((status: any) => {
        this.onActionComplete(true);
      })
      .catch((reason: any) => {
        this.onActionError(reason);
      });
  }

  private getTimeclocks(orgLevelId: number): void {
    this.timeclocksApiService.getTimeclocksForEmlployee(orgLevelId, this.employeeId)
      .then((value: TimeclockContainer) => {
        this.levelTimeclocks = value.records;
        this.refreshGrid();
      })
      .catch((reason: any) => {
        this.lastLoadedOrgLevelId = 0;
        this.levelTimeclocks = [];
      });
  }

  private getUniqTimeclock(item: Timeclock): EmployeeSectionTimeclock {
    let tc: EmployeeSectionTimeclock = _.find(this.employeeSectionTimeclockContainer.records, function (searched: EmployeeSectionTimeclock): boolean {
      return searched.assignmentRestriction.timeclock.id === item.id;
    });
    if (tc) {
      tc.assigned = true;
      this.mapSummaryDetails(tc);
      return tc;
    }
    tc = new EmployeeSectionTimeclock();
    let timeclock: TimeclockDefinition = new TimeclockDefinition();
    timeclock.id = item.id;
    timeclock.name = item.name;
    timeclock.clockOrganization = item.clockOrganization;
    timeclock.physicalId = item.physicalId;
    tc.timeclock = timeclock;
    tc.organization = item.organization;
    tc.department = item.department;
    this.mapSummaryDetails(tc);
    return tc;
  }

  private mapSummaryDetails(tc: EmployeeSectionTimeclock): void {
    const summary: TimeclockDailySummary = this.timeclockDailySummaryMap[tc.timeclock.id];
    if (summary) {
      tc.lastCommunicationDate = this.getFormattedDate(summary.lastCommunicationDate);
      tc.communicationStatus = summary.communicationStatus;
    }
  }

  private refreshGrid(): void {
    if (!this.employeeSectionTimeclockContainer || !this.levelTimeclocks || !this.timeclockDailySummaryMap) {
      this.gridView = null;
      return;
    }
    if (!this.readyTimeclocks) {
      this.readyTimeclocks = _.map(this.levelTimeclocks, (item: Timeclock) => this.getUniqTimeclock(item));
    }

    let sortedRecords: EmployeeSectionTimeclock[] = orderBy(this.readyTimeclocks, this.sort);
    let pagedRecords: EmployeeSectionTimeclock[] = sortedRecords.slice(this.skip, this.skip + this.pageSize);
    this.gridView = {
      data: pagedRecords,
      total: this.readyTimeclocks.length
    };

    this.updateData();
  }

  private editHandler(sender: { dataItem: TimeclockRestrictionDefinition, rowIndex: number }): void {
    this.grid.editRow(sender.rowIndex);
  }

  private loadTimeclocksByOrgLevelId(orgLevelId: number): void {
    if (this.lastLoadedOrgLevelId !== orgLevelId) {
      this.lastLoadedOrgLevelId = orgLevelId;
      this.levelTimeclocks = null;
      this.readyTimeclocks = null;
      this.getTimeclocks(orgLevelId);
    } else {
      this.refreshGrid();
    }
  }

  private updateData(): void {
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }
}
