import { Component, Input } from '@angular/core';
import { NotificationsService } from '../../../../../app/core/components';
import { BusyService } from '../../../../common';
import { WfmSyncService } from '../../../../configuration/services/wfm/wfm-sync.service';
import { BaseSyncComponent } from '../wfm-sync-base.component';
import { dataCheck, IBenefitDeductionsync } from './../../../models/wfm-sync';

@Component({
  selector: 'slx-wfm-ngp-benefitdeduction-sync',
  templateUrl: './wfm-ngp-benefitdeduction-sync.component.html',
  styleUrls: ['./wfm-ngp-benefitdeduction-sync.component.scss']
})
export class WfmNgpBenefitdeductionSyncComponent extends BaseSyncComponent<IBenefitDeductionsync> {
  @Input() syncType: string;

  constructor(
    private wfmSyncService: WfmSyncService,
    notificationService: NotificationsService,
    busyService: BusyService
  ) {
    super(notificationService, busyService);
  }

  protected syncDataCore(selectedItems: IBenefitDeductionsync[]): Promise<any> {
    return this.busyService.busy(
      this.wfmSyncService.benefitdeductionSyncOrg(selectedItems, this.syncType)
    );
  }

  protected getSyncItemKey(item: IBenefitDeductionsync): string {
    return '' + item.enrollmentId;
  }

  protected async fetchData(): Promise<IBenefitDeductionsync[]> {
    const response = await this.busyService.busy(
      this.wfmSyncService.GetBenefitDeductionChangeLogs(this.syncType, this.isActiveOnly)
    );

    return this.processData(response);
  }

  private processData(data: IBenefitDeductionsync[]): IBenefitDeductionsync[] {
    return dataCheck(data);
  }
}
