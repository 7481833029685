import { RuleFormulaModel } from "./rule-formula.model";

export class RuleModel {
    public id: number;
    public ruleDescription?: string;
    public startDate?: Date;
    public endDate?: Date;
    public period?: number;
    public organizationId?: number;
    public departmentId?: number;
    public jobCode?: number;
    public exceptionId?: number;
    public whereClause?: string;
    public ruleFormulas?: RuleFormulaModel[];
    public isNew?: boolean;
}