import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { process, SortDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import * as moment from 'moment';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { ConfirmDialog3Component, ConfirmOptions3 } from '../../../../common/components/confirm-dialog3/confirm-dialog3.component';
import { ModalService } from '../../../../common/index';
import { KendoGridStateHelper } from '../../../../common/models/kendo-grid-helpers/kendo-grid-state-helper';
import { ComponentStateStorageService } from '../../../../common/services/component-state/component-state-storage.service';
import { StateManagementService } from '../../../../common/services/state-management/state-management.service';
import { dateTimeUtils } from '../../../../common/utils/dateTimeUtils';
import { IControlState, StateResetTypes } from '../../../../core/models/settings/index';
import { employeeListConfig } from '../../../../employee/employee-list/employee-list.config';
import { DetailColumn, DetailField, DetailGroup, DetailRow } from '../../../models/index';
import { ExportExcelGridComponent } from '../export-excel-grid/export-excel-grid.component';
import { screenUtils } from '../../../../common/utils/screenUtils';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { CancelTypeList } from '../../../../scheduler/models/detail-screen/cancel-type';
import { DailyUnitGridEmployee } from '../daily-unit-grid-employees/daily-unit-grid-employees-model';

const SELECTED_EMP_ID: string = 'selectedEmpId';

@Component({
  moduleId: module.id,
  selector: 'slx-daily-unit-grid-partialshift',
  templateUrl: './daily-unit-grid-partialshift.component.html',
  styleUrls: ['./daily-unit-grid-partialshift.component.scss']
})
export class DailyUnitGridPartialshiftComponent {
  public gridState: KendoGridStateHelper<DailyUnitGridEmployee>;
  public appConfig: IApplicationConfig;
  public partialShifts: DailyUnitGridEmployee[];

  @Input()
  public set partials(value: DailyUnitGridEmployee[]) {
      this.partialShifts = value;
      this.refreshGrid();
  }
  public get partials(): DailyUnitGridEmployee[] {
    return this.partialShifts;
  }

  @Input()
  public IsEnabled: boolean = false;

  @Output()
  public onFillShiftPartial: EventEmitter<DailyUnitGridEmployee>;
  @Output()
  public onEmployeeDelete: EventEmitter<DailyUnitGridEmployee>;
  @Output()
  public onSwitchEmployeeShift: EventEmitter<DailyUnitGridEmployee>;
  @Output()
  public onEmployeeCancel: EventEmitter<{}>;
  @Output()
  public onReplaceEmployee: EventEmitter<DailyUnitGridEmployee>;
  @Output()
  public onShiftOpen: EventEmitter<DailyUnitGridEmployee>;
  @Output()
  public onOpenIndividual: EventEmitter<DailyUnitGridEmployee>;
  @Output()
  public onNeedScroll: EventEmitter<number>;

  @unsubscribe()
  private gridRefreshSubscription: Subscription;

  constructor(
    private stateManagement: StateManagementService,
    private storageService: ComponentStateStorageService,
    private modalService: ModalService) {
    this.appConfig = appConfig;
    this.gridState = new KendoGridStateHelper();
    this.onEmployeeCancel = new EventEmitter<{DailyUnitGridEmployee,string}>();
    this.onEmployeeDelete = new EventEmitter<DailyUnitGridEmployee>();
    this.onFillShiftPartial = new EventEmitter<DailyUnitGridEmployee>();
    this.onReplaceEmployee = new EventEmitter<DailyUnitGridEmployee>();
    this.onShiftOpen = new EventEmitter<DailyUnitGridEmployee>();
    this.onSwitchEmployeeShift = new EventEmitter<DailyUnitGridEmployee>();
    this.onOpenIndividual = new EventEmitter<DailyUnitGridEmployee>();
    this.onNeedScroll = new EventEmitter<number>();

    this.gridState = new KendoGridStateHelper();
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [];
    this.gridState.state.group = [];

    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: any): void => {
      this.refreshGrid();
    });
  }

  private refreshGrid(): void {
    if (!this.partialShifts) {
      this.gridState.view = null;
      return;
    }

    this.gridState.state.take = 24;
    let partialRecords = _.orderBy(this.partialShifts, ['ShiftStart']);
    this.gridState.view = process(partialRecords, this.gridState.state);
  }

  public selectEmployee(row: DailyUnitGridEmployee): void {
    let empId: number = +row.EmpId;
    this.storageService.setControlState(
      this.stateManagement.componentKey,
      SELECTED_EMP_ID,
      { value: empId },
      StateResetTypes.SessionEnd | StateResetTypes.OrgLevelChange | StateResetTypes.MenuChange
    );
  }

  public isEmptyEmployee(row: DailyUnitGridEmployee): boolean {
    if (row.EmpId === '0') return true;
  }
  public isInPar(row: DailyUnitGridEmployee): boolean {
    return row.InPar.toLowerCase() !== 'false';
  }
  public checkPartnerData(row: DailyUnitGridEmployee): boolean {
    if (row.SlxpartnerdataId !== '0') return true;
  }
  public switchEmployee(row: DailyUnitGridEmployee): void {
    this.selectEmployee(row);
    this.onSwitchEmployeeShift.emit(row);
  }

  public isNoPatner(row: DailyUnitGridEmployee): boolean {
    if (row.SlxpartnerdataId === '0') {
      return true;
    } else {
      if (row.RequeststatusId === '0' || row.RequeststatusId === '3') {
        return true;
      } else {
        return false;
      }
    }
  }
  public isOvertime(row: DailyUnitGridEmployee): boolean {
    return row.IsOvertime.toLowerCase() === 'true';
  }

  public fillShift(row: DailyUnitGridEmployee): void {
    this.onFillShiftPartial.emit(row);
  }
  public removeEmployee(row: DailyUnitGridEmployee): void {
    this.onEmployeeDelete.emit(row);
  }
  public getRemoveTip(row: DailyUnitGridEmployee): string {
    return this.isOvertime(row) ? 'Projected for OT' : 'Remove Employee';
  }

  public messageCount(row: DailyUnitGridEmployee): number {
    return +row.MessageCount;
  }
}
