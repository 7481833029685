import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';;
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { PayCodeDetailsManagementService } from '../../services/pay-code-details/pay-code-details-management.service';
import { PayCodeDetailsContainer } from '../../models/pay-code-details/pay-code-details-container';
import { PaycodeExceptionModel } from '../../models/pay-code-details/models/paycode-exception.model';
import { AdditionalRequirementModel } from '../../models/pay-code-details/models/additional-requirement.model';
import { RuleModel } from '../../models/pay-code-details/models/rule.model';
import { ColorUtil } from '../../../common/utils/index';
import { DatePipe, Location } from '@angular/common';
import { AllocationTypeModel } from '../../models/pay-code-details/models/allocation-type.model';
import { ExceptionGroupModel } from '../../models/pay-code-details/models/exception-group.model';
import { ExceptionVariableModel } from '../../models/pay-code-details/models/exception-variable.model';
import { PayDiffTypeModel } from '../../models/pay-code-details/models/pay-diff-type.model';
import { SpecialCodeModel } from '../../models/pay-code-details/models/special-code.model';
import { appConfig, IApplicationConfig } from '../../../app.config';
import { StateManagementService, ColumnManagementService } from '../../../common/index';
import { AccessManagementService } from '../../services';
import { OrgLevel } from '../../../state-model/models/index';
import { mutableSelect } from '../../../core/decorators/index';
import { InterfaceCategory } from '../../models/pay-code-details/models/interface-category.model';
import { PayCodeMinException } from '../../models/pay-code-details/models/paycode-min-exception.model';
import { WorkCode } from '../../models/pay-code-details/models/work-code.model';
import { RuleFormulaModel } from '../../models/pay-code-details/models/rule-formula.model';

@Component({
  moduleId: module.id,
  selector: 'slx-paycode-details',
  templateUrl: 'pay-code-details.component.html',
  styleUrls: ['pay-code-details.component.scss'],
  providers: [
    AccessManagementService,
    PayCodeDetailsManagementService,
    ColumnManagementService
  ]
})

export class PayCodeDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('templateForm', { static: true }) templateForm: NgForm;

  public container: PayCodeDetailsContainer = new PayCodeDetailsContainer();
  public state: { isLoading: boolean; exceptionsDirty: boolean; additionalRequirementsDirty: boolean; rulesDirty: boolean; };
  public allocationTypes: AllocationTypeModel[] = [];
  public exceptionGroups: ExceptionGroupModel[] = [];
  public exceptionVariables: ExceptionVariableModel[] = [];
  public payDiffTypes: PayDiffTypeModel[] = [];
  public specialCodes: SpecialCodeModel[] = [];
  public payCodeExceptions: PayCodeMinException[] = [];
  public workCodes: WorkCode[] = [];
  public interfaceCategories: InterfaceCategory[] = [];
  public appConfig: IApplicationConfig;
  public selectedOption: string;

  private subscriptions: Subscription[] = [];
  private dataLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly columnGroup: string = 'PayCodeDetails';

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  public orgId: number;


  constructor(
    private stateManagement: StateManagementService,
    private management: PayCodeDetailsManagementService,
    private columnManagementService: ColumnManagementService,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.state = {
      isLoading: false,
      exceptionsDirty: false,
      additionalRequirementsDirty: false,
      rulesDirty: false
    };

    this.subscriptions.push(
      this.dataLoaded$.subscribe(loaded => {
        if (loaded) {
          this.sanitizeData();
          this.populateDropdownData();
          this.state.isLoading = false;
          this.cdr.detectChanges();
        }
      })
    );

    this.subscriptions.push(
      this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
        this.orgId = orgLevel.organizationId;
      })
    );

    this.bindPageLoadData();
    this.management.init();

    this.container = this.management.container;
    this.state.isLoading = true;
    this.loadData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  private async loadData(): Promise<void> {
    try {
      await this.fetchAndSetData();
      if (this.container.exceptions.some(exception => exception.deleteInd)) {
        this.location.back();
        return;
      }
      this.dataLoaded$.next(true);
    } catch (error) {
      console.error('Error loading data:', error);
    } finally {
      this.cdr.detectChanges();
    }
  }

  private async fetchAndSetData(): Promise<void> {
    this.cdr.detectChanges();

    (this.container && this.container.id)
      ? await this.fetchDataAndPopulate(this.container.id)
      : console.error('Container ID is not set.');

    this.cdr.detectChanges();
  }

  private async fetchDataAndPopulate(containerId: any): Promise<void> {
    try {
      await Promise.all([
        this.management.fetchPaycodeExceptions(containerId),
        this.management.fetchAdditionalRequirements(containerId),
        this.management.fetchRulesByExceptionId(containerId),
      ]);
      this.populateDropdownData();
      this.setRuleFormulasValueType();
      this.sanitizeData();
      this.dataLoaded$.next(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  private populateDropdownData(): void {
    if (this.container.exceptions && this.container.exceptions.length > 0) {
      const exception = this.container.exceptions[0];
      this.allocationTypes = exception.allocationTypes || [];
      this.exceptionGroups = exception.exceptionGroups || [];
      this.exceptionVariables = exception.exceptionVariables || [];
      this.payDiffTypes = exception.payDiffTypes || [];
      this.specialCodes = exception.specialCodes || [];
      this.payCodeExceptions = exception.payCodeExceptions || [];
      this.workCodes = exception.workCodes || [];
      this.interfaceCategories = exception.interfaceCategories || [];

      exception.exInterfaceCategoryId = this.adjustInterfaceCategoryIdForDisplay(exception.exInterfaceCategoryId);
      this.parseAndFormatAdditionalRequirements();
      this.determineDefaultOption();
    }
  }

  private sanitizeData(): void {
    // Sanitize and merge defaults for exceptions
    this.container.exceptions = this.container.exceptions.map(this.mergeWithDefaults.bind(this));
    if (this.container.exceptions.length === 0) {
      this.container.exceptions = [this.newPaycodeException()];
    }

    // Sanitize other arrays
    this.container.rules = this.sanitizeArray(this.container.rules);
    this.container.additionalRequirements = this.sanitizeArray(this.container.additionalRequirements);
    if (this.container.additionalRequirements.length === 0) {
      this.container.additionalRequirements = [this.newAdditionalRequirement()];
    }
  }

  private mergeWithDefaults(exception: PaycodeExceptionModel): PaycodeExceptionModel {
    const defaults = this.newPaycodeException();
    // Merge defaults with exception ensuring undefined values in exception are replaced by defaults
    Object.keys(defaults).forEach(key => {
      if (exception[key] === undefined) {
        exception[key] = defaults[key];
      }
    });
    return exception;
  }

  private sanitizeArray<T>(array: T[]): T[] {
    return array.map(item => {
      Object.keys(item).forEach(key => {
        if (item[key] === null || item[key] === undefined) {
          item[key] = '';
        }
      });
      return item;
    });
  }

  private formatDate(date: Date): string {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  private determineDefaultOption(): void {
    if (this.container.exceptions[0].exActionStart || this.container.exceptions[0].exActionEnd) {
      this.selectedOption = 'Action';
    } else if (this.container.exceptions[0].exActionStartVar || this.container.exceptions[0].exActionInterval) {
      this.selectedOption = 'Interval';
    } else if (this.container.exceptions[0].exFormulaStart || this.container.exceptions[0].exFormulaEnd) {
      this.selectedOption = 'Formula';
    } else {
      this.selectedOption = 'Action';
    }
    this.updateDisplayedFields();
  }

  private parseOverride(value: any): number | null {
    if (typeof value === 'string') {
      // Try to match the specific pattern first
      const match = value.match(/OT (\d+)/);
      if (match) {
        return +match[1];
      }
      // Try to convert directly if no match
      const intValue = parseInt(value, 10);
      return isNaN(intValue) ? null : intValue;
    }
    return typeof value === 'number' ? value : null;
  }

  private parseAndFormatAdditionalRequirements(): void {
    this.container.additionalRequirements.forEach(req => {
      req.regularOverrideTemp = this.parseOverride(req.regularOverride);
      req.otOverrideTemp = this.parseOverride(req.otOverride);
      req.holidayOverrideTemp = this.parseOverride(req.holidayOverride);
    });
    this.cdr.detectChanges();
  }

  public updateDisplayedFields(): void {
    this.cdr.detectChanges();
  }

  public saveChanges(): void {
    if (this.isSaveDisabled()) {
      return;
    }
    this.state.isLoading = true;
    const saveTasks: Promise<void>[] = [];
    this.sanitizeData();

    if (this.state.exceptionsDirty) {
      saveTasks.push(this.savePaycodeExceptions());
    }
    if (this.state.additionalRequirementsDirty) {
      saveTasks.push(this.saveAdditionalRequirements());
    }
    if (this.state.rulesDirty) {
      saveTasks.push(this.saveRules());
    }

    Promise.all(saveTasks).then(() => {
      this.state.isLoading = false;
      this.state.exceptionsDirty = false;
      this.state.additionalRequirementsDirty = false;
      this.state.rulesDirty = false;
      this.cdr.detectChanges();
    }).catch(error => {
      console.error('Error saving changes:', error);
      this.state.isLoading = false;
      this.cdr.detectChanges();
    });
  }

  private async savePaycodeExceptions(): Promise<void> {
    const updatedData = this.container.exceptions.map(exception => {
      return {
        ...exception
      };
    });
    this.state.isLoading = true;

    try {
      const savePromises = updatedData.map(dataItem => this.management.savePaycodeException(dataItem));
      await Promise.all(savePromises);
      this.state.exceptionsDirty = false;
    } catch (error) {
      console.error('Error saving paycode exceptions:', error);
    } finally {
      this.state.isLoading = false;
      this.cdr.detectChanges();
    }
  }

  private async saveAdditionalRequirements(): Promise<void> {
    const updatedData = this.container.additionalRequirements;
    this.state.isLoading = true;

    try {
      const savePromises = updatedData.map(dataItem => this.management.saveAdditionalRequirement(this.container.id, dataItem));
      await Promise.all(savePromises);
      this.state.additionalRequirementsDirty = false;
    } catch (error) {
      console.error('Error saving additional requirements:', error);
    } finally {
      this.parseAndFormatAdditionalRequirements();
      this.state.isLoading = false;
      this.cdr.detectChanges();
    }
  }

  private async saveRules(): Promise<void> {
    const updatedData = this.container.rules.map(rule => ({
      ...rule,
      whereClause: this.generateWhereClause(rule), // Generate whereClause before validation
      startDate: rule.startDate ? this.formatDate(rule.startDate) : undefined,
      endDate: rule.endDate ? this.formatDate(rule.endDate) : undefined,
    }));

    this.state.isLoading = true;

    try {
      const savePromises = [];

      for (const rule of updatedData) {
        if (rule.whereClause) {
          const isValid = await this.management.validateRule(rule.whereClause);
          if (!isValid) {
            this.state.isLoading = false;
            this.cdr.detectChanges();
            return; // If validation fails, stop saving
          }
        }

        if (rule.id && rule.id > 0) {
          savePromises.push(this.management.saveRule(rule).then(savedRule => {
            rule.isNew = false;  // Reset the isNew flag after saving
            return savedRule;
          }));
        }
      }

      await Promise.all(savePromises);

      // Re-fetch the rules to ensure the state is up-to-date
      await this.management.fetchRulesByExceptionId(this.container.id);
      this.setRuleFormulasValueType();
      this.state.rulesDirty = false;
    } catch (error) {
      console.error('Error saving rules:', error);
    } finally {
      this.state.isLoading = false;
      this.cdr.detectChanges();
    }
  }

  public bindPageLoadData(): void {
    this.initServices();
    this.appConfig = appConfig;
  }

  private initServices(): void {
    this.stateManagement.init('PayCodeDetailsComponent');
    this.columnManagementService.init('PayCodeDetailsComponent');
    this.columnManagementService.initGroup(this.columnGroup, 12);
  }

  public markExceptionsDirty(): void {
    this.state.exceptionsDirty = true;
  }

  public markAdditionalRequirementsDirty(): void {
    this.state.additionalRequirementsDirty = true;
  }

  public markRulesDirty(): void {
    this.state.rulesDirty = true;
  }

  public isSaveDisabled(): boolean {
    const formValid = this.templateForm ? this.templateForm.valid : false;
    const dropdownsValid = this.container.exceptions.every(exception =>
      exception.exAllocationType && exception.exPayDiffType
    );

    return !(formValid && dropdownsValid && (this.state.exceptionsDirty || this.state.additionalRequirementsDirty || this.state.rulesDirty));
  }

  public onExActionStartVarChange(value: number): void {
    this.container.exceptions[0].exActionStartVar = value;
    this.markExceptionsDirty();
  }

  public onGroupIdChange(value: number): void {
    this.container.exceptions[0].groupId = value;
    this.markExceptionsDirty();
  }

  public onExPayDiffTypeChange(value: number): void {
    this.container.exceptions[0].exPayDiffType = value;
    this.markExceptionsDirty();
  }

  public onExAllocationTypeChange(value: number): void {
    this.container.exceptions[0].exAllocationType = value;
    this.markExceptionsDirty();
  }

  public onExWorkStatusChange(value: string): void {
    this.container.exceptions[0].exWorkStatus = value;
    this.markExceptionsDirty();
  }

  public intToHexColor(value: number): string {
    return ColorUtil.DecToHexString(value, true);
  }

  public onColorChange(event: any): void {
    const colorValue = parseInt(event.target.value.replace('#', ''), 16);
    this.container.exceptions[0].screenColor = colorValue;
    this.markExceptionsDirty();
  }

  public onActionVariableChange(value: string, field: 'exActionStart' | 'exActionEnd'): void {
    this.container.exceptions[0][field] = value;
    this.markExceptionsDirty();
  }

  public onActionIntervalChange(event: any): void {
    const intervalValue = parseInt(event.target.value, 10);
    if (!isNaN(intervalValue)) {
      this.container.exceptions[0].exActionInterval = intervalValue.toString();
    } else {
      this.container.exceptions[0].exActionInterval = '';
    }
    this.markExceptionsDirty();
  }

  onOverrideChange(field: string, value: number): void {
    this.container.additionalRequirements[0][field + 'Temp'] = value;
    this.container.additionalRequirements[0][field] = `OT ${value}`;
    this.markAdditionalRequirementsDirty();
  }

  public cancel(): void {
    this.location.back();
  }

  public async deletePaycodeException(): Promise<void> {
    this.state.isLoading = true;
    try {
      await this.management.deletePaycodeException(this.container.id);
      this.location.back();
    } catch (error) {
      console.error('Error deleting paycode exception:', error);
      this.state.isLoading = false;
    }
  }

  addRule(): void {
    this.container.rules.push(this.newRule());
    this.markRulesDirty();
  }

  public removeRule(index: number): void {
    const rule = this.container.rules[index];
    if (rule.isNew) {
      this.container.rules.splice(index, 1);
      this.cdr.detectChanges();
    } else if (rule.id && rule.id > 0) {
      this.management.deleteRule(rule.id).then(() => {
        this.setRuleFormulasValueType();
        this.cdr.detectChanges();
      }).catch(error => {
        console.error('Error deleting rule:', error);
      });
    }
  }

  addRuleFormula(rule: RuleModel): void {
    const newFormula: RuleFormulaModel = {
      id: Math.floor(Math.random() * 9000000) + 1000000,
      ruleId: rule.id,
      variableId1: '',
      operator: '=',
      variableId2: '',
      isNew: true,
      valueType: 'value'
    };
    rule.ruleFormulas.push(newFormula);
    this.markRulesDirty();
    this.cdr.detectChanges();
  }

  removeRuleFormula(rule: RuleModel, formulaIndex: number): void {
    const formula = rule.ruleFormulas[formulaIndex];
    if (formula.isNew) {
      rule.ruleFormulas.splice(formulaIndex, 1);
      this.cdr.detectChanges();
    }
    else if (formula.id && formula.id > 0) {
      this.management.deleteRuleFormula(rule.id, formula.id).then(() => {
        rule.ruleFormulas.splice(formulaIndex, 1);
        this.cdr.detectChanges();
      }).catch(error => {
        console.error('Error deleting rule formula:', error);
      });
    }
  }

  private setRuleFormulasValueType(): void {
    this.container.rules.forEach(rule => {
      rule.ruleFormulas.forEach(formula => {
        if (formula.variableId2.includes('@')) {
          formula.valueType = 'formula';
        } else if (this.exceptionVariables.some(variable => variable.description === formula.variableId2)) {
          formula.valueType = 'variable';
        } else {
          formula.valueType = 'value';
        }
      });
    });
  }

  private generateWhereClause(rule: RuleModel): string {
    const clauses = rule.ruleFormulas.map(formula => {
      const variable1 = this.getFieldProperty(formula.variableId1);
      const operator = formula.operator;
      let variable2;

      if (formula.valueType === 'variable') {
        variable2 = this.getFieldProperty(formula.variableId2);
      } else if (formula.valueType === 'formula') {
        variable2 = formula.variableId2.startsWith('@') ? formula.variableId2.slice(1) : formula.variableId2;
      } else {
        variable2 = formula.variableId2;
      }

      // Check if dataType is 'char' and operator is '=' to wrap variable2 in single quotes
      const variable = this.exceptionVariables.find(v => v.description === formula.variableId2);
      if (variable && variable.dataType === 'char' && operator === '=' && !variable2.startsWith("'") && !variable2.endsWith("'")) {
        variable2 = `'${variable2}'`;
      }

      return `${variable1} ${operator} ${variable2}`;
    });

    return `WHERE ${clauses.join(' AND ')}`;
  }

  private getFieldProperty(variableDescription: string): string {
    const variable = this.exceptionVariables.find(v => v.description === variableDescription);
    return variable ? variable.field : '';
  }

  private adjustInterfaceCategoryIdForDisplay(id: number): number {
    return (Number)(id) + 1;
  }

  private newPaycodeException(): PaycodeExceptionModel {
    return {
      id: this.container.id,
      exceptionDescription: '',
      screenColor: 0,
      accrualInd: false,
      paidInd: false,
      payDiffPct: 0,
      deleteInd: false,
      discrepancyInd: false,
      exAllocationType: 0,
      exInterface: '',
      exWorkStatus: '',
      exInterfaceCategoryId: 0,
      exFormulaStart: '',
      exFormulaEnd: '',
      exAllowMultiple: false,
      exCountTowardsOvertimeInd: false,
      exSortOrder: 0,
      exMinimumInterval: 0,
      exMaximumAppliedInterval: 0,
      exMinimumAppliedInterval: 0,
      exShiftDiffCategory: '',
      exAddlVariable: '',
      ex2ndPayRateExceptionId: 0,
      exSecondaryJobCode: 0,
      exApplyToWeekday: false,
      exApplyShiftDiff: false,
      groupId: 0,
      exAction: '',
      exActionStart: '',
      exActionEnd: '',
      exActionStartVar: 0,
      exActionInterval: '',
      exActionMessage: '',
      exDepartmentId: 0,
      exPayDiffType: 0,
      exShowInExceptionGrid: false,
      exAmount: 0,
      exDisplayGroup: 0,
      exOrganizationId: this.orgId,
      exUseInTimesheets: false,
      workedInd: false,
      acaInd: false,
      exceptionColumnGroup: '',
      isException: false
    };
  }

  private newAdditionalRequirement(): AdditionalRequirementModel {
    return {
      ignoreBreak: false,
      calculateNetWorkTime: false,
      useContiguousTime: false,
      stayWithinExceptionTimeframe: false,
      exportAsDollarAmount: false,
      paidAtHolidayRate: false,
      regularOverride: '',
      otOverride: '',
      holidayOverride: '',
      overtimeHolidayOverride: '',
      otherCodeOverride: '',
      recalculate: '',
      requirementIds: {}
    };
  }

  private newRule(): RuleModel {
    return {
      id: Math.floor(Math.random() * 9000000) + 1000000,
      ruleDescription: '',
      startDate: new Date(),
      endDate: undefined,
      period: 0,
      organizationId: this.orgId,
      departmentId: 0,
      jobCode: 0,
      exceptionId: this.container.id,
      whereClause: '',
      ruleFormulas: [],
      isNew: true
    };
  }
}