import * as tslib_1 from "tslib";
import { ApprovalPayCodesNavigationService } from './../../../../../common/services/navigation/approval-pay-codes-navigation.service';
import { ApprovalOvertimesNavigationService } from './../../../../../common/services/navigation/approval-overtimes-navigation.service';
import { AccrualsNavigationService } from './../../../../../common/services/navigation/accruals-navigation.service';
import { ConfirmOptions, ConfirmDialogComponent } from './../../../../../common/components/confirm-dialog/confirm-dialog.component';
import { OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { appConfig } from '../../../../../app.config';
import * as _ from 'lodash';
import { OrganizationPayroll } from '../../../models/payroll-export-test/organization-payroll';
import { PayrollExportTestManagementService } from '../../../services/payroll-export-test/payroll-export-test-management.service';
import { TimecardsNavigationService, PaycodesConfigurationNavigationService } from './../../../../../common/services/navigation/index';
import { Router, ActivatedRoute } from '@angular/router';
import { InfoDialogComponent, ModalService } from './../../../../../../app/common';
import { OrgPayrollExportState } from '../../../models/payroll-export-test/org-payroll-export-state';
import { ExportPayrollTimeInterval } from '../../../models/payroll-export-test/export-payroll-time-interval';
import { PayrollExportSignalrService } from '../../../services/payroll-export/payroll-export-signalr.service';
import { SessionService } from '../../../../../core/services/index';
var PayrollExportTestSectionComponent = /** @class */ (function () {
    function PayrollExportTestSectionComponent(manageService, changeDetector, router, route, modalService, payrollExportSignalrService, sessionService) {
        var _this = this;
        this.manageService = manageService;
        this.changeDetector = changeDetector;
        this.router = router;
        this.route = route;
        this.modalService = modalService;
        this.payrollExportSignalrService = payrollExportSignalrService;
        this.sessionService = sessionService;
        this.isCollapsed = true;
        this.isLoading = false;
        this.isAccrualModuleEnabled = false;
        this.exportTimeInterval = [];
        this.exportedIds = [];
        this.isNextgenPayrollEnabled = false;
        this.exportToPayrollFrequency = 0;
        this.exportToPayrollDuration = 0;
        this.isexportToPayrollClicked = false;
        this.isNextgenTimeExportEnabled = false;
        this.nextgenPhasedRollout = false;
        this.liveWithTAExport = 'LiveWithTAExport';
        this.manageService.updateexportPayrollStatus.subscribe(function (value) {
            if (value.organizationPayrollId === _this.organization.organizationPayrollId) {
                _this.organization.status = value.status;
                _this.organization.isCheckingExportStatus = value.isCheckingExportStatus;
                _this.detectChanges();
            }
        });
        this.appConfig = appConfig;
    }
    PayrollExportTestSectionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.manageService.subscribeToIsAccrualModuleEnabled(function (result) {
            _this.isAccrualModuleEnabled = result;
        });
        //signalr notify when payroll export status is failed
        this.payrollExportSignalrService.payrollExportedStatus$.subscribe(function (args) {
            var orgPayrollExportStatus = args && args.length > 2 ? JSON.parse(args[2]) : null;
            if (_this.sessionService.getAlias().toLowerCase() === args[1].toLowerCase() && _this.organization.organizationPayrollId === orgPayrollExportStatus.PayrollRecordId) {
                _this.updateStatusAndEnableBtn(_this.organization.organizationPayrollId, OrgPayrollExportState.ExportFailed, false);
            }
        });
        this.getSettings();
    };
    Object.defineProperty(PayrollExportTestSectionComponent.prototype, "isMobile", {
        get: function () {
            return (screen.width <= appConfig.mobileMaxWidth);
        },
        enumerable: true,
        configurable: true
    });
    PayrollExportTestSectionComponent.prototype.collapsedChange = function (isCollapsed) {
        if (!isCollapsed) {
            this.getTests();
        }
    };
    PayrollExportTestSectionComponent.prototype.runTests = function () {
        var _this = this;
        this.isLoading = true;
        this.manageService.runTests(this.organization.organization.id)
            .then(function (value) {
            _this.getTests();
        });
    };
    PayrollExportTestSectionComponent.prototype.exportToPayroll = function () {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        ConfirmDialogComponent.openDialog('Export Timecards', 'Save Timecards to file?', this.manageService.modalService, function (result) {
            if (result) {
                if (_this.isTAExportNGPChanges()) {
                    _this.updateStatusAndEnableBtn(_this.organization.organizationPayrollId, OrgPayrollExportState.InProgress, true);
                }
                _this.manageService.exportToPayroll(_this.organization.organization.id)
                    .then(function (value) {
                    if (value) {
                        _this.getTests();
                    }
                    _this.isCollapsed = false;
                    if (_this.isTAExportNGPChanges()) {
                        _this.isexportToPayrollClicked = true;
                        //reset duration when user click on Export to Payroll button
                        _this.durationInSeconds = _this.exportToPayrollDuration;
                        //Add organizationPayrollId in session when click on Export to Payroll button
                        var val = new ExportPayrollTimeInterval();
                        val.organizationPayrollId = _this.organization.organizationPayrollId;
                        val.intervalSeconds = _this.exportToPayrollFrequency;
                        _this.exportTimeInterval = JSON.parse(sessionStorage.getItem('PayrollExportTimeInterval'));
                        _this.exportTimeInterval.push(val);
                        sessionStorage.setItem('PayrollExportTimeInterval', JSON.stringify(_this.exportTimeInterval));
                        _this.exportPayrollStatus();
                    }
                });
            }
        }, options);
    };
    PayrollExportTestSectionComponent.prototype.requestExport = function () {
        var _this = this;
        this.isLoading = true;
        this.manageService.requestExport(this.organization.organization.id)
            .then(function (value) {
            if (!value.hasErrors) {
                _this.getTests();
            }
            _this.isCollapsed = false;
            _this.isLoading = false;
            _this.detectChanges();
        });
    };
    PayrollExportTestSectionComponent.prototype.lockOrganizationPayroll = function () {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        options.buttonOKtext = "Confirm";
        options.notes = "Locking these timecards will prevent any further changes or additions to the recorded time.";
        ConfirmDialogComponent.openDialog('Lock Timecard', 'Are you sure you want to lock the selected timecards?', this.modalService, function (result) {
            if (result) {
                _this.isLoading = true;
                _this.manageService.lockOrganizationPayroll(_this.organization, true)
                    .then(function (value) {
                    _this.isLoading = false;
                    _this.detectChanges();
                });
            }
        }, options);
    };
    PayrollExportTestSectionComponent.prototype.unlockOrganizationPayroll = function () {
        var _this = this;
        this.isLoading = true;
        this.manageService.lockOrganizationPayroll(this.organization, false)
            .then(function (value) {
            _this.isLoading = false;
            _this.detectChanges();
        });
    };
    PayrollExportTestSectionComponent.prototype.onTestClicked = function (test) {
        var orgLevelId = this.organization.organization.orgLevelId;
        var startDate = this.manageService.selectedPayCycle.startDate;
        var endDate = this.manageService.selectedPayCycle.endDate;
        switch (test.testId) {
            case 1:
            case 4:
            case 5:
            case 6:
            case 9:
                var tcNavi = new TimecardsNavigationService(this.router, this.route);
                tcNavi.NavigateToTimecardsByOrgLevel(orgLevelId, startDate, endDate);
                break;
            case 2: //unapproved OT
                var aoNavi = new ApprovalOvertimesNavigationService(this.router, this.route);
                aoNavi.navigateToOvertimeApproval(startDate, endDate, null, orgLevelId);
                break;
            case 3: //Pending approval required codes (approval required codes screen for payroll cycle and organization in question)
                var apcNavi = new ApprovalPayCodesNavigationService(this.router, this.route);
                apcNavi.navigateToPayCodesApproval(startDate, endDate, orgLevelId);
                break;
            case 7: //Paycodes screen for organization in question
                var pcNavi = new PaycodesConfigurationNavigationService(this.router, this.route);
                pcNavi.navigateToPaycodesConfiguration(orgLevelId);
                break;
            case 8: //Negative accruals
                {
                    if (!this.isAccrualModuleEnabled) {
                        InfoDialogComponent.OpenDialog('Warning', this.negativeAccrual, this.modalService);
                        return;
                    }
                    var accrualsNavi = new AccrualsNavigationService(this.router, this.route);
                    accrualsNavi.navigateToBalance(endDate, orgLevelId);
                    break;
                }
        }
    };
    PayrollExportTestSectionComponent.prototype.getTests = function () {
        var _this = this;
        this.isLoading = true;
        this.manageService.getTests(this.organization.organizationPayrollId)
            .then(function (value) {
            _this.organization.runAt = value.organizationPayroll.runAt;
            _this.tests = value.tests;
            _this.isLoading = false;
            _this.isCollapsed = false;
            _this.detectChanges();
            if (_this.isTAExportNGPChanges() && _this.isexportToPayrollClicked) {
                _this.updateStatusAndEnableBtn(_this.organization.organizationPayrollId, OrgPayrollExportState.InProgress, true);
                _this.isexportToPayrollClicked = false;
            }
        });
    };
    PayrollExportTestSectionComponent.prototype.detectChanges = function () {
        this.changeDetector.markForCheck();
        if (!this.changeDetector['destroyed']) {
            this.changeDetector.detectChanges();
        }
    };
    Object.defineProperty(PayrollExportTestSectionComponent.prototype, "negativeAccrual", {
        get: function () {
            var negativeAccrual;
            this.tests.forEach(function (x) {
                if (x.testId == 8) {
                    negativeAccrual = x.summary;
                }
            });
            return negativeAccrual;
        },
        enumerable: true,
        configurable: true
    });
    PayrollExportTestSectionComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var exportIds;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.isNextgenPayrollEnabled = this.manageService.isNextgenPayrollEnabled;
                this.exportToPayrollFrequency = this.manageService.exportToPayrollFrequency;
                this.exportToPayrollDuration = this.manageService.exportToPayrollDuration;
                this.isNextgenTimeExportEnabled = this.manageService.isNextgenTimeExportEnabled;
                this.nextgenPhasedRollout = this.manageService.nextgenPhasedRollout;
                this.durationInSeconds = this.exportToPayrollDuration;
                this.frequencyInSeconds = this.exportToPayrollFrequency;
                this.frequencyInML = this.frequencyInSeconds * 1000;
                if (this.isTAExportNGPChanges()) {
                    exportIds = JSON.parse(sessionStorage.getItem('PayrollExportTimeInterval'));
                    if (exportIds !== null && exportIds !== undefined && exportIds.length > 0) {
                        _.map(exportIds, function (item) {
                            if (item.organizationPayrollId === _this.organization.organizationPayrollId) {
                                _this.updateStatusAndEnableBtn(_this.organization.organizationPayrollId, OrgPayrollExportState.InProgress, true);
                                _this.exportPayrollStatus();
                            }
                        });
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    PayrollExportTestSectionComponent.prototype.ngOnDestroy = function () {
        clearInterval(this.manageService.intervalId);
    };
    PayrollExportTestSectionComponent.prototype.exportPayrollStatus = function () {
        var _this = this;
        //clear timer before starting
        if (this.manageService.intervalId) {
            clearInterval(this.manageService.intervalId);
        }
        //update duration in session for each record
        this.exportedIds = JSON.parse(sessionStorage.getItem('PayrollExportTimeInterval'));
        //run time interval to update export status
        this.manageService.intervalId = setInterval(function () {
            if (_this.durationInSeconds > 0 && _this.exportedIds !== null
                && _this.exportedIds !== undefined && _this.exportedIds.length > 0) {
                //Call API to get export status
                var Ids = Object.keys(_this.exportedIds).map(function (key) { return _this.exportedIds[key].organizationPayrollId; }).join(",");
                _this.updateStatus(Ids);
            }
            else {
                //clear interval
                if (_this.manageService.intervalId) {
                    clearInterval(_this.manageService.intervalId);
                }
            }
            _this.durationInSeconds = _this.durationInSeconds - _this.frequencyInSeconds;
            //Update values in SessionStorage
            _this.updateSessionStorage();
        }, this.frequencyInML);
    };
    PayrollExportTestSectionComponent.prototype.updateStatus = function (exportedIds) {
        var _this = this;
        this.manageService.updateStatus(exportedIds)
            .then(function (value) {
            var failedRecords = _.filter(value, function (item) { return item.Status === OrgPayrollExportState.ExportFailed; });
            var _loop_1 = function (i) {
                _.map(_this.exportedIds, function (item) {
                    if (item.organizationPayrollId === failedRecords[i].PayrollRecordId) {
                        var updateStatus = new OrganizationPayroll();
                        updateStatus.status = OrgPayrollExportState.ExportFailed;
                        updateStatus.organizationPayrollId = item.organizationPayrollId;
                        updateStatus.isCheckingExportStatus = false;
                        _this.manageService.updateexportPayrollStatus.next(updateStatus);
                    }
                });
                _this.exportedIds.splice(i, 1);
            };
            //Removed Ids which were failed and update status
            for (var i = 0; i < failedRecords.length; i++) {
                _loop_1(i);
            }
            sessionStorage.setItem('PayrollExportTimeInterval', JSON.stringify(_this.exportedIds));
        });
    };
    PayrollExportTestSectionComponent.prototype.updateStatusAndEnableBtn = function (organizationPayrollId, state, isEnabled) {
        var value = this.manageService.updateStatusAndEnableBtn(organizationPayrollId, state, isEnabled);
        this.organization.status = value.status;
        this.organization.isCheckingExportStatus = value.isCheckingExportStatus;
        this.detectChanges();
    };
    PayrollExportTestSectionComponent.prototype.updateSessionStorage = function () {
        var _this = this;
        if (this.exportedIds !== null) {
            var _loop_2 = function (i) {
                if (this_1.exportedIds[i].intervalSeconds >= this_1.exportToPayrollDuration) {
                    _.map(this_1.exportedIds, function (item) {
                        if (_this.exportedIds[i].organizationPayrollId === item.organizationPayrollId) {
                            var updateStatus = new OrganizationPayroll();
                            updateStatus.status = OrgPayrollExportState.ExportSuccess;
                            updateStatus.organizationPayrollId = item.organizationPayrollId;
                            updateStatus.isCheckingExportStatus = false;
                            _this.manageService.updateexportPayrollStatus.next(updateStatus);
                        }
                    });
                    this_1.exportedIds.splice(i, 1);
                }
                else {
                    this_1.exportedIds[i].intervalSeconds = this_1.exportedIds[i].intervalSeconds + this_1.exportToPayrollFrequency;
                }
            };
            var this_1 = this;
            for (var i = 0; i < this.exportedIds.length; i++) {
                _loop_2(i);
            }
            sessionStorage.setItem('PayrollExportTimeInterval', JSON.stringify(this.exportedIds));
        }
    };
    PayrollExportTestSectionComponent.prototype.isTAExportNGPChanges = function () {
        if ((!this.nextgenPhasedRollout && this.isNextgenPayrollEnabled && this.isNextgenTimeExportEnabled)
            || (this.nextgenPhasedRollout && this.isNextgenPayrollEnabled && this.organization.payrollAppStatus === this.liveWithTAExport)) {
            return true;
        }
        else {
            return false;
        }
    };
    return PayrollExportTestSectionComponent;
}());
export { PayrollExportTestSectionComponent };
