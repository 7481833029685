import * as _ from 'lodash';

export interface ISoDashboardCounters {
  openShiftRequestCount: number;
  employeeProjectedOvertimeCount: number;
  ptoRequestCount: number;
}

export class SoDashboardCounters {
  private openShiftRequestCount: number;
  private employeeProjectedOvertimeCount: number;
  private ptoRequestCount: number;

  constructor(counters?: ISoDashboardCounters) {
    this.openShiftRequestCount = _.get(counters, 'openShiftRequestCount', 0);
    this.employeeProjectedOvertimeCount = _.get(counters, 'employeeProjectedOvertimeCount', 0);
    this.ptoRequestCount = _.get(counters, 'ptoRequestCount', 0);
  }

  public get openShiftRequest(): number {
    return _.isNumber(this.openShiftRequestCount) ? this.openShiftRequestCount : 0;
  }

  public get employeeProjectedOvertime(): number {
    return _.isNumber(this.employeeProjectedOvertimeCount) ? this.employeeProjectedOvertimeCount : 0;
  }

  public get ptoRequest(): number {
    return _.isNumber(this.ptoRequestCount) ? this.ptoRequestCount : 0;
  }

  public get total(): number {
    return this.openShiftRequest + this.employeeProjectedOvertime + this.ptoRequest;
  }
}

export interface ITaDashboardCounters {
  missingPunchCount: number;
  overtimeApprovalCount: number;
  timecardExceptionCount: number;
  punchRequestCount: number;
}

export class TaDashboardCounters {
  private missingPunchCount: number;
  private overtimeApprovalCount: number;
  private timecardExceptionCount: number;
  private punchRequestCount: number;

  constructor(counters?: ITaDashboardCounters) {
    this.missingPunchCount = _.get(counters, 'missingPunchCount', 0);
    this.overtimeApprovalCount = _.get(counters, 'overtimeApprovalCount', 0);
    this.timecardExceptionCount = _.get(counters, 'timecardExceptionCount', 0);
    this.punchRequestCount = _.get(counters, 'punchRequestCount', 0);
  }

  public get missingPunch(): number {
    return _.isNumber(this.missingPunchCount) ? this.missingPunchCount : 0;
  }

  public get overtimeApproval(): number {
    return _.isNumber(this.overtimeApprovalCount) ? this.overtimeApprovalCount : 0;
  }

  public get timecardException(): number {
    return _.isNumber(this.timecardExceptionCount) ? this.timecardExceptionCount : 0;
  }

  public get punchRequest(): number {
    return _.isNumber(this.punchRequestCount) ? this.punchRequestCount : 0;
  }

  public get total(): number {
    return this.missingPunch + this.overtimeApproval + this.timecardException + this.punchRequestCount;
  }
}