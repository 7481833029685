import { Component, Input, OnInit } from '@angular/core';
import { PbjOrganizationModel, EditableListActionKind } from '../../../models/index';
import { CustomListActorBase } from '../../editableList/custom-list-actor.base';
import { IApplicationConfig, appConfig } from '../../../../app.config';
import { OrganizationTimezone } from '../../../../organization/models/lookup/organization-timezone';

@Component({
    moduleId: module.id,
    selector: 'slx-pbj-organization-renderer',
    templateUrl: 'pbj-organization-renderer.component.html',
    styleUrls: ['pbj-organization-renderer.component.scss']
})
export class PbjOrganizationRendererComponent extends CustomListActorBase implements OnInit {

    @Input()
    public set context(value: any) {
        this.item = value.item;
        this.rowIndex = value.rowIndex;
    }

    @Input() 
    organizationTimezones:OrganizationTimezone[];

    @Input()
    public item: PbjOrganizationModel;

    @Input()
    public editable: boolean = true;

    @Input()
    public selectable: boolean = true;

    @Input()
    public rowIndex: number;

    private appConfig: IApplicationConfig;

    public ngOnInit(): void {
        this.appConfig = appConfig;
    }

    public onEditClick(): void {
        this.actionEmitter.emit(EditableListActionKind.START_EDIT);
    }

    public onItemSelect(): void {
        if (this.item) this.item.isSelected = !this.item.isSelected;
        this.actionEmitter.emit(EditableListActionKind.SELECTION_CHANGE);
    }
}
