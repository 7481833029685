import * as tslib_1 from "tslib";
import { LookupType } from './../../../../organization/models/lookup/lookup-definition';
import { LookupService } from './../../../../organization/services/lookup/lookup.service';
import { Subject } from 'rxjs/Subject';
import { BudgetedParsPopupComponent } from './../../budgeted-pars/budgeted-pars-popup.component';
import 'moment-range';
import * as moment from 'moment';
import * as _ from 'lodash';
import { OnInit, OnDestroy, ChangeDetectorRef, ElementRef, AfterViewInit } from '@angular/core';
import { CensusSaveRequest } from './../../../models/census';
import { SidebarActions } from './../../../../portal/actions/sidebar.actions';
import { CensusEntriesPopupComponent } from '../../census-entries-popup/census-entries-popup.component';
import { MasterScheduleContextMenuRequest } from './../../../models/master-schedule/master-schedule-context-menu-request';
import { isChildOf, hasClass } from './../../../../common/utils/index';
import { ModalService, ScheduleEntryNavigationService, DailyUnitAssignmentNavigationService } from './../../../../common/services/index';
import { MasterScheduleOptionsDialogComponent } from './master-schedule-options-dialog.component';
import { MasterScheduleDayInfoDialogComponent } from '../master-schedule-day-info-dialog/master-schedule-day-info-dialog.component';
import { ScheduleDailyDetailsDialogComponent } from '../../schedule-daily-details/index';
import { ScheduleDailyDifferencesDialogComponent } from '../../schedule-daily-details/index';
import { EmployeeSectionNavigationService } from './../../../../common/services/navigation/employee-section-navigation.service';
import { MasterScheduleActions } from './../../../store/master-schedule/master-schedule.actions';
import { GridOptions, SortChangedEvent, SelectionChangedEvent, RowNode, CellEvent, Column, GridReadyEvent } from 'ag-grid-community';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/combineLatest';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/first';
import { GenerateScheduleSummary, ScheduleCycleSummaryViewAction, ScheduleActions, IScheduleActions } from '../../../../organization/models/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { appConfig } from '../../../../app.config';
import { LookupApiService } from '../../../../organization/services/index';
import { IMasterScheduleRow, isMasterScheduleEntryRow, isMasterScheduleSubtotalRow, isMasterScheduleTotalRow, IMasterScheduleEntryRow, IMasterScheduleSubtotalRow, IMasterScheduleTotalRow, MasterScheduleRecordType, MasterScheduleEntryCell, MasterScheduleTotalCell, MasterScheduleData, EmployeeGridData, MasterScheduleEmployeeChange, MasterScheduleEntryRow, MasterScheduleDayInfo, ScheduleDailyDetailsRequest, TotalsDescription, ScheduleDailyDifferencesRequest, MasterScheduleSettings, MasterScheduleFilters, ScheduleEntryEditItem } from '../../../../scheduler/models/index';
import { MasterScheduleManagementService, MasterScheduleToolbarService, MasterScheduleQuickEditService } from '../../../services/index';
import { EmployeesStateSelector } from '../../../store/selectors/index';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterScheduleGridHelper } from './master-schedule-grid-helper';
import { ScheduleCycleSummaryDialogComponent } from '../../../../organization/components/schedule-cycle-summary/index';
import { PopperContent, PopperController } from 'ngx-popper';
import { MasterScheduleGroupingType, MasterScheduleGroupingTypes } from '../../../models/master-schedule/master-schedule-grouping-type';
import { ViewIndividualScheduleDialogComponent } from '../view-individual-schedule-dialog/view-individual-schedule-dialog.component';
var MasterScheduleGridComponent = /** @class */ (function () {
    function MasterScheduleGridComponent(modalService, changeDetector, lookupApiService, masterScheduleActions, managementService, element, router, route, sidebarActions, masterScheduleToolbarService, masterScheduleQuickEditService, lookupService) {
        var _this = this;
        this.sidebarActions = sidebarActions;
        this.masterScheduleToolbarService = masterScheduleToolbarService;
        this.masterScheduleQuickEditService = masterScheduleQuickEditService;
        this.popperMobileStyles = {};
        this.popperDesktopStyles = {};
        this.hideSubtotals = false;
        this.gridReady$ = new Subject();
        this.dataApplied$ = new Subject();
        this.modalService = modalService;
        this.changeDetector = changeDetector;
        this.managementService = managementService;
        this.lookupApiService = lookupApiService;
        this.masterScheduleActions = masterScheduleActions;
        this.element = element;
        this.router = router;
        this.route = route;
        this.gridHelper = new MasterScheduleGridHelper();
        this.gridHelper.state = {
            startDate: null,
            endDate: null,
            numWeeks: 0,
            isEmployeeScheduleChangedEvent: false,
            cellDisplay: 'unit',
            positionGroupColShown: true,
            positionColShown: true,
            dateOfHireColShown: true,
            dateTerminatedColShown: false,
            shiftColShown: true,
            sumShiftsPerWeek: false,
            avgWeeklyRotationsHrs: false,
            displayOrder: 'name',
            separateSeconaryPositions: false,
            pinTotalRows: true,
            weeklyTotals: true,
            positionSubtotals: true,
            dailyTotals: true,
            idealSchedule: true,
            openShifts: true,
            difference: true,
            census: true,
            scheduledPpd: true,
            staffingRatio: true,
            actualHours: true,
            actualPpd: true,
            budgetedPars: true,
            viewTotalsFTEs: false,
            positionGrouping: MasterScheduleGroupingTypes.ByPosition,
            employeeTypeColShown: true
        };
        this.gridOptions = {};
        this.gridOptions.pinnedBottomRowData = [];
        this.selectedRowNode = undefined;
        //this.gridOptions.rowHeight = 30;
        this.gridOptions.getRowClass = function (params) {
            if (isMasterScheduleSubtotalRow(params.data)) {
                return 'subtotal-row';
            }
            return null;
        };
        this.gridOptions.getRowStyle = function (params) { return _this.gridHelper.getRowStyle(params); };
        this.gridOptions.groupHeaderHeight = 50;
        this.quickEditActiveSubscription = this.masterScheduleQuickEditService.quickEditStatusChanged$.subscribe(function (event) {
            _this.isQuickEditStarted = event.isStarted;
            _this.isQuickEditConfigurationError = event.isConfigurationError;
        });
        this.quickEditUndoSubscription = this.masterScheduleQuickEditService.quickEditUndo$.subscribe(function (items) {
            if (_this.internalData) {
                _.forEach(items, function (item) {
                    var row = _.find(_this.internalData.employees, function (row) {
                        return row.id === item.employeeId;
                    });
                    if (!row) {
                        return;
                    }
                    var dateOn = EmployeesStateSelector.getDateKey(item.date);
                    row.cell[dateOn] = item.cellForUndo;
                });
                _this.applyGrid(_this.internalData);
            }
        });
        this.gridOptions.onGridReady = (function (event) {
            _this.gridReady$.next(event);
        });
        lookupService.getLookup({ lookupType: LookupType.empType })
            .then(function (value) {
            _this.gridHelper.empTypesMap = _.keyBy(value.items, value.valueField);
            if (_this.gridOptions.api) {
                _this.gridOptions.api.redrawRows();
            }
        });
    }
    Object.defineProperty(MasterScheduleGridComponent.prototype, "popperInitiator", {
        set: function (directive) {
            this.m_popperInitiator = directive;
        },
        enumerable: true,
        configurable: true
    });
    MasterScheduleGridComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.settingsSubscription = this.masterScheduleToolbarService.onSettingsChanged$.subscribe(function (settings) {
            var groupingChanged = false;
            _this.gridHelper.state.positionGroupColShown = settings.columns.columnsMap['positionGroupName'].displayed;
            _this.gridHelper.state.positionColShown = settings.columns.columnsMap['position'].displayed;
            _this.gridHelper.state.dateOfHireColShown = settings.columns.columnsMap['dateOfHire'].displayed;
            _this.gridHelper.state.dateTerminatedColShown = settings.columns.columnsMap['dateTerminated'].displayed;
            _this.gridHelper.state.avgWeeklyRotationsHrs = settings.columns.columnsMap['avgWeeklyRotationsHrs'].displayed;
            _this.gridHelper.state.shiftColShown = settings.columns.columnsMap['shift'].displayed;
            _this.gridHelper.state.employeeTypeColShown = settings.columns.columnsMap['employeeType'].displayed;
            _this.gridHelper.state.cellDisplay = settings.display.cellDisplay;
            _this.gridHelper.state.displayOrder = settings.display.displayOrder;
            _this.gridHelper.state.separateSeconaryPositions = settings.display.separateSeconaryPositions;
            _this.gridHelper.state.pinTotalRows = settings.display.pinTotalRows;
            if (_this.gridHelper.state.positionGrouping.id !== settings.display.positionGrouping.id) {
                groupingChanged = true;
            }
            _this.gridHelper.state.positionGrouping = settings.display.positionGrouping;
            _this.gridHelper.state.weeklyTotals = settings.totals.weeklyTotals;
            _this.gridHelper.state.positionSubtotals = settings.totals.positionSubtotals;
            _this.gridHelper.state.dailyTotals = settings.totals.dailyTotals;
            _this.gridHelper.state.idealSchedule = settings.totals.idealSchedule;
            _this.gridHelper.state.openShifts = settings.totals.openShifts;
            _this.gridHelper.state.difference = settings.totals.difference;
            _this.gridHelper.state.census = settings.totals.census;
            _this.gridHelper.state.scheduledPpd = settings.totals.scheduledPpd;
            _this.gridHelper.state.staffingRatio = settings.totals.staffingRatio;
            _this.gridHelper.state.actualHours = settings.totals.actualHours;
            _this.gridHelper.state.actualPpd = settings.totals.actualPpd;
            _this.gridHelper.state.budgetedPars = settings.totals.budgetedPars;
            _this.gridHelper.state.viewTotalsFTEs = settings.totals.viewTotalsFTEs;
            _this.setColumnsState();
            _this.gridOptions.isExternalFilterPresent = function () { return true; };
            _this.gridOptions.doesExternalFilterPass = function (node) {
                if (!_this.hideSubtotals) {
                    return true;
                }
                if (isMasterScheduleSubtotalRow(node.data)) {
                    return false;
                }
                return true;
            };
            if (_this.internalData) {
                if (!groupingChanged) {
                    _this.applyGrid(_this.internalData);
                }
                _this.changeDetector.markForCheck();
                _this.changeDetector.detectChanges();
            }
        });
        this.selectedRowSubscription = this.selectedRow$.first().subscribe(function (row) {
            if (!_this.gridOptions.api || row === null || row === undefined) {
                return;
            }
            _this.selectedRowNode = _this.gridOptions.api.getRowNode(row);
            if (_this.selectedRowNode) {
                _this.selectedRowNode.setSelected(true, true, true);
            }
        });
        this.filtersRestoredSubscription = this.managementService.filtersRestored$
            .combineLatest(this.gridReady$)
            .combineLatest(this.dataApplied$)
            .debounceTime(500)
            .subscribe(function (_a) {
            var _b = _a[0], filters = _b[0], gridEvent = _b[1], dataApplied = _a[1];
            if (filters.selectedCell) {
                var rowId = _this.getRowIdByEmpId(filters.selectedCell.empId);
                _this.gridOptions.api.ensureIndexVisible(rowId, 'middle');
                _this.gridOptions.api.ensureColumnVisible(filters.selectedCell.columnId);
                _this.makeRowSelected(rowId);
            }
        });
    };
    MasterScheduleGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.filtersSubscription = this.managementService.filtersChanged$.subscribe(function (filters) {
            var startDate = filters.dateFrom;
            var endDate = moment(startDate).add(filters.weekNumber, 'weeks').subtract(1, 'day').toDate();
            var datesRange = moment.range(startDate, endDate);
            _this.datesRange = datesRange.toArray('days');
            _this.gridHelper.state.startDate = startDate;
            _this.gridHelper.state.endDate = endDate;
            _this.gridHelper.state.numWeeks = filters.weekNumber;
            _this.gridHelper.createColumnDefs(filters);
            _this.showGrid = true;
        });
        this.loadedSubscription = this.managementService.onLoaded$.subscribe(function (data) {
            _this.internalData = data;
            _this.applyGrid(_this.internalData);
        });
        this.loadedTotalsSubscription = this.managementService.onTotalsLoaded$.subscribe(function (data) {
            _this.internalData = data;
            _this.applyGrid(_this.internalData, true);
        });
        this.actionsSubscription = this.managementService.actions$.subscribe(function (actions) {
            _this.actions = actions;
        });
    };
    MasterScheduleGridComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    MasterScheduleGridComponent.prototype.showDailyDetails = function (date, position) {
        var _this = this;
        var req = new ScheduleDailyDetailsRequest();
        req.dateOn = date;
        req.orgLevelId = this.managementService.currentOrgLevelId;
        var ids = this.managementService.currentFilters.filters.getIds();
        if (position) {
            if (position.id > 0) {
                req.positionId = position.id;
            }
            else {
                req.positionGroupId = position.positionGroupId;
            }
        }
        else {
            req.positionId = ids.positionId;
        }
        req.shiftGroupId = ids.shiftGroupId;
        req.unitId = ids.unitId;
        ScheduleDailyDetailsDialogComponent.openDialog(req, this.modalService, function (goDua) {
            if (goDua) {
                var navigateService = new DailyUnitAssignmentNavigationService(_this.router, _this.route);
                navigateService.navigateToDailyUnitAssignmentDate(date, [req.positionId], undefined, true);
            }
        });
    };
    MasterScheduleGridComponent.prototype.showDailyDifferences = function (date) {
        var _this = this;
        var req = new ScheduleDailyDifferencesRequest();
        req.dateOn = date;
        req.orgLevelId = this.managementService.currentOrgLevelId;
        var ids = this.managementService.currentFilters.filters.getIds();
        req.positionId = ids.positionId;
        req.shiftGroupId = ids.shiftGroupId;
        req.unitId = ids.unitId;
        req.positionGroupId = ids.positionGroupId;
        ScheduleDailyDifferencesDialogComponent.openDialog(req, this.modalService, function (goDua) {
            if (goDua) {
                var navigateService = new DailyUnitAssignmentNavigationService(_this.router, _this.route);
                navigateService.navigateToDailyUnitAssignmentDate(date, undefined, undefined, true);
            }
        });
    };
    MasterScheduleGridComponent.prototype.onSortChanged = function ($event) {
        var prevValue = this.hideSubtotals;
        var sortState = $event.api.getSortModel();
        if (sortState.length !== 0) {
            this.hideSubtotals = true;
        }
        else {
            this.hideSubtotals = false;
        }
        if (prevValue !== this.hideSubtotals) {
            this.gridOptions.api.onFilterChanged();
            this.changeDetector.markForCheck();
            this.changeDetector.detectChanges();
        }
    };
    MasterScheduleGridComponent.prototype.onCellClicked = function ($event) {
        var _this = this;
        if (isMasterScheduleSubtotalRow($event.node.data) && this.gridHelper.isDayColumn($event.column.getColDef().field)) {
            var subtotalRow = $event.node.data;
            var date = this.gridHelper.getDayDate($event.column.getColDef().field);
            this.showDailyDetails(date, subtotalRow.position);
            return;
        }
        if (isMasterScheduleTotalRow($event.node.data) && this.gridHelper.isDayColumn($event.column.getColDef().field)) {
            var totalRow = $event.node.data;
            if (totalRow.name === TotalsDescription.totalTotals) {
                var date = this.gridHelper.getDayDate($event.column.getColDef().field);
                this.showDailyDetails(date, undefined);
                return;
            }
            if (totalRow.name === TotalsDescription.totalDifference || totalRow.name === TotalsDescription.totalOpenShifts || totalRow.name === TotalsDescription.totalPARLevels) {
                var date = this.gridHelper.getDayDate($event.column.getColDef().field);
                this.showDailyDifferences(date);
                return;
            }
            if (totalRow.name === TotalsDescription.totalCensus) {
                if (this.censusWindow && this.censusWindow.existsUnsavedData())
                    return;
                this.selectedCell = this.gridHelper.getCell($event);
                if (!this.selectedCell) {
                    if (this.censusWindow)
                        this.censusWindow.closeWindow();
                    return;
                }
                this.sidebarActions.setRightSidebar(false);
                this.saveCensusElements($event.event.target);
                this.selectedCensusDate = this.gridHelper.getDayDate($event.column.getColDef().field);
                this.showCensusWindow();
                return;
            }
            if (totalRow.name === TotalsDescription.totalBudgetedPars) {
                var date = this.gridHelper.getDayDate($event.column.getColDef().field);
                var dialog = BudgetedParsPopupComponent.openDialog(this.modalService, this.managementService, function (result) { });
                dialog.setDate(date);
                return;
            }
        }
        var empId = $event.node.data.id;
        if (empId !== null && empId !== undefined)
            if (parseInt(empId) === 0) {
                return;
            }
        if ($event.column.getColDef().field === 'name') {
            var parsedEmpId = parseInt(empId, 10);
            var navService = new EmployeeSectionNavigationService(this.router, this.route);
            this.managementService.selectEmployeeCell({ empId: parsedEmpId, rowId: $event.rowIndex, columnId: $event.colDef.field });
            navService.NavigateToEmployeeSections(parsedEmpId, false);
        }
        else {
            if ($event.column.getColDef().field === 'actions') {
                var request_1 = new MasterScheduleContextMenuRequest();
                request_1.empId = empId;
                request_1.empName = $event.node.data.name;
                request_1.startDate = this.gridHelper.state.startDate;
                request_1.endDate = this.gridHelper.state.endDate;
                request_1.showGenerateSchedule = true;
                request_1.isFromDifferntDepartment = $event.node.data.isFromDifferntDepartment;
                request_1.isPosted = $event.node.data.isPosted;
                request_1.isStartCycleDate = this.managementService.isStartCycle(request_1.startDate);
                request_1.hasRotations = $event.node.data.hasRotations;
                request_1.scheduleActions = this.actions;
                request_1.shiftsPerWeek = $event.node.data.sumShiftsPerWeek;
                request_1.rowData = $event.node.data;
                MasterScheduleOptionsDialogComponent.openDialog(request_1, this.modalService, function (result, cmd) {
                    if (result && cmd) {
                        if (cmd === 'IndividualSchedule') {
                            ViewIndividualScheduleDialogComponent.showDialog(_this.modalService, request_1, function (hasChanges) {
                                _this.managementService.reloadIndividualSchedule(hasChanges);
                            });
                        }
                        else if (cmd === 'GenerateSchedule') {
                            _this.managementService.generateEmpSchedule(parseInt(empId), _this.gridHelper.state.startDate, _this.gridHelper.state.endDate)
                                .then(function (result) {
                                if (result && result.messages && result.messages.length > 0) {
                                    _this.showSummary(result);
                                }
                            });
                        }
                        else if (cmd === 'DeleteSchedule') {
                            _this.managementService.deleteScheduleForEmployee(_this.managementService.currentOrgLevelId, +empId, _this.gridHelper.state.startDate, _this.gridHelper.state.endDate);
                        }
                        else if (cmd === 'CreateRotationFromSchedule') {
                            _this.managementService.createRotationFromSchedule(_this.managementService.currentOrgLevelId, +empId, request_1.selectedCommandPayload.startDate, request_1.selectedCommandPayload.weeks);
                        }
                        return;
                    }
                });
            }
            else {
                if (this.gridHelper.isDayColumn($event.column.getColDef().field)) {
                    var date = this.gridHelper.getDayDate($event.column.getColDef().field);
                    var ev = $event.event;
                    var el = ev.target;
                    var day = EmployeesStateSelector.getDateKey(date);
                    if (_.includes(el.className, 'ms-badge') || _.includes(el.className, 'ms-badge-tooltip')) {
                        var req = new MasterScheduleDayInfo();
                        req.dateOn = date;
                        req.entryRow = $event.node.data;
                        req.dayCell = req.entryRow.cell[day];
                        MasterScheduleDayInfoDialogComponent.openDialog(req, this.modalService, function (result, cmd) {
                            return;
                        });
                    }
                    else {
                        if (this.isQuickEditStarted) {
                            if (this.isQuickEditConfigurationError) {
                                return;
                            }
                            var row_1 = $event.node.data;
                            if (!row_1.cell[day]) {
                                row_1.cell[day] = new MasterScheduleEntryCell();
                                row_1.cell[day].dateOn = date;
                                row_1.cell[day].shiftsInfo = [];
                            }
                            var cell = row_1.cell[day];
                            if (cell.isInQuickEdit) {
                                if (hasClass($event.event.srcElement, 'quick-edit-undo-button') || isChildOf($event.event.srcElement, 'quick-edit-undo-button')) {
                                    this.masterScheduleQuickEditService.undoEditCommandFromId(row_1.id, cell.dateOn);
                                    return;
                                }
                            }
                            this.masterScheduleQuickEditService.quickEditCommand(this.modalService, row_1, cell).then(function () {
                                $event.node.setData(row_1);
                            });
                            return;
                        }
                        var parsedEmpId = parseInt(empId, 10);
                        var entryService = new ScheduleEntryNavigationService(this.router, this.route);
                        this.managementService.selectEmployeeCell({ empId: parsedEmpId, rowId: $event.rowIndex, columnId: $event.colDef.field });
                        entryService.NavigateToScheduleEntry(parsedEmpId, date);
                    }
                }
            }
        }
    };
    MasterScheduleGridComponent.prototype.onExport = function () {
        var _this = this;
        var params = {
            skipHeader: false,
            columnGroups: true,
            skipFooters: false,
            skipGroups: false,
            skipFloatingTop: false,
            skipFloatingBottom: false,
            allColumns: false,
            onlySelected: false,
            suppressQuotes: false,
            fileName: 'Master Schedule Export.xls',
            columnSeparator: ',',
            processHeaderCallback: function (params) {
                if (params.column && params.column.colDef) {
                    var val = params.column.colDef.headerName;
                    if (val && val.indexOf('main-date-header-text') !== -1) {
                        // strip html tags out from week days
                        return val.replace(/<[^>]*>/g, '');
                    }
                    return val;
                }
                return '';
            },
            processCellCallback: function (params) {
                var colId = params.column.getColDef().field;
                if (!_this.gridHelper.isDayColumn(colId)) {
                    if (_.isNumber(params.value)) {
                        return params.value ? params.value.toFixed(4) : '0';
                    }
                    if (colId === 'position' && params.value && (isMasterScheduleEntryRow(params.node.data) || isMasterScheduleSubtotalRow(params.node.data))) {
                        return params.value;
                    }
                    if (params.node.data && params.node.data.employeeType && params.column.getColDef().field === 'employeeType') {
                        return params.node.data.employeeType.name;
                    }
                }
                return params.value;
            }
        };
        this.gridOptions.api.exportDataAsExcel(params);
    };
    MasterScheduleGridComponent.prototype.onComponentStateChanged = function (event) {
        if (this.restoreScroll && this.gridScrollElement) {
            this.restoreScroll = false;
            this.gridScrollElement.scrollTop = this.scrollTop;
            this.gridScrollElement.scrollLeft = this.scrollLeft;
            if (this.gridOptions.api) {
                this.gridOptions.api.redrawRows();
            }
        }
    };
    MasterScheduleGridComponent.prototype.onSelectionChanged = function (event) {
        if (!this.gridOptions.api) {
            return;
        }
        var selectedRows = this.gridOptions.api.getSelectedNodes();
        if (selectedRows.length > 0) {
            this.selectedRowNode = selectedRows[0];
            this.masterScheduleActions.setSelectedRow(this.selectedRowNode.id);
        }
    };
    MasterScheduleGridComponent.prototype.onCensusPopperShown = function (popper) {
        var _this = this;
        this.censusCancelSubscription = this.censusWindow.onCanceled.subscribe(function (censusSaveRequest) {
            if (_this.m_popperInitiator) {
                _this.m_popperInitiator.hide();
            }
        });
        this.censusSavedSubscription = this.censusWindow.onSaved.subscribe(function (censusSaveRequest) {
            if (censusSaveRequest.copyToFutureDates) {
                if (_this.m_popperInitiator) {
                    _this.m_popperInitiator.hide();
                }
            }
            else {
                _this.censusCellElement.innerHTML = _this.censusWindow.currentCapacity.toFixed(2);
                _this.selectedCell.totalValue = _this.censusWindow.currentCapacity;
                _this.m_popperInitiator.hide();
            }
            _this.masterScheduleActions.fetchTotals();
        });
    };
    MasterScheduleGridComponent.prototype.onCensusPopperHidden = function (popper) {
        if (this.censusSavedSubscription)
            this.censusSavedSubscription.unsubscribe();
        if (this.censusCancelSubscription)
            this.censusSavedSubscription.unsubscribe();
        this.clearCensusElements();
    };
    MasterScheduleGridComponent.prototype.makeRowSelected = function (rowId) {
        this.gridOptions.api.selectIndex(rowId, false, true);
    };
    MasterScheduleGridComponent.prototype.saveCensusElements = function (cell) {
        this.censusCellElement = cell;
    };
    MasterScheduleGridComponent.prototype.clearCensusElements = function () {
        this.selectedCell = null;
        this.censusCellElement = null;
    };
    MasterScheduleGridComponent.prototype.showCensusWindow = function () {
        if (this.m_popperInitiator) {
            this.m_popperInitiator.hide();
            this.changeDetector.markForCheck();
            this.changeDetector.detectChanges();
            this.m_popperInitiator.targetElement = this.censusCellElement;
            this.m_popperInitiator.show();
            this.changeDetector.markForCheck();
            this.changeDetector.detectChanges();
            this.censusWindow.updateInfoBy(this.selectedCensusDate);
            this.censusPopperStyles = this.popperMobileStyles;
            // always fit parent container
            this.censusWindow.fullSize = true;
        }
    };
    MasterScheduleGridComponent.prototype.onQuickFilterChanged = function ($event) {
        this.gridOptions.api.setQuickFilter($event.target.value);
    };
    MasterScheduleGridComponent.prototype.getRowIdByEmpId = function (empId) {
        // let rowId = 0;
        // _.forIn(this.rowData, (row: any) => {
        //   rowId++;
        //   if(parseInt(row.id, 10) === empId) {
        //     return;
        //   }
        // });
        // if (rowId === this.rowData.length)
        //   return 0;
        // else
        //   return rowId;
        return _.findIndex(this.rowData, function (row) {
            return row.id === empId;
        });
    };
    MasterScheduleGridComponent.prototype.applyGrid = function (msData, mergeRequired) {
        if (mergeRequired === void 0) { mergeRequired = false; }
        var data = this.managementService.calculateData(msData, this.gridHelper.state.separateSeconaryPositions, this.gridHelper.state.viewTotalsFTEs, this.gridHelper.state.positionGrouping);
        var rows;
        if (!this.gridHelper.state.positionSubtotals) {
            rows = _.filter(data.rows, function (r) { return !isMasterScheduleSubtotalRow(r); });
        }
        else {
            rows = data.rows;
        }
        if (this.gridHelper.state.positionGrouping) {
            if (this.gridHelper.state.positionGrouping.id === MasterScheduleGroupingType.BYPOSITION) {
                if (this.gridHelper.state.displayOrder === 'seniority') {
                    if (this.gridHelper.state.separateSeconaryPositions) {
                        rows = _.sortBy(rows, 'sortSequence', 'position.name', 'recordType', 'secondarySort', 'seniority');
                    }
                    else {
                        rows = _.sortBy(rows, 'sortSequence', 'position.name', 'recordType', 'seniority');
                    }
                }
                else {
                    if (this.gridHelper.state.separateSeconaryPositions) {
                        rows = _.sortBy(rows, 'sortSequence', 'position.name', 'recordType', 'secondarySort', 'name');
                    }
                    else {
                        rows = _.sortBy(rows, 'sortSequence', 'position.name', 'recordType', 'name');
                    }
                }
            }
            else if (this.gridHelper.state.positionGrouping.id === MasterScheduleGroupingType.BYPOSITIONGROUP) {
                if (this.gridHelper.state.displayOrder === 'seniority') {
                    if (this.gridHelper.state.separateSeconaryPositions) {
                        rows = _.sortBy(rows, 'sortSequence', 'positionGroupName', 'recordType', 'secondarySort', 'seniority');
                    }
                    else {
                        rows = _.sortBy(rows, 'sortSequence', 'positionGroupName', 'recordType', 'seniority');
                    }
                }
                else {
                    if (this.gridHelper.state.separateSeconaryPositions) {
                        rows = _.sortBy(rows, 'sortSequence', 'positionGroupName', 'recordType', 'secondarySort', 'name');
                    }
                    else {
                        rows = _.sortBy(rows, 'sortSequence', 'positionGroupName', 'recordType', 'name');
                    }
                }
            }
            else {
                throw new Error("Master Schedule Behaviour is not implemented For type: " + this.gridHelper.state.positionGrouping.name);
            }
        }
        if (mergeRequired) {
            this.mergeEmployeeData(this.rowData, rows);
        }
        else {
            this.rowData = rows;
        }
        var totalMap = {};
        this.m_footerData = [];
        totalMap = _.keyBy(data.totals, function (r) { return r.name; });
        if (this.gridHelper.state.dailyTotals && totalMap[TotalsDescription.totalTotals]) {
            this.m_footerData.push(totalMap[TotalsDescription.totalTotals]);
        }
        if (this.gridHelper.state.idealSchedule && totalMap[TotalsDescription.totalPARLevels]) {
            this.m_footerData.push(totalMap[TotalsDescription.totalPARLevels]);
        }
        if (this.gridHelper.state.budgetedPars && totalMap[TotalsDescription.totalBudgetedPars]) {
            this.m_footerData.push(totalMap[TotalsDescription.totalBudgetedPars]);
        }
        if (this.gridHelper.state.openShifts && totalMap[TotalsDescription.totalOpenShifts]) {
            this.m_footerData.push(totalMap[TotalsDescription.totalOpenShifts]);
        }
        if (this.gridHelper.state.difference && totalMap[TotalsDescription.totalDifference]) {
            this.m_footerData.push(totalMap[TotalsDescription.totalDifference]);
        }
        if (this.gridHelper.state.census && totalMap[TotalsDescription.totalCensus]) {
            this.m_footerData.push(totalMap[TotalsDescription.totalCensus]);
        }
        if (this.gridHelper.state.scheduledPpd && totalMap[TotalsDescription.totalPPD]) {
            this.m_footerData.push(totalMap[TotalsDescription.totalPPD]);
        }
        if (this.gridHelper.state.staffingRatio && totalMap[TotalsDescription.totalStaffingRatio]) {
            this.m_footerData.push(totalMap[TotalsDescription.totalStaffingRatio]);
        }
        if (this.gridHelper.state.actualHours && totalMap[TotalsDescription.totalActualHours]) {
            this.m_footerData.push(totalMap[TotalsDescription.totalActualHours]);
        }
        if (this.gridHelper.state.actualPpd && totalMap[TotalsDescription.totalActualPPD]) {
            this.m_footerData.push(totalMap[TotalsDescription.totalActualPPD]);
        }
        if (this.gridHelper.state.pinTotalRows) {
            this.footerData = this.m_footerData;
        }
        else {
            this.footerData = [];
            this.rowData = this.mergeFooterData(this.rowData, this.m_footerData);
            if (this.gridOptions.api) {
                this.gridOptions.api.redrawRows();
            }
        }
        this.restoreScroll = true;
        if (this.gridOptions.api && this.selectedRowNode) {
            this.selectedRowNode.setSelected(true, true);
        }
        this.setColumnsState();
        this.dataApplied$.next();
    };
    MasterScheduleGridComponent.prototype.mergeFooterData = function (rowData, footerData) {
        var existingRows = _.filter(rowData, function (row) { return row.recordType === MasterScheduleRecordType.Total; });
        if (existingRows.length === 0) {
            return _.concat(rowData, footerData);
        }
        else {
            var footerMap_1 = _.keyBy(footerData, function (row) { return row.name; });
            _.forEach(existingRows, function (row) {
                var newRow = footerMap_1[row.name];
                row.cell = _.size(row.cell) >= _.size(newRow.cell) ? row.cell : newRow.cell;
            });
            return rowData;
        }
    };
    MasterScheduleGridComponent.prototype.mergeEmployeeData = function (gridRows, rowsToMerge) {
        var _this = this;
        var subtotalGridRows = _.filter(_.map(gridRows, function (row, index) {
            var iteratedRow = {};
            iteratedRow.rowId = index;
            iteratedRow.row = row;
            return iteratedRow;
        }), function (row) { return row.row.recordType === MasterScheduleRecordType.Subtotal; });
        var subtotalRowsToMerge = _.filter(rowsToMerge, function (row) { return row.recordType === MasterScheduleRecordType.Subtotal; });
        _.forIn(subtotalGridRows, function (indexedRowToUpdate) {
            var rowToUpdate = indexedRowToUpdate.row;
            var rowToMerge;
            if (!_this.gridHelper.state.positionGrouping || _this.gridHelper.state.positionGrouping.id === MasterScheduleGroupingType.BYPOSITION) {
                rowToMerge = _.find(subtotalRowsToMerge, function (row) {
                    return ((row.position ? row.position.id : 0) === (rowToUpdate.position ? rowToUpdate.position.id : 0)) &&
                        (row.positionGroupName === '' || row.positionGroupName === rowToUpdate.positionGroupName);
                });
            }
            else {
                rowToMerge = _.find(subtotalRowsToMerge, function (row) {
                    return (row.positionGroupName === rowToUpdate.positionGroupName);
                });
            }
            if (!!rowToMerge) {
                rowToUpdate.cell = rowToMerge.cell;
                rowToUpdate.position = rowToMerge.position;
            }
        });
        var nodesToRefresh = [];
        _.forIn(subtotalGridRows, function (row) {
            var rowNode = _this.gridOptions.api.getRowNode(row.rowId);
            nodesToRefresh.push(rowNode);
        });
        this.gridOptions.api.refreshCells({
            rowNodes: nodesToRefresh,
            force: true
        });
    };
    MasterScheduleGridComponent.prototype.setColumnsState = function () {
        var _this = this;
        if (!this.gridOptions.columnApi) {
            return;
        }
        var columns = this.gridOptions.columnApi.getPrimaryColumns();
        _.forEach(columns, function (column) {
            if (column.getColDef().field === 'positionGroupName') {
                _this.gridOptions.columnApi.setColumnVisible(column, _this.gridHelper.state.positionGroupColShown);
            }
            if (column.getColDef().field === 'position') {
                _this.gridOptions.columnApi.setColumnVisible(column, _this.gridHelper.state.positionColShown);
            }
            if (column.getColDef().field === 'hireDate') {
                _this.gridOptions.columnApi.setColumnVisible(column, _this.gridHelper.state.dateOfHireColShown);
            }
            if (column.getColDef().field === 'dateTerminated') {
                _this.gridOptions.columnApi.setColumnVisible(column, _this.gridHelper.state.dateTerminatedColShown);
            }
            if (column.getColDef().field === 'homeShiftName') {
                _this.gridOptions.columnApi.setColumnVisible(column, _this.gridHelper.state.shiftColShown);
            }
            if (column.getColDef().field === 'avgWeeklyRotationsHrs') {
                _this.gridOptions.columnApi.setColumnVisible(column, _this.gridHelper.state.avgWeeklyRotationsHrs);
            }
            if (column.getColDef().field === 'employeeType') {
                _this.gridOptions.columnApi.setColumnVisible(column, _this.gridHelper.state.employeeTypeColShown);
            }
            if (_this.gridHelper.isWeekTotalColumn(column.getColDef().field)) {
                _this.gridOptions.columnApi.setColumnVisible(column, _this.gridHelper.state.weeklyTotals);
            }
        });
        _.forEach(this.gridHelper.weeks, function (week) {
            _.forEach(week, function (dayCol) {
                if (!_this.gridHelper.isWeekTotalColumn(dayCol.field)) {
                    var width = void 0;
                    switch (_this.gridHelper.state.cellDisplay) {
                        case 'shiftTimes':
                            width = 150;
                            break;
                        case 'shiftName':
                            width = 90;
                            break;
                        case 'shiftNameAndUnit':
                            width = 150;
                            break;
                        case 'shiftDurationHours':
                            width = 80;
                            break;
                        case 'shiftDurationMin':
                            width = 80;
                            break;
                        default:
                            width = 80;
                    }
                    _this.gridOptions.columnApi.setColumnWidth(dayCol.field, width);
                }
            });
        });
    };
    MasterScheduleGridComponent.prototype.showSummary = function (summary) {
        var _this = this;
        ScheduleCycleSummaryDialogComponent.openDialog(summary, this.modalService, function (action) {
            if (!action) {
                return;
            }
            if (action.action === 'NavigateToEmployee') {
                var es = new EmployeeSectionNavigationService(_this.router, _this.route);
                es.NavigateToEmployeeSections(action.employeeId, false);
            }
            if (action.action === 'NavigateToScheduleEntry') {
                var se = new ScheduleEntryNavigationService(_this.router, _this.route);
                se.NavigateToScheduleEntry(action.employeeId, action.date);
            }
        });
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], MasterScheduleGridComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        mutableSelect(['masterSchedule', 'scrollPositions']),
        tslib_1.__metadata("design:type", Observable)
    ], MasterScheduleGridComponent.prototype, "scrollPositions$", void 0);
    tslib_1.__decorate([
        mutableSelect(['masterSchedule', 'selectedRow']),
        tslib_1.__metadata("design:type", Observable)
    ], MasterScheduleGridComponent.prototype, "selectedRow$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleGridComponent.prototype, "censusSavedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleGridComponent.prototype, "censusCancelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleGridComponent.prototype, "loadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleGridComponent.prototype, "loadedTotalsSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleGridComponent.prototype, "filtersSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleGridComponent.prototype, "settingsSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleGridComponent.prototype, "selectedRowSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleGridComponent.prototype, "quickEditActiveSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleGridComponent.prototype, "quickEditUndoSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleGridComponent.prototype, "actionsSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleGridComponent.prototype, "filtersRestoredSubscription", void 0);
    return MasterScheduleGridComponent;
}());
export { MasterScheduleGridComponent };
