import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { LookupApiService } from '../lookup/lookup-api.service';
import { ScheduleCycle, ScheduleCycleScope } from '../../../organization/models/index';
import { OrgLevel } from '../../../state-model/models/index';

@Injectable()
export class ScheduleCycleHelperService {
  constructor(private lookupService: LookupApiService) { }

  public getScheduleCycles(orgLevelId: number): Promise<ScheduleCycle[]> {
    return this.lookupService.getScheduleCycles(orgLevelId);
  }

  public getScheduleCycleByScope(scope: ScheduleCycleScope, orgLevelId: number): Promise<ScheduleCycle> {
    return this.lookupService.getScheduleCycles(orgLevelId)
      .then((cycles: ScheduleCycle[]) => {
        return this.findScheduleCycleByScope(cycles, scope);
      });
  }
  public getScheduleCycleByDate(date: Date, orgLevelId: number): Promise<ScheduleCycle> {
    return this.lookupService.getScheduleCycles(orgLevelId)
      .then((cycles: ScheduleCycle[]) => {
        return this.findScheduleCycleByDate(cycles, date);
      });
  }

  public getWeeksByDate(cycles: ScheduleCycle[], dateFrom: Date): number {
    let m = moment(dateFrom);
    let cycle = _.find(cycles, (c: ScheduleCycle) => {
      return m.isSameOrAfter(c.startDate) && m.isSameOrBefore(c.endDate);
    });
    let weeks: number = cycle ? this.calcWeeks(cycle.startDate, cycle.endDate) : 4;
    return weeks;
  }

  public calcWeeks(startDate: moment.Moment | Date, endDate: moment.Moment | Date): number {
    let weeks: number = moment(endDate).diff(startDate, 'weeks') + 1;
    return weeks;
  }

  public findScheduleCycleByDate(cycles: ScheduleCycle[], date: Date): ScheduleCycle {
    let selectedCycle: ScheduleCycle = _.find(_.orderBy(cycles, (p: ScheduleCycle) => p.startDate, 'asc'), (cycle: ScheduleCycle) => {
      return moment(date).isSameOrAfter(cycle.startDate) && moment(date).isSameOrBefore(cycle.endDate);
    });
    return selectedCycle;
  }

  public isDateWithinRange(cycles: ScheduleCycle[], date: string): ScheduleCycle {
    _.map(cycles, (cycle: any) => { cycle.startDate = cycle.startDate.format('MM/DD/YYYY'); cycle.endDate = cycle.endDate.format('MM/DD/YYYY') })
    const inputDate = new Date(moment(date).format('MM/DD/YYYY'));
    return _.find(_.orderBy(cycles, (p: ScheduleCycle) => p.startDate, 'asc'), (cycle: any) => {
      const startDate = new Date(cycle.startDate);
      const endDate = new Date(cycle.endDate);
      return inputDate >= startDate && inputDate <= endDate;
    });
  }

  public findScheduleCycleByScope(cycles: ScheduleCycle[], scope: ScheduleCycleScope): ScheduleCycle {
    const now = moment().startOf('day').toDate();
    const currentScheduleCycle = this.findScheduleCycleByDate(cycles, now);
    if (scope === ScheduleCycleScope.currentScheduleCycle) {
      return currentScheduleCycle;
    }

    if (scope === ScheduleCycleScope.nextScheduleCycle) {
      if(!currentScheduleCycle) {
        return null;
      }
      const nextScheduleCycle = this.findScheduleCycleByDate(cycles, moment(currentScheduleCycle.endDate).add(1, 'day').toDate());
      return nextScheduleCycle;
    }
    throw new Error('ScheduleCycle out of scope');
  }
}
