import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { dateTimeUtils } from './../../../../common/utils/dateTimeUtils';
import { Assert } from '../../../../framework/index';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { StateResetTypes } from '../../../../core/models/index';
import { BenefitDetailsBasic, BenefitFormulaCalculationType, BenefitDetailsTier, BenefitDetailsOption, } from '../../models/index';
import { StateManagementService, ComponentStateStorageService, FileService } from '../../../../common/services/index';
import { CalculationCommonService } from '../../services/benefit-details/calculation-common.service';
import { BenefitDetailsManagementService } from './benefit-details-management.service';
import { BenefitDetailsPermissionService } from './benefit-details-permission.service';
import { BenefitDetailsApiService } from './benefit-details-api.service';
import { BenefitEmployeesApiService } from '../benefit-employees/benefit-employees-api.service';
import { BenefitEligibilityRulesManagementService } from '../benefit-eligibility-rules';
var BenefitDetailsStandartManagementService = /** @class */ (function () {
    function BenefitDetailsStandartManagementService(commonManService, permissionService, stateService, storageService, apiService, empApiService, fileService, rulesManagementService, costCalculationService, benefitDetailsApiService) {
        var _a;
        this.commonManService = commonManService;
        this.permissionService = permissionService;
        this.stateService = stateService;
        this.storageService = storageService;
        this.apiService = apiService;
        this.empApiService = empApiService;
        this.fileService = fileService;
        this.rulesManagementService = rulesManagementService;
        this.costCalculationService = costCalculationService;
        this.benefitDetailsApiService = benefitDetailsApiService;
        this.calcMethod$ = new ReplaySubject(1);
        this.state$ = new ReplaySubject(1);
        this.providerTiers$ = new ReplaySubject(1);
        this.selectProviderTier$ = new ReplaySubject(1);
        this.addCoverageOption$ = new ReplaySubject(1);
        this.changeProviderPlanDate$ = new ReplaySubject(1);
        this.benefitEligibleEmployeesInfo$ = new ReplaySubject(1);
        this.benefitEnrolledEmployeesInfo$ = new ReplaySubject(1);
        this.benefitEnrolledEmployeesChanged$ = new ReplaySubject(1);
        this.costOrEmpFrequency$ = new Subject();
        this.providerTiers = [];
        this.updateEmployees$ = new ReplaySubject(1);
        this.providerNameIsUnique = true;
        this.providerTierNameIsUnique = true;
        this.providerTierEECodeIsUnique = true;
        this.providerTierERCodeIsUnique = true;
        this.isValidEEdeductionCode = true;
        this.isValidERdeductionCode = true;
        this.minAge = 1;
        this.maxAge = 99;
        this.coverageOptionsValid = true;
        this.formulaTypesNames = (_a = {},
            _a[BenefitFormulaCalculationType.Formula] = 'Formula',
            _a[BenefitFormulaCalculationType.Fixed] = 'Fixed Amount',
            _a[BenefitFormulaCalculationType.MultiplerMaxCap] = 'Multiplier with Max Cap',
            _a[BenefitFormulaCalculationType.AnyValueMaxCap] = 'Any value with Max Cap',
            _a);
        this.subscriptions = {};
        this.controlId = 'shownFormula';
        this.resetBy = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;
        this.defaultTierName = 'Default';
        this.covOptionsChanged = false;
        this.hasEnrolledEmpTierLevel = false;
        this.deductionValue = false;
        this.duplicateValue = false;
        this.isDuplicateERDeductionCode = false;
        this.eEdeductionType = 'employee';
        this.eRdeductionType = 'employer';
    }
    BenefitDetailsStandartManagementService.prototype.init = function (componentId) {
        this.componentId = componentId;
        this.stateService.init(componentId, true);
        this.subscribeToData();
    };
    BenefitDetailsStandartManagementService.prototype.destroy = function () {
        this.calcMethod$.complete();
        this.state$.complete();
        this.providerTiers$.complete();
        this.selectProviderTier$.complete();
        this.changeProviderPlanDate$.complete();
        this.addCoverageOption$.complete();
        this.benefitEligibleEmployeesInfo$.complete();
        this.benefitEnrolledEmployeesInfo$.complete();
        this.benefitEnrolledEmployeesChanged$.complete();
        this.costOrEmpFrequency$.complete();
    };
    BenefitDetailsStandartManagementService.prototype.hasChangesInCovOptions = function () {
        return this.covOptionsChanged;
    };
    BenefitDetailsStandartManagementService.prototype.subscribeToChangeCalcMethod = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.calcMethod$.subscribe(callback);
    };
    BenefitDetailsStandartManagementService.prototype.subscribeToChangeState = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.state$.subscribe(callback);
    };
    BenefitDetailsStandartManagementService.prototype.subscribeToChangeupdateEmployees = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.updateEmployees$.subscribe(callback);
    };
    BenefitDetailsStandartManagementService.prototype.updateEmployees = function (value) {
        this.covOptionsChanged = false;
        this.updateEmployees$.next(value);
    };
    BenefitDetailsStandartManagementService.prototype.subscribeToProviderTiers = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.providerTiers$.subscribe(callback);
    };
    BenefitDetailsStandartManagementService.prototype.subscribeToSelectProviderTier = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.selectProviderTier$.subscribe(callback);
    };
    BenefitDetailsStandartManagementService.prototype.subscribeToChangeProviderPlanDate = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.changeProviderPlanDate$.subscribe(callback);
    };
    BenefitDetailsStandartManagementService.prototype.subscribeToAddNewCoverageOption = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.addCoverageOption$.subscribe(callback);
    };
    BenefitDetailsStandartManagementService.prototype.subscribeToBenefitEligibleEmployeesInfo = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.benefitEligibleEmployeesInfo$.subscribe(callback);
    };
    BenefitDetailsStandartManagementService.prototype.subscribeToBenefitEnrolledEmployeesInfo = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.benefitEnrolledEmployeesInfo$.subscribe(callback);
    };
    BenefitDetailsStandartManagementService.prototype.subscribeToBenefitEnrolledEmployeesChanged = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.benefitEnrolledEmployeesChanged$.subscribe(callback);
    };
    BenefitDetailsStandartManagementService.prototype.subscribeToCostOrEmpFrequencyChanged = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.costOrEmpFrequency$.subscribe(callback);
    };
    BenefitDetailsStandartManagementService.prototype.changeCalcMethod = function (cm) {
        this.calcMethod = cm;
        this.calcMethod$.next(cm);
    };
    BenefitDetailsStandartManagementService.prototype.changeProviderPlanDate = function (date) {
        if (_.isDate(date)) {
            this.providerPlanDate = date;
            this.changeProviderPlanDate$.next(date);
        }
    };
    BenefitDetailsStandartManagementService.prototype.updateProviderName = function (pName) {
        this.selectedProvider.current.name = _.trim(pName);
        this.updateCanSaveState();
    };
    BenefitDetailsStandartManagementService.prototype.updatedeductionCode = function (pName) {
        this.selectedProviderTier.payrollDeductionCode = _.trim(pName);
        this.updateCanSaveState();
    };
    BenefitDetailsStandartManagementService.prototype.updateERDeductionCode = function (eRDCode) {
        this.selectedProviderTier.employerDeductionCode = _.trim(eRDCode);
        this.updateCanSaveState();
    };
    BenefitDetailsStandartManagementService.prototype.selectProviderLine = function (pLine, byPass) {
        if (byPass === void 0) { byPass = false; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.commonManService.loadProviderLine(pLine.id, byPass)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BenefitDetailsStandartManagementService.prototype.updateProviderLine = function (isCostFrequencyChanged) {
        if (isCostFrequencyChanged === void 0) { isCostFrequencyChanged = null; }
        if (_.isBoolean(isCostFrequencyChanged) && !this.selectedProviderLine.isDraft) {
            this.updateAllOptionsInAllTiers(this.selectedProviderLine.current);
        }
        this.updateTierFormulasDates(this.selectedProviderLine);
        this.updateCanSaveState();
    };
    BenefitDetailsStandartManagementService.prototype.addProviderTier = function () {
        var pLine = this.selectedProviderLine.current;
        var newTier = this.createBenefitProviderTier(pLine.line.startDate, pLine.line.endDate, _.size(pLine.tiers));
        pLine.tiers.push(newTier);
        this.selectProviderTiers(pLine.tiers);
        this.selectProviderTier(newTier);
    };
    BenefitDetailsStandartManagementService.prototype.updateProviderTier = function () {
        this.selectedProviderTier.setStateUpdated();
        this.updateCanSaveState();
    };
    BenefitDetailsStandartManagementService.prototype.deleteProviderTier = function (tierIdToDelete) {
        this.rulesManagementService.clearExistingMappedRules();
        var pLine = this.selectedProviderLine.current;
        var tier = _.find(pLine.tiers, { id: tierIdToDelete });
        if (_.isObjectLike(tier)) {
            tier.setStateDeleted();
            var newTier = _.findLast(pLine.tiers, function (t) { return !t.stateIsDeleted; });
            this.selectProviderTier(newTier);
            this.selectProviderTiers(pLine.tiers);
            this.updateCanSaveState();
        }
    };
    BenefitDetailsStandartManagementService.prototype.selectProviderTier = function (newTier, byPass) {
        if (byPass === void 0) { byPass = false; }
        var tier = this.selectedProviderTier;
        if (_.isObjectLike(newTier) && (!_.isObjectLike(tier) || tier.id !== newTier.id || byPass)) {
            this.exSelectedProviderTier = _.cloneDeep(newTier);
            this.selectedProviderLine.selectedTierId = newTier.id;
            this.selectedProviderTier = newTier;
            this.selectProviderTier$.next(newTier);
            this.commonManService.saveSelectedTierId(newTier.id);
            var currentCalcMethod = this.selectedProviderLine.current.calcMethod;
            var editmode = this.permissionService.getEditMode();
            var isEditmode = editmode.providerInfo || editmode.benefitInfo || editmode.coverageOptions;
            var orgId = this.commonManService.getProperOrglevelBasedOnSelectedOrglevel();
            if (currentCalcMethod && isEditmode && !newTier.isDraft) {
                this.getBenefitEnrolledEmployeesInfo({ tierId: newTier.id, orgLevelId: orgId, date: this.providerPlanDate });
            }
            else if (isEditmode && newTier.isDraft) {
                this.hasEnrolledEmpTierLevel = false;
                this.benefitEnrolledEmployeesChanged$.next(false);
            }
        }
    };
    BenefitDetailsStandartManagementService.prototype.updateProviderTierName = function (pTierId, pTierName) {
        this.providerTierNameIsUnique = this.commonManService.isUniqueName(_.filter(this.providerTiers, function (t) { return !t.stateIsDeleted; }), pTierId, pTierName);
        if (this.providerTierNameIsUnique) {
            this.selectedProviderTier.name = _.trim(pTierName);
            this.selectedProviderTier.setStateUpdated();
        }
        this.updateCanSaveState();
        return this.providerTierNameIsUnique;
    };
    BenefitDetailsStandartManagementService.prototype.updateProviderTierCode = function (pTierId, pCode) {
        this.providerTierNameIsUnique = this.commonManService.isUniqueName(_.filter(this.providerTiers, function (t) { return !t.stateIsDeleted; }), pTierId, pCode);
        if (this.providerTierNameIsUnique) {
            this.selectedProviderTier.name = _.trim(pCode);
            this.selectedProviderTier.setStateUpdated();
        }
        this.updateCanSaveState();
        return this.providerTierNameIsUnique;
    };
    BenefitDetailsStandartManagementService.prototype.updateProviderTierEECode = function (pTierId, pCode) {
        this.providerTierEECodeIsUnique = this.commonManService.isUniqueEECode(_.filter(this.providerTiers, function (t) { return !t.stateIsDeleted; }), pTierId, pCode);
        if (this.containsSpecialCharacters(pCode)) {
            this.isValidEEdeductionCode = false;
        }
        else {
            this.isValidEEdeductionCode = true;
        }
        if (!this.providerTierEECodeIsUnique) {
            this.eEErrorMessage = "This code " + pCode.toLowerCase() + " is already in use as an \u201CER Code\u201D please select a different value";
        }
        else {
            this.eEErrorMessage = '';
        }
        if (this.providerTierEECodeIsUnique && this.isValidEEdeductionCode) {
            this.selectedProviderTier.payrollDeductionCode = _.trim(pCode);
            this.selectedProviderTier.setStateUpdated();
        }
        this.updateCanSaveState();
    };
    BenefitDetailsStandartManagementService.prototype.updateProviderTierERCode = function (pTierId, eRCode) {
        this.providerTierERCodeIsUnique = this.commonManService.isUniqueERCode(_.filter(this.providerTiers, function (t) { return !t.stateIsDeleted; }), pTierId, eRCode);
        if (this.containsSpecialCharacters(eRCode)) {
            this.isValidERdeductionCode = false;
        }
        else {
            this.isValidERdeductionCode = true;
        }
        if (!this.providerTierERCodeIsUnique) {
            this.eRErrorMessage = "This code " + eRCode.toLowerCase() + " is already in use as an \u201CEE Code\u201D please select a different value";
        }
        else {
            this.eRErrorMessage = '';
        }
        if (this.providerTierERCodeIsUnique && this.isValidERdeductionCode) {
            this.selectedProviderTier.employerDeductionCode = _.trim(eRCode);
            this.selectedProviderTier.setStateUpdated();
        }
        this.updateCanSaveState();
    };
    BenefitDetailsStandartManagementService.prototype.updateTierEmployerFormula = function (type, fixed, formula) {
        var tier = this.selectedProviderTier;
        if (_.isObjectLike(tier)) {
            if (!this.covOptionsChanged) {
                this.covOptionsChanged =
                    this.hasEnrolledEmpTierLevel &&
                        (type !== tier.erFormula.calculationType ||
                            (tier.erFormula.calculationType === BenefitFormulaCalculationType.Fixed &&
                                fixed !== tier.erFormula.fixedAmount));
            }
            this.updateTierFormula(tier, tier.erFormula, { type: type, fixed: fixed, formula: formula });
        }
    };
    BenefitDetailsStandartManagementService.prototype.updateTierEmployeeFormula = function (type, fixed, formula) {
        var tier = this.selectedProviderTier;
        if (_.isObjectLike(tier)) {
            if (!this.covOptionsChanged) {
                this.covOptionsChanged =
                    this.hasEnrolledEmpTierLevel &&
                        (type !== tier.empFormula.calculationType ||
                            (tier.empFormula.calculationType === BenefitFormulaCalculationType.Fixed &&
                                fixed !== tier.empFormula.fixedAmount));
            }
            this.updateTierFormula(tier, tier.empFormula, { type: type, fixed: fixed, formula: formula });
        }
    };
    BenefitDetailsStandartManagementService.prototype.updateTierCoverageFormula = function (type, fixed, formula, multipler, maxCup) {
        var tier = this.selectedProviderTier;
        if (_.isObjectLike(tier)) {
            if (!this.covOptionsChanged) {
                this.covOptionsChanged =
                    this.hasEnrolledEmpTierLevel &&
                        (type !== tier.coverageFormula.calculationType ||
                            (type === BenefitFormulaCalculationType.Fixed && fixed !== tier.coverageFormula.fixedAmount) ||
                            (type === BenefitFormulaCalculationType.MultiplerMaxCap &&
                                (maxCup !== tier.coverageFormula.maxCap || multipler !== tier.coverageFormula.multipler)) ||
                            (type === BenefitFormulaCalculationType.AnyValueMaxCap && maxCup !== tier.coverageFormula.maxCap));
            }
            this.updateTierFormula(tier, tier.coverageFormula, { type: type, fixed: fixed, formula: formula, multipler: multipler, maxCup: maxCup });
        }
    };
    BenefitDetailsStandartManagementService.prototype.startEditingProvider = function () {
        this.commonManService.storeProviderAndLine(false);
        this.toggleActionsAndModes(false, true, true);
    };
    BenefitDetailsStandartManagementService.prototype.cancelEditingProvider = function () {
        this.covOptionsChanged = false;
        this.rulesManagementService.clearExistingMappedRules();
        this.commonManService.restoreProviderAndLine(false);
        this.toggleActionsAndModes(true, false, false, false);
        this.duplicateValue = false;
        this.isDuplicateERDeductionCode = false;
        this.eeValidationMessage = '';
        this.erValidationMessage = '';
        this.eEErrorMessage = '';
        this.eRErrorMessage = '';
    };
    BenefitDetailsStandartManagementService.prototype.createDraftProvider = function () {
        this.benefitEnrolledEmployeesInfo$.next(0);
        this.rulesManagementService.clearExistingMappedRules();
        this.commonManService.storeProviderAndLine(true);
        this.commonManService.selectDraftProviderAndLine();
        this.toggleActionsAndModes(false, true, null);
    };
    BenefitDetailsStandartManagementService.prototype.removeDraftProvider = function () {
        this.covOptionsChanged = false;
        this.commonManService.removeDraftProvider();
        this.commonManService.restoreProviderAndLine(true);
        this.selectedProviderTier = null;
        this.toggleActionsAndModes(true, false, false, false);
    };
    BenefitDetailsStandartManagementService.prototype.saveProviderLine = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.commonManService.updateSpinner(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.commonManService.saveBenefitProviderLine(function (providerLine) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.selectProviderLine(providerLine, true)];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); }, this.providerPlanDate)];
                    case 2:
                        _a.sent();
                        this.toggleActionsAndModes(true, false, false, false);
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    BenefitDetailsStandartManagementService.prototype.saveProvider = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_2;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.commonManService.saveBenefitProvider(function (providerLine) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.selectProviderLine(providerLine, true)];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })];
                    case 1:
                        _a.sent();
                        this.toggleActionsAndModes(true, false, false, false);
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        console.error(e_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    BenefitDetailsStandartManagementService.prototype.deleteProvider = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_3;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.commonManService.deleteBenefitProvider(function (providerLine) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.selectProviderLine(providerLine, true)];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _a.sent();
                        console.error(e_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    BenefitDetailsStandartManagementService.prototype.deleteTierAttachments = function (delAttachmentIds) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.commonManService.deleteTierAttachments(this.selectedProviderTier.id, delAttachmentIds);
                _.remove(this.selectedProviderTier.attachments, function (a) { return _.includes(delAttachmentIds, a.id); });
                this.updateCanSaveState();
                return [2 /*return*/];
            });
        });
    };
    BenefitDetailsStandartManagementService.prototype.downloadTierAttachment = function (attachmentId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var file, e_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.commonManService.updateSpinner(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.apiService.downloadTierAttachment(attachmentId)];
                    case 2:
                        file = _a.sent();
                        this.fileService.saveToFileSystem(file.blob, file.file);
                        return [3 /*break*/, 5];
                    case 3:
                        e_4 = _a.sent();
                        console.error(e_4);
                        return [3 /*break*/, 5];
                    case 4:
                        this.commonManService.updateSpinner(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    BenefitDetailsStandartManagementService.prototype.getBenefitEligibleEmployeesInfo = function (_a) {
        var orgLevelId = _a.orgLevelId, tierId = _a.tierId, date = _a.date;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var selectedProviderorgLevelId, info, e_5;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.commonManService.updateEligibleEmployeesSpinner(true);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        selectedProviderorgLevelId = orgLevelId ? orgLevelId : this.selectedProvider.current.orgLevelId;
                        return [4 /*yield*/, this.empApiService.getBenefitEligibleEmployeesInfo(selectedProviderorgLevelId, tierId, date)];
                    case 2:
                        info = _b.sent();
                        this.benefitEligibleEmployeesInfo$.next(info);
                        return [3 /*break*/, 5];
                    case 3:
                        e_5 = _b.sent();
                        console.error(e_5);
                        return [3 /*break*/, 5];
                    case 4:
                        this.commonManService.updateEligibleEmployeesSpinner(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    BenefitDetailsStandartManagementService.prototype.getBenefitEnrolledEmployeesInfo = function (_a) {
        var orgLevelId = _a.orgLevelId, tierId = _a.tierId, date = _a.date;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var empCount, e_6;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.commonManService.updateEnrolledEmployeesSpinner(true);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.empApiService.getBenefitEnrolledEmployeesInfo(orgLevelId, tierId, date)];
                    case 2:
                        empCount = _b.sent();
                        this.benefitEnrolledEmployeesInfo$.next(empCount);
                        this.benefitEnrolledEmployeesChanged$.next(empCount > 0);
                        this.hasEnrolledEmpTierLevel = empCount > 0;
                        return [3 /*break*/, 5];
                    case 3:
                        e_6 = _b.sent();
                        console.error(e_6);
                        return [3 /*break*/, 5];
                    case 4:
                        this.commonManService.updateEnrolledEmployeesSpinner(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    BenefitDetailsStandartManagementService.prototype.getFormulaTypes = function () {
        var _this = this;
        var counter = 0;
        return _.map(BenefitFormulaCalculationType, function (v, i) { return new BenefitDetailsBasic(++counter, i, _this.formulaTypesNames[i]); });
    };
    BenefitDetailsStandartManagementService.prototype.changeShownFormulaState = function (value) {
        this.storageService.setControlState(this.componentId, this.controlId, { value: value }, this.resetBy);
        this.state$.next(value);
    };
    BenefitDetailsStandartManagementService.prototype.getAllCoverageOptions = function () {
        var options = [];
        _.forEach(this.providerTiers, function (t) {
            if (!t.stateIsDeleted) {
                options = options.concat(_.filter(t.options, function (o) { return !o.stateIsDeleted; }));
            }
        });
        return options;
    };
    BenefitDetailsStandartManagementService.prototype.updateTierFormula = function (tier, formula, data) {
        formula.calculationType = data.type;
        formula.fixedAmount = null;
        formula.expression = null;
        formula.multipler = null;
        formula.maxCap = null;
        if (formula.isFixedCalcType) {
            formula.fixedAmount = data.fixed;
        }
        if (formula.isFormulaCalcType) {
            formula.expression = data.formula;
        }
        if (formula.isMultiplierCalcType) {
            formula.multipler = data.multipler;
            formula.maxCap = data.maxCup;
        }
        if (formula.isAnyValueMaxCapCalcType) {
            formula.maxCap = data.maxCup;
        }
        tier.setStateUpdated();
        this.updateCanSaveState();
    };
    BenefitDetailsStandartManagementService.prototype.validateCanMaxCapFields = function () {
        var invalidMaxCap = _.some(this.providerTiers, function (tier) {
            var covFormula = tier.coverageFormula;
            return ((covFormula.isAnyValueMaxCapCalcType && !_.isFinite(covFormula.maxCap)) ||
                (covFormula.isMultiplierCalcType &&
                    (!_.isFinite(covFormula.maxCap) ||
                        !_.isFinite(covFormula.multipler) ||
                        _.lt(covFormula.maxCap, covFormula.multipler))));
        });
        return !invalidMaxCap;
    };
    BenefitDetailsStandartManagementService.prototype.updateTierFormulasDates = function (providerLine) {
        if (_.isObjectLike(this.selectedProviderTier)) {
            var _a = this.selectedProviderTier, empFormula = _a.empFormula, erFormula = _a.erFormula, coverageFormula = _a.coverageFormula;
            var _b = providerLine.current.line, startDate = _b.startDate, endDate = _b.endDate;
            this.updateTierFormulaDates(empFormula, startDate, endDate);
            this.updateTierFormulaDates(erFormula, startDate, endDate);
            this.updateTierFormulaDates(coverageFormula, startDate, endDate);
        }
    };
    BenefitDetailsStandartManagementService.prototype.createBenefitProviderTier = function (sDate, eDate, length) {
        var providerTier = new BenefitDetailsTier();
        var tierId = -Math.abs(length) - 1;
        var tierName = this.defaultTierName + " " + Math.abs(tierId);
        providerTier.tier.id = tierId;
        providerTier.tier.name = tierName;
        providerTier.tier.title = tierName;
        providerTier.notes = '';
        providerTier.setStateAdded();
        this.updateTierFormulaDates(providerTier.empFormula, sDate, null /* eDate */);
        this.updateTierFormulaDates(providerTier.erFormula, sDate, null /* eDate */);
        this.updateTierFormulaDates(providerTier.coverageFormula, sDate, null /* eDate */);
        return providerTier;
    };
    BenefitDetailsStandartManagementService.prototype.selectProviderTiers = function (tiers) {
        if (_.isArray(tiers)) {
            this.providerTiers = tiers.concat();
            this.providerTiers$.next(this.providerTiers);
        }
    };
    BenefitDetailsStandartManagementService.prototype.updateTierFormulaDates = function (formula, sDate, eDate) {
        formula.startDate = sDate;
        formula.endDate = eDate;
    };
    BenefitDetailsStandartManagementService.prototype.replaceTierInProviderTiers = function (providerLine, tierToReplace) {
        var index = _.findIndex(providerLine.tiers, { id: tierToReplace.id });
        if (index !== -1) {
            providerLine.tiers.splice(index, 1, tierToReplace);
        }
    };
    BenefitDetailsStandartManagementService.prototype.updateCanSaveState = function () {
        var canSave = this.validateCanSaveProvider();
        this.permissionService.setCanSave(canSave);
    };
    // public updateSpecial(valid:boolean): void {
    //   // valid = this.validateCanSaveProvider();
    //   this.permissionService.setCanSave(valid);
    // }
    BenefitDetailsStandartManagementService.prototype.updateCanSaveDeductionCode = function () {
        var canSave = this.validateCanSaveProvider();
        this.permissionService.setCanSave(canSave);
    };
    BenefitDetailsStandartManagementService.prototype.updateCanSaveStateByValidity = function (isValid) {
        this.coverageOptionsValid = isValid;
        var canSave = this.validateCanSaveProvider();
        this.permissionService.setCanSave(isValid && canSave);
    };
    BenefitDetailsStandartManagementService.prototype.updateFlatCoverageOptions = function (empContribution, employeercontribution) {
        var tiers = this.selectedProviderTier;
        if (!this.covOptionsChanged) {
            this.covOptionsChanged =
                this.hasEnrolledEmpTierLevel &&
                    (tiers.empContribution !== empContribution || tiers.erContribution !== employeercontribution);
        }
        tiers.setStateUpdated();
        tiers.empContribution = empContribution;
        tiers.erContribution = employeercontribution;
        this.updateCanSaveState();
    };
    BenefitDetailsStandartManagementService.prototype.createBenefitProviderOption = function () {
        var option = new BenefitDetailsOption();
        option.id = -(_.size(this.selectedProviderTier.options) + 1);
        return option;
    };
    BenefitDetailsStandartManagementService.prototype.addNewCoverageOption = function () {
        this.addCoverageOption$.next(this.createBenefitProviderOption());
    };
    BenefitDetailsStandartManagementService.prototype.saveCoverageOption = function (o, is401K) {
        var option = _.assign(new BenefitDetailsOption(), o);
        option.setStateAdded();
        if (is401K) {
            _.forEach(this.selectedProviderTier.options, function (o) {
                if (o.isDraft)
                    o.setStateDeleted();
            });
        }
        this.selectedProviderTier.options.unshift(option);
        this.selectedProviderTier.setStateUpdated();
        this.updateCanSaveState();
    };
    BenefitDetailsStandartManagementService.prototype.updateCoverageOption = function (option) {
        if (!this.covOptionsChanged && this.hasEnrolledEmpTierLevel) {
            var empCont = this.exSelectedProviderTier.options.find(function (x) { return x.id === option.id; });
            var hasChangedOptionRates = this.calcMethod.isOptionsRates &&
                (option.empContribution !== empCont.empContribution ||
                    option.erContribution !== empCont.erContribution ||
                    option.code !== empCont.code);
            var hasChanged401KOptions = this.calcMethod.is401K &&
                (empCont.maxContribution !== option.maxContribution ||
                    empCont.suggestedContribution !== option.suggestedContribution ||
                    empCont.maxMatchGrant !== option.maxMatchGrant ||
                    empCont.maxEmpContribution !== option.maxEmpContribution ||
                    empCont.empContribution !== option.empContribution ||
                    empCont.limitAmount !== option.limitAmount ||
                    empCont.compensationLimit !== option.compensationLimit);
            this.covOptionsChanged = hasChangedOptionRates || hasChanged401KOptions;
        }
        option.setStateUpdated();
        this.selectedProviderTier.setStateUpdated();
        this.updateCanSaveState();
    };
    BenefitDetailsStandartManagementService.prototype.removeCoverageOption = function (option) {
        option.setStateDeleted();
        this.selectedProviderTier.setStateUpdated();
        this.updateCanSaveState();
    };
    BenefitDetailsStandartManagementService.prototype.updateTierOptionCost = function (selectedProviderLine, option) {
        var costFreq = selectedProviderLine.costFreq, empContFreq = selectedProviderLine.empContFreq;
        var empContribution = option.empContribution, erContribution = option.erContribution;
        var employeeContribution = 0;
        if (empContribution > 0) {
            employeeContribution = this.costCalculationService.getConvertedEmployeeContribution(costFreq, empContFreq, empContribution);
        }
        option.cost = parseFloat((erContribution + employeeContribution).toFixed(2));
    };
    BenefitDetailsStandartManagementService.prototype.updateAllOptionsInAllTiers = function (line) {
        var _this = this;
        _.forEach(line.tiers, function (t) {
            t.setStateUpdated();
            _.forEach(t.options, function (o) {
                o.setStateUpdated();
                _this.updateTierOptionCost(line, o);
            });
        });
    };
    BenefitDetailsStandartManagementService.prototype.validateDeductionCode = function () {
        return this.hasName(this.selectedProviderTier.payrollDeductionCode);
    };
    BenefitDetailsStandartManagementService.prototype.validateCanSaveProvider = function () {
        var _a = this.selectedProviderLine.current, costFreq = _a.costFreq, empContFreq = _a.empContFreq, calcMethod = _a.calcMethod, line = _a.line, tiers = _a.tiers, childAgeLimit = _a.childAgeLimit;
        return (this.coverageOptionsValid &&
            this.hasName(this.selectedProvider.current) &&
            !this.duplicateValue &&
            !this.isDuplicateERDeductionCode &&
            this.hasId(costFreq) &&
            this.hasId(empContFreq) &&
            this.hasId(calcMethod) &&
            _.isObjectLike(line) &&
            this.isValidDate(line.startDate) &&
            this.isValidDate(line.endDate) &&
            this.hasName(this.selectedProviderTier) &&
            !this.hasOptionsWithoutMandatoryFields &&
            this.providerNameIsUnique &&
            this.providerTierNameIsUnique &&
            this.providerTierEECodeIsUnique &&
            this.providerTierERCodeIsUnique &&
            this.isValidEEdeductionCode &&
            this.isValidERdeductionCode &&
            (!_.isFinite(childAgeLimit) || (childAgeLimit >= this.minAge && childAgeLimit <= this.maxAge)) &&
            this.validateCanMaxCapFields());
    };
    BenefitDetailsStandartManagementService.prototype.containsSpecialCharacters = function (value) {
        var regex = /^[^\^\\]*$/;
        return !regex.test(value);
    };
    Object.defineProperty(BenefitDetailsStandartManagementService.prototype, "hasOptionsWithoutMandatoryFields", {
        get: function () {
            if (!this.selectedProviderLine || !this.selectedProviderLine.current) {
                return false;
            }
            var tiers = this.selectedProviderLine.current.tiers;
            var calcMethod = this.selectedProviderLine.current.calcMethod;
            var hasTiers = _.size(tiers) > 0;
            var hasBadOptions = _.some(tiers, function (tier) {
                if (tier.stateIsDeleted || !tier.options) {
                    return false;
                }
                return _.some(tier.options, function (opt) { return !opt.stateIsDeleted && !opt.hasTypeAndCode; });
            });
            return calcMethod && calcMethod.isOptionsRates && hasTiers && hasBadOptions;
        },
        enumerable: true,
        configurable: true
    });
    BenefitDetailsStandartManagementService.prototype.toggleActionsAndModes = function (create, isEdit, expireExtendRenew, save) {
        if (save === void 0) { save = null; }
        this.permissionService.setCanSave(save);
        this.permissionService.toggleCanCreateEditDeleteSave(create, null, null);
        this.permissionService.toggleEditModeForProviderBenefitCoverageInfo(isEdit, null, null);
        if (!create) {
            this.permissionService.toggleCanAddCovOptions(true);
        }
        if (_.isBoolean(expireExtendRenew)) {
            this.permissionService.toggleCanExpireExtendRenew(expireExtendRenew, expireExtendRenew, expireExtendRenew);
        }
    };
    BenefitDetailsStandartManagementService.prototype.expireOrExtendProviderLine = function (line) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var benefitLines, index;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!_.isObjectLike(line)) return [3 /*break*/, 2];
                        benefitLines = this.selectedProvider.current.benefitLines.slice();
                        index = _.findIndex(benefitLines, { id: line.id });
                        if (!(index !== -1)) return [3 /*break*/, 2];
                        benefitLines.splice(index, 1, line);
                        this.selectedProvider.current.benefitLines = benefitLines;
                        this.commonManService.selectProvider(this.selectedProvider, false, true);
                        return [4 /*yield*/, this.selectProviderLine(line, true)];
                    case 1:
                        _a.sent();
                        this.toggleActionsAndModes(true, false, false, false);
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    BenefitDetailsStandartManagementService.prototype.renewProviderLine = function (prLine) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var benefitLines;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!_.isObjectLike(prLine)) return [3 /*break*/, 2];
                        benefitLines = this.selectedProvider.current.benefitLines.slice();
                        benefitLines.unshift(prLine.current.line);
                        this.selectedProvider.current.benefitLines = benefitLines;
                        this.commonManService.selectProvider(this.selectedProvider, false, true);
                        return [4 /*yield*/, this.selectProviderLine(prLine.current.line, true)];
                    case 1:
                        _a.sent();
                        this.toggleActionsAndModes(true, false, false, false);
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    BenefitDetailsStandartManagementService.prototype.subscribeToData = function () {
        var _this = this;
        this.subscriptions.state = this.stateService.onInit$.subscribe(function () {
            var state = _this.storageService.getControlState(_this.componentId, _this.controlId);
            var value = _.isBoolean(state.value) ? state.value : true;
            _this.state$.next(value);
        });
        this.subscriptions.selectProvider = this.commonManService.subscribeToSelectProvider(function (provider) {
            _this.selectedProvider = provider;
            if (provider.requireUpdate) {
                var selectedLine = provider.current.actualOrLatestBenefitLine;
                if (_.isFinite(provider.selectedLineId)) {
                    selectedLine = _.find(provider.current.benefitLines, { id: provider.selectedLineId }) || selectedLine;
                }
                _this.selectProviderLine(selectedLine, true);
            }
        });
        this.subscriptions.selectProviderLine = this.commonManService.subscribeToSelectProviderLine(function (pr) {
            _this.selectedProviderLine = pr;
            var hasSelectedTier = _.isFinite(pr.selectedTierId);
            var tier = null;
            var calcMethod = null;
            if (pr.isDraft) {
                tier = _this.createBenefitProviderTier(pr.current.line.startDate, pr.current.line.endDate, _.size(pr.current.tiers));
                pr.current.tiers.push(tier);
            }
            else {
                tier = _.head(pr.current.tiers);
                if (hasSelectedTier) {
                    var t = _.find(pr.current.tiers, { id: pr.selectedTierId });
                    if (_.isObjectLike(t) && !t.isDraft)
                        tier = t;
                }
                calcMethod = pr.current.calcMethod;
            }
            _this.detectProviderPlanDate(pr.current.line);
            _this.selectProviderTiers(pr.current.tiers);
            _this.selectProviderTier(tier, !pr.isDraft && hasSelectedTier);
            _this.changeCalcMethod(calcMethod);
        });
        this.subscriptions.createNewProvider = this.commonManService.subscribeToCreateNewProvider(function () {
            Observable.of(true)
                .delay(250)
                .subscribe(function () { return _this.createDraftProvider(); });
        });
        this.subscriptions.expireExtendRenew = this.commonManService.subscribeToExpireExtendRenew(function (b) {
            if (b.isRenew) {
                _this.renewProviderLine(b.benefitLineStandart);
            }
            else {
                _this.expireOrExtendProviderLine(b.benefitLine);
            }
        });
        this.subscriptions.orgLevelChanged = this.commonManService.subscribeToOrgLevel(function (v) {
            var editMode = _this.permissionService.getEditMode();
            var isEditmode = editMode.providerInfo || editMode.benefitInfo || editMode.coverageOptions;
            if (isEditmode) {
                _this.cancelEditingProvider();
            }
        });
    };
    BenefitDetailsStandartManagementService.prototype.detectProviderPlanDate = function (line) {
        var providerPlanDate = null;
        var today = moment().startOf('day');
        var startOfStartDate = line.startOfStartDate, startOfEndDate = line.startOfEndDate;
        var hasDates = _.isDate(startOfStartDate) && _.isDate(startOfEndDate);
        if (!hasDates || (hasDates && today.isBetween(startOfStartDate, startOfEndDate))) {
            providerPlanDate = today.toDate();
        }
        else {
            providerPlanDate = startOfStartDate;
        }
        this.changeProviderPlanDate(providerPlanDate);
    };
    BenefitDetailsStandartManagementService.prototype.hasId = function (value) {
        return _.isObjectLike(value) && !_.isNil(value.id);
    };
    BenefitDetailsStandartManagementService.prototype.hasName = function (value) {
        return _.isObjectLike(value) && _.size(value.name) > 0;
    };
    BenefitDetailsStandartManagementService.prototype.isValidDate = function (date) {
        return _.isDate(date) && dateTimeUtils.isInValidPeriod(moment(date).startOf('day').toDate());
    };
    tslib_1.__decorate([
        unsubscribeAll('destroy'),
        tslib_1.__metadata("design:type", Object)
    ], BenefitDetailsStandartManagementService.prototype, "subscriptions", void 0);
    return BenefitDetailsStandartManagementService;
}());
export { BenefitDetailsStandartManagementService };
