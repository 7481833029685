import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

import * as moment from 'moment';
import * as _ from 'lodash';

import { ResponseBody, Meta, AuthError } from '../../../core/models/index';
import { appConfig } from '../../../app.config';
import { appMessages, NotificationMessage } from '../../../app.messages';
import { StatusCodes } from '../../../core/models/api/status-codes';
import { FileBlobResponse } from '../../../core/models/api/file-blob-response';
import { ValidationError, HandledError } from '../../../core/models/index';
import { CacheUtilService } from '../../../common/services/cache/cache.service';
import { CacheType, CacheDefinition } from '../../models/cache/cache-definition';
import { ICacheConfig } from '../../models/cache/icache-config';
import { SlxHttpRequest } from '../../../core/models/index';
import { AuthApiService } from '../../../core/services/auth-api/auth-api.service';
import { ErrorHandlingService } from '../../../core/services/error-handling/error-handling.service';

@Injectable()
export class ApiUtilService {

  constructor(private authApiService: AuthApiService,
    private errorHandlingService: ErrorHandlingService,
    private cacheUtilService: CacheUtilService) {
  }

  public cachedRequest<TData, TMeta extends Meta>(
    request: HttpRequest<any>,
    cacheType: CacheType = CacheType.default,
    updateCacheForced: boolean = false,
    suppress: boolean = false
  ): Promise<ResponseBody<TData, TMeta>> {
    const url: string = this.getReducedUrl(request.urlWithParams);
    const cachedResponse: any = updateCacheForced ? undefined : this.cacheUtilService.get({ key: url });

    if (!_.isNull(cachedResponse) && !_.isUndefined(cachedResponse)) {
      return cachedResponse;
    }

    const config: ICacheConfig = this.getCacheConfig(cacheType);
    const response: any = this.request(request, suppress);
    this.cacheUtilService.put({ key: url }, response, config.expires);

    return response;
  }

  public requestNew<TData, TMeta extends Meta>(request: SlxHttpRequest<any>, suppress: boolean = false): Promise<ResponseBody<TData, TMeta>> {
    return <any>this.authApiService.requestNew<TData, TMeta>(request)
      .catch((error: any) => {
        this.handleError(error, suppress);
      });
  }

  public request<TData, TMeta extends Meta>(request: HttpRequest<any>, suppress: boolean = false): Promise<ResponseBody<TData, TMeta>> {
    return <any>this.authApiService.request<TData, TMeta>(request)
      .catch((error: any) => {
        this.handleError(error, suppress);
      });
  }

  public requestUnauthorized<TData, TMeta extends Meta>(request: HttpRequest<any>, alias: string, suppress: boolean = false): Promise<ResponseBody<TData, TMeta>> {
    return <any>this.authApiService.requestUnauthorized<TData, TMeta>(request, alias)
      .catch((error: any) => {
        this.handleError(error, suppress);
      });
  }

  public requestForFile(request: HttpRequest<any>, suppress: boolean = false): Promise<FileBlobResponse> {
    return <any>this.authApiService.requestForFile(request)
      .catch((error: any) => {
        this.handleError(error, suppress);
      });

  }

  public getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}`;
  }

  public getTAServicesApiRoot(): string {
    return `${appConfig.api.urlTAServices}/${appConfig.api.taVersion}`;
  }

  public getCommunicationApiRoot(): string {
    return `${appConfig.api.communicationsApiUrl}`;
  }

  public getSignalRApiRoot(): string {
    return `${appConfig.api.communicationsSignalRUrl}`;
  }

  public getCommunicationApiRootVersion(): string {
    return `${appConfig.api.communicationsApiVersion}`;
  }

  public apiRoot(apiTemplate: TemplateStringsArray, ...substitutions: any[]): string {
    let result: string = apiTemplate[0];
    substitutions.forEach((substitution: any, idx: number) => {
      if (substitution instanceof Date || substitution instanceof moment) {
        result += moment(substitution).format(appConfig.requestDate);
      } else {
        result += String(substitution);
      }
      result += apiTemplate[idx + 1];
    });
    return `${this.getApiRoot()}/${result}`;
  }

  private handleError(error: any, suppress: boolean = false): void {
    if (error instanceof AuthError) {
      throw error;
    }

    if (error && error.status === StatusCodes.conflict) {
      this.errorHandlingService.errorWithoutLog(new NotificationMessage('Validation error', error.meta.error), error, suppress);
      throw new ValidationError(error);
    }

    if (error && (error.data || error.meta)) {
      this.errorHandlingService.errorWithoutLog(appMessages.error.requestFailed, error, suppress);
    } else {
      this.errorHandlingService.error(appMessages.error.requestFailed, error, suppress);
    }
    throw new HandledError(error);
  }

  public getReducedUrl(url: string): string {
    const apiUrl: string = appConfig.api.url;
    let reducedUrl: string = url;
    if (_.startsWith(url, apiUrl)) {
      reducedUrl = url.slice(apiUrl.length);
    }

    return reducedUrl;
  }

  private getCacheConfig(cacheType: CacheType): ICacheConfig {
    let config: ICacheConfig;
    switch (cacheType) {
      case CacheType.default:
        config = appConfig.cacheConfig.default;
        break;
      case CacheType.shortTerm:
        config = appConfig.cacheConfig.shortTerm;
        break;
      case CacheType.longTerm:
        config = appConfig.cacheConfig.longTerm;
        break;
      default:
        throw new Error(`CacheType unknown cache: ${cacheType}`);
    }

    return config;
  }
}
