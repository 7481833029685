import { Injectable } from '@angular/core';
import { PaycodeExceptionModel } from '../../models/pay-code-details/models/paycode-exception.model';
import { PaycodeExceptionDto } from '../../models/pay-code-details/dtos/paycode-exception.dto';

@Injectable()
export class PaycodeExceptionMapService {
    public mapToModel(dto: PaycodeExceptionDto): PaycodeExceptionModel {
        let model: PaycodeExceptionModel = new PaycodeExceptionModel();
        Object.assign(model, dto);
        model.exceptionDescription = dto.description;
        if (dto.payDiffPct) {
            model.payDiffPct = Number(dto.payDiffPct);
        }
        if (dto.ex2ndPayRateExceptionId) {
            model.ex2ndPayRateExceptionId = Number(dto.ex2ndPayRateExceptionId);
        }
        if (dto.payCodeExceptions) {
            model.payCodeExceptions = dto.payCodeExceptions.map(exc => ({
                ...exc,
                id: Number(exc.id)
            }));
        }
        return model;
    }

    public mapToDto(model: PaycodeExceptionModel): PaycodeExceptionDto {
        let dto: PaycodeExceptionDto = {
            id: model.id,
            description: model.exceptionDescription,
            screenColor: model.screenColor,
            accrualInd: model.accrualInd,
            paidInd: model.paidInd,
            payDiffPct: model.payDiffPct.toString(),
            deleteInd: model.deleteInd,
            discrepancyInd: model.discrepancyInd,
            exAllocationType: model.exAllocationType,
            exInterface: model.exInterface,
            exWorkStatus: model.exWorkStatus,
            exInterfaceCategoryId: model.exInterfaceCategoryId,
            exFormulaStart: model.exFormulaStart,
            exFormulaEnd: model.exFormulaEnd,
            exAllowMultiple: model.exAllowMultiple,
            exCountTowardsOvertimeInd: model.exCountTowardsOvertimeInd,
            exSortOrder: model.exSortOrder,
            exMinimumInterval: model.exMinimumInterval,
            exMaximumAppliedInterval: model.exMaximumAppliedInterval,
            exMinimumAppliedInterval: model.exMinimumAppliedInterval,
            exShiftDiffCategory: model.exShiftDiffCategory,
            exAddlVariable: model.exAddlVariable,
            ex2ndPayRateExceptionId: model.ex2ndPayRateExceptionId,
            exSecondaryJobCode: model.exSecondaryJobCode,
            exApplyToWeekday: model.exApplyToWeekday,
            exApplyShiftDiff: model.exApplyShiftDiff,
            groupId: model.groupId,
            exAction: model.exAction,
            exActionStart: model.exActionStart,
            exActionEnd: model.exActionEnd,
            exActionStartVar: model.exActionStartVar,
            exActionInterval: model.exActionInterval,
            exActionMessage: model.exActionMessage,
            exDepartmentId: model.exDepartmentId,
            exPayDiffType: model.exPayDiffType,
            exShowInExceptionGrid: model.exShowInExceptionGrid,
            exAmount: model.exAmount,
            exDisplayGroup: model.exDisplayGroup,
            exOrganizationId: model.exOrganizationId,
            exUseInTimesheets: model.exUseInTimesheets,
            workedInd: model.workedInd,
            acaInd: model.acaInd,
            exceptionColumnGroup: model.exceptionColumnGroup,
            isException: model.isException
        };
        return dto;
    }

    public mapToModels(dtos: PaycodeExceptionDto[]): PaycodeExceptionModel[] {
        return dtos.map(dto => this.mapToModel(dto));
    }

    public mapToDtos(models: PaycodeExceptionModel[]): PaycodeExceptionDto[] {
        return models.map(model => this.mapToDto(model));
    }
}