import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUtilService, UrlParamsService } from '../../../common/services/index';
import { Meta, ResponseBody, SlxHttpRequest } from '../../../core/models/index';
import { configurationConfig } from '../../configuration.config';
import { WfmSync } from '../../models/wfm-sync';
import { BenefitDeductionsync, CostcentersyncDetails, EmpSynDetails, EventReport, IBenefitDeductionsync, ICostcentersyncDetails, IDepartment, IEmpSynDetails, IEventReport, IOrg, IOrganization, IPosition, IWFMDashBoardDrillDownModel, IWFMDashBoardModel, IWFMFlagsSyncStatus, IWFMOrgLevelChangeLogModel, IWFMPostEventSyncModel, IWFMSecondaryPositionSyncModel, IWFMStopSyncDetails, IWfmSynDetails, IWfmTransactionReport, IWfnSyncResponse, WFMFlagsSyncStatus, WFMOrgLevelChangeLogModel, WFMStopSyncDetails, WfmTransactionReport } from './../../models/wfm-sync';
import { WFMSyncMapService } from './wfm-sync-map.service';

@Injectable()
export class WfmSyncService {

  constructor(
    private apiUtilService: ApiUtilService,
    private wfmMapService: WFMSyncMapService,
    private urlParamsService: UrlParamsService) {
  }

  public async employeeSync(req: WfmSync): Promise<any> {
    const url: string = `${this.wfmEmployee()}`;
    const body = this.wfmMapService.postEmployeeDetails(req);
    const request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, body);
    return this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        return response;
      });
  }
  public async employeeSyncResponse():Promise<any>{
    const url: string = `${this.wfmEmployeeResponse()}`;
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url);

    const response = await this.apiUtilService.requestNew<IWfnSyncResponse[], Meta>(request);
    console.log(response);
    return response;
  }
  public async employeeSyncDetails(syncId:any):Promise<any>{
    const url: string = `${this.wfmEmployeeDetails(syncId)}`;
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url);

    const response = await this.apiUtilService.requestNew<IWfmSynDetails[], Meta>(request);
    return response;
  }
  private wfmEmployee(): string {
    return `${this.getMessagesApiRoot()}/${configurationConfig.api.configuration.wfm.sync}/${configurationConfig.api.configuration.wfm.employee}`;
  }
  private wfmEmployeeResponse(): string {
    return `${this.getMessagesApiRoot()}/${configurationConfig.api.configuration.wfm.sync}/${configurationConfig.api.configuration.wfm.response}`;
  }
  private wfmEmployeeDetails(syncId:number): string {
    return `${this.getMessagesApiRoot()}/${configurationConfig.api.configuration.wfm.sync}/${syncId}/${configurationConfig.api.configuration.wfm.responsedetails}`;
  }
  private getMessagesApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${configurationConfig.api.configuration.wfm.root}`;
  }

  public async dailyTransactionReport(startDate:string,endDate:string):Promise<any>{
    const url: string = `${this.wfmTransactionDetails(startDate,endDate)}`;
     const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url);
    const response = await this.apiUtilService.requestNew<IWfmTransactionReport[], Meta>(request);
    return response;
 }
  private wfmTransactionDetails(startDate:string,endDate:string){
    return `${this.getMessagesApiRoot()}/${configurationConfig.api.configuration.wfm.report}/${startDate}/${endDate}`;
  }

  public async payDataEventReport(startDate:string,endDate:string):Promise<any>{
    const url: string = `${this.wfmPaydatareportDetails(startDate,endDate)}`;
     const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url);
    const response = await this.apiUtilService.requestNew<IWfmTransactionReport[], Meta>(request);
    return response;
 }
  private wfmPaydatareportDetails(startDate:string,endDate:string){
    return `${this.getMessagesApiRoot()}/${configurationConfig.api.configuration.wfm.paydatareport}/${startDate}/${endDate}`;
  }
  public async GetOrganizationLevelsChangeLogs(workFlowName:string,workFlowStep:string): Promise<any>{
    const url: string = `${this.workLocations(workFlowName,workFlowStep)}`;
    let params: StringMap<any> = {};
    if (workFlowName) {
      params['workFlowName'] = workFlowName;
    }

    if (workFlowStep) {
      params['workFlowStep'] = workFlowStep;
    }
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url,params);
    const response = await this.apiUtilService.requestNew<IWFMOrgLevelChangeLogModel[], Meta>(request);
    return response;
  }
  private workLocations(workFlowName:string,workFlowStep:string){
    return `${this.getApiRoot()}/${configurationConfig.api.orglevels.changelogs}/${configurationConfig.api.orglevels.orgType}`;

  }
  private getApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${configurationConfig.api.orglevels.root}`;
  }
  public async SyncOrganizationLevelsChangeLogs(orgsyncchangelogdetails:WFMOrgLevelChangeLogModel[],workFlowType:string): Promise<any> {
    const url: string = `${this.putSync()}`;
    let params: StringMap<any> = {};
    if (workFlowType) {
      params['workFlowType'] = workFlowType;
    }

    const body =orgsyncchangelogdetails;
    const request: HttpRequest<any> = this.urlParamsService.createPutRequest(url,body,params);
    return this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        return response;
      });
  }
  private putSync(): string {
    return `${this.getApiRoot()}/${configurationConfig.api.orglevels.changelogs}/${configurationConfig.api.orglevels.sync}`;
  }
  public async eventReport(startDate:string,endDate:string,transactionReport:boolean):Promise<any>{
    const url: string = `${this.eventReportDetails(startDate,endDate,transactionReport)}`;
    let params: StringMap<any> = {};
    if (transactionReport) {
      params['transactionReport'] = transactionReport.toString();
    }
     const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url,params);
    const response = await this.apiUtilService.requestNew<IEventReport[], Meta>(request);
    return response;
  }
  private eventReportDetails(startDate:string,endDate:string,transactionReport:boolean){
    return `${this.getMessagesApiRoot()}/${configurationConfig.api.configuration.wfm.eventreport}/${startDate}/${endDate}`;
  }

  public async taEventReport(startDate:string,endDate:string):Promise<any>{
    const url: string = `${this.taEventReportDetails(startDate,endDate)}`;
    let params: StringMap<any> = {};
     const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url,params);
    const response = await this.apiUtilService.requestNew<IEventReport[], Meta>(request);
    return response;
  }
  private taEventReportDetails(startDate:string,endDate:string){
    return `${this.getMessagesApiRoot()}/${configurationConfig.api.configuration.wfm.taeventreport}/${startDate}/${endDate}`;
  }
  public async employeeDetailsSync(workFlowName:string):Promise<any>{
    const url: string = `${this.EmployeeSyncResponse()}`;
    let params: StringMap<any> = {};
    if (workFlowName) {
      params['workFlowName'] = workFlowName;
    }
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url,params);
    let promise: Promise<EmpSynDetails[]> = this.apiUtilService.requestNew<EmpSynDetails[], Meta>(request)
      .then((response: ResponseBody<IEmpSynDetails[], Meta>) =>
        this.wfmMapService.getEmployeeOrgSyncDetails(response.data));
    return promise;
  }
  private EmployeeSyncResponse(): string {
    return `${this.getEmployeeApiRoot()}/${configurationConfig.api.configuration.employee.root}/${configurationConfig.api.configuration.employee.sync}`;
  }
  private getEmployeeApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${configurationConfig.api.configuration.root}`;
  }


  public async employeeSyncOrg(req: EmpSynDetails[],workFlowType:string): Promise<any> {
    const url: string = `${this.employeeSyncOrgRoot()}`;
    let params: StringMap<any> = {};
    if (workFlowType) {
      params['workFlowType'] = workFlowType;
    }
    const body = req;
    const request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, body,params);
    return this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        return response;
      });
  }
  private employeeSyncOrgRoot(): string {
    return `${this.getMessagesApiRoot()}/${configurationConfig.api.configuration.wfm.sync}/${configurationConfig.api.configuration.wfm.employees}`;
  }

  public async orgDetails(): Promise<any> {
    const url: string = `${this.orgStruct()}`;
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url);
    const response = await this.apiUtilService.requestNew<IOrg[], Meta>(request);
    console.log(response);
    return response;
  }
  private orgStruct(): string {
    return `${this.getOrgApiRoot()}/${configurationConfig.api.configuration.org.orglevels}`;
  }
  private getOrgApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${configurationConfig.api.configuration.org.root}`;
  }


  public async orgDataDetails(orgLevelId:number,isActive:boolean): Promise<any> {
    const url: string = `${this.orgDataStruct(orgLevelId,isActive)}`;
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url);
    const response = await this.apiUtilService.requestNew<IOrganization[], Meta>(request);
    return response;
  }
  private orgDataStruct(orgLevelId:number,isActive:boolean): string {
    return `${this.getDataOrgApiRoot()}/${configurationConfig.api.configuration.orgsync.userAccesibleOrganizations}`;
  }
  private getDataOrgApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${configurationConfig.api.configuration.orgsync.lookup}`;
  }

  public async depDataDetails(empId:number,orgLevelId:number,isActive:boolean): Promise<any> {
    const url: string = `${this.depDataStruct(empId,orgLevelId,isActive)}`;
    let params: StringMap<any> = {};
    if (empId) {
      params['empId'] = empId.toString();
    }

    if (orgLevelId) {
      params['orgLevelId'] = orgLevelId.toString();
    }

    // if (_.isBoolean(isActive)) {
    //   params['isActive'] = String(isActive);
    // }
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url,params);
    const response = await this.apiUtilService.requestNew<IDepartment[], Meta>(request);
    return response;
  }
  private depDataStruct(empId:number,orgLevelId:number,isActive:boolean): string {
    return `${this.getDataDepApiRoot()}/${configurationConfig.api.configuration.orgsync.department}`;
  }
  private getDataDepApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${configurationConfig.api.configuration.orgsync.lookup}`;
  }

  public async posDataDetails(empId:number,orgLevelId:number,isActive:boolean): Promise<any> {
    const url: string = `${this.posDataStruct(empId,orgLevelId,isActive)}`;
    let params: StringMap<any> = {};

    if (empId) {
      params['empId'] = empId.toString();
    }

    if (orgLevelId) {
      params['orgLevelId'] = orgLevelId.toString();
    }

    // if (_.isBoolean(isActive)) {
    //   params['isActive'] = String(isActive);
    // }
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url,params);
    const response = await this.apiUtilService.requestNew<IPosition[], Meta>(request);
    return response;
  }
  private posDataStruct(empId:number,orgLevelId:number,isActive:boolean): string {
    return `${this.getDataPosApiRoot()}/${configurationConfig.api.configuration.orgsync.position}`;
  }
  private getDataPosApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${configurationConfig.api.configuration.orgsync.lookup}`;
  }


  public async putErrorDetails(evetErrorReort:EventReport[]): Promise<any> {
    const url: string = `${this.putErrorSync()}`;
    const body =evetErrorReort;
    const request: HttpRequest<any> = this.urlParamsService.createPutRequest(url,body);
    return this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        return response;
      });
  }
  private putErrorSync(): string {
    return `${this.getMessagesApiRoot()}/${configurationConfig.api.configuration.wfm.event}/${configurationConfig.api.configuration.wfm.error}/${configurationConfig.api.configuration.wfm.errordetails}`;
  }


  public async GetBenefitDeductionChangeLogs(workFlowName:string, isActiveOnly: boolean = true){
    const url: string = `${this.BenefitDeductions()}`;
    let params: StringMap<any> = {};
    if (workFlowName) {
      params['workFlowName'] = workFlowName;
    }
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url,params);
    let promise: Promise<BenefitDeductionsync[]> = this.apiUtilService.requestNew<BenefitDeductionsync[], Meta>(request)
      .then((response: ResponseBody<IBenefitDeductionsync[], Meta>) =>
        this.wfmMapService.mapBenifitDeductions(response.data, isActiveOnly));
    return promise;
  }
  private BenefitDeductions(){
    return `${this.apiUtilService.getApiRoot()}/${configurationConfig.api.configuration.root}/${configurationConfig.api.configuration.employeeBenifits.root}/${configurationConfig.api.configuration.employeeBenifits.sync}`;

  }

  public async benefitdeductionSyncOrg(req: BenefitDeductionsync[],workFlowType:string): Promise<any> {
    const url: string = `${this.BenefitdeductSyncOrgRoot()}`;
    let params: StringMap<any> = {};
    if (workFlowType) {
      params['workFlowType'] = workFlowType;
    }
    const body = req;
    const request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, body,params);
    return this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        return response;
      });
  }
  private BenefitdeductSyncOrgRoot(): string {
    return `${this.getMessagesApiRoot()}/${configurationConfig.api.configuration.wfm.sync}/${configurationConfig.api.configuration.wfm.employeeBenifits}`;
  }

  public async GetLabourCodeChangeLogs(workFlowName:string){
    const url: string = `${this.Labourcodes()}`;
    let params: StringMap<any> = {};
    if (workFlowName) {
      params['workFlowName'] = workFlowName;
    }
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url,params);
    const response = await this.apiUtilService.requestNew<ICostcentersyncDetails[], Meta>(request);
    console.log(response);
    return response;
  }
  private Labourcodes(){
    return `${this.apiUtilService.getApiRoot()}/${configurationConfig.api.configuration.root}/${configurationConfig.api.configuration.costcenter.root}/${configurationConfig.api.configuration.costcenter.sync}`;

  }

  public async LabourCodeSyncOrg(req: CostcentersyncDetails[],workFlowType:string): Promise<any> {
    const url: string = `${this.LabourcodeSyncOrgRoot()}`;
    let params: StringMap<any> = {};
    if (workFlowType) {
      params['workFlowType'] = workFlowType;
    }
    const body = req;
    const request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, body,params);
    return this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        return response;
      });
  }
  private LabourcodeSyncOrgRoot(): string {
    return `${this.getMessagesApiRoot()}/${configurationConfig.api.configuration.wfm.sync}/${configurationConfig.api.configuration.wfm.costcenter}`;
  }

  public async postEventSync(startDate:string,endDate:string):Promise<any>{
    const url: string = `${this.postEventResponse(startDate,endDate)}`;
     const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url);
    const response = await this.apiUtilService.requestNew<IWFMPostEventSyncModel[], Meta>(request);
    return response;
 }
  private postEventResponse(startdate:string,enddate:string): string {
    return `${this.apiUtilService.getApiRoot()}/${configurationConfig.api.wfm.root}/${configurationConfig.api.wfm.post}/${configurationConfig.api.wfm.event}/${configurationConfig.api.wfm.startdate}/${startdate}/${configurationConfig.api.wfm.enddate}/${enddate}`;
  }

  public async postSyncEvent(eventid:string): Promise<any> {
    const url: string = `${this.postEvent(eventid)}`;
    // const body = this.wfmMapService.postEmployeeDetails(eventid);
    const body=eventid;
    const request: HttpRequest<any> = this.urlParamsService.createPostRequest(url,body);
    return this.apiUtilService.request<IWFMPostEventSyncModel[], Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        return response;
      });
  }
  private postEvent(eventid:string): string {
    return `${this.apiUtilService.getApiRoot()}/${configurationConfig.api.wfm.root}/${configurationConfig.api.wfm.post}/${configurationConfig.api.wfm.event}/${eventid}/${configurationConfig.api.wfm.sync}`;
  }
  public async syncDashboard(workFlowName:string,workFlowType:string,IsPayrollAppStatusWorkflow:boolean=false):Promise<any>{
    const url: string = `${this.syncDashBoardApi(workFlowName,workFlowType,IsPayrollAppStatusWorkflow)}`;
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url);

    const response = await this.apiUtilService.requestNew<IWFMDashBoardModel[], Meta>(request);
    return response;
  }
  private syncDashBoardApi(workFlowName:string,workFlowType:string,IsPayrollAppStatusWorkflow:boolean): string {
    return `${this.getMessagesApiRoot()}/${configurationConfig.api.configuration.wfm.dashBoard}/${workFlowName}/${workFlowType}/${IsPayrollAppStatusWorkflow}`;
  }

  public async syncPostDashboard(workFlowName:string,workFlowType:string,status:string,IsPayrollAppStatusWorkflow:boolean=false):Promise<any>{
    const url: string = `${this.syncPostDashBoardApi(workFlowName,workFlowType,status,IsPayrollAppStatusWorkflow)}`;
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url);

    const response = await this.apiUtilService.requestNew<IWFMDashBoardDrillDownModel[], Meta>(request);
    return response;
  }
  private syncPostDashBoardApi(workFlowName:string,workFlowType:string,status:string,IsPayrollAppStatusWorkflow:boolean): string {
    return `${this.getMessagesApiRoot()}/${configurationConfig.api.configuration.wfm.dashBoard}/${configurationConfig.api.configuration.wfm.drillDown}/${workFlowName}/${workFlowType}/${status}/${IsPayrollAppStatusWorkflow}`;
  }

  public async getSyncStatus():Promise<any>{
    const url: string = `${this.syncGetApi()}`;
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url);

    const response = await this.apiUtilService.requestNew<IWFMFlagsSyncStatus[], Meta>(request);
    return response;
  }
  private syncGetApi(){
    return `${this.apiUtilService.getApiRoot()}/${configurationConfig.api.wfm.root}/${configurationConfig.api.wfm.sync}/${configurationConfig.api.wfm.flagstatus}`;
  }
  public async postSyncStatus(req:WFMFlagsSyncStatus):Promise<any>{
    const url: string = `${this.syncPostApi()}`;
    const body = req;
    const request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, body);
    return this.apiUtilService.request<any, Meta>(request)
    .then((response: ResponseBody<any, Meta>) => {
      return response;
    });

  }
  private syncPostApi(){
    return `${this.apiUtilService.getApiRoot()}/${configurationConfig.api.wfm.root}/${configurationConfig.api.wfm.sync}/${configurationConfig.api.wfm.flagstatus}/${configurationConfig.api.wfm.update}`;
  }
  public async getStopSyncStatus():Promise<any>{
    const url: string = `${this.syncStopGetApi()}`;
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url);

    const response = await this.apiUtilService.requestNew<IWFMStopSyncDetails[], Meta>(request);
    return response;
  }
  private syncStopGetApi(){
    return `${this.apiUtilService.getApiRoot()}/${configurationConfig.api.wfm.root}/${configurationConfig.api.wfm.stop}/${configurationConfig.api.wfm.sync}`;
  }
  public async stopSyncStatus(req:WFMStopSyncDetails):Promise<any>{
    const url: string = `${this.stopSyncyncPostApi()}`;
    const body = req;
    const request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, body);
    return this.apiUtilService.request<any, Meta>(request)
    .then((response: ResponseBody<any, Meta>) => {
      return response;
    });

  }
  private stopSyncyncPostApi(){
    return `${this.apiUtilService.getApiRoot()}/${configurationConfig.api.wfm.root}/${configurationConfig.api.wfm.stop}/${configurationConfig.api.wfm.sync}`;
  }
  public async resync(req:WfmTransactionReport):Promise<any>{
    const url: string = `${this.resyncApi()}`;
    const body = req;
    const request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, body);
    return this.apiUtilService.request<any, Meta>(request)
    .then((response: ResponseBody<any, Meta>) => {
      return response;
    });

  }
  private resyncApi(){
    return `${this.apiUtilService.getApiRoot()}/${configurationConfig.api.wfm.root}/${configurationConfig.api.wfm.resync}`;
  }

  private secondaryPositions(){
    return `${this.apiUtilService.getApiRoot()}/${configurationConfig.api.wfm.root}/${configurationConfig.api.wfm.sync}/${configurationConfig.api.wfm.secondaryPositions}`;

  }

  public async getSecondaryPositionsDataSync(workFlowName:string){
    const url: string = `${this.secondaryPositions()}`;
    let params: StringMap<any> = {};
    if (workFlowName) {
      params['workFlowName'] = workFlowName;
    }
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url,params);
    const response = await this.apiUtilService.requestNew<IWFMSecondaryPositionSyncModel[], Meta>(request);
    return response;
  }

  public async secondaryPositionSyncOrg(req: IWFMSecondaryPositionSyncModel[],workFlowType:string): Promise<any> {
    const url: string = `${this.secondaryPositionOrgRoot()}`;
    let params: StringMap<any> = {};
    if (workFlowType) {
      params['workFlowType'] = workFlowType;
    }
    const body = req;
    const request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, body,params);
    return this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        return response;
      });
  }

  private secondaryPositionOrgRoot(): string {
    return `${this.getMessagesApiRoot()}/${configurationConfig.api.configuration.root}/${configurationConfig.api.wfm.secondaryPositions}/${configurationConfig.api.wfm.sync}`;
  }
}








