import * as tslib_1 from "tslib";
import { AuditTrailEmployeeAction, AuditTrailConfigAction } from '../../models/index';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AppSettingsManageService } from './../../../../app-settings/services';
import { mutableSelect } from '../../../../core/decorators/index';
import { Observable } from 'rxjs';
import { OrgLevelType } from '../../../../state-model/models/index';
var AuditTrailMapService = /** @class */ (function () {
    function AuditTrailMapService(appSettingsManageService) {
        var _this = this;
        this.appSettingsManageService = appSettingsManageService;
        this.isLocalTimeShown = true;
        this.appSettingsManageService.getAppServerConfig()
            .then(function (appConfig) {
            _this.serverUtcOffsetSec = appConfig.serverUtcOffsetSec;
        });
        this.orgLevel$.subscribe(function (orgLevel) {
            _this.orgLevel = orgLevel;
            _this.getTimeStatus(orgLevel);
        });
    }
    AuditTrailMapService.prototype.getTimeStatus = function (orgLevel) {
        if (!orgLevel)
            return;
        this.isLocalTimeShown = !(orgLevel.type === OrgLevelType.organization || orgLevel.type === OrgLevelType.department);
        if (!this.isLocalTimeShown) {
            this.currentOffset = +_.get(orgLevel, 'location.timeZoneOffset') || 0;
        }
    };
    AuditTrailMapService.prototype.mapAuditTrailEmployeeActions = function (recordsDto) {
        var _this = this;
        return _.map(recordsDto, function (dto) { return _this.mapAuditTrailEmployeeAction(dto); });
    };
    AuditTrailMapService.prototype.mapAuditTrailEmployeeAction = function (dto) {
        var act = new AuditTrailEmployeeAction();
        act.action = dto.action;
        act.actionType = dto.actionType;
        act.area = dto.area;
        act.auditDateTime = moment(dto.auditDateTime).add(this.currentOffset, 'hours').toString();
        act.effectiveDate = dto.effectiveDate ? moment(dto.effectiveDate).startOf('day').toDate() : null;
        act.employeeId = dto.employeeId;
        act.employeeName = dto.employeeName;
        act.fieldName = dto.fieldName;
        act.orgLevelId = dto.orgLevelId;
        act.recordNumber = dto.recordNumber;
        act.userLoginId = dto.userLoginId;
        act.valueAfter = dto.valueAfter;
        act.valueBefore = dto.valueBefore;
        act.departmentName = dto.departmentName;
        act.empOrganization = dto.empOrganization;
        return act;
    };
    AuditTrailMapService.prototype.mapAuditTrailConfigActions = function (recordsDto) {
        var _this = this;
        return _.map(recordsDto, function (dto) { return _this.mapAuditTrailConfigAction(dto); });
    };
    AuditTrailMapService.prototype.mapAuditTrailConfigAction = function (dto) {
        var act = new AuditTrailConfigAction();
        act.action = dto.action;
        act.actionType = dto.actionType;
        act.area = dto.area;
        act.auditDateTime = moment(dto.auditDateTime).add(this.currentOffset, 'hours');
        act.effectiveDate = dto.effectiveDate ? moment(dto.effectiveDate).startOf('day').toDate() : null;
        act.entityDate = dto.entityDate;
        act.entityDescription = _.isNil(dto.entityDescription) ? '' : dto.entityDescription.trim();
        act.fieldName = _.isNil(dto.fieldName) ? '' : dto.fieldName.trim();
        act.orgLevelId = dto.orgLevelId;
        act.recordNumber = dto.recordNumber;
        act.userLoginId = dto.userLoginId;
        act.valueAfter = _.isNil(dto.valueAfter) ? '' : dto.valueAfter.trim();
        act.valueBefore = _.isNil(dto.valueBefore) ? '' : dto.valueBefore.trim();
        return act;
    };
    AuditTrailMapService.prototype.convertUtcToFacilityTime = function (date) {
        date = ((date.includes("-", date.length - 6)) || (date.includes("+", date.length - 6))) ? this.convertToZuluFormat(date) : date;
        var formattedDateTime = moment.utc(date).format('MM/DD/YYYY hh:mm:ss A');
        var newDate = moment(new Date(formattedDateTime + " UTC"));
        var locaTime = newDate.utcOffset(this.currentOffset);
        return moment(locaTime);
    };
    AuditTrailMapService.prototype.convertToZuluFormat = function (date) {
        var replaceText = date.slice(date.length - 6, date.length);
        date = date.replace(replaceText, "Z");
        return date;
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], AuditTrailMapService.prototype, "orgLevel$", void 0);
    return AuditTrailMapService;
}());
export { AuditTrailMapService };
