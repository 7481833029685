<kendo-grid class="slx-full-height"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [filterable]="true"
  [filter]="gridState.state.filter"
  [groupable]="false"
  [group]="gridState.state.group"
  [pageable]="false"
  [pageSize]="pageSize"
  [skip]="gridState.state.skip"
  (pageChange)="gridState.pageChange($event)"
  (dataStateChange)="gridState.dataStateChange($event)"
  (groupChange)="gridState.groupChange($event)"
>

<kendo-grid-column title="Position" field="positionName" width="100">
  <ng-template kendoGridHeaderTemplate>Position</ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.positionName | removeAsterisk}}</ng-template>

  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
    <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
    </slx-kendo-grid-string-filter>
  </ng-template>
</kendo-grid-column>

<kendo-grid-column title="Shift" field="shiftName" width="120">
  <ng-template kendoGridHeaderTemplate>Shift</ng-template>
  
  <ng-template kendoGridCellTemplate let-dataItem>
    <div class="slx-parameter-partial-label" *ngIf="dataItem.hasPartialShift"><label>Partial</label></div>
    {{dataItem.shiftName}}
  </ng-template>
  <!-- <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
    <slx-kendo-grid-multiselect-filter
      [filter]="filter" [showOperators]="false" [column]="column" [items]="typeFilters" ></slx-kendo-grid-multiselect-filter>
 </ng-template> -->
 <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
    <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
    </slx-kendo-grid-string-filter>
  </ng-template>
</kendo-grid-column>

<kendo-grid-column title="Unit" field="unitName" width="90">
  <ng-template kendoGridHeaderTemplate>Unit</ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.unitName}}</ng-template>
  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
    <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
    </slx-kendo-grid-string-filter>
  </ng-template>
</kendo-grid-column>
<kendo-grid-column title="Open" field="calculatedOpenShiftCount" width="90">
    <ng-template kendoGridHeaderTemplate>Open</ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.calculatedOpenShiftCount }}</ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-number-filter [spinners]="false" [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-number-filter>
    </ng-template>
</kendo-grid-column>
<kendo-grid-column title="Over" field="overScheduleCount" width="90">
  <ng-template kendoGridHeaderTemplate>Over</ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.overScheduleCount }}</ng-template>
  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
    <slx-kendo-grid-number-filter [spinners]="false" [showOperators]="true" [column]="column" [filter]="filter">
    </slx-kendo-grid-number-filter>
  </ng-template>
</kendo-grid-column>
<kendo-grid-column title="Requests" field="newMessageCount" width="90">
    <ng-template kendoGridHeaderTemplate>Requests</ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem?.requestedPartialShiftCount + dataItem?.newMessageCount + dataItem?.partnerShiftCount}}</ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-number-filter [spinners]="false" [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-number-filter>
    </ng-template>
</kendo-grid-column>

<kendo-grid-column title="Actions" [sortable]="false" [filterable]="false" width="120" class="overflow-visible-cell">
    <ng-template kendoGridHeaderTemplate>Actions</ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <slx-actions-list>
        <slx-actions-button [popperContent]="popperContent" [popperPosition]="'bottom-end'">
          Actions <span class="caret"></span>
        </slx-actions-button>
        <popper-content class="poper-content-host" #popperContent>
          <slx-actions-list-item [disabled]="!canEditOpenShiftCount" (onClick)="onEditOpenShiftsClick(dataItem, $event)">
            <span class="action-list-item-host">
              <span *ngIf="!canEditOpenShiftCount" slxTooltip="No rights" tipPosition="left"></span>
              <i class="fas fa-pencil-alt" aria-hidden="true"></i>&nbsp; Edit Open Shifts count
            </span>
          </slx-actions-list-item>
          <slx-actions-list-item *ngIf="canSendSMS" [disabled]="false" hidden="true">
            <i class="far fa-envelope" aria-hidden="true"></i>&nbsp; Reply via SMS
          </slx-actions-list-item>
        </popper-content>
      </slx-actions-list>
    </ng-template>
</kendo-grid-column>

<div *kendoGridDetailTemplate="let dataItem">
  <slx-daily-shift-messages-grid [summary]="dataItem" *ngIf="!dataItem.hasPartialShifts"
  (itemRemove)="removeItem($event,dataItem)"></slx-daily-shift-messages-grid>
  <slx-daily-partial-shifts-grid [partials]="dataItem.partialShifts" *ngIf="dataItem.hasPartialShifts"
    (itemRemove)="removeItem($event,dataItem.partialShifts)"></slx-daily-partial-shifts-grid>
</div>
</kendo-grid>
<!--   -->
